import React from 'react'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import ivImage01 from "../../../../images/Software/Other_Platforms/InstarVision_Blackberry/Instarvision.blackberry.app_01.webp"
import ivImage02 from "../../../../images/Software/Other_Platforms/InstarVision_Blackberry/Instarvision.blackberry.app_02.webp"
import ivImage03 from "../../../../images/Software/Other_Platforms/InstarVision_Blackberry/Instarvision.blackberry.app_03.webp"
import ivImage04 from "../../../../images/Software/Other_Platforms/InstarVision_Blackberry/Instarvision.blackberry.app_04en.webp"
import ivImage05 from "../../../../images/Software/Other_Platforms/InstarVision_Blackberry/Instarvision.blackberry.app_05en.webp"


export default function PoECards() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={ivImage01}
                    alt="Blackberry App for your INSTAR IP Camera"
                  />
              }
              description={
                <p><strong>Fig1.</strong> Multi-Ansicht.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={ivImage02}
                    alt="Blackberry App for your INSTAR IP Camera"
                  />
              }
              description={
                <p><strong>Fig2.</strong> Vollbildanschicht.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={ivImage03}
                    alt="Blackberry App for your INSTAR IP Camera"
                  />
              }
              description={
                <p><strong>Fig3.</strong> Volle Kamerakontrolle.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={ivImage04}
                    alt="Blackberry App for your INSTAR IP Camera"
                  />
              }
              description={
                <p><strong>Fig4.</strong> FAQ Seiten miteingebunden.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={ivImage05}
                    alt="Blackberry App for your INSTAR IP Camera"
                  />
              }
              description={
                <p><strong>Fig5.</strong> Anpassbar.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
