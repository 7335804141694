import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Anmerkung">
        <EuiText>
          <p>Wenn Sie bei IN-8015 HD einen externen Sensor (oder den internen PIR-Sensor) verwenden, müssen Sie entweder die interne Bewegungserkennung (Alarmbereiche) deaktivieren oder mit dem Alarmeingang koppeln! Der Alarm kann nicht anders ausgelöst werden.</p>
          <p>Wenn Sie beide Erkennungsmethoden miteinander verbunden haben, müssen Sie beide immer deaktivieren, um die Bewegungserkennung zu deaktivieren!</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

