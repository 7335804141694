import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {
  
  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Serie/Alarm/Aktionen/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_Alarm_Alarm_Actions.webp'
                    alt="INSTAR 1440p Web User Interface Alarm Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Alarm Aktionen"
              description="Passen Sie das Verhalten der Kamera im Falle eines Bewegungsalarm-Auslöseereignisses an. Sie können die Kamera so einstellen, dass sie Ihnen eine E-Mail sendet und Schnappschüsse oder Videos auf Ihrer SD-Karte speichert..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1440p_Serie/Alarm/Aktionen/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Serie/Alarm/Bereiche/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_Alarm_Alarm_Areas.webp'
                    alt="INSTAR 1440p Web User Interface Alarm Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Alarmbereiche"
              description="Die Alarmbereiche erkennen Bewegungen durch eine Bildanalyse, die auf jede Veränderung innerhalb des Videobildes reagiert, anstatt einen Infrarot- oder Mikrowellensensor zu verwenden..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1440p_Serie/Alarm/Bereiche/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Serie/Alarm/Objekt_Erkennung/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_Alarm_Alarm_Areas.webp'
                    alt="INSTAR 1440p Web User Interface Alarm Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Objekt Erkennung"
              description="Der äußere Rahmen gibt die maximale Größe an, die ein erkanntes Objekt haben darf, um einen Alarm auszulösen. Der innere Rahmen definiert die Mindestgröße..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1440p_Serie/Alarm/Objekt_Erkennung/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Serie/Alarm/Zeitplan/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_Alarm_Schedule.webp'
                    alt="INSTAR 1440p Web User Interface Alarm Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Alarm Zeitplan"
              description="Hier können Sie einen Zeitplan festlegen, in dem die Bewegungserkennung für die aktiven Erkennungsbereiche aktiv sein soll. Jedes blaue Quadrat steht für 30 Minuten scharfe Bewegung..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1440p_Serie/Alarm/Zeitplan/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Serie/Alarm/Push_Dienst/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_Alarm_Push_Service.webp'
                    alt="INSTAR 1440p Web User Interface Alarm Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Push Dienst"
              description="Ihre Kamera kann Sie per E-Mail benachrichtigen, wenn eine Bewegungserkennung ausgelöst wird. Eine Push-Benachrichtigung erfüllt den gleichen Zweck, ist aber viel schneller! Aktivieren Sie Push, wenn Sie möchten..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1440p_Serie/Alarm/Push_Dienst/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
