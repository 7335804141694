import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="WebUI Einstellungen">
        <EuiText>
          <p>
            UI Kacheln für Schnelleinstellung für Ihre INSTAR 1440p FullHD IP Kamera. Stellen Sie hier ein welche Kacheln rechts neben dem Live Video angezeigt werden sollen.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

