import React from 'react'
import { OutboundLink } from 'gatsby-plugin-gtag'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiIcon
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="gwjk_epTyaY"
                    title="INSTAR IN-8015 Full HD"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              footer={
                <EuiText>
                <p>Die ganz neue INSTAR IN-8015 Full HD. Das Flagschiff unter den IP Innenkameras. Dank eines Panasonic WDR Bildsensors erzeugt die IN-8015 sagenhafte Aufnahmen bei Tag als auch bei Nacht. </p>
                <p>Der eingebaute PIR Wärmesensor verhindert zudem das die Kamera Fehlalarme erzeugt und eine mitgelieferte 16GB MicroSD Speicherkarte ermöglicht das Aufnahmen der Alarm Videos von bis zu mehr als einem Monat. Wem das nicht reicht, der kann bis zu 128GB MicroSD Speicherkarten in die Kamera einsetzen. Auf <OutboundLink href="https://www.youtube.com/watch?v=gwjk_epTyaY" target="_blank" rel="noopener noreferrer">Youtube ansehen <EuiIcon type="popout" /></OutboundLink></p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}