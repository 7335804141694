import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'InstarVision',
      href: '/de/Software/Android/InstarVision/',
    },
    {
      text: 'IP Cam Viewer',
      href: '/de/Software/Android/IP_Cam_Viewer/',
    },
    {
      text: 'TinyCam Monitor',
      href: '/de/Software/Android/TinyCam_Monitor/',
    },
    {
      text: '◄ Android',
      href: '/de/Software/Android/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={11}
        aria-label="Software Platform for your INSTAR IP Camera"
      />
      <EuiSpacer />
    </Fragment>
  );
}