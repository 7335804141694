import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class PTZTourTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Pan, Tilt & Zoom Tour" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Ihre Funktion aktivieren', descriptionColumn: 'Programmieren Sie Ihre eigene Kameratour, wählen Sie dazu eine Position aus Ihren gespeicherten Funktionen und setzen Sie eine Zeit, wie lange die Kamera auf dieser Position bleiben soll (max. 43.200s = 12h).' },
        { actionColumn: 'Anzahl der Wiederholungen', descriptionColumn: 'Sie müssen zuerst ein paar Kamerapositionen speichern - bis zu 8 verfügbare Positionen. Wählen Sie sie dann in der Reihenfolge aus, in der sich Ihre Kamera bewegen soll. Klicken Sie auf die grüne Schaltfläche +, um bei Bedarf weitere Positionen hinzuzufügen.' },
        { actionColumn: 'Verweildauer in Sekunden', descriptionColumn: 'Nach jeder Position können Sie eine Wartezeit einstellen. Nachdem Ihre Kamera an der Tour-Position angekommen ist, wird auf die eingestellte Anzahl von Minuten (1min bis 720min) gewartet.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

PTZTourTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(PTZTourTable)