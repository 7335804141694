import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Angehängte Alarm Trigger">
        <EuiText>
          <ul>
              <li>Alarmbereich Bereich 1 ausgelöst: <code>&active=1</code></li>
              <li>Alarmbereich Bereich 2 ausgelöst: <code>&active=2</code></li>
              <li>Alarmbereich Bereich 3 ausgelöst: <code>&active=3</code></li>
              <li>Alarmbereich Bereich 4 ausgelöst: <code>&active=4</code></li>
              <li>Alarmeingang / PIR ausgelöst: <code>&active=5</code></li>
              <li>Audioalarm ausgelöst: <code>&active=6</code></li>
              <li>PIR & Bereich 1 ausgelöst (gekoppelt): <code>&active=7</code></li>
              <li>PIR & Bereich 2 ausgelöst (gekoppelt): <code>&active=8</code></li>
              <li>PIR & Bereich 3 ausgelöst (gekoppelt): <code>&active=9</code></li>
              <li>PIR & Bereich 4 ausgelöst (gekoppelt): <code>&active=10</code></li>
              <li>Manual Trigger (<em>cmd=pushhostalarm</em>): <code>&active=99</code></li>
          </ul>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

