import React, { useState } from 'react'

import {EuiButton} from "@elastic/eui"

import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel"

import Wizard01 from '../../../../../images/Web_User_Interface/1440p_Series/Wizard/Wizard_01.webp'
import Wizard02 from '../../../../../images/Web_User_Interface/1440p_Series/Wizard/Wizard_02.webp'
import Wizard03 from '../../../../../images/Web_User_Interface/1440p_Series/Wizard/Wizard_03.webp'
import Wizard04 from '../../../../../images/Web_User_Interface/1440p_Series/Wizard/Wizard_04.webp'
import Wizard05 from '../../../../../images/Web_User_Interface/1440p_Series/Wizard/Wizard_05.webp'
import Wizard06 from '../../../../../images/Web_User_Interface/1440p_Series/Wizard/Wizard_06.webp'
import Wizard07 from '../../../../../images/Web_User_Interface/1440p_Series/Wizard/Wizard_07.webp'
import Wizard08 from '../../../../../images/Web_User_Interface/1440p_Series/Wizard/Wizard_08.webp'
import Wizard09 from '../../../../../images/Web_User_Interface/1440p_Series/Wizard/Wizard_09.webp'

const AutoRotatingCarouselModal = ({ handleOpen, setHandleOpen }) => {
  return (
    <div>
      <AutoRotatingCarousel
        label="Return"
        open={handleOpen.open}
        onClose={() => setHandleOpen({ open: false })}
        onStart={() => setHandleOpen({ open: false })}
        mobile
        autoplay={true}
        style={{ position: "absolute" }}
      >
        <Slide
          media={
            <img alt="INSTAR WQHD 2k+ IP Kamera Installationsassistent" src={Wizard01} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Installationsassistent"
          subtitle="Beim ersten Kamerastart führt die Kamera einen Assistenten aus, der Sie durch die Erstinstallation führt. Sie können diesen Assistenten auch in der Weboberfläche unter Features / Wizard noch einmal manuell starten."
        />
        <Slide
          media={
            <img alt="INSTAR WQHD 2k+ IP Kamera Installationsassistent" src={Wizard02} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Login"
          subtitle="Hinterlegen Sie hier ein Login für den Administrator Account. Dies ist das Login mit dem Sie sich anschließend in der Weboberfläche der Kamera anmelden und weitere Einstellungen vornehmen können."
        />
        <Slide
          media={
            <img alt="INSTAR WQHD 2k+ IP Kamera Installationsassistent" src={Wizard03} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="IP Konfiguration"
          subtitle="Wenn Sie die Netzwerkschnittstelle der Kamera nicht konfigurieren und diesen Schritt überspringen, wird die Kamera im sog. DHCP Modus eine verfügbare IP Adresse von Ihrem Router beziehen. Geben Sie hier eine statische IP an, um die Kamera immer über die gleiche Adresse erreichen zu können."
        />
        <Slide
          media={
            <img alt="INSTAR WQHD 2k+ IP Kamera Installationsassistent" src={Wizard04} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#454649' }}
          style={{ backgroundColor: '#07d' }}
          title="WLAN Einbindung"
          subtitle="Wenn Sie Ihre Kamera ins WLAN einbinden wollen, können Sie hier nach verfügbaren WLAN's suchen. Wenn Sie Ihr WLAN Netz gefunden haben, wählen Sie es aus und geben Sie Ihren WLAN Schlüssel an. Mit dem Test-Button können Sie die Verbindung überprüfen und mit Bestätigen die Daten speichern."
        />
        <Slide
          media={
            <img alt="INSTAR WQHD 2k+ IP Kamera Installationsassistent" src={Wizard05} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Video Overlays"
          subtitle="Legen Sie hier das Datum, die Uhrzeit und den Kameraname fest. Und bestimmen Sie, ob Sie diese als Einblendung im Video-Bild der Kamera sehen möchten."
        />
        <Slide
          media={
            <img alt="INSTAR WQHD 2k+ IP Kamera Installationsassistent" src={Wizard06} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="INSTAR Cloud"
          subtitle="Haben Sie bereits einen INSTAR Cloud Account? Dann können Sie hier Ihre Daten eintragen und Ihre neue Kamera zu Ihrem Cloud Account hinzufügen. Die Verwendung der INSTAR Cloud ist rein optional und hat keinen Einfluß auf die restlichen Funktionen der Kamera."
        />
        <Slide
          media={
            <img alt="INSTAR WQHD 2k+ IP Kamera Installationsassistent" src={Wizard07} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="InstarVision App"
          subtitle="INSTAR bieten kostenlose Apps für Windows (Metro&Phone), iPhone, iPad und Android an. Diese können Sie sich hier auf Ihr Gerät herunterladen, installieren und Ihre Kamera durch Scannen des QR Codes hinzufügen."
        />
        <Slide
          media={
            <img alt="INSTAR WQHD 2k+ IP Kamera Installationsassistent" src={Wizard08} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Bewegungserkennung"
          subtitle="Ihre Kamera kann anhand einer software-basierten Bewegungserkennung (Alarm Bereiche) und mittels des Wärmesensors (PIR) Bewegungen erkennen. Aktivieren Sie diese hier zusammen mit der Alarm EMail und Pushbenachrichtigung - letzteres funktioniert nur im Zusammenhang mit der im letzten Schritt installierten, kostenlosen InstarVision App."
        />
        <Slide
          media={
            <img alt="INSTAR WQHD 2k+ IP Kamera Installationsassistent" src={Wizard09} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Abschluß"
          subtitle="Nach dem Abschluß des Installationsassistenten wird die Weboberfläche (webUI) der Kamera geladen. Dort können Sie alle Funktionen der Kamera konfigurieren. Auch die bereits vorgenommenen noch einmal anpassen."
        />
      </AutoRotatingCarousel>
    </div>
  );
}

function SettingGallery() {
  const [handleOpen, setHandleOpen] = useState({ open: false });
  const handleClick = () => {
    setHandleOpen({ open: true });
  };
  return (
    <>
      <EuiButton fill onClick={handleClick}>
        Wizard
      </EuiButton>
      <AutoRotatingCarouselModal
        handleOpen={handleOpen}
        setHandleOpen={setHandleOpen}
      />
    </>
  );
}


export default SettingGallery