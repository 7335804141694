import { EuiPinnableListGroupItemProps } from "@elastic/eui";

export const TopLinks: EuiPinnableListGroupItemProps[] = [
  {
    label: "Kontakt",
    iconType: "email",
    isActive: true,
    "aria-current": true,
    href: "https://www.instar.com/contact",
    pinnable: false,
  },
];

export const SupportLinks: EuiPinnableListGroupItemProps[] = [
  { label: "FAQs", href: "/de/FAQs/" },
  { label: "Erweiterte Suche", href: "/de/Search/" },
  { label: "Handbücher", href: "/de/Produkte/Gebrauchsanleitungen/" },
  { label: "Downloads", href: "/de/Downloadbereich/" },
  { label: "Reviews", href: "/de/Assistants/Review_Wall/" },
  { label: "Privatsphäre", href: "https://www.instar.com/information/privacy" },
];

export const ProductsLinks: EuiPinnableListGroupItemProps[] = [
  { label: "Kameras Vergleichen", href: "/de/Produkte/Vergleich/" },
  { label: "Innenkameras", href: "/de/Innenkameras/" },
  { label: "Aussenkameras", href: "/de/Aussenkameras/" },
  { label: "Power over Ethernet", href: "/de/Produkte/Power_over_Ethernet/" },
  { label: "Objektive", href: "/de/Produkte/Objektive/" },
  { label: "Software", href: "/de/Software/" },
  { label: "Web User Interface", href: "/de/Web_User_Interface/" },
  { label: "Andere Produkte", href: "/de/Produkte/Andere/" },
];

export const InstallationLinks: EuiPinnableListGroupItemProps[] = [
  {
    label: "IP Kameras",
    href: "/de/Schnell_Installation/Wie_Funktioniert_eine_IP_Kamera/",
  },
  {
    label: "Nach dem Auspacken",
    href: "/de/Schnell_Installation/Nach_dem_Auspacken/",
  },
  { label: "Erste Schritte", href: "/de/Schnell_Installation/Erste_Schritte/" },
  {
    label: "WLAN Verbindung",
    href: "/de/Schnell_Installation/Herstellen_der_WLAN_Verbindung/",
  },
  {
    label: "Power over Ethernet",
    href: "/de/Schnell_Installation/Power_over_Ethernet/",
  },
  { label: "Powerline", href: "/de/Schnell_Installation/Powerline/" },
  {
    label: "Direkte LAN Verbindung",
    href: "/de/Schnell_Installation/Direkte_LAN_Verbindung/",
  },
  {
    label: "Auffinden Ihrer Kamera",
    href: "/de/Schnell_Installation/Alternative_IP_Scanner/",
  },
  { label: "Sprachauswahl", href: "/de/Schnell_Installation/Sprachauswahl/" },
  { label: "Live Video", href: "/de/Schnell_Installation/Live_Video/" },
  {
    label: "Benutzerverwaltung",
    href: "/de/Schnell_Installation/Benutzerverwaltung/",
  },
  {
    label: "Software Update",
    href: "/de/Schnell_Installation/WebUI_und_Firmware_Upgrade/",
  },
  { label: "ONVIF", href: "/de/Schnell_Installation/ONVIF/" },
  {
    label: "Browser History",
    href: "/de/Schnell_Installation/Leeren_des_Browserverlaufs/",
  },
];

export const MotionLinks: EuiPinnableListGroupItemProps[] = [
  { label: "Einrichtung", href: "/de/Bewegungserkennung/Einrichten/" },
  {
    label: "PIR Bewegungsmelder",
    href: "/de/Bewegungserkennung/Passive_Infrared_Detection/",
  },
  {
    label: "Alarm Benachrichtigung",
    href: "/de/Bewegungserkennung/Alarm_Benachrichtigung/",
  },
  {
    label: "SD Karten Zugriff",
    href: "/de/Bewegungserkennung/SD_Karten_Zugriff/",
  },
  {
    label: "Alarm FTP Upload",
    href: "/de/Bewegungserkennung/Alarm_FTP_Upload/",
  },
  {
    label: "Router als FTP Server",
    href: "/de/Bewegungserkennung/Router_als_FTP_Server/",
  },
  { label: "FTP Server", href: "/de/Bewegungserkennung/FTP_Server_Setup/" },
  { label: "Alarm Server", href: "/de/Bewegungserkennung/Alarm_Server/" },
  { label: "INSTAR Cloud", href: "/de/Bewegungserkennung/INSTAR_Cloud/" },
];

export const InternetLinks: EuiPinnableListGroupItemProps[] = [
  { label: "Point to Point", href: "/de/Fernzugriff/Point_to_Point/" },
  { label: "DDNS Dienst", href: "/de/Fernzugriff/Der_DDNS_Dienst/" },
  { label: "Portweiterleitung", href: "/de/Fernzugriff/Port_Weiterleitung/" },
  { label: "Mobiler Zugriff", href: "/de/Fernzugriff/Mobil_Zugriff/" },
  { label: "Drittanbieter DDNS", href: "/de/Fernzugriff/DDNS_Anbieter/" },
];

export const AdvancedLinks: EuiPinnableListGroupItemProps[] = [
  { label: "CGI Befehle", href: "/de/Erweitert/CGI_Befehle/" },
  { label: "INSTAR MQTT Broker", href: "/de/Erweitert/INSTAR_MQTT_Broker/" },
  { label: "VPN AVM Fritzbox", href: "/de/Erweitert/VPN_AVM_Fritzbox/" },
  {
    label: "Webseiten Einbindung",
    href: "/de/Erweitert/Webseiten_Einbindung/",
  },
  {
    label: "Alle Smarthome Tutorials",
    href: "/de/Erweitert/Alle_Smarthome_Tutorials/",
  },
  {
    label: "Youtube Videostreaming",
    href: "/de/Erweitert/Youtube_Videostreaming_from_your_Camera/",
  },
  { label: "Tasker Automation", href: "/de/Erweitert/Tasker/" },
  {
    label: "WebUI Wiederherstellen",
    href: "/de/Erweitert/WebUI_Wiederherstellen/",
  },
  {
    label: "Firmware Wiederherstellen",
    href: "/de/Erweitert/Firmware_Wiederherstellen/",
  },
  { label: "Analog vs Digital", href: "/de/Erweitert/CCTV_vs_IP/" },
];
