import React from 'react'
import {Link} from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiCard,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
} from '@elastic/eui'

export default function softwareArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/iOS/InstarVision/iPad/">
                  <StaticImage
                    src='../../../../images/Software/iOS/InstarVision/IPad_App-MockUp.png'
                    alt="InstarVision for iPad INSTAR IP Camera"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/iOS/InstarVision/iPad/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        InstarVision fürs iPad
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/iOS/InstarVision/iPhone/">
                  <StaticImage
                    src='../../../../images/Software/iOS/InstarVision/IPhone_App-MockUp.png'
                    alt="InstarVision for iPhone INSTAR IP Camera"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/iOS/InstarVision/iPhone/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        InstarVision fürs iPhone
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
