import React from 'react'

import {
  EuiPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiTitle,
  EuiImage
} from '@elastic/eui'

import lenseSpecsImage from '../../../../images/Indoor_Cameras/IN-8015_HD/Technical_Specifications/4-2_Lense_Specifications.png'
import pirSpecsImage from '../../../../images/Outdoor_Cameras/IN-9008_HD/Technical_Specifications/PaPIR_Specifications.png'

export default function FooterCards() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiPanel paddingSize="m">
              <EuiTitle size="m">
                <h3>[2] Objektiv Spezifikationen</h3>
              </EuiTitle>
              <EuiImage
                allowFullScreen
                alt='Lense Specifications'
                src={lenseSpecsImage}
              />
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiPanel paddingSize="m">
              <EuiTitle size="m">
                <h3>[5] Panasonic PIR Sensor</h3>
              </EuiTitle>
              <EuiImage
                allowFullScreen
                alt='IN-9008 Full HD PIR Sensor Specifications'
                src={pirSpecsImage}
              />
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
