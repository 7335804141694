import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class VideoTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Video Settings" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'MJPEG Stream aktivieren', descriptionColumn: 'Ihre Kamera bietet die Möglichkeit, anstatt eines h.264 codiertem Videostream, eine Sequenz in Form von einzelnen Bildern zu senden. Der Motion-JPEG Stream hat den Vorteil, ohne ein Video-Plugin in jedem Webbrowser angezeigt zu werden. Das ist notwendig, wenn Sie das Live-Video auf einem Rechner sehen möchten, auf dem Sie keine Rechte haben ein benötigtes Plugin zu installieren, z.B. Ihr Arbeitsrechner. Der Nachteil dieses Modus ist das die Komprimierung wesentlich geringer ist und Sie eine wesentlich höhere Bandbreite benötigen. Sie können den Modus aktivieren/deaktivieren mit dem Schnellzugriff in der oberen Video Menüleiste.' },
        { actionColumn: 'Video Kanal', descriptionColumn: 'Ihre Kamera kann das h.264 Video in 3 verschiedenen Streams mit unterschiedlichen Auflösungen senden. Sie können alle 3 Streams unabhängig voneinander mit externer Software erreichen. Den Stream den Sie hier auswählen wird innerhalb der Weboberfläche dargestellt.' },
        { actionColumn: 'Video Auflösung', descriptionColumn: 'Zeigt die Auflösung des ausgewählten Videostreams. Die Videoauflösung wird durch den Videokanal, den Sie oben ausgewählt haben, definiert. Zum Beispiel wird der erste Kanal immer in 720p HD Auflösung streamen.' },
        { actionColumn: 'Video Framerate', descriptionColumn: 'Dieser Wert gibt die Frames per Sekunde an die Ihre Kamera streamen soll. Bitte beachten Sie, dass dieser Wert nur ein theoretisches Maximum ist, der ihre Kamera nicht überschreiten soll. Die tatsächliche Framerate kann durch die Netzwerkverbindung und die Expositionszeit des Bildsensors (desto dunkler das Image wird, desto länger muss der Bildsensor Licht sammeln und desto länger dauert es einen Frame zu laden) beeinflusst werden. Die Framerate eignet sich dafür, ein Höchstlimit für die Nutzung der Bandbreite Ihrer Kamera zu setzen, um eine Netzwerküberlastung zu vermeiden. Der Standardwert von 15 fps ist normalerweise ein guter Kompromiss für die meisten Überwachungsszenarien.' },
        { actionColumn: 'Video Bitrate', descriptionColumn: 'Dieser Wert legt fest wie viele Informationen in einen Videostream gepackt werden können. Ein erhöhen der Bitrate wird auch die Bandbreite massiv erhöhen, um den Stream im lokalen Netzwerk anzusehen. Auch die Dateigrößen der Videoaufnahmen werden sich erhöhen. Ein signifikanter Vorteil in der Bildqualität wird nur erreicht, wenn das Video viele kleine feine Details aufweist. Normalerweise wird empfohlen diesen Wert bei den Standardeinstellungen von 1024-2048kbps zu belassen.' },
        { actionColumn: 'Videocodierung', descriptionColumn: 'Einstellen, um die gesetzte Bitrate zu halten, oder die Kamera eigenständig eine geringere Bitrate auswählen lassen (z.B. arbeitet Ihre Kamera während der Nacht oft mit einer geringeren Bitrate). Das hat Auswirkungen auf die Dateigröße der aufgenommenen Videos, als auch auf die genutzte Bandbreite des Livestreams. Wir empfehlen, dass Sie die variable Bitrate nutzen.' },
        { actionColumn: 'Bildqualität', descriptionColumn: 'Stellen Sie die JPG Qualität jedes Frames im h.264 Video ein. Sie werden das beste Ergebnis erhalten, wenn Sie den Wert bei 3 belassen.' },
        { actionColumn: 'Keyframe Interval', descriptionColumn: 'Ein Keyframe (I-Frame) ist ein in seiner Gesamtheit codierter Frame, wie ein JPEG Bild. P-frames sind "vorausgesagt", das heißt, Sie enthalten nur die Veränderungen von einem Frame zum nächsten Frame. Daher sind I-Frames typischerweise 10x so groß wie P-Frames, desto weniger Sie haben, desto mehr Kompression ist möglich. Als Kompromiss zwischen Bildqualität und benötigter Bandbreite wird empfohlen, 1 Keyframe pro Sekunde zu erzeugen. Mit dem Standardintervall von 20 frames und einer Framerate von 15fps wird Ihre Kamera ein I-Frame alle 1,33s (20 Frames/15 Frames/s) senden.' },
        { actionColumn: 'h.264 Video Plugin', descriptionColumn: 'Hier können Sie das Plugin auswählen, mit dem die Weboberfläche den h.264 Stream wiedergeben soll. Das Standard Plugin ist Flash. Der Quicktime Player oder ActiveX Player stehen optional zur Verfügung. Grundsätzlich empfehlen wir das Flash Plugin zu nutzen.' },
        { actionColumn: 'MotionJPEG Video Modus', descriptionColumn: 'Wenn Sie dem MJPEG Stream nutzen, können Sie zwischen den beiden Modi - ein "echter"" MJPEG Stream (empfohlen) oder einem "simulierten" Stream.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

VideoTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(VideoTable)