import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/Alarm/Areas/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/AlarmLivestreamPerm';
import UpdateBox from 'components/Web_User_Interface/1440p_Series/Alarm/Areas/PrimaryBoxUpdates';
import InfoBox from 'components/Web_User_Interface/1440p_Series/Alarm/Areas/PrimaryBoxInfo';
import AreasTable from 'components/Web_User_Interface/1440p_Series/Alarm/Areas/areasTable';
import YoutubeVideoCardAlarm from 'components/Web_User_Interface/1440p_Series/Alarm/Actions/YoutubeVideoCardAlarm';
import YoutubeVideoCardAlarmAreas from 'components/Web_User_Interface/1440p_Series/Alarm/Areas/YoutubeVideoCardAlarmAreas';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  SuccessBox,
  PermissionBox,
  UpdateBox,
  InfoBox,
  AreasTable,
  YoutubeVideoCardAlarm,
  YoutubeVideoCardAlarmAreas,
  BreadCrumbs,
  SEOHelmet,
  React
};