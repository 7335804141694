import React from 'react'
import { OutboundLink } from 'gatsby-plugin-gtag'

import {
  EuiImage,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'

import ivImage from '../../../../../../images/Software/Windows/InstarVision/Advanced/License/Image003.png'


export default function ImageCard() {

  return (
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem grow={false}>
            <EuiImage
              hasShadow
              allowFullScreen
              alt="InstarVision v2 for Windows"
              src={ivImage}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiText>
              <p><strong>Validieren Sie Ihre Lizenz Online oder per Email</strong></p>
              <p>Nachdem Sie InstarVision Software zum ersten Mal geöffnet haben, werden Sie aufgefordert, Ihre Software zu registrieren. Sie können auf Testversion klicken, um einen unbegrenzten Testlauf von 14 Tagen zu starten. <OutboundLink href="https://www.instar.com/produkte/software/instarvision-for-windows-macos.html" target="_blank" rel="noopener noreferrer">www.instar.de</OutboundLink>.</p>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
  );
}
