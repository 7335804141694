import React from 'react'
import { OutboundLink } from 'gatsby-plugin-gtag'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Software Update">
        <EuiText>
          <p>
            Die INSTAR Software Abteilung arbeitet konstant daran die Erfahrung mit unseren Produkten zu verbessern. Überprüfen Sie hier ob die Software Ihrer Kamera noch aktuell ist. Alternativ können Sie auch unseren <OutboundLink href="https://www.instar.com/support/downloads" target="_blank" rel="noopener noreferrer">Downloadbereich</OutboundLink> besuchen, um die neueste Software und deren Changelog zu finden.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

