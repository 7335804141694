import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Innenkameras/">
                  <StaticImage
                    src='../../images/Search/D_SearchThumb_IndoorCam.webp'
                    alt="Innenkameras"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Innenkameras"
              description="INSTAR IP-Kameras für die Innenraumüberwachung mit IR-Nachtsicht, E-Mail- und Push-Benachrichtigung, Alarm-Videoaufzeichnung und FTP-Upload."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Innenkameras/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Aussenkameras/">
                  <StaticImage
                    src='../../images/Search/D_SearchThumb_OutdoorCam.webp'
                    alt="Aussenkameras"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Aussenkameras"
              description="INSTAR IP-Kameras für die Außenüberwachung mit IR-Nachtsicht, E-Mail- und Push-Benachrichtigung, Alarm-Videoaufzeichnung und FTP-Upload."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Aussenkameras/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Desktop_Software/">
                  <StaticImage
                    src='../../images/Search/D_SearchThumb_Software.webp'
                    alt="Desktop Software"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Desktop Software"
              description="Kompatible Desktop-Software für Ihre IP-Kameraüberwachung - die InstarVision Monitoring Station und verschiedene Software von Drittanbietern."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Desktop_Software/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Mobile_Apps/">
                  <StaticImage
                    src='../../images/Search/D_SearchThumb_Mobile.webp'
                    alt="Mobile Apps"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Mobile Apps"
              description="Kompatible iOS-, Android-, Windows Phone- und Blackberry-Software für Ihre IP-Kameraüberwachung - der InstarVision Überwachungsbegleiter."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Mobile_Apps/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Utensilien/">
                  <StaticImage
                    src='../../images/Search/D_SearchThumb_Accessories.webp'
                    alt="Zubehör"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Zubehör"
              description="Allgemeines Zubehör für Ihre INSTAR IP-Kamera - PIR- und Mikrowellen-Bewegungsmelder, Mikrofone und Infrarotscheinwerfer."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Utensilien/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Netzwerk_Utensilien/">
                  <StaticImage
                    src='../../images/Search/D_SearchThumb_NetworkAccessories.webp'
                    alt="Netzwerk Zubehör"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Netzwerk Zubehör"
              description="Netzwerkzubehör für Ihre INSTAR IP-Kamera - IN-LAN Powerline Kommunikation und Power over Ethernet (PoE) Injektoren und Switches."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Netzwerk_Utensilien/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
