import React from 'react'
import {Link} from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-gtag'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiIcon
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="PESNN3KxKNU"
                    title="INSTAR Kamera Tool"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              footer={
                <EuiText>
                <p>Wir zeigen Ihnen in diesem Video das neue INSTAR Kamera Tool Version 1.7 mit dem es jetzt auch möglich ist Videos von Ihrer INSTAR HD Kamera herunter zu laden um sich diese dann bequem unter Windows anzuschauen. Sie finden das neue Tool auf der INSTAR Webseite oder direkt hier: <Link to="/Downloadbereich/Desktop_Software/Instar_Camera_Tool/">Download</Link>. Auf <OutboundLink href="https://www.youtube.com/watch?v=PESNN3KxKNU" target="_blank" rel="noopener noreferrer">Youtube ansehen <EuiIcon type="popout" /></OutboundLink></p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}