import React from 'react'
import { Link } from 'gatsby'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

import downloadsSearchIcon from '../../../images/Search/D_Downloads_SearchThumb.webp'


export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Netzwerk_Utensilien/IN-LAN_500/">
                  <img
                    src={downloadsSearchIcon}
                    alt="IN-LAN 500"
                  />
                </Link>
              }
              title="IN-LAN 500"
              description="IN-LAN 500 und IN-LAN 500p Powerline-Kommunikation.Verbinden Sie Ihre Kamera über Ihr Stromnetz mit Ihrem lokalen Netzwerk.Kein LAN-Kabel erforderlich."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Netzwerk_Utensilien/IN-LAN_500/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Netzwerk_Utensilien/IN-PoE_1000/">
                  <img
                    src={downloadsSearchIcon}
                    alt="IN-PoE 1000"
                  />
                </Link>
              }
              title="IN-PoE 1000"
              description="Versorgen Sie Ihre PoE-Kamera über ein LAN-Kabel mit Strom, indem Sie unseren IN - PoE 1000 Injektor für Einzelkameras verwenden, oder nutzen Sie unsere IN - PoE 4000 / IN - PoE 8000 Switches."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Netzwerk_Utensilien/IN-PoE_1000/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Netzwerk_Utensilien/IN-Route_P52/">
                  <img
                    src={downloadsSearchIcon}
                    alt="IN-Route P52"
                  />
                </Link>
              }
              title="IN-Route P52"
              description="Die INSTAR IN-Route-Linie bietet eine mobile, drahtlose Lösung zum Austausch von Informationen zwischen mobilen Geräten.NAS-Lösung, sowie eine Powerbank."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Netzwerk_Utensilien/IN-Route_P52/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
