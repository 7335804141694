import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Installationsrichtlinien">
        <EuiText>
         <h4>Worauf muss ich bei der Installation achten?</h4>
         <ul>
            <li>Die ideale Montagehöhe liegt zwischen 1,80 und 2,50 Metern (obere Grafik, rechts).</li>
            <li>Achten Sie darauf, dass Sie Bewegungsmelder möglichst quer zur Bewegungsrichtung montieren (obere Grafik, rechts). Bewegungen, die direkt auf einen Bewegungsmelder zugehen, werden eher spät erkannt (untere Grafik, rechts).</li>
            <li>Bewegungsmelder erkennen keine Bewegungen hinter Glas.</li>
            <li>Bewegungsmelder dürfen nicht der direkten Sonneneinstrahlung ausgesetzt werden.</li>
            <li>Wenn möglich, Bewegungsmelder nicht auf Wärmequellen und Glasscheiben richten.</li>
            <li>Im Sichtfeld des Melders sollten sich keine Objekte befinden, die die Temperatur schnell ändern (Elektroherd, Kühlschränke, Faxgeräte usw.) oder sich bewegende Objekte wie über den Heizkörper wehende Vorhänge.</li>
            <li>Es wird nicht empfohlen, den Melder an Orten zu installieren, an denen Luft strömt (Lüftung, Luftkanäle, undichte Fenster usw.). Auch plötzliche Zirkulationen von warmer Luft, z. B. durch Heizgeräte, können unter ungünstigen Umständen Bewegungsmelder aktivieren. </li>
            <li>Bei Fenstern besteht die Gefahr, dass plötzlich einfallendes Sonnenlicht einen Alarm auslöst. Generell gilt, dass der Sensor nachts zuverlässiger arbeitet und in der Kälte des Winters die beste Reichweite erreicht!</li>
            <li>Es sollte auch darauf geachtet werden, dass sich keine bewegten Objekte im Wirkungsbereich des IR-Detektors befinden (Aquarium, Blumen, etc.).</li>
            <li>Pflanzen neigen dazu, tagsüber die Wärme der Sonne "aufzusaugen". Blätter sind in der Regel oben wärmer als auf der Unterseite. Dieses Temperaturgefälle kann in Kombination mit Wind einen Baum oder eine Hecke in einen <em>Disco-Ball</em> verwandeln. Besonders am Ende des Tages, wenn die Umgebungsluft schnell abkühlt.</li>
            <li>Hecken, hohe Gräser sowie gestrichene (nicht weiße) Wände des Gebäudes, an dem Sie Ihren Sensor angebracht haben, geben die Wärme langsam ab. Aufsteigende warme Luft vor einem viel kälteren Hintergrund kann Fehlalarme auslösen.</li>
         </ul>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox