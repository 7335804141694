import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class ConfigTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "numberColumn", title: "#" },
        { name: "componentColumn", title: "Komponente" },
        { name: "functionColumn", title: "Funktion" }
      ],
      rows: [
        { numberColumn: '1', componentColumn: 'Relay Jumper', functionColumn: 'Allows you to set the relay to “Normally Open” (1&2) or “Normally Closed” (2&3). In NC mode an interruption in the connection to the sensor, e.g. when it is damaged, will lead to an alarm. NC is the standard mode.' },
        { numberColumn: '2', componentColumn: 'Alarm Indicator Jumper', functionColumn: 'Set the Jumper to the pins 2&3 to deactivate the red alarm indicator LED (see 7, below).' },
        { numberColumn: '3', componentColumn: 'Tamper Switch', functionColumn: 'Reserved holes for screw mounting (Recommended to use bracket mouting).' },
        { numberColumn: '4', componentColumn: 'Connection Terminal', functionColumn: 'Connector for the 12V power supply + alarm output.' },
        { numberColumn: '5', componentColumn: 'Pulse Compensation Jumper', functionColumn: 'In case your sensor triggers too many false alarms, please adjust the sensors sensitivity - Jumper on Pin 1&2 equals a high sensitivity, 2&3 an average sensitivity and removing the jumper leads to a low sensitivity.' },
        { numberColumn: '6', componentColumn: 'IR Sensor', functionColumn: 'Dual element pyroelectric detector.' },
        { numberColumn: '7', componentColumn: 'Alarm Indicator', functionColumn: 'The red shows LED the alarm state of the sensor.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

ConfigTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(ConfigTable)