import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'Übersicht',
      href: '/de/Aussenkameras/IN-2905_V2/',
    },
    {
      text: 'Spezifikationen',
      href: '/de/Aussenkameras/IN-2905_V2/Technische_Spezifikationen/',
    },
    {
      text: 'Handbuch',
      href: '/de/Aussenkameras/IN-2905_V2/Handbuch/',
    },
    {
      text: '◄ Aussenkameras',
      href: '/de/Aussenkameras/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        aria-label="INSTAR IN-2905 V2 Online Usermanual Chapters"
      />
      <EuiSpacer />
    </Fragment>
  );
}