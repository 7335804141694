import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'AVM Fritzbox',
      href: '/de/Fernzugriff/Port_Weiterleitung/AVM_Fritzbox/',
    },
    {
      text: 'D-Link',
      href: '/de/Fernzugriff/Port_Weiterleitung/D-Link/',
    },
    {
      text: 'Digitalisierungsbox Smart',
      href: '/de/Fernzugriff/Port_Weiterleitung/Digitalisierungsbox/',
    },
    {
      text: 'Linksys',
      href: '/de/Fernzugriff/Port_Weiterleitung/Linksys/',
    },
    {
      text: 'Netgear',
      href: '/de/Fernzugriff/Port_Weiterleitung/Netgear/',
    },
    {
      text: 'Pirelli',
      href: '/de/Fernzugriff/Port_Weiterleitung/Pirelli/',
    },
    {
      text: 'Telekom Speedport',
      href: '/de/Fernzugriff/Port_Weiterleitung/Telekom_Speedport/',
    },
    {
      text: 'TP-Link',
      href: '/de/Fernzugriff/Port_Weiterleitung/TP-Link/',
    },
    {
      text: 'Vodafone Easybox',
      href: '/de/Fernzugriff/Port_Weiterleitung/Vodafone_Easybox/',
    },
    {
      text: 'Zyxel Speedlink',
      href: '/de/Fernzugriff/Port_Weiterleitung/Zyxel_Speedlink/',
    },
    {
      text: '◄ Port Weiterleitung',
      href: '/de/Fernzugriff/Port_Weiterleitung/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={11}
        aria-label="Use a Third Party DDNS services with your INSTAR IP Camera"
      />
      <EuiSpacer />
    </Fragment>
  );
}