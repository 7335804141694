import { EuiSpacer } from '@elastic/eui';
import VideoCard from 'components/Advanced_User/Videos/video-hd-firmware-recover-winxp';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  VideoCard,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};