import React from 'react'
import { Link } from 'gatsby'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

import products2905SearchIcon from '../../../images/Search/P_SearchThumb_IN-2905_Downloads.webp'
import products2908SearchIcon from '../../../images/Search/P_SearchThumb_IN-2908_Downloads.webp'
import products4010SearchIcon from '../../../images/Search/P_SearchThumb_IN-4010_Downloads.webp'
import products4011SearchIcon from '../../../images/Search/P_SearchThumb_IN-4011_Downloads.webp'

import products5905HDSearchIcon from '../../../images/Search/P_SearchThumb_IN-5905HD_Downloads.webp'
import products5907HDSearchIcon from '../../../images/Search/P_SearchThumb_IN-5907HD_Downloads.webp'
import products7011HDSearchIcon from '../../../images/Search/P_SearchThumb_IN-7011HD_Downloads.webp'
import products9008HDSearchIcon from '../../../images/Search/P_SearchThumb_IN-9008HD_Downloads.webp'
import products9010HDSearchIcon from '../../../images/Search/P_SearchThumb_IN-9010HD.webp'
import products9020HDSearchIcon from '../../../images/Search/P_SearchThumb_IN-9020HD.webp'
import products9408WQHDSearchIcon from '../../../images/Search/P_SearchThumb_IN-9408WQHD_Downloads.webp'
import products9420WQHDSearchIcon from '../../../images/Search/P_SearchThumb_IN-9420WQHD_Downloads.webp'


export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Aussenkameras/IN-9420_WQHD/">
                  <img
                    src={products9420WQHDSearchIcon}
                    alt="IN-9420 WQHD"
                  />
                </Link>
              }
              title="IN-9420 WQHD"
              description="Die IN-9420 2k+ (WQHD) ist die erste INSTAR IP-Überwachungskamera für den Außenbereich, die unseren neuen Sony 1440p / WQHD STARVIS Sensor verwendet."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Aussenkameras/IN-9420_WQHD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Aussenkameras/IN-9408_WQHD/">
                  <img
                    src={products9408WQHDSearchIcon}
                    alt="IN-9408 WQHD"
                  />
                </Link>
              }
              title="IN-9408 WQHD"
              description="Die IN-9408 2k+ (WQHD) ist die erste INSTAR IP-Überwachungskamera für den Außenbereich, die unseren neuen Sony 1440p / WQHD STARVIS Sensor verwendet."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Aussenkameras/IN-9408_WQHD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Aussenkameras/IN-9020_HD/">
                  <img
                    src={products9020HDSearchIcon}
                    alt="IN-9020 Full HD"
                  />
                </Link>
              }
              title="IN-9020 Full HD"
              description="Die IN-9020 HD ist die erste INSTAR Outdoor IP-Überwachungs-PTZ-Kamera, die unseren neuen Panasonic 1080p / fullHD Bildsensor und einen Panasonic PIR-Sensor nutzt."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Aussenkameras/IN-9020_HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Aussenkameras/IN-9010_HD/">
                  <img
                    src={products9010HDSearchIcon}
                    alt="IN-9010 Full HD"
                  />
                </Link>
              }
              title="IN-9010 Full HD"
              description="Die IN-9010 HD ist die erste INSTAR Outdoor IP-Überwachungs-PTZ-Kamera, die unseren neuen Panasonic 1080p / fullHD Bildsensor nutzt."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Aussenkameras/IN-9010_HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Aussenkameras/IN-9008_HD/">
                  <img
                    src={products9008HDSearchIcon}
                    alt="IN-PoE"
                  />
                </Link>
              }
              title="IN-9008 Full HD"
              description="Die IN-9008 HD ist die erste INSTAR Outdoor IP-Überwachungskamera, die unseren neuen Panasonic 1080p / fullHD Bildsensor nutzt."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Aussenkameras/IN-9008_HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Aussenkameras/IN-7011_HD/">
                  <img
                    src={products7011HDSearchIcon}
                    alt="IN-7011 HD"
                  />
                </Link>
              }
              title="IN-7011 HD"
              description="Die IN-7011 HD ist eine wetterfeste Dome-Kamera, die im Innen- und Außenbereich eingesetzt werden kann. Ein Motor ermöglicht die Änderung der Blickrichtung des Objektivs."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Aussenkameras/IN-7011_HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Aussenkameras/IN-5907_HD/">
                  <img
                    src={products5907HDSearchIcon}
                    alt="IN-5907 HD"
                  />
                </Link>
              }
              title="IN-5907 HD"
              description="Die IN-5907 HD ist eine wetterfeste und kabellose Außenkamera. Dank der eingebauten Hochleistungs-Infrarot-Dioden können Sie bis tief in die Nacht hinein sehen."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Aussenkameras/IN-5907_HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Aussenkameras/IN-5905_HD/">
                  <img
                    src={products5905HDSearchIcon}
                    alt="IN-5905 HD"
                  />
                </Link>
              }
              title="IN-5905 HD"
              description="Sie suchen eine kleine Außenkamera? Die brandneue IN-5905HD, im Grunde eine deutlich kleinere Version unseres Flaggschiffs IN-5907HD, ist genau das, wonach Sie suchen!"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Aussenkameras/IN-5905_HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Aussenkameras/IN-4011/">
                  <img
                    src={products4011SearchIcon}
                    alt="IN-4011"
                  />
                </Link>
              }
              title="IN-4011"
              description="Die IN-4011 ist das Nachfolgemodell der Dome-Kamera IN-4010v2 und bietet dank der neuen leistungsstarken PTZ-Steuerplatine erweiterte Schwenk- und Neigefunktionen."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Aussenkameras/IN-4011/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Aussenkameras/IN-4010_V2/">
                  <img
                    src={products4010SearchIcon}
                    alt="IN-4010"
                  />
                </Link>
              }
              title="IN-4010"
              description="Die IN-4010 v2 ist das Nachfolgemodell der Dome-Kamera IN-4010 und bietet dank der neuen leistungsstarken PTZ-Steuerplatine erweiterte Schwenk- und Neigefunktionen."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Aussenkameras/IN-4010_V2/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Aussenkameras/IN-4009/">
                  <img
                    src={products4010SearchIcon}
                    alt="IN-4009"
                  />
                </Link>
              }
              title="IN-4009"
              description="Die IN-4009 ist der kleine Bruder unserer Dome-Kamera IN-4010 und bietet dank der neuen leistungsstarken PTZ-Steuerplatine erweiterte Schwenk- und Neigefunktionen."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Aussenkameras/IN-4009/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Aussenkameras/IN-2908/">
                  <img
                    src={products2908SearchIcon}
                    alt="IN-2908"
                  />
                </Link>
              }
              title="IN-2908"
              description="Der große Bruder der IN-2905v2 - stärkere Infrarot-Nachtsicht und ein dreifacher optischer Zoom machen dieses Kameramodell zu einem klaren Sieger."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Aussenkameras/IN-2908/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Aussenkameras/IN-2905_V2/">
                  <img
                    src={products2905SearchIcon}
                    alt="IN-2905"
                  />
                </Link>
              }
              title="IN-2905"
              description="Die IN-2905 v2 ist der Nachfolger der beliebten IN-2905. Dutzende von Upgrades machen diese Kamera auf Augenhöhe mit der High-End-Linie der INSTAR-Kameras."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Aussenkameras/IN-2905_V2/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Aussenkameras/IN-2904/">
                  <img
                    src={products2905SearchIcon}
                    alt="IN-2904"
                  />
                </Link>
              }
              title="IN-2904"
              description="Die IN-2904 ist eine kompakte Lösung für die Außenüberwachung. Der Kamerakopf ist mit IR-Dioden für Infrarot-Nachtsicht ausgestattet."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Aussenkameras/IN-2904/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
