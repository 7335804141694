import { EuiSpacer } from '@elastic/eui';
import VideoCard from 'components/Motion_Detection/Videos/video-ftp-server-setup';
import NavButtons from 'components/Motion_Detection/NavButtons';
import ForumBox from 'components/Motion_Detection/FTP_Server_Setup/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  VideoCard,
  NavButtons,
  ForumBox,
  BreadCrumbs,
  SEOHelmet,
  React
};