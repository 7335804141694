import React from 'react'
import {Link} from 'gatsby'

import {
  EuiCard,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import ivIPhone from "../../../images/Motion_Detection/SD_Card_Access/IPhone_App_NEW_(20).webp"
import ivWinPhone from "../../../images/Motion_Detection/SD_Card_Access/WP_App15.webp"


export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/iOS/InstarVision/">
                  <img
                    src={ivIPhone}
                    alt="InstarVision iPhone - The Recording Menu"
                  />
                </Link>
              }
              title='InstarVision iPhone'
              description={
                <p>Aufnahme Menü</p>
              }
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/iOS/InstarVision/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Windows/InstarVision/Windows_Phone/">
                  <img
                    src={ivWinPhone}
                    alt="InstarVision Windows Phone - The Recording Menu"
                  />
                </Link>
              }
              title='InstarVision Windows Phone'
              description={
                <p>Aufnahme Menü</p>
              }
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/InstarVision/Windows_Phone/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
