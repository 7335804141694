import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function LatestArticle() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1440p_Serie_CGI_Befehle/Alarm_Menu/Aktionen/">
                  <StaticImage
                    src='../../../images/1440p_Series_CGI_List/Alarm_Menu/1440pCGIs-WebUI_Alarm_Actions.png'
                    alt="1440p Series CGI List - Alarm Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Actions"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1440p_Serie_CGI_Befehle/Alarm_Menu/Object_Erkennung/">
                  <StaticImage
                    src='../../../images/1440p_Series_CGI_List/Alarm_Menu/1440pCGIs-WebUI_Alarm_Areas.png'
                    alt="1440p Series CGI List - Alarm Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Object Erkennung"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1440p_Serie_CGI_Befehle/Alarm_Menu/Bereiche/">
                  <StaticImage
                    src='../../../images/1440p_Series_CGI_List/Alarm_Menu/1440pCGIs-WebUI_Alarm_Areas.png'
                    alt="1440p Series CGI List - Alarm Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Areas"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1440p_Serie_CGI_Befehle/Alarm_Menu/Push_Dienst/">
                  <StaticImage
                    src='../../../images/1440p_Series_CGI_List/Alarm_Menu/1440pCGIs-WebUI_Alarm_Push.png'
                    alt="1440p Series CGI List - Alarm Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Push Service"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1440p_Serie_CGI_Befehle/Alarm_Menu/Zeitplan/">
                  <StaticImage
                    src='../../../images/1440p_Series_CGI_List/Alarm_Menu/1440pCGIs-WebUI_Alarm_Schedule.png'
                    alt="1440p Series CGI List - Alarm Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Schedule"
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
