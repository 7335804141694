import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Portweiterleitung & DDNS">
        <EuiText>
          <p>ABei allen diesen Übertragungsmodi müssen Sie den HTTP- (oder RTSP-) Port der Software an den Server weiterleiten, auf dem das Surveillance Center ausgeführt wird. Weitere Informationen finden Sie in unserer <strong><a href="/de/Fernzugriff/Port_Forwarding">Weiterleitungsinstruktion</a></strong>. Hinweis: In dieser Anleitung leiten wir einen Port direkt an jede Kamera weiter, auf die Sie zugreifen möchten. Im Falle des Surveillance Center Senders müssen wir nur einen Port (HTTP oder RTSP) direkt an den Server weiterleiten, auf dem die Software läuft und können auf alle vom Surveillance Center verwalteten Kamerastreams zugreifen! Denken Sie daran, dass Sie weiterhin die <strong><a href="/de/Fernzugriff/The_DDNS_Service">DDNS-Adresse</a></strong> Ihrer Kamera verwenden können, indem Sie einfach die DDNS-Adresse Ihrer Kamera eingeben wird im selben Netzwerk wie das Surveillance Center betrieben) und fügen Sie den Port hinzu, den Sie an Ihren Server weitergeleitet haben. Z.B. Wenn Ihre Kamera aus dem Internet über die Adresse <strong>abcd12.ddns-instar.de:8081</strong> erreichbar ist und Sie den Port 8082 an den Server weitergeleitet haben, geben Sie <strong>http: //abcd12.ddns- instar.de:8082</strong> um auf die Software-Webbenutzeroberfläche zuzugreifen!</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

