import { EuiSpacer } from '@elastic/eui';
import APIv2Box from 'components/Frequently_Asked_Question/WQHD_MQTTv5_Alarmserver_APIv2_NodeRED/PrimaryBox';
import NavButtons from 'components/Software/Other_Platforms/NavButtons';
import ForumBox from 'components/Software/Other_Platforms/Homematic_IP/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  APIv2Box,
  NavButtons,
  ForumBox,
  BreadCrumbs,
  SEOHelmet,
  React
};