import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Smarthome/Loxone/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_Loxone.webp'
                    alt="Loxone"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Loxone"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Smarthome/Loxone/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Smarthome/Homee/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_homee.webp'
                    alt="homee"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="homee"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Smarthome/Homee/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Smarthome/Homematic/MQTTv5/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_Homematic.webp'
                    alt="Homematic"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Homematic"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Smarthome/Homematic/MQTTv5/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Smarthome/Athom_Homey/MQTTv5/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Homey.webp'
                    alt="Athom Homey"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Athom Homey"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Smarthome/Athom_Homey/MQTTv5/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Smarthome/IFTTT/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_IFTTT.webp'
                    alt="IFTTT"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IFTTT"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Smarthome/IFTTT/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Smarthome/IP_Symcon/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Symcon.webp'
                    alt="IP Symcon"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IP Symcon"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Smarthome/IP_Symcon/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Smarthome/OpenHAB/MQTTv5/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_OpenHAB.webp'
                    alt="OpenHAB"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="OpenHAB"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Smarthome/OpenHAB/MQTTv5/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Smarthome/FHEM/MQTTv5/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_FHEM.webp'
                    alt="FHEM"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="FHEM"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Smarthome/FHEM/MQTTv5/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Smarthome/IOBroker/MQTTv5/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_ioBroker.webp'
                    alt="ioBroker"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="ioBroker"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Smarthome/IOBroker/MQTTv5/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Smarthome/Home_Assistant/MQTTv5/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_HomeAssistant.webp'
                    alt="Home Assistant"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Home Assistant"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Smarthome/Home_Assistant/MQTTv5/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Smarthome/Node-RED/MQTTv5/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_Node-RED.webp'
                    alt="Node-RED"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Node-RED"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Smarthome/Node-RED/MQTTv5/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Smarthome/Logitech_Harmony/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Harmony.webp'
                    alt="Logitech Harmony"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Logitech Harmony"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Smarthome/Logitech_Harmony/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Erweitert/Alexa_Echo5_and_Monocle_Gateway/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_Alexa.webp'
                    alt="Alexa and Monocle Gateway"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Alexa und das Monocle Gateway"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Erweitert/Alexa_Echo5_and_Monocle_Gateway/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
