import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class FeatureTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "numberColumn", title: "#" },
        { name: "componentColumn", title: "Komponente" },
        { name: "functionColumn", title: "Funktion" },
      ],
      rows: [
        { numberColumn: '01', componentColumn: 'Helligkeitssensor', functionColumn: 'Dämmerungsschalter für IR-LEDs' },
        { numberColumn: '02', componentColumn: 'Infrarot-LEDs', functionColumn: '6 IR-LEDs (unsichtbar, 940nm) und 4 IR-LEDs (leicht rötlich, 850nm)' },
        { numberColumn: '03', componentColumn: 'Objektiv', functionColumn: 'Weitwinkel (Brennweite 4,3 mm / Blickwinkel: ~ 90º dia.)' },
        { numberColumn: '04', componentColumn: 'Mikrofon', functionColumn: 'Für Geräuscherkennung / Audioaufnahme / Zwei-Wege-Audiokommunikation' },
        { numberColumn: '05', componentColumn: 'PIR-Wärmesensor', functionColumn: 'Integrierter Passiv-Infrarot (PIR) Bewegungsmelder' },
        { numberColumn: '06', componentColumn: 'Status LED', functionColumn: 'Rot: Power-LED; Blau: Netzwerkstatus-LED. Beide können deaktiviert werden. (s. LED Modi, unten)' },
        { numberColumn: '07', componentColumn: 'Lautsprecher', functionColumn: 'Integrierter Lautsprecher' },
        { numberColumn: '08', componentColumn: 'Antenne', functionColumn: 'Interne Antenne ( 3dB Antenne)' },
        { numberColumn: '09', componentColumn: 'Kamera Label', functionColumn: 'P2P ID, LAN MAC Adresse und Seriennummer' },
        { numberColumn: '10', componentColumn: 'WPS / Reset', functionColumn: '3 Sekunden für WPS-WLAN-Setup / 20 Sek. für Reset' },
        { numberColumn: '11', componentColumn: 'SD Karteleser', functionColumn: 'Steckplatz für MicroSD/SDXC-Karten (max. 256 GB - 32 GB im Lieferumfang enthalten) - im Inneren der Kamera' },
        { numberColumn: '12', componentColumn: 'Montagegewinde / Halterung', functionColumn: 'Die Halterung kann als Wand- oder Deckenhalterung verwendet werden, wenn sie mit einem der beiden Befestigungsgewinde verbunden wird' },
        { numberColumn: '13', componentColumn: 'Stromanschluss', functionColumn: '5V / 2A DC - Anschluss Ø : 1,35mm (innen) / 3,5mm (außen)' },
        { numberColumn: '14', componentColumn: 'Netzwerk Port', functionColumn: 'RJ45 LAN Port / Anschluss für Cat5e LAN-Kabel oder höher' },
        { numberColumn: '15', componentColumn: 'Schraubensicherung', functionColumn: 'Für Tisch-, Wand- und Deckenmontage' },
        { numberColumn: '16', componentColumn: 'Magnetfuß', functionColumn: 'Eingebauter Magnet für die Installation über die mitgelieferte Metall-Befestigungsplatte.' }
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

FeatureTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(FeatureTable)