import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Alarm Schedule">
        <EuiText>
          <p>
            Legen Sie hier Ihren Zeitplan fest, an dem die (<Link to="/Web_User_Interface/720p_Serie/Alarm/Aktionen/">Bewegungserkennung</Link> für die <Link to="/Web_User_Interface/720p_Serie/Alarm/Aktionen/">Bereiche</Link> aktiv sein soll. Jede orange Box Symbolisiert dabei eine Aktivitätsphase von 30 Minuten. Bitte stellen Sie sicher, dass Sie die korrekte Zeitzone einstellen, die auch von Ihrer Kamera genutzt wird. Denken Sie an die (<Link to="/Web_User_Interface/720p_Serie/System/Datum_Uhrzeit/">Zeitumstellung</Link>!
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

