import React from 'react'

import {Link} from 'gatsby'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiButton
} from '@elastic/eui'


import ivImage01 from '../../../../../images/Software/Windows/InstarVision/Advanced/Image003_small.png'
import ivImage02 from '../../../../../images/Software/Windows/InstarVision/Advanced/Image050-Log_small.png'
import ivImage03 from '../../../../../images/Software/Windows/InstarVision/Advanced/Image049_Export_small.png'


export default function ImageCard() {

  return (
    <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
      <EuiFlexItem>
        <EuiCard
          textAlign="left"
          image={
            <div>
              <img
                src={ivImage01}
                alt="Geben Sie Ihren Lizenzschlüssel ein"
              />
            </div>
          }
          title="Lizenz"
          description="Geben Sie Ihren Lizenzschlüssel ein"
          content={
            <EuiText>
              <p>Geben Sie Ihren Lizenzschlüssel ein oder Upgraden Sie Ihre Installation</p>
            </EuiText>
          }
          footer={
            <EuiFlexGroup justifyContent="flexEnd">
              <EuiFlexItem grow={false}>
                <Link to="/Software/Windows/InstarVision/Advanced/License/">
                  <EuiButton size="s" fill>
                    mehr...
                  </EuiButton>
                </Link>
              </EuiFlexItem>
            </EuiFlexGroup>
          }
        />
        </EuiFlexItem>
        <EuiFlexItem>
        <EuiCard
          textAlign="left"
          image={
            <div>
              <img
                src={ivImage02}
                alt="Überprüfen Sie das System Log."
              />
            </div>
          }
          title="Log Info"
          description="Überprüfen Sie das System Log."
          content={
            <EuiText>
              <p>Überprüfen Sie das System Log.</p>
            </EuiText>
          }
          footer={
            <EuiFlexGroup justifyContent="flexEnd">
              <EuiFlexItem grow={false}>
                <Link to="/Software/Windows/InstarVision/Advanced/Log/">
                  <EuiButton size="s" fill>
                    mehr...
                  </EuiButton>
                </Link>
              </EuiFlexItem>
            </EuiFlexGroup>
          }
        />
        </EuiFlexItem>
        <EuiFlexItem>
        <EuiCard
          textAlign="left"
          image={
            <div>
              <img
                src={ivImage03}
                alt="Export/Import der Systemeinstellungen"
              />
            </div>
          }
          title="Import / Export"
          description="Export/Import der Systemeinstellungen"
          content={
            <EuiText>
              <p>Export/Import der Systemeinstellungen</p>
            </EuiText>
          }
          footer={
            <EuiFlexGroup justifyContent="flexEnd">
              <EuiFlexItem grow={false}>
                <Link to="/Software/Windows/InstarVision/Advanced/Export/">
                  <EuiButton size="s" fill>
                    mehr...
                  </EuiButton>
                </Link>
              </EuiFlexItem>
            </EuiFlexGroup>
          }
        />
        </EuiFlexItem>
    </EuiFlexGroup>
  );
}
