import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="System Reboot">
        <EuiText>
          <p>
            Nutzen Sie diese Funktion, um Ihre Kamera sicher neuzustarten. Es wird nicht empfohlen, einfach das Kabel ab- und wieder anzustecken (Diese Aktionen können sowohl für die laufenden Prozesse als auch für die interne SD-Karte schädlich sein). Bitte starten Sie immer über die Weboberfläche neu. Nutzen Sie den Hardware-Neustart nur, falls Sie die Weboberfläche nicht mehr erreichen können.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

