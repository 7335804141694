import React from 'react'

import {
  EuiAccordion,
  EuiText,
  EuiSpacer,
  EuiPanel
} from '@elastic/eui'


function CommandAccordion() {
  return (
    <React.Fragment>
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion1"
          buttonContent="Live video"
          paddingSize="l"
          style={{height: "fit-content"}}>
            <EuiPanel color="subdued">
              <EuiText style={{height: 200}}>
                <h5>Alexa Befehle:</h5>
                <ul>
                  <li>Alexa,zeige die [Kamera Name].</li>
                  <li>Alexa,blende [Kamera Name] aus.</li>
                </ul>
              </EuiText>
            </EuiPanel>
          </EuiAccordion>
        </EuiPanel>
  
        <EuiSpacer />
  
      
        <EuiPanel hasBorder>
          <EuiAccordion
            id="accordion2"
            buttonContent="Turn on/off alarm of camera"
            buttonContentClassName="eui-textTruncate"
            paddingSize="l">
            <EuiPanel color="subdued">
              <EuiText style={{height: 200}}>
                <h5>Alexa Befehle:</h5>
                <ul>
                  <li>Alexa,zeige die [Kamera Name].</li>
                  <li>Alexa,blende [Kamera Name] aus.</li>
                </ul>
              </EuiText>
            </EuiPanel>
          </EuiAccordion>
        </EuiPanel>
    </React.Fragment>
  );
}

export default CommandAccordion


// export default () => (
//   <React.Fragment>
//     <EuiPanel hasBorder>
//       <EuiAccordion
//         id="accordion1"
//         buttonContent="Live video"
//         paddingSize="l"
//         style={{height: "fit-content"}}>
//           <EuiPanel color="subdued">
//             <EuiText style={{height: 200}}>
//               <h5>Alexa Befehle:</h5>
//               <ul>
//                 <li>Alexa,zeige die [Kamera Name].</li>
//                 <li>Alexa,blende [Kamera Name] aus.</li>
//               </ul>
//             </EuiText>
//           </EuiPanel>
//         </EuiAccordion>
//       </EuiPanel>

//       <EuiSpacer />

    
//       <EuiPanel hasBorder>
//         <EuiAccordion
//           id="accordion2"
//           buttonContent="Turn on/off alarm of camera"
//           buttonContentClassName="eui-textTruncate"
//           paddingSize="l">
//           <EuiPanel color="subdued">
//             <EuiText style={{height: 200}}>
//               <h5>Alexa Befehle:</h5>
//               <ul>
//                 <li>Alexa,zeige die [Kamera Name].</li>
//                 <li>Alexa,blende [Kamera Name] aus.</li>
//               </ul>
//             </EuiText>
//           </EuiPanel>
//         </EuiAccordion>
//       </EuiPanel>
//   </React.Fragment>
// );