import React from 'react'
import { OutboundLink } from 'gatsby-plugin-gtag'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="IP Configuration">
        <EuiText>
          <p>
            Ändern Sie IP-Adresse, Gateway und DNS-Server Ihrer Kamera - all diese Einstellungen werden normalerweise mit dem <OutboundLink href="https://install.instar.com/" target="_blank" rel="noopener noreferrer">Installation Wizard</OutboundLink> vorgenommen und müssen nicht geändert werden. Der HTTP-Port der Kamera muss vielleicht geändert werden, um aus dem Internet auf die Weboberfläche zugreifen zu können.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

