import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class FeatureTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "numberColumn", title: "#" },
        { name: "componentColumn", title: "Komponente" },
        { name: "functionColumn", title: "Funktion" },
      ],
      rows: [
        { numberColumn: '01', componentColumn: 'Halterung', functionColumn: 'Universell ausrichtbare Halterung für Wand- Deckenmontage.' },
        { numberColumn: '02', componentColumn: 'Helligkeitsensor', functionColumn: 'Fotodiode für den Dämmerungsschalter.' },
        { numberColumn: '03', componentColumn: 'Status LEDs', functionColumn: 'Rot: Power-LED; Blau: Netzwerkstatus-LED. Beide können deaktiviert werden. (s. LED Modi, unten)' },
        { numberColumn: '04', componentColumn: 'Dual LEDs', functionColumn: '2 High-Power IR-LEDs ( @ 850nm). 2 High-Power Flutlicht LEDs.' },
        { numberColumn: '05', componentColumn: 'Objektiv', functionColumn: 'Weitwinkel (Brennweite: 4.3mm / Blickwinkel: ~90º dia.)' },
        { numberColumn: '06', componentColumn: 'PIR Wärmesensor', functionColumn: 'Integrierter passiver Infrarot (PIR) Bewegungsmelder' },
        { numberColumn: '07', componentColumn: 'Mikrofon', functionColumn: 'Für Geräuscherkennung / Audio Aufnahme.' },
        { numberColumn: '08', componentColumn: 'MicroSD Kartenleser', functionColumn: 'Einschub für die MicroSD/SDXC Karte (max. 256GB - 32GB inkludiert) - im inneren der Kamera.' },
        { numberColumn: '09', componentColumn: 'Alarmeingang', functionColumn: 'Alarmeingang für potentialfreie Alarmsensoren.' },
        { numberColumn: '10', componentColumn: 'Alarmausgang', functionColumn: 'Alarmausgang Relais für max. 24V/1A Belastung.' },
        { numberColumn: '11', componentColumn: 'Werksreset', functionColumn: '20s gedrückt halten zum Zurücksetzen.' },
        { numberColumn: '12', componentColumn: 'Netzwerk LED', functionColumn: 'Aus: kein Strom / keine Verbindung, Blinken: Netzwerksignal.' },
        { numberColumn: '13', componentColumn: 'Netzwerkanschluss', functionColumn: 'RJ45 LAN Port / Anschluß für Cat5e LAN Kable oder besser.' },
        { numberColumn: '14', componentColumn: 'Netzteilanschluss', functionColumn: 'Für inkludiertes 12V / 1A Netzteil' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

FeatureTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(FeatureTable)