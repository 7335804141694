import React from 'react'

import Image01Quality from '../../../images/Outdoor_Cameras/IN-9008_HD/IN-9008-vs-IN-5905_resTestBig.webp'
import Image02Quality from '../../../images/Outdoor_Cameras/IN-9008_HD/IN-9008-vs-IN-5907_resTestBig.webp'
import Image03Quality from '../../../images/Outdoor_Cameras/IN-9008_HD/IN-9008-vs-IN-5907_WA_resTestBig.webp'
import Image04Quality from '../../../images/Outdoor_Cameras/IN-9008_HD/IN-9008v5905_resTestBig2.webp'
import Image05Quality from '../../../images/Outdoor_Cameras/IN-9008_HD/IN-9008v5905_resTestBig3.webp'
import Image06Quality from '../../../images/Outdoor_Cameras/IN-9008_HD/IN-9008-vs-IN-5905_Night_resTestBig.webp'

import {
  EuiImage,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'



function ImageQCards() {
    return (
        <React.Fragment>
            <EuiFlexGroup justifyContent="spaceBetween">
                <EuiFlexItem grow={false}>
                    <EuiImage
                        size="fullWidth"
                        hasShadow
                        allowFullScreen
                        caption="Click me"
                        alt="IN-9008FHD vs IN-5905HD"
                        fullScreenIconColor="light"
                        src={Image01Quality}
                    />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiImage
                        size="fullWidth"
                        hasShadow
                        allowFullScreen
                        caption="Click me"
                        alt="IN-9008FHD vs IN-5907HD"
                        fullScreenIconColor="light"
                        src={Image02Quality}
                    />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiImage
                        size="fullWidth"
                        hasShadow
                        allowFullScreen
                        caption="Click me"
                        alt="IN-9008FHD vs IN-5907HD"
                        fullScreenIconColor="light"
                        src={Image03Quality}
                    />
                </EuiFlexItem>
            </EuiFlexGroup>
    
            <EuiSpacer />
    
            <EuiFlexGroup justifyContent="spaceBetween">
                <EuiFlexItem grow={false}>
                    <EuiImage
                        size="fullWidth"
                        hasShadow
                        allowFullScreen
                        caption="Click me"
                        alt="IN-9008FHD vs IN-5905HD"
                        fullScreenIconColor="light"
                        src={Image04Quality}
                    />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiImage
                        size="fullWidth"
                        hasShadow
                        allowFullScreen
                        caption="Click me"
                        alt="IN-9008FHD vs IN-5905HD"
                        fullScreenIconColor="light"
                        src={Image05Quality}
                    />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiImage
                        size="fullWidth"
                        hasShadow
                        allowFullScreen
                        caption="Click me"
                        alt="IN-9008FHD vs IN-5905HD"
                        fullScreenIconColor="light"
                        src={Image06Quality}
                    />
                </EuiFlexItem>
            </EuiFlexGroup>
      </React.Fragment>
    );
  }

  export default ImageQCards

// export default () => (
//     <React.Fragment>
//         <EuiFlexGroup justifyContent="spaceBetween">
//             <EuiFlexItem grow={false}>
//                 <EuiImage
//                     size="fullWidth"
//                     hasShadow
//                     allowFullScreen
//                     caption="Click me"
//                     alt="IN-9008FHD vs IN-5905HD"
//                     fullScreenIconColor="light"
//                     src={Image01Quality}
//                 />
//             </EuiFlexItem>
//             <EuiFlexItem grow={false}>
//                 <EuiImage
//                     size="fullWidth"
//                     hasShadow
//                     allowFullScreen
//                     caption="Click me"
//                     alt="IN-9008FHD vs IN-5907HD"
//                     fullScreenIconColor="light"
//                     src={Image02Quality}
//                 />
//             </EuiFlexItem>
//             <EuiFlexItem grow={false}>
//                 <EuiImage
//                     size="fullWidth"
//                     hasShadow
//                     allowFullScreen
//                     caption="Click me"
//                     alt="IN-9008FHD vs IN-5907HD"
//                     fullScreenIconColor="light"
//                     src={Image03Quality}
//                 />
//             </EuiFlexItem>
//         </EuiFlexGroup>

//         <EuiSpacer />

//         <EuiFlexGroup justifyContent="spaceBetween">
//             <EuiFlexItem grow={false}>
//                 <EuiImage
//                     size="fullWidth"
//                     hasShadow
//                     allowFullScreen
//                     caption="Click me"
//                     alt="IN-9008FHD vs IN-5905HD"
//                     fullScreenIconColor="light"
//                     src={Image04Quality}
//                 />
//             </EuiFlexItem>
//             <EuiFlexItem grow={false}>
//                 <EuiImage
//                     size="fullWidth"
//                     hasShadow
//                     allowFullScreen
//                     caption="Click me"
//                     alt="IN-9008FHD vs IN-5905HD"
//                     fullScreenIconColor="light"
//                     src={Image05Quality}
//                 />
//             </EuiFlexItem>
//             <EuiFlexItem grow={false}>
//                 <EuiImage
//                     size="fullWidth"
//                     hasShadow
//                     allowFullScreen
//                     caption="Click me"
//                     alt="IN-9008FHD vs IN-5905HD"
//                     fullScreenIconColor="light"
//                     src={Image06Quality}
//                 />
//             </EuiFlexItem>
//         </EuiFlexGroup>
//   </React.Fragment>
// )