import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Innenkameras/IN-8415_WQHD/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_IN-8415WQHD.webp'
                    alt="IN-8415 WQHD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-8415 WQHD"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Innenkameras/IN-8415_WQHD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Innenkameras/IN-8403_WQHD/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_IN-8403WQHD.webp'
                    alt="IN-8403 WQHD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-8403 WQHD"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Innenkameras/IN-8403_WQHD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Innenkameras/IN-8401_WQHD/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_IN-8401WQHD.webp'
                    alt="IN-8401 WQHD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-8401 WQHD"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Innenkameras/IN-8401_WQHD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Innenkameras/IN-8015_HD/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_IN-8015HD.webp'
                    alt="IN-8015 Full HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-8015 Full HD"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Innenkameras/IN-8015_HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Innenkameras/IN-8003_HD/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_IN-8003HD.webp'
                    alt="IN-8003 Full HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-8003 Full HD"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Innenkameras/IN-8003_HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Innenkameras/IN-8001_HD/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_IN-8001HD.webp'
                    alt="IN-8001 Full HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-8001 Full HD"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Innenkameras/IN-8001_HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Innenkameras/IN-6014_HD/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_IN-6014HD.webp'
                    alt="IN-6014 HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-6014 HD"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Innenkameras/IN-6014_HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Innenkameras/IN-6012_HD/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_IN-6012HD.webp'
                    alt="IN-6012 HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-6012 HD"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Innenkameras/IN-6012_HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Innenkameras/IN-6001_HD/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_IN-6001HD.webp'
                    alt="IN-6001 HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-6001 HD"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Innenkameras/IN-6001_HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Innenkameras/IN-3011/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_IN-3011.webp'
                    alt="IN-3011"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-3011"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Innenkameras/IN-3011/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
