import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="PTZ Tour">
        <EuiText>
          <p>
            Deaktivieren Sie den Kalibrierungslauf Ihrer Kamera beim Start (deaktiviert auch die gespeicherten Positionen). Lassen Sie Ihre Kamera nach einem Neustart in eine gespeicherte Position gehen oder beschränken Sie den Bereich der Schwenk- und Neigebewegung auf Datenschutzprobleme.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

