import React from 'react'
import {Link} from 'gatsby'

import {
  EuiCard,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import routerFritz from "../../../images/Motion_Detection/Router_as_a_FTP_Server/Fritzbox7490.webp"
import routerSpeedport from "../../../images/Motion_Detection/Router_as_a_FTP_Server/Speedport-W724V.webp"
import routerQNAP from "../../../images/Motion_Detection/Router_as_a_FTP_Server/QNAP_TurboNAS_TS-259_Pro.webp"
import routerWD from "../../../images/Motion_Detection/Router_as_a_FTP_Server/WD_MyCloud.webp"
import routerSynology from "../../../images/Motion_Detection/Router_as_a_FTP_Server/Synology_NAS.webp"




export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/Router_als_FTP_Server/AVM_Fritzbox_als_FTP_Server/">
                  <img
                    src={routerFritz}
                    alt="AVM Fritzbox"
                  />
                </Link>
              }
              title='AVM Fritzbox'
              description={
                <p></p>
              }
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/Router_als_FTP_Server/AVM_Fritzbox_als_FTP_Server/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/Router_als_FTP_Server/Telekom_Speedport_als_FTP_Server/">
                  <img
                    src={routerSpeedport}
                    alt="Telekom Speedport"
                  />
                </Link>
              }
              title='Telekom Speedport'
              description={
                <p></p>
              }
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Motion_Detection/Router_als_FTP_Server/Telekom_Speedport_als_FTP_Server/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/Router_als_FTP_Server/QNAP_als_FTP_Server/">
                  <img
                    src={routerQNAP}
                    alt="QNAP TS-259"
                  />
                 </Link>
              }
              title='QNAP TS-259'
              description={
                <p></p>
              }
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/Router_als_FTP_Server/QNAP_als_FTP_Server/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/Router_als_FTP_Server/WD_MyCloud_als_FTP_Server/">
                  <img
                    src={routerWD}
                    alt="WD MyCloud"
                  />
                </Link>
              }
              title='WD MyCloud'
              description={
                <p></p>
              }
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/Router_als_FTP_Server/WD_MyCloud_als_FTP_Server/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/Router_als_FTP_Server/Synology_als_FTP_Server/">
                  <img
                    src={routerSynology}
                    alt="Synology"
                  />
                </Link>
              }
              title='Synology'
              description={
                <p></p>
              }
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/Router_als_FTP_Server/Synology_als_FTP_Server/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
