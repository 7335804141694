import React from 'react'

import {
  EuiImage,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'

import ivImage from '../../../../../images/Software/Windows/InstarVision/Layout/Image019_Settings.png'


export default function ImageCard() {

  return (
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem style={{ maxWidth: 600 }}>
            <EuiImage
              hasShadow
              allowFullScreen
              alt="InstarVision v2 for Windows"
              src={ivImage}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiText>
              <p><strong>Layout Vorlagen</strong></p>
              <p>Es gibt Layout Vorlagen, die optimiert sind für VGA Kameras mit einem 4:3 Seitenverhältnis und Vorlagen speziell für HD Kameras mit einem 16:9 Seitenverhältnis. Klicken Sie auf das Hamburger Icon (die 3 horizontalen Striche) oberhalb der Seiten Navigation für eine Schnellauswahl der jeweiligen Vorlagen.</p>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
  );
}
