import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class RemoteTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "DDNS Einstellungen" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'P2P Benutzer ID', descriptionColumn: 'Mit dem P2P-Zugang haben Sie keinen vollständigen Zugriff auf alle Funktionen Ihrer Kamera. Sie müssen jedoch keinen Port innerhalb Ihres Routers weiterleiten, und P2P funktioniert auch, wenn DDNS möglicherweise fehlschlägt - z. auf 3G / LTE oder IPv6-Zugang ohne eine öffentliche IPv4-Adresse. Scannen Sie einfach den QR-Code (unten) mit unserem Android, iPhone, iPad, Windows Phone oder Windows Metro App, um Ihre Kamera hinzuzufügen. Sollte das Hinzufügen fehlschlagen, deaktivieren/aktivieren Sie den Dienst, starten Sie die Kamera einmal neu und versuchen Sie es erneut. Ein Zugriff über die P2P ID ist alternativ auch mit dem Drittanbieter Windows Programm P2P Client möglich.' },
        { actionColumn: 'INSTAR DDNS Adresse', descriptionColumn: 'Um über das Internet auf die WebUI Ihrer Kamera zugreifen zu können, müssen Sie Ihre INSTAR DDNS-Adresse aktivieren. Bitte beachten Sie, dass Ihre Kamera möglicherweise noch eine Weile über die DDNS-Adresse verfügbar ist, nachdem Sie sie deaktiviert haben. Der Zugang wird blockiert, sobald Ihr Internet Service Provider Ihre WAN IP Adresse ändert - dies geschieht normalerweise einmal alle 24 Stunden (und in einigen Fällen, sobald Sie Ihren Internet Router neu gestartet haben). Dies ist Ihre persönliche INSTAR DDNS-Adresse. Sie können über diese Adresse auf Ihre Kamera zugreifen, nachdem Sie den HTTP-Port Ihrer Kamera weitergeleitet haben. Scannen Sie einfach den QR-Code (unten) mit unserer Android, iPhone, iPad, Windows Phone oder WINDOWS METRO App, um Ihre Kamera hinzuzufügen.' },
        { actionColumn: 'QR Code', descriptionColumn: 'Um Ihre Kamera zu unserer Android hinzuzufügen, iPhone, iPad, Windows Phone oder Windows Metro App hinzuzufügen, müssen Sie einfach den QR-Code scannen.' },
        { actionColumn: 'Dritthersteller DDNS Adresse aktivieren', descriptionColumn: 'Wenn Sie Ihre INSTAR DDNS-Adresse nicht verwenden möchten, fügen Sie einfach eine DDNS-Adresse eines Drittanbieters hinzu. Bitte kontaktieren Sie Ihren Drittanbieter für Details. Wenn Sie Ihre INSTAR DDNS-Adresse deaktivieren möchten, aktivieren Sie einfach die Adresse des Drittanbieters (denken Sie daran, dass Sie warten müssen, bis Ihr Internet-Provider Ihre WAN-IP-Adresse ändert, um den Effekt zu sehen - normalerweise alle 24 Stunden).' },
        { actionColumn: 'Port Prüfen', descriptionColumn: 'Nutzen Sie das Port Checker-Tool um den Zugriff auf Ihre DDNS Adresse zu überprüfen: 1.) Sehen Sie, ob Ihre Kamera den DDNS-Server kontaktiert hat und Ihre DDNS-Adresse aktiv ist. 2:) Testen Sie den Zugriff über das Internet auf Ihre Kamera, um zu sehen, ob Ihre Portweiterleitungsregel eingestellt und aktiv ist. Fehlerbehebung: Eine fehlgeschlagene DDNS-Prüfung zeigt, dass Ihre Kamera noch nicht mit unserem DDNS-Server in Kontakt treten konnte. Warte nur eine halbe Stunde und versuche es noch einmal. Beachten Sie, dass Ihr PC oder Ihr Router den fehlgeschlagenen ersten Versuch zwischenspeichern und weiterhin eine Fehlermeldung geben kann - starten Sie Ihren Computer und Router neu und wiederholen Sie den Test.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

RemoteTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(RemoteTable)