import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/styles'
import {
  SortingState, SelectionState, FilteringState, GroupingState,
  IntegratedFiltering, IntegratedGrouping, IntegratedSelection,
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table, TableHeaderRow, TableFilterRow, TableGroupRow,
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);
 
  
class TechSpecGrid extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
        columns: [
          { name: "category", title: "Kategorie" },
          { name: "feature", title: "Feature" },
          { name: "property", title: "Eigenschaft" }
        ],
        rows: [
          { category: 'Optik & Bildgebung', feature: 'Standard Objektiv', property: '4.3 ±5% mm (Manueller Focus) f/1.6 IR /M12 - S-Mount [1]' },
          { category: 'Optik & Bildgebung', feature: 'Blickwinkel', property: 'ca. 90° (diag.)' },
          { category: 'Optik & Bildgebung', feature: 'CMOS Sensor', property: 'Sony STARVIS IMX335 CMOS Sensor [6]' }, 
          { category: 'Optik & Bildgebung', feature: 'Sensor Format', property: '1/2.8"' },
          { category: 'Optik & Bildgebung', feature: 'Dimensionen', property: '38 x 38 (mm) PCB, 26.5 x 26.5 (mm)' },
          { category: 'Optik & Bildgebung', feature: 'Pixel Größe', property: '2.0 µm (H) × 2.0 µm (V)' },
          { category: 'Optik & Bildgebung', feature: 'Dynamikbereich', property: '72dB' },
          { category: 'Optik & Bildgebung', feature: 'Sensitivität', property: '>=2000mV' },
          { category: 'Optik & Bildgebung', feature: 'Max. Auflösung', property: '5.0 Megapixel' },
          { category: 'Optik & Bildgebung', feature: 'Absolute Anzahl an Pixel', property: '2704 (H) × 2104 (V) ca. 5.69 M Pixel' },
          { category: 'Optik & Bildgebung', feature: 'Effektive Anzahl an Pixel', property: '2616 (H) × 1964 (V) ca. 5.14 M Pixel' },
          { category: 'Optik & Bildgebung', feature: 'Anzahl an aktiven Pixel', property: '2616 (H) × 1960 (V) ca. 5.13 M Pixel' },
          { category: 'Optik & Bildgebung', feature: 'Empfohlene Anzahl an Aufnahme Pixel', property: '2592 (H) × 1944 (V) ca. 5.04 M Pixel' },
          { category: 'Optik & Bildgebung', feature: 'Unterstützte Auflösungen', property: '1440p (2560x1440 Pixel ~ 3.7MP), 1080p (1920x1080 Pixel ~ 2.1MP), 720p (1280x720 Pixel ~ 1MP)' },
          { category: 'Optik & Bildgebung', feature: 'Bit Tiefe', property: '10 / 12 bit' },
          { category: 'Optik & Bildgebung', feature: 'Hoher Dynamikbereich (HDR)', property: 'Mehrfachbelichtung HDR / Digitale Überlagerung HDR' },
          { category: 'Optik & Bildgebung', feature: 'Video Komprimierung', property: 'h.265, h.264, MJPEG, JPG' },
          { category: 'Optik & Bildgebung', feature: 'Bitrate (h.265)', property: 'bis zu 5.120 kbps' },
          { category: 'Optik & Bildgebung', feature: 'Framerate', property: '5.0MP 2592 x 1944 ca. 14fps /  4.0MP 2592 x 1520 ca. 19fps / 1080P @30fps' },
          { category: 'Optik & Bildgebung', feature: 'Parallele Streams', property: '3' },
          { category: 'Optik & Bildgebung', feature: 'Video Overlay', property: 'Datum, Zeit, Kamera Name' },
          { category: 'Optik & Bildgebung', feature: 'Video Parameter', property: 'Helligkeit, Kontrast, Sättigung, Schärfe, Gamma, Belichtung' },
          { category: 'Optik & Bildgebung', feature: 'Tag/Nacht Schaltung', property: 'Ja' },
          { category: 'Optik & Bildgebung', feature: 'Weißabgleich', property: 'Automatisch' },
          { category: 'Optik & Bildgebung', feature: 'Elektronischer Shutter', property: 'Automatisch' },
          { category: 'Optik & Bildgebung', feature: 'Flip / Spiegel Video', property: 'Ja / Ja' },
          { category: 'Nachtsicht', feature: 'Infrarot LEDs', property: '5 Hochleistungsdioden @850nm' },
          { category: 'Nachtsicht', feature: 'IR Modi', property: 'aus/an/automatisch' },
          { category: 'Nachtsicht', feature: 'max. Reichweite', property: '15-20m' },
          { category: 'Nachtsicht', feature: 'intgr. IRcut Filter', property: 'Ja (Automatisch)' },
          { category: 'Netzwerk', feature: 'Netzwerk Schnittstellen', property: '10Base-T/100Base-TX Ethernet Port / WLAN IEEE 802.11b/g/n (nicht beim PoE Modell)' },
          { category: 'Netzwerk', feature: 'Netzwerk Protokolle', property: 'TCP/IP, DHCP, PPPoE, ARP, FTP, SMTP, DNS, NTP, UPnP, HTTP, TCP, UDP, MQTT' },
          { category: 'Netzwerk', feature: 'Transfer Verschlüsselung', property: 'HTTPS. MQTTS, FTPS, sFTP' },
          { category: 'Netzwerk', feature: 'WLAN Verschlüsselung (nicht beim PoE Modell)', property: 'WPA2-PSK (TKIP, AES, CCMP)' },
          { category: 'Netzwerk', feature: 'WLAN Frequenzen (nicht beim PoE Modell)', property: '5GHz / 2.4GHz, Wi‑Fi 5 (802.11ac)' },
          { category: 'Netzwerk', feature: 'WLAN Frequenzen Bänder (nicht beim PoE Modell)', property: '2,4-2.4845GHz, 5,150-5,350/5,470 - 5,725GHz' },
          { category: 'Netzwerk', feature: 'WLAN Frequenzübertragung', property: '<16dBm @ 11b/g, <15dBm @ 11n < 18dBm @11ac' },
          { category: 'Netzwerk', feature: 'WLAN Antenne (nicht beim PoE Modell)', property: '3 dBi WLAN RP-SMA Antenne [3]' },
          { category: 'Netzwerk', feature: 'WLAN Reichweite (nicht beim PoE Modell)', property: 'ca. 8-12m' },
          { category: 'Netzwerk', feature: 'kostenlose DDNS Adresse', property: 'Ja' },
          { category: 'Netzwerk', feature: 'kostenlose P2P UID', property: 'Ja' },
          { category: 'Chipset', feature: 'AI IPCAM SoC', property: 'Novatek NT98528' },
          { category: 'Chipset', feature: 'CPU', property: 'Dual Cortex A9' },
          { category: 'Chipset', feature: 'Speicher', property: '16-bit DDR3' },
          { category: 'Chipset', feature: 'Codec', property: '8Mp20+sub stream' },
          { category: 'Chipset', feature: 'Fast Boot', property: 'Linux application < 1.2/1.5s' },
          { category: 'Chipset', feature: 'Platform AI', property: 'HW-CNN 1.5T' },
          { category: 'Chipset', feature: 'ISP', property: 'TMNR2 3DNR, SHDR, Defog, RGBIR' },
          { category: 'Chipset', feature: 'Serielller Bus', property: 'USB2.0' },
          { category: 'Features', feature: 'Zugriffsbeschränkung', property: 'Benutzername/Passwort - 3-stufige Autorisierung' },
          { category: 'Features', feature: 'Bewegungserkennung', property: 'Ja (Software Analyse + PIR)' },
          { category: 'Features', feature: 'Alarm I/O', property: 'Nein' },
          { category: 'Features', feature: 'Alarm Benachrichtigung', property: 'Email/FTP/HTTP (Alarmserver)/Push' },
          { category: 'Features', feature: 'Alarmaufnahme Vorlauf', property: 'Ja (3s)' },
          { category: 'Features', feature: 'Fotoserie', property: 'Ja (auf SD Karte und FTP Server)' },
          { category: 'Features', feature: 'SD Karte', property: 'Austauschbare microSDHC-Karte mit bis zu 256 GB (32 GB im Lieferumfang enthalten) [4]' },
          { category: 'Features', feature: 'Cloud Speicher', property: 'Ja (optional)' },
          { category: 'Features', feature: 'Pan & Tilt', property: 'Nein' },
          { category: 'Features', feature: 'Audio', property: 'Audio Ausgang (3,5-mm-Buchse für Kopfhörer/externe Lautsprecher)' },
          { category: 'Features', feature: 'Mikrofon', property: 'Integriertes Mikrofon' },
          { category: 'Features', feature: 'Intercom', property: 'Zwei-Wege-Audiokommunikation mit unserer App InstarVision' },
          { category: 'Features', feature: 'Alarmaufzeichnung', property: 'Ja' },
          { category: 'Physikalische Details', feature: 'Gehäuse', property: 'IP65 Aluminium (weiß/schwarz)'},
          { category: 'Physikalische Details', feature: 'Halterung', property: 'Wand- und Deckenmontage'},
          { category: 'Physikalische Details', feature: 'Betriebstemperatur', property: '-5°C to 55°C'},
          { category: 'Physikalische Details', feature: 'Steckdose', property: '110V - 230V'},
          { category: 'Physikalische Details', feature: 'Netzwerkkabel', property: '3m Cat5e'},
          { category: 'Physikalische Details', feature: 'Power over Ethernet', property: 'IEEE 802.3af (+ Pin 4 and 5 / - Pin 7 and 8)'},
          { category: 'Physikalische Details', feature: 'Netzteil', property: '110-240V ac @ 50-60Hz to 12V dc and 2000mA - Kabellänge: 3m'},
          { category: 'Physikalische Details', feature: 'Netzstecker', property: '2-Pin-Buchse JST XH 2,54 mm Stecker [Breite: 6mm, Länge: 8mm, 2,54mm zwischen den Drähten]'},
          { category: 'Physikalische Details', feature: 'Leistungsaufnahme', property: 'max. 8.5 W (Normalbetrieb ohne IR LEDs 3.1W)'},
          { category: 'Physikalische Details', feature: 'Nettogewicht', property: '900 g'},
          { category: 'Physikalische Details', feature: 'Höhe/Breite/Tiefe', property: '10.3 / 7.5 / 23 cm'},
          { category: 'Physikalische Details', feature: 'Paket', property: '11 / 28.5 / 19 cm'},
          { category: 'Physikalische Details', feature: 'Pyroelektrischer Bewegungssensor (PIR)', property: 'Panasonic PaPIR EKMC16 Pyroelektrischer Sensor für große Entfernungen [5]'},
          { category: 'Physikalische Details', feature: 'PIR Blickwinkel', property: '102° x 92° (108° x 99°)'},
          { category: 'Physikalische Details', feature: 'PIR Detektor Reichweite', property: 'up to 12m [7]'},
          { category: 'Physikalische Details', feature: 'PIR Erkennungsbereich', property: '92 beams'},
          { category: 'Physikalische Details', feature: 'PIR Erkennung Bedingung', property: 'Temperaturunterschied muss > 4°C sein, Bewegungsgeschwindigkeit: 1,0m/s, mit einer Größe von ca. 700×250mm, die den Erfassungsstrahl kreuzt'},
          { category: 'Physikalische Details', feature: 'Zertifizierung', property: 'CE, FCC, RoHS'},
        ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns} >

          <FilteringState />

          <SortingState
            defaultSorting={[
              { columnName: 'category', direction: 'asc' },
            ]}
          />
          <GroupingState
            defaultGrouping={[{ columnName: 'category' }]}
            defaultExpandedGroups={['Optik & Bildgebung']}
          />
          <SelectionState />

          <IntegratedFiltering />
          <IntegratedGrouping />
          <IntegratedSelection />
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
          <TableFilterRow />
          <TableGroupRow />
        </Grid>
      </Paper>
    );
  }
}

TechSpecGrid.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(TechSpecGrid)

