import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Datenschutz">
        <EuiText>
          <p>
            Mit dem Schalter unten geben Sie der INSTAR Camera Skill Ihre Erlaubnis, Ihre Kamera über das P2P-Protokoll zu steuern. Die notwendigen Zugangsinformationen werden in Ihrem INSTAR Cloud-Konto gespeichert und nicht an Dritte weitergegeben.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

