import React from 'react'
import { Link } from 'gatsby'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

import cloudSearchIcon from '../../../images/Search/MD_SearchThumb_Cloud.webp'
import cloudAlexaSearchIcon from '../../../images/Search/MD_SearchThumb_Cloud_Alexa.webp'
import cloudIFTTTSearchIcon from '../../../images/Search/MD_SearchThumb_Cloud_IFTTT.webp'


export default function CloudArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/INSTAR_Cloud/Alexa_Cloud_Skills/">
                  <img
                    src={cloudAlexaSearchIcon}
                    alt="Alexa Skill"
                  />
                </Link>
              }
              title="Alexa Skill"
              description="Der INSTAR Cloud Skill verbindet sich mit der INSTAR Cloud Plattform. Benutzer müssen über ein INSTAR Cloud-Konto verfügen. Sobald Sie Ihr Cloud-Konto mit diesem Skill verknüpft haben, können Sie die Alarmaufzeichnungen Ihrer INSTAR IP-Kamera(s) sehen und abspielen."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/INSTAR_Cloud/Alexa_Cloud_Skills/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/">
                  <img
                    src={cloudIFTTTSearchIcon}
                    alt="IFTTT Applets"
                  />
                </Link>
              }
              title="IFTTT Applets"
              description="Der Name IFTTT leitet sich von der programmiertechnischen Bedingungsanweisung 'if this, then that' ab. Was das Unternehmen anbietet, ist eine Softwareplattform, die Apps, Geräte und Dienste verschiedener Entwickler miteinander verbindet, um eine oder mehrere Automatisierungen auszulösen, die diese Apps, Geräte und Dienste betreffen."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/INSTAR_Cloud/Personenerkennung/">
                  <img
                    src={cloudSearchIcon}
                    alt="Personenerkennung"
                  />
                </Link>
              }
              title="Personenerkennung"
              description="Hier können Sie alle Personen sehen, die in Ihren aufgenommenen Videos erfasst wurden. Jede Person wird mit ihrem Namen und einer Miniaturansicht ihres Gesichts angezeigt."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/INSTAR_Cloud/Personenerkennung/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/INSTAR_Cloud/Nummernschilderkennung/">
                  <img
                    src={cloudSearchIcon}
                    alt="License Plate Recognition"
                  />
                </Link>
              }
              title="Nummernschilderkennung"
              description="Hier sehen Sie alle erkannten Nummernschilder in Ihren aufgenommenen Videos. Jedes Nummernschild wird mit dem erkannten Schriftzug und einem Vorschaubild angezeigt."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/INSTAR_Cloud/Nummernschilderkennung/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/INSTAR_Cloud/Webhook/">
                  <img
                    src={cloudSearchIcon}
                    alt="Webhook"
                  />
                </Link>
              }
              title="Webhook"
              description="Smarthomes und Automatisierungssysteme bieten oft HTTP-Webhooks zur Verbindung mit Systemen von Drittanbietern. Konfigurieren Sie Ihre INSTAR Cloud so, dass sie einen solchen Webhook kontaktiert, wenn ein Alarm aufgezeichnet wird."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/INSTAR_Cloud/Webhook/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/INSTAR_Cloud/Google_Home/">
                  <img
                    src={cloudSearchIcon}
                    alt="Google Home"
                  />
                </Link>
              }
              title="Google Home"
              description="So verknüpfen Sie Ihre INSTAR Cloud Action Mit der Google Home-App können Sie Google Nest- oder Home-Lautsprecher und -Displays sowie Chromecast einrichten und steuern."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/INSTAR_Cloud/Google_Home/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
