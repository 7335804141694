import React from "react";

import { Link } from "gatsby";

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiButton,
} from "@elastic/eui";

import ivImage05 from "../../../../../images/Software/Windows/InstarVision/Advanced/Image049_Export_small.png";

export default function ImageCard() {
  return (
    <EuiFlexGroup gutterSize="l" direction="row" style={{ margin: "0 7px" }}>
      <EuiFlexItem>
        <EuiCard
          textAlign="left"
          image={
            <div>
              <img src={ivImage05} alt="Öffnen Sie den Windows Explorer" />
            </div>
          }
          title="Explorer"
          description="Öffnen Sie den Windows Explorer"
          content={
            <EuiText>
              <p>Öffnen Sie den Windows Explorer</p>
            </EuiText>
          }
          footer={
            <EuiFlexGroup justifyContent="flexEnd">
              <EuiFlexItem grow={false}>
                <Link to="/Software/Windows/InstarVision/Advanced/Explorer/">
                  <EuiButton size="s" fill>
                    mehr...
                  </EuiButton>
                </Link>
              </EuiFlexItem>
            </EuiFlexGroup>
          }
        />
      </EuiFlexItem>
      <EuiFlexItem></EuiFlexItem>
      <EuiFlexItem></EuiFlexItem>
    </EuiFlexGroup>
  );
}
