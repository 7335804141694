import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Es wird KEIN kostenplichtiger INSTAR Cloud Account benötigt!">
        <EuiText>
          <p>
            Damit Ihre Kamera eine Verbindung zur Amazon Cloud aufbauen kann, müssen Sie sich einen INSTAR Cloud Account für Ihre Kamera anlegen. Das Anlegen eines Test Account is kostenlos und gibt Ihnen für 30 Tage vollen Zugriff auf alle INSTAR Cloud Funktionen. Nach Ablauf dieser Testphase können Sie keine Alarmvideos mehr hochladen lassen - der Account, und damit auch die Möglichkeit die <strong>Smart-Home Skill</strong> zu nutzen, bleibt Ihnen jedoch bestehen.
          </p>
          <p>
            Die <strong>Cloud Skill</strong> dient der Verwaltung Ihrer Alarmaufnahmen in der INSTAR Cloud und kann während der Testphase im vollen Umfang genutzt werden.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

