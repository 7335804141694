import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class ResetTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Werksreset Einstellungen" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Kamera zurücksetzen', descriptionColumn: 'Aktivieren Sie die Werksreste-Schaltfläche und bestätigen Sie, um Ihre Kamera auf die Werkseinstellungen zurückzusetzen. Bitte beachten Sie, dass Sie Ihre Kamera wieder neu über ein Netzwerkkabel einrichten müssen, nachdem Sie den Werksreset durchgeführt haben.' },
        { actionColumn: 'WiFi & IP Konfiguration beibehalten', descriptionColumn: 'Mit dieser Funktion können Sie Ihre gesamte Kamerakonfiguration mit Ausnahme Ihrer Netzwerkeinstellungen zurücksetzen. Nach dem Zurücksetzen stellt Ihre Kamera automatisch wieder eine Verbindung zu Ihrem LAN oder WiFi her.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

ResetTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(ResetTable)