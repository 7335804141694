import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Fernzugriff">
        <EuiText>
          <p>
            Sie haben zwei Möglichkeiten Ihre Kamera über das Internet zu erreichen - der einfache Weg ist P2P. Der einzige Weg um einen kompletten Zugriff auf Ihre Kamerafunktionen zu erhalten heißt DDNS. Die DDNS oder Internet Adresse gibt Ihnen Zugriff auf die Weboberfläche Ihrer Kamera.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

