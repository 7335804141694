import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'Übersicht',
      href: '/de/Innenkameras/IN-8403_WQHD/',
    },
    {
      text: 'Spezifikationen',
      href: '/de/Innenkameras/IN-8403_WQHD/Technische_Spezifikationen/',
    },
    {
      text: 'Features',
      href: '/de/Innenkameras/IN-8403_WQHD/Produkt_Features/',
    },
    {
      text: 'Installation',
      href: '/de/Innenkameras/IN-8403_WQHD/Schnell_Installation/',
    },
    {
      text: 'Objektivjustage',
      href: '/de/Innenkameras/IN-8403_WQHD/Objektivjustage/',
    },
    {
      text: 'Handbuch',
      href: '/de/Innenkameras/IN-8403_WQHD/Handbuch/',
    },
    {
      text: '◄ Innenkameras',
      href: '/de/Innenkameras/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={10}
        aria-label="INSTAR IN-8403 2k+ WQHD Online Handbuch Kapitel"
      />
      <EuiSpacer />
    </Fragment>
  );
}