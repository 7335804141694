import React from 'react'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="034bXd-DYgU"
                    title="Synology Surveillance Station"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              title="Synology Surveillance Station"
              footer={
                <EuiText>
                  <p>In diesem Video zeigen wir Ihnen wie Sie schnell und einfach eine INSTAR IP Kamera in Ihrer Synology Surveillance Station hinzufügen können. Schauen Sie auch unsere anderen Videos wo wir zeigen Sie die Synology konfigurieren damit diese das Video der Kamera durchgehend bzw. nur bei Bewegungen aufnimmt. Zudem zeigen wir auch den mobilen Zugriff auf die Synology um so z.B. mit Ihrem Smartphone auf die Synology zuzugreifen von unterwegs um sich die Aufnahmen anzuschauen.</p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}