import React from 'react'

import {
  EuiCard,
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import tinyCam16 from "../../../../images/Software/Android/TinyCam_Monitor/TinyCam-Monitor_16.webp"
import tinyCam18 from "../../../../images/Software/Android/TinyCam_Monitor/TinyCam-Monitor_18.webp"
import tinyCam19 from "../../../../images/Software/Android/TinyCam_Monitor/TinyCam-Monitor_19.webp"




export default function softwareArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={tinyCam16}
                    alt="TinyCam Monitor for your INSTAR IP Camera"
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">
                    <p>Öffnen Sie die erweiterten Einstellungen, um die JPG-Bildadresse einzustellen.</p>
                  </EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={tinyCam18}
                    alt="TinyCam Monitor for your INSTAR IP Camera"
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">
                    <p>Der Pfad tmpfs/snap.jpg liefert Ihnen den JPG-Stream in voller Auflösung.</p>
                  </EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={tinyCam19}
                    alt="TinyCam Monitor for your INSTAR IP Camera"
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">
                    <p>Der Pfad tmpfs/auto.jpg liefert Ihnen den komprimierten JPG-Stream.</p>
                  </EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
