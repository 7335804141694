import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class FeatureTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "numberColumn", title: "#" },
        { name: "componentColumn", title: "Komponente" },
        { name: "functionColumn", title: "Funktion" },
      ],
      rows: [
        { numberColumn: '01', componentColumn: 'Infrarot-LEDs', functionColumn: '5x Hochleistungs-Infrarot-LEDs für Nachtsicht @850nm' },
        { numberColumn: '02', componentColumn: 'Objektiv', functionColumn: 'Weitwinkel (Brennweite 4,2 mm / Blickwinkel: ~ 90º dia.)' },
        { numberColumn: '03', componentColumn: 'Fotosensor', functionColumn: 'Dämmerungsschalter für IR-LEDs' },
        { numberColumn: '04', componentColumn: 'PIR Sensor', functionColumn: 'Integrierter Panasonic PIR-Bewegungsmelder' },
        { numberColumn: '05', componentColumn: 'Mikrofon', functionColumn: 'Für Geräuscherkennung / Audioaufnahme / Zwei-Wege-Audiokommunikation' },
        { numberColumn: '06', componentColumn: 'Audio Output', functionColumn: '3,5-mm-Buchse für Kopfhörer/Aktivlautsprecher' },
        { numberColumn: '07', componentColumn: 'Netzwork Port', functionColumn: 'RJ45 LAN-Anschluss für das mitgelieferte CAT5e-Ethernet-Kabel (oder höher)' },
        { numberColumn: '08', componentColumn: 'Stromanschluss', functionColumn: 'Für das mitgelieferte 12V / 2A dc-Netzteil' },
        { numberColumn: '09', componentColumn: 'Micro SD Karte', functionColumn: 'Steckplatz für MicroSD/SDXC-Karten bis zu 256 GB (32 GB enthalten)' },
        { numberColumn: '10', componentColumn: 'WPS / Reset', functionColumn: '3s für WiFi-Setup / 15s für Zurücksetzen auf Werkseinstellungen' },
        { numberColumn: '11', componentColumn: 'Status LEDs', functionColumn: 'Rot: Power-LED; Blau: Netzwerkstatus-LED. Beide können deaktiviert werden. (s. LED Modi, unten)' },
        { numberColumn: '12', componentColumn: 'Antennenanschluss', functionColumn: 'RP-SMA WiFi Antennenanschluss' },
        { numberColumn: '13', componentColumn: 'WiFi Antenne', functionColumn: '3 dBi Antenne (RP-SMA)' },
        { numberColumn: '14', componentColumn: 'Kabeldurchführung', functionColumn: 'IP66 wetterfest mit Gummidichtung für 3, 2 oder 1 Kabel. Bitte sehen Sie sich unsere Schnellinstallationsanleitung an, wie Sie Ihre Kamerakabel anbringen.' },
        { numberColumn: '15', componentColumn: 'Dichtringe', functionColumn: 'Gummi-Dichtringe für 3, 2 oder 1 Kabel. Bitte sehen Sie sich unsere Schnellinstallationsanleitung an, wie Sie Ihre Kamerakabel anbringen.' },
        { numberColumn: '16', componentColumn: 'Nuss', functionColumn: 'Schließen Sie die Kabeldurchführung und dichten Sie sie ab.' },
        { numberColumn: '17', componentColumn: 'Halterung', functionColumn: 'Wand- und Deckenbefestigung.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

FeatureTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(FeatureTable)