import React from "react"
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'


export default function YoutubeVideoCardAlarmAreas() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="Ns6D0I5dcTU"
                    title="Sessions und Session Cookies erklärt: Wie man die automatische Abmeldezeit einstellt"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              title="Sessions und Session Cookies erklärt"
              footer={
                <EuiText>
                  <h4>Wie man die automatische Abmeldezeit einstellt</h4>
                  <p>In diesem Video erkläre ich, was eine Session und ein Session Cookie sind und wie sie in der Webentwicklung verwendet werden. Außerdem zeige ich dir, wie du die automatische Abmeldezeit einstellen kannst, damit Benutzer nach einer bestimmten Inaktivitätsdauer erneut eingeloggt werden müssen.</p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}
