import React from 'react'

import {
  EuiImage,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'

import ivImage from '../../../../../images/Software/Windows/InstarVision/Users/Image047_cut.png'


export default function ImageCard() {

  return (
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem grow={false}>
            <EuiImage
              hasShadow
              allowFullScreen
              alt="InstarVision v2 for Windows"
              src={ivImage}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiText>
              <p><strong>InstarVision bietet Ihnen eine hochgradig anpassbare mehrstufige Benutzerverwaltung</strong></p>
              <p>Sofern Sie als Administrator angemeldet sind, können Sie hier Benutzer Hinzufügen, Bearbeiten, Löschen und die <a href="#benutzerrechte">Benutzerrechte</a> der einzelnen Benutzer vergeben. Ist der <a href="#aktivieren-sie-den-passwortschutz">Passwortschutz</a> deaktiviert, sind Sie automatisch als Administrator über den <a href="#standardbenutzer">Standardbenutzer</a> angemeldet. </p>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
  );
}
