import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Audio Settings">
        <EuiText>
          <p>
           Hier können Sie die Verstärkung des Audio-In / Mikrofons, als auch die Lautstärke des Audio-Out Signals festlegen.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

