import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Camera Reset">
        <EuiText>
          <p>
            Hier können Sie die Kamera in den Werkszustand zurückversetzen. Die Option “WLAN & IP Konfig. beibehalten” ist standardmäßig ausgewählt. Wenn Sie den Reset mit dieser Option durchführen, dann behält die Kamera sämtliche Netzwerkdaten und ist nach dem Reset über die gleiche IP Adresse wie zuvor erreichbar. Auch die WLAN-Daten bleiben erhalten.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

