import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class InfoTable02 extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Kamera Übersicht" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Kamera ID', descriptionColumn: 'Modellbezeichnung Ihrer Kamera.' },
        { actionColumn: 'WAN IP Adresse', descriptionColumn: 'Wide Area Network Adresse Ihres Internetanschlusses. Dies ist die Adresse, die hinter Ihrer DDNS Adresse hinterlegt wird um die Kamera von extern zu erreichen (benötigt eine Portweiterleitung!).' },
        { actionColumn: 'Internet', descriptionColumn: 'Kann Ihre Kamera auf das Internet zugreifen? Der Internetzugang ist für die Bedienung Ihrer Kamera nicht zwingend erforderlich. Aber es wird für einige Funktionen benötigt, wie Email & Push Benachrichtigung, FTP Upload, automatischen Datum & Uhrzeit Abgleich, Fernzugriff über DDNS & P2P, automatische Update Benachrichtigung, usw.' },
        { actionColumn: 'DDNS Adresse', descriptionColumn: 'Diese Funktion zeigt Ihnen ob Ihre DDNS Adresse erfolgreich mit Ihrer aktuellen WAN-IP-Adresse aktualisiert wurde. Dies ist Voraussetzung für Zugriff auf Ihre Kamera über das Internet.' },
        { actionColumn: 'DDNS Status', descriptionColumn: 'Überprüft ob die WAN IP Adresse Ihres Internetanschlusses hinter Ihrer DDNS Adresse hinterlegt wurde. Dies wird für den Zugriff über das Internet auf die Kamera benötigt.' },
        { actionColumn: 'P2P UID', descriptionColumn: 'Ihre persönliche Point-to-Point-Benutzeridentifikationsnummer. Um mit unseren Android, iPhone, iPad, Windows Phone oder Windows Metro auf Ihre Kamera zuzugreifen' },
        { actionColumn: 'P2P Status', descriptionColumn: 'Aktivitätsstatus für den Point-to-Point Fernzugriffsdienst.' },
        { actionColumn: 'UPnP Status', descriptionColumn: 'Der UPnP Dienst würde automatisch eine Portweiterleitung für den DDNS Zugriff auf Ihre Kamera anlegen. Wir empfehlen jedoch dies manuell zu machen und UPnP zu deaktivieren.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

InfoTable02.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(InfoTable02)