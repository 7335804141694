import React from 'react'
// import { OutboundLink } from 'gatsby-plugin-gtag'

import {
  EuiButton,
  EuiToolTip,
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiPageContentBody,
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiText,
  EuiTitle,
  EuiSpacer
} from '@elastic/eui'

import '../../assets/styles/gatsby-image.css'

import SearchFlyout from '../Search/SearchFlyout'


function Layout ({ children }) {
  return (
    <EuiPage restrictWidth={1000}>
      <EuiPageBody style={{width: "100%", height: "100%", display: 'inline-block', overflow: "hidden" }}>
        <EuiTitle size="m">
          <h4>INSTAR Wiki 2.5</h4>
        </EuiTitle>
        <EuiText size="s" color="subdued">
          <p>Wissensdatenbank für IP Kameras und Hausautomatisierung</p>
        </EuiText>
        <EuiSpacer />
        <EuiPageHeader>
          <EuiPageHeaderSection>
              <EuiToolTip
              position="top"
              content={
                <p>
                  INSTAR Forum
                </p>
              }
              >
                <EuiButton
                  iconType="quote"
                  href="https://forum.instar.de"
                  color="primary" size="s" fill >
                  Discuss on our Forum
                </EuiButton>
              </EuiToolTip>
            {/* <OutboundLink href="https://forum.instar.de" target="_blank" rel="nofollow, noreferrer" >
              <EuiButton
                iconType="quote"
                color="primary" size="s" fill >
                Artikel Diskutieren
              </EuiButton>
            </OutboundLink> */}
          </EuiPageHeaderSection>
          <EuiPageHeaderSection>
            <SearchFlyout style={{float: 'right'}}/>
          </EuiPageHeaderSection>
        </EuiPageHeader>
        <EuiPageContent>
          <EuiPageContentBody style={{width: "100%", height: "100%", boxSizing: "content-box", whiteSpace: "normal", wordWrap: "break-word", overflowY: "hidden", overflowX: "hidden"}}>
            <EuiText>
              {children}
            </EuiText>
          </EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  )
}

export default Layout