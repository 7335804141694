import React, { useState } from 'react'
import {Link} from 'gatsby'

import {
  EuiPopover,
  EuiSpacer,
  EuiButton,
  EuiButtonEmpty,
  EuiText,
  EuiPopoverTitle,
  EuiPopoverFooter,
  EuiFlexGroup,
  EuiFlexItem,
  EuiShowFor,
} from '@elastic/eui'

export const SideNavItems = () => {
  const [isProductMenuOpen, setisProductMenuOpen] = useState(false);
  const [isInstallationMenuOpen, setisInstallationMenuOpen] = useState(false);
  const [isMotionMenuOpen, setisMotionMenuOpen] = useState(false);
  const [isRemoteMenuOpen, setisRemoteMenuOpen] = useState(false);
  const [isAdvancedMenuOpen, setisAdvancedMenuOpen] = useState(false);

  return (
    <EuiShowFor sizes={['l', 'xl']}>
      <EuiFlexGroup wrap gutterSize="s" alignItems="center">
        <EuiFlexItem grow={true}>
          <EuiPopover
            ownFocus
            button={
              <EuiButtonEmpty
                color="ghost"
                aria-label="INSTAR Products Menu"
                aria-controls="guideHeaderProductsMenu"
                aria-haspopup="true"
                onClick={() => setisProductMenuOpen(!isProductMenuOpen)}>
                Produkte
              </EuiButtonEmpty>
            }
            isOpen={isProductMenuOpen}
            anchorPosition="downRight"
            closePopover={() => setisProductMenuOpen(false)}>
            <EuiPopoverTitle><Link to="/Produkte/">INSTAR Produkte</Link></EuiPopoverTitle>
            <div style={{ width: '300px' }}>
              <EuiText size="s">
                <p>
                INSTAR IP-Kameras, Netzwerksicherheits- und Sicherheitssoftwareprodukte sowie zugehörige Software von Drittanbietern und Hausautomatisierungslösungen für die Kameraüberwachung.
                </p>
              </EuiText>
            </div>
            <EuiPopoverFooter paddingSize="l">
              <Link to="/Innenkameras/">
                <EuiButton fullWidth size="s" fill>
                  Innenkameras
                </EuiButton>
              </Link>
              <EuiSpacer size="m" />
              <Link to="/Aussenkameras/">
                <EuiButton fullWidth size="s" fill>
                  Aussenkameras
                </EuiButton>
              </Link>
              <EuiSpacer size="m" />
              <Link to="/Produkte/Power_over_Ethernet/">
                <EuiButton fullWidth size="s" fill>
                  Power over Ethernet
                </EuiButton>
              </Link>
              <EuiSpacer size="m" />
              <Link to="/Produkte/Objektive/">
                <EuiButton fullWidth size="s" fill>
                  Objektive
                </EuiButton>
              </Link>
              <EuiSpacer size="m" />
              <Link to="/Software/">
                <EuiButton fullWidth size="s" fill>
                  Software
                </EuiButton>
              </Link>
              <EuiSpacer size="m" />
              <Link to="/Web_User_Interface/">
                <EuiButton fullWidth size="s" fill>
                Web User Interface
                </EuiButton>
              </Link>
              <EuiSpacer size="m" />
              <Link to="/Bewegungserkennung/INSTAR_Cloud/">
                <EuiButton fullWidth size="s" fill>
                  INSTAR Cloud Dienst
                </EuiButton>
              </Link>
              <EuiSpacer size="m" />
              <Link to="/Produkte/Andere/">
                <EuiButton fullWidth size="s" fill>
                  Andere Produkte
                </EuiButton>
              </Link>
          </EuiPopoverFooter>
        </EuiPopover>
      </EuiFlexItem>
        <EuiFlexItem grow={true}>
          <EuiPopover
            ownFocus
            button={
              <EuiButtonEmpty
                color="ghost"
                aria-label="INSTAR Installation Menu"
                aria-controls="guideHeaderInstallationMenu"
                aria-haspopup="true"
                onClick={() => setisInstallationMenuOpen(!isInstallationMenuOpen)}>
                Installation
              </EuiButtonEmpty>
            }
            isOpen={isInstallationMenuOpen}
            anchorPosition="downRight"
            closePopover={() => setisInstallationMenuOpen(false)}>
            <EuiPopoverTitle><Link to="/Schnell_Installation/">Schnellinstallation</Link></EuiPopoverTitle>
            <div style={{ width: '300px' }}>
              <EuiText size="s">
                <p>
                Erste Schritte bei der Installation Ihrer INSTAR IP Kamera.
                </p>
              </EuiText>
            </div>
            <EuiPopoverFooter paddingSize="l">
              <Link to="/Schnell_Installation/Wie_Funktioniert_eine_IP_Kamera/">
                <EuiButton fullWidth size="s" fill>
                  IP Kameras
                </EuiButton>
              </Link>
              <EuiSpacer size="m" />
              <Link to="/Schnell_Installation/Nach_dem_Auspacken/">
                <EuiButton fullWidth size="s" fill>
                  Nach dem Auspacken
                </EuiButton>
              </Link>
              <EuiSpacer size="m" />
              <Link to="/Schnell_Installation/Erste_Schritte/">
                <EuiButton fullWidth size="s" fill>
                  Erste Schritte
                </EuiButton>
              </Link>
              <EuiSpacer size="m" />
              <Link to="/Schnell_Installation/Herstellen_der_WLAN_Verbindung/">
                <EuiButton fullWidth size="s" fill>
                  WLAN Verbindung
                </EuiButton>
              </Link>
              <EuiSpacer size="m" />
              <Link to="/Schnell_Installation/Power_over_Ethernet/">
                <EuiButton fullWidth size="s" fill>
                  Power over Ethernet
                </EuiButton>
              </Link>
              <EuiSpacer size="m" />
              <Link to="/Schnell_Installation/Powerline/">
                <EuiButton fullWidth size="s" fill>
                  Powerline
                </EuiButton>
              </Link>
              <EuiSpacer size="m" />
              <Link to="/Schnell_Installation/Direkte_LAN_Verbindung/">
                <EuiButton fullWidth size="s" fill>
                  Direkte LAN Verbindung
                </EuiButton>
              </Link>
              <EuiSpacer size="m" />
              <Link to="/Schnell_Installation/Alternative_IP_Scanner/">
                <EuiButton fullWidth size="s" fill>
                  Auffinden der Kamera
                </EuiButton>
              </Link>
              <EuiSpacer size="m" />
              <Link to="/Schnell_Installation/Sprachauswahl/">
                <EuiButton fullWidth size="s" fill>
                  Sprachauswahl
                </EuiButton>
              </Link>
              <EuiSpacer size="m" />
              <Link to="/Schnell_Installation/Live_Video/">
                <EuiButton fullWidth size="s" fill>
                  Live Video
                </EuiButton>
              </Link>
              <EuiSpacer size="m" />
              <Link to="/Schnell_Installation/Benutzerverwaltung/">
                <EuiButton fullWidth size="s" fill>
                  Benutzerverwaltung
                </EuiButton>
              </Link>
              <EuiSpacer size="m" />
              <Link to="/Schnell_Installation/WebUI_und_Firmware_Upgrade/">
                <EuiButton fullWidth size="s" fill>
                  Software Update
                </EuiButton>
              </Link>
              <EuiSpacer size="m" />
              <Link to="/Schnell_Installation/Leeren_des_Browserverlaufs/">
                <EuiButton fullWidth size="s" fill>
                  Browser History
                </EuiButton>
              </Link>
              <EuiSpacer size="m" />
              <Link to="/Schnell_Installation/ONVIF/">
                <EuiButton fullWidth size="s" fill>
                  ONVIF
                </EuiButton>
              </Link>
            </EuiPopoverFooter>
          </EuiPopover>
        </EuiFlexItem>
        <EuiFlexItem grow={true}>
          <EuiPopover
            ownFocus
            button={
              <EuiButtonEmpty
                color="ghost"
                aria-label="INSTAR Motion Detection Menu"
                aria-controls="guideHeaderMotionMenu"
                aria-haspopup="true"
                onClick={() => setisMotionMenuOpen(!isMotionMenuOpen)}>
                Bewegungserkennung
              </EuiButtonEmpty>
            }
            isOpen={isMotionMenuOpen}
            anchorPosition="downRight"
            closePopover={() => setisMotionMenuOpen(false)}>
            <EuiPopoverTitle><Link to="/Bewegungserkennung/">Bewegungserkennung</Link></EuiPopoverTitle>
            <div style={{ width: '300px' }}>
              <EuiText size="s">
                <p>
                  Verwenden Sie die Bewegungserkennungsfunktion Ihrer Kameras, um ungebetene Besucher zu erkennen und Alarmbenachrichtigungen zu senden.
                </p>
              </EuiText>
            </div>
            <EuiPopoverFooter paddingSize="l">
                <Link to="/Bewegungserkennung/Einrichten/">
                  <EuiButton fullWidth size="s" fill>
                    Erkennung einrichten
                  </EuiButton>
                </Link>
                <EuiSpacer size="m" />
                <Link to="/Bewegungserkennung/Passive_Infrared_Detection/">
                  <EuiButton fullWidth size="s" fill>
                    PIR Bewegungsmelder
                  </EuiButton>
                </Link>
                <EuiSpacer size="m" />
                <Link to="/Bewegungserkennung/Alarm_Benachrichtigung/">
                  <EuiButton fullWidth size="s" fill>
                    Alarm Benachrichtigung
                  </EuiButton>
                </Link>
                <EuiSpacer size="m" />
                <Link to="/Bewegungserkennung/SD_Karten_Zugriff/">
                  <EuiButton fullWidth size="s" fill>
                    SD Karten Zugriff
                  </EuiButton>
                </Link>
                <EuiSpacer size="m" />
                <Link to="/Bewegungserkennung/Alarm_FTP_Upload/">
                  <EuiButton fullWidth size="s" fill>
                    Alarm FTP Upload
                  </EuiButton>
                </Link>
                <EuiSpacer size="m" />
                <Link to="/Bewegungserkennung/Router_als_FTP_Server/">
                  <EuiButton fullWidth size="s" fill>
                    Router als FTP Server
                  </EuiButton>
                </Link>
                <EuiSpacer size="m" />
                <Link to="/Bewegungserkennung/FTP_Server_Setup/">
                  <EuiButton fullWidth size="s" fill>
                    FTP Server Setup
                  </EuiButton>
                </Link>
                <EuiSpacer size="m" />
                <Link to="/Bewegungserkennung/Alarm_Server/">
                  <EuiButton fullWidth size="s" fill>
                    Alarm Server
                  </EuiButton>
                </Link>
                <EuiSpacer size="m" />
                <Link to="/Bewegungserkennung/INSTAR_Cloud/">
                  <EuiButton fullWidth size="s" fill>
                    INSTAR Cloud
                  </EuiButton>
                </Link>
            </EuiPopoverFooter>
          </EuiPopover>
        </EuiFlexItem>
        <EuiFlexItem grow={true}>
          <EuiPopover
            ownFocus
            button={
              <EuiButtonEmpty
                color="ghost"
                aria-label="INSTAR Remote Access Menu"
                aria-controls="guideHeaderRemoteMenu"
                aria-haspopup="true"
                onClick={() => setisRemoteMenuOpen(!isRemoteMenuOpen)}>
                Fernzugriff
              </EuiButtonEmpty>
            }
            isOpen={isRemoteMenuOpen}
            anchorPosition="downRight"
            closePopover={() => setisRemoteMenuOpen(false)}>
            <EuiPopoverTitle><Link to="/Fernzugriff/">Fernzugriff</Link></EuiPopoverTitle>
            <div style={{ width: '300px' }}>
              <EuiText size="s">
                <p>
                  Greifen Sie auf Ihre INSTAR IP-Kamera über das Internet mit dem integrierten Point2Point- oder DDNS-Dienst zu. Richten Sie Ihren Router so ein, dass Sie vollen Zugriff auf Ihre Kamera haben, wenn Sie nicht zu Hause sind.
                </p>
              </EuiText>
            </div>
            <EuiPopoverFooter paddingSize="l">
                <Link to="/Fernzugriff/Point_to_Point/">
                  <EuiButton fullWidth size="s" fill>
                    Point to Point
                  </EuiButton>
                </Link>
                <EuiSpacer size="m" />
                <Link to="/Fernzugriff/Der_DDNS_Dienst/">
                  <EuiButton fullWidth size="s" fill>
                    DDNS Dienst
                  </EuiButton>
                </Link>
                <EuiSpacer size="m" />
                <Link to="/Fernzugriff/Port_Weiterleitung/">
                  <EuiButton fullWidth size="s" fill>
                    Port Weiterleitung
                  </EuiButton>
                </Link>
                <EuiSpacer size="m" />
                <Link to="/Fernzugriff/Mobil_Zugriff/">
                  <EuiButton fullWidth size="s" fill>
                    Mobiler Zugriff
                  </EuiButton>
                </Link>
                <EuiSpacer size="m" />
                <Link to="/Fernzugriff/DDNS_Anbieter/">
                  <EuiButton fullWidth size="s" fill>
                    DDNS Anbieter
                  </EuiButton>
                </Link>
            </EuiPopoverFooter>
          </EuiPopover>
        </EuiFlexItem>
        <EuiFlexItem grow={true}>
          <EuiPopover
            ownFocus
            button={
              <EuiButtonEmpty
                color="ghost"
                aria-label="INSTAR Advanced Menu"
                aria-controls="guideHeaderAdvancedMenu"
                aria-haspopup="true"
                onClick={() => setisAdvancedMenuOpen(!isAdvancedMenuOpen)}>
                Entwickler
              </EuiButtonEmpty>
            }
            isOpen={isAdvancedMenuOpen}
            anchorPosition="downRight"
            closePopover={() => setisAdvancedMenuOpen(false)}>
            <EuiPopoverTitle><Link to="/Erweitert/">Für Entwickler</Link></EuiPopoverTitle>
            <div style={{ width: '300px' }}>
              <EuiText size="s">
                <p>
                  Themen für fortgeschrittene Benutzer und Entwickler. API-Beschreibung, Heimautomatisierungs-Integration und erweiterte Netzwerk-Themen.
                </p>
              </EuiText>
            </div>
            <EuiPopoverFooter paddingSize="l">
                <Link to="/Erweitert/CGI_Befehle/">
                  <EuiButton fullWidth size="s" fill>
                    CGI Befehle
                  </EuiButton>
                </Link>
                <EuiSpacer size="m" />
                <Link to="/Erweitert/INSTAR_MQTT_Broker/">
                  <EuiButton fullWidth size="s" fill>
                    INSTAR MQTT Broker
                  </EuiButton>
                </Link>
                <EuiSpacer size="m" />
                <Link to="/Erweitert/VPN_AVM_Fritzbox/">
                  <EuiButton fullWidth size="s" fill>
                    VPN AVM Fritzbox
                  </EuiButton>
                </Link>
                <EuiSpacer size="m" />
                <Link to="/Erweitert/Webseiten_Einbindung/">
                  <EuiButton fullWidth size="s" fill>
                    Website Einbindung
                  </EuiButton>
                </Link>
                <EuiSpacer size="m" />
                <Link to="/Erweitert/Youtube_Videostreaming_from_your_Camera/">
                  <EuiButton fullWidth size="s" fill>
                    Youtube Videostreaming
                  </EuiButton>
                </Link>
                <EuiSpacer size="m" />
                <Link to="/Erweitert/Alle_Smarthome_Tutorials/">
                  <EuiButton fullWidth size="s" fill>
                    Alle Smarthome Tutorials
                  </EuiButton>
                </Link>
                <EuiSpacer size="m" />
                <Link to="/Erweitert/Tasker/">
                  <EuiButton fullWidth size="s" fill>
                    Tasker
                  </EuiButton>
                </Link>
                <EuiSpacer size="m" />
                <Link to="/Erweitert/WebUI_Wiederherstellen/">
                  <EuiButton fullWidth size="s" fill>
                    WebUI Wiederherstellen
                  </EuiButton>
                </Link>
                <EuiSpacer size="m" />
                <Link to="/Erweitert/Firmware_Wiederherstellen/">
                  <EuiButton fullWidth size="s" fill>
                    Firmware Wiederherstellen
                  </EuiButton>
                </Link>
                <EuiSpacer size="m" />
                <Link to="/Erweitert/CCTV_vs_IP/">
                  <EuiButton fullWidth size="s" fill>
                    CCTV vs IP
                  </EuiButton>
                </Link>
            </EuiPopoverFooter>
          </EuiPopover>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiShowFor>
  )
}
