import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Push Service">
        <EuiText>
          <p>
          Ihre Kamera kann Sie per Email informieren, jedes Mal, wenn die Bewegungserkennung ausgelöst hat. Eine Push-Benachrichtigung erfüllt den selben Zweck, ist aber viel schneller! Aktivieren Sie die Push-Benachrichtigung, wenn Sie direkt über einen Alarm informiert werden möchten. Die Push-Benachrichtigung funktioniert nur mit der InstarVision App für <Link to="/Software/iOS/InstarVision/iPhone/Multiview/">iPhone</Link> (mit Youtube Tutorial), <Link to="/Software/iOS/InstarVision/iPad/Multiview/">iPad</Link>, <Link to="/Software/Windows/InstarVision/Windows_Phone/Multiview/">Windows Phone</Link>, <Link to="/Software/Windows/InstarVision/Metro/Multiview/">Windows (Metro)</Link> oder <Link to="/Software/Android/InstarVision/Multiview/">Android</Link> Geräte.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox