import React from 'react'
import { Link } from 'gatsby'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

import downloadsSearchIcon from '../../../images/Search/D_Downloads_SearchThumb.webp'


export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Utensilien/IN-Motion_500/">
                  <img
                    src={downloadsSearchIcon}
                    alt="IN-Motion 500"
                  />
                </Link>
              }
              title="IN-Motion 500"
              description="Dieses Produkt integriert Mikrowellen- und Passiv-Infrarot-Bewegungserkennung. Und ist die beste Wahl für die Einbruchserkennung im Freien."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Utensilien/IN-Motion_500/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Utensilien/IN-Motion_300/">
                  <img
                    src={downloadsSearchIcon}
                    alt="IN-Motion 300"
                  />
                </Link>
              }
              title="IN-Motion 300"
              description="IN-Motion ist ein passiver (PIR) Bewegungsmelder, der die perfekte Erweiterung für Ihre IP-Kamera ist, um Fehlalarme zu minimieren."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Utensilien/IN-Motion_300/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Utensilien/IN-Mikro_380/">
                  <img
                    src={downloadsSearchIcon}
                    alt="IN-Mikro 380"
                  />
                </Link>
              }
              title="IN-Mikro 380"
              description="Das IN-MIKRO 380 ist ein hochwertiges aktives Mikrofon, das einfach an Ihre Kamera angeschlossen werden kann. Alles, was Sie brauchen, ist eine Kamera, die einen eingebauten Audioeingang hat."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Utensilien/IN-Mikro_380/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Utensilien/IN-90x/">
                  <img
                    src={downloadsSearchIcon}
                    alt="IN-Frared"
                  />
                </Link>
              }
              title="IN-Frared"
              description="Verwenden Sie den IN-907, IN-906, IN-905 oder IN-903 IR-Strahler mit Ihren nachtsichtfähigen Kameras, um die Nachtsicht zu verbessern. (Auch in 940nm erhältlich)"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Utensilien/IN-90x/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Utensilien/IN-DV1215/">
                  <img
                    src={downloadsSearchIcon}
                    alt="IN-DV1215"
                  />
                </Link>
              }
              title="IN-DV1215"
              description="Action-Kamera mit einem 120-Grad-Objektiv, wasserdichtem Gehäuse und High-Speed-Videoaufnahmefunktion. Ideal für Sportaufnahmen, wie Snowboarding, Skifahren, Tauchen..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Utensilien/IN-DV1215/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
