import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Excursus">
        <EuiText>
          <p>Um auf Ihre Kamera zuzugreifen, können Sie immer die gleiche DDNS-Adresse verwenden. Wenn Sie also mehr als eine Kamera haben, brauchen Sie sich nur eine Adresse zu merken. Am wichtigsten ist, dass Sie den Port hinter der DDNS-Nummer ändern. Der Grund dafür ist, dass Ihr Internet Service Provider Ihnen alle 24 Stunden eine neue IP-Adresse zuweist. Sobald die Adresse geändert wird, merkt die Kamera dies und sendet es an den Server, um sie mit unserer DDNS-Adresse zu verbinden. Auf diese Weise können Sie immer mit einer Adresse auf Ihre Kamera zugreifen, auch wenn sich die IP dahinter ändert. Auch wenn Sie also verschiedene DDNS-Adressen verwenden, sind diese alle mit einer Adresse verknüpft. Sie greifen also mit jeder Adresse auf Ihren Router zu. Und durch die Portweiterleitung weiß Ihr Router dann, zu welchem Gerät Sie weitergeleitet werden wollen. So geben wir zum Beispiel folgendes ein, um auf die Kameras zuzugreifen:</p>
          <ul>
            <li><strong>Camera 1</strong>http://d2908.ddns-instar.com:80</li>
            <li><strong>Camera 2</strong>http://d2908.ddns-instar.com:8081</li>
            <li><strong>Camera 3</strong>http://d2908.ddns-instar.com:8082 and so on...</li>
          </ul>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

