import React from 'react'
import { Link } from 'gatsby'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

import cloudSearchIcon from '../../../images/Search/MD_SearchThumb_Cloud.webp'
import cloudAccountSearchIcon from '../../../images/Search/MD_SearchThumb_Cloud_Create.webp'
import cloudStorageSearchIcon from '../../../images/Search/MD_SearchThumb_Cloud_Storage.webp'
import cloudManagementSearchIcon from '../../../images/Search/MD_SearchThumb_Cloud_Management.webp'
import cloudAdministrationSearchIcon from '../../../images/Search/MD_SearchThumb_Cloud_Administration.webp'
import cloudRecordingsSearchIcon from '../../../images/Search/MD_SearchThumb_Cloud_Recordings.webp'
import cloudSetsSearchIcon from '../../../images/Search/MD_SearchThumb_Cloud_Sets.webp'


export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/INSTAR_Cloud/Benutzer_Account_Erstellen/">
                  <img
                    src={cloudAccountSearchIcon}
                    alt="User Account"
                  />
                </Link>
              }
              title="Benutzer Account"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/INSTAR_Cloud/Benutzer_Account_Erstellen/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/INSTAR_Cloud/Speicherplatz/">
                  <img
                    src={cloudStorageSearchIcon}
                    alt="Storage"
                  />
                </Link>
              }
              title="Speicherplatz"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/INSTAR_Cloud/Speicherplatz/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/INSTAR_Cloud/Benutzer/">
                  <img
                    src={cloudManagementSearchIcon}
                    alt="Management"
                  />
                </Link>
              }
              title="Benutzer"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/INSTAR_Cloud/Benutzer/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/INSTAR_Cloud/Administration/">
                  <img
                    src={cloudAdministrationSearchIcon}
                    alt="Administration"
                  />
                </Link>
              }
              title="Administration"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/INSTAR_Cloud/Administration/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/INSTAR_Cloud/Alarm_Aufnahmen/">
                  <img
                    src={cloudRecordingsSearchIcon}
                    alt="Alarm Recordings"
                  />
                </Link>
              }
              title="Alarm Aufnahmen"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/INSTAR_Cloud/Alarm_Aufnahmen/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/INSTAR_Cloud/Kamera_Sets/">
                  <img
                    src={cloudSetsSearchIcon}
                    alt="Kamera Sets"
                  />
                </Link>
              }
              title="Camera Sets"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/INSTAR_Cloud/Kamera_Sets/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/INSTAR_Cloud/Cloud_Erweiterungen/">
                  <img
                    src={cloudSearchIcon}
                    alt="Cloud Extensions"
                  />
                </Link>
              }
              title="Cloud Erweiterungen"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/INSTAR_Cloud/Cloud_Erweiterungen/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/INSTAR_Cloud/Account_Loeschen/">
                  <img
                    src={cloudAdministrationSearchIcon}
                    alt="Account Löschen"
                  />
                </Link>
              }
              title="Account Löschen"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/INSTAR_Cloud/Account_Loeschen/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
