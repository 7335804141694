import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Software-basierte Objekterkennung">
        <EuiText>
          <p>
          Um die softwarebasierte Objekterkennung Ihrer Kamera einzustellen, aktivieren Sie sie zunächst im Menü <Link to='/Web_User_Interface/1440p_Serie/Alarm/Aktionen/'>Alarm Aktionen</Link>. Verwenden Sie das <strong>Objekterkennung</strong> Menü, um Ihr Videobild mit zwei Begrenzungsrahmen zu versehen. Der äußere Rahmen gibt die maximale Größe an, die ein erkanntes Objekt haben darf, um einen Alarm auszulösen. Der innere Rahmen definiert die Mindestgröße, die erforderlich ist, damit eine Erkennung als Alarm behandelt wird.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

