import React from 'react'
import { OutboundLink } from 'gatsby-plugin-gtag'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Beachten Sie bitte">
        <EuiText>
        <p>Die Kamera kann sich im Anschluss nicht mehr in Ihr WLAN einwählen kann, da ihr der WLAN Schlüssel fehlt. Auch wird Sie vermutlich nicht mehr über die alte IP Adresse verfügen, sondern automatisch eine neue von Ihrem Router beziehen, sobald Sie die Kamera per LAN Kabel anschließen. Danach ist eine erneute Erstinstallation notwendig. Details hierzu finden Sie in unserem: <strong><OutboundLink href="https://install.instar.com/" target="_blank" rel="noopener noreferrer">Installation Wizard</OutboundLink></strong>.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

