import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="INSTAR MQTT Broker">
        <EuiText>
          <p>
            Wenn Sie eine INSTAR Full HD (1080p) Kamera verwenden, brauchen Sie keinen separaten MQTT Broker zu installieren, da Ihre Kamera bereits mit einem <Link to="/Erweitert/INSTAR_MQTT_Broker/">vorinstallierten INSTAR MQTT Broker</Link> ausgestattet ist.<br/><br/>    
            Wenn Sie eine INSTAR HD (720p) oder VGA-Kamera verwenden möchten, können Sie den Broker verwenden, der mit dem Home Assistant installiert werden kann - wie unten beschrieben. Um Ihre HD- oder VGA-Kamera an diesen Broker anzuschließen, können Sie ein Tool wie Node-RED verwenden - für das wir hier ein <Link to="/Software/Linux/Node-RED/">detailliertes Tutorial</Link> haben.<br/><br/>
            Sobald Sie über die MQTT-Schnittstelle auf die API Ihrer Kamera zugreifen können, können Sie die MQTT-Seite mit dem Home Assistant verbinden. Wie das aussieht, ist <Link to="/Erweitert/INSTAR_MQTT_Broker/Home_Assistant/">hier beschrieben für den INSTAR MQTT Broker</Link> und funktioniert genauso, wenn Sie Ihren eigenen MQTT Broker + Node-RED verwenden.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

