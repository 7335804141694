import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import {
  SortingState, SelectionState, FilteringState, GroupingState,
  IntegratedFiltering, IntegratedGrouping, IntegratedSelection,
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table, TableHeaderRow, TableFilterRow, TableGroupRow,
} from '@devexpress/dx-react-grid-material-ui'

import { withStyles } from '@material-ui/core/styles'

const styles = {
  customCell: {
    whiteSpace: "normal"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

CustomTableCellBase.propTypes = {
  column: PropTypes.shape({ name: PropTypes.string }).isRequired,
}

export const CustomTableCell = withStyles(styles)(CustomTableCellBase);

class MQTT2CGIGrid extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "category", title: "Kategorie" },
        { name: "feature", title: "MQTT Topic" },
        { name: "property", title: "CGI Command" }
      ],
      rows: [
        { category: 'Network', feature: 'network/config/dhcp', property: 'param.cgi?cmd=setnetattr&-dhcp=[val]' },
        { category: 'Network', feature: 'network/config/ipaddr', property: 'param.cgi?cmd=setnetattr&-ipaddr=[val]' },
        { category: 'Network', feature: 'network/config/netmask', property: 'param.cgi?cmd=setnetattr&-netmask=[val]' },
        { category: 'Network', feature: 'network/config/gateway', property: 'param.cgi?cmd=setnetattr&-gateway=[val]' },
        { category: 'Network', feature: 'network/config/dns', property: 'param.cgi?cmd=setnetattr&-fdnsip=[val]' },
        { category: 'Network', feature: 'network/config/http', property: 'param.cgi?cmd=sethttpport&-httpport=[val]' },
        { category: 'Network', feature: 'network/config/https', property: 'param.cgi?cmd=sethttpsport&-httpsport=[val]' },
        { category: 'Network', feature: 'network/config/rtspauth', property: 'param.cgi?cmd=setrtspauth&-rtsp_aenable=[val]' },
        { category: 'Network', feature: 'network/config/rtspport', property: 'param.cgi?cmd=setrtspport&-rtspport=[val]' },
        { category: 'Network', feature: 'network/config/rtmpport', property: 'param.cgi?cmd=setrtmpattr&-rtmpport=[val]' },
        { category: 'Network', feature: 'network/wifi/enable', property: 'param.cgi?cmd=setwirelessattr&-wf_enable=[val]' },
        { category: 'Network', feature: 'network/wifi/ssid', property: 'param.cgi?cmd=setwirelessattr&-wf_ssid=[val]' },
        { category: 'Network', feature: 'network/wifi/encryption', property: 'param.cgi?cmd=setwirelessattr&-wf_auth=[val]' },
        { category: 'Network', feature: 'network/wifi/key', property: 'param.cgi?cmd=setwirelessattr&-wf_key=[val]' },
        { category: 'Network', feature: 'network/wifi/encryptiontype', property: 'param.cgi?cmd=setwirelessattr&-wf_enc=[val]' },
        { category: 'Network', feature: 'network/remote/instarddns', property: 'param.cgi?cmd=setourddnsattr&-our_enable=[val]' },
        { category: 'Network', feature: 'network/remote/otherddns', property: 'param.cgi?cmd=set3thddnsattr&-d3th_enable=[val]' },
        { category: 'Network', feature: 'network/remote/instarp2p', property: 'cmd=setsmartp2pattr&-smartp2p_enable=[val]' },
        { category: 'Network', feature: 'network/upnp/enable', property: 'param.cgi?cmd=setupnpattr&-upm_enable=[val]' },
        { category: 'Network', feature: 'network/onvif/enable', property: 'param.cgi?cmd=setonvifattr&-ov_enable=[val]' },
        { category: 'Network', feature: 'network/onvif/auth', property: 'param.cgi?cmd=setonvifattr&-ov_authflag=[val]' },
        { category: 'Network', feature: 'network/onvif/timeset', property: 'param.cgi?cmd=setonvifattr&-ov_forbitset=[val]' },
        { category: 'Network', feature: 'network/onvif/port', property: 'param.cgi?cmd=setonvifattr&-ov_port=[val]' },
        { category: 'Network', feature: 'network/mqtt', property: 'param.cgi?cmd=set_instar_admin&-index=32&-value=[val]' },
        { category: 'Multimedia', feature: 'multimedia/audio/outvolume', property: 'param.cgi?cmd=setaudiooutvolume&-volume=[val]' },
        { category: 'Multimedia', feature: 'multimedia/audio/involume', property: 'param.cgi?cmd=setaudioinvolume&-volume=[val]' },
        { category: 'Multimedia', feature: 'multimedia/audio/enable/high', property: 'param.cgi?cmd=setaencattr&-chn=11&-aeswitch=[val]' },
        { category: 'Multimedia', feature: 'multimedia/audio/enable/mid', property: 'param.cgi?cmd=setaencattr&-chn=12&-aeswitch=[val]' },
        { category: 'Multimedia', feature: 'multimedia/audio/enable/low', property: 'param.cgi?cmd=setaencattr&-chn=13&-aeswitch=[val]' },
        { category: 'Multimedia', feature: 'multimedia/video/high/bitrate', property: 'param.cgi?cmd=setvencattr&-chn=11&-bps=[val]' },
        { category: 'Multimedia', feature: 'multimedia/video/mid/bitrate', property: 'param.cgi?cmd=setvencattr&-chn=12&-bps=[val]' },
        { category: 'Multimedia', feature: 'multimedia/video/low/bitrate', property: 'param.cgi?cmd=setvencattr&-chn=13&-bps=[val]' },
        { category: 'Multimedia', feature: 'multimedia/video/high/compression', property: 'param.cgi?cmd=setvencattr&-chn=11&-imagegrade=[val]' },
        { category: 'Multimedia', feature: 'multimedia/video/mid/compression', property: 'param.cgi?cmd=setvencattr&-chn=12&-imagegrade=[val]' },
        { category: 'Multimedia', feature: 'multimedia/video/low/compression', property: 'param.cgi?cmd=setvencattr&-chn=13&-imagegrade=[val]' },
        { category: 'Multimedia', feature: 'multimedia/video/high/vbr', property: 'param.cgi?cmd=setvencattr&-chn=11&-brmode=[val]' },
        { category: 'Multimedia', feature: 'multimedia/video/mid/vbr', property: 'param.cgi?cmd=setvencattr&-chn=12&-brmode=[val]' },
        { category: 'Multimedia', feature: 'multimedia/video/low/vbr', property: 'param.cgi?cmd=setvencattr&-chn=13&-brmode=[val]' },
        { category: 'Multimedia', feature: 'multimedia/video/high/fps', property: 'param.cgi?cmd=setvencattr&-chn=11&-fps=[val]' },
        { category: 'Multimedia', feature: 'multimedia/video/mid/fps', property: 'param.cgi?cmd=setvencattr&-chn=12&-fps=[val]' },
        { category: 'Multimedia', feature: 'multimedia/video/low/fps', property: 'param.cgi?cmd=setvencattr&-chn=13&-fps=[val]' },
        { category: 'Multimedia', feature: 'multimedia/video/high/gop', property: 'param.cgi?cmd=setvencattr&-chn=11&-gop=[val]' },
        { category: 'Multimedia', feature: 'multimedia/video/mid/gop', property: 'param.cgi?cmd=setvencattr&-chn=12&-fps=[val]' },
        { category: 'Multimedia', feature: 'multimedia/video/low/gop', property: 'param.cgi?cmd=setvencattr&-chn=13&-fps=[val]' },
        { category: 'Multimedia', feature: 'multimedia/video/webui', property: 'param.cgi?cmd=set_instar_guest&-index=40&-value=[val]' },
        { category: 'Multimedia', feature: 'multimedia/video/mjpeg', property: 'param.cgi?cmd=set_instar_admin&-index=41&-value=[val]' },
        { category: 'Multimedia', feature: 'multimedia/image/brightness', property: 'param.cgi?cmd=setimageattr&-image_type=1&-brightness=[val]' },
        { category: 'Multimedia', feature: 'multimedia/image/brightness/night', property: 'param.cgi?cmd=setimageattr&-image_type=0&-brightness=[val]' },
        { category: 'Multimedia', feature: 'multimedia/image/saturation', property: 'param.cgi?cmd=setimageattr&-image_type=1&-saturation=[val]' },
        { category: 'Multimedia', feature: 'multimedia/image/saturation/night', property: 'param.cgi?cmd=setimageattr&-image_type=0&-saturation=[val]' },
        { category: 'Multimedia', feature: 'multimedia/image/hue', property: 'param.cgi?cmd=setimageattr&-image_type=1&-hue=[val]' },
        { category: 'Multimedia', feature: 'multimedia/image/hue/night', property: 'param.cgi?cmd=setimageattr&-image_type=0&-hue=[val]' },
        { category: 'Multimedia', feature: 'multimedia/image/contrast', property: 'param.cgi?cmd=setimageattr&-image_type=1&-contrast=[val]' },
        { category: 'Multimedia', feature: 'multimedia/image/contrast/night', property: 'param.cgi?cmd=setimageattr&-image_type=0&-contrast=[val]' },
        { category: 'Multimedia', feature: 'multimedia/image/sharpness', property: 'param.cgi?cmd=setimageattr&-image_type=1&-sharpness=[val]' },
        { category: 'Multimedia', feature: 'multimedia/image/sharpness/night', property: 'param.cgi?cmd=setimageattr&-image_type=0&-sharpness=[val]' },
        { category: 'Multimedia', feature: 'multimedia/image/gamma', property: 'param.cgi?cmd=setimageattr&-image_type=1&-gamma=[val]' },
        { category: 'Multimedia', feature: 'multimedia/image/gamma/night', property: 'param.cgi?cmd=setimageattr&-image_type=0&-gamma=[val]' },
        { category: 'Multimedia', feature: 'multimedia/image/exposure', property: 'param.cgi?cmd=setimageattr&-image_type=1&-targety=[val]' },
        { category: 'Multimedia', feature: 'multimedia/image/exposure/night', property: 'param.cgi?cmd=setimageattr&-image_type=0&-targety=[val]' },
        { category: 'Multimedia', feature: 'multimedia/image/flip', property: 'param.cgi?cmd=setimageattr&-image_type=1&-flip=[val]' },
        { category: 'Multimedia', feature: 'multimedia/image/flip/night', property: 'param.cgi?cmd=setimageattr&-image_type=0&-flip=[val]' },
        { category: 'Multimedia', feature: 'multimedia/image/mirror', property: 'param.cgi?cmd=setimageattr&-image_type=1&-mirror=[val]' },
        { category: 'Multimedia', feature: 'multimedia/image/mirror/night', property: 'param.cgi?cmd=setimageattr&-image_type=0&-mirror=[val]' },
        { category: 'Multimedia', feature: 'multimedia/image/autodenoise', property: 'param.cgi?cmd=setimageattrex&-d3noauto=[val]' },
        { category: 'Multimedia', feature: 'multimedia/image/manualdenoise', property: 'param.cgi?cmd=setimageattrex&-d3noval=[val]' },
        { category: 'Multimedia', feature: 'multimedia/image/autowdr', property: 'param.cgi?cmd=setimageattrex&-wdrauto=[val]' },
        { category: 'Multimedia', feature: 'multimedia/image/autowdrval', property: 'param.cgi?cmd=setimageattrex&-wdrautval=[val]' },
        { category: 'Multimedia', feature: 'multimedia/image/manualwdrval', property: 'param.cgi?cmd=setimageattrex&-wdrmanval=[val]' },
        { category: 'Multimedia', feature: 'multimedia/image/hardwarewdr', property: 'param.cgi?cmd=setvideoattr&-wdrmode=[val]' },
        { category: 'Multimedia', feature: 'multimedia/image/lenserectify', property: 'param.cgi?cmd=setldcattr&-ldc_ratio=[val]' },
        { category: 'Multimedia', feature: 'multimedia/overlay/showname', property: 'param.cgi?cmd=setoverlayattr&-region=1&-show=[val]' },
        { category: 'Multimedia', feature: 'multimedia/overlay/cameraname', property: 'param.cgi?cmd=setoverlayattr&-region=1&-name=[val]' },
        { category: 'Multimedia', feature: 'multimedia/overlay/showtimestamp', property: 'param.cgi?cmd=setoverlayattr&-region=0&-show=[val]' },
        { category: 'Multimedia', feature: 'multimedia/privacy/region1/enable', property: 'param.cgi?cmd=setcover&-region=1&-show=[val]' },
        { category: 'Multimedia', feature: 'multimedia/privacy/region1/color', property: 'param.cgi?cmd=setcover&-region=1&-color=[val]' },
        { category: 'Multimedia', feature: 'multimedia/privacy/region1/xorigin', property: 'param.cgi?cmd=setcover&-region=1&-x=[val]' },
        { category: 'Multimedia', feature: 'multimedia/privacy/region1/yorigin', property: 'param.cgi?cmd=setcover&-region=1&-y=[val]' },
        { category: 'Multimedia', feature: 'multimedia/privacy/region1/height', property: 'param.cgi?cmd=setcover&-region=1&-h=[val]' },
        { category: 'Multimedia', feature: 'multimedia/privacy/region1/width', property: 'param.cgi?cmd=setcover&-region=1&-w=[val]' },
        { category: 'Multimedia', feature: 'multimedia/privacy/region2/enable', property: 'param.cgi?cmd=setcover&-region=2&-show=[val]' },
        { category: 'Multimedia', feature: 'multimedia/privacy/region2/color', property: 'param.cgi?cmd=setcover&-region=2&-color=[val]' },
        { category: 'Multimedia', feature: 'multimedia/privacy/region2/xorigin', property: 'param.cgi?cmd=setcover&-region=21&-x=[val]' },
        { category: 'Multimedia', feature: 'multimedia/privacy/region2/yorigin', property: 'param.cgi?cmd=setcover&-region=2&-y=[val]' },
        { category: 'Multimedia', feature: 'multimedia/privacy/region2/height', property: 'param.cgi?cmd=setcover&-region=2&-h=[val]' },
        { category: 'Multimedia', feature: 'multimedia/privacy/region2/width', property: 'param.cgi?cmd=setcover&-region=2&-w=[val]' },
        { category: 'Multimedia', feature: 'multimedia/privacy/region3/enable', property: 'param.cgi?cmd=setcover&-region=3&-show=[val]' },
        { category: 'Multimedia', feature: 'multimedia/privacy/region3/color', property: 'param.cgi?cmd=setcover&-region=3&-color=[val]' },
        { category: 'Multimedia', feature: 'multimedia/privacy/region3/xorigin', property: 'param.cgi?cmd=setcover&-region=3&-x=[val]' },
        { category: 'Multimedia', feature: 'multimedia/privacy/region3/yorigin', property: 'param.cgi?cmd=setcover&-region=3&-y=[val]' },
        { category: 'Multimedia', feature: 'multimedia/privacy/region3/height', property: 'param.cgi?cmd=setcover&-region=3&-h=[val]' },
        { category: 'Multimedia', feature: 'multimedia/privacy/region3/width', property: 'param.cgi?cmd=setcover&-region=3&-w=[val]' },
        { category: 'Multimedia', feature: 'multimedia/privacy/region4/enable', property: 'param.cgi?cmd=setcover&-region=4&-show=[val]' },
        { category: 'Multimedia', feature: 'multimedia/privacy/region4/color', property: 'param.cgi?cmd=setcover&-region=4&-color=[val]' },
        { category: 'Multimedia', feature: 'multimedia/privacy/region4/xorigin', property: 'param.cgi?cmd=setcover&-region=4&-x=[val]' },
        { category: 'Multimedia', feature: 'multimedia/privacy/region4/yorigin', property: 'param.cgi?cmd=setcover&-region=4&-y=[val]' },
        { category: 'Multimedia', feature: 'multimedia/privacy/region4/height', property: 'param.cgi?cmd=setcover&-region=4&-h=[val]' },
        { category: 'Multimedia', feature: 'multimedia/privacy/region4/width', property: 'param.cgi?cmd=setcover&-region=4&-w=[val]' },
        { category: 'Features', feature: 'features/email/sender', property: 'param.cgi?cmd=setsmtpattr&-ma_from=[val]' },
        { category: 'Features', feature: 'features/email/receiver', property: 'param.cgi?cmd=setsmtpattr&-ma_to=[val]' },
        { category: 'Features', feature: 'features/email/subject', property: 'param.cgi?cmd=setsmtpattr&-ma_subject=[val]' },
        { category: 'Features', feature: 'features/email/text', property: 'param.cgi?cmd=setsmtpattr&-ma_text=[val]' },
        { category: 'Features', feature: 'features/email/server', property: 'param.cgi?cmd=setsmtpattr&-ma_server=[val]' },
        { category: 'Features', feature: 'features/email/ssl', property: 'param.cgi?cmd=setsmtpattr&-ma_ssl=[val]' },
        { category: 'Features', feature: 'features/email/port', property: 'param.cgi?cmd=setsmtpattr&-ma_port=[val]' },
        { category: 'Features', feature: 'features/email/authentication', property: 'param.cgi?cmd=setsmtpattr&-ma_logintype=[val]' },
        { category: 'Features', feature: 'features/email/username', property: 'param.cgi?cmd=setsmtpattr&-ma_username=[val]' },
        { category: 'Features', feature: 'features/email/password', property: 'param.cgi?cmd=setsmtpattr&-ma_password=[val]' },
        { category: 'Features', feature: 'features/ftp/server', property: 'param.cgi?cmd=setftpattr&-ft_server=[val]' },
        { category: 'Features', feature: 'features/ftp/port', property: 'param.cgi?cmd=setftpattr&-ft_port=[val]' },
        { category: 'Features', feature: 'features/ftp/username', property: 'param.cgi?cmd=setftpattr&-ft_username=[val]' },
        { category: 'Features', feature: 'features/ftp/password', property: 'param.cgi?cmd=setftpattr&-ft_password=[val]' },
        { category: 'Features', feature: 'features/ftp/pasvmode', property: 'param.cgi?cmd=setftpattr&-ft_mode=[val]' },
        { category: 'Features', feature: 'features/ftp/dirname', property: 'param.cgi?cmd=setftpattr&-ft_dirname=[val]' },
        { category: 'Features', feature: 'features/ftp/dirmode', property: 'param.cgi?cmd=setftpattr&-ft_dirmode=[val]' },
        { category: 'Features', feature: 'features/ftp/ssl', property: 'param.cgi?cmd=setftpattr&-ft_ssl=[val]' },
        { category: 'Features', feature: 'features/nightvision/autoled', property: 'param.cgi?cmd=setinfrared&-infraredstat=[val]' },
        { category: 'Features', feature: 'features/nightvision/autoircut', property: 'param.cgi?cmd=set_instar_admin&-index=2&-value=[val]' },
        { category: 'Features', feature: 'features/nightvision/manualswitchon', property: 'param.cgi?cmd=setplancgi&-plancgi_enable_0=[val]' },
        { category: 'Features', feature: 'features/nightvision/manualswitchontime', property: 'param.cgi?cmd=setplancgi&-plancgi_time_0=[val]' },
        { category: 'Features', feature: 'features/nightvision/manualswitchoff', property: 'param.cgi?cmd=setplancgi&-plancgi_enable_1=[val]' },
        { category: 'Features', feature: 'features/nightvision/manualswitchofftime', property: 'param.cgi?cmd=setplancgi&-plancgi_time_1=[val]' },
        { category: 'Features', feature: 'features/nightvision/upperirthreshold', property: 'param.cgi?cmd=setircutattr&-saradc_switch_value=[val]' },
        { category: 'Features', feature: 'features/nightvision/lowerirthreshold', property: 'param.cgi?cmd=setircutattr&-saradc_b2c_switch_value=[val]' },
        { category: 'Features', feature: 'features/nightvision/currentbrightness', property: 'param.cgi?cmd=getsaradcstate' },
        { category: 'Features', feature: 'features/ptz/move', property: 'ptzctrl.cgi?-step=0&-act=[val]&-speed=63' },
        { category: 'Features', feature: 'features/ptz/movestep', property: 'ptzctrl.cgi?-step=1&-act=[val]&-speed=63' },
        { category: 'Features', feature: 'features/ptz/preset', property: 'param.cgi?cmd=preset&-act=goto&-number=[val]' },
        { category: 'Features', feature: 'features/ptz/savepreset', property: 'param.cgi?cmd=preset&-act=set&-status=1&-number=[val]' },
        { category: 'Features', feature: 'features/ptz/scan', property: 'param.cgi?cmd=ptzctrl&-act=[val]' },
        { category: 'Features', feature: 'features/ptz/calibration/enable', property: 'param.cgi?cmd=setmotorattr&-selfdet=[val]' },
        { category: 'Features', feature: 'features/ptz/startpreset/enable', property: 'param.cgi?cmd=setmotorattr&-movehome=[val]' },
        { category: 'Features', feature: 'features/ptz/startpreset/position', property: 'param.cgi?cmd=setmotorattr&-initpresetindex=[val]' },
        { category: 'Features', feature: 'features/ptz/alarmpreset/enable', property: 'param.cgi?cmd=setmdalarm&-aname=preset&-switch=[val]' },
        { category: 'Features', feature: 'features/ptz/alarmpreset/position', property: 'param.cgi?cmd=setmotorattr&-alarmpresetindex=[val]' },
        { category: 'Features', feature: 'features/ptz/ptzalarmmask', property: 'param.cgi?cmd=setmotorattr&-ptzalarmmask=[val]' },
        { category: 'Features', feature: 'features/ptz/panspeed', property: 'param.cgi?cmd=setmotorattr&-panspeed=[val]' },
        { category: 'Features', feature: 'features/ptz/tiltspeed', property: 'param.cgi?cmd=setmotorattr&-tiltspeed=[val]' },
        { category: 'Features', feature: 'features/ptz/panscan', property: 'param.cgi?cmd=setmotorattr&-panscan=[val]' },
        { category: 'Features', feature: 'features/ptz/tiltscan', property: 'param.cgi?cmd=setmotorattr&-tiltscan=[val]' },
        { category: 'Features', feature: 'features/ptz/parkpreset/enable', property: 'param.cgi?cmd=settimerpreset&-timerpreset_enable=[val]' },
        { category: 'Features', feature: 'features/ptz/parkpreset/position', property: 'param.cgi?cmd=settimerpreset&-timerpreset_index=[val]' },
        { category: 'Features', feature: 'features/ptz/parkpreset/interval', property: 'param.cgi?cmd=settimerpreset&-timerpreset_interval=[val]' },
        { category: 'Features', feature: 'features/ptz/tourpresets', property: 'param.cgi?cmd=setptztour&-tour_index=[val]' },
        { category: 'Features', feature: 'features/ptz/tourinterval', property: 'param.cgi?cmd=setptztour&-tour_interval=[val]' },
        { category: 'Features', feature: 'features/ptz/tourrepeats', property: 'param.cgi?cmd=setptztour&-tour_times=[val]' },
        { category: 'Features', feature: 'features/manualrec/duration', property: 'param.cgi?cmd=set_instar_admin&-index=44&-value=[val]' },
        { category: 'Features', feature: 'features/manualrec/start', property: 'param.cgi?cmd=manualrec&-act=on&-time=[val]' },
        { category: 'Features', feature: 'features/manualrec/stop', property: 'param.cgi?cmd=manualrec&-act=[val]' },
        { category: 'Features', feature: 'features/indicator/power', property: 'param.cgi?cmd=setlightattr&-light_index=2&-light_enable=[val]' },
        { category: 'Features', feature: 'features/indicator/wifi', property: 'param.cgi?cmd=setlightattr&-light_index=1&-light_enable=[val]' },
        { category: 'Alarm', feature: 'alarm/pushalarm', property: 'param.cgi?cmd=pushhostalarm' },
        { category: 'Alarm', feature: 'alarm/actions/email', property: 'param.cgi?cmd=setmdalarm&-aname=emailsnap&-switch=[val]' },
        { category: 'Alarm', feature: 'alarm/actions/snapshot2sd', property: 'param.cgi?cmd=setmdalarm&-aname=snap&-switch=[val]' },
        { category: 'Alarm', feature: 'alarm/actions/snapshot2ftp', property: 'param.cgi?cmd=setmdalarm&-aname=ftpsnap&-switch=[val]' },
        { category: 'Alarm', feature: 'alarm/actions/alarmsnapshots/sd', property: 'param.cgi?cmd=setalarmsnapattr&-snap_chn=11&-snap_count=[val]' },
        { category: 'Alarm', feature: 'alarm/actions/alarmsnapshots/email', property: 'param.cgi?cmd=setalarmsnapattr&-snap_chn=11&-email_snap_count=[val]' },
        { category: 'Alarm', feature: 'alarm/actions/alarmsnapshots/ftp', property: 'param.cgi?cmd=setalarmsnapattr&-snap_chn=11&-ftp_snap_count=[val]' },
        { category: 'Alarm', feature: 'alarm/actions/video2sd', property: 'param.cgi?cmd=setmdalarm&-aname=record&-switch=[val]' },
        { category: 'Alarm', feature: 'alarm/actions/video2ftp', property: 'param.cgi?cmd=setmdalarm&-aname=ftprec&-switch=[val]' },
        { category: 'Alarm', feature: 'alarm/actions/videolength', property: 'param.cgi?cmd=set_instar_admin&-index=45&-value=[val]' },
        { category: 'Alarm', feature: 'alarm/actions/pir/enable', property: 'param.cgi?cmd=setpirattr&-pir_enable=[val]' },
        { category: 'Alarm', feature: 'alarm/actions/pir/flag', property: 'param.cgi?cmd=setpirattr&-pir_flag=[val]' },
        { category: 'Alarm', feature: 'alarm/actions/linkareas', property: 'param.cgi?cmd=setmdalarm&-aname=type&-switch=[val]' },
        { category: 'Alarm', feature: 'alarm/actions/alarmout', property: 'param.cgi?cmd=setmdalarm&-aname=relay&-switch=[val]' },
        { category: 'Alarm', feature: 'alarm/actions/alarmin', property: 'param.cgi?cmd=setioattr&-io_enable=[val]' },
        { category: 'Alarm', feature: 'alarm/actions/alarminmode', property: 'param.cgi?cmd=setioattr&-io_flag=[val]' },
        { category: 'Alarm', feature: 'alarm/actions/alarmsignal', property: 'param.cgi?cmd=setmdalarm&-aname=sound&-switch=[val]' },
        { category: 'Alarm', feature: 'alarm/actions/audioalarm', property: 'param.cgi?cmd=setaudioalarmattr&-aa_enable=[val]' },
        { category: 'Alarm', feature: 'alarm/actions/audioalarmsensitivity', property: 'param.cgi?cmd=setaudioalarmattr&-aa_value=[val]' },
        { category: 'Alarm', feature: 'alarm/area1/enable', property: 'param.cgi?cmd=setmdattr&-name=1&-enable=[val]' },
        { category: 'Alarm', feature: 'alarm/area1/sensitivity', property: 'param.cgi?cmd=setmdattr&-name=1&-s=[val]' },
        { category: 'Alarm', feature: 'alarm/area1/xorigin', property: 'pparam.cgi?cmd=setmdattr&-name=1&-x=[val]' },
        { category: 'Alarm', feature: 'alarm/area1/yorigin', property: 'pparam.cgi?cmd=setmdattr&-name=1&-y=[val]' },
        { category: 'Alarm', feature: 'alarm/area1/height', property: 'pparam.cgi?cmd=setmdattr&-name=1&-h=[val]' },
        { category: 'Alarm', feature: 'alarm/area1/width', property: 'pparam.cgi?cmd=setmdattr&-name=1&-w=[val]' },
        { category: 'Alarm', feature: 'alarm/area2/enable', property: 'param.cgi?&cmd=setmdattr&-name=2&-enable=[val]' },
        { category: 'Alarm', feature: 'alarm/area2/sensitivity', property: 'param.cgi?cmd=setmdattr&-name=2&-s=[val]' },
        { category: 'Alarm', feature: 'alarm/area2/xorigin', property: 'pparam.cgi?cmd=setmdattr&-name=2&-x=[val]' },
        { category: 'Alarm', feature: 'alarm/area2/yorigin', property: 'pparam.cgi?cmd=setmdattr&-name=2&-y=[val]' },
        { category: 'Alarm', feature: 'alarm/area2/height', property: 'pparam.cgi?cmd=setmdattr&-name=2&-h=[val]' },
        { category: 'Alarm', feature: 'alarm/area2/width', property: 'pparam.cgi?cmd=setmdattr&-name=2&-w=[val]' },
        { category: 'Alarm', feature: 'alarm/area3/enable', property: 'param.cgi?cmd=setmdattr&-name=3&-enable=[val]' },
        { category: 'Alarm', feature: 'alarm/area3/sensitivity', property: 'param.cgi?cmd=setmdattr&-name=3&-s=[val]' },
        { category: 'Alarm', feature: 'alarm/area3/xorigin', property: 'pparam.cgi?cmd=setmdattr&-name=3&-x=[val]' },
        { category: 'Alarm', feature: 'alarm/area3/yorigin', property: 'pparam.cgi?cmd=setmdattr&-name=3&-y=[val]' },
        { category: 'Alarm', feature: 'alarm/area3/height', property: 'pparam.cgi?cmd=setmdattr&-name=3&-h=[val]' },
        { category: 'Alarm', feature: 'alarm/area3/width', property: 'pparam.cgi?cmd=setmdattr&-name=3&-w=[val]' },
        { category: 'Alarm', feature: 'alarm/area4/enable', property: 'param.cgi?cmd=setmdattr&-name=4&-enable=[val]' },
        { category: 'Alarm', feature: 'alarm/area4/sensitivity', property: 'param.cgi?cmd=setmdattr&-name=4&-s=[val]' },
        { category: 'Alarm', feature: 'alarm/area4/xorigin', property: 'pparam.cgi?cmd=setmdattr&-name=4&-x=[val]' },
        { category: 'Alarm', feature: 'alarm/area4/yorigin', property: 'pparam.cgi?cmd=setmdattr&-name=4&-y=[val]' },
        { category: 'Alarm', feature: 'alarm/area4/height', property: 'pparam.cgi?cmd=setmdattr&-name=4&-h=[val]' },
        { category: 'Alarm', feature: 'alarm/area4/width', property: 'pparam.cgi?cmd=setmdattr&-name=4&-w=[val]' },
        { category: 'Alarm', feature: 'alarm/schedule/sunday', property: 'param.cgi?cmd=setscheduleex&-ename=md&-week0=[val]' },
        { category: 'Alarm', feature: 'alarm/schedule/monday', property: 'param.cgi?cmd=setscheduleex&-ename=md&-week1=[val]' },
        { category: 'Alarm', feature: 'alarm/schedule/tuesday', property: 'param.cgi?cmd=setscheduleex&-ename=md&-week2=[val]' },
        { category: 'Alarm', feature: 'alarm/schedule/wednesday', property: 'param.cgi?cmd=setscheduleex&-ename=md&-week3=[val]' },
        { category: 'Alarm', feature: 'alarm/schedule/thursday', property: 'param.cgi?cmd=setscheduleex&-ename=md&-week4=[val]' },
        { category: 'Alarm', feature: 'alarm/schedule/friday', property: 'param.cgi?cmd=setscheduleex&-ename=md&-week5=[val]' },
        { category: 'Alarm', feature: 'alarm/schedule/saturday', property: 'param.cgi?cmd=setscheduleex&-ename=md&-week6=[val]' },
        { category: 'Alarm', feature: 'alarm/schedule/sunday/webui', property: 'param.cgi?cmd=setbackupschedule&-ename=md&-week0=[val]' },
        { category: 'Alarm', feature: 'alarm/schedule/monday/webui', property: 'param.cgi?cmd=setbackupschedule&-ename=md&-week1=[val]' },
        { category: 'Alarm', feature: 'alarm/schedule/tuesday/webui', property: 'param.cgi?cmd=setbackupschedule&-ename=md&-week2=[val]' },
        { category: 'Alarm', feature: 'alarm/schedule/wednesday/webui', property: 'param.cgi?cmd=setbackupschedule&-ename=md&-week3=[val]' },
        { category: 'Alarm', feature: 'alarm/schedule/thursday/webui', property: 'param.cgi?cmd=setbackupschedule&-ename=md&-week4=[val]' },
        { category: 'Alarm', feature: 'alarm/schedule/friday/webui', property: 'param.cgi?cmd=setbackupschedule&-ename=md&-week5=[val]' },
        { category: 'Alarm', feature: 'alarm/schedule/saturday/webui', property: 'param.cgi?cmd=setbackupschedule&-ename=md&-week6=[val]' },
        { category: 'Alarm', feature: 'alarm/schedule/areagroup12/enable', property: 'param.cgi?cmd=setplancgi&-plancgi_enable_3=[val]' },
        { category: 'Alarm', feature: 'alarm/schedule/areagroup12/time', property: 'param.cgi?cmd=setplancgi&-plancgi_time_3=[val]' },
        { category: 'Alarm', feature: 'alarm/schedule/areagroup34/enable', property: 'param.cgi?cmd=setplancgi&-plancgi_enable_4=[val]' },
        { category: 'Alarm', feature: 'alarm/schedule/areagroup34/time', property: 'param.cgi?cmd=setplancgi&-plancgi_time_4=[val]' },
        { category: 'Alarm', feature: 'alarm/push/enable', property: 'param.cgi?cmd=setmdalarm&-aname=server&-switch=[val]' },
        { category: 'Alarm', feature: 'alarm/alarmserver/enable', property: 'param.cgi?cmd=setmdalarm&-aname=server2&-switch=[val]' },
        { category: 'Alarm', feature: 'alarm/alarmserver/address', property: 'param.cgi?cmd=setalarmserverattr&-as_index=3&-as_server=[val]' },
        { category: 'Alarm', feature: 'alarm/alarmserver/port', property: 'param.cgi?cmd=setalarmserverattr&-as_index=3&-as_port=[val]' },
        { category: 'Alarm', feature: 'alarm/alarmserver/path', property: 'param.cgi?cmd=setalarmserverattr&-as_index=3&-as_path=[val]' },
        { category: 'Alarm', feature: 'alarm/alarmserver/query1', property: 'param.cgi?cmd=setalarmserverattr&-as_index=3&-as_query1=[val]' },
        { category: 'Alarm', feature: 'alarm/alarmserver/queryattr1', property: 'param.cgi?cmd=setalarmserverattr&-as_index=3&-as_queryattr1=[val]' },
        { category: 'Alarm', feature: 'alarm/alarmserver/queryval1', property: 'param.cgi?cmd=setalarmserverattr&-as_index=3&-as_queryval1=[val]' },
        { category: 'Alarm', feature: 'alarm/alarmserver/query2', property: 'param.cgi?cmd=setalarmserverattr&-as_index=3&-as_query2=[val]' },
        { category: 'Alarm', feature: 'alarm/alarmserver/queryattr2', property: 'param.cgi?cmd=setalarmserverattr&-as_index=3&-as_queryattr2=[val]' },
        { category: 'Alarm', feature: 'alarm/alarmserver/queryval2', property: 'param.cgi?cmd=setalarmserverattr&-as_index=3&-as_queryval2=[val]' },
        { category: 'Alarm', feature: 'alarm/alarmserver/query3', property: 'param.cgi?cmd=setalarmserverattr&-as_index=3&-as_query3=[val]' },
        { category: 'Alarm', feature: 'alarm/alarmserver/queryattr3', property: 'param.cgi?cmd=setalarmserverattr&-as_index=3&-as_queryattr3=[val]' },
        { category: 'Alarm', feature: 'alarm/alarmserver/queryval3', property: 'param.cgi?cmd=setalarmserverattr&-as_index=3&-as_queryval3=[val]' },
        { category: 'Alarm', feature: 'alarm/alarmserver/appendtrigger', property: 'param.cgi?cmd=setalarmserverattr&-as_index=3&-as_activequery=[val]' },
        { category: 'Alarm', feature: 'alarm/alarmserver/authentication', property: 'param.cgi?cmd=setalarmserverattr&-as_index=3&-as_auth=[val]' },
        { category: 'Alarm', feature: 'alarm/alarmserver/username', property: 'param.cgi?cmd=setalarmserverattr&-as_index=3&-as_username=[val]' },
        { category: 'Alarm', feature: 'alarm/alarmserver/password', property: 'param.cgi?cmd=setalarmserverattr&-as_index=3&-as_password=[val]' },
        { category: 'Tasks', feature: 'task/photoseries/sdcard/enable', property: 'param.cgi?cmd=setsnaptimerattrex&-as_type=snap&-as_enable=[val]' },
        { category: 'Tasks', feature: 'task/photoseries/sdcard/interval', property: 'param.cgi?cmd=setsnaptimerattrex&-as_type=snap&-as_interval=[val]' },
        { category: 'Tasks', feature: 'task/photoseries/email/enable', property: 'param.cgi?cmd=setsnaptimerattrex&-as_type=email&-as_enable=[val]' },
        { category: 'Tasks', feature: 'task/photoseries/email/interval', property: 'param.cgi?cmd=setsnaptimerattrex&-as_type=email&-as_interval=[val]' },
        { category: 'Tasks', feature: 'task/photoseries/ftp/enable', property: 'param.cgi?cmd=setsnaptimerattrex&-as_type=ftp&-as_enable=[val]' },
        { category: 'Tasks', feature: 'task/photoseries/ftp/interval', property: 'param.cgi?cmd=setsnaptimerattrex&-as_type=ftp&-as_interval=[val]' },
        { category: 'Tasks', feature: 'task/photoseries/ftp/fixedname', property: 'param.cgi?cmd=setalarmsnapattr&-snap_name_mode=[val]' },
        { category: 'Tasks', feature: 'task/photoseries/ftp/filename', property: 'param.cgi?cmd=setalarmsnapattr&-snap_timer_name=[val]' },
        { category: 'Tasks', feature: 'task/photoseries/schedule/sunday', property: 'param.cgi?cmd=setscheduleex&-ename=snap&-week0=[val]' },
        { category: 'Tasks', feature: 'task/photoseries/schedule/monday', property: 'param.cgi?cmd=setscheduleex&-ename=snap&-week1=[val]' },
        { category: 'Tasks', feature: 'task/photoseries/schedule/tuesday', property: 'param.cgi?cmd=setscheduleex&-ename=snap&-week2=[val]' },
        { category: 'Tasks', feature: 'task/photoseries/schedule/wednesday', property: 'param.cgi?cmd=setscheduleex&-ename=snap&-week3=[val]' },
        { category: 'Tasks', feature: 'task/photoseries/schedule/thursday', property: 'param.cgi?cmd=setscheduleex&-ename=snap&-week4=[val]' },
        { category: 'Tasks', feature: 'task/photoseries/schedule/friday', property: 'param.cgi?cmd=setscheduleex&-ename=snap&-week5=[val]' },
        { category: 'Tasks', feature: 'task/photoseries/schedule/saturday', property: 'param.cgi?cmd=setscheduleex&-ename=snap&-week6=[val]' },
        { category: 'Tasks', feature: 'task/videorecording/enable', property: 'param.cgi?cmd=setplanrecattr&-planrec_enable=[val]' },
        { category: 'Tasks', feature: 'task/videorecording/resolution', property: 'param.cgi?cmd=setplanrecattr&-planrec_chn=[val]' },
        { category: 'Tasks', feature: 'task/videorecording/length', property: 'param.cgi?cmd=setplanrecattr&-planrec_time=[val]' },
        { category: 'Tasks', feature: 'task/videorecording/schedule/sunday', property: 'param.cgi?cmd=setscheduleex&-ename=snap&-week0=[val]' },
        { category: 'Tasks', feature: 'task/videorecording/schedule/monday', property: 'param.cgi?cmd=setscheduleex&-ename=snap&-week1=[val]' },
        { category: 'Tasks', feature: 'task/videorecording/schedule/tuesday', property: 'param.cgi?cmd=setscheduleex&-ename=snap&-week2=[val]' },
        { category: 'Tasks', feature: 'task/videorecording/schedule/wednesday', property: 'param.cgi?cmd=setscheduleex&-ename=snap&-week3=[val]' },
        { category: 'Tasks', feature: 'task/videorecording/schedule/thursday', property: 'param.cgi?cmd=setscheduleex&-ename=snap&-week4=[val]' },
        { category: 'Tasks', feature: 'task/videorecording/schedule/friday', property: 'param.cgi?cmd=setscheduleex&-ename=snap&-week5=[val]' },
        { category: 'Tasks', feature: 'task/videorecording/schedule/saturday', property: 'param.cgi?cmd=setscheduleex&-ename=snap&-week6=[val]' },
        { category: 'System', feature: 'system/user/admin/name', property: 'param.cgi?cmd=setuserattr&-at_username=admin&-at_newname=[val]' },
        { category: 'System', feature: 'system/user/admin/password', property: 'param.cgi?cmd=setuserattr&-at_username=admin&-at_password=[val]' },
        { category: 'System', feature: 'system/user/user/enable', property: 'param.cgi?cmd=setuserattr&-at_username=user&-at_enable=[val]' },
        { category: 'System', feature: 'system/user/user/name', property: 'param.cgi?cmd=setuserattr&-at_username=user&-at_newname=[val]' },
        { category: 'System', feature: 'system/user/user/password', property: 'param.cgi?cmd=setuserattr&-at_username=user&-at_password=[val]' },
        { category: 'System', feature: 'system/user/guest/enable', property: 'param.cgi?cmd=setuserattr&-at_username=guest&-at_enable=[val]' },
        { category: 'System', feature: 'system/user/guest/name', property: 'param.cgi?cmd=setuserattr&-at_username=guest&-at_newname=[val]' },
        { category: 'System', feature: 'system/user/guest/password', property: 'param.cgi?cmd=setuserattr&-at_username=guest&-at_password=[val]' },
        { category: 'System', feature: 'system/user/iplogging', property: 'param.cgi?cmd=setlogattr&-log_mode=[val]' },
        { category: 'System', feature: 'system/reboot', property: 'param.cgi?cmd=sysreboot' },
        { category: 'System', feature: 'system/reboot/planned', property: 'param.cgi?cmd=setplancgi&-plancgi_enable_2=[val]' },
        { category: 'System', feature: 'system/reboot/time', property: 'param.cgi?cmd=setplancgi&-plancgi_time_2=[val]' },
        { category: 'System', feature: 'system/reset', property: 'param.cgi?cmd=sysreset.cgi' }
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper>
        <Grid rows={rows} columns={columns}>

          <FilteringState />

          <SortingState
            defaultSorting={[
              { columnName: 'category', direction: 'asc' },
            ]}
          />
          <GroupingState
            defaultGrouping={[{ columnName: 'category' }]}
            defaultExpandedGroups={['Network']}
          />
          <SelectionState />

          <IntegratedFiltering />
          <IntegratedGrouping />
          <IntegratedSelection />
          <Table cellComponent={CustomTableCell} />
          <TableHeaderRow />
          <TableFilterRow />
          <TableGroupRow />
        </Grid>
      </Paper>
    );
  }
}

MQTT2CGIGrid.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(MQTT2CGIGrid)