import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class ImageAdvancedTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Erweiterte Bildeinstellungen" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Bild um 180° drehen', descriptionColumn: 'Falls Sie Ihre Kamera Über-Kopf installiert haben, drehen und spiegeln Sie einfach das Bild, um es wieder richtig zu sehen.' },
        { actionColumn: 'Bild entzerren', descriptionColumn: 'Gerade Weitwinkel-Objektive können Ihr Kamerabild verzerren. Nutzen Sie diesen Slider, um eine Korrektur vorzunehmen.' },
        { actionColumn: 'Wide Dynamic Range', descriptionColumn: 'Ist Ihre Kamera einer starken Hintergrundbeleuchtung ausgesetzt? Mit dem leistungsstarken Hardware-Wide-Dynamic-Range-Modus können Sie mit dieser Situation umgehen. Aber es fügt dem Videobild einen Weichzeichnungs- / Blooming-Effekt um Lichtquellen hinzu. Der Software-WDR-Modus ist weniger leistungsfähig und kann Rauschen zu Ihrem Videobild hinzufügen (abhängig von der WDR-Stärke, siehe unten). Aber das Bild wird immer "scharf" bleiben. Verwenden Sie den Hardware-WDR-Modus für Überwachungsbereiche, die den Bildsensor mit hohen Kontrastwerten herausfordern. Die Software WDR bietet meist in gleichmäßig ausgeleuchteten Bereichen die beste Bildqualität.' },
        { actionColumn: 'Fester WDR Zeitplan', descriptionColumn: 'Der Hardware-WDR hilft Ihnen, die Helligkeit Ihres Videos während des Tages auszugleichen. Es kann aber dazu führen, dass Ihr Bild unscharf ist, wenn es dunkel wird (siehe Screenshots oben auf dieser Seite). Verwenden Sie diese Automatisierung, um Ihre Kamera nachts auf den Software-WDR und bei Sonnenaufgang wieder auf den Hardware-WDR umzuschalten.' },
        { actionColumn: 'Hardware WDR ab', descriptionColumn: 'Legen Sie einen Zeitpunkt fest, zu dem Ihre Kamera den Hardware-WDR-Modus aktivieren soll.' },
        { actionColumn: 'Software WDR ab', descriptionColumn: 'Legen Sie einen Zeitpunkt fest, zu dem Ihre Kamera den Software-WDR-Modus aktivieren soll.' },
        { actionColumn: 'Auto WDR', descriptionColumn: 'Lassen Sie Ihre Kamera automatisch bestimmen, wieviel WDR notwendig ist, um helle und dunkel Bereiche im Bild auszugleichen. Deaktivieren Sie den Auto Modus um manuell einen festen Wert vorzugeben (s.u.). Nur für den Hardware WDR Modus verfügbar!' },
        { actionColumn: 'WDR Stärke', descriptionColumn: 'Dieser Schieberegler passt die Stärke des Auto WDR-Modus an (der nur im Hardware-WDR-Modus aktiviert werden kann). Je höher der Wert, desto mehr versucht der Sensor, Bereiche mit hohem Kontrast in Ihrem Videobild zu kompensieren. Hohe Werte können den Blendungseffekt starker Lichtquellen kompensieren, die auf die Kamera zeigen. Sicherheitskameras werden auch oft auf dunkle Gebäude vor einem hellen Himmel gerichtet - ein hoher Wert für die WDR-Stärke wird die Schatten aufhellen und gleichzeitig die Helligkeit des Himmels verringern. Beachten Sie, dass ein hoher Wert von WDR einen Blooming-Effekt in der Umgebung von Lichtquellen verursacht, wodurch das Bild weniger scharf erscheint. Verringern Sie die WDR-Stärke, um diesen Effekt zu reduzieren. Oder wechseln Sie in den Software-WDR-Modus.' },
        { actionColumn: 'Feste WDR Stärke', descriptionColumn: 'Wenn Sie nicht möchten, dass die Kamera die WDR Stärke an die jeweilige Szene anpasst (oder Sie den Software WDR nutzen), können Sie hier einen festen Wert vorgeben.' },
        { actionColumn: 'Auto. Entrauschen', descriptionColumn: 'Entfernen Sie digitales Rauschen in ihrem Kamerabild.' },
        { actionColumn: 'Fester Entrauschwert', descriptionColumn: 'Deaktivieren Sie das automatische Entrauschen oben, um einen manuellen Wert einzustellen. Wenn Sie mit den von Ihnen gewählten Bildeinstellungen ein zu starkes Bildrauschen erhalten, können Sie diesen Wert auf 220 - 240 anheben. Beachten Sie bitte, dass ein hoher Wert jedoch dazu führt, dass Sie Details im Video verlieren. Für die Überwachung ist ein leicht verrauschtes Bild häufig besser auszuwerten (z.B. um Nummernschildern oder Gesichter zu erkennen).' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

ImageAdvancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(ImageAdvancedTable)