import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'Zubehör',
      href: '/de/Downloadbereich/Utensilien/',
    },
    {
      text: 'Desktop Software',
      href: '/de/Downloadbereich/Desktop_Software/',
    },
    {
      text: 'Innenkameras',
      href: '/de/Downloadbereich/Innenkameras/',
    },
    {
      text: 'Mobile Apps',
      href: '/de/Downloadbereich/Mobile_Apps/',
    },
    {
      text: 'Netzwerk Zubehör',
      href: '/de/Downloadbereich/Netzwerk_Utensilien/',
    },
    {
      text: 'Aussenkameras',
      href: '/de/Downloadbereich/Aussenkameras/',
    },
    {
      text: '◄ Downloadbereich',
      href: '/de/Downloadbereich/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={11}
        aria-label="Downloadbereich for your INSTAR IP Camera"
      />
      <EuiSpacer />
    </Fragment>
  );
}