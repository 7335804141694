import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Camera Reset">
        <EuiText>
          <p>
            Aktivieren Sie die Werksreste-Schaltfläche und bestätigen Sie, um Ihre Kamera auf die Werkseinstellungen zurückzusetzen. Bitte beachten Sie, dass Sie Ihre Kamera wieder neu über ein Netzwerkkabel einrichten müssen, nachdem Sie den Werksreset durchgeführt haben.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

