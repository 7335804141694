import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Innenkameras/IN-8415_WQHD/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_IN-8415WQHD.webp'
                    alt="IN-8415 WQHD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-8415 WQHD"
              description="Die IN-8415 2k+ WQHD ist die neue INSTAR Innenkamera mit dem neuen 1440p Chipsatz. Und die erste Kamera, die erste WQHD Pan&Tilt Innenkamera von INSTAR."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Innenkameras/IN-8415_WQHD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Innenkameras/IN-8403_WQHD/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_IN-8403WQHD.webp'
                    alt="IN-8403 WQHD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-8403 WQHD"
              description="Die IN-8403 2k+ WQHD ist die neue INSTAR Innenkamera mit dem neuen 1440p Chipsatz. Und die erste Kamera, die sowohl 850nm IR LED als auch regulär, sichtbare LEDs bietet."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Innenkameras/IN-8403_WQHD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Innenkameras/IN-8401_WQHD/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_IN-8401WQHD.webp'
                    alt="IN-8401 WQHD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-8401 WQHD"
              description="Die IN-8401 2k+ WQHD ist die neue INSTAR Innenkamera mit dem neuen 1440p Chipsatz. Und die erste Kamera, die sowohl 850nm als auch 'unsichtbare' 940nm IR-Nachtsicht bietet."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Innenkameras/IN-8401_WQHD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Innenkameras/IN-8015_HD/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_IN-8015HD.webp'
                    alt="IN-8015 Full HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-8015 Full HD"
              description="WLAN IP Kamera für Indoor mit 2-Wege-Audio, PIR Sensor, HTML5 Video- und Webinterface. ONVIF und MQTT für Smarthome Integration. Arbeitet mit Alexa und IFTTT."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Innenkameras/IN-8015_HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Innenkameras/IN-8003_HD/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_IN-8003HD.webp'
                    alt="IN-8003 Full HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-8003 Full HD"
              description="PoE LAN Kamera für innen & außen. Betrieb durch ein einzelnes LAN-Kabel dank PoE. 1080p Full HD & IR Nachtsicht. Aufzeichnungen flexible auf SD-Karte, FTP-Server oder INSTAR Cloud sichern."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Innenkameras/IN-8003_HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Innenkameras/IN-8001_HD/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_IN-8001HD.webp'
                    alt="IN-8001 Full HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-8001 Full HD"
              description="Kleine aber feine Innenkamera mit unsichtbaren 940nm Infrarot LEDs, PIR-Sensor und SD-Karte. 3 Sekunde Pre-Recording vor dem Alarm. Smarthome Kompatibilität dank CGI und MQTT."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Innenkameras/IN-8001_HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Innenkameras/IN-6014_HD/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_IN-6014HD.webp'
                    alt="IN-6014 HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-6014 HD"
              description="Die IN-6014 HD bietet alle Vorteile der IN-6012 HD. Hat aber zusätzlich ein 80 ° Weitwinkelobjektiv und einen integrierten PIR-Bewegungsmelder."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Innenkameras/IN-6014_HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Innenkameras/IN-6012_HD/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_IN-6012HD.webp'
                    alt="IN-6012 HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-6012 HD"
              description="Die IN-6012HD ist die Standard-HD-PTZ-Kamera für die Innenraumüberwachung. Sie verfügt über ein Standardobjektiv mit 40 ° Betrachtungswinkel und IR-Nachtsicht."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Innenkameras/IN-6012_HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Innenkameras/IN-6001_HD/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_IN-6001HD.webp'
                    alt="IN-6001 HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-6001 HD"
              description="Der Nachfolger der VGA-Kamera IN-3001 - kompakt, unauffällig, IR-Nachtsicht-fähig mit einer Videoauflösung von 720p HD und integrierter SD-Karte."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Innenkameras/IN-6001_HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Innenkameras/IN-3011/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_IN-3011.webp'
                    alt="IN-3011"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-3011"
              description="Die IN-3011 ist die Standard-Innenkamera für die VGA-Überwachung. Sie verfügt über IR-Nachtsicht und einen, über unsere App, fernsteuerbaren Kamerakopf."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Innenkameras/IN-3011/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
