import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class FeatureTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "numberColumn", title: "#" },
        { name: "componentColumn", title: "Komponente" },
        { name: "functionColumn", title: "Funktion" },
      ],
      rows: [
        { numberColumn: '01', componentColumn: 'Wandhalterung', functionColumn: 'Wandhalterung' },
        { numberColumn: '02', componentColumn: 'Deckenhalterung', functionColumn: 'Deckenhalterung' },
        { numberColumn: '03', componentColumn: 'Antennen Anschluss', functionColumn: 'RP-SMA WiFi Antennenanschluss (nicht für PoE Modelle)' },
        { numberColumn: '04', componentColumn: 'Infrared LED', functionColumn: '6 high-powered IR LEDs (Nachtsicht @ 850nm)' },
        { numberColumn: '05', componentColumn: 'Autofocus Objektiv', functionColumn: '4 x optischer Zoom Autofokusobjektiv (2.8mm ~ 12mm)' },
        { numberColumn: '06', componentColumn: 'Helligkeitsensor', functionColumn: '12V / 2A dc - Plug Ø 2.1mm in / 5.5mm (out)' },
        { numberColumn: '07', componentColumn: 'PIR Sensor', functionColumn: 'Integrierter Panasonic Wärmesensor (PIR) Bewegungsmelder' },
        { numberColumn: '08', componentColumn: 'Mikrofon', functionColumn: 'Audioerkennung / Audioaufnahme' },
        { numberColumn: '09', componentColumn: 'MicroSD Kartenleser', functionColumn: 'Slot für MicroSD/SDXC Karten (max.128GB - 16GB enthalten)' },
        { numberColumn: '10', componentColumn: 'Network LED', functionColumn: 'Indikator für den Netzwerk Status - Ein: Netzwerkverbindung / Aus: Keine Verbindung oder kein Strom' },
        { numberColumn: '11', componentColumn: 'Alarm Ausgang', functionColumn: 'Signalausgang für externe Signalgeräte' },
        { numberColumn: '12', componentColumn: 'Netzwerkanschluss', functionColumn: 'RJ45 LAN Port / Steckerplatz für Cat5e LAN Kabel (oder besser)' },
        { numberColumn: '13', componentColumn: 'Netzteilanschluss', functionColumn: 'Für beinhaltetes 12V / 2A Netzteil' },
        { numberColumn: '14', componentColumn: 'Verlängerungskabel', functionColumn: 'Verlängerungskabel' },
        { numberColumn: '15', componentColumn: 'Antennen Anschluss', functionColumn: 'RP-SMA WiFi Antennenanschluss (not for PoE version)' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

FeatureTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(FeatureTable)