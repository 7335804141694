import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-gtag";
import { EuiSpacer } from "@elastic/eui";
import NavButtons from "components/Outdoor_Cameras/IN-9420_WQHD/NavButtons";
import CompareOutdoor from "components/Products/Compare/CompareOutdoorFlyout";
import SmartCards from "components/Products/Flyouts/Cards/9408Cards";
import TableFeatures from "components/Outdoor_Cameras/IN-9420_WQHD/TableFeatures";
import OutdoorData from "components/Products/Flyouts/Graphs/OutdoorGraphsFlyout";
import ImageQGallery from "components/Outdoor_Cameras/IN-9420_WQHD/ImageQGallery";
import ImageQCards from "components/Outdoor_Cameras/IN-9420_WQHD/ImageQCards";
import RadarGraphCard from "components/Products/RadarGraphs/in9408-radargraph-grid";
import BreadCrumbs from "components/Layout/BreadCrumbs";
import SEOHelmet from "components/Layout/SEOHelmet";
import * as React from 'react';
export default {
  Link,
  OutboundLink,
  EuiSpacer,
  NavButtons,
  CompareOutdoor,
  SmartCards,
  TableFeatures,
  OutdoorData,
  ImageQGallery,
  ImageQCards,
  RadarGraphCard,
  BreadCrumbs,
  SEOHelmet,
  React
};