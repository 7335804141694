import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Bitte beachten Sie">
        <EuiText>
          <p>Die Genauigkeit der Positionen wird mit der Zeit abnehmen. Bitte starten Sie Ihre Kamera neu, um eine erneute Kalibrierung vorzunehmen.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

