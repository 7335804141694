import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Kennwort Richtlinien">
        <EuiText>
            <p>Das WLAN-Passwort ist nach dem WPA-Standard auf max. 63 Zeichen und kann folgende Zeichen enthalten: {' 0-9, a-b A-B, -/: & = \\ @ ( ) ! ? . , $  < > ~ | % # { } [ ] + _ ^ * ; “ '} '. {' 0-9, a-b A-B, -/: & = \\ @ ( ) ! ? . , $  < > ~ | % # { } [ ] + _ ^ * ; “ '} '. Be careful not to create a HTML tag, like &#60;b\&#62;, &#60;br\&#62;, &#60;u\&#62; etc. - as they will not work inside the web user interface!</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

