import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Only available from software version Firmware 4.1.2.42 WebUI 2.1 (192)">
        <EuiText>
        <p>Den Download finden Sie in unserem: <Link to="/Downloadbereich/Innenkameras/IN-8003_HD/">Download Bereich</Link>.</p>
        <p>Diese kann auch direkt über die Weboberfläche Ihrer Kamera heruntergeladen werden: <Link to="/Web_User_Interface/1080p_Serie/System/Update/">System/Update Menü</Link>.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

