
import React, { useState } from 'react'
import {Link} from 'gatsby'

import {
  EuiCallOut,
  EuiFlyout,
  EuiFlyoutHeader,
  EuiFlyoutBody,
  EuiButton,
  EuiTitle,
  EuiText,
  EuiSpacer
} from '@elastic/eui'

import alexaBanner01 from "images/Products/Alexa/INSTAR_Cloud_Alexa_Skill_00.webp"
import alexaBanner02 from "images/Products/Alexa/INSTAR_Cloud_Alexa_Skill_15.webp"
import alexaBanner03 from "images/Products/Alexa/INSTAR_Cloud_Alexa_Skill_21.webp"

export default () => {
  const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);

  const closeFlyout = () => setIsFlyoutVisible(false);

  const showFlyout = () => setIsFlyoutVisible(true);

  let flyout;

  const callOut = (
    <EuiCallOut iconType="help">
      <p>
        Wir bieten Ihnen im Alexa Skill Store sowohl einen <Link to="/Bewegungserkennung/INSTAR_Cloud/Alexa_Cloud_Skills/">INSTAR Cloud Skill</Link> als auch einen <Link to="/Web_User_Interface/1080p_Serie/Smarthome/Alexa_Skill/">INSTAR Camera Skill</Link> an. Für die INSTAR Cloud Skill benötigen Sie nur ein INSTAR Cloud-Konto und ein Alexa-Gerät mit einem Display. Fügen Sie die Skill auf Ihrem Alexa-Gerät hinzu und nutzen Sie es direkt.
      </p>
    </EuiCallOut>
  );

  if (isFlyoutVisible) {
    flyout = (
      <EuiFlyout
        ownFocus
        onClose={closeFlyout}
        size="l"
        aria-labelledby="flyoutWithBannerTitle">
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="m">
            <h2 id="flyoutWithBannerTitle">INSTAR Alexa Kamera Skill</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody banner={callOut}>
          <EuiText>
            <h4>Aktivieren Sie die Kamerakontrolle durch Alexa</h4>
          </EuiText>
          <img alt="INSTAR Alexa Support" src={alexaBanner01} style={{ maxWidth: '100%', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px'}} />
          <EuiSpacer />
          <EuiText>
            <p>Mit dem Schalter im <Link to="/Web_User_Interface/1080p_Serie/Smarthome/Alexa_Skill/">Smarthome Menü</Link> in der WebUI Ihrer Kamera geben Sie der INSTAR Camera Skill Ihre Erlaubnis, Ihre Kamera über das P2P-Protokoll zu steuern. Die notwendigen Zugangsinformationen werden in Ihrem INSTAR Cloud-Konto gespeichert und nicht an Dritte weitergegeben.</p>
          </EuiText>
          <EuiSpacer />
          <img alt="INSTAR Alexa Support" src={alexaBanner02} style={{ maxWidth: '100%', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px'}} />
          <EuiSpacer />
          <EuiText>
            <p>Damit Ihre Kamera eine Verbindung zur Amazon Cloud aufbauen kann, müssen Sie sich einen <Link to="/Bewegungserkennung/INSTAR_Cloud/Alexa_Cloud_Skills/">INSTAR Cloud Account für Ihre Kamera anlegen</Link>. Das Anlegen eines Test Account is <strong>kostenlos</strong> und gibt Ihnen für 30 Tage vollen Zugriff auf alle INSTAR Cloud Funktionen. Nach Ablauf dieser Testphase können Sie keine Alarmvideos mehr hochladen lassen - der Account, und damit auch die Möglichkeit die Smart-Home Skill zu nutzen, bleibt Ihnen jedoch bestehen.</p>
            <p>Die Cloud Skill dient der Verwaltung Ihrer Alarmaufnahmen in der INSTAR Cloud und kann während der Testphase im vollen Umfang genutzt werden.</p>
            <EuiSpacer />
            <img alt="INSTAR Alexa Support" src={alexaBanner03} style={{ maxWidth: '100%', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px'}} />
          </EuiText>
          <EuiSpacer />
        </EuiFlyoutBody>
      </EuiFlyout>
    );
  }
  return (
    <div>
      <EuiSpacer />
      <EuiButton onClick={showFlyout} color="text">Alexa</EuiButton>
      {flyout}
    </div>
  );
};