import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Updates:">
        <EuiText>
          <ul>
              <li><Link to="/Frequently_Asked_Question/9408_vs_9008_sensor_sensitivity/">IN-9408 WQHD vs 8015 Full HD Sensorempfindlichkeit</Link></li>
          </ul>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

