import React from 'react'
import { OutboundLink } from 'gatsby-plugin-gtag'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiIcon
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="xnzk7EidhxU-8"
                    title="Windows Filezilla Server"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              footer={
                <EuiText>
                <p>Dieses Video beschreibt Ihnen mehrere Punkte.</p>
                <ul>
                  <li>1) Wir zeigen Ihnen wie Sie unter Windows Filezilla Server installieren, also einen eigenen FTP Server erstellen auf den die Kamera dann Ihre Aufnahmen übertragen kann.</li>
                  <li>2) Wir zeigen Sie wie und wo Sie die Daten in der Kamera eintragen müssen</li>
                  <li>3) Mit der automatischen Aufgabenplanung von Windows lassen wir Dateien automatisch löschen die älter als X Tage sind</li>
                </ul>
                <p>Auf <OutboundLink href="https://www.youtube.com/watch?v=xnzk7EidhxU-8" target="_blank" rel="noopener noreferrer">Youtube ansehen <EuiIcon type="popout" /></OutboundLink></p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}