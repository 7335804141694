import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class LogTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Kamera Logbuch" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'ipc_server start', descriptionColumn: 'Zeit des letzten Systemstarts. Beachten Sie, dass der System Log bei jedem Neustart gelöscht wird.' },
        { actionColumn: '-- empty entyry --', descriptionColumn: 'Loggen Sie sich in das Live Video über das Flash Video Plugin ein.' },
        { actionColumn: 'user (admin, user or guest) login for live stream', descriptionColumn: 'Loggen Sie sich über den admin, user oder guest zum Live Video Stream ein (es sei denn Sie sind über das Flash Plugin s.o. eingeloggt).' },
        { actionColumn: 'motion detection (area= 1,2,3 or 4) alarm', descriptionColumn: 'Zeit einer Bewegungserkennung im Alarm Bereich 1, 2, 3 oder 4.' },
        { actionColumn: 'input alarm', descriptionColumn: 'Zeit des ausgelösten Alarms durch den Alarmeingang - z.B. durch einen externen Sensor oder den internen PIR Sensor (im Fall der IN-6014 HD).' },
        { actionColumn: 'audio alarm', descriptionColumn: 'Zeit eines Alarm Events durch den Audio Alarm Ihrer Kamera.' },
        { actionColumn: 'alarm event: alarm to email (snap)', descriptionColumn: 'Das Alarm Event, dass eine Benachrichtigung per Email auslöst, gemäß Ihren Einstellungen im Menü Alarm Aktionen.' },
        { actionColumn: 'alarm event: alarm to ftp (snap or video)', descriptionColumn: 'Das Alarm Event, dass ein Video oder Snapshot per FTP hochlädt, gemäß Ihren Einstellungen im Menü Alarm Aktionen, auf Ihren FTP Server' },
        { actionColumn: 'alarm event: alarm to SD (snap or video)', descriptionColumn: 'Das Alarm Event, dass ein Video oder Snapshot auf Ihre SD Karte aufnimmt, gemäß Ihren Einstellungen im Menü Alarm Aktionen.' },
        { actionColumn: 'Delete', descriptionColumn: 'Löschen des System Logs. Beachten Sie, dass der System Log bei jedem Neustart gelöscht wird.' },
        { actionColumn: 'Refresh', descriptionColumn: 'Das Logbuch wird sich nicht aktualisieren, wenn das Menü noch offen ist. Klicken Sie auf Aktualisieren um es manuell durchzuführen.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

LogTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(LogTable)