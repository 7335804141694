import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Achtung:">
        <EuiText>
          <p>Während es möglich ist, den DDNS-Zugang in einer FritzBox des gleichen Netzes zu testen, gibt es Router wie z. B. die Telekom Speedports, die den Test verweigern können. Das heißt, Sie müssten einen Rechner in einem anderen Netz verwenden oder alternativ den DDNS-Zugang über das Mobilfunknetz auf Ihrem Smartphone testen.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

