import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/Einrichten/">
                  <StaticImage
                    src='../../images/Search/MD_SearchThumb_MDSetup.webp'
                    alt="Bewegungserkennung"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Bewegungserkennung"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/Einrichten/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/Passive_Infrared_Detection/">
                  <StaticImage
                    src='../../images/Search/MD_SearchThumb_PIR_Sensor.webp'
                    alt="PIR Sensor"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="PIR Sensor"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/Passive_Infrared_Detection/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/Alarm_Benachrichtigung/">
                  <StaticImage
                    src='../../images/Search/MD_SearchThumb_AlarmNotification.webp'
                    alt="Alarm Benachrichtigung"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Alarm Benachrichtigung"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/Alarm_Benachrichtigung/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/SD_Karten_Zugriff/">
                  <StaticImage
                    src='../../images/Search/MD_SearchThumb_SDCardAccess.webp'
                    alt="SD Karten Zugriff"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="SD Karten Zugriff"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/SD_Karten_Zugriff/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/Alarm_FTP_Upload/">
                  <StaticImage
                    src='../../images/Search/MD_SearchThumb_FTPSettingsAlarmUpload.webp'
                    alt="Alarm FTP Upload"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Alarm FTP Upload"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/Alarm_FTP_Upload/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/Router_als_FTP_Server/">
                  <StaticImage
                    src='../../images/Search/MD_SearchThumb_RouterAsFTPServer.webp'
                    alt="Router als FTP Server"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Router oder NAS als FTP Server"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/Router_als_FTP_Server/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/FTP_Server_Setup/">
                  <StaticImage
                    src='../../images/Search/MD_SearchThumb_FTPServerSetup.webp'
                    alt="FTP Server Setup"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="FTP Server Setup"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/FTP_Server_Setup/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/INSTAR_Cloud/">
                  <StaticImage
                    src='../../images/Search/MD_SearchThumb_Cloud.webp'
                    alt="INSTAR Cloud"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="INSTAR Cloud"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/INSTAR_Cloud/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
