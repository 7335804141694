import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Language Settings">
        <EuiText>
          <p>
            Ändern Sie die Sprache der Weboberfläche in Deutsch, Englisch, Französisch oder Chinesisch. Die Weboberfläche setzt die Sprache automatisch auf die Sprache Ihres Betriebssystems. Sie können es manuell ändern, falls die automatische Erkennung fehlgeschlagen ist oder Sie die Weboberfläche in einer anderen Sprache darstellen möchten. Ihre Einstellungen werden lokal als Cookie gespeichert und nicht global auf der Kamera selbst.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

