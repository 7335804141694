import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Seien Sie vorsichtig">
        <EuiText>
          <p>Ihre Kamera wird nach der Portweiterleitung für jeden erreichbar sein. Um unerwünschten Zugriff zu vermeiden, ändern Sie die Login-Daten, bevor Sie die Kamera weiterleiten! Die Login-Daten können im <Link to="/Web_User_Interface/720p_Serie/System/Benutzer/">Benutzermenü</Link> eingestellt werden.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

