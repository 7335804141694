import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'Alarm',
      href: '/de/1080p_Serie_CGI_Befehle/Alarm_Menu/',
    },
    {
      text: 'Features',
      href: '/de/1080p_Serie_CGI_Befehle/Features_Menu/',
    },
    {
      text: 'Multimedia',
      href: '/de/1080p_Serie_CGI_Befehle/Multimedia_Menu/',
    },
    {
      text: 'Netzwerk',
      href: '/de/1080p_Serie_CGI_Befehle/Netzwerk_Menu/',
    },
    {
      text: 'System',
      href: '/de/1080p_Serie_CGI_Befehle/System_Menu/',
    },
    {
      text: 'Vollständige Liste',
      href: '/de/1080p_Serie_CGI_Befehle/Komplette_CGI_Liste/',
    },
    {
      text: '1080p CGIs',
      href: '/de/1080p_Serie_CGI_Befehle/',
    },
    {
      text: 'CGIs Befehle',
      href: '/de/Erweitert/CGI_Befehle/',
    },
    {
      text: '◄ Entwickler',
      href: '/de/Erweitert/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={11}
        aria-label="Topics for Developers"
      />
      <EuiSpacer />
    </Fragment>
  );
}