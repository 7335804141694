import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Kostenloser Cloud Account">
        <EuiText>
          <p>Das Anlegen eines Test Accounts ist kostenlos und gibt Ihnen <strong>auch nach dem Ablauf</strong> der 30-tägigen Test-Phase Zugriff auf die Alexa Funktion. Nur das Speichern von Alarmvideos in der Cloud würde einen <strong>kostenpflichtigen Freischaltcode</strong> benötigen.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

