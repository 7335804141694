import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class UpdateTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Update Settings" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Firmware Version', descriptionColumn: 'Versionsnummer des Betriebssystems Ihrer Kamera. Die Software Version wird Ihnen in Rot angezeigt, wenn eine neuere Version verfügbar ist.' },
        { actionColumn: 'WebUI Version', descriptionColumn: 'Versionsnummer des Web Benutzeroberfläche Ihrer Kamera. Die Software Version wird Ihnen in Rot angezeigt, wenn eine neuere Version verfügbar ist.' },
        { actionColumn: 'Neuste Version installiert', descriptionColumn: 'Wenn eine neuere Software Version verfügbar ist, wird Ihnen hier direkt der Download Link angezeigt. Beachten Sie bitte, dass Sie die update.pkg Datei vor dem Update aus dem ZIP Container hinter diesem Link entpacken müssen.' },
        { actionColumn: 'Datei auswählen...', descriptionColumn: 'Klicken Sie hier um die update.pkg Datei auszuwählen.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

UpdateTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(UpdateTable)