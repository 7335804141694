import CGIArticles from 'components/Advanced_User/CGI_Commands/IntroCGIs';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  CGIArticles,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};