import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Software/Other_Platforms/NavButtons';
import ForumBox from 'components/Software/Other_Platforms/biOs_MQTT_Stream_Deck/ForumBox';
import PrimaryBox from 'components/Software/Other_Platforms/biOs_MQTT_Stream_Deck/PrimaryBox';
import YoutubeVideoCardStreamDeck from 'components/Software/Other_Platforms/Vivre_Motion_Stream_Deck/YoutubeVideoCardStreamDeck';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  NavButtons,
  ForumBox,
  PrimaryBox,
  YoutubeVideoCardStreamDeck,
  BreadCrumbs,
  SEOHelmet,
  React
};