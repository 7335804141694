import React from 'react'

import {
  EuiPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiTitle,
  EuiImage
} from '@elastic/eui'

// import lenseSpecsImage from '../../../../images/Outdoor_Cameras/IN-9408_WQHD/Technical_Specifications/4-3_Lense_Specifications.webp'
import pirSpecsImage from '../../../../images/Outdoor_Cameras/IN-9408_WQHD/Technical_Specifications/PaPIR_Specifications.webp'

export default function FooterCards() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          {/* <EuiFlexItem>
            <EuiPanel paddingSize="m">
              <EuiTitle size="m">
                <h3>[2] Lense Specifications</h3>
              </EuiTitle>
              <EuiImage
                allowFullScreen
                alt='Lense Specifications'
                src={lenseSpecsImage}
              />
            </EuiPanel>
          </EuiFlexItem> */}
          <EuiFlexItem>
            <EuiPanel paddingSize="m">
              <EuiTitle size="m">
                <h3>[4] Panasonic PIR Sensor</h3>
              </EuiTitle>
              <EuiImage
                allowFullScreen
                alt='IN-9408 WQHD PIR Sensor Specifications'
                src={pirSpecsImage}
              />
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
