import { EuiSpacer } from '@elastic/eui';
import VideoCard from 'components/Motion_Detection/Videos/video-instar-cloud-storage';
import NavButtons from 'components/Motion_Detection/INSTAR_Cloud/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  VideoCard,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};