import React from 'react'

import 'react-vis/dist/style.css'

import {
  FlexibleWidthXYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  DiscreteColorLegend,
  Crosshair
} from 'react-vis'

const DATA = [
  [
    { x: 'IN-6001 HD', y: 5 },
    { x: 'IN-6014 HD', y: 5 },
    { x: 'IN-8003 FHD', y: 8 },
    { x: 'IN-8015 FHD', y: 5 },
    { x: 'IN-5905 HD', y: 12 },
    { x: 'IN-5907 HD', y: 18 },
    { x: 'IN-7011 HD', y: 8 },
    { x: 'IN-9008 FHD', y: 12 },
    { x: 'IN-9020 FHD', y: 15 },
    { x: 'IN-9408 WQHD', y: 12 }
  ],
  [
    { x: 'IN-6001 HD', y: 7 },
    { x: 'IN-6014 HD', y: 10 },
    { x: 'IN-8003 FHD', y: 12 },
    { x: 'IN-8015 FHD', y: 10 },
    { x: 'IN-5905 HD', y: 20 },
    { x: 'IN-5907 HD', y: 25 },
    { x: 'IN-7011 HD', y: 12 },
    { x: 'IN-9008 FHD', y: 20 },
    { x: 'IN-9020 FHD', y: 25 },
    { x: 'IN-9408 WQHD', y: 20 }
  ]
];

export default class Example extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      crosshairValues: []
    };
  }
  render() {
    return (
        <React.Fragment>

          <div className="chart">
            <FlexibleWidthXYPlot
              onMouseLeave={() => this.setState({ crosshairValues: [] })}
              height={300}
              xType="ordinal"
              animation={true}
              stackBy="y">
              
              <VerticalGridLines />
              <HorizontalGridLines />
              <XAxis />
              <YAxis tickFormat={v => `${v} m`} />

              <Crosshair values={this.state.crosshairValues} />

              <VerticalBarSeries
                onNearestX={(value, { index }) =>
                  this.setState({ crosshairValues: DATA.map(d => d[index]) })}
                cluster="Minimale Reichweite"
                color="#0077cc"
                data={DATA[0]}
              />

              <VerticalBarSeries
                cluster="Maximale Reichweite"
                color="#F44336"
                data={DATA[1]}
              />

            </FlexibleWidthXYPlot>
          </div>

          <div className="legend">
            <DiscreteColorLegend
              orientation="horizontal"
              items={[
                {
                  title: 'Datensatz[0] Minimal [m]',
                  color: '#0077cc'
                },
                {
                  title: 'Datensatz[1] Maximal [m]',
                  color: '#F44336'
                }
              ]}
            />
          </div>

        </React.Fragment>
    );
  }
}