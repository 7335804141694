import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/styles'
import {
  SortingState, SelectionState, FilteringState, GroupingState,
  IntegratedFiltering, IntegratedGrouping, IntegratedSelection,
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table, TableHeaderRow, TableFilterRow, TableGroupRow,
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class TechSpecGrid extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "category", title: "Kategorie" },
        { name: "feature", title: "Feature" },
        { name: "property", title: "Eigenschaft" }
      ],
      rows: [
        { category: 'Optik & Imaging', feature: 'Standard Objektiv', property: '4.2 ±5% mm (Manueller Fokus) f/2.1 [2]' },
        { category: 'Optik & Imaging', feature: 'Blickwinkel', property: 'ca. 90° (diag.)' },
        { category: 'Optik & Imaging', feature: 'Optischer Zoom', property: 'No' },
        { category: 'Optik & Imaging', feature: 'Bildsensor', property: 'Panasonic MN34229 1080p 1/3" CMOS Wide Dynamic Range [1]' },
        { category: 'Optik & Imaging', feature: 'Sensorfläche', property: '5,346 (H) µm x 3,003 (V) µm' },
        { category: 'Optik & Imaging', feature: 'Auflösung', property: '2.12 Megapixel' },
        { category: 'Optik & Imaging', feature: 'Unterstützte Auflösungen', property: '1080p, 320p, 160p' },
        { category: 'Optik & Imaging', feature: 'Min. Beleuchtung (Farbe)', property: '0.05 Lux @ f/2.1' },
        { category: 'Optik & Imaging', feature: 'Video Kompression', property: 'h.264, MJPEG, JPG' },
        { category: 'Optik & Imaging', feature: 'h.264 Bitrate', property: '90 kbps - 4.096 kbps' },
        { category: 'Optik & Imaging', feature: 'Framerate', property: 'bis zu 30fps' },
        { category: 'Optik & Imaging', feature: '# an parallelen Streams', property: '3' },
        { category: 'Optik & Imaging', feature: 'Video Overlay', property: 'Datum, Uhrzeit, Kamera Name' },
        { category: 'Optik & Imaging', feature: 'Video Parameter', property: 'Helligkeit, Kontrast, Saturation, Schärfe, Gamma, Belichtung' },
        { category: 'Optik & Imaging', feature: 'Tag / Nacht Wechsel', property: 'Ja' },
        { category: 'Optik & Imaging', feature: 'Weißabgleich', property: 'Automatisch' },
        { category: 'Optik & Imaging', feature: 'Elektronische Blende', property: 'Automatisch' },
        { category: 'Optik & Imaging', feature: 'Video Drehen / Spiegeln', property: 'Ja / Ja' },
        { category: 'Nachtsicht', feature: 'Infrarot LEDs', property: '12 LEDs @ 850 nm' },
        { category: 'Nachtsicht', feature: 'IR Modes', property: 'aus/Automatisch' },
        { category: 'Nachtsicht', feature: 'Effektive Reichweite', property: '8 - 12 m' },
        { category: 'Nachtsicht', feature: 'intgr. IRcut Filter', property: 'Ja (Automatisch)' },
        { category: 'Netzwerk', feature: 'Netzwerkschnittstelle', property: '10Base-T/100Base-TX Ethernet Port' },
        { category: 'Netzwerk', feature: 'Network Protocols', property: 'TCP/IP, DHCP, PPPoE, ARP, FTP, SMTP, DNS, NTP, UPnP, HTTP, TCP, UDP, MQTT' },
        { category: 'Netzwerk', feature: 'SSL Verschlüsselung', property: 'HTTPS & FTPS' },
        { category: 'Netzwerk', feature: 'Free DDNS Address', property: 'Ja' },
        { category: 'Netzwerk', feature: 'kostenlose P2P UID', property: 'Ja' },
        { category: 'Features', feature: 'Zugangsbeschränkung', property: 'Benutzername/Passwort - 3 Level Autorisierung' },
        { category: 'Features', feature: 'Bewegungserkennung', property: 'Ja (Software Analyse + PIR)' },
        { category: 'Features', feature: 'Alarm I/O', property: 'Ja (potentialfreier Alarmausgang / Alarmausgang Relais max. 120VAC / 24VDC @ 1A) + Integrierter Wärmesensor (PIR) for Bewegungserkennung [3]' },
        { category: 'Features', feature: 'PIR Sensor Winkel', property: '120° horizontal' },
        { category: 'Features', feature: 'PIR Reichweite', property: '5 Meter' },
        { category: 'Features', feature: 'Alarm Benachrichtigung', property: 'Email/FTP/HTTP (Alarmserver)/Push' },
        { category: 'Features', feature: 'Alarm Pre-Recording', property: 'Ja (3s)' },
        { category: 'Features', feature: 'Fotoserie', property: 'Ja (auf SD Karte und FTP Server)' },
        { category: 'Features', feature: 'SD Karte', property: 'Erweiterbare microSDHC Karte bis zu 128GB (16GB inklusive)  [5]' },
        { category: 'Features', feature: 'Cloud Speicherplatz', property: 'Ja (optional)' },
        { category: 'Features', feature: 'Pan & Tilt', property: 'Nein' },
        { category: 'Features', feature: 'Mikrofon', property: 'Integriertes Mikrofon' },
        { category: 'Features', feature: 'Scheduled & Alarm Recording', property: 'Ja' },
        { category: 'Hardware', feature: 'Gehäuse', property: 'Metall, weiß' },
        { category: 'Hardware', feature: 'Halterung', property: 'Wand & Deckenhalterung' },
        { category: 'Hardware', feature: 'Betriebstemperatur', property: '-5°C bis 55°C' },
        { category: 'Hardware', feature: 'Netzspannung', property: '110V - 230V' },
        { category: 'Hardware', feature: 'Netzwerkkabel', property: '3m Cat5e' },
        { category: 'Hardware', feature: 'Netzteil', property: '110-240V ac @ 50-60Hz bis 12V dc und 2000mA - Kabellänge: 3m' },
        { category: 'Hardware', feature: 'Netzstecker', property: '2,1mm Innen / 5,5mm Außen' },
        { category: 'Hardware', feature: 'Stromverbrauch', property: 'max. 7,5W (im Normalbetrieb 3W)' },
        { category: 'Hardware', feature: 'Zertifikate', property: 'CE, FCC, RoHS' }
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns} >

          <FilteringState />

          <SortingState
            defaultSorting={[
              { columnName: 'category', direction: 'asc' },
            ]}
          />
          <GroupingState
            defaultGrouping={[{ columnName: 'category' }]}
            defaultExpandedGroups={['Optik & Imaging']}
          />
          <SelectionState />

          <IntegratedFiltering />
          <IntegratedGrouping />
          <IntegratedSelection />
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
          <TableFilterRow />
          <TableGroupRow />
        </Grid>
      </Paper>
    );
  }
}

TechSpecGrid.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(TechSpecGrid)























































































































  












































































































  































































































































