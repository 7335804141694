import React from 'react'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="DjlLetsqL9k"
                    title="IN-8015 FHD WPS Setup"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              title="IN-8015 FHD WPS Setup"
              footer={
                <EuiText>
                  <p>In diesem Video erklären wir, wie Sie die IN-8015 Full HD mit dem Netzwerk verbinden. Dabei gibt es zwei Mögliche Vorgehensweisen.</p>
                  <ul>
                    <li><strong>1.)</strong> Per LAN Kabel (um dann z.B. später die WLAN Einstellungen vorzunehmen)</li>
                    <li><strong>2.)</strong> Per WPS um die Kamera ohne das Anschließen eines LAN Kabels mit dem WLAN zu verbinden.</li>
                  </ul>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}
