import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Beachten Sie:">
        <EuiText>
          <p>In neueren Versionen von Debian wird die Ethernet-Schnittstelle nicht mehr <strong>eth0</strong> genannt. Die Datei enthält die Zeile <code>iface eth0 inet dhcp</code> - die Bezeichnung zwischen <strong>iface</strong> und <strong>inet</strong> ist die ID Ihrer Ethernet-Schnittstelle (ändern Sie sie nicht in eth0!)</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

