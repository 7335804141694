import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Bild Einstellungen">
        <EuiText>
          <p>
            Hier können Sie die Bildparameter Helligkeit, Sättigung, Kontrast, Vibrance (Lebhaftigkeit) und Schärfe einstellen. In den <strong>Erweiterten Einstellungen</strong> können Sie den WDR Modus der Kamera schalten und konfigurieren.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

