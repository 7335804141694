import React from 'react'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="L_nR9qY7-8I"
                    title="INSTAR IN-5907 HD Unboxing"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              footer={
                <EuiText>
                  <p>Die IN-5907 HD ist eine wetterfeste und kabellose Außenkamera. Eingebaute, leistungsstarke Infrarotdioden ermöglichen es Ihnen, tief in die Dunkelheit zu sehen. Der integrierte IR-Sperrfilter wiederum ermöglicht die Erfassung von nativen Farben, wie sie das menschliche Auge sieht. Durch den Einsatz eines effizienten h.264-Kompressionscodecs ist sie in der Lage, Videodateien auf einer MicroSD-Speicherkarte mit vergleichsweise geringer Größe aber besten Bildergebnissen aufzuzeichnen.</p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}
