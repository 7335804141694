import React from 'react'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="PESNN3KxKNU"
                    title="Zugriff auf die SD Karte (Windows)"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              title="Zugriff auf die SD Karte (Windows)"
              footer={
                <EuiText>
                  <p>Wir zeigen Ihnen in diesem Video das neue INSTAR Kamera Tool Version 1.7 mit dem es jetzt auch möglich ist Videos von Ihrer INSTAR HD Kamera herunter zu laden um sich diese dann bequem unter Windows anzuschauen.</p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}
