import React from 'react'
import { OutboundLink } from 'gatsby-plugin-gtag'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiIcon
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="m66PEKpBMwo"
                    title="IN-9008 Full HD Netzwerkverbindung"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              footer={
                <EuiText>
                <p>In diesem Video erklären wir, wie Sie die IN-9008 Full HD mit dem Netzwerk verbinden. Dabei gibt es zwei Mögliche Vorgehensweisen.</p>
                <ul>
                  <li>1. Per LAN Kabel (um dann z.B. später die WLAN Einstellungen vorzunehmen)</li>
                  <li>2. Per WPS um die Kamera ohne das Anschließen eines LAN Kabels mit dem WLAN zu verbinden.</li>
                </ul>
                <p>Auf <OutboundLink href="https://www.youtube.com/watch?v=m66PEKpBMwo" target="_blank" rel="noopener noreferrer">Youtube ansehen <EuiIcon type="popout" /></OutboundLink></p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}