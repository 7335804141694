import React from 'react'
import {Link} from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-gtag'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText
} from '@elastic/eui'

import qiSelect01 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Quick_Installation/Quickinstallation_IN-5905_01.webp"
import qiSelect02 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Quick_Installation/Quickinstallation_IN-5905_02.webp"
import qiSelect03 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Quick_Installation/Quickinstallation_03.webp"
import qiSelect04 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Quick_Installation/Quickinstallation_IN-5905_04.webp"
import qiSelect05 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Quick_Installation/Quickinstallation_IN-5905_05.webp"
import qiSelect06 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Quick_Installation/Quickinstallation_06.webp"
import qiSelect07 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Quick_Installation/Quickinstallation_07.webp"
import qiSelect08 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Quick_Installation/Quickinstallation_08.webp"
import qiSelect09 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Quick_Installation/Quickinstallation_09.webp"
import qiSelect10 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Quick_Installation/Quickinstallation_12.webp"
import qiSelect11 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Quick_Installation/Quickinstallation_13.webp"
import qiSelect12 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Quick_Installation/Quickinstallation_14.webp"



export default function QIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect01}
                    alt="Schrauben Sie die WLAN Antenne auf den SMA Anschluss im hinteren Teil der Kamera um später eine WLAN Verbindung aufbauen zu können."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Schrauben Sie die WLAN Antenne auf den SMA Anschluss im hinteren Teil der Kamera um später eine WLAN Verbindung aufbauen zu können.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect02}
                    alt="Stecken Sie das Kabel des Netzteils in den DC Anschluss am Kabelbaum der Kamera, um diese mit Strom zu versorgen."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Stecken Sie das Kabel des Netzteils in den DC Anschluss am Kabelbaum der Kamera, um diese mit Strom zu versorgen.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect03}
                    alt="In Ihrem WLAN Router ist es empfohlen einen Kanal im Bereich von 1-6 (802.11bgn) eizustellen, sowie die WPA2/AES (CCMP) Verschlüsselung."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    In Ihrem WLAN Router ist es empfohlen einen Kanal im Bereich von 1-6 (802.11bgn) eizustellen, sowie die WPA2/AES (CCMP) Verschlüsselung.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect04}
                    alt="A Drücken Sie die WPS-Taste (s. oben) an Ihrem Router. B Drücken Sie die Reset-Taste der Kamera für 3s. Beachten Sie, dass ein längeres Halten als 10s zu einem Reset der Kamera führt. C Die Kamera verbindet sich nun automatisch mit Ihrem WiFi-Netzwerk. Bitte springen Sie zu Schritt 7 (für PC oder Mac) oder 14 (für Smartphone)."
                  />
              }
              footer={
                <EuiText>
                    <ul>
                      <li><strong>A</strong> Drücken Sie den WPS Button auf Ihrem Router.</li>
                      <li><strong>B</strong> Drücken Sie den Reset Taster der Kamera für 3s (länger als 10s führt zu einem Werksreset!).</li>
                      <li><strong>C</strong> Die Kamera wird sich danach automatisch mit Ihrem WLAN verbinden. Springen Sie zu Schritt 7 (PC oder Mac) oder Schritt 14 (Smartphone).</li>
                    </ul>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect05}
                    alt="Für eine LAN Verbindung, stecken Sie bitte das Netzwerkkabel in den RJ-45 Anschluss am Kabelbaum der Kamera um eine Verbindung aufzubauen (auch für WLAN benötigt wenn kein WPS vorhanden s. #4)."
                  />
              }
              footer={
                <EuiText>
                    <p>
                      Für eine LAN Verbindung, stecken Sie bitte das Netzwerkkabel in den RJ-45 Anschluss am Kabelbaum der Kamera um eine Verbindung aufzubauen (auch für WLAN benötigt wenn kein WPS vorhanden s. #4).
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect06}
                    alt="Stecken Sie das andere Ende des Netzwerkkabel in einen freien LAN Port Ihres Internet Routers."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Stecken Sie das andere Ende des Netzwerkkabel in einen freien LAN Port Ihres Internet Routers.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect07}
                    alt="Starten Sie unser IP-Kamera-Tool von der Software-CD und doppelklicken Sie auf den Namen der Kamera."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Starten Sie das <OutboundLink href="https://install.instar.com">IP Camera Tool</OutboundLink> von der Software CD und führen Sie einen Doppelklick auf den Namen der Kamera aus.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect08}
                    alt="Standard-Logins Benutzer/Passwort: admin / instar für den Administrator, user / instar für normale Benutzer und guest / instar für eingeschränkte Benutzer. Unser INSTALLATION WIZARD führt Sie durch den Rest des Installationsvorgangs."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Standard Logins Benutzer/Kennwort:  <strong>admin / instar</strong> für Administratoren, <strong>user / instar</strong> für normale Nutzer und <strong>guest / instar</strong> für Gäste. Unser <OutboundLink href="https://install.instar.com" target="_target">Installationsassistent</OutboundLink> führt Sie durch den restlichen Installationsprozess.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect09}
                    alt="Aktivieren Sie die WiFi-Karte Ihrer Kamera in der webUI und starten Sie die Suche nach WiFi-Signalen. Wählen Sie Ihr WiFi aus, fügen Sie Ihren Schlüssel hinzu und sichern Sie die Einstellungen. Starten Sie Ihre Kamera neu und ziehen Sie das LAN-Kabel ab."
                  />
              }
              footer={
                <EuiText>
                    <p>
                      Aktivieren Sie das WLAN der Kamera in der <Link to="/Web_User_Interface/720p_Serie/Netzwerk/WLAN/">Weboberfläche</Link> mittels des WLAN- Scanners. Nach der Eingabe und Speicherung des WLAN Passwortes muss das LAN Kabel gezogen und die Kamera neugestartet werden.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect10}
                    alt="Um auf Ihre Kamera über das Internet zuzugreifen, müssen Sie den HTTP-Port in Ihrem Router weiterleiten (Standard '80'). Um auf das Adobe Flash Plugin in der WebUI zuzugreifen, müssen Sie auch den RTMP-Port weiterleiten (Standard '1935')."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Für den Zugriff über das Internet ist eine Portweiterleitung des HTTP Ports (Standard “80”) in Ihrem Router notwendig. Für den Zugriff auf den <Link to="/Schnell_Installation/Live_Video/">Adobe Flash Plugin</Link> (z.B. über das Flash Plugin im Webbrowser) benötigen Sie noch die Weiterleitung des <Link to="/Web_User_Interface/720p_Serie/Netzwerk/IP_Konfiguration/">RTMP Ports</Link> (Standard “1935”). - siehe auch 14.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect11}
                    alt="Jetzt können Sie die DDNS-Informationen Ihrer Kamera in unsere kostenlose Windows Phone, Windows Metro, Android, iPhone, iPad oder Blackberry App eingeben."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Jetzt können Sie die DDNS-Informationen Ihrer Kamera in unsere kostenlose Windows Phone, Windows Metro, Android, iPhone, iPad oder Blackberry App eingeben.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect12}
                    alt="Wenn Sie keinen Port für den DDNS-Dienst der Kamera öffnen können, können Sie stattdessen immer noch den Point-2-Point-Dienst mit unserer Windows Phone, Windows Metro, Android, iPhone, iPad oder Blackberry App verwenden."
                  />
              }
              footer={
                <EuiText>
                    <p>
                      Um Ihre Kamera über unsere <Link to="/Software/Windows/InstarVision/Windows_Phone/DDNS/">Windows Phone</Link>, <a href="/de/Software/Windows/InstarVision/Metro/DDNS/">Windows Metro</a>, <Link to="/Software/Android/InstarVision/DDNS/">Android</Link>, <Link to="/Software/iOS/InstarVision/iPhone/DDNS/">iPhone</Link>, <Link to="/Software/iOS/InstarVision/iPad/DDNS/">iPad</Link> oder <Link to="/Software/Other_Platforms/InstarVision_Blackberry/">Blackberry</Link> App zu steuern, laden Sie sich bitte die InstarVision® App aus dem App Store herunter.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
