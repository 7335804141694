import React from 'react'
import {Link} from 'gatsby'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import angryIP from "../../../images/Quick_Installation/Alternative_IP_Scanner/angryIP.webp"
import fingCLI from "../../../images/Quick_Installation/Alternative_IP_Scanner/fingCLI.webp"
import fingAndroid from "../../../images/Quick_Installation/Alternative_IP_Scanner/fingAndroid.webp"


export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Schnell_Installation/Alternative_IP_Scanner/Angry_IP_Scanner/">
                  <img
                    src={angryIP}
                    alt="Angry IP Scanner"
                  />
                </Link>
              }
              title='Angry IP Scanner'
              description={
                <p>Angry IP Scanner ist ein sehr schneller Open-Source IP-Adressen- und Port-Scanner um Ihr lokales Netzwerk zu durchsuchen. Er ist für Windows, macOSX und für Linux verfügbar. <Link to="/Schnell_Installation/Alternative_IP_Scanner/Angry_IP_Scanner/">mehr...</Link></p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Schnell_Installation/Alternative_IP_Scanner/Fing_CLI/">
                  <img
                    src={fingCLI}
                    alt="Fing CLI"
                  />
                </Link>
              }
              title='Fing CLI'
              description={
                <p>Fing ist ein einfach zu verwendendes Kommandozeilen-Tool um Ihr lokales Netzwerk zu durchsuchen. Die Software ist für Windows, macOS und LINUX verfügbar. <Link to="/Schnell_Installation/Alternative_IP_Scanner/Fing_CLI/">mehr...</Link></p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Schnell_Installation/Alternative_IP_Scanner/Fing_Mobile/">
                  <img
                    src={fingAndroid}
                    alt="Android Fing IP Scanner"
                  />
                </Link>
              }
              title='Android Fing IP Scanner'
              description={
                <p>Entdecken Sie alle Ihre Geräte in Ihrem lokalen Netzwerk mit Ihrem Smartphone. Verfügbar für Android und iOS. <Link to="/Schnell_Installation/Alternative_IP_Scanner/Fing_Mobile/">mehr...</Link></p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
