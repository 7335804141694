import React from 'react'
import {Link} from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-gtag'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Updates:">
        <EuiText>
          <ul>
              <li><Link to="/Erweitert/OpenHAB_3_in_Docker/">OpenHAB 3 Installation in Docker</Link></li>
              <li><Link to="/Erweitert/OpenHAB_3_Camera_Binding/">OpenHAB 3 Camera Binding</Link></li>
              <li><Link to="/Erweitert/OpenHAB_3_MQTT_Binding/">OpenHAB 3 MQTT Binding</Link></li>
              <li><OutboundLink href="https://forum.instar.de/t/openhab-3-in-docker-mit-instar-camera-binding/28043/2">INSTAR Forum: OpenHAB3 in Docker</OutboundLink></li>
          </ul>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

