import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Hinweis:">
      <EuiText>
        <p>Für den Zugriff über das Internet auf den RTSP Stream der Kamera benötigen Sie, wie für den <Link to="/Fernzugriff/Port_Weiterleitung/">LAN (HTTP) Port (Standard 80) beschrieben</Link>, eine Portweiterleitung für den RTSP Port (Standard 554) auf die lokale IP Adresse der Kamera in Ihrem Router!</p>
      </EuiText >
    </EuiCallOut>
  );
}

export default DangerBox

