import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'VGA Serie',
      href: '/de/Bewegungserkennung/Alarm_FTP_Upload/VGA_Serie/',
    },
    {
      text: '720p Serie',
      href: '/de/Bewegungserkennung/Alarm_FTP_Upload/720p_Serie/',
    },
    {
      text: '1080p Serie',
      href: '/de/Bewegungserkennung/Alarm_FTP_Upload/',
    },
    {
      text: '◄ Bewegungserkennung',
      href: '/de/Bewegungserkennung/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={6}
        aria-label="Alarm Upload / FTP Settings for 1080p Cameras"
      />
      <EuiSpacer />
    </Fragment>
  );
}