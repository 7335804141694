import React from 'react'

import {
    EuiText,
    EuiCallOut,
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Achtung:">
        <EuiText>
           <p>Mit den meisten gängigen Routern - z.B. einer AVM FritzBox - ist es möglich, Ihre Kamera über ihre DDNS-Adresse aus dem lokalen Netzwerk heraus anzusprechen. Es gibt jedoch Router wie den <em>Telekom Speedport</em>, die Ihre Anfrage blockieren - Sie können nur über die lokale IP-Adresse (z. B. 192.168.1.201:8081) auf Ihre Kamera zugreifen. Sie müssten also einen Computer in einem anderen Netzwerk verwenden oder alternativ den DDNS-Zugriff über das Mobilfunknetz auf Ihrem Smartphone testen.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

