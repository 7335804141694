import React from 'react'

import {
  EuiImage,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiTitle
} from '@elastic/eui'

import ivImage from '../../../../../images/Software/Windows/InstarVision/Image_Adjust/Image034_cut.png'


export default function ImageCard() {

  return (
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem grow={false}>
            <EuiImage
              hasShadow
              allowFullScreen
              alt="InstarVision v2 for Windows"
              src={ivImage}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiTitle>
              <h4>Videoeinstellungen Ihrer IP Kamera</h4>
            </EuiTitle>
            <EuiText>
                <p>Der Schieberegler <strong>Brightness</strong> passt die Helligkeit Ihres Videos an. Mit <strong>Kontrast</strong> können Sie den Video-Kontrast Ihrer Kamera einstellen. Passen Sie die Videosättigung Ihrer Kamera mit dem letzten Schieberegler <strong>Sättigung</strong> an. Die Standardschaltfläche setzt alle Werte für die ausgewählte Kamera auf ihre Standardeinstellungen zurück. Bitte beachten Sie, dass nicht alle Kameras alle 3 Einstellungen unterstützen! Z.B. Der Sättigungsregler ist bei einer VGA-Kamera nicht verfügbar.</p>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
  );
}
