import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class IPConfigTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Netzwerk Einstellungen" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'IP Adresse', descriptionColumn: 'Dies ist die IPv4-Adresse Ihrer Kamera in Ihrem lokalen Netzwerk. Um auf die Web-Benutzeroberfläche Ihrer Kamera zuzugreifen, geben Sie einfach die IP-Adresse in die Adressleiste des Webbrowsers ein. Wenn der HTTP-Port Ihrer Kamera nicht not 80 ist, fügen Sie den Port der IP-Adresse hinzu, getrennt durch einen:, wie 192.168.1.125:8081.' },
        { actionColumn: 'Subnetz Maske', descriptionColumn: 'Die Subnetzmaske definiert das Subnetz, mit dem Ihre Kamera verbunden ist. In den meisten kleinen Netzwerken ist es 255.255.255.0.' },
        { actionColumn: 'Gateway', descriptionColumn: 'Das Gateway ist die Verbindung zwischen Ihrem lokalen Netzwerk und dem Internet. In den meisten kleinen Netzwerken ist dies die IP-Adresse Ihres Routers - z. 192.168.1.1, 192.168.2.1 oder 192.168.178.1. Die ersten 3 Nummern sind die gleichen wie die IP-Adresse Ihrer Kamera - fügen Sie einfach die Nummer 1 an der letzten Position hinzu.' },
        { actionColumn: 'Primärer DNS Server', descriptionColumn: 'Der DNS-Server wird von Ihrer Kamera benötigt, um Internetadressen aufzulösen. In den meisten kleinen Netzwerken ist dies die IP-Adresse Ihres Routers - z.B. 192.168.1.1, 192.168.2.1 oder 192.168.178.1.' },
        { actionColumn: 'Sekundärer DNS Server', descriptionColumn: 'Dieses Feld können Sie leer lassen, wenn Sie nur einen DNS Server in Ihrem Netzwerk haben. Oder tragen Sie einen öffentlichen Online DNS Server, wie 1.1.1.1, 208.67.222.222 oder 8.8.8.8, als Backup Dienst ein.' },
        { actionColumn: 'HTTP Port', descriptionColumn: 'Der HTTP-Port ist der LAN-Port Ihrer Kamera. Fügen Sie diesen Port der IP-Adresse Ihrer Kamera hinzu, wenn Sie mit Ihrem Webbrowser auf die Web-Benutzeroberfläche zugreifen möchten. Dies ist auch der Port, den Sie weiterleiten  müssen in Ihrem Router, um auf Ihre Kamera, über ihre DDNS Adresse, zuzugreifen mit unserer Smartphone-App (Android, iPhone, iPad, Windows Phone, Windows Metro). Wir empfehlen, dass Sie einen HTTP-Port verwenden, der 8081 oder höher ist. Wenn Sie mehr als eine Kamera haben, fügen Sie immer 1 zu dem Port für die nächste Kamera 8081, 8082, 8083 usw. hinzu. Nur wenn Sie mit unserer Smartphone-App über Ihre DDNS-Adresse auf Ihre Kamera zugreifen wollen!.' },
        { actionColumn: 'HTTPS Port', descriptionColumn: 'Der HTTPS-Port ist der sichere LAN-Port Ihrer Kamera. Fügen Sie diesen Port der IP-Adresse Ihrer Kamera hinzu und fügen Sie das Präfix `https://` hinzu, wenn Sie mit Ihrem Webbrowser auf die Webbenutzeroberfläche zugreifen möchten. `https://192.168.1.200:443`. Dies ist auch der Port, den Sie in Ihrem Router weiterleiten müssen, um mit Ihrem Browser über die DDNS Adresse auf die Kamera zuzugreifen. Wenn du mehr als eine Kamera hast, füge immer 1 zum Port für die nächste Kamera 443, 444, 445, etc. hinzu. Nur wenn Sie mit Ihrem Webbrowser über deine DDNS-Adresse auf deine Kamera zugreifen willst!' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

IPConfigTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(IPConfigTable)