import React from 'react'
import { Link } from 'gatsby'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

import downloadsAndroidSearchIcon from '../../../images/Search/P_SearchThumb_InstarVision_Android.webp'
import downloadsIOSSearchIcon from '../../../images/Search/P_SearchThumb_InstarVision_iOS.webp'
import downloadsWPSearchIcon from '../../../images/Search/P_SearchThumb_InstarVision_WP.webp'


export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Mobile_Apps/Android_BlackBerry/">
                  <img
                    src={downloadsAndroidSearchIcon}
                    alt="InstarVision Android/Blackberry"
                  />
                </Link>
              }
              title="InstarVision Android/Blackberry"
              description="Unsere kostenlose InstarVision App für Ihr Android-Telefon. Behalten Sie die Live-Videos Ihrer Kamera im Auge, wenn Sie nicht zu Hause sind, und lassen Sie sich im Falle eines Alarms benachrichtigen."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Mobile_Apps/Android_BlackBerry/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Mobile_Apps/iOS/">
                  <img
                    src={downloadsIOSSearchIcon}
                    alt="iOS & iPad"
                  />
                </Link>
              }
              title="iOS & iPad"
              description="Unsere kostenlose InstarVision App für Ihr iPhone oder iPad. Behalten Sie die Live-Videos Ihrer Kamera im Auge, wenn Sie nicht zu Hause sind, und lassen Sie sich im Falle eines Alarms benachrichtigen."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Mobile_Apps/iOS/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Mobile_Apps/Windows_Phone/">
                  <img
                    src={downloadsWPSearchIcon}
                    alt="Windows Phone"
                  />
                </Link>
              }
              title="Windows Phone"
              description="Unsere kostenlose InstarVision App für Ihr Windows Phone. Behalten Sie die Live-Videos Ihrer Kamera im Auge, wenn Sie nicht zu Hause sind, und lassen Sie sich im Falle eines Alarms benachrichtigen."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Mobile_Apps/Windows_Phone/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
