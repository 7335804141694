import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="User Management">
        <EuiText>
          <p>
            Die Weboberfläche Ihrer Kamera bietet Ihnen eine dreistufige Benutzerverwaltung für Benutzer mit unterschiedlichen Zugriffsrechten für Administratoren, Benutzer und Besucher. Sie können die Benutzernamen und zugehörige Kennwörter für alle 3 Stufen im Benutzer-Overlay festlegen. Gäste [guest] haben nur Zugriff auf den Videostream der Kamera, Benutzer [user] können die Kamera zusätzlich noch schwenken und nur der Administrator [admin] hat Zugriff auf die Konfigurationsmenüs der Kamera.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

