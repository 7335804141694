
import React, { useState } from 'react'
import {Link} from 'gatsby'

import {
  EuiCallOut,
  EuiFlyout,
  EuiFlyoutHeader,
  EuiFlyoutBody,
  EuiButton,
  EuiTitle,
  EuiText,
  EuiSpacer
} from '@elastic/eui'

import PowerIndoorCard from './power-outdoor-card'
import AovCard from './aov-card'
import NightvisionCard from './nightvision-min-max-card'

export default () => {
  const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);

  const closeFlyout = () => setIsFlyoutVisible(false);

  const showFlyout = () => setIsFlyoutVisible(true);

  let flyout;

  const callOut = (
    <EuiCallOut iconType="help">
      <p>
        Dies is eine Übersicht über des Stromverbrauchs, der Nachtsicht und des diagonalen / horizontalen und vertikalen Blickwinkel der Standardobjektive aller <Link to="/Aussenkameras/">INSTAR Außen IP Kameras</Link>. Für eine Übersicht der <Link to="/Innenkameras/">Innenkameras</Link> folgen Sie bitte dem <Link to="/Innenkameras/">Link</Link>.
      </p>
    </EuiCallOut>
  );

  if (isFlyoutVisible) {
    flyout = (
      <EuiFlyout
        ownFocus
        onClose={closeFlyout}
        size="l"
        aria-labelledby="flyoutWithBannerTitle">
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="m">
            <h2 id="flyoutWithBannerTitle">Außenkameras Vergleichen</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody banner={callOut}>
          <EuiText>
            <h4>Stromverbrauch</h4>
            <p>Dies ist eine Übersicht über die maximale Leistung die Ihre Kamera während der folgenden Betriebsmodi zieht: <strong>Bootvorgang</strong> <em>(minimal)</em>, <strong>Bootvorgang</strong> <em>(Leistungspitzen)</em>, <strong>Bootvorgang</strong> <em>(mit IR LEDs aktiv)</em>, <strong>Kamera Leerlauf</strong>, <strong>Kamera Leerlauf</strong> <em>(mit IR LEDs aktiv)</em>, <strong>Kamera Leerlauf</strong> <em>(mit IR LEDs & WLAN aktiv)</em>, <strong>Kamera Leerlauf</strong> <em>(mit IR LEDs & Kameraschwenk (PTZ))</em>, <strong>Kamera Leerlauf</strong> <em>(mit Kameraschwenk (PTZ))</em>, <strong>Kamera Leerlauf</strong> <em>(mit Videoaufnahme)</em>, <strong>Kamera Leerlauf</strong> <em>(mit Videoaufnahme & IR LEDs aktiv)</em>.</p>
          </EuiText>
          <EuiSpacer/>
          <PowerIndoorCard/>
          <EuiSpacer/>
          <EuiText>
            <h4>Standardobjektiv Öffnungswinkel</h4>
            <p>Dies ist eine Übersicht über den diagonalen, vertikalen und horizontalen Blickwinkel, den Sie mit Ihrer INSTAR Kamera abdecken. Beachten Sie bitte das bei großen Öffnungswinkel ein Fischaugeneffekt auftritt, über den man einen etwas größeren Bereiche abdeckt als es die berechneten Werte hier wiedergeben. Wenn Sie in den Bildeinstellungen Ihrer Kamera die Fischaugenkorrektur so einstellen, dass alle Linien im Bild - die gerade sein sollten - auch gerade sind, werden Sie bei den unten angegebenen Blickwinkel ankommen.</p>
          </EuiText>
          <AovCard/>
          <EuiText>
            <h4>Infrarot Nachtischt</h4>
            <p>Dies ist eine Übersicht der maximalen und minimalen Reichweite der Nachtsicht Ihrer INSTAR IP Kamera. Beachten Sie bitte das die Nachtsicht um so besser wird, je mehr IR Licht zur Kamera zurückreflektiert wird. D.h. in Innenräumen haben Sie i.d.R. eine bessere Nachtsicht als im Freien und Grünanlagen können ein Großteil des IR Licht diffuse streuen und somit die Nachtsicht stark einschränken.</p>
          </EuiText>
          <NightvisionCard/>
        </EuiFlyoutBody>
      </EuiFlyout>
    );
  }
  return (
    <div>
      <EuiSpacer />
      <EuiButton onClick={showFlyout} color="text">Technische Daten</EuiButton>
      {flyout}
    </div>
  );
};