import React from 'react'

import {
  EuiPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiTitle,
  EuiImage,
  EuiSpacer
} from '@elastic/eui'

import relaySpecsImage from '../../../../images/Indoor_Cameras/IN-8015_HD/Technical_Specifications/HD_Camera_Alarm_Relay.png'
import powerSpecsImage from '../../../../images/Outdoor_Cameras/IN-7011_HD/Technical_Specifications/5907-7011-Netzteil.jpg'
import domeSpecsImage from '../../../../images/Outdoor_Cameras/IN-7011_HD/Technical_Specifications/Kuppel_IN7011HD.jpg'

export default function FooterCards() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiPanel paddingSize="m">
              <EuiTitle size="m">
                <h3>[3] Alarm Relais Spezifikationen</h3>
              </EuiTitle>
              <EuiImage
                allowFullScreen
                alt='HD Kameras Alarm Relais Spezifikationen'
                src={relaySpecsImage}
              />
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiPanel paddingSize="m">
              <EuiTitle size="m">
                <h3>[6] Netzteil</h3>
              </EuiTitle>
              <EuiImage
                allowFullScreen
                alt='IN-7011 HD Netzteil'
                src={powerSpecsImage}
              />
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiPanel paddingSize="m">
              <EuiTitle size="m">
                <h3>[7] Kamera Kuppel</h3>
              </EuiTitle>
              <EuiImage
                allowFullScreen
                alt='IN-7011 HD Kamera Kuppel'
                src={domeSpecsImage}
              />
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
