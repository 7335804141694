import React from 'react'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import webUI108001 from "../../../images/Motion_Detection/SD_Card_Access/WebUI-SD_01_1080.webp"
import webUI108002 from "../../../images/Motion_Detection/SD_Card_Access/WebUI-SD_02_1080.webp"
import webUISD01 from "../../../images/Motion_Detection/SD_Card_Access/WebUI-SD_01.webp"
import webUISD02 from "../../../images/Motion_Detection/SD_Card_Access/WebUI-SD_02.webp"
import webUISD03 from "../../../images/Motion_Detection/SD_Card_Access/WebUI-SD_03.webp"
import webUISD04 from "../../../images/Motion_Detection/SD_Card_Access/WebUI-SD_04.webp"




export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={webUI108001}
                    alt="1080p WebUI"
                  />
              }
              title='1080p WebUI'
              description={
                <p>Die Kameras der HD Serie ermöglichen es Ihnen Videoaufnahmen oder Bilder direkt auf die interne SD Karte zu speichern. Um die Aufnahmen schnell einzusehen, klicken Sie auf SD Karte oben links in der Weboberfläche Ihrer Kamera.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={webUI108002}
                    alt="1080p WebUI"
                  />
              }
              title='1080p WebUI'
              description={
                <p>Das Verzeichnis der SD Karte wird in einem neuen Tab geöffnet. Melden Sie sich ggbfs. mit dem Benutzernamen und dem Passwort Ihrer Kamera an um die Aufnahmen einzusehen. Sollte die SD Karte nicht angezeigt werden, so kann durch das konstante Beschreiben ggf. ein Fehler in der Verzeichnisstruktur entstanden sein. In diesem Fall formatieren Sie am besten einmal die SD Karte oder entfernen Sie diese kurzzeitig aus der Kamera.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={webUISD01}
                    alt="720p WebUI"
                  />
              }
              title='720p WebUI'
              description={
                <p>Ihre Kamera generiert ein neues Verzeichnis für jeden Tag und ein neues recordXXX Unterverzeichnis für jeweils 200 Alarmaufnahmen dieses Tages. Jeder Alarm erzeugt eine *.avi Datei, die mit dem Großbuchstaben A beginnt - geplante Aufnahmen starten mit dem Buchstaben P und so weiter - gefolgt von dem Zeitcode des auslösenden Ereignis.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={webUISD02}
                    alt="720p WebUI"
                  />
              }
              title='720p WebUI'
              description={
                <p>Das Standardverhalten der meisten Browser ist es, das Video - das Sie anklicken - herunterzuladen. Browser, wie z.B. der Mozilla Firefox Browsers, unterstützen das VLC plugin um das Video direkt im Browser-Fenster wiederzugeben. Stellen Sie dafür bei der Installation des VLC Players sicher, dass Sie das Firefox Plugin aktivieren und starten Sie den Browser nach der Installation neu. Das VLC Web Plugin sollte danach in der Addons Liste unter Plugins aufgeführt sein.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={webUISD03}
                    alt="720p WebUI"
                  />
              }
              title='720p WebUI'
              description={
                <p>Ihre Kamera generiert ein neues Verzeichnis für jeden Tag und ein neues recordXXX Unterverzeichnis für jeweils 200 Alarmaufnahmen dieses Tages. Jeder Alarm erzeugt eine *.avi Datei, die mit dem Großbuchstaben A beginnt - geplante Aufnahmen starten mit dem Buchstaben P und so weiter - gefolgt von dem Zeitcode des auslösenden Ereignis.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={webUISD04}
                    alt="720p WebUI"
                  />
              }
              title='720p WebUI'
              description={
                <p>Das Standardverhalten der meisten Browser ist es, das Video - das Sie anklicken - herunterzuladen. Browser, wie z.B. der Mozilla Firefox Browsers, unterstützen das VLC plugin um das Video direkt im Browser-Fenster wiederzugeben. Stellen Sie dafür bei der Installation des VLC Players sicher, dass Sie das Firefox Plugin aktivieren und starten Sie den Browser nach der Installation neu. Das VLC Web Plugin sollte danach in der Addons Liste unter Plugins aufgeführt sein.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
