import { EuiSpacer } from '@elastic/eui';
import VideoCard from 'components/Products/IN-LAN/video-in-lan-500';
import NavButtons from 'components/Products/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  VideoCard,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};