import { EuiSpacer } from "@elastic/eui";
import TableFeatures from "components/Outdoor_Cameras/IN-9420_WQHD/Product_Features/TableFeatures";
import TableStatus from 'components/Outdoor_Cameras/IN-9420_WQHD/Product_Features/TableStatus';
import NavButtons from "components/Outdoor_Cameras/IN-9420_WQHD/NavButtons";
import BreadCrumbs from "components/Layout/BreadCrumbs";
import SEOHelmet from "components/Layout/SEOHelmet";
import * as React from 'react';
export default {
  EuiSpacer,
  TableFeatures,
  TableStatus,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};