import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1080p_Serie/Netzwerk/IP_Konfiguration/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1080p_SearchThumb_Network_IP_Configuration.webp'
                    alt="Netzwerk Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IP Konfiguration"
              description="Ändern Sie die IP-Adresse, das Gateway und den DNS-Server Ihrer Kamera - alle diese Einstellungen werden normalerweise vom Installationsassistenten eingerichtet und müssen nicht geändert werden..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1080p_Serie/Netzwerk/IP_Konfiguration/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1080p_Serie/Netzwerk/SSL_Zertifikat/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1080p_SearchThumb_Network_IP_Configuration.webp'
                    alt="Network Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="SSL Zertifikat"
              description="Unsere Kamera verwendet ein gültiges CA-SSL-Zertifikat, mit dem Sie über Ihre persönliche DDNS-Adresse verschlüsselt auf die SSL-Web-Oberfläche zugreifen können. Wenn Sie ein eigenes SSL-Zertifikat benötigen, können Sie dieses hier auf die Kamera hochladen."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1080p_Serie/Netzwerk/SSL_Zertifikat/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1080p_Serie/Netzwerk/WLAN/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1080p_SearchThumb_Network_WiFi.webp'
                    alt="Network Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="WLAN"
              description="Ihre WiFi-Verbindung sollte bereits per WPS oder durch den Installationsassistenten eingerichtet worden sein. Verwenden Sie den WiFi-Scanner, um Ihre Kamera mit einem anderen WiFi-Netzwerk zu verbinden..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1080p_Serie/Netzwerk/WLAN/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1080p_Serie/Netzwerk/Fernzugriff/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1080p_SearchThumb_Network_Remote_Access.webp'
                    alt="Netzwerk Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Fernzugriff"
              description="Sie haben zwei Möglichkeiten, über das Internet auf Ihre Kamera zuzugreifen - der einfache Weg heißt. Der einzige Weg, um vollständigen Zugriff auf alle Funktionen Ihrer Kamera zu erhalten, heißt DDNS..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1080p_Serie/Netzwerk/Fernzugriff/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1080p_Serie/Netzwerk/UPnP/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1080p_SearchThumb_Network_Remote_Access.webp'
                    alt="Netzwerk Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="UPnP Service"
              description="Der UPnP-Standard soll Ihnen das Leben leicht machen. Jeder Port, der von einem Gerät in Ihrem Netzwerk öffentlich benötigt wird, wird automatisch weitergeleitet..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1080p_Serie/Netzwerk/UPnP/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1080p_Serie/Netzwerk/ONVIF/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1080p_SearchThumb_Network_ONVIF.webp'
                    alt="Netzwerk Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="ONVIF Dienst"
              description="Die Überwachungsindustrie hat ein offenes Standardprotokoll namens ONVIF geschaffen, das es Kameras ermöglicht, untereinander und mit Netzwerkaufzeichnungsgeräten zu kommunizieren..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1080p_Serie/Netzwerk/ONVIF/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
