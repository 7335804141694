import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class ConfigTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "parameterColumn", title: "Parameter" },
        { name: "valueColumn", title: "Description" }
      ],
      rows: [
        { parameterColumn: 'IP Adresse', valueColumn: 'Wählen Sie hier eine IP-Adresse für Ihre Kamera aus. Aber achten Sie darauf, dass die IP-Adresse Ihrem Router angepasst ist! Für eine Fritzbox, zum Beispiel, ist dies standardmäßig 192.168.178.XXX, für einen Telekom Speedport Router die 192.168.2.XXX. Damit es nicht zu Fehlern in Ihrem lokalen Netzwerk kommt, wählen Sie am besten eine freie Zuordnungszahl für Ihre Kamera ab dem Wert 200. Im Falle einer Fritzbox vergeben Sie also für Kamera 1 die 192.168.178.201, für Kamera 2 die 192.168.178.202, usw.' },
        { parameterColumn: 'Subnetzmaske', valueColumn: 'Hier geben Sie standardmäßig die __255.255.255.0__ ein.' },
        { parameterColumn: 'Gateway', valueColumn: 'Hier geben Sie die IP-Adresse Ihres Routers ein, zB 192.168.178.1 falls Sie eine Fritzbox benutzen oder 192.168.2.1 für den Telekom Speedport Router.' },
        { parameterColumn: 'DNS Server', valueColumn: 'Hier geben Sie ebenfalls die IP-Adresse Ihres Routers ein.' },
        { parameterColumn: 'HTTP Port', valueColumn: 'Vergeben Sie hier für jede Ihrer Kamera einen Wert ab 8081 aufsteigend. Also für Kamera 1 den Wert 8081, für Kamera 2 den Wert 8082, usw. (Bei einer FullHD Kamera lassen Sie diesen Port auf 80)' },
        { parameterColumn: 'HTTPS Port', valueColumn: '(Nur bei FullHD Kameras) Vergeben Sie hier für jede Ihrer Kamera einen Wert ab 8081 aufsteigend. Also für Kamera 1 den Wert 8081, für Kamera 2 den Wert 8082, usw. Klicken Sie hier wenn Sie keinen Ton in unserer Handy App InstarVision bekommen.' },
        { parameterColumn: 'RTMP Port', valueColumn: 'Vergeben Sie hier für jede Ihrer Kamera einen Wert ab 1935 aufsteigend. Also für Kamera 1 den Wert 1935, für Kamera 2 den Wert 1936, usw. Dieser Port wird nur verwendet, wenn Sie Ihre Kamera über das Internet mit einem Webbrowser aufrufen. Wenn Sie nur unsere Smartphone App InstarVision nutzen, brauchen Sie den RTMP / Flash Port nicht weiter zu konfigurieren oder weiterleiten.' },
        { parameterColumn: 'RTSP Port', valueColumn: 'Vergeben Sie hier für jede Ihrer Kamera einen Wert ab 554 aufsteigend. Also für Kamera 1 den Wert 554, für Kamera 2 den Wert 555, usw. Sie brauchen diesen Port nur konfigurieren, wenn Sie planen den RTSP Videostream Ihrer Kamera über das Internet abzurufen. Dieser wird von manchen Drittanbieter Software benötigt, oder wenn Sie Probleme mit dem Audio bei Ihrer Full HD Kamera haben.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

ConfigTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(ConfigTable)




// import React from 'react'
// import {Table, Column, Cell} from 'fixed-data-table-2'
// import '../../../assets/styles/data_tables/fixed-data-table.css'

// const parameterRow = [
//   'IP-Address',
//   'Subnetmask',
//   'Gateway',
//   'DNS Server',
//   'HTTP Port',
//   'HTTPS Port',
//   'RTMP Port',
//   'RTSP Port'
// ]

// const descriptionRow = [
//   'Here you have to choose an IP-Address for your camera. But be aware, that the IP Address has to relate to your router! Taking the Fritzbox as an example, the beginning of the IP-Address is __192.168.178.XXX__ by default, for a Telekom Speedport Router it is __192.168.2.XXX__. To avoid mistakes in your local network, we recommend to choose the last number higher than 200 (outside of the default DHCP range). If you own a FritzBox, then you take the 192.168.178.201 for your first camera, for your second camera you choose 192.168.178.202, and so on.',
//   'Enter __255.255.255.0__ by default.',
//   'In most cases the gateway is the IP Address of your router, for example 192.168.178.1 if you own a FritzBox, or 192.168.2.1 if you own a Telekom Speedport Router.',
//   'In most cases it is your router that handles DNS on your network - just enter the IP Address of your router.',
//   'Here you have to enter a different port for each of your camera, starting at __8081__ ascending. Meaning for camera 1 enter 8081, for camera 2 enter 8082, etc. (In case of a __Full HD Camera__ leave this Port on 80)',
//   'This is __only for the FullHD-Cameras__ - Here you have to enter a different port for each of your cameras, starting at __8081__ ascending, meaning for camera 1 enter 8081, for camera 2 enter 8082, etc. [Click Here](/Software/Mobile_Access_Over_HTTPS/) if you don`t have audio inside our mobile app InstarVision.',
//   'Here you have to enter a port starting at __1935__ ascending. Thus for camera 1 should be set to port 1935, for camera 2 port it´s 1936, etc. This is only important, if you want to access your camera over the internet inside a web browser. You don`t need to configure or forward the RTMP/Flash Port in case you only use our smartphone app InstarVision.',
//   'Here you have to enter a port starting at __554__ ascending. Thus for camera 1 it´s port 554, for camera 2 port it´s 555, etc. Only configure and forward this port, if you plan to access your cameras RTSP stream over the internet. The RTSP stream is needed for certain 3rd Party Software - as well as - when you are having [difficulties with audio](/Software/Mobile_Access_Over_HTTPS) with your Full HD Camera.',
// ]



// // Render your table
// const ConfigTable = () => {
//     return (
//       <Table
//         rowHeight={55}
//         rowsCount={parameterRow.length}
//         touchScrollEnabled={true}
//         width={850}
//         maxHeight={1000}
//         headerHeight={50}>
//         <Column
//           header={<Cell>Parameter</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {parameterRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={1}
//           width={150}
//         />
//         <Column
//           header={<Cell>Description</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {descriptionRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={3}
//           width={700}
//         />
//       </Table>
//     )
// }

// export default ConfigTable