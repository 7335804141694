import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Wichtiger Hinweis!">
        <EuiText>
            <p>Es ist wichtig, dass der private RSA-Schlüssel oder der private Schlüssel, den Sie im <Link to="/Web_User_Interface/1440p_Series/Network/SSL_Certificate/">Menü SSL-Zertifikate</Link> hochgeladen haben, mit dem <code>X.509</code> Zertifikat übereinstimmt. Wenn die HASH-Werte nicht übereinstimmen, kann die Kamera den öffentlichen Schlüssel von verbindenden Clients nicht verifizieren und Dienste wie der <Link to="/Advanced_User/INSTAR_MQTT_Broker/">INSTAR MQTT Broker</Link> müssen im unsicheren Modus betrieben werden.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox
