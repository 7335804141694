import React from 'react'
import { Link } from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="OpenHAB">
        <EuiText>
         <h2>Alle OpenHAB Tutorials</h2>
         <ul>
            <li><Link to="/Erweitert/OpenHAB_3_Camera_Binding/">OpenHAB 3 Camera Binding</Link></li>
            <li><Link to="/Erweitert/OpenHAB_3_Camera_Widget/">OpenHAB 3 Camera Widget</Link></li>
            <li><Link to="/Erweitert/OpenHAB_3_in_Docker/">OpenHAB 3 in Docker</Link></li>
            <li><Link to="/Erweitert/OpenHAB_3_MQTT_Binding/">OpenHAB 3 MQTT Binding</Link></li>
            <li><Link to="/Erweitert/OpenHAB_Heimautomatisierung/">OpenHAB Smarthome</Link></li>
            <li><Link to="/Erweitert/OpenHAB_IP_Camera_Binding/">OpenHAB IP Camera Binding</Link></li>
            <li><Link to="/Erweitert/openHABian_auf_Raspberry_Pi/">openHABian unter Raspberry Pi</Link></li>
            <li><Link to="/Erweitert/INSTAR_MQTT_Broker/OpenHAB2/">INSTAR MQTT - OpenHAB2</Link></li>
         </ul>
         
         <h2>Alle OpenHAB FAQs</h2>
         <ul>
           <li><Link to="/Frequently_Asked_Question/OpenHab3_IpCamera_Alarmserver/">OpenHab3 IpCamera Binding Alarmserver</Link></li>
           <li><Link to="/Frequently_Asked_Question/OpenHab3_IpCamera_Binding/">OpenHab3 IpCamera Binding mit einer WQHD Kamera</Link></li>
           <li><Link to="/Frequently_Asked_Question/OpenHab3_Docker_IpCamera/">Verwendung von OpenHab3 in Docker mit einer WQHD-Kamera</Link></li>
           <li><Link to="/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_I/">OpenHAB v3 Konfiguration für Ihren WQHD INSTAR MQTTv5 Broker</Link></li>
           <li><Link to="/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_II/">OpenHAB v3 dateibasierte Konfiguration für Ihren WQHD INSTAR MQTTv5 Broker</Link></li>
           <li><Link to="/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_III/">OpenHAB v3 Rules für Ihren WQHD INSTAR MQTTv5 Broker</Link></li>
           <li><Link to="/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_IV/">OpenHAB v3 Blockly Scripts für Ihren WQHD INSTAR MQTTv5 Broker</Link></li>
           <li><Link to="/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_V/">OpenHAB v3 Sitemaps für Ihren WQHD INSTAR MQTTv5 Broker</Link></li>
           <li><Link to="/Frequently_Asked_Question/Debugging_the_OpenHAB_IP_Camera_Binding/">Debugging des OpenHAB IP Camera Bindings</Link></li>
         </ul>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox