import React from 'react'

import {
  EuiImage,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'

import ivImage from '../../../../../../images/Software/Windows/InstarVision/Advanced/Explorer/Image049_Explorer.png'

export default function ImageCard() {

  return (
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem grow={false}>
            <EuiImage
              hasShadow
              allowFullScreen
              alt="InstarVision v2 for Windows"
              src={ivImage}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiText>
              <p><strong>Verwalten Sie Ihre Videoaufnahmen manuell</strong></p>
              <p>Der Explorer Button öffnet das aktive Aufnahmeverzeichnis der InstarVision im Windows Explorer und gibt Ihnen Zugriff auf alle Videoaufnahmen der Software. Der Ordner wird vom InstarVision Sicherheit Center organisiert - es wird für jeden Tag ein neuer Ordner und jeweils ein Unterordner für jeden aktiven Kamerakanal angelegt.</p>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
  );
}
