import React from 'react'
import { OutboundLink } from 'gatsby-plugin-gtag'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiIcon
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="9s3P1TTj0Lc"
                    title="Firmware auf Ihre INSTAR HD Kamera aufspielen"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              title="Firmware auf Ihre INSTAR HD Kamera aufspielen"
              footer={
                <EuiText>
                <p>In diesem Video erklären wir Ihnen wie Sie die neueste Firmware auf Ihre INSTAR HD Kamera aufspielen können und wir stellen auch die neuesten Funktionen vor die Ihnen die neue Firmware bietet. Auf <OutboundLink href="https://www.youtube.com/watch?v=9s3P1TTj0Lc" target="_blank" rel="noopener noreferrer">Youtube ansehen <EuiIcon type="popout" /></OutboundLink></p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}