import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" iconType="user" color="danger" title="Zugriffsbeschränkung">
        <EuiText>
          <p>
            Ihre Kamera verfügt über ein fein abgestuftes Zugriffsrechtesystem, mit dem Sie den Zugriff auf bestimmte Menüs der Web-Benutzeroberfläche und die zugrunde liegenden <Link to="/1440p_Serie_CGI_Befehle/Komplette_CGI_Liste/">CGI-Befehle</Link> einschränken können. Die Mindestberechtigung für den Zugriff auf dieses Menü ist:
          </p>
          <ul>
            <li><strong>Benutzerrechte</strong>: <code>System Einstellungen</code>, <code>RTSP Video</code></li>
          </ul>
          <p>
            Die Benutzerberechtigungen können im <Link to="/Web_User_Interface/1440p_Serie/System/Benutzer/">Benutzer Menü</Link> angepasst werden.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

