import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Manuelle Video Aufnahme">
        <EuiText>
          <p>
            Definieren Sie hier die Gesamtdauer und Auflösung für manuelle Aufnahmen, die Sie über die Schnellleiste initiieren können. Beachten Sie, dass die eingestellte Vorlaufzeit in die Gesamtdauer der Aufnahme integriert ist.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

