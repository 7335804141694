import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Weitere Anmerkung">
        <EuiText>
          <p>Ihre Kamera unterstützt weitere Verschlüsselungsstandards - wir empfehlen für Ihre Sicherheit und eine zuverlässige Verbindung den WPA2 Standard mit AES/CCMP. Wenn Sie einen älteren Router verwenden, wählen Sie WPA (PSK) / AES. Vermeiden Sie den gemischten Modus WPA/WPA2 - TKIP! Wenn Ihr WiFi unstabil bleibt, bitte wählen Sie einen festen WiFi Kanal für Ihren Router - wir empfehlen die Kanäle 1 - 6.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

