import React from 'react'
import { OutboundLink } from 'gatsby-plugin-gtag'


import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="alert" title="Verbotene Zeichen">
        <EuiText>
          <h3>URL Encoding</h3>
          <p>Alle CGI-Befehle sowie Video- und Snapshot-URLs können verwendet werden, indem Sie Ihren Benutzer-Login an die URL anhängen, um sich automatisch zu authentifizieren. Diese Methode wird <strong>BasicAuth</strong> oder Basisauthentifizierung genannt. Beachten Sie, dass die Gruppe der bei dieser Methode zulässigen Zeichen begrenzt ist und das Hinzufügen von Sonderzeichen zu Ihrem Login diese Methode verhindern kann. Wenn Sie zum Beispiel ein <strong>?</strong> oder <strong>@</strong> zu Ihrem Passwort hinzufügen, wie im folgenden Beispiel, funktioniert das nicht:</p>
          <ul>
            <li>/snap.cgi?chn=11&user=myuser&pwd=P@ssword?123</li>
          </ul>
          <p>Wenn Sie diese URL in der Adressleiste des Browsers zur IP-Adresse Ihrer Kamera hinzufügen, wird eine Fehlermeldung angezeigt, da die Authentifizierung abgelehnt wird. Sie können diese verbotenen Zeichen jedoch mit der URL-Kodierung umgehen. Eine Liste aller verbotenen Zeichen und ihrer Kodierung finden Sie auf der <OutboundLink href='https://www.w3schools.com/tags/ref_urlencode.ASP' target="_blank" rel="noreferrer">W3 Webseite</OutboundLink>. So kann das <strong>@</strong> durch <strong>%40</strong> und <strong>?</strong> durch <strong>%3F</strong> ersetzt werden und schon funktioniert wieder alles:</p>
          <ul>
            <li>/snap.cgi?chn=11&user=myuser&pwd=P%40ssword%3F123</li>
          </ul>
          <p>Besuchen Sie die <OutboundLink href='https://www.w3schools.com/tags/ref_urlencode.ASP' target="_blank" rel="noreferrer">W3 Webseite</OutboundLink> für eine Liste aller Kodierungen!</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

