import React, { Component } from "react"
import {EuiSpacer, EuiText} from '@elastic/eui'

import {
  ReactiveBase,
  DataSearch,
  ReactiveList,
  ResultList,
} from "@appbaseio/reactivesearch"

import { _connectionString, _index } from './ElasticParam'

const { ResultListWrapper } = ReactiveList

class ReactiveSearch extends Component {
  render() {
    
    const wrapper = {
      marginTop: 75
    };

    const flexBox = {
      display: 'flex',
      marginTop: 20
    };
    
    const resultContainer = {
      maxWidth: "100%",
      marginLeft: 20,
      padding: 10
    };
    const resultCard = {
      background: 'white',
      margin: '10px 0',
      padding: 15,
      boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)'
    };

    return (
      <div style={wrapper}>
        <ReactiveBase
          url={_connectionString}
          app={_index}
          // type={_type}
          theme={{
            colors: {
              primaryColor: "#bebebe",
              textColor: "#7e7e7e"
            },
          }}
        >
          <DataSearch
            componentId="q"
            placeholder="INSTAR Search Assistant"
            dataField={["title", "tags", "abstract", "description"]}
            fieldWeights={[8, 10, 5, 3]}
            searchOperators={false}
            autosuggest={true}
            highlight={false}
            fuzziness={1}
            debounce={100}
            // URLParams={true}
            defaultValue="⁇"
          />
          <div style={flexBox}>
            <ReactiveList
              from={0}
              react={{
                and: ["q"],
              }}
              componentId="SearchResult"
              dataField="chapter.raw"
              stream={true}
              pagination={true}
              paginationAt="bottom"
              size={5}
              loader="Loading Results.."
              showResultStats={true}
              scrollOnChange={true}
            >
              {({ data }) => (
                <div style={resultContainer}>
                  <ResultListWrapper>
                    {data.map((item) => (
                      <div style={resultCard} key={item._id}>
                        <ResultList href={'/de'+item.sublink1} onClick={this.props.closeFlyout}>
                            <ResultList.Image src={item.imagesquare} style={{ minWidth: 125 }} />
                            <ResultList.Content>
                              <EuiText size="s">
                                <ResultList.Title
                                  dangerouslySetInnerHTML={{
                                    __html: item.title,
                                  }}
                                />
                                <EuiSpacer />
                                <ResultList.Description
                                  dangerouslySetInnerHTML={{
                                    __html: item.abstract,
                                  }}
                                />
                              </EuiText>
                            </ResultList.Content>
                          </ResultList>
                      </div>
                    ))}
                  </ResultListWrapper>
                </div>
              )}
            </ReactiveList>
          </div>
        </ReactiveBase>
      </div>
    );
  }
}

export default ReactiveSearch
