import React from 'react'

import 'react-vis/dist/style.css'

import {
  FlexibleWidthXYPlot,
  XAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  DiscreteColorLegend,
  Crosshair
} from 'react-vis'

const DATA = [
  [
    { x: 'IN-6001 HD', y: 75.3 },
    { x: 'IN-6012 HD', y: 44.1 },
    { x: 'IN-6014 HD', y: 75.3 },
    { x: 'IN-5905 HD', y: 78.1 },
    { x: 'IN-5907 HD', y: 44.1 },
    { x: 'IN-7011 HD', y: 56.8 },
    { x: 'IN-8003 FHD', y: 72.3 },
    { x: 'IN-8015 FHD', y: 72.3 },
    { x: 'IN-9008 FHD', y: 72.3 },
    { x: 'IN-9408 WQHD', y: 72.3 }
  ],
  [
    { x: 'IN-6001 HD', y: 64 },
    { x: 'IN-6012 HD', y: 37.5 },
    { x: 'IN-6014 HD', y: 64 },
    { x: 'IN-5905 HD', y: 66.3 },
    { x: 'IN-5907 HD', y: 37.5 },
    { x: 'IN-7011 HD', y: 48.2 },
    { x: 'IN-8003 FHD', y: 61.4 },
    { x: 'IN-8015 FHD', y: 61.4 },
    { x: 'IN-9008 FHD', y: 61.4 },
    { x: 'IN-9408 WQHD', y: 61.4 }
  ],
  [
    { x: 'IN-6001 HD', y: 39.7 },
    { x: 'IN-6012 HD', y: 23.3 },
    { x: 'IN-6014 HD', y: 39.7 },
    { x: 'IN-5905 HD', y: 41.2 },
    { x: 'IN-5907 HD', y: 23.3 },
    { x: 'IN-7011 HD', y: 29.9 },
    { x: 'IN-8003 FHD', y: 38.1 },
    { x: 'IN-8015 FHD', y: 38.1 },
    { x: 'IN-9008 FHD', y: 38.1 },
    { x: 'IN-9408 WQHD', y: 38.1 }
  ]
];

export default class Example extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      crosshairValues: []
    };
  }

  render() {
    return (
      <React.Fragment>

        <div className="chart">
          <FlexibleWidthXYPlot
            onMouseLeave={() => this.setState({ crosshairValues: [] })}
            height={300}
            xType="ordinal"
            stackBy="y">

            <VerticalGridLines />

            <HorizontalGridLines />

            <XAxis />

            <VerticalBarSeries
              onNearestX={(value, { index }) =>
                this.setState({ crosshairValues: DATA.map(d => d[index]) })}
              cluster="aov"
              color="#0077cc"
              data={DATA[2]} />
 
            <VerticalBarSeries
              cluster="aov"
              color="#f98510"
              data={DATA[1]} />

            <VerticalBarSeries
              cluster="aov"
              color="#00b3a4"
              data={DATA[0]} />

            <Crosshair values={this.state.crosshairValues} />

          </FlexibleWidthXYPlot>
        </div>

        <div className="legend">
          <DiscreteColorLegend
            orientation="horizontal"
            items={[
              {
                title: 'Datensatz[0] diagonal [°]',
                color: '#00b3a4'
              },
              {
                title: 'Datensatz[1] horizontal [°]',
                color: '#f98510'
              },
              {
                title: 'Datensatz[2] vertikal [°]',
                color: '#0077cc'
              }
            ]}
          />
        </div>

      </React.Fragment>
    );
  }
}