import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1080p_Serie/Alarm/Alarm_Server/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1080p_SearchThumb_Smarthome_Alarmserver.webp'
                    alt="Smarthome Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Alarm Server"
              description="Verwenden Sie Ihre Kamera, um Ihr Hausautomatisierungssystem im Falle einer Bewegungserkennung zu benachrichtigen."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1080p_Serie/Alarm/Alarm_Server/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1080p_Serie/Netzwerk/MQTT/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1080p_SearchThumb_Smarthome_MQTT.webp'
                    alt="Smarthome Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="MQTT Broker"
              description="Konfigurieren Sie den INSTAR MQTT-Broker - aktivieren Sie den Server, oder wechseln Sie zu einem externen Broker. Sie können auch die Authentifizierung konfigurieren und Ihr persönliches SSL-Zertifikat hinzufügen..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1080p_Serie/Netzwerk/MQTT/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1080p_Serie/Smarthome/Alexa_Skill/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1080p_SearchThumb_Smarthome_Alexa.webp'
                    alt="Smarthome Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Alexa Skill"
              description="Wir stellen sowohl einen INSTAR Cloud Skill als auch einen INSTAR Camera Skill für Sie im Alexa Skill Store bereit. Für den INSTAR Cloud Skill benötigen Sie lediglich ein INSTAR Cloud-Konto und ein Alexa-Gerät mit einem Display. Fügen Sie den Skill auf Ihrem Alexa-Gerät hinzu und nutzen Sie ihn direkt."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1080p_Serie/Smarthome/Alexa_Skill/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1080p_Serie/Smarthome/IFTTT_Applets/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1080p_SearchThumb_Smarthome_IFTTT.webp'
                    alt="Smarthome Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IFTTT Applets"
              description="Auf der IFTTT-Plattform haben wir einen Dienst namens INSTAR bereitgestellt. Die INSTAR Applets bieten Ihnen eine Möglichkeit, einige Einstellungen Ihrer INSTAR Kamera oder INSTAR Cloud mit IFTTT zu steuern. Sie können INSTAR mit Standort- und Datum & Zeit-Triggern verbinden und Benachrichtigungen erhalten und mehr. Zum Beispiel können Sie die INSTAR Applets mit dem IFTTT Location Service verbinden und so den Alarm ausschalten, wenn Sie zu Hause ankommen. Sie können die INSTAR Applets auch verwenden, um Ihre eigene Automatisierung zu erstellen, indem Sie sie mit anderen Applets von Drittanbietern verbinden."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1080p_Serie/Smarthome/IFTTT_Applets/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1080p_Serie/Smarthome/Homekit/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1080p_SearchThumb_Smarthome_Homekit.webp'
                    alt="Smarthome Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="INSTAR Homekit"
              description="Verwenden Sie Ihre INSTAR Full HD IP-Kamera in Apple Homekit. HomeKit ist ein Software-Framework von Apple, das in iOS/iPadOS zur Verfügung gestellt wird und mit dem Sie mit Ihrer Kamera über Apple-Geräte kommunizieren und diese steuern können. Es bietet Ihnen eine Möglichkeit, Ihre Kamera automatisch zu erkennen und auf ihr Live-Video zuzugreifen."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1080p_Serie/Smarthome/Homekit/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
