import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Privacy Mask">
        <EuiText>
          <p>
            Das Menü Privatsphäre erlaubt Ihnen Bereiche aus Ihrer Überwachung auszuschwärzen. Zum Beispiel wenn Sie auf den Videos noch einen Teil der öffentlichen Straße sehen, Sie die Kamera aber nicht mehr weiter drehen können. Sie können jetzt bis zu 4 Masken einstellen um zu verhindern, dass die Straße in Ihren Überwachungsaufnahmen angezeigt wird - aktivieren Sie dazu einfach einen Bereich und zeichnen Sie ihn im Bild ein.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

