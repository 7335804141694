import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Fernzugriff/Point_to_Point/">
                  <StaticImage
                    src='../../images/Search/IA_SearchThumb_P2P.webp'
                    alt="P2P Fernzugriff"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="P2P Fernzugriff"
              description="Einrichten einer Point2Point-Verbindung mit Ihrer Kamera InstarVision 2 für Windows Lesen Sie in unserem Benutzerhandbuch nach, wie Sie Ihre INSTAR P2P-Kamera zu unserer Windows-Software InstarVision 2 hinzufügen. InstarVision Mobile ..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Fernzugriff/Point_to_Point/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Fernzugriff/Der_DDNS_Dienst/">
                  <StaticImage
                    src='../../images/Search/IA_SearchThumb_DDNSService.webp'
                    alt="DDNS Anbieter"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="INSTAR DDNS Service"
              description="Unterschiede zwischen Kamerazugriff im lokalen Netzwerk und dem Fernzugriff und wie man der DDNS-Dienst für INSTAR IP Kameras einrichten kann."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Fernzugriff/Der_DDNS_Dienst/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Fernzugriff/Port_Weiterleitung/">
                  <StaticImage
                    src='../../images/Search/IA_SearchThumb_Portforwarding.webp'
                    alt="Port Weiterleitung"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Port Weiterleitung"
              description="Verbindung zu Ihrer Kamera über das Internet herstellen Die Schritte zum Weiterleiten eines Ports sind bei jedem Internet-Router nahezu identisch. Im Folgenden geben wir eine Schritt-für-Schritt-Anleitung für die gängigsten Router..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Fernzugriff/Port_Weiterleitung/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Fernzugriff/Mobil_Zugriff/">
                  <StaticImage
                    src='../../images/Search/IA_SearchThumb_Mobile.webp'
                    alt="Mobiler Zugriff"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Mobiler Zugriff"
              description="Zugriff auf Ihre Kamera über ihre DDNS-Adresse Bitte lesen Sie zuerst Schritt I zur Konfiguration Ihrer Kamera und Schritt II für die notwendige Router-Konfiguration. Im Folgenden werden wir den Webbrowser verwenden..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Fernzugriff/Mobil_Zugriff/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Fernzugriff/DDNS_Anbieter/">
                  <StaticImage
                    src='../../images/Search/IA_SearchThumb_ThirdPartyDDNS.webp'
                    alt="DDNS Anbieter"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="DDNS Anbieter"
              description="Verwenden Sie einen Drittanbieter-DDNS-Anbieter mit Ihrer INSTAR IP-Kamera Was hat es mit diesem DDNS auf sich? Warum verstecken wir die realen Adressen unserer Webseiten hinter Domain-Namen? Erstens sind die IP-Adressen ein bisschen anders..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Fernzugriff/DDNS_Anbieter/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
