import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class MQTTTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "MQTT Settings" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'MQTT Topic Präfix', descriptionColumn: 'Geben Sie ein Präfix an, dass jedem Kamera Topic vorangestellt wird. Dies dient der Gruppierung von MQTT Geräten in Ihrem Netzwerk. Verwenden Sie zum Beispiel "cameras" um alle Kameras in einer Gruppe unterzubringen. Oder auch "garden/cams", um weitere Untergruppen zu bilden.' },
        { actionColumn: 'MQTT Client ID', descriptionColumn: 'Die ID folgt im Topic nach dem Präfix. Wenn Sie oben zum Beispiel "garden/cams" und als Klienten ID hier 115 (die letzte Zahl der IP der Kamera) gewählt haben, dann werden alle MQTT Topics der Kamera mit "garden/cams/115" beginnen.' },
        { actionColumn: 'MQTT LWT', descriptionColumn: 'Das Last-Will-and-Testament (der "letzte Wille") der Kamera. Unter diesem Topic hinterlegt die Kamera sowohl einen "Ich bin online" als auch einen "Ich habe keine Verbindung zum MQTT Broker" Wert. Wenn der heartbeat ("Herzschlag") der Kamera für 60s ausbleibt, ändert der Broker das Topic automatisch auf den letzteren Wert ab.' },
        { actionColumn: 'MQTT LWT Online', descriptionColumn: 'Wert des LWTs wenn die Kamera eine Verbindung zum Broker hat.' },
        { actionColumn: 'MQTT LWT Offline', descriptionColumn: 'Wert des LWTs wenn die Kamera keine Verbindung zum Broker hat.' }
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

MQTTTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(MQTTTable)