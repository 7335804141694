import React from 'react'
import {Link} from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-gtag'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="IP Configuration">
        <EuiText>
          <p>
            Ändern Sie IP-Adresse, Gateway und DNS-Server Ihrer Kamera - all diese Einstellungen werden normalerweise mit dem <OutboundLink href="https://install.instar.com/" target="_blank" rel="noopener noreferrer">Installation Wizard</OutboundLink> vorgenommen und müssen nicht geändert werden. Der HTTP-Port der Kamera muss vielleicht geändert werden, um aus dem Internet auf die Weboberfläche zugreifen zu können. Der <Link to="/Fernzugriff/Der_DDNS_Dienst/">RTSP Port</Link> ist der <Link to="/Software/Mobile_Apps_HTTPS_and_RTSP/">RTSP Streaming Port</Link> der Kamera.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

