import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class ImageTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Bildeinstellungen" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Voreinstellungen', descriptionColumn: 'Die ganzen Bildeinstellungen können etwas überwältigen. Die Voreinstellungen bieten Ihnen einen Startpunkt für übliche Lichtverhältnisse. Wählen Sie ein Preset aus und klicken Sie auf übernehmen. Führen Sie im Anschluß noch noch ein paar Feineinstellungen durch. Sollte das Bild der Kamera einmal vollständig "verstellt" sein, können Sie es hier wieder auf die Standardwerte zurückbringen.' },
        { actionColumn: 'Bildeinstellungen aktivieren', descriptionColumn: 'Ihre Kamera verwendet unterschiedliche Bildeinstellungen für Tag und Nacht. Standardmäßig ist dies auf automatisch eingestellt und ermöglicht Ihrer Kamera, die richtigen Einstellungen zu laden, je nachdem, ob sie im Tag- oder Nachtmodus läuft. Sie können Ihre Kamera zwingen, eine der beiden Einstellungen zu laden, indem Sie hier Tag- oder Nachteinstellungen auswählen.' },
        { actionColumn: 'Tag/Nacht', descriptionColumn: 'Klicken Sie hier, um zwischen den Einstellungen für Tag und Nacht umzuschalten. Beachten Sie, dass Ihre Kamera NICHT in den entsprechenden Modus umschaltet, wenn Sie auf diese Schaltfläche klicken. Nur Änderungen, die an den derzeit aktiven Einstellungen vorgenommen werden, sind im Live-Video Ihrer Kamera sichtbar. Verwenden Sie das Dropdown-Menü oben, um die Einstellungen für den derzeit nicht aktiven Modus zu laden, damit Sie diese Einstellungen anpassen können.' },
        { actionColumn: 'Farbton', descriptionColumn: 'Verwenden Sie den Farbtonregler (Hue), um den Farbton für Ihr Videobild zu ändern. Mit dem Farbton-Parameter können Sie Farbanpassungen vornehmen, wenn der automatische Weißabgleich nicht Ihren Vorstellungen entspricht.' },
        { actionColumn: 'Bildsättigung', descriptionColumn: 'Stellen Sie die Sättigung des Videobilds ein. Um kräftigere Farben im Video zu erhalten, können Sie hier einen Wert größer 50 einstellen. Ein Wert unter 50 entsättigt das Bild. Diese Variable steht nur im Tag-Modus der Kamera zur Verfügung.' },
        { actionColumn: 'Kontrast', descriptionColumn: 'Stellen Sie den Kontrast des Videobilds ein. Ein hoher Kontrast läßt das Bild schärfer erscheinen. Während ein zu geringer Wert dem Bild eher einen "verwaschenen" Eindruck gibt. Ein zu hoher Wert kann jedoch dazu führen, daß Sie Details in den sehr hellen oder sehr dunklen Bereichen des Bildes verlieren. Bei einem Anheben des Kontrastes hilft es häufig auch die Helligkeit ein wenig anzuheben (und umgekehrt), um ein natürliches Bild zu erhalten.' },
        { actionColumn: 'Bildschärfe', descriptionColumn: 'Stellen Sie die Schärfe des Videobilds ein. Dieser Wert verstellt nicht das Objektiv, um einen besseren Fokus zu erhalten, sondern zeichnet kontrastreiche Kanten im Video nach, um eine höhere Schärfung des Bildes zu erhalten. Sie können den Wert auf ca. 70 belassen. Beachten Sie dabei aber, dass die Schärfung bei detailreichen Szenen, z.B. Grünanlagen, zu unschönen digitalen Artefakten im Bild führen kann. In diesem Fall sollte man den Wert reduzieren.' },
        { actionColumn: 'Helligkeit', descriptionColumn: 'Stellen Sie die Helligkeit des Videobilds ein. Um ein helleres Bild zu erhalten, können Sie hier den Wert 75 eintragen. Wie oben beschrieben, sollte man den Kontrast in die gleiche Richtung verstellen verstellen, um ein natürliches Bild zu erhalten.' },
        { actionColumn: 'Lebendigkeit', descriptionColumn: 'Lebendigkeit oder Vibrance bezeichnet die Lebhaftigkeit der Farbdarstellung. Im Gegensatz zur Bildsättigung werden hiermit nur Farbtöne angehoben, die nicht bereits stark gesättigt sind. Vibrance ermöglicht ihnen somit gezielt Bereiche mit schwacher Farbsättigung farbenfroher zu machen.' },
        { actionColumn: 'Vertikal spiegeln', descriptionColumn: 'Spiegeln Sie hier das Bild um seine vertikale Schwerpunktsachse.' },
        { actionColumn: 'Horizontal spiegeln', descriptionColumn: 'Spiegeln Sie hier das Bild um seine horizontale Schwerpunktsachse.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

ImageTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(ImageTable)