import React from 'react'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import ftpWebUI01 from "../../../../images/Motion_Detection/Router_as_a_FTP_Server/AVM_Fritzbox_as_FTP_Server/FTP-Fritzbox-7490de_06.webp"
import ftpWebUI02 from "../../../../images/Motion_Detection/Router_as_a_FTP_Server/AVM_Fritzbox_as_FTP_Server/FTP-Fritzbox-7490de_07.webp"
import ftpWebUI03 from "../../../../images/Motion_Detection/Router_as_a_FTP_Server/AVM_Fritzbox_as_FTP_Server/FTP-Fritzbox-7490de_08.webp"
import ftpWebUI04 from "../../../../images/Motion_Detection/Router_as_a_FTP_Server/AVM_Fritzbox_as_FTP_Server/FTP-Fritzbox-7490de_09.webp"



export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={ftpWebUI01}
                    alt="Web User Interface"
                  />
              }
              title='WebUI'
              description={
                <p>Öffnen Sie die Weboberfläche Ihrer Kamera und geben Sie Ihre zuvor eingestellten FTP Server Informationen ein.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={ftpWebUI02}
                    alt="FTP Settings"
                  />
              }
              title='FTP Einstellungen'
              description={
                <p>Tippen Sie, wie oben beschrieben, Ihre FTP Login-Daten und Server Informationen ein. Bestätigen Sie, bevor Sie den Upload testen.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={ftpWebUI03}
                    alt="Testing"
                  />
              }
              title='Testing'
              description={
                <p>Sie finden einen Snapshot in Ihrem Upload-Verzeichnis, nachdem Sie Ihre FTP Verbindung erfolgreich getestet haben.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={ftpWebUI04}
                    alt="Alarm Uploads"
                  />
              }
              title='Alarm Uploads'
              description={
                <p>Sie können jetzt die FTP Upload Funktion bei Alarm Events aktivieren.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
