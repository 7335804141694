import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Aussenkameras/IN-9420_WQHD/Handbuch/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-9420WQHD.webp'
                    alt="IN-9420 2k+ (WQHD)"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-9420 2k+ (WQHD)"
              description="Die IN-9420 2k+ ist die erste INSTAR Outdoor IP-Überwachungs-Kamera, die unseren neuen Sony 1440p / WQHD Bildsensor und einen Panasonic PIR-Sensor nutzt."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Aussenkameras/IN-9420_WQHD/Handbuch/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Aussenkameras/IN-9408_WQHD/Handbuch/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-9408WQHD.webp'
                    alt="IN-9408 2k+ (WQHD)"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-9408 2k+ (WQHD)"
              description="Die IN-9408 2k+ ist die erste INSTAR Outdoor IP-Überwachungs-Kamera, die unseren neuen Sony 1440p / WQHD Bildsensor und einen Panasonic PIR-Sensor nutzt."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Aussenkameras/IN-9408_WQHD/Handbuch/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Aussenkameras/IN-9020_HD/Handbuch/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-9020HD.webp'
                    alt="IN-9020 Full HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-9020 Full HD"
              description="PTZ IP Kamera mit PIR-Sensor und Autofokus. Flexible Smarthome Kompatibilität via RTSP, CGI und MQTT. 1080p Full HD & hochwertige Nachtsicht."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Aussenkameras/IN-9020_HD/Handbuch/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Aussenkameras/IN-9010_HD/Handbuch/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-9010HD.webp'
                    alt="IN-9010 Full HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-9010 Full HD"
              description="350° Pan, 100° Tilt und 4-fach optischer Zoom garantieren einen umfassenden Rundumschutz. Moderne INSTAR WebUI, MQTT Standardkonformität und vieles mehr verfügbar."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Aussenkameras/IN-9010_HD/Handbuch/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Aussenkameras/IN-9008_HD/Handbuch/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-9008HD.webp'
                    alt="IN-9008 Full HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-9008 Full HD"
              description="1080p Full HD WLAN IP Kamera für den Außenbereich. Bullet Kamera mit Weitwinkelobjektiv. Ausgestattet mit PIR-Sensor und SD-Karte. Kompatibel mit IFTTT und Alexa."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Aussenkameras/IN-9008_HD/Handbuch/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Aussenkameras/IN-7011_HD/Handbuch/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-7011HD.webp'
                    alt="IN-7011 HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-7011 HD"
              description="Die IN-7011 HD ist eine wetterfeste Dome-Kamera, die im Innen- und Außenbereich eingesetzt werden kann. Ein Motor ermöglicht die Änderung der Blickrichtung des Objektivs."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Aussenkameras/IN-7011_HD/Handbuch/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Aussenkameras/IN-5907_HD/Handbuch/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-5907HD.webp'
                    alt="IN-5907 HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-5907 HD"
              description="Die IN-5907 HD ist eine wetterfeste und drahtlose Außenkamera. Dank der eingebauten Hochleistungs-Infrarot-Dioden können Sie bis tief in die Nacht hinein sehen."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Aussenkameras/IN-5907_HD/Handbuch/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Aussenkameras/IN-5905_HD/Handbuch/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-5905HD.webp'
                    alt="IN-5905 HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-5905 HD"
              description="Sie suchen eine kleine Außenkamera? Die brandneue IN-5905HD, im Grunde eine deutlich kleinere Version unseres Flaggschiffs IN-5907HD, ist genau das, wonach Sie suchen!"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Aussenkameras/IN-5905_HD/Handbuch/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Aussenkameras/IN-4011/Handbuch/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-4011.webp'
                    alt="IN-4011"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-4011"
              description="Die IN-4011 ist das Nachfolgemodell der Dome-Kamera IN-4010v2 und bietet dank der neuen leistungsstarken PTZ-Steuerplatine erweiterte Schwenk- und Neigefunktionen."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Aussenkameras/IN-4011/Handbuch/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Aussenkameras/IN-4010_V2/Handbuch/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-4010.webp'
                    alt="IN-4010"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-4010"
              description="Die IN-4010 v2 ist das Nachfolgemodell der IN-4010 Dome-Kamera und bietet dank der neuen leistungsstarken PTZ-Steuerplatine erweiterte Schwenk- und Neigefunktionen."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Aussenkameras/IN-4010_V2/Handbuch/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Aussenkameras/IN-2908/Handbuch/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-2908.webp'
                    alt="IN-2908"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-2908"
              description="Der große Bruder der IN-2905v2 - eine stärkere Infrarot-Nachtsicht und ein dreifacher optischer Zoom machen dieses Kameramodell zu einem klaren Sieger."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Aussenkameras/IN-2908/Handbuch/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Aussenkameras/IN-2905_V2/Handbuch/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-2905.webp'
                    alt="IN-2905"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-2905"
              description="Die IN-2905 v2 ist der Nachfolger der beliebten IN-2905. Dutzende von Upgrades machen diese Kamera auf Augenhöhe mit der High-End-Linie der INSTAR-Kameras."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Aussenkameras/IN-2905_V2/Handbuch/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
