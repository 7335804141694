import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class InfoTable01 extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Kamera Übersicht" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Kamera ID', descriptionColumn: 'Modellbezeichnung Ihrer Kamera.' },
        { actionColumn: 'Firmware Version', descriptionColumn: 'Versionsnummer des Betriebssystems Ihrer Kamera. Sie finden die neueste Firmware/Weboberfläche für Ihre Kamera in unserem Download Bereich. Das Aufspielen der neuen Software können Sie über das Update Menü durchführen.' },
        { actionColumn: 'WebUI Version', descriptionColumn: 'Versionsnummer der Benutzeroberfläche Ihrer Kamera. Sie finden die neueste Firmware/Weboberfläche für Ihre Kamera in unserem Download Bereich. Das Aufspielen der neuen Software können Sie über das Update Menü durchführen.' },
        { actionColumn: 'Nachtsicht', descriptionColumn: 'Betriebsmodus Ihrer Nachtsicht-LEDs - entweder automatisch eingeschaltet (wenn es dunkel wird) oder dauerhaft deaktiviert.' },
        { actionColumn: 'SD Status', descriptionColumn: 'Anzeige der Betriebsbereitschaft Ihrer SD Karte und des verbleibenden Speicherplatzes.' },
        { actionColumn: 'SD Kapazität', descriptionColumn: 'Volle Kapazität der SD-Karte Ihrer Kamera.' },
        { actionColumn: 'Freier SD Speicherplatz', descriptionColumn: 'Die verbleibende Kapazität (die SD-Karte wird als Ringpuffer verwendet - der älteste Ordner wird gelöscht, sobald die Kapazität erreicht ist).' },
        { actionColumn: 'MAC Adresse', descriptionColumn: 'Hardware-Identifikation der LAN-Netzwerkschnittstelle' },
        { actionColumn: 'WLAN Module', descriptionColumn: 'Hardware-Identifikation der WLAN-Netzwerkschnittstelle' },
        { actionColumn: 'Kamera aktiv seit', descriptionColumn: 'Betriebszeit Ihrer Kamera seit dem letzten Neustart' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

InfoTable01.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(InfoTable01)