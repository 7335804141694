import React from 'react'

import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Smarthome Integration:">
        <EuiText>
          <ul>
              <li><Link to="/Software/Windows/Blue_Iris_v5/INSTAR_MQTT/">BlueIris und INSTAR MQTT</Link></li>
              <li><Link to="/Software/Windows/Blue_Iris_v5/Node-RED/">BlueIris und Node-RED</Link></li>
              <li><Link to="/Software/Windows/Blue_Iris_v5/ioBroker/">BlueIris und ioBroker</Link></li>
              <li><Link to="/Software/Windows/Blue_Iris_v5/Home_Assistant/">BlueIris und Home Assistant</Link></li>
              <li><Link to="/Frequently_Asked_Question/BlueIris_v5_preset_positions/">BlueIris Preset Positionen</Link></li>
              <li><Link to="/Frequently_Asked_Question/BlueIris_v5_http_alarmserver/">BlueIris als HTTP Alarmserver</Link></li>
          </ul>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

