import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Sehr Wichtig!">
        <EuiText>
          <p>Für die VGA Kameras! Bei der Auswahl der Firmware ist es wichtig, dass Sie die richtige Version auswählen. Bitte überprüfen Sie in Ihrer Kamera welche Firmware derzeit installiert ist. Wenn die Version derzeit mit xx.25 oder xx.22 beginnt dann stellen Sie sicher das die neue Version welche Sie runterladen auch mit xx.25 oder mit xx.22 beginnen. Niemals eine Version mit xx.35. oder xx.37. aufspielen!!!</p>
          <p>Sollte Ihre Firmware mit xx.35 oder xx.37 beginnen so stellen Sie sicher, dass Sie "nur" eine Firmware herunterladen welche mit xx.35 oder xx.37 beginnt.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

