import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class ActionTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Alarm Aktionen" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Alarm-Email senden', descriptionColumn: 'Alle Alarm-Aktionen können über die Bereiche der Bewegungserkennung , den Alarmeigang, als auch den Audioalarm ausgelöst werden. Hier können Sie die Alarmbenachrichtigung per Email aktivieren. Bitte beachten Sie, dass Sie zuerst den ausgehenden Email Server SMTP und Email Empfänger eingeben müssen.' },
        { actionColumn: 'Alarm Video auf SD-Karte senden', descriptionColumn: 'Aktivieren, um im Alarmfall ein Video auf die interne SD Karte zu senden. Eine Alarm-Video Datei startet immer mit einem __A__ und danach mit einem Zeitstempel. Ihre Kamera nimmt immer 3 Sekunden vor dem Alarm und 15 Sekunden nach dem Alarm auf.' },
        { actionColumn: 'Alarm Video auf einen FTP-Server senden', descriptionColumn: 'Aktivieren, um im Alarmfall ein Video auf Ihren FTP Server zu senden. Bitte beachten Sie, dass Sie zuerst Ihre FTP Server Information eingeben müssen.' },
        { actionColumn: 'Aufzeichnung in der INSTAR-Cloud speichern', descriptionColumn: 'Aktivieren Sie diese Funktion, um Alarmaufzeichnungen an den INSTAR Cloud-Dienst zu senden.' },
        { actionColumn: 'Alarm Video auf einen FTP-Server senden', descriptionColumn: 'Aktivieren, um im Alarmfall ein Video auf Ihren FTP Server zu senden. Bitte beachten Sie, dass Sie zuerst Ihre FTP Server Information eingeben müssen.' },
        { actionColumn: 'Videoauflösung', descriptionColumn: 'Stellen Sie die Auflösung für die Alarmvideos Ihrer Kamera ein.' },
        { actionColumn: 'Länge der Alarmvideos', descriptionColumn: 'Legen Sie die Länge Ihrer Alarmaufzeichnung fest (Ihre Kamera fügt eine 3s-Voraufzeichnung hinzu)' },
        { actionColumn: 'Alarm Snapshot auf SD-Karte senden', descriptionColumn: 'Aktivieren, um im Alarmfall einen Snapshot auf die interne SD Karte zu senden.' },
        { actionColumn: 'Alarm Snapshot auf FTP Server senden', descriptionColumn: 'AAktivieren, um im Alarmfall Snapshots auf Ihren FTP Server zu senden. Bitte beachten Sie, dass Sie zuerst Ihre FTP Server Informationen im FTP Menü eingeben müssen.' },
        { actionColumn: 'Snapshot Auflösung', descriptionColumn: 'Stellen Sie die Auflösung für die Snapshots ein, die im Alarmfall per FTP hochgeladen werden, per Email gesendet werden oder auf der SD-Karte gespeichert werden.' },
        { actionColumn: 'Fotos in Email (email)', descriptionColumn: 'Stellen Sie ein, wie viele Snapshots in einer Alarm Email angehängt werden sollen. Bitte beachten Sie, dass die Email etwas verzögert eintrifft, wenn zu viele Bilder angehängt werden. Wenn Sie ohne eine Verzögerung benachrichtigt werden wollen, nutzen Sie bitte den Push Dienst.' },
        { actionColumn: 'Fotos auf SD-Karte', descriptionColumn: 'Stellen Sie ein, wie viele Snapshots Sie im Alarmfall auf Ihrer SD Karte speichern wollen.' },
        { actionColumn: 'Fotos per FTP Upload', descriptionColumn: 'Stellen Sie ein, wie viele Snapshots Sie im Alarmfall von Ihrer Kamera auf Ihren FTP Server senden wollen.' },
        { actionColumn: 'Play Alarm Signal', descriptionColumn: 'Stellen Sie hier ein ob die Kamera bei einem Alarm einen Alarmton ausgeben soll. Bitte verwenden Sie diese Funktion nicht in Kombination mit dem Audio Alarm (unten) :).' },
        { actionColumn: 'Audio Alarm aktivieren', descriptionColumn: 'Lassen Sie Ihre Kamera Geräusche hören. Dies ist die ideale Funktion, um Ihre Kamera als Babyphone zu nutzen.' },
        { actionColumn: 'Sensibilität', descriptionColumn: 'Einstellen, wie sensibel die Audio Erkennung reagieren soll.' },
        { actionColumn: 'Alarm Ausgang aktivieren', descriptionColumn: 'Aktivieren Sie den externen Alarm Ausgang Ihrer Kamera (nicht bei allen Kameramodellen verfügbar) und lassen Sie Ihre Kamera im Alarmfall das Relais schließen.' },
        { actionColumn: 'Schließzeit des Relais in Sekunden', descriptionColumn: 'Einstellen, wie viele das Relais am Alarmausgang im Alarmfall geschlossen bleiben soll.' },
        { actionColumn: 'Externen Alarm Eingang aktivieren', descriptionColumn: 'ktivieren Sie den Alarm Eingang Ihrer Kamera (nicht bei allen Kameramodellen verfügbar). Informationen über das Verbinden mit einem externen Sensor können Sie hier finden. Sie können jeden Sensor mit Ihrer Kamera verbinden, welcher mit einem potentialfreien Kontakt arbeitet.' },
        { actionColumn: 'Externer Sensor während Alarm / Alarm Gerät eingestellt auf', descriptionColumn: 'Stellen Sie ein, ob Ihr externer Sensor im Alarmfall schließt oder öffnet (siehe auch: IN-MOTION 500. Bitte beachten Sie, dass Ihre Kamera sich in einem konstanten Alarmzustand befindet, wenn Sie den Alarmeingang auf N.C. stellen und dabei keinen externen Sensor angeschlossen haben.' },
        { actionColumn: 'Alarmeingang und Alarmbereiche koppeln', descriptionColumn: 'Es wird empfohlen die interne Bewegungserkennung mit dem externen Sensor zu koppeln. Bitte beachten Sie, dass Sie mindestens einen Alarmbereich aktiviert haben müssen, um die Kopplungsfunktion nutzen zu können.' },
        { actionColumn: 'Internen PIR Sensor aktivieren', descriptionColumn: 'Aktivieren Sie den internen passiven Infrarot Sensor (PIR Bewegungserkennung) um einen Alarm auszulösen, wenn eine Person den Überwachungsbereich betritt. Sie können die Alarmbereiche deaktivieren, wenn der PIR Sensor aktiv ist. In den meisten Fällen wird jedoch empfohlen, die interne Bewegungserkennung mit dem PIR Sensor zu koppeln.' },
        { actionColumn: 'PIR und Alarmbereiche koppeln', descriptionColumn: 'Sie können auch die Alarmbereiche mit dem internen PIR Sensor koppeln. Nutzen Sie diese Funktion um effizient gegen Falschalarme vorzugehen. Bitte denken Sie daran, dass Sie mindestens einen Alarmbereich aktiviert haben müssen, um diese Funktion nutzen zu können! Beachten Sie auch, dass Sie in unserer Smartphone App danach immer das Augen-Symbol und das Einbrecher-Symbol schalten müssen, um den Alarm zu deaktivieren oder aktivieren.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

ActionTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(ActionTable)