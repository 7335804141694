import React from 'react'
import { Link } from 'gatsby'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

// import productsProductsSearchIcon from '../../../../static/images/Search/P_SearchThumb_Products.webp'
import usbWebCamSearchIcon from '../../../../static/images/Search/P_SearchThumb_IN-Wx.webp'
import productsIRSearchIcon from '../../../../static/images/Search/P_SearchThumb_InfraredSpotlights.webp'
import productsMotionSearchIcon from '../../../../static/images/Search/P_SearchThumb_MotionDetector.webp'
import productsRouterSearchIcon from '../../../../static/images/Search/P_SearchThumb_MobileRouter.webp'
import productsMicSearchIcon from '../../../../static/images/Search/P_SearchThumb_Microphones.webp'
import productsPowerlineSearchIcon from '../../../../static/images/Search/P_SearchThumb_Powerline.webp'
import productsPoESearchIcon from '../../../../static/images/Search/P_SearchThumb_PowerOverEthernet.webp'


export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Produkte/IR_Beleuchtung/">
                  <img
                    src={productsIRSearchIcon}
                    alt="Infrared Floodlights"
                  />
                </Link>
              }
              title="IR Beleuchtung"
              description="Alle INSTAR Nachtsichtkameras verwenden Infrarot-LEDs, die ein Emissionsmaximum um 850nm haben. Photonen mit einer Wellenlänge höher als ~750nm sind für das menschliche Auge unsichtbar..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Produkte/IR_Beleuchtung/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Produkte/USB-Webcams/">
                  <img
                    src={usbWebCamSearchIcon}
                    alt="IN-Wx Full-HD 1080P Webcam"
                  />
                </Link>
              }
              title="IN-Wx Full-HD"
              description="Die IN-Wx ist eine USB-Webcam mit einer 1080p Full HD-Auflösung. Sie kann an Ihren PC oder Laptop angeschlossen werden und mit Chat- und Team-Programmen wie Skype, Zoom, Facetime und anderen verwendet werden."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Produkte/USB-Webcams/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Produkte/Power_over_Ethernet/">
                  <img
                    src={productsPoESearchIcon}
                    alt="IN-PoE"
                  />
                </Link>
              }
              title="IN-PoE"
              description="Der IN-PoE 1000 kombiniert das Netzwerksignal und die Stromversorgung, so dass es über ein einziges Netzwerkkabel an ein PoE-kompatibles Netzwerkgerät übertragen werden kann."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Produkte/Power_over_Ethernet/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Produkte/IN-LAN/">
                  <img
                    src={productsPowerlineSearchIcon}
                    alt="IN-LAN"
                  />
                </Link>
              }
              title="IN-LAN"
              description="IN-LAN ist eine intelligente und sichere Technologie, mit der Sie ganz einfach ein Heimnetzwerk über Ihr Stromnetz einrichten können - ohne aufwändige und teure Spezialverkabelung."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Produkte/IN-LAN/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Produkte/IN-Motion/">
                  <img
                    src={productsMotionSearchIcon}
                    alt="IN-Motion"
                  />
                </Link>
              }
              title="IN-Motion"
              description="Der IN-Motion 500 verfügt über eine Mikrowelle und einen Passiv-Infrarot-Bewegungssensor. Er ist perfekt geeignet für die Außenerkennung."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Produkte/IN-Motion/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Produkte/IN-Mikro/">
                  <img
                    src={productsMicSearchIcon}
                    alt="IN-Mikro"
                  />
                </Link>
              }
              title="IN-Mikro"
              description="Das IN-MIKRO 380 ist ein hochwertiges aktives Mikrofon, das einfach an Ihre IP-Kamera angeschlossen werden kann. Alles, was Sie benötigen, ist ein IP-Kameragerät, das über einen integrierten Audioeingang verfügt."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Produkte/IN-Mikro/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Produkte/IN-Route/">
                  <img
                    src={productsRouterSearchIcon}
                    alt="IN-Route"
                  />
                </Link>
              }
              title="IN-Route"
              description="Die INSTAR IN-Route-Linie bietet eine Plug-and-Play-Mobilfunklösung für den Informationsaustausch zwischen mobilen Geräten."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Produkte/IN-Route/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
