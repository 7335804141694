import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class IRTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "IR LEDs" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Infrarot LED Steuerung', descriptionColumn: 'Hier können Sie das Verhalten der Infrarot LEDs der Kamera einstellen. Sie können zwischen 2 Modi wählen, zum einen die LEDs dauerhaft ausschalten und zum anderen die LEDs durch den Lichtsensor automatisch einschalten zu lassen.' },
        { actionColumn: 'Infrarot Cut Filter', descriptionColumn: 'Der IRCut Filter filtert das Infrarotlicht der Sonne bei Tage und wird automatisch entfernt wenn die Kamera in den Nachtmodus schaltet - dies ermöglicht die Nutzung der IR LEDs zur Ausleuchtung des Überwachungsbereiches. Sie können diese Automatik hier deaktivieren, indem Sie die Kamera permanent in den Nacht- oder Tagmodus schalten.' },
        { actionColumn: 'Zeitplan für IR LEDs', descriptionColumn: 'Falls Sie mit dem automatischen Modus nicht zufrieden sind - z.B. der Nachtmodus schaltet sich zu früh oder zu spät ein - können Sie hier einen fixen Zeitplan eintragen.' },
        { actionColumn: 'Aktivieren Sie die IR-LEDs um', descriptionColumn: 'Hier können Sie die Zeit einstellen, wenn die IR-LEDs in den Automatikmodus geschaltet werden. Denken Sie daran, dass dies nur die IR-LEDs aktiviert, wenn es dunkel genug ist. Der Fotosensor der Kamera verhindert die Aktivierung des Nachtsichtmodus bei Tageslicht.' },
        { actionColumn: 'Deaktivieren Sie die IR-LEDs um', descriptionColumn: 'Die hier eingestellte Zeit ist die Zeit, in der die IR-LEDs deaktiviert werden.' },
        { actionColumn: 'Aktueller Helligkeitswert', descriptionColumn: 'Nicht verfügbar in den Modellen IN-9020 und IN-9010 Der aktuelle Helligkeitswert, der vom Helligkeitssensor der Kamera gemessen wird.' },
        { actionColumn: 'Grenzwerte', descriptionColumn: 'Nicht verfügbar in den Modellen IN-9020 und IN-9010 Die obere und untere Schwelle, die von der Automatisierung verwendet wird, um Ihre Kamera vom Tag- in den Nachtmodus und umgekehrt zu schalten.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

IRTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(IRTable)