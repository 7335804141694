import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class WebUITable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Account Einstellungen" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Benutzername', descriptionColumn: 'Benutzername für Ihr aktives Konto.' },
        { actionColumn: 'Kennwort', descriptionColumn: 'Passwort für Ihr aktives Konto.' },
        { actionColumn: 'Kennwort bestätigen', descriptionColumn: 'Wiederholen Sie das Passwort für Ihr aktives Konto.' },
        { actionColumn: 'Ablauf des Logins', descriptionColumn: 'Wenn Sie sich bei Ihrem Konto anmelden, wird ein Login-Web-Token generiert. Solange Sie Ihren Browserverlauf nicht löschen, bleibt dieses Token bis zum hier eingestellten Ablaufdatum gültig.' },
        { actionColumn: 'Sprache', descriptionColumn: 'Ihre bevorzugte Sprache für die Benutzeroberfläche.' },
        { actionColumn: 'Email Adresse', descriptionColumn: 'E-Mail-Adresse, die Sie als zweiten Faktor für die Authentifizierung verwenden möchten. Wenn Sie versuchen, sich über die Web-Benutzeroberfläche zu authentifizieren, wird eine E-Mail an diese Adresse gesendet, um zu überprüfen, ob Sie es sind, der versucht, Zugang zu erhalten. Diese E-Mail-Adresse wird auch für die Funktion "Kennwort vergessen" verwendet und ermöglicht es Ihnen, den Zugang zu Ihrer Kamera wiederherzustellen.' },
        { actionColumn: 'Zwei-Faktor-Authentifizierung (2FA)', descriptionColumn: 'Aktivieren Sie die Zwei-Faktor-Authentifizierung.' },
        { actionColumn: 'Die Sicherheitsfrage wurde gesetzt', descriptionColumn: 'Hier können Sie eine Frage und die geheime Antwort festlegen. Ihre Kamera benötigt diese Antwort, damit Sie Ihr Passwort wiederherstellen können.' }
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

WebUITable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(WebUITable)