import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Table,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-material-ui";

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word",
  },
};

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
);

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0,
    }}
    {...restProps}
  />
);

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class PhotoScheduleTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Snapshot Zeitplan" },
        { name: "descriptionColumn", title: "Beschreibung" },
      ],
      rows: [
        {
          actionColumn: "SD Karte",
          descriptionColumn:
            "Definieren Sie Zeitpläne für die Aufnahme von Schnappschüssen an bestimmten Wochentagen und zu bestimmten Tageszeiten auf der lokalen SD-Karte Ihrer Kamera.",
        },
        {
          actionColumn: "EMail",
          descriptionColumn:
            "Definieren Sie Zeitpläne für die Snapshot-Aufnahme für Wochentage und Tageszeiten per E-Mail. Vergewissern Sie sich, dass Sie einen SMTP-Server und einen E-Mail-Empfänger unter Features / Email definiert haben.",
        },
        {
          actionColumn: "FTP",
          descriptionColumn:
            "Definieren Sie Zeitpläne für die Aufnahme von Schnappschüssen an bestimmten Wochentagen und zu bestimmten Tageszeiten auf Ihrem FTP-Server. Vergewissern Sie sich, dass Sie den FTP-Server, den Sie verwenden möchten, unter Features / FTP definiert haben.",
        },
        {
          actionColumn: "Fester Dateiname",
          descriptionColumn:
            "Nur beim FTP-Upload können Sie einen festen Namen für die hochgeladene Bilddatei festlegen. Verwenden Sie diese Option z.B., wenn Sie das Bild in Ihre Website einbetten möchten und nicht wollen, dass sich der fest kodierte Link zu dieser Datei ändert.",
        },
        {
          actionColumn:
            "Speichern von Snapshots auf  SD Karte / per Email / an FTP",
          descriptionColumn:
            "Um einen Zeitplan hinzuzufügen, klicken Sie auf das grüne Plus-Symbol, kreuzen Sie die Tage von Mo - So an, an denen der Schnappschuss aufgenommen werden soll, und legen Sie eine Zeit zwischen 0:00 und 23:59 Uhr fest.",
        },
      ],
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{ display: "inline-block", maxWidth: "915px" }}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

PhotoScheduleTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PhotoScheduleTable);
