import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Alarm FTP Upload">
        <EuiText>
          <p>
            Um den Video <Link to="/Web_User_Interface/720p_Serie/Alarm/FTP/">FTP Upload</Link> zu nutzen, müssen Sie zuerst Ihre FTP Server Adresse und Ihre Logindaten eingeben. Sie können zum Beispiel den <Link to="/Bewegungserkennung/FTP_Server_Setup/">FTP-Dienst Ihres Routers</Link>) als FTP Server nutzen - in diesem Fall müssen Sie die IP Adresse Ihres Routers als FTP Server Adresse eingeben. Wenn Sie einen FTP Server auf Ihrem lokalen PC aufsetzen (zum Beispiel mit <Link to="/Bewegungserkennung/FTP_Server_Setup/">Filezilla Server</Link>), benutzen Sie die lokale IP-Adresse des PC's, außerdem können Sie natürlich auch die URL des Webservers nutzen. Der Standard FTP-Port ist 21.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

