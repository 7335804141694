import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class ActionTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Cloud Einstellung" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Erstellen einer neuen Kamera', descriptionColumn: 'Loggen Sie sich in Ihren Cloud-Account ein und klicken Sie auf das orangene Feld im Reiter "Kameras" um eine neue INSTAR Kamera hinzuzufügen.' },
        { actionColumn: 'Kameraprofil einrichten', descriptionColumn: 'Geben Sie der Kamera in der Cloud einen Namen (bsp. Gartenkamera) und wählen Sie das Kameramodell sowie die gewünschte Fehlalarmerkennung aus.' },
        { actionColumn: 'Profildaten', descriptionColumn: 'Nachdem Sie auf bestätigen geklickt haben wurde ein neues Kameraprofil für die "Gartenkamera" erstellt. Sie finden hier eine einzigartige Cloud-Kamera-ID sowie das dazugehörige Cloud-Kamera-Kennwort.' },
        { actionColumn: 'Einbindung', descriptionColumn: 'Tragen Sie die Cloud-Kamera-ID und das dazugehörige Cloud-Kamera-Kennwort in das Cloudmenü in der Kamera-WebUI ein. Aktivieren Sie die Cloud und klicken Sie bei Bedarf auf den Test-Button. Die Kamera ist nun für die Cloud eingerichtet.' },
        { actionColumn: 'Cloud-Menü (bevor Login)', descriptionColumn: 'Das Menü klappt aus. Sollte die Kamera vorher schon gültige Cloud-Kamera Daten eingetragen haben, die zu diesem Account gehören, dann wird dies mit dem entsprechenden Kameraprofil und Einstellung angezeigt.' },
        { actionColumn: 'Kameraprofil auswählen oder neue Cloud-Kamera erstellen', descriptionColumn: 'Falls es sich um eine neue Kamera oder einen neuen Cloud Account handelt, dann wählen Sie "Als neue Kamera hinzufügen" aus. Ansonsten wählen Sie eines der aufgelisteten Kameraprofile aus.' },
        { actionColumn: 'Cloud Upload aktivieren & Einstellungen speichern', descriptionColumn: 'Vergessen Sie nicht den Cloud Upload zu aktivieren und die Einstellungen zu übernehmen. Das war es schon! Die Kamera ist nun bereit bei einem Alarm die Aufnahmen an die Cloud zu senden!' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

ActionTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(ActionTable)