import React from 'react'
import {Link} from 'gatsby'

import {
  EuiCard,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText
} from '@elastic/eui'

import qiSelect01 from "../../../../../images/Outdoor_Cameras/IN-9008_HD/Quick_Installation/PoE_or_WiFi/Quickinstallation_Tile_9008_Step_09.webp"
import qiSelect02 from "../../../../../images/Outdoor_Cameras/IN-9008_HD/Quick_Installation/PoE_or_WiFi/QI-P2P_Tile_9008_Step_06.webp"




export default function QIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              title='II-d Option I - Ethernet'
              image={
                <Link to="/Aussenkameras/IN-9008_HD/Schnell_Installation/PoE_or_WiFi/Ethernet/">
                  <img
                    src={qiSelect01}
                    alt="Connect your camera´s Ethernet cable with the Ethernet Port to connect your camera to a free LAN port on your internet router. (Recommended)"
                  />
                </Link>
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">Schließen Sie das Ethernet-Kabel Ihrer Kamera an den Ethernet-Anschluss an, um Ihre Kamera an einen freien LAN-Anschluss Ihres Internet-Routers anzuschließen. (<strong>Empfohlen</strong>)</EuiText>
                  <EuiSpacer size="s" />
                  <EuiFlexGroup justifyContent="flexEnd">
                    <Link to="/Aussenkameras/IN-9008_HD/Schnell_Installation/PoE_or_WiFi/Ethernet/">
                      <EuiFlexItem grow={false}>
                        <EuiButton size="s" fill>
                          Nur WLAN
                        </EuiButton>
                        <EuiSpacer size="s" />
                      </EuiFlexItem>
                    </Link>
                  </EuiFlexGroup>
                </React.Fragment>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              title='II-e Option II - WPS'
              image={
                <Link to="/Aussenkameras/IN-9008_HD/Schnell_Installation/PoE_or_WiFi/WPS/">
                  <img
                    src={qiSelect02}
                    alt="2-hole Sealing Ring: to connect the Power Cable and LAN Cable"
                  />
                </Link>
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">Wenn Ihr Router eine WPS-Funktion bietet, aktivieren Sie den WPS-Modus und drücken Sie die WPS-Taste Ihrer Kamera, um Ihre Kamera ohne ein Ethernet-Kabel.</EuiText>
                  <EuiSpacer size="s" />
                  <EuiFlexGroup justifyContent="flexEnd">
                    <Link to="/Aussenkameras/IN-9008_HD/Schnell_Installation/PoE_or_WiFi/WPS/">
                      <EuiFlexItem grow={false}>
                        <EuiButton size="s" fill>
                          Ethernet
                        </EuiButton>
                        <EuiSpacer size="s" />
                      </EuiFlexItem>
                    </Link>
                  </EuiFlexGroup>
                </React.Fragment>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
