import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'Android / BlackBerry',
      href: '/de/Downloadbereich/Mobile_Apps/Android_BlackBerry/',
    },
    {
      text: 'iOS',
      href: '/de/Downloadbereich/Mobile_Apps/iOS/',
    },
    {
      text: 'Windows',
      href: '/de/Downloadbereich/Mobile_Apps/Windows_Phone/',
    },
    {
      text: 'Mobile Apps',
      href: '/de/Downloadbereich/Mobile_Apps/',
    },
    {
      text: '◄ Downloadbereich',
      href: '/de/Downloadbereich/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={11}
        aria-label="Downloadbereich for your INSTAR IP Camera"
      />
      <EuiSpacer />
    </Fragment>
  );
}