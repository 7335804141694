import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Aussenkameras/IN-9420_WQHD/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_IN-9420WQHD.webp'
                    alt="IN-9420 2k+ (WQHD)"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-9420 2k+ (WQHD)"
              description="Die IN-9420 2k+ ist die erste INSTAR Outdoor IP-Überwachungs-Kamera, die unseren neuen Sony 1440p / WQHD Bildsensor und einen Panasonic PIR-Sensor nutzt."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Aussenkameras/IN-9420_WQHD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Aussenkameras/IN-9408_WQHD/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_IN-9408WQHD.webp'
                    alt="IN-9408 2k+ (WQHD)"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-9408 2k+ (WQHD)"
              description="Die IN-9408 2k+ ist die erste INSTAR Outdoor IP-Überwachungs-Kamera, die unseren neuen Sony 1440p / WQHD Bildsensor und einen Panasonic PIR-Sensor nutzt."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Aussenkameras/IN-9408_WQHD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Aussenkameras/IN-9020_HD/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_IN-9020HD.webp'
                    alt="IN-9020 Full HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-9020 Full HD"
              description="Die IN-9020 HD ist die erste INSTAR Outdoor IP-Überwachungs-PTZ-Kamera, die unseren neuen Panasonic 1080p / fullHD Bildsensor und einen Panasonic PIR-Sensor nutzt."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Aussenkameras/IN-9020_HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Aussenkameras/IN-9010_HD/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_IN-9010HD.webp'
                    alt="IN-9010 Full HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-9010 Full HD"
              description="Die IN-9010 HD ist die erste INSTAR Outdoor IP-Überwachungs-PTZ-Kamera, die unseren neuen Panasonic 1080p / fullHD Bildsensor nutzt."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Aussenkameras/IN-9010_HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Aussenkameras/IN-9008_HD/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_IN-9008HD.webp'
                    alt="IN-9008 Full HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-9008 Full HD"
              description="Die IN-9008 HD ist die erste INSTAR Outdoor IP-Überwachungskamera, die unseren neuen Panasonic 1080p / fullHD Bildsensor nutzt."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Aussenkameras/IN-9008_HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Aussenkameras/IN-7011_HD/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_IN-7011HD.webp'
                    alt="IN-7011 HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-7011 HD"
              description="Die IN-7011 HD ist eine wetterfeste Dome-Kamera, die im Innen- und Außenbereich eingesetzt werden kann. Ein Motor ermöglicht die Änderung der Blickrichtung des Objektivs."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Aussenkameras/IN-7011_HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Aussenkameras/IN-5907_HD/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_IN-5907HD.webp'
                    alt="IN-5907 HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-5907 HD"
              description="Die IN-5907 HD ist eine wetterfeste und drahtlose Außenkamera. Dank der eingebauten Hochleistungs-Infrarot-Dioden können Sie bis tief in die Nacht hinein sehen."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Aussenkameras/IN-5907_HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Aussenkameras/IN-5905_HD/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_IN-5905HD.webp'
                    alt="IN-5905 HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-5905 HD"
              description="Sie suchen eine kleine Außenkamera? Die brandneue IN-5905HD, im Grunde eine deutlich kleinere Version unseres Flaggschiffs IN-5907HD, ist genau das, wonach Sie suchen!"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Aussenkameras/IN-5905_HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
