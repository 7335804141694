import React from 'react'

import ImageGallery from "react-image-gallery"

import galleryImage01 from "images/Web_User_Interface/1440p_Series/Multimedia/Video/Compare_Bitrate/Bitrate_Test_11_WDRMODE_0_Indoor.avif"
import galleryImage01Thumb from "images/Web_User_Interface/1440p_Series/Multimedia/Video/Compare_Bitrate/Bitrate_Test_11_WDRMODE_0_Indoor.avif"
import galleryImage02 from "images/Web_User_Interface/1440p_Series/Multimedia/Video/Compare_Bitrate/Bitrate_Test_11_WDRMODE_0_Indoor_320000.avif"
import galleryImage02Thumb from "images/Web_User_Interface/1440p_Series/Multimedia/Video/Compare_Bitrate/Bitrate_Test_11_WDRMODE_0_Indoor_320000.avif"
import galleryImage03 from "images/Web_User_Interface/1440p_Series/Multimedia/Video/Compare_Bitrate/Bitrate_Test_11_WDRMODE_0_Indoor_640000.avif"
import galleryImage03Thumb from "images/Web_User_Interface/1440p_Series/Multimedia/Video/Compare_Bitrate/Bitrate_Test_11_WDRMODE_0_Indoor_640000.avif"
import galleryImage04 from "images/Web_User_Interface/1440p_Series/Multimedia/Video/Compare_Bitrate/Bitrate_Test_11_WDRMODE_0_Indoor_1280000.avif"
import galleryImage04Thumb from "images/Web_User_Interface/1440p_Series/Multimedia/Video/Compare_Bitrate/Bitrate_Test_11_WDRMODE_0_Indoor_1280000.avif"
import galleryImage05 from "images/Web_User_Interface/1440p_Series/Multimedia/Video/Compare_Bitrate/Bitrate_Test_11_WDRMODE_0_Indoor_1600000.avif"
import galleryImage05Thumb from "images/Web_User_Interface/1440p_Series/Multimedia/Video/Compare_Bitrate/Bitrate_Test_11_WDRMODE_0_Indoor_1600000.avif"
import galleryImage06 from "images/Web_User_Interface/1440p_Series/Multimedia/Video/Compare_Bitrate/Bitrate_Test_11_WDRMODE_0_Indoor_1920000.avif"
import galleryImage06Thumb from "images/Web_User_Interface/1440p_Series/Multimedia/Video/Compare_Bitrate/Bitrate_Test_11_WDRMODE_0_Indoor_1920000.avif"
import galleryImage07 from "images/Web_User_Interface/1440p_Series/Multimedia/Video/Compare_Bitrate/Bitrate_Test_11_WDRMODE_0_Indoor_2240000.avif"
import galleryImage07Thumb from "images/Web_User_Interface/1440p_Series/Multimedia/Video/Compare_Bitrate/Bitrate_Test_11_WDRMODE_0_Indoor_2240000.avif"
import galleryImage08 from "images/Web_User_Interface/1440p_Series/Multimedia/Video/Compare_Bitrate/Bitrate_Test_11_WDRMODE_0_Indoor_2560000.avif"
import galleryImage08Thumb from "images/Web_User_Interface/1440p_Series/Multimedia/Video/Compare_Bitrate/Bitrate_Test_11_WDRMODE_0_Indoor_2560000.avif"
import galleryImage09 from "images/Web_User_Interface/1440p_Series/Multimedia/Video/Compare_Bitrate/Bitrate_Test_11_WDRMODE_0_Indoor_5120000.avif"
import galleryImage09Thumb from "images/Web_User_Interface/1440p_Series/Multimedia/Video/Compare_Bitrate/Bitrate_Test_11_WDRMODE_0_Indoor_5120000.avif"

require("react-image-gallery/styles/css/image-gallery.css")

export class NaturalLightOutdoor extends React.Component {
  render() {
    const images = [{ original: galleryImage01, thumbnail: galleryImage01Thumb }, { original: galleryImage02, thumbnail: galleryImage02Thumb }, { original: galleryImage03, thumbnail: galleryImage03Thumb }, { original: galleryImage04, thumbnail: galleryImage04Thumb }, { original: galleryImage05, thumbnail: galleryImage05Thumb }, { original: galleryImage06, thumbnail: galleryImage06Thumb }, { original: galleryImage07, thumbnail: galleryImage07Thumb }, { original: galleryImage08, thumbnail: galleryImage08Thumb }, { original: galleryImage09, thumbnail: galleryImage09Thumb }];

    return (
      <div style={{ padding: '15px',maxWidth: '1115px', margin: '2.5rem 0 2.5rem 0', boxShadow: '0 2px 3px 0 rgb(0 0 0 / 36%' }}>
        <ImageGallery items={images} lazyLoad={true} showPlayButton={false} />
      </div>
    );
  }
}

export default NaturalLightOutdoor
