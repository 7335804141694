import { EuiSpacer } from '@elastic/eui';
import VideoCard from 'components/Indoor_Cameras/IN-8015_HD/Videos/video-8015-unboxing';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import NavButtons from 'components/Indoor_Cameras/IN-8015_HD/NavButtons';
import * as React from 'react';
export default {
  EuiSpacer,
  VideoCard,
  BreadCrumbs,
  SEOHelmet,
  NavButtons,
  React
};