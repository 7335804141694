import React from 'react'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import ceConform from "../../../../images/Indoor_Cameras/IN-6014_HD/Safety_Warnings/CE-Conformity.png"
import fccConform from "../../../../images/Indoor_Cameras/IN-6014_HD/Safety_Warnings/FCC-Conformity.png"
import rohsConform from "../../../../images/Indoor_Cameras/IN-6014_HD/Safety_Warnings/RoHS-Conformity.png"




export default function ConformityCard() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={ceConform}
                    alt="CE Conformity"
                  />
              }
              description={
                <p>Das Gerät entspricht den grundlegenden Anforderungen, der europäischen Richtlinie für elektromagnetische Verträglichkeit (2004/108/EC) und der Niederspannungsrichtlinie (2006/95/EC ).</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={fccConform}
                    alt="FCC Conformity"
                  />
              }
              description={
                <p>Dieses Produkt entspricht den unter FCC Teil 15 angegebenen Regularitäten. Diese Grenzwerte wurden festgelegt, um einen angemessenen Schutz vor schädlicher Belastung beim Betrieb des Geräts zu gewährleisten. Dieses Produkt emittiert und verwendet Funkenergie. Es kann außerdem andere Funkkommunikationssysteme stören, wenn es nicht gemäß diesem Handbuch verwendet wird.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={rohsConform}
                    alt="RoHS Conformity"
                  />
              }
              description={
                <p>Alle INSTAR-Produkte sind bleifrei und erfüllen die Anforderungen der europäischen Richtlinie zur Beschränkung gefährlicher Stoffe (RoHS). Dies garantiert, dass der gesamte Produktionsprozess und das Produkt selbst frei von Blei und allen aufgeführten Gefahrstoffen sind.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
