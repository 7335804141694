import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="UPnP Automatic Port Forwarding">
        <EuiText>
          <p>
            Der UPnP Standard soll Ihr Leben einfach machen. Jeder Port, der durch ein Gerät in Ihren Netzwerk verwendet wird, wird automatisch weitergeleitet. Dies würde den DDNS Zugriff auf Ihre Kamera sehr einfach machen. Leider, funktioniert der UPnP Service nicht sehr gut und wird teilweise durch Ihren Netzwerk Administrator blockiert. Bitte deaktivieren Sie UPnP, wenn Sie mit einer manuellen Portweiterleitung arbeiten.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

