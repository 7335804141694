import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/Einrichten/">
                  <StaticImage
                    src='../../images/Search/MD_SearchThumb_MDSetup.webp'
                    alt="Bewegungserkennung"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Bewegungserkennung"
              description="Um die Bewegungserkennung zu aktivieren, öffnen Sie das Webinterface Ihrer INSTAR HD IP-Kamera und klicken auf das Menü Alarm / Bereiche. Jetzt ist die..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/Einrichten/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/Passive_Infrared_Detection/">
                  <StaticImage
                    src='../../images/Search/MD_SearchThumb_PIR_Sensor.webp'
                    alt="PIR Sensor"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="PIR Sensor"
              description="Nutzen Sie den Passiven IR Sensor Ihrer Kamera um Fehlalarme durch Lichtschwankungen im Überwachungsraum zu reduzieren..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/Passive_Infrared_Detection/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/Alarm_Benachrichtigung/">
                  <StaticImage
                    src='../../images/Search/MD_SearchThumb_AlarmNotification.webp'
                    alt="Alarm Benachrichtigung"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Alarm Benachrichtigung"
              description="Einrichten und Aktivieren des E-Mail-Alarms. Schritt 01 Öffnen Sie zunächst die E-Mail-Einstellungen. INSTAR bietet Ihnen eine E-Mail-Weiterleitung an, mit der Sie ..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/Alarm_Benachrichtigung/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/SD_Karten_Zugriff/">
                  <StaticImage
                    src='../../images/Search/MD_SearchThumb_SDCardAccess.webp'
                    alt="SD Karten Zugriff"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="SD Karten Zugriff"
              description="Inhaltsverzeichnis Smartphone-Apps INSTAR Camera Tool Web-Benutzeroberfläche FTP-Software (Windows) FTP-Software (macOS) Die HD-Kameramodelle verwenden eine interne (herausnehmbare) SD-Karte zur Aufzeichnung von Videos, Alarmsna..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/SD_Karten_Zugriff/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/Alarm_FTP_Upload/">
                  <StaticImage
                    src='../../images/Search/MD_SearchThumb_FTPSettingsAlarmUpload.webp'
                    alt="Alarm FTP Upload"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Alarm FTP Upload"
              description="Alarm-Upload / FTP-Einstellungen für 1080p-Kameras Einstellen des FTP-Uploads während der Alarm- und Fotoserie Ihre IP-Kamera kann Bilder und Videos entweder während einer Bewegung..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/Alarm_FTP_Upload/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/Router_als_FTP_Server/">
                  <StaticImage
                    src='../../images/Search/MD_SearchThumb_RouterAsFTPServer.webp'
                    alt="Router als FTP Server"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Router als FTP Server"
              description="Lokaler FTP-Server Die meisten modernen Router unterstützen mittlerweile das Anstecken von USB-Massenspeichern (USB-Sticks, Festplatten, etc. ...) - einige verfügen sogar über eine große Menge an On-Board-Speicher, den Sie nutzen können ..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/Router_als_FTP_Server/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/FTP_Server_Setup/">
                  <StaticImage
                    src='../../images/Search/MD_SearchThumb_FTPServerSetup.webp'
                    alt="FTP Server Setup"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="FTP Server Setup"
              description="Einrichten eines Filezilla-Servers auf Ihrem Windows-Computer Ihre Kamera kann Bilder während eines Alarmereignisses oder auch in einem definierten Zeitraum auf einen beliebigen FTP-Server hochladen. Neben..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/FTP_Server_Setup/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/INSTAR_Cloud/">
                  <StaticImage
                    src='../../images/Search/MD_SearchThumb_Cloud.webp'
                    alt="INSTAR Cloud"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="INSTAR Cloud"
              description="Falschalarm-Erkennung Die neue Funktion fügt eine zusätzliche serverseitige Alarm-Event-Validierung hinzu, die in der Lage ist, mögliche Falschalarme zu erkennen und sie in der Registerkarte Alarme entsprechend zu markieren. Die Plattform pr..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/INSTAR_Cloud/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
