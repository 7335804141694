import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Schnell_Installation/Wie_Funktioniert_eine_IP_Kamera/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_HowDoesAnIPCameraWork.webp'
                    alt="Wie funktioniert eine IP Kamera"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Wie funktioniert eine IP Kamera"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Schnell_Installation/Wie_Funktioniert_eine_IP_Kamera/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Schnell_Installation/Nach_dem_Auspacken/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_AfterUnpackingYourIPCamera.webp'
                    alt="Nach dem Auspacken"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Nach dem Auspacken"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Schnell_Installation/Nach_dem_Auspacken/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Schnell_Installation/Erste_Schritte/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_FirstStepsWithYourIPCamera.webp'
                    alt="Erste Schritte"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Erste Schritte"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Schnell_Installation/Erste_Schritte/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Schnell_Installation/Power_over_Ethernet/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_PowerOverEthernet.webp'
                    alt="Power over Ethernet"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Power over Ethernet"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Schnell_Installation/Power_over_Ethernet/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Schnell_Installation/Powerline/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_Powerline.webp'
                    alt="IN-LAN Powerline"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-LAN Powerline"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Schnell_Installation/Powerline/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Schnell_Installation/Direkte_LAN_Verbindung/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_DirectConnectionWithYourPC.webp'
                    alt="Direkte LAN Verbindung"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Direkte LAN Verbindung"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Schnell_Installation/Direkte_LAN_Verbindung/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Schnell_Installation/Alternative_IP_Scanner/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_AlternativeIPScanner_eng.webp'
                    alt="IP Kamera Suche"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IP Kamera Suche"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Schnell_Installation/Alternative_IP_Scanner/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Schnell_Installation/Sprachauswahl/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_LanguageSettings.webp'
                    alt="Sprachauswahl"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Sprachauswahl"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Schnell_Installation/Sprachauswahl/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Schnell_Installation/Live_Video/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_LiveVideo.webp'
                    alt="Zeigen Sie das Video Ihrer Kameras in Ihrem Browser an"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Zeigen Sie das Video Ihrer Kameras in Ihrem Browser an"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Schnell_Installation/Live_Video/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Schnell_Installation/Benutzerverwaltung/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_CreatingUserAccounts.webp'
                    alt="Benutzerverwaltung"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Benutzerverwaltung"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Schnell_Installation/Benutzerverwaltung/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Schnell_Installation/WebUI_und_Firmware_Upgrade/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_WebUIFirmwareUpgrade.webp'
                    alt="Software Update"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Software Update"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Schnell_Installation/WebUI_und_Firmware_Upgrade/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Schnell_Installation/Leeren_des_Browserverlaufs/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_ClearBrowsingHistory.webp'
                    alt="Löschen des Browserverlaufs"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Löschen des Browserverlaufs"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Schnell_Installation/Leeren_des_Browserverlaufs/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Schnell_Installation/Internet_Protocol_IPv6/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_DirectConnectionWithYourPC.webp'
                    alt="Internet Protokoll Version 6 (IPv6)"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Internet Protokoll Version 6 (IPv6)"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Schnell_Installation/Internet_Protocol_IPv6/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Schnell_Installation/Herstellen_der_WLAN_Verbindung/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_WLAN.webp'
                    alt="Wireless Protected Setup"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Wireless Protected Setup"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Schnell_Installation/Herstellen_der_WLAN_Verbindung/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            {/* <EuiCard
              textAlign="left"
              image={
                <Link to="/Schnell_Installation/ONVIF/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_ONVIF.webp'
                    alt="ONVIF"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="ONVIF"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Schnell_Installation/ONVIF/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            /> */}
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}