import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class ONVIFTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "ONVIF Einstellungen" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'ONVIF Protokoll aktivieren', descriptionColumn: 'Den ONVIF Dienst aktivieren oder deaktivieren.' },
        { actionColumn: 'ONVIF Port', descriptionColumn: 'Das ist der Port mit dem externe Geräte mit Ihrer Kamera kommunizieren müssen. Es wird empfohlen den Standard Port 8080 zu lassen. Stellen Sie sicher, dass Sie nicht denselben Port als ONVIF Protokoll. Es wird empfohlen den Standard Port 8080 zu lassen. Stellen Sie sicher, dass Sie nicht denselben Port als LAN, RTMP oder RTSP Port verwenden!' },
        { actionColumn: 'ONVIF Authentifizierung', descriptionColumn: 'Wenn Ihr Drittanbieter-Gerät sich nicht mit Ihrer Kamera verbinden kann, können Sie versuchen, die Authentifizierung für das ONVIF Protokoll zu deaktivieren. Beachten Sie, dass jeder auf Ihre Kamera zugreifen könnte, wenn Ihre Authentifizierung deaktiviert ist. Es wird in diesem Fall nicht empfohlen, den ONVIF Port ins Internet weiterleiten.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

ONVIFTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(ONVIFTable)