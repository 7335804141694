import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="System Reboot">
        <EuiText>
          <p>
            Hier können Sie auf sichere Weise die Kamera neustarten. Es wird empfohlen die Kamera nicht einfach vom Strom zu trennen, da dies der SD-Karte schaden kann. Falls Sie die Kamera vom Strom nehmen müssen, dann empfehlen wir es zunächst alle Alarmaktionen oder andere Funktionen die im Zusammenhang mit einer Aufnahme auf der SD-Karte stehen zu stoppen oder einfach die SD-Karte unter dem Menü SD-Karte auszuwerfen.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

