import React from 'react'

import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Hinweis:">
        <EuiText>
          <p>Dies ist eine aktualisierte Anleitung, die Docker zur Installation von Home Assistant verwendet, anstatt die Software direkt über die Python-Pakete zu installieren, wie es in unserer jetzt veralteten Anleitung <Link to="/Software/Linux/Home_Assistant/Deprecated/">beschrieben ist</Link>. Dieser Leitfaden wurde auch aktualisiert, um mit INSTAR-Kameras neueren Datums als der Full HD-Serie verwendet werden zu können. Es gibt auch eine Liste von <Link to="/Erweitert/Alle_Home_Assistant_Tutorials/">FAQs</Link>), die bereits aktualisiert sind.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

