import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'Übersicht',
      href: '/de/Innenkameras/IN-6012_HD/',
    },
    {
      text: 'Spezifikationen',
      href: '/de/Innenkameras/IN-6012_HD/Technische_Spezifikationen/',
    },
    {
      text: 'Features',
      href: '/de/Innenkameras/IN-6012_HD/Produkt_Features/',
    },
    {
      text: 'Installation',
      href: '/de/Innenkameras/IN-6012_HD/Schnell_Installation/',
    },
    {
      text: 'P2P',
      href: '/de/Innenkameras/IN-6012_HD/Point2Point/',
    },
    {
      text: 'Objektivjustage',
      href: '/de/Innenkameras/IN-6012_HD/Objektivjustage/',
    },
    {
      text: 'Handbuch',
      href: '/de/Innenkameras/IN-6012_HD/Handbuch/',
    },
    {
      text: '◄ Innenkameras',
      href: '/de/Innenkameras/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={10}
        aria-label="INSTAR IN-6012 HD Online Usermanual Chapters"
      />
      <EuiSpacer />
    </Fragment>
  );
}

// export default () => {
//   const breadcrumbs = [
//     {
//       text: 'Overview',
//       href: '/Innenkameras/IN-6012_HD/',
//     },
//     {
//       text: 'Technical Specifications',
//       href: '/Innenkameras/IN-6012_HD/Technical_Specifications/',
//     },
//     {
//       text: 'Product Features',
//       href: '/Innenkameras/IN-6012_HD/Product_Features/',
//     },
//     {
//       text: 'Installation',
//       href: '/Innenkameras/IN-6012_HD/Quick_Installation/',
//     },
//     {
//       text: 'P2P Installation',
//       href: '/Innenkameras/IN-6012_HD/Point2Point/',
//     },
//     {
//       text: 'Lense Adjustment',
//       href: '/Innenkameras/IN-6012_HD/Lense_Adjustment/',
//     },
//     {
//       text: '◄ Usermanual',
//       href: '/Innenkameras/IN-6012_HD/Usermanual/',
//     }
//   ];

//   return (
//     <Fragment>
//       <EuiBreadcrumbs
//         truncate={true}
//         breadcrumbs={breadcrumbs}
//         max={7}
//         aria-label="INSTAR IN-6012 HD Online Usermanual Chapters"
//       />
//       <EuiSpacer />
//     </Fragment>
//   );
// }