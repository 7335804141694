import React from 'react'

import TableRow from './TableRow'

export default function CompareTable(props) {

    let names = []
    for(let i = 0; i < props.compareItems.length; i++){
        names.push(<th className="table-item-names">{props.compareItems[i].name}</th>)
    }
    console.log(names)
    return names.length >= 2 ? (
        <table className="compare-table">
            <thead className="thread">
                <tr>
                    <th className="table-item-names"></th>
                    {names.length >= 2 ? names: null}
                </tr>
            </thead>
            <tbody>

                <tr style={{backgroundColor: "#2f78f9"}}>
                    <th scope="rowgroup" colSpan="7" style={{color: "white"}}>Optik und Bild</th>
                </tr>
                <TableRow {...props} name="Standardobjektiv"/>
                <TableRow {...props} name="CMOS Sensor"/>
                <TableRow {...props} name="MegaPixel"/>
                <TableRow {...props} name="Lichtempfindlichkeit"/>
                <TableRow {...props} name="Blickwinkel"/>
                <TableRow {...props} name="optischer Zoom"/>
                <TableRow {...props} name="Video Codierung"/>
                <TableRow {...props} name="Auflösung"/>
                <TableRow {...props} name="Bildwiederholungsrate"/>
                <TableRow {...props} name="Bild"/>

                <tr style={{backgroundColor: "#2f78f9"}}>
                    <th scope="rowgroup" colSpan="7" style={{color: "white"}}>Netzwerkanbindung</th>
                </tr>
                <TableRow {...props} name="LAN"/>
                <TableRow {...props} name="WLAN/PoE"/>
                <TableRow {...props} name="WLAN Frequenzband"/>
                <TableRow {...props} name="WLAN Reichweite"/>
                <TableRow {...props} name="WLAN Verschlüsslung"/>

                <tr style={{backgroundColor: "#2f78f9"}}>
                    <th scope="rowgroup" colSpan="7" style={{color: "white"}}>Nachtsicht</th>
                </tr>
                <TableRow {...props} name="Infrarot Dioden"/>
                <TableRow {...props} name="IR Wellenlänge"/>
                <TableRow {...props} name="Nachtsicht"/>
                <TableRow {...props} name="IR Cut Filter"/>

                <tr style={{backgroundColor: "#2f78f9"}}>
                    <th scope="rowgroup" colSpan="7" style={{color: "white"}}>Features</th>
                </tr>
                <TableRow {...props} name="Bewegungserkennung"/>
                <TableRow {...props} name="PIR Bewegungsmelder"/>
                <TableRow {...props} name="Alarm Ein/Ausgang"/>
                <TableRow {...props} name="Alarm per Email"/>
                <TableRow {...props} name="MQTT Broker/Client"/>
                <TableRow {...props} name="HTTPS Verschlüsselung"/>
                <TableRow {...props} name="DDNS Adresse"/>
                <TableRow {...props} name="Drittanbieter DDNS"/>
                <TableRow {...props} name="Point2Point UID"/>
                <TableRow {...props} name="uPnP"/>
                <TableRow {...props} name="INSTAR Cloud"/>
                <TableRow {...props} name="FTPS Verschlüsselung"/>
                <TableRow {...props} name="Alarm per FTP Upload"/>
                <TableRow {...props} name="FTP Intervall Upload"/>
                <TableRow {...props} name="Zeitplan für Alarm"/>
                <TableRow {...props} name="Video Aufnahme"/>
                <TableRow {...props} name="Alarm Pre-Recording"/>
                <TableRow {...props} name="integrierte SD Karte"/>
                <TableRow {...props} name="Snapshot Funktion"/>
                <TableRow {...props} name="Fotoserie"/>
                <TableRow {...props} name="Multi-Ansicht"/>
                {/* <TableRow {...props} name="ONVIF Protokoll"/> */}
                <TableRow {...props} name="integriertes Mikrofon"/>
                <TableRow {...props} name="integrierter Lautsprecher"/>
                <TableRow {...props} name="Audio Eingang"/>
                <TableRow {...props} name="Audio Ausgang"/>
                <TableRow {...props} name="Pan & Tilt Steuerung"/>
                <TableRow {...props} name="Preset Positionen"/>

                <tr style={{backgroundColor: "#2f78f9"}}>
                    <th scope="rowgroup" colSpan="7" style={{color: "white"}}>System</th>
                </tr>
                <TableRow {...props} name="Benutzerrechte Vergabe"/>
                <TableRow {...props} name="Passwortschutz"/>
                <TableRow {...props} name="Betriebssysteme"/>
                <TableRow {...props} name="Mobilsysteme"/>

                <tr style={{backgroundColor: "#2f78f9"}}>
                    <th scope="rowgroup" colSpan="7" style={{color: "white"}}>Details</th>
                </tr>
                <TableRow {...props} name="Montagearten"/>
                <TableRow {...props} name="Betriebstemperatur"/>
                <TableRow {...props} name="Heizung"/>
                <TableRow {...props} name="Netzteil Anschluss"/>
                <TableRow {...props} name="Länge des Netzteils"/>
                <TableRow {...props} name="Spannungsversorgung"/>
                <TableRow {...props} name="Stromverbrauch"/>
                <TableRow {...props} name="Gewicht"/>
                <TableRow {...props} name="Maße"/>
            </tbody>
        </table>
    ): null
}
