import React from 'react'
import {Link} from 'gatsby'

import { EuiAvatar, EuiButtonIcon, EuiText, EuiBadge, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import {
  EuiCommentList,
} from '@elastic/eui'

import iftttImage from 'images/Products/IFTTT/INSTAR_IFTTT.webp'
import mqttImage from 'images/Products/MQTT/INSTAR_MQTT.webp'

const iftttRelease = (
  <EuiFlexGroup responsive={false} alignItems="center" gutterSize="s">
    <EuiFlexItem grow={false}>IFTTT Applets</EuiFlexItem>
    <EuiFlexItem grow={false}>
      <EuiBadge color="primary">Firmware Update</EuiBadge>
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <EuiBadge color="warning">Full HD Serie</EuiBadge>
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <EuiBadge color="default">INSTAR Cloud</EuiBadge>
    </EuiFlexItem>
  </EuiFlexGroup>
);

const annotation = (
  <EuiButtonIcon
    title="Annotation"
    aria-label="Annotation"
    color="subdued"
    iconType="annotation"
  />
);

const timeLine = (
  <EuiButtonIcon
    title="Git Commit"
    aria-label="Git Commit"
    color="subdued"
    iconType="timeline"
  />
);

const iftttAd = (
  <EuiText size="s">
    <h2>Kontrollieren Sie Ihre Kamera über den IFTTT Online Dienst</h2>
    <img alt="INSTAR IFTTT Support" src={iftttImage} style={{ maxWidth: '100%', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px'}} />
    <p>
      Auf der IFTTT-Plattform stellten wir einen <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/">Dienst namens INSTAR</Link> zur Verfügung. Die <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Einleitung/">INSTAR Applets</Link> bieten Ihnen die Möglichkeit, einige Einstellungen Ihrer <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/User_Guide/How_to_use_INSTAR_Applets/">INSTAR Kamera oder INSTAR Cloud mit IFTTT zu steuern</Link>. Sie können INSTAR mit Auslösern für Ort (Geo Location) und Datum & Zeit verbinden, Benachrichtigungen versenden und vieles mehr. Zum Beispiel können Sie die INSTAR Applets mit dem IFTTT-Ortungsdienst verbinden und automatisch den Alarm deaktivieren lassen, wenn Sie zu Hause ankommen. Sie können die INSTAR-Applets auch verwenden, um Ihre eigene Automatisierung zu erstellen und sie mit <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/">anderen Applets von Drittanbietern zu verbinden</Link>.
    </p>
  </EuiText>
);

const mPol = (
  <EuiFlexGroup responsive={false} alignItems="center" gutterSize="s">
    <EuiFlexItem grow={false}>
      <EuiAvatar
        imageUrl="/de/images/Assistants/agentsmith.webp"
        size="m"
        name="Mike Polinowski"
      />
    </EuiFlexItem>
    <EuiFlexItem grow={false}>Mike Polinowski</EuiFlexItem>
  </EuiFlexGroup>
);

const mqttRelease = (
  <EuiFlexGroup responsive={false} alignItems="center" gutterSize="s">
    <EuiFlexItem grow={false}>INSTAR MQTT</EuiFlexItem>
    <EuiFlexItem grow={false}>
      <EuiBadge color="primary">Firmware Update</EuiBadge>
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <EuiBadge color="warning">Full HD Serie</EuiBadge>
    </EuiFlexItem>
  </EuiFlexGroup>
);

const mqttAd = (
  <EuiText size="s">
    <h2>Machen Sie Ihrer Kamera zum Herzstück Ihres Smarthomes</h2>
    <img alt="INSTAR MQTT Support" src={mqttImage} style={{ maxWidth: '100%', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px'}} />
    <p>
      Mithilfe der <Link to="/Erweitert/INSTAR_MQTT_Broker/">MQTT Schnittstelle</Link> Ihrer <Link to="/Produkte/">INSTAR Full HD Kameras</Link> können Sie diese zu einem bestehenden Smarthome-System (<Link to="/Erweitert/INSTAR_MQTT_Broker/Home_Assistant/">Home Assistant</Link>, <Link to="/Erweitert/INSTAR_MQTT_Broker/OpenHAB2/">OpenHAB</Link>, <Link to="/Erweitert/INSTAR_MQTT_Broker/Node-RED/">Node-RED</Link>, <Link to="/Erweitert/INSTAR_MQTT_Broker/Athom_Homey_MQTT_Client/">Athom Homey</Link>, <Link to="/Erweitert/INSTAR_MQTT_Broker/Homematic_CCU3_und_RedMatic/">Homematic</Link>, <Link to="/Erweitert/INSTAR_MQTT_Broker/ioBroker/">ioBroker</Link>, <Link to="/Erweitert/INSTAR_MQTT_Broker/Loxone/">Loxone</Link>, <Link to="/Erweitert/INSTAR_MQTT_Broker/homee/">homee</Link>) hinzufügen kann. Oder auch zum Haupt-Broker für Ihre MQTT Sensoren machen. MQTT ermöglicht es Ihnen <strong>ALLE</strong> <Link to="/Software/Smarthome/">Funktionen Ihrer Kamera zu automatisieren</Link> und mit anderen Diensten in Ihrem Smarthome zu verknüpfen.
    </p>
  </EuiText>
);



const instarAvatar = (
  <EuiAvatar
    imageUrl="/de/images/Assistants/INSTAR-Ico.webp"
    size="l"
    name="INSTAR Deutschland GmbH"
  />
);

const comments = [
  {
    username: mPol,
    type: 'update',
    actions: timeLine,
    event: mqttRelease,
    timestamp: 'Mai 10, 2021',
    timelineIcon: 'tag',
  },
  {
    username: 'INSTAR Deutschland GmbH',
    event: 'INSTAR MQTT',
    children: mqttAd,
    actions: annotation,
    timestamp: 'Dezember 31, 2019',
    timelineIcon: instarAvatar,
  },
  {
    username: mPol,
    type: 'update',
    actions: timeLine,
    event: iftttRelease,
    timestamp: 'September 9, 2020',
    timelineIcon: 'tag',
  },
  {
    username: 'INSTAR Deutschland GmbH',
    event: 'INSTAR IFTTT Applets',
    children: iftttAd,
    actions: annotation,
    timestamp: 'Oktober 19, 2020',
    timelineIcon: instarAvatar,
  },
];

export default () => <EuiCommentList comments={comments} />;