import React from 'react'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import alexaCardImage from "images/Products/Alexa/instar-alexa-8001.webp"
import homekitCardImage from "images/Products/Homekit/instar-homekit-8001.webp"

import HomekitFlyout from './HomekitFlyout'
import AlexaFlyout from './AlexaFlyout'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={alexaCardImage}
                    alt="INSTAR Alexa Kamera Skill"
                  />
              }
              title='Alexa Kamera Skill'
              description={
                <p>Aktivieren Sie die Kamerakontrolle für Ihre IN-8401, IN-8403 oder IN-8415 2k+ WQHD durch Alexa mit Hilfe Ihres kostenlosen INSTAR Cloud Accounts.</p>
              }
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                    <EuiFlexItem grow={false}>
                      <AlexaFlyout />
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={homekitCardImage}
                    alt="INSTAR Kamera Homekit Support"
                  />
              }
              title='Apple Homekit'
              description={
                <p>Kontrollieren Sie Ihre INSTAR Full 2k+ WQHD mit der Apple Homekit App von Ihrem iPhone oder iPad.</p>
              }
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                    <EuiFlexItem grow={false}>
                      <HomekitFlyout />
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
