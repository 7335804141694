import React from 'react'
import { OutboundLink } from 'gatsby-plugin-gtag'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiIcon
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="f8_yPDpo5hE"
                    title="GData Antivirus"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              title="GData Antivirus"
              footer={
                <EuiText>
                <p>Sollten Sie z.B. über die Weboberfläche, die Software InstarVision 2.0 oder die Windows App kein Bild erhalten bzw. kein Video, dann kann dies damit zu tuen haben das dieses durch GData blockiert wird.</p>
                <p>Mit großer Wahrscheinlichkeit müssten Sie in diesem Fall eine Ausnahmeregel anlegen für den Webschutz.</p>
                <p>Wir zeigen Ihnen hier wie dies geht. Auf <OutboundLink href="https://www.youtube.com/watch?v=f8_yPDpo5hE" target="_blank" rel="noopener noreferrer">Youtube ansehen <EuiIcon type="popout" /></OutboundLink></p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}