import React from 'react'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="82qRXMxHf4c"
                    title="Synology NAS als FTP Server"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              title="Synology NAS als FTP Server"
              footer={
                <EuiText>
                  <p>In diesem Video zeigen wir Ihnen wie Sie die Synology Disk Station als FTP Server einrichten können um so die Videoaufnahmen Ihrer INSTAR HD Kamera auf diese übertragen zu lassen. </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}
