import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Achtung">
        <EuiText>
            <p>Der Benutzername und das Kennwort müssen 8-30 Zeichen lang sein. Für Benutzername und Kennwort dürfen alle <a href="https://www.asciitable.com/" target="_blank" rel="noopener noreferrer">ASCII Zeichen</a>, mit Ausnahme der Erweiterten Zeichen, verwendet werden. Achten Sie darauf, daß <strong>Umlaute</strong> z.B. nicht Teil dieses unterstüzten Zeichensatzes sind. Passwörter müssen Groß- und Kleinbuchstaben sowie min. eine Zahl und ein Sonderzeichen beinhalten.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

