import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class settingsColumn extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "settingColumn", title: "Setting" },
        { name: "selectColumn", title: "Select" },
      ],
      rows: [
        { settingColumn: 'Anwendung', selectColumn: 'Wählen Sie "Andere Anwendung".' },
        { settingColumn: 'Bezeichnung', selectColumn: 'Wählen Sie einen Namen - z.B. den Kameranamen' },
        { settingColumn: 'Protokoll', selectColumn: 'Wählen Sie TCP' },
        { settingColumn: 'Port an Gerät', selectColumn: 'Hier stellen Sie den Port für Ihre Kamera ein, den Sie bereits in Schritt 1 eingestellt haben. Also für Kamera 1 ist es 8081, für Ihre 2. Kamera ist es 8082 usw.' },
        { settingColumn: 'Bis Port', selectColumn: 'Hier stellen Sie den gleichen Port wie im Array zuvor ein (8081 usw.)' },
        { settingColumn: 'Port extern gewünscht', selectColumn: 'Hier stellen Sie den gleichen Port ein wie in den Arrays zuvor (8081 usw.)' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

settingsColumn.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(settingsColumn)