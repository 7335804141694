import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Vorsicht">
        <EuiText>
          <p>Ihre Kamera wird für jedermann erreichbar sein, nachdem Sie sie in Ihrem Router weitergeleitet haben. Um ungewünschte Zugriffe zu vermeiden, ändern Sie den Standardlogin Ihrer Kamera. Der Standardlogin kann im <Link to="/Web_User_Interface/1080p_Serie/System/Benutzer/">Benutzer Menü</Link> angepasst werden. .</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

