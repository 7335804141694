import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Alarm Emails">
        <EuiText>
          <p>
            Im Email Menü können Sie die Absender und Ihre Empfänger Adresse für die Email Benachrichtigung hinterlegen. Der Absender muss entsprechend des gewählten SMTP Accounts gesetzt werden. Beachten Sie bitte, dass einige Email Anbieter es nicht erlauben, wenn Absender und Empfänger Email gleich gewählt werden! Im Falle der INSTAR Vorauswahl muß der Absender bei <code>cam@instar.email</code> belassen werden. Es ist empfohlen auch einen Betreff und Email Text (max. 64 Zeichen) einzutragen, da leere Emails ggf. an Spam-Wächtern hängen bleiben.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

