import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Achtung">
        <EuiText>
          <p>Der Benutzername und das Kennwort sollten nicht mehr als 30 Zeichen enthalten. Die folgenden Sonderzeichen werden <u>unterstützt</u>: <code>@ $ * - _ . / ?</code>. Die folgenden Sonderzeichen werden <u>nicht unterstützt</u>: <code>& § = ä ö ü + # ß ´ ! " ( ) % ' : ; , SPACE</code></p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

