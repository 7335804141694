import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'Troubleshooting',
      href: '/de/Bewegungserkennung/Alarm_Benachrichtigung/Fehlerbehebung/',
    },
    {
      text: 'SMTP Servers',
      href: '/de/Bewegungserkennung/Alarm_Benachrichtigung/SMTP_Servers/',
    },
    {
      text: 'VGA Series',
      href: '/de/Bewegungserkennung/Alarm_Benachrichtigung/VGA_Serie/',
    },
    {
      text: '720p Series',
      href: '/de/Bewegungserkennung/Alarm_Benachrichtigung/720p_Serie/',
    },
    {
      text: '1080p Series',
      href: '/de/Bewegungserkennung/Alarm_Benachrichtigung/',
    },
    {
      text: '◄ Bewegungserkennung',
      href: '/de/Bewegungserkennung/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={6}
        aria-label="Alarm Notification"
      />
      <EuiSpacer />
    </Fragment>
  );
}

// export default () => {
//   const breadcrumbs = [
//     {
//       text: 'Troubleshooting',
//       href: '/Bewegungserkennung/Alarm_Notification/Troubleshooting/',
//     },
//     {
//       text: 'SMTP Servers',
//       href: '/Bewegungserkennung/Alarm_Notification/SMTP_Servers/',
//     },
//     {
//       text: 'VGA Series',
//       href: '/Bewegungserkennung/Alarm_Notification/VGA_Series/',
//     },
//     {
//       text: '720p Series',
//       href: '/Bewegungserkennung/Alarm_Notification/720p_Series/',
//     },
//     {
//       text: '1080p Series',
//       href: '/Bewegungserkennung/Alarm_Notification/',
//     },
//     {
//       text: '◄ Alarm Notification',
//       href: '/Bewegungserkennung/Alarm_Notification/',
//     }
//   ];

//   return (
//     <Fragment>
//       <EuiBreadcrumbs
//         truncate={true}
//         breadcrumbs={breadcrumbs}
//         max={6}
//         aria-label="Alarm Notification"
//       />
//       <EuiSpacer />
//     </Fragment>
//   );
// }