import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class PTZTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Pan, Tilt & Zoom" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Kamera Kalibrierung & Positionen', descriptionColumn: 'Aktivieren oder deaktivieren Sie die PTZ Kalibrierung beim Starten der Kamera. Bitte beachten Sie, dass die gespeicherten Positionen nicht länger verfügbar sind, wenn Sie die Kalibrierung deaktivieren.' },
        { actionColumn: 'Position 1 nach dem Neustart', descriptionColumn: 'Die Kamera bewegt sich standardmäßig zur mittleren Position nach der Kalibrierung. Aktivieren Sie diese Checkbox, wenn Sie die Kamera stattdessen zur gespeicherten Positionen 1 bewegen wollen. Nur verfügbar, wenn die Kamera Kalibrierung aktiv ist!' },
        { actionColumn: 'Drehen & Neigen löst keinen Alarm aus', descriptionColumn: 'In den meisten Fällen ist es nicht gewünscht, dass eine Schwenk&Neige Bewegung Ihrer Kamera eine Bewegungserkennung auslöst. Falls Sie aber benachrichtigt werden wollen, wenn Ihre Kamera bewegt wird, aktivieren Sie diese Checkbox.' },
        { actionColumn: 'Aktivieren Sie Schritt für Schritt Schwenken&Neigen', descriptionColumn: 'Mit den Standardeinstellungen wird sich Ihre Kamera solange in eine Richtung bewegen wie Sie den Pfeilbutton im Kontroll-Pad drücken. Wenn Ihre Verbindung etwas schlechter ist oder hohe Latenzen aufweist, kann es passieren, dass der Stopp-Befehl verloren geht und Ihre Kamera bis zum Ende durchfährt. Sie können die Ein-Schritt Bewegung nutzen, um das zu verhindern. Ihre Kamera wird sich bei jedem drücken nur einen Schritt weiterbewegen - dies gibt Ihnen die höchste Präzision.' },
        { actionColumn: 'Schwenk Geschwindigkeit', descriptionColumn: 'Setzen Sie die Geschwindigkeit der horizontalen Bewegung Ihrer Kamera fest.' },
        { actionColumn: 'Neige Geschwindigkeit', descriptionColumn: 'Setzen Sie die Geschwindigkeit der vertikalen Bewegung Ihrer Kamera fest.' },
        { actionColumn: 'vscan Anzahl', descriptionColumn: 'Stellen Sie die Menge der vertikalen Läufe Ihrer Kamera ein, nachdem Sie die VSCAN Funktion gestartet haben.' },
        { actionColumn: 'hscan Anzahl', descriptionColumn: 'Stellen Sie die Menge der horizontalen Läufe Ihrer Kamera ein, nachdem Sie die HSCAN Funktion gestartet haben.' },
        { actionColumn: 'Schwenk / Neig Limit Menü anzeigen', descriptionColumn: 'Die P&T Limits erlauben es Ihnen, die Weite der vertikalen und horizontalen Bewegungen zu begrenzen. Das Limit Menü wird am P&amp;T Kontroll-Pad angezeigt, nachdem Sie die Funktion hier aktiviert haben. Nur verfügbar, wenn die Kamerakalibrierung aktiv ist!' },
        { actionColumn: 'Alarmposition aktivieren', descriptionColumn: 'Aktivieren Sie die Alarm Position, wenn Sie Ihre Kamera zu einer spezifischen Stelle fahren lassen wollen, nachdem ein Alarm ausgelöst wurde. Das ist besonders dann interessant, wenn Sie einen externen Sensor benutzen, wie z.B. den IN-MOTION 500, oder den Audio Alarm. Lassen Sie Ihre Kamera ein Auge auf die eine Seite halten und den externen Sensor auf die andere Seite. Nur verfügbar, wenn die Kalibrierung aktiv ist!' },
        { actionColumn: 'Alamposition', descriptionColumn: 'Stellen Sie hier die Alarmposition ein.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

PTZTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(PTZTable)