import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class BackupTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Backup Einstellungen" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Backup', descriptionColumn: 'Erstellen Sie Sicherheitskopien Ihrer Kameraeinstellungen oder importieren Sie zuvor erstellte Backups.' },
        { actionColumn: 'Datei auswählen', descriptionColumn: 'Nachdem Sie ein Backup erstellt haben, können Sie diese Datei auf die Kamera hochladen um alte Konfiguration wieder herzustellen.' },
        { actionColumn: 'Importieren', descriptionColumn: 'Nachdem Sie die Datei ausgewählt haben, können Sie diese hiermit auf die kamera importieren. Beim Importieren werden alle Einstellungen auf einen früheren Stand gebracht!' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

BackupTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(BackupTable)