import React from 'react'
import { Link } from 'gatsby'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

import mqttApiCardIcon from '../../../images/Advanced_User/INSTAR_MQTT_Broker/toc_Card_mqtt_api.webp'

export default function LatestArticle() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Erweitert/INSTAR_MQTT_Broker/MQTTv5_API/">
                  <img
                    src={mqttApiCardIcon}
                    alt="INSTAR MQTT v5 API"
                  />
                </Link>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
