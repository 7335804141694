import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class IRTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "IR LEDs" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Infrarot LED Steuerung', descriptionColumn: 'Hier können Sie das Verhalten der Infrarot LEDs der Kamera einstellen. Sie können zwischen 2 Modi wählen, zum einen die LEDs dauerhaft ausschalten und zum anderen die LEDs durch den Lichtsensor automatisch einschalten zu lassen.' },
        { actionColumn: 'Infrarot Cut Filter', descriptionColumn: 'Der IRCut Filter filtert das Infrarotlicht der Sonne bei Tage und wird automatisch entfernt wenn die Kamera in den Nachtmodus schaltet - dies ermöglicht die Nutzung der IR LEDs zur Ausleuchtung des Überwachungsbereiches. Sie können diese Automatik hier deaktivieren, indem Sie die Kamera permanent in den Nacht- oder Tagmodus schalten.' },
        { actionColumn: 'Zeitplan für IR LEDs', descriptionColumn: 'Falls Sie mit dem automatischen Modus nicht zufrieden sind - z.B. der Nachtmodus schaltet sich zu früh oder zu spät ein - können Sie hier einen fixen Zeitplan eintragen.' },
        { actionColumn: 'Aktivieren Sie die IR-LEDs um', descriptionColumn: 'Falls Sie mit dem automatischen Modus nicht zufrieden sind - z.B. der Nachtmodus schaltet sich zu früh oder zu spät ein - können Sie hier einen fixen Zeitplan eintragen. Beachten Sie bitte, dass ein Setzen dieser Einstellung den Modus der Kamera nicht sofort verändert, sondern erst, wenn das nächste Mal die eingestellte Zeit erreicht wird.' },
        { actionColumn: 'Deaktivieren Sie die IR-LEDs um', descriptionColumn: 'Die hier eingestellte Zeit ist die Zeit, in der die IR-LEDs deaktiviert werden.' },
        { actionColumn: 'PIR-Nachtschaltung aktivieren', descriptionColumn: 'Aktivieren Sie die IR-LEDs nur, wenn der PIR-Sensor ausgelöst wird.' },
        { actionColumn: 'Leuchtdauer der IR-LEDs bei PIR-Nachtschaltung', descriptionColumn: 'Zeitraum, in dem die IR-LEDs aktiv sein werden. Dieses Zeitfenster wird durch ein erneute Auslösung zurückgesetzt.' },
        { actionColumn: 'Schwellenwerte für Tag- und Nachtschaltung', descriptionColumn: 'Legen Sie fest ab welcher Helligkeit die Kamera in den Tages- bzw. Farbmodus und ab welchem Grad der Dunkelheit die Kamera in den Nacht-bzw. Schwarz-Weiss-Modus schaltet. Der Schwellenwert für die Schaltung in den Tagesmodus muss dabei immer unterhalb des Schwellenwertes für den Nachtmodus liegen.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

IRTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(IRTable)