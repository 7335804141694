import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function LatestArticle() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1440p_Serie_CGI_Befehle/Aufnahme_Menu/Fotoserie/">
                  <StaticImage
                    src='../../../images/1440p_Series_CGI_List/Task_Menu/1440pCGIs-WebUI_Task_Photoseries.png'
                    alt="1440p Serie CGI List - Aufnahme Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Fotoserie"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1440p_Serie_CGI_Befehle/Aufnahme_Menu/Video/">
                  <StaticImage
                    src='../../../images/1440p_Series_CGI_List/Task_Menu/1440pCGIs-WebUI_Task_Video.png'
                    alt="1440p Serie CGI List - Aufnahme Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Video"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
