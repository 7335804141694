import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="OpenHAB 3 Updates:">
        <EuiText>
          <ul>
            <li><a href="/Erweitert/OpenHAB_3_in_Docker/">Installing OpenHAB 3 mit Docker</a></li>
            <ul>
              <li><a href="/Erweitert/OpenHAB_3_MQTT_Binding/">OpenHAB 3 MQTT Binding</a></li>
              <li><a href="/Erweitert/OpenHAB_3_Camera_Binding/">OpenHAB 3 Camera Binding</a></li>
            </ul>
          </ul>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

