import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function LatestArticle() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1080p_Serie_CGI_Befehle/Features_Menu/Email/">
                  <StaticImage
                    src='../../../images/1080p_Series_CGI_List/Features_Menu/1080pCGIs-WebUI_Features_Email.png'
                    alt="1080p Serie CGI List - Features Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Email"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1080p_Serie_CGI_Befehle/Features_Menu/FTP/">
                  <StaticImage
                    src='../../../images/1080p_Series_CGI_List/Features_Menu/1080pCGIs-WebUI_Features_FTP.png'
                    alt="1080p Serie CGI List - Features Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="FTP"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1080p_Serie_CGI_Befehle/Features_Menu/IR_Nachtsicht/">
                  <StaticImage
                    src='../../../images/1080p_Series_CGI_List/Features_Menu/1080pCGIs-WebUI_Features_Nightvision.png'
                    alt="1080p Serie CGI List - Features Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IR Nightvision"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1080p_Serie_CGI_Befehle/Features_Menu/PTZ-Tour/">
                  <StaticImage
                    src='../../../images/1080p_Series_CGI_List/Features_Menu/1080pCGIs-WebUI_Features_PTZ_Tour.png'
                    alt="1080p Serie CGI List - Features Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="PTZ-Tour"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1080p_Serie_CGI_Befehle/Features_Menu/PTZ/">
                  <StaticImage
                    src='../../../images/1080p_Series_CGI_List/Features_Menu/1080pCGIs-WebUI_Features_PTZ.png'
                    alt="1080p Serie CGI List - Features Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="PTZ"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1080p_Serie_CGI_Befehle/Features_Menu/SD_Karte/">
                  <StaticImage
                    src='../../../images/1080p_Series_CGI_List/Features_Menu/1080pCGIs-WebUI_Features_SD.png'
                    alt="1080p Serie CGI List - Features Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="SD Card"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />


      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1080p_Serie_CGI_Befehle/Features_Menu/Status_LEDs/">
                  <StaticImage
                    src='../../../images/1080p_Series_CGI_List/Features_Menu/1080pCGIs-WebUI_Features_Status_LEDs.png'
                    alt="1080p Serie CGI List - Features Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Status LEDs"
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
