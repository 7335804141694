import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class PTZTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Pan, Tilt & Zoom" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Show Border Menü', descriptionColumn: 'Das Begrenzungsmenü ermöglicht es Ihnen den Bereich, den Ihre Kamera abfahren kann, zu begrenzen. Aktivieren Sie diese Einstellung hier um die Funktion am Steuerkreuz anzeigen zu lassen. Nur verfügbar mit aktivierter Justagefahrt!' },
        { actionColumn: 'Alarme während der Fahrt unterbinden', descriptionColumn: 'Möchten Sie die Bewegungserkennung der Kamera während eines Kamerschwenk deaktivieren, um Fehlalarme zu unterbinden.' },
        { actionColumn: 'Schrittweise Steuerung aktivieren', descriptionColumn: 'Mit den Standardeinstellungen bewegt sich Ihre Kamera in eine Richtung, solange Sie die Pfeiltaste auf dem [PTZ Control Pad](/Web_User_Interface/1080p_Serie/Live_Video/) drücken. Wenn Ihre Verbindung zur Kamera mit hohen Latenzzeiten behaftet ist, kann es passieren, dass der Stoppbefehl verloren geht und Ihre Kamera sich bis zum Ende der Reichweite bewegt. Sie können die einstufige Bewegung verwenden, um dies zu verhindern. Ihre Kamera bewegt sich nur einen Schritt mit jedem Klick auf das Steuerfeld - und Sie erhalten die höchste Präzision.' },
        { actionColumn: 'Horizontale Geschwindigkeit', descriptionColumn: 'Stellen Sie die Geschwindigkeit für die horizontale Bewegung Ihrer Kamera ein.' },
        { actionColumn: 'Vertikale Geschwindigkeit', descriptionColumn: 'Stellen Sie die Geschwindigkeit für die vertikale Bewegung Ihrer Kamera ein.' },
        { actionColumn: 'Anzahl der VSCAN-Runden', descriptionColumn: 'Stellen Sie die Anzahl der vertikalen Durchläufe ein, die Ihre Kamera durchführen soll, sobald Sie die Funktion VSCAN starten.' },
        { actionColumn: 'Anzahl der HSCAN-Runden', descriptionColumn: 'Legen Sie die Anzahl der horizontalen Durchläufe fest, die Ihre Kamera ausführen soll, sobald Sie die HSCAN-Funktion starten.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

PTZTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(PTZTable)