import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class RecTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Fotoserie" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Fotoserie auf SD Karte', descriptionColumn: 'Aktivieren, um die geplanten Snapshot Aufnahme auf der internen SD Karte zu speichern. Bitte beachten Sie, dass die Aufnahme nur startet wenn der AUFNAHME ZEITPLAN aktiv ist.' },
        { actionColumn: 'Zeitintervall in Sekunden', descriptionColumn: 'Setzen Sie das Zeitintervall zwischen den Snapshots. Das längste unterstützte Intervall ist 86400 Sekunden - dies würde eine Fotoserie erstellen, bei der 24 Stunden zwischen den beiden aufgenommen Bildern vergangen sind.' },
        { actionColumn: 'Fotoserie zu Email', descriptionColumn: 'Aktivieren Sie die geplante Schnappschuss-Aufzeichnung und versenden Sie diese Schnappschüsse per E-Mail. Bitte beachten Sie, dass die Aufzeichnung nur startet, wenn der Aufzeichnungszeitplan (siehe unten) aktiv ist und Sie zuvor einen SMTP-Server festgelegt haben.' },
        { actionColumn: 'Zeitintervall in Minuten', descriptionColumn: 'Legen Sie das Zeitintervall zwischen zwei Schnappschüssen fest. Das längste unterstützte Intervall beträgt 1440 Minuten - dies würde eine Fotoserie mit 24 Stunden zwischen zwei aufgenommenen Bildern ergeben.' },
        { actionColumn: 'Fotoserie auf FTP server', descriptionColumn: 'Aktivieren Sie die geplante Snapshot-Aufzeichnung und laden Sie diese Snapshots auf einen FTP-Server hoch. Bitte beachten Sie, dass die Aufzeichnung erst dann beginnt, wenn der Aufnahme Zeitplan (s.u.) aktiv ist und ein FTP Server in der Kamera hinterlegt wurde.' },
        { actionColumn: 'Zeitintervall in Sekunden', descriptionColumn: 'Setzen Sie das Zeitintervall zwischen dem Versand der Emails. Das längste unterstützte Intervall ist 1440 Minuten - dies würde eine Fotoserie erstellen, bei der 24 Stunden zwischen den beiden aufgenommen Bildern vergangen sind.' },
        { actionColumn: 'Fester Dateiname', descriptionColumn: 'Legen Sie einen festen Dateinamen fest und überschreiben Sie die Datei bei jedem Upload. Auf diese Weise haben Sie nur eine aktuell hochgeladene Datei auf Ihrem FTP-Server - was ideal ist, wenn Sie diese Datei in Ihre Website einbetten möchten.' },
        { actionColumn: 'Dateiname', descriptionColumn: 'Wählen Sie einen festen Dateinamen für den per FTP hochgeladenen Snapshot (siehe oben).' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

RecTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(RecTable)