import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Updates:">
        <EuiText>
          <ul>
              <li><Link to="/Erweitert/INSTAR_MQTT_Broker/ioBroker/">INSTAR MQTT Broker und ioBroker</Link></li>
              <li><Link to="/Frequently_Asked_Question/ioBroker_as_MQTT_Broker_for_your_Camera/">Verwenden Sie ioBroker als MQTT-Broker anstelle eines MQTT-Clients</Link></li>
              <li><Link to="/Frequently_Asked_Question/INSTAR_MQTT_Alarmserver_ioBroker/">Verwenden Sie den INSTAR MQTT Alarmserver mit ioBroker</Link></li>
              <li><Link to="/Frequently_Asked_Question/INSTAR_MQTT_Alarmserver_Motion_Tracking/">Verwenden Sie den INSTAR MQTT Alarmserver, um eine einfache Objektverfolgung zu erstellen</Link></li>
              <li><Link to="/Frequently_Asked_Question/ioBroker_MQTT_Overwrites_Camera_Settings/">Aktualisieren, wenn Clients eine Verbindung zu Ihrem Broker herstellen</Link></li>
              <li><Link to="/Frequently_Asked_Question/ioBroker_without_MQTT/">Hinzufügen von Kameras, die MQTT nicht unterstützen</Link></li>
              <li><Link to="/Frequently_Asked_Question/ioBroker_and_INSTAR_Alarmserver/">Verwendung des HTTP-Alarmservers anstelle des MQTT-Alarmservers</Link></li>
              <li><Link to="/Frequently_Asked_Question/ioBroker_INSTAR_Camera_Vis_with_Node-RED/">Verwenden des Node-RED-Adapters zum Erstellen einer Visualisierung</Link></li>
              <li><Link to="/Frequently_Asked_Question/Live_Video_and_ioBroker_VIS/">Ein intelligenter Weg, die Live-Videos Ihrer Kameras dem ioBroker Vis hinzuzufügen</Link></li>
              <li><Link to="/Software/Windows/Blue_Iris_v5/ioBroker/">INSTAR MQTT und BlueIris in ioBroker</Link></li>
              <li><Link to="/Frequently_Asked_Question/ioBroker_MQTT_Broker_vs_Client/">ioBroker MQTT Adapter und Sonderzeichen</Link></li>
          </ul>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

