
import React, { useState } from 'react'

import {
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutHeader,
  EuiFlyoutBody,
  EuiButton,
  EuiTitle,
  EuiSpacer
} from '@elastic/eui'

import ProcessorCard from "components/Software/Windows/InstarVision/System_Requirements/processor-load-card";
import MemoryCard from "components/Software/Windows/InstarVision/System_Requirements/memory-load-card";
import NetworkCard from "components/Software/Windows/InstarVision/System_Requirements/network-load-card"

const SysReqs = () => {
  const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);

  const closeFlyout = () => setIsFlyoutVisible(false);

  const showFlyout = () => setIsFlyoutVisible(true);

  let flyout;

  const callOut = (
    <EuiCallOut iconType="help">
      <p>
        Zur besseren Übersicht der benötigten Ressourcen der InstarVision 2.0 haben wir in verschiedenen Szenarien die Software getestet und die Ergebnisse oben dargestellt. Als Test-System haben wir einen Mittelklasse-Rechner mit folgenden Daten genutzt.
      </p>
    </EuiCallOut>
  );

  if (isFlyoutVisible) {
    flyout = (
      <EuiFlyout
        ownFocus
        onClose={closeFlyout}
        size="l"
        aria-labelledby="flyoutWithBannerTitle">
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="m">
            <h2 id="flyoutWithBannerTitle">InstarVision v2</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody banner={callOut}>
          <h2>System Voraussetzung</h2>
          <EuiSpacer />
          <u>Server Setup:</u>
          <EuiSpacer />

          <EuiFlexGroup justifyContent="spaceBetween">
              <EuiFlexItem grow={false}>

                <ul>
                  <li><strong>CPU</strong>: Intel Core i5-2520M 2.50GHz</li>
                  <li><strong>RAM</strong>: 4 GB</li>
                  <li><strong>DISK</strong>: 120 GB SSD</li>
                  <li><strong>OS</strong>: Windows 10 64-Bit</li>
                </ul>

                <p><small>All cameras were connected by Ethernet cable.</small></p>

            </EuiFlexItem>
            <EuiFlexItem grow={false}>

              <u>As bare minimum requirements for 4 HD cameras we recommend:</u>
              <EuiSpacer />
              <ul>
                <li><strong>CPU</strong>: DualCore ASUS eeePC</li>
                <li><strong>RAM</strong>: 2 GB</li>
                <li><strong>DISK</strong>: 250 GB HDD</li>
                <li><strong>OS</strong>: Windows 7 32-Bit</li>
              </ul>

              </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer />
          <hr />
          <EuiSpacer />
          <ProcessorCard />
          <EuiSpacer />
          <hr />
          <EuiSpacer />
          <MemoryCard />
          <EuiSpacer />
          <hr />
          <EuiSpacer />
          <NetworkCard />
        </EuiFlyoutBody>
      </EuiFlyout>
    );
  }
  return (
    <div>
      <EuiSpacer />
      <EuiButton onClick={showFlyout} color="text">Systemanforderungen</EuiButton>
      {flyout}
    </div>
  );
};

export default SysReqs