import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class PrivacyTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Privatsphäre Einstellungen" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Bereiche', descriptionColumn: 'Klicken Sie auf den Radio Button hinter einem Bereich (1-4), den Sie editieren wollen. Bewegen Sie den Mauszeiger über das Bild und halten Sie die linke Maustaste gedrückt, um ihre Maske zu zeichnen. Um eine weitere Maske einzuzeichnen, aktivieren Sie den Bereich 2 und wiederholen Sie den Schritt.' },
        { actionColumn: 'Aktiv', descriptionColumn: 'Checken Sie die Aktiv-Box um den jeweiligen Bereich zu aktivieren. Der Bereich wird im Live-Video, in die Videoaufnahmen als auch in den Snapshots ausgeschwärzt.' },
        { actionColumn: 'Ganzer Bereich', descriptionColumn: 'Maximiert den ausgewählten Bereich auf die volle Bildgröße.' },
        { actionColumn: 'Reset', descriptionColumn: 'Setzt alle Bereich auf die Standardeinstellungen zurück.' },
        { actionColumn: 'Aktive Geräuschunterdrückung (ANR)', descriptionColumn: 'Aktivieren Sie die aktive Rauschunterdrückung für die Tonaufnahme Ihrer Kamera.' },
        { actionColumn: 'ANR-Stufe', descriptionColumn: 'Legen Sie den Prozentsatz der Rauschunterdrückung fest, der verwendet werden soll.' },
        { actionColumn: 'Aktive Echounterdrückung (AEC)', descriptionColumn: 'Aktivieren Sie die aktive Echounterdrückung für die Audioaufnahme Ihrer Kamera.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

PrivacyTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(PrivacyTable)