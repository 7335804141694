import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Hinweise für die Installation">
        <EuiText>
        <ul>
            <li>Die Powerline Kommunikation wird nur erfolgreich sein, wenn beide IN-LAN Adapter (am Router und an der Kamera) an Steckdosen angeschlossen werden, die an der gleichen Phase anliegen. Der "Sprung" in nächste Stockwerk ist häufig nicht möglich, da sich dort meist Sicherungskästen dazwischen befinden.</li>
            <li>Die Kommunikation kann auf alten Stromleitungen gestört werden. Die Qualität der Powerlineanbindung in Altbauten ist häufig ungenügend.</li>
            <li>Stecken Sie den IN-LAN Adapter nach Möglichkeit direkt in eine Wandsteckdose - vermeiden Sie Mehrfachsteckdosen und Verlängerungskabel. Wenn Sie am Installationsort bereits eine Mehrfachsteckdose betreiben, ist es empfohlen den IN-LAN 500p Adapter an der Wandsteckdose anzuschliessen und die Mehrfachsteckdose am IN-LAN Adapter.</li>
            <li>Bei der Verwendung eines IN-LAN 500p Adapters sollte das Netzteil des Endgerätes (die Kamera) direkt am IN-LAN Adapter angeschlossen werden und nicht an einer separaten Steckdose.</li>
        </ul>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

