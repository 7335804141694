import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1080p_Serie/System/Info/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1080p_SearchThumb_System_Overview.webp'
                    alt="System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Info"
              description="Info ist einfach eine Sammlung von vielen Systeminterna, die interessant sind, wenn Sie mit der Fehlersuche in Ihrer Einrichtung beginnen müssen. Ihr DDNS-Zugang funktioniert nicht? ..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1080p_Serie/System/Info/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1080p_Serie/System/Benutzer/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1080p_SearchThumb_System_User.webp'
                    alt="System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Benutzerverwaltung"
              description="Ihre Kamera wird mit 3 Benutzerklassen - Administrator, Benutzer und Besucher - und einem Passwort für alle - instar - eingerichtet. Bitte ändern Sie dieses so schnell wie..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1080p_Serie/System/Benutzer/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1080p_Serie/System/WebUI/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1080p_SearchThumb_System_WebUI.webp'
                    alt="System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="WebUI"
              description="Aktivieren Sie ein dunkles Theme für die Web-Benutzeroberfläche Ihrer Kamera. Und passen Sie die Schnellzugriffsschaltflächen an, die rechts neben dem Live-Video-Bildschirm verfügbar sind ..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1080p_Serie/System/WebUI/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1080p_Serie/System/Datum_Uhrzeit/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1080p_SearchThumb_System_Date_Time.webp'
                    alt="System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Datum & Uhrzeit"
              description="Ihre Kamera kontaktiert automatisch einen sogenannten NTP-Server, um die interne Uhr zu stellen. Es wird empfohlen, diese Funktion immer eingeschaltet zu lassen..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1080p_Serie/System/Datum_Uhrzeit/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1080p_Serie/System/Sprache/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1080p_SearchThumb_System_Language.webp'
                    alt="System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Sprachauswahl"
              description="Ändern Sie die Sprache der Web-Bedienoberfläche auf Deutsch, Englisch, Französisch oder Chinesisch. Die Benutzeroberfläche stellt die Sprache automatisch entsprechend der Sprache..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1080p_Serie/System/Sprache/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1080p_Serie/System/Log/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1080p_SearchThumb_System_Log.webp'
                    alt="System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="System Log"
              description="Funktioniert etwas nicht mehr? Prüfen Sie die Systemprotokolldatei auf Informationen. Sie erhalten keine Alarm-E-Mails mehr, aber Ihre SMTP-Einstellungen sehen in Ordnung aus? Prüfen Sie das Log..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1080p_Serie/System/Log/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1080p_Serie/System/Update/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1080p_SearchThumb_System_Update.webp'
                    alt="System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Software Update"
              description="Die INSTAR-Softwareabteilung arbeitet ständig daran, Ihre Erfahrungen mit unserem Produkt zu verbessern. Sie können den Update-Bereich von Zeit zu Zeit überprüfen, um sicherzustellen, dass Ihr System..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1080p_Serie/System/Update/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1080p_Serie/System/Neustarten/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1080p_SearchThumb_System_Reboot.webp'
                    alt="System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Neustarten"
              description="Verwenden Sie diese Funktion, um Ihre Kamera sicher neu zu starten oder zurückzusetzen. Es wird nicht empfohlen, Ihre Kamera einfach auszustecken/neu einzustecken oder den Hardware-Reset unnötig zu verwenden..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1080p_Serie/System/Neustarten/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1080p_Serie/System/Werksreset/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1080p_SearchThumb_System_Reset.webp'
                    alt="System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Werksreset"
              description="Verwenden Sie diese Funktion, um Ihre Kamera sicher neu zu starten oder zurückzusetzen. Es wird nicht empfohlen, Ihre Kamera einfach auszustecken/neu einzustecken oder den Hardware-Reset unnötig zu verwenden..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1080p_Serie/System/Werksreset/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
