import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class DDNSTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "DDNS Einstellungen" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'INSTAR DDNS aktivieren', descriptionColumn: 'Um auf die Weboberfläche Ihrer Kamera zuzugreifen müssen Sie Ihre INSTAR DDNS Adresse aktivieren. Bitte beachten Sie, dass Ihre Kamera auch nach dem Deaktivieren der DDNS Adresse noch für eine Weile unter dieser Adresse erreichbar ist. Die Adresse wird blockiert, sobald Ihr Internet Provider Ihre öffentliche IP-Adresse ändert - dies passiert in der Regel alle 24h oder in manchen Fällen, nachdem Sie Ihren Router neugestartet haben.' },
        { actionColumn: 'INSTAR DDNS Adresse', descriptionColumn: 'Dies ist Ihre persönliche Internet Adresse. Sie können Ihre Kamera über diese Adresse erreichen, sobald Sie den HTTP Port weitergeleitet haben.' },
        { actionColumn: 'QR Code', descriptionColumn: 'Um Ihre Kamera auf Ihrem iPhone, iPad, Android, Windows Phone oder in Windows Metro hinzuzufügen, nutzen Sie unsere App und scannen einfach den QR Code.' },
        { actionColumn: 'Drittanbieter DDNS verwenden', descriptionColumn: '_(Optional)_ Wenn Sie die INSTAR DDNS Adresse nicht verwenden möchten, fügen Sie eine Drittanbieter DDNS hinzu. Bitte kontaktieren Sie Ihren Drittanbieter für mehr Details.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

DDNSTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(DDNSTable)