import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Alarm Server">
        <EuiText>
          <p>
            Die Google Home-App Die Google Home-App hilft Ihnen beim Einrichten und Steuern von Google Nest- oder Home-Lautsprechern und -Displays sowie von Chromecast. Sie können Tausende von kompatiblen Lampen, Kameras, Lautsprechern und mehr steuern. Sie können auch Ihre Erinnerungen und aktuellen Benachrichtigungen überprüfen - alles über eine einzige App. Die Google Home-App ist für Android-Smartphones und -Tablets sowie für iPhones und iPads verfügbar.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

