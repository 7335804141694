import React from 'react'
import {Link} from 'gatsby'

import { EuiAvatar, EuiButtonIcon, EuiText, EuiBadge, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import {
  EuiCommentList,
} from '@elastic/eui'

import homeKitImage from 'images/Products/Homekit/Homekit_1080p_Smartphone_01.webp'
import alexaImage from 'images/Products/Alexa/INSTAR_Cloud_Alexa_Skill_00.webp'

const alexaRelease = (
  <EuiFlexGroup responsive={false} alignItems="center" gutterSize="s">
    <EuiFlexItem grow={false}>Alexa Kamera Skill</EuiFlexItem>
    <EuiFlexItem grow={false}>
      <EuiBadge color="primary">Firmware Update</EuiBadge>
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <EuiBadge color="warning">Full HD Serie</EuiBadge>
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <EuiBadge color="default">INSTAR Cloud</EuiBadge>
    </EuiFlexItem>
  </EuiFlexGroup>
);

const annotation = (
  <EuiButtonIcon
    title="Annotation"
    aria-label="Annotation"
    color="subdued"
    iconType="annotation"
  />
);

const timeLine = (
  <EuiButtonIcon
    title="Git Commit"
    aria-label="Git Commit"
    color="subdued"
    iconType="timeline"
  />
);

const alexaAd = (
  <EuiText size="s">
    <h2>Kontrollieren Sie Ihre Kamera mit Alexa Endgerät</h2>
    <img alt="INSTAR Alexa Support" src={alexaImage} style={{ maxWidth: '100%', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px'}} />
    <p>
      Wir bieten Ihnen im Alexa Skill Store sowohl einen <Link to="/Bewegungserkennung/INSTAR_Cloud/Alexa_Cloud_Skills/">INSTAR Cloud Skill</Link> als auch einen <Link to="/Web_User_Interface/1080p_Serie/Smarthome/Alexa_Skill/">INSTAR Camera Skill</Link> an. Für die INSTAR Cloud Skill benötigen Sie nur ein INSTAR Cloud-Konto und ein Alexa-Gerät mit einem Display. Fügen Sie die Skill auf Ihrem Alexa-Gerät hinzu und nutzen Sie es direkt.
    </p>
  </EuiText>
);

const mPol = (
  <EuiFlexGroup responsive={false} alignItems="center" gutterSize="s">
    <EuiFlexItem grow={false}>
      <EuiAvatar
        imageUrl="/de/images/Assistants/agentsmith.webp"
        size="m"
        name="Mike Polinowski"
      />
    </EuiFlexItem>
    <EuiFlexItem grow={false}>Mike Polinowski</EuiFlexItem>
  </EuiFlexGroup>
);

const homekitRelease = (
  <EuiFlexGroup responsive={false} alignItems="center" gutterSize="s">
    <EuiFlexItem grow={false}>Apple Homekit</EuiFlexItem>
    <EuiFlexItem grow={false}>
      <EuiBadge color="primary">Firmware Update</EuiBadge>
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <EuiBadge color="warning">Full HD Serie</EuiBadge>
    </EuiFlexItem>
  </EuiFlexGroup>
);

const homekitAd = (
  <EuiText size="s">
    <h2>Kontrollieren Sie Ihre Kamera mit Ihrem iPhone oder iPad</h2>
    <img alt="INSTAR Homekit Support" src={homeKitImage} style={{ maxWidth: '100%', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px'}} />
    <p>
      Die Anbindung an den <Link to="/Web_User_Interface/1080p_Serie/Smarthome/Homekit/">Homekit Dienst</Link> steht ab der Firmware Version <code>3.2(361)</code> (<em>Juni 2021</em>) für alle Full HD Modelle zur Verfügung. Sie können die Firmware Ihrer Kamera direkt über die <Link to="/Schnell_Installation/WebUI_und_Firmware_Upgrade/">Weboberfläche aktualisieren</Link>.
    </p>
  </EuiText>
);

const instarAvatar = (
  <EuiAvatar
    imageUrl="/de/images/Assistants/INSTAR-Ico.webp"
    size="l"
    name="INSTAR Deutschland GmbH"
  />
);

const comments = [
  {
    username: mPol,
    type: 'update',
    actions: timeLine,
    event: alexaRelease,
    timestamp: 'September 9, 2020',
    timelineIcon: 'tag',
  },
  {
    username: 'INSTAR Deutschland GmbH',
    event: 'INSTAR Alexa Kamera Skill',
    children: alexaAd,
    actions: annotation,
    timestamp: 'September 10, 2020',
    timelineIcon: instarAvatar,
  },
  {
    username: mPol,
    type: 'update',
    actions: timeLine,
    event: homekitRelease,
    timestamp: 'Mai 10, 2021',
    timelineIcon: 'tag',
  },
  {
    username: 'INSTAR Deutschland GmbH',
    event: 'Apple Homekit',
    children: homekitAd,
    actions: annotation,
    timestamp: 'Mai 10, 2021',
    timelineIcon: instarAvatar,
  },
];

export default () => <EuiCommentList comments={comments} />;