import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class AudioTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Audio Einstellungen" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Audio aktivieren', descriptionColumn: 'Aktiveren oder deaktiveren des Kamera Audio. Dies deaktiviert beides, sowohl den Kamera Input als auch den Kamera Output.' },
        { actionColumn: 'Audio Output Lautstärke', descriptionColumn: 'Hier können Sie die Audiolautstärke für die internen oder optional externen Lautsprecher einstellen.' },
        { actionColumn: 'Audio Input Lautstärke', descriptionColumn: 'Die Audio Input Lautstärke regelt das interne oder (optional) externe Mikrofon an Ihrer Kamera. Bitte denken Sie daran, dass die Lautstärke indirekt die Sensibilität Audioerkennung beeinflusst.' },
        { actionColumn: 'Akustisches Signal aktivieren', descriptionColumn: 'Sie können ein akustisches Signal aktiveren, das in einem Alarmfall abgespielt wird. Alarm Events können bei einer internen Bewegungserkennung und der Audioerkennung ausgelöst werden. Bitten denken Sie daran, dass die Audioerkennung möglicherweise durch das akustische Signal ausgelöst werden kann!' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

AudioTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(AudioTable)