import React from 'react'
import { EuiImage, EuiText } from '@elastic/eui'

import ivImage from "../../../../images/Software/Windows/InstarVision/Image003.png"




export default () => (
  <EuiText>
    <EuiImage
      size="l"
      float="left"
      margin="l"
      hasShadow
      caption="InstarVision v3 for Windows"
      allowFullScreen
      alt="InstarVision v3 for Windows"
      src={ivImage}
    />
    <p>Sobald die Software registriert ist, wird dieser Dialog nicht mehr angezeigt werden beim Programmstart. Sie können ihn aber im Erweitert Menü manuell aufrufen. Sollten Sie Ihre Installation erweitern wollen, können Sie Ihren alten Lizenzschlüssel einfach löschen, den neuen hineinkopieren und auf Online Registrieren klicken.</p>
  </EuiText>
);
