import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Andere_Plattform/InstarVision_Blackberry/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_InstarVision_Android.webp'
                    alt="InstarVision für Blackberry"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="InstarVision für Blackberry"
              description="Unsere InstarVision App für Blackberry. Steuern Sie Ihre Kamera von Ihrem BlackBerry-Smartphone aus, wenn Sie keinen Zugriff auf Ihren PC haben."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Andere_Plattform/InstarVision_Blackberry/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Andere_Plattform/Synology/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Synology.webp'
                    alt="Synology Surveillance Station"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Synology Surveillance Station"
              description="Alle INSTAR IP-Kameras werden von der Synology Surveillance Station unterstützt. Verwalten Sie alle Ihre Kameras und Videoaufnahmen."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Andere_Plattform/Synology/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Andere_Plattform/AVM_Fritzphone/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_AVM.webp'
                    alt="AVM Fritzphone"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="AVM Fritzphone"
              description="Nutzen Sie Ihr AVM Fritzphone, um das Live-Video Ihrer Kamera zu überprüfen - richten Sie es über die Benutzeroberfläche Ihrer AVM Fritzbox ein."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Andere_Plattform/AVM_Fritzphone/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Andere_Plattform/Athom_Homey/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Homey.webp'
                    alt="Athom Homey"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Athom Homey"
              description="Verwenden Sie die Athom Homey Basisstation, um Ihre INSTAR-Kamera mit drahtlosen Zigbee-, Z-Wave- oder IR-Sensoren, Signalgebern und Smart Buttons interagieren zu lassen. Automatisieren Sie den Betrieb Ihrer Kameras in einer Smarthome-Umgebung."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Andere_Plattform/Athom_Homey/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Andere_Plattform/Homematic_IP/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_Homematic.webp'
                    alt="Homematic CCU3"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Homematic CCU3"
              description="Um Ihre Homematic- oder Homematic IP-Geräte, wie z. B. Ihre INSTAR IP-Kamera, steuern, konfigurieren und in zentralen Programmen nutzen zu können, müssen Sie die Geräte über die WebUI in das System einbinden."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Andere_Plattform/Homematic_IP/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Andere_Plattform/Loxone_Miniserver/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_Loxone.webp'
                    alt="Loxone Miniserver"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Loxone Miniserver"
              description="Der Homeautomation Miniserver von Loxone verbindet alle smarten Komponenten in Ihrem Haus oder Ihrer Wohnung. Alle Stränge laufen zum Miniserver zusammen, um die Kommunikation zwischen den einzelnen Komponenten zu ermöglichen: Licht, Beschattung, Heizung, Taster sowie Ihre INSTAR IP-Überwachungskamera."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Andere_Plattform/Loxone_Miniserver/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Andere_Plattform/Lupus_XT2_Plus/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_Loxone.webp'
                    alt="Lupus XT2 Plus"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Lupus XT2 Plus"
              description="Integrieren Sie Videoüberwachungssysteme wie IP-Kameras von LUPUS und vielen anderen Herstellern einfach in die LUPUSEC Bedienoberfläche. Einfach! Und sicher - garantiert."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Andere_Plattform/Lupus_XT2_Plus/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Andere_Plattform/Homee/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_homee.webp'
                    alt="homee"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="homee"
              description="Verwenden Sie die homee-Basisstation, um Ihre INSTAR-Kamera mit drahtlosen Zigbee-, Z-Wave- oder EnOcean-Sensoren, Signalgeräten und Smart Buttons interagieren zu lassen. Automatisieren Sie den Kamerabetrieb in einem Smarthome."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Andere_Plattform/Homee/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Andere_Plattform/Homee_and_Node-RED/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_homee.webp'
                    alt="homee und Node-RED"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="homee und Node-RED"
              description="Diesmal wollen wir mit Node-RED ein virtuelles homee erstellen, der direkt mit der MQTT-Schnittstelle unserer Kamera verbunden ist. Dieses virtuelle Gerät wird dann von unserem realen homee gesteuert."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Andere_Plattform/Homee_and_Node-RED/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Andere_Plattform/QNAP/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_QNAP.webp'
                    alt="QNAP Surveillance Station"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="QNAP Surveillance Station"
              description="Alle INSTAR IP-Kameras werden von der QNAP Surveillance Station unterstützt. Verwalte alle deine Kameras und Videoaufnahmen."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Andere_Plattform/QNAP/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Home_Automation/Logitech_Harmony/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Harmony.webp'
                    alt="Logitech Harmony"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Logitech Harmony"
              description="Logitech Harmony ist eine universelle Fernbedienung, die so programmiert werden kann, dass sie nicht nur mit Ihrem Fernseher, sondern auch mit Ihren Smarthome-Komponenten kommuniziert."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Home_Automation/Logitech_Harmony/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Andere_Plattform/Vivre_Motion_Stream_Deck/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Stream_Deck.webp'
                    alt="Stream Deck Vivre Motion"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Stream Deck Vivre Motion"
              description="Mit der VivreMotion Stream Deck IP-CAMERA Plugin App können Sie das Live-Bild Ihrer INSTAR IP-Kamera direkt auf eine Schaltfläche legen und sogar den Ausschnitt auswählen."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Andere_Plattform/Vivre_Motion_Stream_Deck/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Andere_Plattform/biOs_MQTT_Stream_Deck/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Stream_Deck.webp'
                    alt="Stream Deck biOs MQTT"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Stream Deck biOs MQTT"
              description="Mit dem biOs Stream Deck MQTT Plugin können Sie das Ihre INSTAR IP-Kamera direkt auf eine Stream Deck Button kontrollieren."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Andere_Plattform/biOs_MQTT_Stream_Deck/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Erweitert/Alexa_Echo5_and_Monocle_Gateway/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_Alexa.webp'
                    alt="Alexa and Monocle Gateway"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Alexa und Monocle Gateway für den Echo 5"
              description="Um auf die Kameras (HD und FullHD Serie) z.B. mit einem Echo Show 5 zuzugreifen ist es notwendig das man von Monocle den Monocle-Gateway verwendet. Der Gateway erstellt eine Schnittstelle so dass Alexa Geräte auf die Kameras zugreifen können."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Erweitert/Alexa_Echo5_and_Monocle_Gateway/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Andere_Plattform/Amazon_Alexa_Skill_via_Monocle/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_Alexa.webp'
                    alt="Alexa Skill (Monocle)"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Amazon Alexa Skill (Monocle)"
              description="Binden Sie Ihre Kamera in Amazons Alexa ein, und lassen Sie sich so mit Sprachbefehlen das aktuelle Videobild auf Ihrem Alexa Echo Spot oder Show anzeigen."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Andere_Plattform/Amazon_Alexa_Skill_via_Monocle/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Andere_Plattform/IP_Symcon/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Symcon.webp'
                    alt="IP Symcon"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IP Symcon Smarthome"
              description="Die IP-Symcon-Software ist die Komplettlösung im Bereich der Hausautomation die mit allen INSTAR HD- und Full HD-IP-Kameras kompatibel ist. Eine Vielzahl von Assistenten und Tools ermöglichen eine komfortable Konfiguration."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Andere_Plattform/IP_Symcon/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Andere_Plattform/Domovea/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Hager.webp'
                    alt="Domovea"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Domovea"
              description="Fügen Sie Ihre INSTAR IP-Kamera Ihrem Domovea Hausautomationssystem hinzu. Fügen Sie einfach unsere EXCAM-Dateien hinzu..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Andere_Plattform/Domovea/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Andere_Plattform/Hager_Domovea/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Hager.webp'
                    alt="Domovea"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Hager Domovea v2"
              description="Die intelligente Gebäudetechnik entwickelt sich rasant weiter. Das bedeutet: komplexer werdende Technik und unterschiedliche Systeme unter ein Dach zu bringen. Mit domovea von Hager geht das ganz einfach und nur mit einem einzigen Baustein! domovea ist die einfache, vielseitige und effiziente Smart-Home-Lösung für Wohn- und Zweckbau."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Andere_Plattform/Hager_Domovea/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Andere_Plattform/ASUSTOR_Surveillance_Center/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Hager.webp'
                    alt="ASUSTOR"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="ASUSTOR Surveillance Center"
              description="Nutzen Sie das ASUSTOR Surveillance Center Web Benutzeroberfläche zur Einbindung, Verwaltung und Einrichtung der Videoaufzeichnung Ihrer INSTAR-IP-Kameras."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Andere_Plattform/ASUSTOR_Surveillance_Center/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
