import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Excursus">
        <EuiText>
          <p>Bitte beachten Sie, dass die ersten drei Zahlen den IP Subnetz des Routers definieren. Im obigen Beispiel wurde das Standard Subnetze der Fritz!Box verwendet. Ein andereres Beispiel ist der Speedport von T-Mobile, dieser verwendet standardmäßig das IP Subnetz 192.168.2.x. Die meisten anderen Router verwenden den IP Raum 192.168.1.x. Hierbei ist zu beachten, dass das x für die Zahlen 1 bis 254 stehen kann und die 1 i.d.R. den Router adressiert.</p>
          <p>Wir haben im obigen Beispiel die 201 für die Kamera verwendet, da es im allgemeinen besser ist eine IP Adresse zu verwenden welche nicht vom Router reserviert wird. Der Router reserviert einen bestimmten Bereich des IP Raumes für DHCP. Sollte es einmal doch zu Problemen kommen, können Sie einfach einmal versuchen, mit unserem <a href="/de/Schnell_Installation/Alternative_IP_Scanner/">Camera Tool</a>, eine IP Adresse in einem anderen Bereich zu verwenden.</p>
          <p>Sollte Sie einmal nicht sicher sein, wie die IP Adresse Ihres Routers lautet so können Sie diese einfach testen. Geben Sie die vermeintliche IP Adresse Ihres Routers in den Web Browsers Ihres Vertrauens ein, wenn es die Richtige ist, sollten Sie auf die Weboberfläche Ihres Routers kommen. Wenn Ihr PC mit dem Router verbunden ist, können Sie die IP Ihres Routers auch über den Befehl __ipconfig__ ausfindig machen. Tippen Sie diesen einfach in die MS-Dos Kommandozeile ein und Sie werden die IP Adresse Ihres Standardateways angezeigt bekommen. Ihr Standard-Gateways in die Welt des Internets ist aber eben Ihr Router und somit ist diese IP Adresse identisch mit der Ihres Routers.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

