import React from 'react'
import { Link } from 'gatsby'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

import downloadsIVWinSearchIcon from '../../../images/Search/P_SearchThumb_InstarVision_Windows.webp'
import downloadsSearchIcon from '../../../images/Search/D_Downloads_SearchThumb.webp'
import downloadsIVMetroSearchIcon from '../../../images/Search/P_SearchThumb_InstarVision_Metro.webp'


export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Desktop_Software/InstarVision/">
                  <img
                    src={downloadsIVWinSearchIcon}
                    alt="InstarVision for Windows"
                  />
                </Link>
              }
              title="InstarVision für Windows"
              description="InstarVision (1.x / 2.0) IP Surveillance Station für Windows. Verwalten Sie bis zu 64 IP-Kameras und deren Aufzeichnungen mit Ihrem Computer."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Desktop_Software/InstarVision/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Desktop_Software/InstarVision_for_macOS/">
                  <img
                    src={downloadsIVWinSearchIcon}
                    alt="InstarVision for macOS"
                  />
                </Link>
              }
              title="InstarVision für macOS"
              description="InstarVision (1.x / 2.0) IP Surveillance Station für macOS. Verwalten Sie bis zu 64 IP-Kameras und deren Aufzeichnungen mit Ihrem Computer."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Desktop_Software/InstarVision_for_macOS/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Desktop_Software/Instar_Camera_Tool/">
                  <img
                    src={downloadsSearchIcon}
                    alt="Instar Camera Tool"
                  />
                </Link>
              }
              title="Instar Camera Tool"
              description="Das INSTAR Camera Tool zur Suche nach Ihrer Kamera im Netzwerk. Ändern Sie die IP-Konfiguration, aktualisieren Sie die Software und greifen Sie auf die SD-Karte der Kamera zu."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Desktop_Software/Instar_Camera_Tool/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Desktop_Software/InstarVision_Metro/">
                  <img
                    src={downloadsIVMetroSearchIcon}
                    alt="InstarVision (Metro)"
                  />
                </Link>
              }
              title="InstarVision (Metro)"
              description="Unsere kostenlose Windows Metro App für Windows 8/8.1 und Windows 10. Nutzen Sie die Leistungsfähigkeit unserer Windows Phone App auf Ihrem Windows Desktop Computer."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Desktop_Software/InstarVision_Metro/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
