import React from 'react'
import {Link} from 'gatsby'

import {
  EuiCard,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import browserExplorer from "../../../images/Quick_Installation/How_To_Clear_Your_Browsing_History/Icon_Internet-Explorer-10.webp"
import browserFirefox from "../../../images/Quick_Installation/How_To_Clear_Your_Browsing_History/Icon_Firefox.webp"
import browserChromium from "../../../images/Quick_Installation/How_To_Clear_Your_Browsing_History/Icon_Chromium.webp"
import browserOpera from "../../../images/Quick_Installation/How_To_Clear_Your_Browsing_History/Icon_Opera.webp"
import browserEdge from "../../../images/Quick_Installation/How_To_Clear_Your_Browsing_History/Icon_Edge.webp"
import browserVivaldi from "../../../images/Quick_Installation/How_To_Clear_Your_Browsing_History/Icon_Vivaldi.webp"


export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Schnell_Installation/Leeren_des_Browserverlaufs/Internet_Explorer/">
                  <img
                    src={browserExplorer}
                    alt="Löschen des Browservelraufs in Internet Explorer"
                  />
                </Link>
              }
              title='Löschen des Browservelraufs in Internet Explorer'
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Schnell_Installation/Leeren_des_Browserverlaufs/Internet_Explorer/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Schnell_Installation/Leeren_des_Browserverlaufs/Mozilla_Firefox/">
                  <img
                    src={browserFirefox}
                    alt="Löschen des Browservelraufs in Mozilla Firefox"
                  />
                </Link>
              }
              title='Löschen des Browservelraufs in Mozilla Firefox'
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Schnell_Installation/Leeren_des_Browserverlaufs/Mozilla_Firefox/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Schnell_Installation/Leeren_des_Browserverlaufs/Google_Chromium/">
                  <img
                    src={browserChromium}
                    alt="Löschen des Browservelraufs in Chrome"
                  />
                </Link>
              }
              title='Löschen des Browservelraufs in Chrome'
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Schnell_Installation/Leeren_des_Browserverlaufs/Google_Chromium/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Schnell_Installation/Leeren_des_Browserverlaufs/Opera/">
                  <img
                    src={browserOpera}
                    alt="Löschen des Browservelraufs in Opera"
                  />
                </Link>
              }
              title='Löschen des Browservelraufs in Opera'
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Schnell_Installation/Leeren_des_Browserverlaufs/Opera/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Schnell_Installation/Leeren_des_Browserverlaufs/Vivaldi/">
                  <img
                    src={browserVivaldi}
                    alt="Löschen des Browservelraufs in Vivaldi"
                  />
                </Link>
              }
              title='Löschen des Browservelraufs in Vivaldi'
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Schnell_Installation/Leeren_des_Browserverlaufs/Vivaldi/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Schnell_Installation/Leeren_des_Browserverlaufs/Microsoft_Edge/">
                  <img
                    src={browserEdge}
                    alt="Löschen des Browservelraufs in Edge"
                  />
                </Link>
              }
              title='Löschen des Browservelraufs in Edge'
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Schnell_Installation/Leeren_des_Browserverlaufs/Microsoft_Edge/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
