import React from 'react'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiCard
} from '@elastic/eui'


export default function QRDownloads() {

  return (
    <EuiFlexGroup gutterSize="l">
    <EuiFlexItem>
      <EuiCard
        textAlign="left"
        href="https://apps.apple.com/us/app/instarvision/id413109553"
        image="/en/images/Downloads/Qrcode_iOS-AppStore_iPad.webp"
        title={'InstarVision für iPhone'}
        description="Laden Sie die InstarVision iPhone App aus dem Apple Store herunter."
      />
    </EuiFlexItem>
    <EuiFlexItem>
      <EuiCard
        textAlign="left"
        href="https://apps.apple.com/us/app/instarvision-for-ipad/id767539403"
        image="/en/images/Downloads/Qrcode_iOS-AppStore_iPad.webp"
        title={'InstarVision für iPad'}
        description="Laden Sie die InstarVision iPad App aus dem Apple Store herunter."
      />
    </EuiFlexItem>
  </EuiFlexGroup>
  );
}