import React from 'react'
import {Link} from 'gatsby'

import {
  EuiAccordion,
  EuiText,
  EuiSpacer,
  EuiImage,
  EuiPanel
} from '@elastic/eui'


import Applets01 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-01.webp'
import Applets02 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-02.webp'
import Applets02Set1 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-02-set-1.webp'
import Applets02Set2 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-02-set-2.webp'
import Applets03 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-03.webp'
import Applets03Set1 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-03-set-1.webp'
import Applets03Set2 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-03-set-2.webp'
import Applets04 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-04.webp'
import Applets04Set1 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-04-set-1.webp'
import Applets04Set2 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-04-set-2.webp'
import Applets05 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-05.webp'
import Applets05Set from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-05-set-1.webp'
import Applets06 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-06.webp'
import Applets07 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-07.webp'
import Applets07Set from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-07-set-1.webp'
import Applets08 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-08.webp'
import Applets08Set from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-08-set-1.webp'
import Applets09 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-09.webp'
import Applets09Set from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-09-set-1.webp'
import Applets10 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-10.webp'
import Applets11 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-11.webp'
import Applets11Set from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-11-set-1.webp'
import Applets12 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-12.webp'
import Applets12Set1 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-12-set-1.webp'
import Applets12Set2 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-12-set-2.webp'
import Applets13 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-13.webp'
import Applets13Set1 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-13-set-1.webp'
import Applets13Set2 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-13-set-2.webp'
import Applets14 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-14.webp'
import Applets14Set from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-14-set-1.webp'
import Applets15 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-15.webp'
import Applets15Set from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-15-set-1.webp'
import Applets16 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-16.webp'
import Applets16Set from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-16-set-1.webp'
import Applets17 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-17.webp'
import Applets17Set from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-17-set-1.webp'
import Applets18 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-18.webp'
import Applets18Set from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-18-set-1.webp'
import Applets19 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-19.webp'
import Applets19Set from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-19-set-1.webp'
import Applets20 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-20.webp'
import Applets20Set from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-20-set-1.webp'
import Applets21 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-21.webp'
import Applets21Set from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-21-set-1.webp'
import Applets22 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-22.webp'
import Applets22Set from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-22-set-1.webp'
import Applets23 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-23.webp'
import Applets23Set from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-23-set-1.webp'
import Applets24 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-24.webp'
import Applets24Set from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-24-set-1.webp'
import Applets25 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-25.webp'
import Applets25Set from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-25-set-1.webp'
import Applets26 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-26.webp'
import Applets26Set from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-26-set-1.webp'
import Applets27 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-27.webp'
import Applets27Set from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet-27-set-1.webp'


function AppletAccordion() {
  return (
    <React.Fragment>
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion1"
          buttonContent="Get an email when a new INSTAR Applet is published"
          paddingSize="l">
          <EuiPanel color="subdued">
            <EuiText style={{height: 500}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Eine E-Mail erhalten, wenn ein neues INSTAR Applet veröffentlicht wird"
                src={Applets01}
              />
              <p>Eine E-Mail erhalten, wenn ein neues INSTAR Applet veröffentlicht wird:</p>
              <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
            </EuiText>
            </EuiPanel>
          </EuiAccordion>
        </EuiPanel>
  
      <EuiSpacer />
  
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion2"
          buttonContent="Set a time schedule for your alarm areas"
          buttonContentClassName="eui-textTruncate"
          paddingSize="l">
          <EuiPanel color="subdued">
            <EuiText style={{height: 800}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Set a time schedule for your alarm areas"
                src={Applets02}
              />
              <p>This app allows you to define time windows in which your alarm areas are switched on or off:</p>
              <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
                <EuiSpacer size="xl" />
                <EuiImage
                  size="l"
                  float="right"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="IFTTT Set a time schedule for your alarm areas"
                  src={Applets02Set1}
                />
                <EuiImage
                  size="l"
                  float="right"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="IFTTT Set a time schedule for your alarm areas"
                  src={Applets02Set2}
                />
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
  
      <EuiSpacer />
  
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion3"
          buttonContent="Turn to the alarm position when I leave"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 800}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Turn to the alarm position when I leave"
                src={Applets03}
              />
              <p>Turn the camera to a preset position, when I leave home:</p>
              <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
                <EuiSpacer size="xl" />
                <EuiImage
                  size="l"
                  float="right"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="IFTTT Turn to the alarm position when I leave"
                  src={Applets03Set1}
                />
                <EuiImage
                  size="l"
                  float="right"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="IFTTT Turn to the alarm position when I leave"
                  src={Applets03Set2}
                />
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
  
      <EuiSpacer />
  
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Turn away when I arrive home"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 800}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Turn away when I arrive home"
                src={Applets04}
              />
              <p>Turn the camera to preset position, when I arrive home:</p>
              <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
                <EuiSpacer size="xl" />
                <EuiImage
                  size="l"
                  float="right"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="IFTTT Turn away when I arrive home"
                  src={Applets04Set1}
                />
                <EuiImage
                  size="l"
                  float="right"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="IFTTT Turn away when I arrive home"
                  src={Applets04Set2}
                />
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
  
      <EuiSpacer />
  
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Activate alarm when my phone disconnects from local WiFi"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 500}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Activate alarm when my phone disconnects from local WiFi"
                src={Applets05}
              />
              <p>Turn the camera alarm on when my phone disconnects from my home wireless network.</p>
              <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
                <EuiSpacer size="xl" />
                <EuiImage
                  size="l"
                  float="right"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="IFTTT Activate alarm when my phone disconnects from local WiFi"
                  src={Applets05Set}
                />
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
  
      <EuiSpacer />
  
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Alarm trigger when a door is opened"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 500}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Alarm trigger when a door is opened"
                src={Applets06}
              />
              <p>Trigger an alarm and cloud/ftp video upload when a door is opened.</p>
              <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
  
      <EuiSpacer />
  
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Arm my camera alarm when I leave"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 500}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Arm my camera alarm when I leave"
                src={Applets07}
              />
              <p>Turn on your INSTAR cameras alarm when you leave an area.</p>
              <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
                <EuiSpacer size="xl" />
                <EuiImage
                  size="l"
                  float="right"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="IFTTT Arm my camera alarm when I leave"
                  src={Applets07Set}
                />
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
  
      <EuiSpacer />
  
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Alexa that you are leaving to activate the alarm"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 500}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Alexa that you are leaving to activate the alarm"
                src={Applets08}
              />
              <p>Turn on your INSTAR cameras alarm when you say &quot;Alexa trigger&quot; + the phrase that you have defined here.</p>
              <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
                <EuiSpacer size="xl" />
                <EuiImage
                  size="l"
                  float="right"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="IFTTT Alexa that you are leaving to activate the alarm"
                  src={Applets08Set}
                />
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
  
      <EuiSpacer />
  
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Activate your cameras alarm schedule"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 500}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Activate your cameras alarm schedule"
                src={Applets09}
              />
              <p>Arm your INSTAR cameras alarm at a specific time of the day.</p>
              <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
                <EuiSpacer size="xl" />
                <EuiImage
                  size="l"
                  float="right"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="IFTTT Activate your cameras alarm schedule"
                  src={Applets09Set}
                />
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
  
      <EuiSpacer />
  
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Email notification when INSTAR publishes something new"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 500}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Email notification when INSTAR publishes something new"
                src={Applets10}
              />
              <p>Get an email when INSTAR publishes a new trigger or action.</p>
              <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
                <EuiSpacer size="xl" />
                <EuiImage
                  size="l"
                  float="right"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="IFTTT Email notification when INSTAR publishes something new"
                  src={Applets09Set}
                />
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
  
      <EuiSpacer />
  
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Deactivate alarm when my phone connects to local WiFi"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 500}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Deactivate alarm when my phone connects to local WiFi"
                src={Applets11}
              />
              <p>Turn the camera alarm off when my phone connects to my home wireless network.</p>
              <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
                <EuiSpacer size="xl" />
                <EuiImage
                  size="l"
                  float="right"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="IFTTT Deactivate alarm when my phone connects to local WiFi"
                  src={Applets11Set}
                />
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
  
      <EuiSpacer />
  
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Adjust the alarm sensitivity at sunrise"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 800}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Adjust the alarm sensitivity at sunrise"
                src={Applets12}
              />
              <p>Set a specific sensitivity value for each alarm area during the day.</p>
              <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
                <EuiSpacer size="xl" />
                <EuiImage
                  size="l"
                  float="right"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="IFTTT Adjust the alarm sensitivity at sunrise"
                  src={Applets12Set1}
                />
                <EuiImage
                  size="l"
                  float="right"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="IFTTT Adjust the alarm sensitivity at sunrise"
                  src={Applets12Set2}
                />
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
  
      <EuiSpacer />
  
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Adjust the alarm sensitivity after sunset"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 800}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Adjust the alarm sensitivity at sunrise"
                src={Applets13}
              />
              <p>Set a specific sensitivity value for each alarm area after dark.</p>
              <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
                <EuiSpacer size="xl" />
                <EuiImage
                  size="l"
                  float="right"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="IFTTT Adjust the alarm sensitivity after sunset"
                  src={Applets13Set1}
                />
                <EuiImage
                  size="l"
                  float="right"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="IFTTT Adjust the alarm sensitivity after sunset"
                  src={Applets13Set2}
                />
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
  
      <EuiSpacer />
  
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Deactivate your cameras alarm schedule"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 500}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Deactivate your cameras alarm schedule"
                src={Applets14}
              />
              <p>Disarm your INSTAR cameras alarm at a specific time of the day.</p>
              <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
                <EuiSpacer size="xl" />
                <EuiImage
                  size="l"
                  float="right"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="IFTTT Deactivate your cameras alarm schedule"
                  src={Applets14Set}
                />
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
  
      <EuiSpacer />
  
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Turn off the alarm when I arrive home"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 500}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Turn off the alarm when I arrive home"
                src={Applets15}
              />
              <p>Turn off INSTAR camera&apos;s alarm when I return home.</p>
              <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
                <EuiSpacer size="xl" />
                <EuiImage
                  size="l"
                  float="right"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="IFTTT Turn off the alarm when I arrive home"
                  src={Applets15Set}
                />
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
  
      <EuiSpacer />
  
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Tell Alexa that you arrived home to deactivate the alarm"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 500}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Tell Alexa that you arrived home to deactivate the alarm"
                src={Applets16}
              />
              <p>Turn your INSTAR cameras alarm off when you say &quot;Alexa trigger&quot; + the phrase that you have defined here.</p>
              <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
                <EuiSpacer size="xl" />
                <EuiImage
                  size="l"
                  float="right"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="IFTTT Tell Alexa that you arrived home to deactivate the alarm"
                  src={Applets16Set}
                />
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
  
      <EuiSpacer />
  
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Turn the alarm email on when I leave home"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 500}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Turn the alarm email on when I leave home"
                src={Applets17}
              />
              <p>Turn your INSTAR camera&apos;s alarm email on when you leave home.</p>
              <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
                <EuiSpacer size="xl" />
                <EuiImage
                  size="l"
                  float="right"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="IFTTT Turn the alarm email on when I leave home"
                  src={Applets17Set}
                />
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
  
      <EuiSpacer />
  
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Turn off alarm email when I arrive home"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 500}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Turn off alarm email when I arrive home"
                src={Applets18}
              />
              <p>Turn off INSTAR camera&apos;s alarm email when you come back home.</p>
              <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
                <EuiSpacer size="xl" />
                <EuiImage
                  size="l"
                  float="right"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="IFTTT Turn off alarm email when I arrive home"
                  src={Applets18Set}
                />
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
  
      <EuiSpacer />
  
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Turn on INSTAR Cloud alarm email when you leave"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 500}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Turn on INSTAR Cloud alarm email when you leave"
                src={Applets19}
              />
              <p>Turn INSTAR Cloud alarm email on when you leave home.</p>
              <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
                <EuiSpacer size="xl" />
                <EuiImage
                  size="l"
                  float="right"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="IFTTT Turn on INSTAR Cloud alarm email when you leave"
                  src={Applets19Set}
                />
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
  
      <EuiSpacer />
  
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Turn INSTAR Cloud alarm email on at sunrise"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 500}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Turn INSTAR Cloud alarm email on at sunrise"
                src={Applets20}
              />
              <p>Turn INSTAR Cloud alarm email on at sunrise.</p>
              <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
                <EuiSpacer size="xl" />
                <EuiImage
                  size="l"
                  float="right"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="IFTTT Turn INSTAR Cloud alarm email on at sunrise"
                  src={Applets20Set}
                />
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
  
      <EuiSpacer />
  
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Turn off INSTAR Cloud alarm email when you arrive home"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 500}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Turn off INSTAR Cloud alarm email when you arrive home"
                src={Applets21}
              />
              <p>Turn off INSTAR Cloud alarm email when I come back home.</p>
              <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
                <EuiSpacer size="xl" />
                <EuiImage
                  size="l"
                  float="right"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="IFTTT Turn off INSTAR Cloud alarm email when you arrive home"
                  src={Applets21Set}
                />
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
  
      <EuiSpacer />
  
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Turn off INSTAR Cloud alarm email at sunset"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 500}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Turn off INSTAR Cloud alarm email at sunset"
                src={Applets22}
              />
              <p>Turn off INSTAR Cloud alarm email at sunset.</p>
              <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
                <EuiSpacer size="xl" />
                <EuiImage
                  size="l"
                  float="right"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="IFTTT Turn off INSTAR Cloud alarm email at sunset"
                  src={Applets22Set}
                />
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
  
      <EuiSpacer />
  
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Email the daily INSTAR Cloud alarm count"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 500}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Email the daily INSTAR Cloud alarm count"
                src={Applets23}
              />
              <p>Sent me an email with the number of INSTAR Cloud alarm uploads every day at a specified time.</p>
              <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
                <EuiSpacer size="xl" />
                <EuiImage
                  size="l"
                  float="right"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="IFTTT Email the daily INSTAR Cloud alarm count"
                  src={Applets23Set}
                />
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
  
      <EuiSpacer />
  
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="INSTAR Cloud notification when alarm count exceeded a limit"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 500}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT INSTAR Cloud notification when alarm count exceeded a limit"
                src={Applets24}
              />
              <p>Notify me when the number of new alarms in the INSTAR Cloud is over the number specified above.</p>
              <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
                <EuiSpacer size="xl" />
                <EuiImage
                  size="l"
                  float="right"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="IFTTT INSTAR Cloud notification when alarm count exceeded a limit"
                  src={Applets24Set}
                />
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
  
      <EuiSpacer />
  
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="INSTAR Cloud storage is full"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 500}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT INSTAR Cloud storage is full"
                src={Applets25}
              />
              <p>Notify me when my INSTAR Cloud storage is full.</p>
              <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
                <EuiSpacer size="xl" />
                <EuiImage
                  size="l"
                  float="right"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="IFTTT INSTAR Cloud storage is full"
                  src={Applets25Set}
                />
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
  
      <EuiSpacer />
  
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="INSTAR Cloud storage will expire"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 500}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT INSTAR Cloud storage will expire"
                src={Applets26}
              />
              <p>Notify me when my INSTAR Cloud storage will expire in the specified amount of days.</p>
              <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
                <EuiSpacer size="xl" />
                <EuiImage
                  size="l"
                  float="right"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="IFTTT INSTAR Cloud storage will expire"
                  src={Applets26Set}
                />
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
  
      <EuiSpacer />
  
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="INSTAR Cloud false alarm over 80%"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 500}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT INSTAR Cloud false alarm over 80%"
                src={Applets27}
              />
              <p>Notify me when the false alarm detection flags over 80% of my alarm videos in my INSTAR Cloud storage.</p>
              <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
                <EuiSpacer size="xl" />
                <EuiImage
                  size="l"
                  float="right"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="IFTTT INSTAR Cloud false alarm over 80%"
                  src={Applets27Set}
                />
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
    </React.Fragment>
  );
}

export default AppletAccordion

// export default () => (
//   <React.Fragment>
//     <EuiPanel hasBorder>
//       <EuiAccordion
//         id="accordion1"
//         buttonContent="Get an email when a new INSTAR Applet is published"
//         paddingSize="l">
//         <EuiPanel color="subdued">
//           <EuiText style={{height: 500}}>
//             <EuiImage
//               size="l"
//               float="left"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT Eine E-Mail erhalten, wenn ein neues INSTAR Applet veröffentlicht wird"
//               src={Applets01}
//             />
//             <p>Eine E-Mail erhalten, wenn ein neues INSTAR Applet veröffentlicht wird:</p>
//             <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
//           </EuiText>
//           </EuiPanel>
//         </EuiAccordion>
//       </EuiPanel>

//     <EuiSpacer />

//     <EuiPanel hasBorder>
//       <EuiAccordion
//         id="accordion2"
//         buttonContent="Set a time schedule for your alarm areas"
//         buttonContentClassName="eui-textTruncate"
//         paddingSize="l">
//         <EuiPanel color="subdued">
//           <EuiText style={{height: 800}}>
//             <EuiImage
//               size="l"
//               float="left"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT Set a time schedule for your alarm areas"
//               src={Applets02}
//             />
//             <p>This app allows you to define time windows in which your alarm areas are switched on or off:</p>
//             <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
//               <EuiSpacer size="xl" />
//               <EuiImage
//                 size="l"
//                 float="right"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="IFTTT Set a time schedule for your alarm areas"
//                 src={Applets02Set1}
//               />
//               <EuiImage
//                 size="l"
//                 float="right"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="IFTTT Set a time schedule for your alarm areas"
//                 src={Applets02Set2}
//               />
//           </EuiText>
//         </EuiPanel>
//       </EuiAccordion>
//     </EuiPanel>

//     <EuiSpacer />

//     <EuiPanel hasBorder>
//       <EuiAccordion
//         id="accordion3"
//         buttonContent="Turn to the alarm position when I leave"
//         paddingSize="m">
//         <EuiPanel color="subdued">
//           <EuiText style={{height: 800}}>
//             <EuiImage
//               size="l"
//               float="left"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT Turn to the alarm position when I leave"
//               src={Applets03}
//             />
//             <p>Turn the camera to a preset position, when I leave home:</p>
//             <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
//               <EuiSpacer size="xl" />
//               <EuiImage
//                 size="l"
//                 float="right"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="IFTTT Turn to the alarm position when I leave"
//                 src={Applets03Set1}
//               />
//               <EuiImage
//                 size="l"
//                 float="right"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="IFTTT Turn to the alarm position when I leave"
//                 src={Applets03Set2}
//               />
//           </EuiText>
//         </EuiPanel>
//       </EuiAccordion>
//     </EuiPanel>

//     <EuiSpacer />

//     <EuiPanel hasBorder>
//       <EuiAccordion
//         id="accordion4"
//         buttonContent="Turn away when I arrive home"
//         paddingSize="m">
//         <EuiPanel color="subdued">
//           <EuiText style={{height: 800}}>
//             <EuiImage
//               size="l"
//               float="left"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT Turn away when I arrive home"
//               src={Applets04}
//             />
//             <p>Turn the camera to preset position, when I arrive home:</p>
//             <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
//               <EuiSpacer size="xl" />
//               <EuiImage
//                 size="l"
//                 float="right"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="IFTTT Turn away when I arrive home"
//                 src={Applets04Set1}
//               />
//               <EuiImage
//                 size="l"
//                 float="right"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="IFTTT Turn away when I arrive home"
//                 src={Applets04Set2}
//               />
//           </EuiText>
//         </EuiPanel>
//       </EuiAccordion>
//     </EuiPanel>

//     <EuiSpacer />

//     <EuiPanel hasBorder>
//       <EuiAccordion
//         id="accordion4"
//         buttonContent="Activate alarm when my phone disconnects from local WiFi"
//         paddingSize="m">
//         <EuiPanel color="subdued">
//           <EuiText style={{height: 500}}>
//             <EuiImage
//               size="l"
//               float="left"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT Activate alarm when my phone disconnects from local WiFi"
//               src={Applets05}
//             />
//             <p>Turn the camera alarm on when my phone disconnects from my home wireless network.</p>
//             <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
//               <EuiSpacer size="xl" />
//               <EuiImage
//                 size="l"
//                 float="right"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="IFTTT Activate alarm when my phone disconnects from local WiFi"
//                 src={Applets05Set}
//               />
//           </EuiText>
//         </EuiPanel>
//       </EuiAccordion>
//     </EuiPanel>

//     <EuiSpacer />

//     <EuiPanel hasBorder>
//       <EuiAccordion
//         id="accordion4"
//         buttonContent="Alarm trigger when a door is opened"
//         paddingSize="m">
//         <EuiPanel color="subdued">
//           <EuiText style={{height: 500}}>
//             <EuiImage
//               size="l"
//               float="left"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT Alarm trigger when a door is opened"
//               src={Applets06}
//             />
//             <p>Trigger an alarm and cloud/ftp video upload when a door is opened.</p>
//             <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
//           </EuiText>
//         </EuiPanel>
//       </EuiAccordion>
//     </EuiPanel>

//     <EuiSpacer />

//     <EuiPanel hasBorder>
//       <EuiAccordion
//         id="accordion4"
//         buttonContent="Arm my camera alarm when I leave"
//         paddingSize="m">
//         <EuiPanel color="subdued">
//           <EuiText style={{height: 500}}>
//             <EuiImage
//               size="l"
//               float="left"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT Arm my camera alarm when I leave"
//               src={Applets07}
//             />
//             <p>Turn on your INSTAR cameras alarm when you leave an area.</p>
//             <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
//               <EuiSpacer size="xl" />
//               <EuiImage
//                 size="l"
//                 float="right"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="IFTTT Arm my camera alarm when I leave"
//                 src={Applets07Set}
//               />
//           </EuiText>
//         </EuiPanel>
//       </EuiAccordion>
//     </EuiPanel>

//     <EuiSpacer />

//     <EuiPanel hasBorder>
//       <EuiAccordion
//         id="accordion4"
//         buttonContent="Alexa that you are leaving to activate the alarm"
//         paddingSize="m">
//         <EuiPanel color="subdued">
//           <EuiText style={{height: 500}}>
//             <EuiImage
//               size="l"
//               float="left"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT Alexa that you are leaving to activate the alarm"
//               src={Applets08}
//             />
//             <p>Turn on your INSTAR cameras alarm when you say &quot;Alexa trigger&quot; + the phrase that you have defined here.</p>
//             <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
//               <EuiSpacer size="xl" />
//               <EuiImage
//                 size="l"
//                 float="right"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="IFTTT Alexa that you are leaving to activate the alarm"
//                 src={Applets08Set}
//               />
//           </EuiText>
//         </EuiPanel>
//       </EuiAccordion>
//     </EuiPanel>

//     <EuiSpacer />

//     <EuiPanel hasBorder>
//       <EuiAccordion
//         id="accordion4"
//         buttonContent="Activate your cameras alarm schedule"
//         paddingSize="m">
//         <EuiPanel color="subdued">
//           <EuiText style={{height: 500}}>
//             <EuiImage
//               size="l"
//               float="left"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT Activate your cameras alarm schedule"
//               src={Applets09}
//             />
//             <p>Arm your INSTAR cameras alarm at a specific time of the day.</p>
//             <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
//               <EuiSpacer size="xl" />
//               <EuiImage
//                 size="l"
//                 float="right"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="IFTTT Activate your cameras alarm schedule"
//                 src={Applets09Set}
//               />
//           </EuiText>
//         </EuiPanel>
//       </EuiAccordion>
//     </EuiPanel>

//     <EuiSpacer />

//     <EuiPanel hasBorder>
//       <EuiAccordion
//         id="accordion4"
//         buttonContent="Email notification when INSTAR publishes something new"
//         paddingSize="m">
//         <EuiPanel color="subdued">
//           <EuiText style={{height: 500}}>
//             <EuiImage
//               size="l"
//               float="left"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT Email notification when INSTAR publishes something new"
//               src={Applets10}
//             />
//             <p>Get an email when INSTAR publishes a new trigger or action.</p>
//             <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
//               <EuiSpacer size="xl" />
//               <EuiImage
//                 size="l"
//                 float="right"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="IFTTT Email notification when INSTAR publishes something new"
//                 src={Applets09Set}
//               />
//           </EuiText>
//         </EuiPanel>
//       </EuiAccordion>
//     </EuiPanel>

//     <EuiSpacer />

//     <EuiPanel hasBorder>
//       <EuiAccordion
//         id="accordion4"
//         buttonContent="Deactivate alarm when my phone connects to local WiFi"
//         paddingSize="m">
//         <EuiPanel color="subdued">
//           <EuiText style={{height: 500}}>
//             <EuiImage
//               size="l"
//               float="left"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT Deactivate alarm when my phone connects to local WiFi"
//               src={Applets11}
//             />
//             <p>Turn the camera alarm off when my phone connects to my home wireless network.</p>
//             <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
//               <EuiSpacer size="xl" />
//               <EuiImage
//                 size="l"
//                 float="right"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="IFTTT Deactivate alarm when my phone connects to local WiFi"
//                 src={Applets11Set}
//               />
//           </EuiText>
//         </EuiPanel>
//       </EuiAccordion>
//     </EuiPanel>

//     <EuiSpacer />

//     <EuiPanel hasBorder>
//       <EuiAccordion
//         id="accordion4"
//         buttonContent="Adjust the alarm sensitivity at sunrise"
//         paddingSize="m">
//         <EuiPanel color="subdued">
//           <EuiText style={{height: 800}}>
//             <EuiImage
//               size="l"
//               float="left"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT Adjust the alarm sensitivity at sunrise"
//               src={Applets12}
//             />
//             <p>Set a specific sensitivity value for each alarm area during the day.</p>
//             <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
//               <EuiSpacer size="xl" />
//               <EuiImage
//                 size="l"
//                 float="right"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="IFTTT Adjust the alarm sensitivity at sunrise"
//                 src={Applets12Set1}
//               />
//               <EuiImage
//                 size="l"
//                 float="right"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="IFTTT Adjust the alarm sensitivity at sunrise"
//                 src={Applets12Set2}
//               />
//           </EuiText>
//         </EuiPanel>
//       </EuiAccordion>
//     </EuiPanel>

//     <EuiSpacer />

//     <EuiPanel hasBorder>
//       <EuiAccordion
//         id="accordion4"
//         buttonContent="Adjust the alarm sensitivity after sunset"
//         paddingSize="m">
//         <EuiPanel color="subdued">
//           <EuiText style={{height: 800}}>
//             <EuiImage
//               size="l"
//               float="left"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT Adjust the alarm sensitivity at sunrise"
//               src={Applets13}
//             />
//             <p>Set a specific sensitivity value for each alarm area after dark.</p>
//             <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
//               <EuiSpacer size="xl" />
//               <EuiImage
//                 size="l"
//                 float="right"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="IFTTT Adjust the alarm sensitivity after sunset"
//                 src={Applets13Set1}
//               />
//               <EuiImage
//                 size="l"
//                 float="right"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="IFTTT Adjust the alarm sensitivity after sunset"
//                 src={Applets13Set2}
//               />
//           </EuiText>
//         </EuiPanel>
//       </EuiAccordion>
//     </EuiPanel>

//     <EuiSpacer />

//     <EuiPanel hasBorder>
//       <EuiAccordion
//         id="accordion4"
//         buttonContent="Deactivate your cameras alarm schedule"
//         paddingSize="m">
//         <EuiPanel color="subdued">
//           <EuiText style={{height: 500}}>
//             <EuiImage
//               size="l"
//               float="left"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT Deactivate your cameras alarm schedule"
//               src={Applets14}
//             />
//             <p>Disarm your INSTAR cameras alarm at a specific time of the day.</p>
//             <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
//               <EuiSpacer size="xl" />
//               <EuiImage
//                 size="l"
//                 float="right"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="IFTTT Deactivate your cameras alarm schedule"
//                 src={Applets14Set}
//               />
//           </EuiText>
//         </EuiPanel>
//       </EuiAccordion>
//     </EuiPanel>

//     <EuiSpacer />

//     <EuiPanel hasBorder>
//       <EuiAccordion
//         id="accordion4"
//         buttonContent="Turn off the alarm when I arrive home"
//         paddingSize="m">
//         <EuiPanel color="subdued">
//           <EuiText style={{height: 500}}>
//             <EuiImage
//               size="l"
//               float="left"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT Turn off the alarm when I arrive home"
//               src={Applets15}
//             />
//             <p>Turn off INSTAR camera&apos;s alarm when I return home.</p>
//             <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
//               <EuiSpacer size="xl" />
//               <EuiImage
//                 size="l"
//                 float="right"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="IFTTT Turn off the alarm when I arrive home"
//                 src={Applets15Set}
//               />
//           </EuiText>
//         </EuiPanel>
//       </EuiAccordion>
//     </EuiPanel>

//     <EuiSpacer />

//     <EuiPanel hasBorder>
//       <EuiAccordion
//         id="accordion4"
//         buttonContent="Tell Alexa that you arrived home to deactivate the alarm"
//         paddingSize="m">
//         <EuiPanel color="subdued">
//           <EuiText style={{height: 500}}>
//             <EuiImage
//               size="l"
//               float="left"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT Tell Alexa that you arrived home to deactivate the alarm"
//               src={Applets16}
//             />
//             <p>Turn your INSTAR cameras alarm off when you say &quot;Alexa trigger&quot; + the phrase that you have defined here.</p>
//             <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
//               <EuiSpacer size="xl" />
//               <EuiImage
//                 size="l"
//                 float="right"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="IFTTT Tell Alexa that you arrived home to deactivate the alarm"
//                 src={Applets16Set}
//               />
//           </EuiText>
//         </EuiPanel>
//       </EuiAccordion>
//     </EuiPanel>

//     <EuiSpacer />

//     <EuiPanel hasBorder>
//       <EuiAccordion
//         id="accordion4"
//         buttonContent="Turn the alarm email on when I leave home"
//         paddingSize="m">
//         <EuiPanel color="subdued">
//           <EuiText style={{height: 500}}>
//             <EuiImage
//               size="l"
//               float="left"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT Turn the alarm email on when I leave home"
//               src={Applets17}
//             />
//             <p>Turn your INSTAR camera&apos;s alarm email on when you leave home.</p>
//             <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
//               <EuiSpacer size="xl" />
//               <EuiImage
//                 size="l"
//                 float="right"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="IFTTT Turn the alarm email on when I leave home"
//                 src={Applets17Set}
//               />
//           </EuiText>
//         </EuiPanel>
//       </EuiAccordion>
//     </EuiPanel>

//     <EuiSpacer />

//     <EuiPanel hasBorder>
//       <EuiAccordion
//         id="accordion4"
//         buttonContent="Turn off alarm email when I arrive home"
//         paddingSize="m">
//         <EuiPanel color="subdued">
//           <EuiText style={{height: 500}}>
//             <EuiImage
//               size="l"
//               float="left"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT Turn off alarm email when I arrive home"
//               src={Applets18}
//             />
//             <p>Turn off INSTAR camera&apos;s alarm email when you come back home.</p>
//             <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
//               <EuiSpacer size="xl" />
//               <EuiImage
//                 size="l"
//                 float="right"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="IFTTT Turn off alarm email when I arrive home"
//                 src={Applets18Set}
//               />
//           </EuiText>
//         </EuiPanel>
//       </EuiAccordion>
//     </EuiPanel>

//     <EuiSpacer />

//     <EuiPanel hasBorder>
//       <EuiAccordion
//         id="accordion4"
//         buttonContent="Turn on INSTAR Cloud alarm email when you leave"
//         paddingSize="m">
//         <EuiPanel color="subdued">
//           <EuiText style={{height: 500}}>
//             <EuiImage
//               size="l"
//               float="left"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT Turn on INSTAR Cloud alarm email when you leave"
//               src={Applets19}
//             />
//             <p>Turn INSTAR Cloud alarm email on when you leave home.</p>
//             <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
//               <EuiSpacer size="xl" />
//               <EuiImage
//                 size="l"
//                 float="right"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="IFTTT Turn on INSTAR Cloud alarm email when you leave"
//                 src={Applets19Set}
//               />
//           </EuiText>
//         </EuiPanel>
//       </EuiAccordion>
//     </EuiPanel>

//     <EuiSpacer />

//     <EuiPanel hasBorder>
//       <EuiAccordion
//         id="accordion4"
//         buttonContent="Turn INSTAR Cloud alarm email on at sunrise"
//         paddingSize="m">
//         <EuiPanel color="subdued">
//           <EuiText style={{height: 500}}>
//             <EuiImage
//               size="l"
//               float="left"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT Turn INSTAR Cloud alarm email on at sunrise"
//               src={Applets20}
//             />
//             <p>Turn INSTAR Cloud alarm email on at sunrise.</p>
//             <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
//               <EuiSpacer size="xl" />
//               <EuiImage
//                 size="l"
//                 float="right"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="IFTTT Turn INSTAR Cloud alarm email on at sunrise"
//                 src={Applets20Set}
//               />
//           </EuiText>
//         </EuiPanel>
//       </EuiAccordion>
//     </EuiPanel>

//     <EuiSpacer />

//     <EuiPanel hasBorder>
//       <EuiAccordion
//         id="accordion4"
//         buttonContent="Turn off INSTAR Cloud alarm email when you arrive home"
//         paddingSize="m">
//         <EuiPanel color="subdued">
//           <EuiText style={{height: 500}}>
//             <EuiImage
//               size="l"
//               float="left"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT Turn off INSTAR Cloud alarm email when you arrive home"
//               src={Applets21}
//             />
//             <p>Turn off INSTAR Cloud alarm email when I come back home.</p>
//             <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
//               <EuiSpacer size="xl" />
//               <EuiImage
//                 size="l"
//                 float="right"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="IFTTT Turn off INSTAR Cloud alarm email when you arrive home"
//                 src={Applets21Set}
//               />
//           </EuiText>
//         </EuiPanel>
//       </EuiAccordion>
//     </EuiPanel>

//     <EuiSpacer />

//     <EuiPanel hasBorder>
//       <EuiAccordion
//         id="accordion4"
//         buttonContent="Turn off INSTAR Cloud alarm email at sunset"
//         paddingSize="m">
//         <EuiPanel color="subdued">
//           <EuiText style={{height: 500}}>
//             <EuiImage
//               size="l"
//               float="left"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT Turn off INSTAR Cloud alarm email at sunset"
//               src={Applets22}
//             />
//             <p>Turn off INSTAR Cloud alarm email at sunset.</p>
//             <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
//               <EuiSpacer size="xl" />
//               <EuiImage
//                 size="l"
//                 float="right"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="IFTTT Turn off INSTAR Cloud alarm email at sunset"
//                 src={Applets22Set}
//               />
//           </EuiText>
//         </EuiPanel>
//       </EuiAccordion>
//     </EuiPanel>

//     <EuiSpacer />

//     <EuiPanel hasBorder>
//       <EuiAccordion
//         id="accordion4"
//         buttonContent="Email the daily INSTAR Cloud alarm count"
//         paddingSize="m">
//         <EuiPanel color="subdued">
//           <EuiText style={{height: 500}}>
//             <EuiImage
//               size="l"
//               float="left"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT Email the daily INSTAR Cloud alarm count"
//               src={Applets23}
//             />
//             <p>Sent me an email with the number of INSTAR Cloud alarm uploads every day at a specified time.</p>
//             <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
//               <EuiSpacer size="xl" />
//               <EuiImage
//                 size="l"
//                 float="right"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="IFTTT Email the daily INSTAR Cloud alarm count"
//                 src={Applets23Set}
//               />
//           </EuiText>
//         </EuiPanel>
//       </EuiAccordion>
//     </EuiPanel>

//     <EuiSpacer />

//     <EuiPanel hasBorder>
//       <EuiAccordion
//         id="accordion4"
//         buttonContent="INSTAR Cloud notification when alarm count exceeded a limit"
//         paddingSize="m">
//         <EuiPanel color="subdued">
//           <EuiText style={{height: 500}}>
//             <EuiImage
//               size="l"
//               float="left"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT INSTAR Cloud notification when alarm count exceeded a limit"
//               src={Applets24}
//             />
//             <p>Notify me when the number of new alarms in the INSTAR Cloud is over the number specified above.</p>
//             <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
//               <EuiSpacer size="xl" />
//               <EuiImage
//                 size="l"
//                 float="right"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="IFTTT INSTAR Cloud notification when alarm count exceeded a limit"
//                 src={Applets24Set}
//               />
//           </EuiText>
//         </EuiPanel>
//       </EuiAccordion>
//     </EuiPanel>

//     <EuiSpacer />

//     <EuiPanel hasBorder>
//       <EuiAccordion
//         id="accordion4"
//         buttonContent="INSTAR Cloud storage is full"
//         paddingSize="m">
//         <EuiPanel color="subdued">
//           <EuiText style={{height: 500}}>
//             <EuiImage
//               size="l"
//               float="left"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT INSTAR Cloud storage is full"
//               src={Applets25}
//             />
//             <p>Notify me when my INSTAR Cloud storage is full.</p>
//             <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
//               <EuiSpacer size="xl" />
//               <EuiImage
//                 size="l"
//                 float="right"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="IFTTT INSTAR Cloud storage is full"
//                 src={Applets25Set}
//               />
//           </EuiText>
//         </EuiPanel>
//       </EuiAccordion>
//     </EuiPanel>

//     <EuiSpacer />

//     <EuiPanel hasBorder>
//       <EuiAccordion
//         id="accordion4"
//         buttonContent="INSTAR Cloud storage will expire"
//         paddingSize="m">
//         <EuiPanel color="subdued">
//           <EuiText style={{height: 500}}>
//             <EuiImage
//               size="l"
//               float="left"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT INSTAR Cloud storage will expire"
//               src={Applets26}
//             />
//             <p>Notify me when my INSTAR Cloud storage will expire in the specified amount of days.</p>
//             <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
//               <EuiSpacer size="xl" />
//               <EuiImage
//                 size="l"
//                 float="right"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="IFTTT INSTAR Cloud storage will expire"
//                 src={Applets26Set}
//               />
//           </EuiText>
//         </EuiPanel>
//       </EuiAccordion>
//     </EuiPanel>

//     <EuiSpacer />

//     <EuiPanel hasBorder>
//       <EuiAccordion
//         id="accordion4"
//         buttonContent="INSTAR Cloud false alarm over 80%"
//         paddingSize="m">
//         <EuiPanel color="subdued">
//           <EuiText style={{height: 500}}>
//             <EuiImage
//               size="l"
//               float="left"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT INSTAR Cloud false alarm over 80%"
//               src={Applets27}
//             />
//             <p>Notify me when the false alarm detection flags over 80% of my alarm videos in my INSTAR Cloud storage.</p>
//             <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
//               <EuiSpacer size="xl" />
//               <EuiImage
//                 size="l"
//                 float="right"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="IFTTT INSTAR Cloud false alarm over 80%"
//                 src={Applets27Set}
//               />
//           </EuiText>
//         </EuiPanel>
//       </EuiAccordion>
//     </EuiPanel>
//   </React.Fragment>
// );