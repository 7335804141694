import React from 'react'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="AaPJ4Y8c9r0"
                    title="INSTAR Cloud Overview"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              title="INSTAR Cloud Overview"
              footer={
                <EuiText>
                  <p>In diesem Video geben wir Ihnen einen allgemeinen Überblick über die neue Aufnahmeplattform von INSTAR. Die INSTAR Cloud bietet Ihnen sehr viele Vorteile, unter anderem z.B.:</p>
                  <ul>
                    <li>die Aufnahmen sind sicher vor Einbrechern</li>
                    <li>Sie zahlen nur für den Speicherplatz und können soviele Kameras nutzen wie Sie möchten</li>
                    <li>Alle Daten werden Sicher in deutschen Rechenzentren gespeichert</li>
                    <li>Schnelle und komfortable Übersicht über alle Alarme Ihrer Kameras</li>
                    <li>HTML5 Videos können in jedem gängigen Webbrowser ohne Videoplugins wiedergegeben werden</li>
                    <li>Laden Sie sich die Aufnahmen bequem auf Ihren PC</li>
                    <li>wesentlich kostengünstiger als eigene NVR / NAS Stationen</li>
                  </ul>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}
