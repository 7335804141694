import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="ONVIF Camera Service">
        <EuiText>
          <p>
            Die Überwachungsindustrie hat ein offenes Standardprotokoll entwickelt, dass sich <Link to="/Schnell_Installation/ONVIF/">ONVIF</Link>  nennt. Dieses Protokoll erlaubt es Kameras untereinander und mit Netzwerkgeräten zu kommunizieren. Sie können den Service aktivieren oder deaktivieren und den Port ändern - wie es Ihr empfangenes ONVIF Gerät benötigt.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

