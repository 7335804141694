import React from 'react'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'


import ivImage01 from '../../../images/Products/IR_Lighting/IN-90X_08.webp'
import ivImage02 from '../../../images/Products/IR_Lighting/IN-90X_10.webp'

export default function ImageCard() {

  return (
    <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
      <EuiFlexItem>
        <EuiCard
          image={
            <div>
              <img
                src={ivImage01}
                alt="INSTAR Infrared Spotlights"
              />
            </div>
          }
          footer={
            <EuiText>
              <p>Die Sensorreaktion für einfallendes Licht mit einer höheren Wellenlänge wird stark reduziert, was zu einer geringeren Empfindlichkeit gegenüber LEDs bei 940 nm und einer geringeren Reichweite für diese Scheinwerfer führt.</p>
            </EuiText>
          }
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiCard
          image={
            <div>
              <img
                src={ivImage02}
                alt="INSTAR Infrared Spotlights"
              />
            </div>
          }
          footer={
            <EuiText>
              <p>Auch die Luft neigt dazu, bei höheren Wellenlängen gegen uns zu arbeiten - die Opazität steigt schnell an und verringert die Reichweite, die Sie mit einer 940nm Lichtquelle vor allem im Außenbereich erreichen werden.</p>
            </EuiText>
          }
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}
