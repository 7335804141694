import React from 'react'
import {Link} from 'gatsby'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText
} from '@elastic/eui'

import qiSelect01 from "../../../../images/Indoor_Cameras/IN-6012_HD/Point2Point/WPS-P2P_01.webp"
import qiSelect02 from "../../../../images/Indoor_Cameras/IN-6012_HD/Point2Point/WPS-P2P_02.webp"
import qiSelect03 from "../../../../images/Indoor_Cameras/IN-6012_HD/Point2Point/WPS-P2P_03.webp"
import qiSelect04 from "../../../../images/Indoor_Cameras/IN-6012_HD/Point2Point/WPS-P2P_04.webp"
import qiSelect05 from "../../../../images/Indoor_Cameras/IN-6012_HD/Point2Point/WPS-P2P_05.webp"
import qiSelect06 from "../../../../images/Indoor_Cameras/IN-6012_HD/Point2Point/WPS-P2P_06.webp"
import qiSelect07 from "../../../../images/Indoor_Cameras/IN-6012_HD/Point2Point/WPS-P2P_07.webp"
import qiSelect08 from "../../../../images/Indoor_Cameras/IN-6012_HD/Point2Point/WPS-P2P_08.webp"
import qiSelect09 from "../../../../images/Indoor_Cameras/IN-6012_HD/Point2Point/WPS-P2P_09.webp"



export default function QIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect01}
                    alt="Verbinden Sie die Antenne mit der SMA Buchse."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Verbinden Sie die Antenne mit der SMA Buchse (<Link to="/Innenkameras/IN-6012_HD/Product_Features/">#11</Link>). (nur nicht-PoE Version!)
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect02}
                    alt="Plug in the power supply (#7). to start the camera. In case you are installing the PoE model, plug in the LAN cable (#12), connect it to your router and skip the WiFi section below."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Verbinden Sie das LAN Kabel mit dem Anschluss in der Kamera und verbinden Sie das andere Ende mit Ihrem Router (<Link to="/Innenkameras/IN-6012_HD/Product_Features/">#12</Link>) - überspringen Sie die Schritte der WLAN Einbindung, im Falle des PoE Modells.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect03}
                    alt="Plug in the power supply (#7) to start the camera."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Verbinden Sie das Netzteil mit der DC Buchse der Kamera (<Link to="/Innenkameras/IN-6012_HD/Product_Features/">#7</Link>) um diese zu starten.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect04}
                    alt="If your router supports the WPS (Wireless Protected Setup) function, please press the WPS button on the router, which looks similar to the example above. (only for the non-PoE Version!)"
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Wenn Sie keine LAN Verbindung herstellen wollen und Ihr Router eine WPS (Wireless Protected Setup) Funktion hat, können Sie auch die WPS Taste am Router drücken (siehe Beispiel oben)
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect05}
                    alt="Press the cameras reset button for 3 seconds to activate the cameras WPS service (#9). Wait for max. 5min for the camera to connect to your WiFi network. If you press it for 10s, the camera will be reset to its factory defaults!"
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Drücken Sie danach den Reset Button (<Link to="/Innenkameras/IN-6012_HD/Product_Features/">#9</Link>) der Kamera für 3 Sekunden um den WPS Dienst der Kamera zu aktivieren. Warten Sie danach für max. 5min darauf, dass sich die Kamera mit Ihrem WLAN Router verbindet. Beachten Sie bitte: Wenn Sie den Reset Button für mehr als 10s halten, versetzten Sie die Kamera wieder in die Werkseinstellungen.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect06}
                    alt="Start our Android, iOS or Windows Phone app Instar Vision. Choose to add a new p2p camera and start the QR code scanner. Your QR code is located on a label on the camera case (s. above)."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Start Sie unsere <Link to="/Software/Android/InstarVision/P2P/">Android</Link>, <Link to="/Software/iOS/InstarVision/iPhone/P2P/">iOS</Link> oder <Link to="/Software/Windows/InstarVision/Windows_Phone/P2P/">Windows Phone</Link> App InstarVision. Wählen Sie das Hinzufügen einer neuen p2p Kamera und starten Sie den QR Code Scanner. Lokalisieren Sie den QR Code auf dem Kameragehäuse (s. Bild o.).
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect07}
                    alt="The QR code contains all the necessary information to add your camera. Please be aware that the default login credentials will be used. If you want to change the default password, please check the Quick Installation Guide. The password can only be changed in the web user interface."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Der QR Code beinhaltet alle Informationen zum Einbinden der Kamera. Bitte beachten Sie, dass hierbei die Standard Login Daten verwendet werden. Das Standard Kennwort kann nur über die Weboberfläche der Kamera geändert werden (s. 9).
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect08}
                    alt="The app will now connect to your cameras live stream and you will be able to pan & tilt the device, as well as to activate the audio stream of the internal microphone and to take snapshots."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Die App wird jetzt den Live Stream der Kamera abgreifen können und Ihnen die Möglichkeit geben die Kamera zu schwenken, den Audiostream zu aktivieren und einen Schnappschuss zu speichern.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect09}
                    alt="The WPS / P2P method allows you to quickly connect your camera and check the cameras live stream. Please refer to the Quick Installation Guide for instruction on how to access additional camera features."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Die p2p Funktion ist ein einfacher und schneller Weg sich mit der Kamera zu verbinden. Wenn Sie weitere Funktionen der Kamera nutzen möchten, lesen Sie bitte in der <Link to="/Innenkameras/IN-6012_HD/Schnell_Installation/">Schnellinstallation Anleitung </Link> weiter.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
