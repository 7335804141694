import React from 'react'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="L_nR9qY7-8I"
                    title="IN-5907 HD SD Karte tauschen"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              footer={
                <EuiText>
                  <p>IN-5907 HD SD Karte tauschen</p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="rF60Sh863yc"
                    title="IN-5907 HD deaktivieren der IR LEDs"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              footer={
                <EuiText>
                  <p>IN-5907 HD deaktivieren der IR LEDs</p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer />

        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="qTNFJKNBgRo"
                    title="IN-5907 HD Heizelement Installation"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              footer={
                <EuiText>
                  <p>IN-5907 HD Heizelement Installation</p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="UxnCqE1YU0k"
                    title="IN-5907 HD Verbindungskabel"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              footer={
                <EuiText>
                  <p>IN-5907 HD Verbindungskabel</p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer />

        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="RIE2TjE_JCg"
                    title="IN 5907 HD Feuchtigkeit"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              footer={
                <EuiText>
                  <p>IN 5907 HD Feuchtigkeit</p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="c136CT0SypU"
                    title="IN-5907 HD Objektivjustage"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              footer={
                <EuiText>
                  <p>IN-5907 HD Objektivjustage</p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}
