import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class FeatureTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "parColumn", title: "Parameter" },
        { name: "descColumn", title: "Beschreibung" },
      ],
      rows: [
        { parColumn: 'FTP Server', descColumn: 'Bitte geben Sie die Adresse Ihres FTP Servers ein. Im Beispiel oben haben wir eine AVM Fritzbox (Router) als FTP Server genutzt. Der Router bietet einen USB Stick als Speicherplatz für die Bilder. In unserem Fall müssen wir die IP Adresse unseres Routers eingeben. Falls Sie Ihren eigenen FTP Server auf Ihrem lokalen Rechner einrichten möchten, finden Sie hier eine passende Schritt-für-Schritt Anleitung. Sie können auch eine Webadresse als FTP Server angeben. Dies kann entweder ein Online-Service sein oder eine DDNS-Adresse die auf einen anderen FTP Server verweist.' },
        { parColumn: 'FTP Port', descColumn: 'Bitte geben Sie den FTP Port ein, welcher normalerweise 21 ist.' },
        { parColumn: 'FTP Benutzername', descColumn: 'Bitte geben Sie den Benutzernamen Ihres FTP-Accounts an. Der Standard-Benutzername der AVM Fritzbox ist "ftpuser".' },
        { parColumn: 'FTP Kennwort', descColumn: 'Bitte geben Sie das Passwort Ihres FTP-Accounts an.' },
        { parColumn: 'FTP Upload Ordner', descColumn: 'Geben Sie den Ordner an, in den die Bilder hochgeladen werden sollen. Standardmäßig ist der Ordner "./" , was die Bilder in den Root-Ordner des Servers hochladen würde. In unserem Beispiel oben werden die Bilder im Root-Ordner gespeichert.' },
        { parColumn: 'FTP Modus', descColumn: 'Hier können Sie zwischen (PASV) und dem aktiven Modus (PORT) wählen. Der Standardmodus ist PORT mit der die Kamera dem Server Ihre IP-Adresse und Port mitteilt. Dieser Modus funktioniert auch mit passiven FTP-Servern. Wenn sich der Client hinter einem Router befindet, der NAT benutzt, oder eine Firewall die Verbindung blockiert, sollte der PASV-Modus verwendet werden.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

FeatureTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(FeatureTable)