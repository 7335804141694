import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'Home Assistant',
      href: '/de/Software/Windows/Blue_Iris_v5/Home_Assistant/',
    },
    {
      text: 'ioBroker',
      href: '/de/Software/Windows/Blue_Iris_v5/ioBroker/',
    },
    {
      text: 'Node-RED',
      href: '/de/Software/Windows/Blue_Iris_v5/Node-RED/',
    },
    {
      text: 'INSTAR MQTT',
      href: '/de/Software/Windows/Blue_Iris_v5/INSTAR_MQTT/',
    },
    {
      text: '◄ Blue Iris v5',
      href: '/de/Software/Windows/Blue_Iris_v5/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={6}
        aria-label="Blue Iris Version 5"
      />
      <EuiSpacer />
    </Fragment>
  );
}