import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Angehängte Alarm Trigger">
        <EuiText>
          <p>Der Ursprung einer Aufnahme (Video oder Einzelbild) auf der SD Karte oder dem FTP Server, kann man anhand des Vorgestellten Buchstabens im Dateinamen ableiten.</p>
          <ul>
            <li class="whitetext"><p>Alarmaufnahmen:  <strong>A</strong></p></li>
            <li class="whitetext"><p>Geplante Aufnahme / Fotoserie:  <strong>P</strong></p></li>
            <li class="whitetext"><p>Manuelle Aufnahme:  <strong>N</strong></p></li>
            <li class="whitetext"><p>FTP Test:  <strong>T</strong></p></li>
          </ul>
          <p><u>Beispiel</u>: Die Datei <strong>P17073016245100.jpg</strong> stammt aus einer Fotoserie und wurde am 2017 (Jahr). 07 (Monat). 30 (Tag) um 16:24:51 aufgenommen. Bei den letzten beiden Ziffern handelt es sich um eine fortlaufende Nummer, die Bilder einem auslösenden Ereignis zuweisen - wenn man einstellt bei einem Alarm 6 Bilder zu speichern, wird hier von <strong>00</strong> bis <strong>05</strong> hochgezählt.
          Bei der Datei <strong>A170730_171158_171212.avi</strong> handelt es sich um eine Alarmvideoaufnahme, die am 30 Juli 2017 zwischen 17:11:58 und 17:12:12 aufgenommen wurde.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

