import React from 'react'
import {Link} from 'gatsby'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import qsUserManualCable from "../../../../images/Outdoor_Cameras/IN-5905_HD/Usermanual/IN-5905HD_Cable-Installation.png"
import qsUserManualLense from "../../../../images/Outdoor_Cameras/IN-5905_HD/Usermanual/IN-5905HD_Lense-Adjustment.png"
import qsUserManualHeater from "../../../../images/Outdoor_Cameras/IN-5905_HD/Usermanual/IN-5905HD_Heater-Installation.png"
import qsUserManualSD from "../../../../images/Outdoor_Cameras/IN-5905_HD/Usermanual/Youtube_SD_Card.png"




export default function ManualArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <Link to="/Aussenkameras/IN-5905_HD/Video/Cable/">
              <EuiCard
                textAlign="left"
                titleElement="h5"
                titleSize="xs"
                image={
                    <img
                      src={qsUserManualCable}
                      alt="IN-5905 HD Verbindungskabel"
                    />
                }
                title='IN-5905 HD Connection Cable'
              />
            </Link>
          </EuiFlexItem>
          <EuiFlexItem>
            <Link to="/Aussenkameras/IN-5905_HD/Video/Mount/">
              <EuiCard
                textAlign="left"
                titleElement="h5"
                titleSize="xs"
                image={
                    <img
                      src={qsUserManualLense}
                      alt="IN-5905 HD Adjust the wallmount"
                    />
                }
                title='IN-5905 HD Wandbesfestigung'
              />
            </Link>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <Link to="/Aussenkameras/IN-5905_HD/Video/Lense_Adjustment/">
              <EuiCard
                textAlign="left"
                titleElement="h5"
                titleSize="xs"
                image={
                    <img
                      src={qsUserManualLense}
                      alt="IN-5905 HD Lense Adjustment"
                    />
                }
                title='IN-5905 HD Objektivjustage'
              />
            </Link>
          </EuiFlexItem>
          <EuiFlexItem>
            <Link to="/Aussenkameras/IN-5905_HD/Video/Heater/">
              <EuiCard
                textAlign="left"
                titleElement="h5"
                titleSize="xs"
                image={
                    <img
                      src={qsUserManualHeater}
                      alt="IN-5905 HD Heater Installation"
                    />
                }
                title='IN-5905 HD Heizelement Installation'
              />
            </Link>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <Link to="/Aussenkameras/IN-5905_HD/Video/SD_Card/">
              <EuiCard
                textAlign="left"
                titleElement="h5"
                titleSize="xs"
                image={
                    <img
                      src={qsUserManualSD}
                      alt="IN-5905 HD swap the MicroSD Card"
                    />
                }
                title='IN-5905 HD MicroSD Karte tauschen'
              />
            </Link>
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
