import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Alarm Areas">
        <EuiText>
          <p>
            Die Alarmbereiche entdecken Bewegung durch eine Bildanalyse, die auf jede Veränderung im Videobild reagiert. Sie können bis zu 4 Alarmbereiche auswählen - einfach den Bereich aktivieren und den Bereich in das Bild einzeichnen - und die Sensibilität für das Auslösen eines Alarms einstellen. Ist die Bewegungserkennung in einem Bereich aktiviert, können Sie die Sensibilität zwischen sehr unsensibel (Wert=0) und sehr sensibel (Wert=100) einstellen.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

