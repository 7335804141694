import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_Home_Assistant_Tutorials/PrimaryBox';
import PrimaryBox1 from 'components/Software/Linux/Home_Assistant/PrimaryBox1';
import PrimaryBox2 from 'components/Software/Linux/Home_Assistant/PrimaryBox2';
import DangerBox from 'components/Software/Linux/Home_Assistant/DangerBox';
import NavButtons from 'components/Software/Linux/NavButtons';
import ForumBox from 'components/Software/Linux/Home_Assistant/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  PrimaryBoxInfo,
  PrimaryBox1,
  PrimaryBox2,
  DangerBox,
  NavButtons,
  ForumBox,
  BreadCrumbs,
  SEOHelmet,
  React
};