import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function LatestArticle() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/720p_Serie_CGI_Befehle/Alarm_Menu/Aktionen/">
                  <StaticImage
                    src='../../../images/720p_Series_CGI_List/Alarm_Menu/WebUI-Alarm_Actions_square.png'
                    alt="720p Serie CGI List - Alarm Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Aktionen"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/720p_Serie_CGI_Befehle/Alarm_Menu/Alarm_Server/">
                  <StaticImage
                    src='../../../images/720p_Series_CGI_List/Alarm_Menu/WebUI-Alarm_Alarmserver_square.png'
                    alt="720p Serie CGI List - Alarm Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Alarm Server"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/720p_Serie_CGI_Befehle/Alarm_Menu/Bereiche/">
                  <StaticImage
                    src='../../../images/720p_Series_CGI_List/Alarm_Menu/WebUI-Alarm_Areas_square.png'
                    alt="720p Serie CGI List - Alarm Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Bereiche"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/720p_Serie_CGI_Befehle/Alarm_Menu/Audio_Alarm/">
                  <StaticImage
                    src='../../../images/720p_Series_CGI_List/Alarm_Menu/WebUI-Alarm_Audio_square.png'
                    alt="720p Serie CGI List - Alarm Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Audio Alarm"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/720p_Serie_CGI_Befehle/Alarm_Menu/Email/">
                  <StaticImage
                    src='../../../images/720p_Series_CGI_List/Alarm_Menu/WebUI-Alarm_Email_square.png'
                    alt="720p Serie CGI List - Alarm Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Email"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/720p_Serie_CGI_Befehle/Alarm_Menu/FTP/">
                  <StaticImage
                    src='../../../images/720p_Series_CGI_List/Alarm_Menu/WebUI-Alarm_FTP_square.png'
                    alt="720p Serie CGI List - Alarm Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="FTP"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/720p_Serie_CGI_Befehle/Alarm_Menu/Privat_Maske/">
                  <StaticImage
                    src='../../../images/720p_Series_CGI_List/Alarm_Menu/WebUI-Alarm_Privacy_square.png'
                    alt="720p Serie CGI List - Alarm Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Privacy Mask"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/720p_Serie_CGI_Befehle/Alarm_Menu/Zeitplan/">
                  <StaticImage
                    src='../../../images/720p_Series_CGI_List/Alarm_Menu/WebUI-Alarm_Schedule_square.png'
                    alt="720p Serie CGI List - Alarm Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Zeitplan"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/720p_Serie_CGI_Befehle/Alarm_Menu/SD_Karte/">
                  <StaticImage
                    src='../../../images/720p_Series_CGI_List/Alarm_Menu/WebUI-Alarm_SD_square.png'
                    alt="720p Serie CGI List - Alarm Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="SD Karte"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/720p_Serie_CGI_Befehle/Alarm_Menu/SMTP_Server/">
                  <StaticImage
                    src='../../../images/720p_Series_CGI_List/Alarm_Menu/WebUI-Alarm_SMTP_square.png'
                    alt="720p Serie CGI List - Alarm Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="SMTP Server"
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
