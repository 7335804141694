import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Powerline vs Power-over-Ethernet">
        <EuiText>
           <p>Bei <strong>Powerline</strong> wird die Netzwerkanbindung der Kamera über das Stromnetz aufgebaut. Die Kamera muss dabei noch zusätzlich über das mitgelieferte Netzteil mit Strom versorgt werden. Bei <strong>Power-over-Ethernet</strong> läuft sowohl das Netzwerk, als auch die Stromversorgung, direkt über das Netzwerkkabel. Es wird jedoch ein <Link to="/Schnell_Installation/Power_over_Ethernet/">PoE Injektor</Link> oder <Link to="/Schnell_Installation/Power_over_Ethernet/">PoE Switch</Link> benötigt, der den Strom auf das Kabel legt.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

