import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Table,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-material-ui";

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word",
  },
};

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
);

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0,
    }}
    {...restProps}
  />
);

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class PhotoScheduleTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Relais Zeitplan" },
        { name: "descriptionColumn", title: "Beschreibung" },
      ],
      rows: [
        {
          actionColumn: "Täglichen Schaltplan für das Relais aktivieren",
          descriptionColumn:
            "Um einen Zeitplan hinzuzufügen, klicken Sie auf das grüne Plus-Symbol, selektieren Sie die Tage von Mo - So, an denen das Relais schalten soll, und stellen Sie eine Zeit von 0:00 bis 23:59 Uhr ein.",
        },
      ],
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{ display: "inline-block", maxWidth: "915px" }}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

PhotoScheduleTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PhotoScheduleTable);
