import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function LatestArticle() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1080p_Serie_CGI_Befehle/System_Menu/Info/">
                  <StaticImage
                    src='../../../images/1080p_Series_CGI_List/System_Menu/1080pCGIs-WebUI_System_Overview.png'
                    alt="1080p Serie CGI List - System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Overview"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1080p_Serie_CGI_Befehle/System_Menu/Benutzer/">
                  <StaticImage
                    src='../../../images/1080p_Series_CGI_List/System_Menu/1080pCGIs-WebUI_System_User.png'
                    alt="1080p Serie CGI List - System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="User"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1080p_Serie_CGI_Befehle/System_Menu/Datum_Uhrzeit/">
                  <StaticImage
                    src='../../../images/1080p_Series_CGI_List/System_Menu/1080pCGIs-WebUI_System_Date.png'
                    alt="1080p Serie CGI List - System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Date & Time"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1080p_Serie_CGI_Befehle/System_Menu/Sprache/">
                  <StaticImage
                    src='../../../images/1080p_Series_CGI_List/System_Menu/1080pCGIs-WebUI_System_Language.png'
                    alt="1080p Serie CGI List - System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Language"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1080p_Serie_CGI_Befehle/System_Menu/System_Log/">
                  <StaticImage
                    src='../../../images/1080p_Series_CGI_List/System_Menu/1080pCGIs-WebUI_System_Log.png'
                    alt="1080p Serie CGI List - System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="System Log"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1080p_Serie_CGI_Befehle/System_Menu/Update/">
                  <StaticImage
                    src='../../../images/1080p_Series_CGI_List/System_Menu/1080pCGIs-WebUI_System_Update.png'
                    alt="1080p Serie CGI List - System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Update"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1080p_Serie_CGI_Befehle/System_Menu/Neustart/">
                  <StaticImage
                    src='../../../images/1080p_Series_CGI_List/System_Menu/1080pCGIs-WebUI_System_Reboot.png'
                    alt="1080p Serie CGI List - System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Reboot"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1080p_Serie_CGI_Befehle/System_Menu/Werksreset/">
                  <StaticImage
                    src='../../../images/1080p_Series_CGI_List/System_Menu/1080pCGIs-WebUI_System_Reboot.png'
                    alt="1080p Serie CGI List - System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Reset"
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
