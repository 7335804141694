import React from 'react'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="9JCRp329I0I"
                    title="INSTAR IN-7011 HD Unboxing"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              footer={
                <EuiText>
                  <p>Die IN-7011 HD ist eine wetterfeste Dome-Kamera, die sowohl im Innen- als auch im Außenbereich eingesetzt werden kann. Ein komfortabler Motor ermöglicht eine schnelle Nachjustierung der Blickrichtung des Objektivs und kann über ein beliebiges Netzwerkgerät wie ein Tablet oder ein Smartphone gesteuert werden. Die eingebauten Infrarotdioden ermöglichen die Sicht in stockdunklen Umgebungen, während der integrierte IR-Sperrfilter die Darstellung von leuchtenden Farben, wie sie das menschliche Auge sieht, unterstützt. Durch den Einsatz eines effizienten h.264-Kompressionscodecs ist sie in der Lage, Videodateien auf einer MicroSD-Speicherkarte mit vergleichsweise geringer Größe aber besten Bildergebnissen aufzuzeichnen.</p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}
