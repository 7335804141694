import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import { OutboundLink } from 'gatsby-plugin-gtag';
import YoutubeVideoCloud from 'components/Motion_Detection/INSTAR_Cloud/Create_User_Account/YoutubeVideoCardCloud';
import YoutubeVideoCardAlarm from 'components/Web_User_Interface/1440p_Series/Alarm/Actions/YoutubeVideoCardAlarm';
import YoutubeVideoCardAlarmAreas from 'components/Web_User_Interface/1440p_Series/Alarm/Areas/YoutubeVideoCardAlarmAreas';
import NavButtons from 'components/Motion_Detection/INSTAR_Cloud/NavButtons';
import ForumBox from 'components/Motion_Detection/INSTAR_Cloud/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  EuiSpacer,
  OutboundLink,
  YoutubeVideoCloud,
  YoutubeVideoCardAlarm,
  YoutubeVideoCardAlarmAreas,
  NavButtons,
  ForumBox,
  BreadCrumbs,
  SEOHelmet,
  React
};