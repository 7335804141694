import React from 'react'
import { Link } from 'gatsby'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

import cloudSearchIcon from '../../../images/Search/MD_SearchThumb_Cloud.webp'
import cloudAccountSearchIcon from '../../../images/Search/MD_SearchThumb_Cloud_Create.webp'
import cloudStorageSearchIcon from '../../../images/Search/MD_SearchThumb_Cloud_Storage.webp'
import cloudManagementSearchIcon from '../../../images/Search/MD_SearchThumb_Cloud_Management.webp'
import cloudAdministrationSearchIcon from '../../../images/Search/MD_SearchThumb_Cloud_Administration.webp'
import cloudRecordingsSearchIcon from '../../../images/Search/MD_SearchThumb_Cloud_Recordings.webp'
import cloudSetsSearchIcon from '../../../images/Search/MD_SearchThumb_Cloud_Sets.webp'


export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/INSTAR_Cloud/Benutzer_Account_Erstellen/">
                  <img
                    src={cloudAccountSearchIcon}
                    alt="User Account"
                  />
                </Link>
              }
              title="Benutzer Account"
              description="Um sich bei der INSTAR Cloud anzumelden, müssen Sie sich zunächst mit Ihrer E-Mail-Adresse und einem von Ihnen gewählten Passwort registrieren."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/INSTAR_Cloud/Benutzer_Account_Erstellen/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/INSTAR_Cloud/Speicherplatz/">
                  <img
                    src={cloudStorageSearchIcon}
                    alt="Storage"
                  />
                </Link>
              }
              title="Speicherplatz"
              description="Jedes Cloud-Konto erhält 10 GB Speicherplatz für 30 Tage, um die Cloud zu testen. Sie können Ihren Speicherplatz durch einen Gutschein erweitern."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/INSTAR_Cloud/Speicherplatz/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/INSTAR_Cloud/Benutzer/">
                  <img
                    src={cloudManagementSearchIcon}
                    alt="Management"
                  />
                </Link>
              }
              title="Benutzerverwaltung"
              description="Verwalten Sie Benutzerkonten mit unterschiedlichen Zugriffsrechten in drei Benutzerebenen auf Ihrem Cloud-Server."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/INSTAR_Cloud/Benutzer/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/INSTAR_Cloud/Administration/">
                  <img
                    src={cloudAdministrationSearchIcon}
                    alt="Administration"
                  />
                </Link>
              }
              title="Administration"
              description="Das folgende Video gibt einen Überblick über alle Funktionen, die dem Cloud-Administrator zur Verfügung stehen."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/INSTAR_Cloud/Administration/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/INSTAR_Cloud/Alarm_Aufnahmen/">
                  <img
                    src={cloudRecordingsSearchIcon}
                    alt="Alarm Recordings"
                  />
                </Link>
              }
              title="Alarm Aufnahmen"
              description="Zeigen Sie Ihre Alarmaufnahmen an, löschen Sie unwichtige Videos oder markieren Sie wichtige Aufnahmen, damit sie nicht gelöscht werden."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/INSTAR_Cloud/Alarm_Aufnahmen/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/INSTAR_Cloud/Kamera_Sets/">
                  <img
                    src={cloudSetsSearchIcon}
                    alt="Camera Sets"
                  />
                </Link>
              }
              title="Kamera Sets"
              description="Organisieren Sie Ihre integrierten Kameras in Sets und vergeben Sie unterschiedliche Zugriffsrechte für Benutzer."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/INSTAR_Cloud/Kamera_Sets/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/INSTAR_Cloud/Cloud_Erweiterungen/">
                  <img
                    src={cloudSearchIcon}
                    alt="Cloud Erweiterungen"
                  />
                </Link>
              }
              title="Cloud Erweiterungen"
              description="Eine Liste der verfügbaren Funktionserweiterungen für Ihr INSTAR Cloud-Konto. Alexa, IFTTT, Gesichts- und Nummernschilderkennung."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/INSTAR_Cloud/Cloud_Erweiterungen/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Bewegungserkennung/INSTAR_Cloud/Account_Loeschen/">
                  <img
                    src={cloudAdministrationSearchIcon}
                    alt="Account Löschen"
                  />
                </Link>
              }
              title="Account Löschen"
              description="Um Ihren Account und alle damit verbundenen Aufnahmen zu löschen, folgen Sie bitte den folgenden Anweisungen."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Bewegungserkennung/INSTAR_Cloud/Account_Loeschen/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
