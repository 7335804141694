import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Serie/System/Info/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_System_Info.webp'
                    alt="System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Info"
              description="Info ist einfach eine Sammlung von vielen Systeminterna, die interessant sind, wenn Sie mit der Fehlersuche in Ihrer Einrichtung beginnen müssen. Ihr DDNS-Zugang funktioniert nicht?"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/720p_Serie/System/Info/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Serie/System/Datum_Uhrzeit/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_System_Date_Time.webp'
                    alt="System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Datum & Uhrzeit"
              description="Ihre Kamera kontaktiert automatisch einen sogenannten NTP-Server, um die interne Uhr zu stellen. Es wird empfohlen, diese Funktion immer eingeschaltet zu lassen..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/720p_Serie/System/Datum_Uhrzeit/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Serie/System/Benutzer/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_System_User.webp'
                    alt="System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Benutzerverwaltung"
              description="Ihre Kamera wird mit 3 Benutzerklassen - Administrator, Benutzer und Besucher - und einem Passwort für alle - instar - eingerichtet. Bitte ändern Sie dieses so schnell wie..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/720p_Serie/System/Benutzer/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Serie/System/Status_LED/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_System_Status_LED.webp'
                    alt="System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Status LED"
              description="Ist Ihnen die blaue und blinkende LED an der Kamera aufgefallen? Sie kann in einem dunklen Raum sehr störend sein. Aber sie kann zur Fehlersuche bei Ihrer Netzwerkverbindung genutzt werden..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/720p_Serie/System/Status_LED/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Serie/System/IR_LEDs/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_System_IR_LEDs.webp'
                    alt="System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IR LEDs"
              description="Die IR-LEDs Ihrer Kamera werden durch den internen Lichtsensor automatisch ein- und ausgeschaltet. Wenn Sie Ihre Kamera hinter einem stark reflektierenden Fenster verwenden möchten..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/720p_Serie/System/IR_LEDs/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Serie/System/ONVIF/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_System_ONVIF.webp'
                    alt="System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="ONVIF"
              description="Die Überwachungsindustrie hat ein offenes Standardprotokoll namens ONVIF geschaffen, das es Kameras ermöglicht, untereinander und mit Netzwerkaufzeichnungsgeräten zu kommunizieren..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/720p_Serie/System/ONVIF/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Serie/System/PTZ/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_System_PTZ.webp'
                    alt="System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Pan & Tilt"
              description="Deaktivieren Sie den Kalibrierungslauf Ihrer Kamera beim Start (deaktiviert auch die gespeicherten Positionen). Lassen Sie Ihre Kamera nach einem Neustart auf eine gespeicherte Position gehen oder begrenzen..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/720p_Serie/System/PTZ/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Serie/System/PTZ_Tour/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_System_PTZ_Tour.webp'
                    alt="System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Pan & Tilt Tour"
              description="Deactivate your camera´s calibration run at start-up (will also deactivate the saved positions). Let your camera go to a saved position after a restart or limit..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/720p_Serie/System/PTZ_Tour/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Serie/System/System_Logbuch/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_System_Log.webp'
                    alt="System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="System Logbuch"
              description="Funktioniert etwas nicht mehr? Prüfen Sie die Systemprotokolldatei auf Informationen. Sie erhalten keine Alarm-E-Mails mehr, aber Ihre SMTP-Einstellungen sehen in Ordnung aus?"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/720p_Serie/System/System_Logbuch/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
