import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Pan, Tilt & Zoom">
        <EuiText>
          <p>
            Lassen Sie Ihre Kamera nach dem Neustart auf eine gespeicherte Position fahren oder limitieren Sie die Dreh- und Schwenkbewegung.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

