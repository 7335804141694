import React from 'react'
import { Link } from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

  function PrimaryBox() {
    return (
      <EuiCallOut size="m" iconType="alert" title="Home Assistant">
          <EuiText>
           <h2>Alle ioBroker Tutorials</h2>
           <ul>
              <li><Link to="/Software/Linux/Home_Assistant/">Home Assistant mit Docker Installieren (Aktualisiert: 04.04.2024</Link></li>
              <li><Link to="/Advanced_User/INSTAR_MQTT_Broker/Home_Assistant/">INSTAR MQTT Server mit Home Assistant</Link></li>
              <li><Link to="/Erweitert/Home_Assistant_auf_Raspberry_Pi/">Home Assistant auf einem Raspberry Pi</Link></li>
           </ul>
           
           <h2>All ioBroker FAQs</h2>
           <ul>
             <li><Link to="/Frequently_Asked_Question/Home_Assistant_INSTAR_WQHD_MQTTv5_Camera_Control/">Home Assistant 2022.8.6 Konfiguration für Ihren WQHD INSTAR MQTTv5 Broker</Link></li>
             <li><Link to="/Frequently_Asked_Question/Home_Assistant_INSTAR_WQHD_Camera_Live_Video/">Home Assistant 2022.8.6 und der INSTAR WQHD Live Videostream</Link></li>
             <li><Link to="/Frequently_Asked_Question/INSTAR_MQTT_Home_Assistant_Self-Signed_Certificate/">INSTAR_MQTT und selbstsigniertes Zertifikat mit Home Assistant</Link></li>
           </ul>
          </EuiText>
      </EuiCallOut>
    );
  }

export default PrimaryBox