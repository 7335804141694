import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'IN-Route P52',
      href: '/de/Downloadbereich/Netzwerk_Utensilien/IN-Route_P52/',
    },
    {
      text: 'IN-PoE 1000',
      href: '/de/Downloadbereich/Netzwerk_Utensilien/IN-PoE_1000/',
    },
    {
      text: 'IN-LAN 500',
      href: '/de/Downloadbereich/Netzwerk_Utensilien/IN-LAN_500/',
    },
    {
      text: 'Netzwerk Zubehör',
      href: '/de/Downloadbereich/Netzwerk_Utensilien/',
    },
    {
      text: '◄ Downloadbereich',
      href: '/de/Downloadbereich/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={11}
        aria-label="Downloadbereich for your INSTAR IP Camera"
      />
      <EuiSpacer />
    </Fragment>
  );
}