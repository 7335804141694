import React from 'react'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import avmImage01 from "../../../../images/Software/Other_Platforms/AVM_Fritzphone/FritzphoneC4_01.webp"
import avmImage02 from "../../../../images/Software/Other_Platforms/AVM_Fritzphone/FritzphoneC4_02.webp"
import avmImage03 from "../../../../images/Software/Other_Platforms/AVM_Fritzphone/FritzphoneC4_03.webp"
import avmImage04 from "../../../../images/Software/Other_Platforms/AVM_Fritzphone/FritzphoneC4_04.webp"
import avmImage05 from "../../../../images/Software/Other_Platforms/AVM_Fritzphone/FritzphoneC4_05.webp"
import avmImage06 from "../../../../images/Software/Other_Platforms/AVM_Fritzphone/FritzphoneC4_06.webp"
import avmImage07 from "../../../../images/Software/Other_Platforms/AVM_Fritzphone/FritzphoneC4_07.webp"
import avmImage08 from "../../../../images/Software/Other_Platforms/AVM_Fritzphone/FritzphoneC4_08.webp"


export default function PoECards() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={avmImage01}
                    alt="AVM Fritzphone C4 for your INSTAR IP Camera"
                  />
              }
              description={
                <p><strong>Fig1.</strong> Drücken Sie auf der Tastatur des Telefons die Taste Menu.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={avmImage02}
                    alt="AVM Fritzphone C4 for your INSTAR IP Camera"
                  />
              }
              description={
                <p><strong>Fig2.</strong> Blättere Sie bitte zum Menüpunkt Heimnetz und drücken Sie OK.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={avmImage03}
                    alt="AVM Fritzphone C4 for your INSTAR IP Camera"
                  />
              }
              description={
                <p><strong>Fig3.</strong> Wähle Sie danach bitte Live-Bild und drücken Sie OK.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={avmImage04}
                    alt="AVM Fritzphone C4 for your INSTAR IP Camera"
                  />
              }
              description={
                <p><strong>Fig4.</strong> Wählen Sie als nächstes Neu.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={avmImage05}
                    alt="AVM Fritzphone C4 for your INSTAR IP Camera"
                  />
              }
              description={
                <p><strong>Fig5.</strong> Geben Sie im Eingabefeld Name einen beliebigen Namen für die IP-Kamera ein.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={avmImage06}
                    alt="AVM Fritzphone C4 for your INSTAR IP Camera"
                  />
              }
              description={
                <p><strong>Fig6.</strong> Gebe im Eingabefeld URL den JPG Pfad Ihrer Kamera ein.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={avmImage07}
                    alt="AVM Fritzphone C4 for your INSTAR IP Camera"
                  />
              }
              description={
                <p><strong>Fig7.</strong> Nach dem festlegen des Zeitintervalls und Speichern erscheint im Display der Namen Ihrer IP-Kamera.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={avmImage08}
                    alt="AVM Fritzphone C4 for your INSTAR IP Camera"
                  />
              }
              description={
                <p><strong>Fig8.</strong> Drücke Sie dann auf "OK" und Ihnen wird das Live-Bild auf dem Display des FRITZ!Fon angezeigt.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
