import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class NetTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Netzwerk Einstellunge" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'IP Adresse', descriptionColumn: 'Dies ist die IP-Adresse Ihrer Kamera innerhalb Ihres lokalen Netzwerks. Um auf die Weboberfläche Ihrer Kamera zuzugreifen, tippen Sie einfach die IP-Adresse Ihrer Kamera in die Adressleiste Ihres Browsers ein. Wenn der LAN Port Ihrer Kamera nicht 80 ist, fügen Sie bitte den Port hinter die IP-Adresse, getrennt durch ein : , wie zum Beispiel 192.168.1.125:8081.' },
        { actionColumn: 'Subnetzmaske', descriptionColumn: 'Die Subnetzmaske definiert das Subnetz, in dem Ihre Kamera verbunden ist. In den meisten kleinen Netzwerken ist das Subnetz 255.255.255.0.' },
        { actionColumn: 'Gateway', descriptionColumn: 'Das Gateway ist die Verbindung zwischen Ihrem lokalen Netzwerk und dem Internet. In den meisten kleinen Netzwerken ist das die IP-Adresse Ihres Routers - zum Beispiel 192.168.1.1, 192.168.2.1 oder 192.168.178.1.' },
        { actionColumn: 'DNS Server', descriptionColumn: 'Den DNS Server benötigt Ihre Kamera um die Internetadressen aufzulösen. In den meisten kleinen Netzwerken ist das die IP-Adresse Ihres Routers - zum Beispiel 192.168.1.1, 192.168.2.1 oder 192.168.178.1.' },
        { actionColumn: 'LAN Port', descriptionColumn: 'The LAN Port ist der HTTP Port Ihrer Kamera. Fügen Sie diesen Port hinter die IP-Adresse Ihrer Kamera, wenn Sie die Weboberfläche über Ihren Browser erreichen möchten. Das ist auch der Port, den Sie in Ihrem Router weiterleiten müssen, um Ihre Kamera über die DDNS Adresse zu erreichen. Wir empfehlen einen HTTP Port zu verwenden, der höher ist als 8080.' },
        { actionColumn: 'RTMP (Flash) Port', descriptionColumn: 'Der RTMP Port ist der Video Streaming Port für das Flash Video Plugin. Bitte leiten Sie diesen Port weiter, wenn Sie die Weboberfläche über das Internet erreichen möchten. Sie müssen den Port nicht weiterleiten, wenn Sie die Kamera über Ihre Smartphone App erreichen möchten!' },
        { actionColumn: 'Aktiviere RTSP Authentifikation', descriptionColumn: 'Wenn Sie eine Streaming Software verwenden möchten, wie den VLC Player um den RTSP Stream zu erhalten, haben Sie die Option, die Authentifikation zu deaktivieren. Der Stream wird in Ihrem Netzwerk frei verfügbar. Bitte stellen Sie sicher, dass die Authentifikation vor der Weiterleitung des RTSP Ports aktiv ist!' },
        { actionColumn: 'RTSP Port', descriptionColumn: 'Der RTSP Stream Ihrer Kamera läuft über den RTSP Port. Stellen Sie sicher, dass der Port in Ihrem Router weitergeleitet ist, wenn Sie Ihn über Ihre DDNS Adresse erreichen möchten.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

NetTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(NetTable)