import React from 'react'
import {Link} from 'gatsby'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText
} from '@elastic/eui'

import qiSelect01 from "../../../../../images/Outdoor_Cameras/IN-9008_HD/Quick_Installation/Ethernet/Quickinstallation_Tile_9008_Step_19.webp"
import qiSelect02 from "../../../../../images/Outdoor_Cameras/IN-9008_HD/Quick_Installation/Ethernet/Quickinstallation_Tile_9008_Step_20.webp"




export default function QIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              title='III-j DDNS Access'
              image={
                  <img
                    src={qiSelect01}
                    alt="Now you can enter your camera´s DDNS information to our free Windows Phone, Windows Metro, Android, iPhone, iPad or Blackberry App."
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">Jetzt können Sie die DDNS-Informationen Ihrer Kamera in unser kostenlose <Link to="/Software/Windows/InstarVision/Windows_Phone/DDNS/">Windows Phone</Link>, <Link to="/Software/Windows/InstarVision/Metro/DDNS/">Windows Metro</Link>, <Link to="/Software/Android/InstarVision/DDNS/">Android</Link>, <Link to="/Software/iOS/InstarVision/iPhone/DDNS/">iPhone</Link>, <Link to="/Software/iOS/InstarVision/iPad/DDNS/">iPad</Link> oder <Link to="/Software/Other_Platforms/InstarVision_Blackberry/">Blackberry</Link> App eingeben.</EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              title='III-k P2P Access'
              image={
                  <img
                    src={qiSelect02}
                    alt="If you cannot open a port for camera´s DDNS service, you can still use the POINT-2-POINT SERVICE instead with our Windows Phone, Windows Metro, Android, iPhone, iPad or Blackberry App."
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">Wenn Sie keinen Port für den DDNS-Dienst der Kamera öffnen können, können Sie stattdessen den <Link to="/Web_User_Interface/1080p_Serie/Netzwerk/Fernzugriff/">Point-2-Point Service</Link> mit unserer  <Link to="/Software/Windows/InstarVision/Windows_Phone/P2P/">Windows Phone</Link>, <Link to="/Software/Windows/InstarVision/Metro/P2P/">Windows Metro</Link>, <Link to="/Software/Android/InstarVision/P2P/">Android</Link>, <Link to="/Software/iOS/InstarVision/iPhone/P2P/">iPhone</Link>, <Link to="/Software/iOS/InstarVision/iPad/P2P/">iPad</Link> oder <Link to="/Software/Other_Platforms/InstarVision_Blackberry/">Blackberry</Link> App verwenden.</EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
