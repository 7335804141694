import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="HTML5 Video:">
        <EuiText>
          <p>
            Die Verwendung des HTML5-Videos ermöglicht es Ihnen, das Live-Video innerhalb der WebUI ohne externe Plugins, z. B. ActiveX, Flash usw., anzuzeigen. Das bedeutet aber, dass die Arbeit, die von diesen Plugins geleistet wurde, nun von Ihrem Browser übernommen wird. Ältere Desktop-Computer wie auch mobile Geräte können es schwer haben, all diese Daten zu dekodieren, und das Video könnte anfangen zu stottern.
          </p>
          <p>
            Um dies zu verhindern, können Sie den Videostream auf seine mittlere Auflösung begrenzen. <strong>Hinweis</strong>: das in unseren Mobil- oder Desktop-Anwendungen angezeigte Video ist davon nicht betroffen. Auch die Alarmvideos werden immer in voller Auflösung aufgezeichnet!
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

