import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Software-basierte Bewegungserkennung">
        <EuiText>
          <p>
            Legen Sie fest, was Ihre Kamera im Alarmfall machen soll. Bestimmen Sie hier welche Aktionen die Kamera ausführen soll, wenn ein Alarmereignis ausgelöst wurde.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

