import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class InfoTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Camera Overview" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Kamera ID', descriptionColumn: 'Modellnummer Ihrer Kamera.' },
        { actionColumn: 'Firmware Version', descriptionColumn: 'Version des Betriebssystems Ihrer Kamera. Sie können die aktuellste Firmware/WebUI Version immer in unserem Download Bereich finden. Die Software wird über das Update Menü auf Ihrer Kamera hochgeladen.' },
        { actionColumn: 'WebUI Version', descriptionColumn: 'Version der Weboberfläche Ihrer Kamera. Sie können die aktuellste Firmware/WebUI Version immer in unserem Download Bereich finden. Die Software wird über das Update Menü auf Ihrer Kamera hochgeladen.' },
        { actionColumn: 'Netzwerk Schnittstelle', descriptionColumn: 'Gibt an, ob die Kamera per WiFi oder Kabel verbunden ist.' },
        { actionColumn: 'MAC Adresse', descriptionColumn: 'Hardware Identifikation Ihrer aktiven (LAN oder WLAN) Netzwerkschnittstelle.' },
        { actionColumn: 'SD Status', descriptionColumn: 'Gibt an, ob die SD Karte bereit ist und wieviel Speicherplatz noch verfügbar ist (ist die SD Karte voll werden die Dateien überschrieben (angefangen bei der ältesten Datei)).' },
        { actionColumn: 'IR LED Status', descriptionColumn: 'Betriebsmodus Ihrer Nachtsicht-LED -  werden entweder automatisch eingeschaltet (sobald es dunkel wird) oder sind permanent deaktiviert.' },
        { actionColumn: 'WAN IP Adresse', descriptionColumn: 'Wide Area Network Adresse Ihrer Internetverbindung. Das ist die Adresse zu der Ihre DDNS Adresse übersetzt wird, um Ihre Kamera aus dem Internet erreichbar zu machen (benötigt eine Portweiterleitung!).' },
        { actionColumn: 'DDNS Status', descriptionColumn: 'Diese Funktion überprüft, ob Ihre DDNS Adresse erfolgreich mit Ihrer tatsächlichen WAN-IP-Adresse aktualisiert wurde. Dies ist eine Voraussetzung, um Ihre Kamera aus dem Internet zu erreichen.' },
        { actionColumn: 'UPnP Status', descriptionColumn: 'Der UPnP Service versucht automatisch eine Portweiterleitung für den DDNS Zugriff einzurichten. Wir empfehlen den UPnP Service zu deaktivieren und stattdessen eine manuelle Portweiterleitung einzurichten.' },
        { actionColumn: 'Kamera aktiv seit', descriptionColumn: 'Up-Time Ihrer Kamera seit dem letzten Neustart.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

InfoTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(InfoTable)