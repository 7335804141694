import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class UpdateTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Update Settings" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Firmware Version', descriptionColumn: 'Versionsnummer des Betriebssystems Ihrer Kamera. Die Software Version wird Ihnen in Rot angezeigt, wenn eine neuere Version verfügbar ist.' },
        { actionColumn: 'WebUI Version', descriptionColumn: 'Versionsnummer des Web Benutzeroberfläche Ihrer Kamera. Die Software Version wird Ihnen in Rot angezeigt, wenn eine neuere Version verfügbar ist.' },
        { actionColumn: 'Status', descriptionColumn: 'Wenn eine neue Firmware verfügbar ist, können Sie mit einem Klick auf dieses Feld den Update-Prozess direkt starten. Es wird empfohlen diese Update-Variante zu verwenden, da diese simpel und schnell ist.' },
        { actionColumn: 'Offline-Update', descriptionColumn: 'Laden Sie hier die aktuelle Firmware als Datei herunter. Beachten Sie bitte, dass Sie die "update.pkg" Datei vor dem Update aus dem ZIP Container entpacken müssen. Klicken Sie anschliessend auf "Datei auswählen..." und wählen Sie die Datei "update.pkg" aus. Das Update wird gestartet, sobald Sie auf den Button "Updaten! geklickt und die Update-Meldung bestätigt haben.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

UpdateTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(UpdateTable)