import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Alarm Server">
        <EuiText>
          <p>
            Sie können die Alarmserver-Funktion nutzen um einen HTTP-Request zu einem verbundenen Server zu senden. Der Server benötigt eine kompatible Software um diesen Request als Alarm zu interpretieren und weitere Aktionen folgen zu lassen. Diese Funktion wird oft von Smart-Home Alarmsystemen unterstützt. Sie können eigene Kommandos zum Request hinzufügen, um spezielle Aktionen in der Serversoftware auszulösen.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

