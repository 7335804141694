import React from 'react'

import {Link} from 'gatsby'

import {
  EuiImage,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiSpacer
} from '@elastic/eui'

import ivImage01 from "../../../../../images/Software/Windows/InstarVision/Webserver/Image039_ActiveX.png"
import ivImage02 from "../../../../../images/Software/Windows/InstarVision/Webserver/Image039_WMP.png"
import ivImage03 from "../../../../../images/Software/Windows/InstarVision/Webserver/Image039_QT.png"
import ivImage04 from "../../../../../images/Software/Windows/InstarVision/Webserver/Image039_Flash.png"
import ivImage05 from "../../../../../images/Software/Windows/InstarVision/Webserver/Image039_JPG.png"


export default function ImageCard() {

  return (
    <React.Fragment>
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem grow={false}>
          <EuiImage
            hasShadow
            allowFullScreen
            alt="InstarVision v2 for Windows"
            src={ivImage01}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <p>
              <strong>ActiveX:</strong> Der ActiveX-Viewer-Modus kann bis zu 32 Videokanäle gleichzeitig auf Ihrer Webseite anzeigen. Klicken Sie auf __Setup__, um das Plugin für Ihre Anforderungen zu konfigurieren. Ihr Websitebenutzer muss das ActiveX-Plugin installieren, wenn er die Website zum ersten Mal betritt. Das ActiveX-Plugin wird nur von Microsoft Internet Explorer unterstützt. Wenn Sie Microsoft Edge, Firefox, Safari oder Chrome verwenden möchten, wählen Sie bitte ein anderes Plugin - oder gehen Sie ohne Plugin im Simple JPEG-Modus vor.
            </p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />
      
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem grow={false}>
          <EuiImage
            hasShadow
            allowFullScreen
            alt="InstarVision v2 for Windows"
            src={ivImage02}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <p>
              <strong>Windows Player:</strong> In diesem Ansichtsmodus wird Windows Media Player verwendet, um das Video Ihrer Kamera anzuzeigen. Das bedeutet, dass es nur unter Microsoft Windows mit installiertem Windows Media Player funktioniert! Auch das Aufzeichnungsdateiformat des Surveillance Centers muss für den Kanal, den Sie senden möchten, auf ASF oder WMV eingestellt sein. Um das Dateiformat zu ändern, gehen Sie zu den <Link to="/Software/Windows/InstarVision/Record/General/">Allgemeine Aufzeichnungseinstellungen</Link>, wählen Sie den gewünschten Kanal aus und wählen Sie das Format aus dem Dropdown-Menü.
            </p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />
      
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem grow={false}>
          <EuiImage
            hasShadow
            allowFullScreen
            alt="InstarVision v2 for Windows"
            src={ivImage03}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <p>
              <strong>QuickTime:</strong> In diesem Ansichtsmodus wird das Quicktime Player-Web-Plugin zum Anzeigen des Videos Ihrer Kamera verwendet. Bitte installieren Sie und den <Link to="/Schnell_Installation/Live_Video/QuickTime/">Quicktime Player</Link> auf Ihrem PC oder MAC, um einen Videostream von einer IP-Kamera empfangen zu können (setzen Sie das Streaming-Protokoll auf HTTP / Port 80). Außerdem muss das Aufnahme-Videodateiformat des Surveillance Centers für den Kanal, den Sie übertragen möchten, auf MP4 oder MOV eingestellt sein. Um das Dateiformat zu ändern, gehen Sie zu den <Link to="/Software/Windows/InstarVision/Record/General/">Allgemeine Aufnahme Einstellungen</Link>, wählen Sie den gewünschten Kanal aus und wählen Sie das Format aus dem Dropdown-Menü.
            </p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />
      
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem grow={false}>
          <EuiImage
            hasShadow
            allowFullScreen
            alt="InstarVision v2 for Windows"
            src={ivImage04}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <p>
              <strong>Flash:</strong> Dieser Viewer verwendet den Flash Player, um den Videostream Ihrer Kamera anzuzeigen. Sie müssen das <a href="https://get.adobe.com/flashplayer/" target="_blank" rel="noopener noreferrer">Adobe Flash plugin</a> in Ihrem Browser installieren. Das Aufzeichnungsvideodateiformat des Überwachungszentrums muss für den Kanal, den Sie senden möchten, auf FLV eingestellt sein. Um das Dateiformat zu ändern, gehen Sie zu den <Link to="/Software/Windows/InstarVision/Record/General/">Allgemeine Aufnahme Einstellungen</Link>, wählen Sie den gewünschten Kanal aus und wählen Sie das Format aus dem Dropdown-Menü.
            </p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />
      
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem grow={false}>
          <EuiImage
            hasShadow
            allowFullScreen
            alt="InstarVision v2 for Windows"
            src={ivImage05}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <p>
              <strong>JPG Mode:</strong> Dieser Viewer erhält einfach einzelne JPEG-Bilder aus dem Live-Stream Ihrer Kamera und zeigt sie kontinuierlich an, um ein Video zu erstellen. Aufgrund der Art dieses Prozesses wird kein Audiosignal von Ihrer Kamera ausgegeben. Aber __Sie brauchen keine Plugins__, um das Video anzuzeigen!
            </p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  );
}
