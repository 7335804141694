import React from 'react'

import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Note:">
        <EuiText>
          <p>Dies ist eine aktualisierte Anleitung, die Docker zur Installation von ioBroker verwendet, anstatt die Software direkt auf dem Host-System zu installieren. Dieser Leitfaden wurde auch aktualisiert, um mit INSTAR-Kameras neuerer Bauart als der Full HD-Serie verwendet werden zu können.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

