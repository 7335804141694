import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class PTZTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Pan, Tilt & Zoom" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Alarme während der Fahrt unterbinden', descriptionColumn: 'Möchten Sie die Bewegungserkennung der Kamera während eines Kamerschwenk deaktivieren, um Fehlalarme zu unterbinden.' },
        { actionColumn: 'Justagefahrt aktivieren', descriptionColumn: 'Aktivieren oder deaktivieren Sie den PTZ-Kalibrierungslauf der Kamera beim Hochfahren. Bitte beachten Sie, dass die [Gespeicherten Positionen](/Web_User_Interface/1080p_Series/Live_Video/) für Ihre Kamera nicht mehr verfügbar sind, wenn die Kalibrierung deaktiviert ist.' },
        { actionColumn: 'Umkehrung der horizontalen Bewegung', descriptionColumn: 'Schwenken nach links/rechts umkehren, wenn die Kamera verkehrt herum installiert ist.' },
        { actionColumn: 'Umkehrung der vertikalen Bewegung', descriptionColumn: 'Umkehrung der Neigung nach oben/unten, wenn die Kamera verkehrt herum installiert ist.' },
        { actionColumn: 'Start Position / Aktion aktivieren', descriptionColumn: 'Aktivieren Sie eine Position / Aktion, nach der sich die Kamera nach dem Startvorgang bewegen soll.' },
        { actionColumn: 'Start Position / Aktion auswählen', descriptionColumn: 'Wählen Sie eine gespeicherte Position, zu der Ihre Kamera nach dem Hochfahren wechseln soll, oder aktivieren Sie den vertikalen Scan oder Tour Funktion' },
        { actionColumn: 'Alarmposition aktivieren', descriptionColumn: 'Möchten Sie, dass Ihre Kamera beim Auslösen eines Alarms in eine bestimmte Richtung zeigt? Aktivieren Sie diese Funktion hier.' },
        { actionColumn: 'Alarmposition auswählen', descriptionColumn: 'Stellen Sie hier die Position ein, zu der Ihre Kamera im Falle eines Alarms fahren soll (siehe oben).' },
        { actionColumn: 'Parkposition automatisch anfahren', descriptionColumn: 'Möchtest du, dass deine Kamera standardmäßig immer in eine bestimmte Richtung zeigt? Aktivieren Sie die Parkposition, damit Ihre Kamera nach dem Verschieben automatisch zu dieser Position zurückkehrt.' },
        { actionColumn: 'Parkposition/Parkaktion auswählen', descriptionColumn: 'Stellen Sie hier die Parkposition ein (siehe oben).' },
        { actionColumn: 'Anfahren nach x Sekunden', descriptionColumn: 'Stellen Sie ein, wie lange die Kamera warten soll bevor die Parkposition wieder angefahren wird.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

PTZTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(PTZTable)