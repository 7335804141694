import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="ONVIF integration into InstarVision v2.1 Beta">
        <EuiText>
            <p>
            Die Funktion ist derzeit nur in den aktuellen Versionen der InstarVision v2 verfügbar. Diese können heruntergeladen werden aus userem <Link to="/Downloadbereich/Desktop_Software/InstarVision/">Downloadbereich</Link>.
            </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

