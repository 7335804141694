import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/1440p_Series_CGI_List/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import MoveTable from 'components/1440p_Series_CGI_List/Features_Menu/MoveTable';
import RelPosTable from 'components/1440p_Series_CGI_List/Features_Menu/RelPosTable';
import * as React from 'react';
export default {
  EuiSpacer,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  MoveTable,
  RelPosTable,
  React
};