import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function LatestArticle() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/720p_Serie_CGI_Befehle/Netzwerk_Menu/DDNS/">
                  <StaticImage
                    src='../../../images/720p_Series_CGI_List/Network_Menu/WebUI-Network_DDNS_square.png'
                    alt="720p Serie CGI List - Network Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="DDNS"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/720p_Serie_CGI_Befehle/Netzwerk_Menu/IP_Konfiguration/">
                  <StaticImage
                    src='../../../images/720p_Series_CGI_List/Network_Menu/WebUI-Network_IP-Config_square.png'
                    alt="720p Serie CGI List - Netzwerk Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IP Konfiguration"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/720p_Serie_CGI_Befehle/Netzwerk_Menu/P2P/">
                  <StaticImage
                    src='../../../images/720p_Series_CGI_List/Network_Menu/WebUI-Network_P2P_square.png'
                    alt="720p Serie CGI List - Netzwerk Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="P2P"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/720p_Serie_CGI_Befehle/Netzwerk_Menu/Push_Dienst/">
                  <StaticImage
                    src='../../../images/720p_Series_CGI_List/Network_Menu/WebUI-Network_Push_square.png'
                    alt="720p Serie CGI List - Netzwerk Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Push Dienst"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/720p_Serie_CGI_Befehle/Netzwerk_Menu/UPnP/">
                  <StaticImage
                    src='../../../images/720p_Series_CGI_List/Network_Menu/WebUI-Network_UPnP_square.png'
                    alt="720p Serie CGI List - Multimedia Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="UPnP"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/720p_Serie_CGI_Befehle/Netzwerk_Menu/WLAN/">
                  <StaticImage
                    src='../../../images/720p_Series_CGI_List/Network_Menu/WebUI-Network_WLAN_square.png'
                    alt="720p Serie CGI List - Multimedia Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="WLAN"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
