import React from 'react'
import {Link} from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-gtag'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText
} from '@elastic/eui'

import qiSelect01 from "../../../../images/Outdoor_Cameras/IN-7011_HD/Quick_Installation/Quickinstallation_IN-7011_01.webp"
import qiSelect02 from "../../../../images/Outdoor_Cameras/IN-7011_HD/Quick_Installation/Quickinstallation_IN-5905_02.webp"
import qiSelect03 from "../../../../images/Outdoor_Cameras/IN-7011_HD/Quick_Installation/Quickinstallation_03.webp"
import qiSelect04 from "../../../../images/Outdoor_Cameras/IN-7011_HD/Quick_Installation/Quickinstallation_IN-5905_04.webp"
import qiSelect05 from "../../../../images/Outdoor_Cameras/IN-7011_HD/Quick_Installation/Quickinstallation_IN-5905_05.webp"
import qiSelect06 from "../../../../images/Outdoor_Cameras/IN-7011_HD/Quick_Installation/Quickinstallation_06.webp"
import qiSelect07 from "../../../../images/Outdoor_Cameras/IN-7011_HD/Quick_Installation/Quickinstallation_07.webp"
import qiSelect08 from "../../../../images/Outdoor_Cameras/IN-7011_HD/Quick_Installation/Quickinstallation_08.webp"
import qiSelect09 from "../../../../images/Outdoor_Cameras/IN-7011_HD/Quick_Installation/Quickinstallation_09.webp"
import qiSelect10 from "../../../../images/Outdoor_Cameras/IN-7011_HD/Quick_Installation/Quickinstallation_12.webp"
import qiSelect11 from "../../../../images/Outdoor_Cameras/IN-7011_HD/Quick_Installation/Quickinstallation_13.webp"
import qiSelect12 from "../../../../images/Outdoor_Cameras/IN-7011_HD/Quick_Installation/Quickinstallation_14.webp"



export default function QIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect01}
                    alt="Schrauben Sie die WLAN Antenne auf den SMA Anschluss im hinteren Teil der Kamera um später eine WLAN Verbindung aufbauen zu können."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Schrauben Sie die WLAN Antenne auf den SMA Anschluss im hinteren Teil der Kamera um später eine WLAN Verbindung aufbauen zu können.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect02}
                    alt="Stecken Sie das Kabel des Netzteils in den DC Anschluss am Kabelbaum der Kamera, um diese mit Strom zu versorgen."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Stecken Sie das Kabel des Netzteils in den DC Anschluss am Kabelbaum der Kamera, um diese mit Strom zu versorgen.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect03}
                    alt="In Ihrem WLAN Router ist es empfohlen einen Kanal im Bereich von 1-6 (802.11bgn) eizustellen, sowie die WPA2/AES (CCMP) Verschlüsselung."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    In Ihrem WLAN Router ist es empfohlen einen Kanal im Bereich von 1-6 (802.11bgn) eizustellen, sowie die WPA2/AES (CCMP) Verschlüsselung.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect04}
                    alt="A Press the WPS button (s. above) on your router. B Press the camera´s reset button for 3s. Be aware that holding it for longer than 10s will result in a camera reset. C The camera will now automatically connect to your WiFi network. Please skip to step 7 (for PC or Mac) or 14 (for smartphone)."
                  />
              }
              footer={
                <EuiText>
                    <ul>
                      <li><strong>A</strong> Drücken Sie den WPS Button auf Ihrem Router.</li>
                      <li><strong>B</strong> Drücken Sie den Reset Taster der Kamera für 3s (länger als 10s führt zu einem Werksreset!).</li>
                      <li><strong>C</strong> Die Kamera wird sich danach automatisch mit Ihrem WLAN verbinden. Springen Sie zu Schritt 7 (PC oder Mac) oder Schritt 14 (Smartphone).</li>
                    </ul>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect05}
                    alt="Für eine LAN Verbindung, stecken Sie bitte das Netzwerkkabel in den RJ-45 Anschluss am Kabelbaum der Kamera um eine Verbindung aufzubauen (auch für WLAN benötigt wenn kein WPS vorhanden s. #4)."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Für eine LAN Verbindung, stecken Sie bitte das Netzwerkkabel in den RJ-45 Anschluss am Kabelbaum der Kamera um eine Verbindung aufzubauen (auch für WLAN benötigt wenn kein WPS vorhanden s. #4).
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect06}
                    alt="Stecken Sie das andere Ende des Netzwerkkabels in einen freien LAN Port Ihres Internet Routers."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Stecken Sie das andere Ende des Netzwerkkabels in einen freien LAN Port Ihres Internet Routers.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect07}
                    alt="Start our IP Camera Tool from the Software CD and double click on the name of the camera."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Starten Sie das <OutboundLink href="http://install.instar.com" target="_blank" rel="noopener noreferrer">IP Camera Tool</OutboundLink> von der Software CD und führen Sie einen Doppelklick auf den Namen der Kamera aus.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect08}
                    alt="Default Logins User/Password: admin / instar for the administrator, user / instar for normal users and guest / instar for restricted users. Our INSTALLATION WIZARD will lead you through the rest of the installation process."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Standard Logins Benutzer/Kennwort:  <strong>admin / instar</strong> für Administratoren, <strong>user / instar</strong> für normale Nutzer und <strong>guest / instar</strong> für Gäste. Unser <OutboundLink href="https://install.instar.com" target="_target">Installationsassistent</OutboundLink> führt Sie durch den restlichen Installationsprozess.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect09}
                    alt="Activate the WiFi card of your camera inside the webUI and start searching for WiFi signals. Select your WiFi, add your key and safe the settings. Restart your camera and unplug the LAN cable."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Aktivieren Sie das WLAN der Kamera in der <Link to="/Web_User_Interface/720p_Serie/Netzwerk/WLAN/">Weboberfläche</Link> mittels des WLAN- Scanners. Nach der Eingabe und Speicherung des WLAN Passwortes muss das LAN Kabel gezogen und die Kamera neugestartet werden.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect10}
                    alt="To access your camera over the internet, you have to forward the HTTP Port inside your router (default “80”) To access the Adobe Flash Plugin inside the WebUI, you also have to forward the RTMP Port (Standard “1935”)."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Für den Zugriff über das Internet ist eine Portweiterleitung des HTTP Ports (Standard “80”) in Ihrem Router notwendig. Für den Zugriff auf den <Link to="/Schnell_Installation/Live_Video/">Adobe Flash Plugin</Link> (z.B. über das Flash Plugin im Webbrowser) benötigen Sie noch die Weiterleitung des <Link to="/Web_User_Interface/720p_Serie/Netzwerk/IP_Konfiguration/">RTMP Ports</Link> (Standard “1935”). - siehe auch 14.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect11}
                    alt="Now you can enter your camera´s DDNS information to our free Windows Phone, Windows Metro, Android, iPhone, iPad or Blackberry App."
                  />
              }
              footer={
                <EuiText>
                    <p>
                      Um Ihre Kamera über unsere <Link to="/Software/Windows/InstarVision/Windows_Phone/DDNS/">Windows Phone</Link>, <Link to="/Software/Windows/InstarVision/Metro/DDNS/">Windows Metro</Link>, <Link to="/Software/Android/InstarVision/DDNS/">Android</Link>, <Link to="/Software/iOS/InstarVision/iPhone/DDNS/">iPhone</Link>, <Link to="/Software/iOS/InstarVision/iPad/DDNS/">iPad</Link> oder <Link to="/Software/Other_Platforms/InstarVision_Blackberry/">Blackberry</Link> App zu steuern, laden Sie sich bitte die InstarVision® App aus dem App Store herunter.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect12}
                    alt="If you cannot open a port for camera´s DDNS service, you can still use the Point-2-Point Service instead with our Windows Phone, Windows Metro, Android, iPhone, iPad or Blackberry App."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Die <Link to="/Web_User_Interface/1080p_Serie/Netzwerk/Fernzugriff/">Point-2-Point Funktion</Link> erlaubt den Internetzugriff auf die Kamera ohne Portweiterleitung (s.#12). Fügen Sie sie einfach als neue p2p Kamera in unserer <Link to="/Software/Windows/InstarVision/Windows_Phone/P2P/">Windows Phone</Link>, <Link to="/Software/Windows/InstarVision/Metro/P2P/">Windows Metro</Link>, <Link to="/Software/Android/InstarVision/P2P/">Android</Link>, <Link to="/Software/iOS/InstarVision/iPhone/P2P/">iPhone</Link>, <Link to="/Software/iOS/InstarVision/iPad/P2P/">iPad</Link> oder <Link to="/Software/Other_Platforms/InstarVision_Blackberry/">Blackberry</Link> App hinzu, scannen Sie den QR Code in der Weboberfläche unter Network/P2P in der WebUI oder auf dem Sticker auf der Kamera und tragen Sie Ihr Login ein (s.#8).
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
