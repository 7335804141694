import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Smarthome/Loxone/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_Loxone.webp'
                    alt="Loxone"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Loxone"
              description="Der Homeautomation Miniserver von Loxone verbindet alle smarten Komponenten in Ihrem Haus oder Ihrer Wohnung. Alle Stränge laufen zum Miniserver zusammen, um die Kommunikation zwischen den einzelnen Komponenten zu ermöglichen: Licht, Beschattung, Heizung, Taster sowie Ihre INSTAR IP-Überwachungskamera."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Smarthome/Loxone/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Smarthome/Homee/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_homee.webp'
                    alt="homee"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="homee"
              description="Verwenden Sie die homee Basisstation, um Ihre INSTAR-Kamera mit drahtlosen Zigbee-, Z-Wave- oder EnOcean-Sensoren, Signalgebern und Smart Buttons interagieren zu lassen. Automatisieren Sie den Betrieb Ihrer Kameras in einer Smarthome-Umgebung."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Smarthome/Homee/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Smarthome/Homematic/MQTTv5/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_Homematic.webp'
                    alt="Homematic"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Homematic"
              description="Um Ihre Homematic- oder Homematic IP-Geräte, wie z. B. Ihre INSTAR IP-Kamera, steuern, konfigurieren und in zentralen Programmen nutzen zu können, müssen Sie die Geräte über die WebUI in das System einbinden."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Smarthome/Homematic/MQTTv5/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Smarthome/Athom_Homey/MQTTv5/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Homey.webp'
                    alt="Athom Homey"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Athom Homey"
              description="Verwenden Sie die Athom Homey Basisstation, um Ihre INSTAR-Kamera mit drahtlosen Zigbee-, Z-Wave- oder IR-Sensoren, Signalgebern und Smart Buttons interagieren zu lassen. Automatisieren Sie den Betrieb Ihrer Kameras in einer Smarthome-Umgebung."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Smarthome/Athom_Homey/MQTTv5/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Smarthome/IFTTT/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_IFTTT.webp'
                    alt="IFTTT"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IFTTT"
              description="If This Then That, auch bekannt als IFTTT, ist ein kostenloser webbasierter Dienst zum Erstellen von Ketten einfacher bedingter Anweisungen, so genannter Applets für Ihre Full HD-Kamera..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Smarthome/IFTTT/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Smarthome/IP_Symcon/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Symcon.webp'
                    alt="IP Symcon"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IP Symcon"
              description="Die IP-Symcon Software ist die Komplettlösung im Bereich der Hausautomation. Kombinieren Sie Produkte aller namhaften Hersteller, realisieren Sie Ihre Wünsche und lassen Sie Ihr Zuhause selbst denken ..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Smarthome/IP_Symcon/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Smarthome/OpenHAB/MQTTv5/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_OpenHAB.webp'
                    alt="OpenHAB"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="OpenHAB"
              description="Empowering the smart home - eine hersteller- und technologieunabhängige Open-Source-Automatisierungssoftware für Ihr Zuhause..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Smarthome/OpenHAB/MQTTv5/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Smarthome/FHEM/MQTTv5/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_FHEM.webp'
                    alt="FHEM"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="FHEM"
              description="FHEM ist ein GPL'd Perl-Server für die Hausautomatisierung. Er wird verwendet, um einige gängige Aufgaben im Haushalt wie das Schalten von Lampen, Rollläden, Heizung zu automatisieren."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Smarthome/FHEM/MQTTv5/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Smarthome/IOBroker/MQTTv5/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_ioBroker.webp'
                    alt="ioBroker"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="ioBroker"
              description="Automatisieren Sie Ihr Smarthome mit ioBroker von Ihrem Raspberry Pi oder Windows PC aus. Integrieren Sie Ihre IP-Kamera und erhalten Sie Push-Benachrichtigungen."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Smarthome/IOBroker/MQTTv5/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Smarthome/Home_Assistant/MQTTv5/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_HomeAssistant.webp'
                    alt="Home Assistant"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Home Assistant"
              description="Open-Source-Heimautomatisierung, die lokale Kontrolle und Privatsphäre in den Vordergrund stellt. Angetrieben von einer weltweiten Community von Tüftlern und Heimwerkern. Perfekt für den Betrieb auf einem Raspberry Pi oder einem lokalen Server."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Smarthome/Home_Assistant/MQTTv5/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Smarthome/Node-RED/MQTTv5/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_Node-RED.webp'
                    alt="Node-RED"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Node-RED"
              description="Node-RED ist ein Programmierwerkzeug, um Hardwaregeräte, APIs und Online-Dienste auf neue und interessante Weise miteinander zu verdrahten..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Smarthome/Node-RED/MQTTv5/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Smarthome/Logitech_Harmony/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Harmony.webp'
                    alt="Logitech Harmony"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Logitech Harmony"
              description="Logitech Harmony ist eine Universalfernbedienung, die so programmiert werden kann, dass sie nicht nur mit Ihrem Fernseher, sondern auch mit Ihren Smarthome-Komponenten kommuniziert."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Smarthome/Logitech_Harmony/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Erweitert/Alexa_Echo5_and_Monocle_Gateway/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_Alexa.webp'
                    alt="Alexa and Monocle Gateway"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Alexa und das Monocle Gateway für den Echo 5"
              description="Um sich mit den Kameras (HD- und FullHD-Serie) z. B. mit einem Echo Show 5 zu verbinden, ist es notwendig, das Monocle Gateway von Monocle zu verwenden. Das Gateway schafft eine Schnittstelle, damit Alexa-Geräte auf die Kameras zugreifen können."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Erweitert/Alexa_Echo5_and_Monocle_Gateway/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
