import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Erweitert/CGI_Befehle/">
                  <StaticImage
                    src='../../images/Search/AU_SearchThumb_CGICommands.webp'
                    alt="CGI Befehle"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="CGI Befehle"
              description="Steuern Sie Ihre INSTAR IP-Kamera über Ihren Standard-Webbrowser CGI: Common Gateway Interface Common Gateway Interface (CGI) ist eine Standardmethode, um dynamische Inhalte auf Webseiten zu erzeugen. CGIs bieteten..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Erweitert/CGI_Befehle/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Erweitert/INSTAR_MQTT_Broker/">
                  <StaticImage
                    src='../../images/Search/AU_SearchThumb_MQTT.webp'
                    alt="INSTAR MQTT"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="INSTAR MQTT Interface"
              description="Smarthome- und Internet-of-Things (IoT)-Bereich gibt es ein weiteres Protokoll, das weit verbreitet ist und einige Vorteile gegenüber dem klassischen HTTP bietet - dieses Protokoll heißt MQTT."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Erweitert/INSTAR_MQTT_Broker/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Erweitert/VPN_AVM_Fritzbox/">
                  <StaticImage
                    src='../../images/Search/AU_SearchThumb_VPN.webp'
                    alt="VPN Tunnel"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="VPN Tunnel"
              description="Allgemeine Informationen für die Fritz.box VPN-Funktion Damit die Fritz! Box zum VPN-Server werden kann, prüfen Sie zunächst unter System / Update, ob die neueste Firmware-Version installiert ist. Stellen Sie dann sicher, dass..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Erweitert/VPN_AVM_Fritzbox/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Erweitert/Website_Integration/">
                  <StaticImage
                    src='../../images/Search/AU_SearchThumb_WebsiteIntegration.webp'
                    alt="Website Integration"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Website Integration"
              description="Fügen Sie Ihr Kamerabild zu Ihrer persönlichen Homepage hinzu."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Erweitert/Website_Integration/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Erweitert/Youtube_Videostreaming_from_your_Camera/">
                  <StaticImage
                    src='../../images/Search/AU_SearchThumb_Youtube.webp'
                    alt="Youtube Videostreaming"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Youtube Videostreaming mit Ihrer Kamera"
              description="Installieren von OBS Hinzufügen Ihrer Kameras Verbinden mit Ihrem Youtube-Account VLC Video Source Sie können die kostenlose, quelloffene OBS-Software verwenden, um den Live-Stream Ihrer Kameras auf die Video-Streaming-Plattform..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Erweitert/Youtube_Videostreaming_from_your_Camera/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Erweitert/Alle_Smarthome_Tutorials/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_Smarthome.webp'
                    alt="Youtube Videostreaming"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Smarthome"
              description="Übersicht über kompatible Home Automation Systeme, die mit Ihrer INSTAR IP-Kamera kompatibel sind."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Erweitert/Alle_Smarthome_Tutorials/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Erweitert/Tasker/">
                  <StaticImage
                    src='../../images/Search/AU_SearchThumb_Tasker.webp'
                    alt="Youtube Videostreaming"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Tasker Android App"
              description="Verwenden Sie CGI-Befehle, um Funktionen Ihrer Kamera zu automatisieren Tasker :: Android Makro-Tool Um Ihre IP-Kamera mit Ihrem Android-Telefon zu automatisieren, benötigen Sie ein Makro-Tool, wie die App Tasker. Die aktuelle (kostenpflichtige) Version..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Erweitert/Tasker/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Erweitert/WebUI_Wiederherstellen/">
                  <StaticImage
                    src='../../images/Search/AU_SearchThumb_ResetAfterWebUIError.webp'
                    alt="WebUI Wiederherstellen"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Wiederherstellung der Kamera nach einem fehlerhaften WebUI-Upgrade"
              description="Sie haben die Web-Bedienoberfläche Ihrer Kamera aktualisiert - aber jetzt können Sie sich mit Ihrem Webbrowser nicht mehr an Ihrer Kamera anmelden. Sie sehen die Kamera noch innerhalb Ihres Netzwerks und können vielleicht sogar auf die..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Erweitert/WebUI_Wiederherstellen/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Erweitert/Firmware_Wiederherstellen/">
                  <StaticImage
                    src='../../images/Search/AU_SearchThumb_ResetAfterFirmwareError.webp'
                    alt="Youtube Videostreaming"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Wiederherstellung Ihrer HD-Kamera nach einem fehlerhaften Firmware-Upgrade"
              description="VGA-Kameras VGA-Modelle 720p-KAMERAS Einrichten einer seriellen Verbindung Wiederherstellen der Firmware."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Erweitert/Firmware_Wiederherstellen/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Erweitert/CCTV_vs_IP/">
                  <StaticImage
                    src='../../images/Search/AU_SearchThumb_IPvsCCTV.webp'
                    alt="CCTV vs IP"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IP vs CCTV"
              description="Was ist der Unterschied zwischen analogen und IP-Kameras? Eine der bedeutendsten technologischen Veränderungen auf dem CCTV-Markt ist das Aufkommen der so genannten IP-Kameras. Bei der Auswahl von Sicherheitskameras für Ihr Zuhause..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Erweitert/CCTV_vs_IP/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Erweitert/Kerberos_mit_Docker/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_Lupus_XT2.webp'
                    alt="Kerberos.io Videoüberwachung"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Kerberos.io Videoüberwachung und INSTAR Kameras"
              description="Kerberos ist eine Videoüberwachungslösung, die Computer-Vision-Algorithmen verwendet, um Veränderungen zu erkennen, und die Aktionen auslösen kann (Benachrichtigungen, Api, etc.)."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Erweitert/Kerberos_mit_Docker/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
