import React from 'react'
import { OutboundLink } from 'gatsby-plugin-gtag'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Bitte beachten Sie">
        <EuiText>
        <p>Die Kamera wird sich nicht mehr in Ihr Wi-Fi-Netzwerk einwählen können, da ihr der WiFi-Schlüssel fehlt. Auch werden Sie wahrscheinlich nicht mehr die alte IP-Adresse haben, sondern automatisch eine neue von Ihrem Router erhalten, sobald Sie die Kamera über ein LAN-Kabel anschließen. Danach ist eine neue Erstinstallation notwendig. Details dazu finden Sie hier: <strong><OutboundLink href="https://install.instar.com/" target="_blank" rel="noopener noreferrer">Installation Wizard</OutboundLink></strong>.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

