import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class DateTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Datum & Uhrzeit Einstellungen" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Datum & Uhrzeit der Kamera', descriptionColumn: 'Interne Zeit des Kamera Betriebssystem. Diese wird z.B. für die Alarm- und Aufnahmezeitpläne genutzt, sowie der Benennung von Aufgenommenen Dateien mit Zeitstempeln.' },
        { actionColumn: 'Mit Computerzeit synchronisieren', descriptionColumn: 'Wenn Ihre Kamera keinen Zugang zum Internet hat, können Sie hiermit die Kamerazeit mit der Zeiteinstellung Ihres Computers abgleichen. Dies muss in diesem Fall regelmäßig wiederholt werden, um keine Genauigkeit zu verlieren.' },
        { actionColumn: 'Automatische Synchronisation', descriptionColumn: 'Diese Funktion synchronisiert die interne Uhr der Kamera mit einem NTP Server. Es wird empfohlen diese Funktion zu nutzen, um die Kamera-Zeit aktuell zu halten.' },
        { actionColumn: 'Sommerzeit aktivieren', descriptionColumn: 'Die Sommerzeit muss für die Kamera manuell aktiviert werden - und im Winter dann wieder deaktiviert werden.' },
        { actionColumn: 'Kamera Zeitzone', descriptionColumn: 'Wählen Sie hier die Zeitzone aus, in der sich die Kamera befindet - im Falle von Deutschland wäre dies GMT+1.' },
        { actionColumn: 'NTP Server', descriptionColumn: 'Sollten Sie Probleme bei dem Zeitabgleich mit den NTP Server haben, können Sie hier einen anderen auswählen. In Firmennetzwerken kann es notwendig sein, einen eigenen Zeitserver anzugeben - dieser kann auf der Kamera per CGI Befehl gesetzt werden.' },
        { actionColumn: 'Eigener NTP Server', descriptionColumn: 'Geschlossene Unternehmensnetzwerke müssen möglicherweise einen internen NTP-Server einrichten.' },
        { actionColumn: 'NTP Update Intervall', descriptionColumn: 'Legen Sie hier das Intervall fest, in dem der Zeitabgleich mit dem Zeitserver erfolgen soll.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

DateTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(DateTable)