import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class ActionTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Alarm Server" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Alarm Server kontaktieren', descriptionColumn: 'Aktivieren Sie die Alarm Server Funktion' },
        { actionColumn: 'Ruheintervall', descriptionColumn: 'Bei einem Daueralarm, wie lange soll der Server warten bis er Sie erneut benachrichtigt (1s - 60s)' },
        { actionColumn: 'Erlaubte Alarmauslöser', descriptionColumn: 'Wählen Sie welche Sensoren den Alarmserver auslösen dürfen: Bewegungserkennungsbereiche, Passiver IR Sensor, Audioerkennung, Bereiche + PIR (gekoppelt).' },
        { actionColumn: 'Alarm Server Adresse', descriptionColumn: 'Geben Sie die IP-Adresse Ihres Alarmservers ein - z.B. Ihr FHEM Server.' },
        { actionColumn: 'Server Port', descriptionColumn: 'Geben Sei den TCP Port ein, auf den Ihr Alarm Server hört.' },
        { actionColumn: 'Server Pfad', descriptionColumn: 'Geben Sie die URL zum Server an.' },
        { actionColumn: 'Verschlüsselung', descriptionColumn: 'Aktivieren Sie die HTTPS TLS Verschlüsselung für die Web-Anfrage.' },
        { actionColumn: 'POST Request', descriptionColumn: 'Aktivieren Sie einen POST anstatt der standardmäßigen GET Anfrage' },
        { actionColumn: 'Parameter', descriptionColumn: 'Sie können optionale Parameter zur URL hinzufügen.' },
        { actionColumn: 'Alarmauslöser anhängen', descriptionColumn: 'Der Alarm Trigger (z.B. "Bewegungserkennung Bereich 1 " oder "Audioerkennung") wird zum HTTP Request in Form eines Querys hinzugefügt. *' },
        { actionColumn: 'Authentifizierung', descriptionColumn: 'Fügen Sie Ihren Serverlogin zur URL hinzu.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

ActionTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(ActionTable)