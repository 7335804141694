import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class SheduleTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Alarm Zeitplan" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Master Zeitplan', descriptionColumn: 'Verwenden Sie den Hauptzeitplan, um alle aktiven Alarmaktionen zu deaktivieren. Der Hauptzeitplan hat Vorrang vor allen anderen Alarmzeitplänen.' },
        { actionColumn: 'Bereich Zeitplan', descriptionColumn: 'Verwenden Sie den Bereichszeitplan, um die im Menü Alarm/Bereiche eingerichtete softwarebasierte Bewegungserkennung gezielt zu deaktivieren. Dieser Zeitplan hat nur dann eine Wirkung, wenn der Master-Zeitplan für das betreffende Zeitfenster aktiv gesetzt ist.' },
        { actionColumn: 'PIR Zeitplan', descriptionColumn: 'Verwenden Sie den PIR-Zeitplan, um gezielt die Bewegungserkennung durch den integrierten Passiv-Infrarot-Sensor zu deaktivieren, der im Menü Alarm/Aktionen eingerichtet wurde. Dieser Zeitplan hat nur dann eine Wirkung, wenn der Master-Zeitplan für das betreffende Zeitfenster aktiv gesetzt ist.' },
        { actionColumn: 'Audio Zeitplan', descriptionColumn: 'Verwenden Sie den Audiozeitplan, um die im Menü Alarm/Aktionen eingerichtete softwarebasierte Audioerkennung gezielt zu deaktivieren. Dieser Zeitplan hat nur dann eine Wirkung, wenn der Master-Zeitplan für das betreffende Zeitfenster aktiv gesetzt ist.' },
        { actionColumn: 'Alarmeingang Zeitplan', descriptionColumn: 'Verwenden Sie den Eingangszeitplan, um den im Menü Alarm/Aktionen eingerichteten Alarmeingangsauslöser gezielt zu deaktivieren. Dieser Zeitplan hat nur dann eine Wirkung, wenn der Master-Zeitplan für das betreffende Zeitfenster aktiv gesetzt ist.' },
        { actionColumn: 'Relay Zeitplan', descriptionColumn: 'Verwenden Sie den Relaiszeitplan, um den im Menü Alarm/Aktionen eingestellten Alarmeingangsauslöser gezielt zu deaktivieren. Dieser Zeitplan hat nur dann eine Wirkung, wenn der Master-Zeitplan für das betreffende Zeitfenster aktiv gesetzt ist.' },
        { actionColumn: 'Alles auswählen', descriptionColumn: 'Klicken Sie hier um die Alarm Aktionen 24/7 zu aktivieren. Jede aktive Alarm Funktion, (Bewegungserkennung, Audio Alarm und externer Alarmeingang) kann die gesetzten Alarmaktionen auslösen.' },
        { actionColumn: 'Nichts auswählen', descriptionColumn: 'Klicken Sie hier um die 24/7 Alarm Aktionen zu deaktivieren. Jede aktive Alarm Funktion (Bewegungserkennung, Audio Alarm und externer Alarmeingang) kann die gesetzten Alarmaktionen auslösen.' },
        { actionColumn: 'Arbeitszeit', descriptionColumn: 'Klicken Sie hier, wenn Sie die Alarm Aktionen während der normalen Arbeitszeiten aktiviert haben möchten. Alle aktiven Alarm Funktionen werden nur während dieser Zeit Alarm Aktionen auslösen.' },
        { actionColumn: 'Nachtzeit', descriptionColumn: 'Klicken Sie hier, wenn Sie die Alarm Aktionen während der Nacht aktiviert haben möchten. Alle aktiven Alarm Funktionen werden nur während dieser Zeit Alarm Aktionen auslösen.' },
        { actionColumn: 'Benutzerdefiniert', descriptionColumn: 'Klicken Sie unten links auf Plan merken, um Ihren aktuellen Zeitplan zu speichern. Im Anschluß können Sie mit einem Klick auf Benutzerdefiniert diesen Plan jederzeit wieder herstellen.' },
        { actionColumn: 'Alles Umkehren', descriptionColumn: 'Dieser Button kehrt das ausgewählte Zeitfenster um.' },
        { actionColumn: 'Zeitgesteuerte Bereicheinstellung', descriptionColumn: 'Durch das Aktivieren dieser Funktion werden die Bereiche 1&2 und Bereiche 2&3 zu Gruppen zusammengefasst. Sie können im Anschluß festlegen in welchem Zeitfenster die beiden Gruppen aktiv sein sollen.' },
        { actionColumn: 'Plan merken', descriptionColumn: 'Klicken Sie auf diese Schaltfläche, um einen von Ihnen erstellten Zeitplan zu speichern. Dieser Zeitplan kann dann durch Klicken auf den voreingestellten Zeitplan Benutzerdefiniert ausgewählt werden.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

SheduleTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(SheduleTable)