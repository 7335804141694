import React from 'react'

import {Link} from 'gatsby'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiButton
} from '@elastic/eui'


import ivImage04 from '../../../../../images/Software/Windows/InstarVision/Record/Image044_small.png'
import ivImage05 from '../../../../../images/Software/Windows/InstarVision/Record/Image045-1_small.png'
import ivImage06 from '../../../../../images/Software/Windows/InstarVision/Record/Image045-2_small.png'

export default function ImageCard() {

  return (
    <EuiFlexGroup gutterSize="l" direction="row" >
      <EuiFlexItem>
        <EuiCard
          textAlign="left"
          image={
            <div>
              <img
                src={ivImage04}
                alt="Starten Sie eine Intervall Schnappschussaufnahme."
              />
            </div>
          }
          title="Fotoserie"
          description="Starten Sie eine Intervall Schnappschussaufnahme."
          content={
            <EuiText>
              <p>Starten Sie eine Intervall Schnappschussaufnahme.</p>
            </EuiText>
          }
          footer={
            <EuiFlexGroup justifyContent="flexEnd">
              <EuiFlexItem grow={false}>
                <Link to="/Software/Windows/InstarVision/Record/Photoseries/">
                  <EuiButton size="s" fill>
                    mehr...
                  </EuiButton>
                </Link>
              </EuiFlexItem>
            </EuiFlexGroup>
          }
        />
        </EuiFlexItem>
        <EuiFlexItem>
        <EuiCard
          textAlign="left"
          image={
            <div>
              <img
                src={ivImage05}
                alt="Lassen Sie sich, im Falle eines Alarms, per Email benachrichtigen."
              />
            </div>
          }
          title="Email"
          description="Lassen Sie sich, im Falle eines Alarms, per Email benachrichtigen."
          content={
            <EuiText>
              <p>Lassen Sie sich, im Falle eines Alarms, per Email benachrichtigen.</p>
            </EuiText>
          }
          footer={
            <EuiFlexGroup justifyContent="flexEnd">
              <EuiFlexItem grow={false}>
                <Link to="/Software/Windows/InstarVision/Record/Email/">
                  <EuiButton size="s" fill>
                    mehr...
                  </EuiButton>
                </Link>
              </EuiFlexItem>
            </EuiFlexGroup>
          }
        />
        </EuiFlexItem>
        <EuiFlexItem>
        <EuiCard
          textAlign="left"
          image={
            <div>
              <img
                src={ivImage06}
                alt="Sichern Sie Ihre Alarmaufnahmen auf einem separaten FTP Server."
              />
            </div>
          }
          title="FTP Server"
          description="Sichern Sie Ihre Alarmaufnahmen auf einem separaten FTP Server."
          content={
            <EuiText>
              <p>Sichern Sie Ihre Alarmaufnahmen auf einem separaten FTP Server.</p>
            </EuiText>
          }
          footer={
            <EuiFlexGroup justifyContent="flexEnd">
              <EuiFlexItem grow={false}>
                <Link to="/Software/Windows/InstarVision/Record/FTP_Server/">
                  <EuiButton size="s" fill>
                    mehr...
                  </EuiButton>
                </Link>
              </EuiFlexItem>
            </EuiFlexGroup>
          }
        />
        </EuiFlexItem>
    </EuiFlexGroup>
  );
}
