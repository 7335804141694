import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Serie/Netzwerk/">
                  <StaticImage
                    src='../../../images/Search/WebUI_1440p_SearchThumb_Network_IP_Configuration.webp'
                    alt="Netzwerk Menü"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Netzwerk Menü"
              description="Ändern Sie die IP-Konfiguration oder die WiFi-Einstellungen Ihrer Kamera oder verbinden Sie sie mit Ihrem Smartphone, indem Sie den DDNS-, P2P- oder Push-Service-QR-Code mit unserer Smartphone-App scannen."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1440p_Serie/Netzwerk/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Serie/Multimedia/">
                  <StaticImage
                    src='../../../images/Search/WebUI_1440p_SearchThumb_Multimedia_Video.webp'
                    alt="Multimedia Menü"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Multimedia Menü"
              description="Passen Sie die Videoqualität und die Audiolautstärke Ihrer Kamera an. In den Bildeinstellungen können Sie Kontrast, Sättigung und Helligkeit Ihrer Kamera einstellen."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1440p_Serie/Multimedia/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Serie/Alarm/">
                  <StaticImage
                    src='../../../images/Search/WebUI_1440p_SearchThumb_Alarm_Alarm_Actions.webp'
                    alt="Alarm Menü"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Alarm Menü"
              description="Aktivieren Sie die Bewegungs- oder Audioerkennung Ihrer Kamera und die Aktionen, die im Falle eines Alarms ausgeführt werden sollen - wie Videoaufnahmen, E-Mail-Benachrichtigungen oder FTP-Uploads."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1440p_Serie/Alarm/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Serie/System/">
                  <StaticImage
                    src='../../../images/Search/WebUI_1440p_SearchThumb_System_Overview.webp'
                    alt="System Menü"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="System Menü"
              description="Ändern Sie die Sprache der Web-Benutzeroberfläche, führen Sie einen Neustart oder Reset durch oder laden Sie ein Firmware- und Web-UI-Update auf Ihre Kamera."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1440p_Serie/System/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Serie/Smarthome/">
                  <StaticImage
                    src='../../../images/Search/WebUI_1440p_SearchThumb_Smarthome_Alarmserver.webp'
                    alt="Smarthome Menü"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Smarthome Menü"
              description="Smarthome-Integration für Ihre INSTAR Full HD IP-Kamera. Nutzen Sie den INSTAR Alexa Skill oder ein INSTAR IFTTT Applet zur Steuerung Ihrer Kamera. Der INSTAR Alarmserver und die INSTAR MQTT-Schnittstelle ermöglichen die Integration in die meisten gängigen Smarthome-Systeme."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1440p_Serie/Smarthome/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Serie/Cloud/">
                  <StaticImage
                    src='../../../images/Search/WebUI_1440p_SearchThumb_Cloud_Introduction.webp'
                    alt="Cloud Menü"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Cloud Menü"
              description="Ein Überblick über unsere Online-Aufzeichnungsplattform für Ihre INSTAR IP-Kamera."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1440p_Serie/Cloud/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Serie/Features/">
                  <StaticImage
                    src='../../../images/Search/WebUI_1440p_SearchThumb_Features_Wizard.webp'
                    alt="Features Menü"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Features Menü"
              description="Ändern Sie die Systemeinstellungen Ihrer Kamera, passen Sie die Status- und IR-LEDs oder das Schwenk-, Neige- und Zoomverhalten Ihrer Kamera an."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1440p_Serie/Features/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Serie/Live_Video/">
                  <StaticImage
                    src='../../../images/Search/QI_SearchThumb_LiveVideo.webp'
                    alt="Live Video"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Live Video"
              description="Wenn Sie die Web-Bedienoberfläche Ihrer Kamera aufrufen, werden Sie mit dem Live-Video-Fenster begrüßt."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1440p_Serie/Live_Video/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Serie/Aufnahme_Aufgaben/">
                  <StaticImage
                    src='../../../images/Search/WebUI_1440p_SearchThumb_Tasks_Photoseries.webp'
                    alt="Recording"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Aufnahmen"
              description="Zeichnen Sie Fotoserien - zeitlich begrenzte Schnappschüsse - auf der internen SD-Karte Ihrer Kamera auf."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Web_User_Interface/1440p_Serie/Aufnahme_Aufgaben/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
