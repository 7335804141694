import React from 'react'
import { OutboundLink } from 'gatsby-plugin-gtag'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="INSTAR Forum">
        <EuiText>
          <p>Diskutieren Sie diesen Artikel im <OutboundLink href="https://forum.instar.de/t/logitech-harmony-und-instar-mqtt-in-node-red-iobroker-und-home-assistant/26179" target="_blank" rel="nofollow">INSTAR Forum</OutboundLink> !</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox