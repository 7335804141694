import React from 'react'
import {Link} from 'gatsby'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText
} from '@elastic/eui'

import qiSelect01 from "../../../../images/Outdoor_Cameras/IN-7011_HD/Point2Point/WPS-P2P_IN-7011_01.webp"
import qiSelect02 from "../../../../images/Outdoor_Cameras/IN-7011_HD/Point2Point/WPS-P2P_IN-5905_02.webp"
import qiSelect03 from "../../../../images/Outdoor_Cameras/IN-7011_HD/Point2Point/WPS-P2P_IN-5905_03.webp"
import qiSelect04 from "../../../../images/Outdoor_Cameras/IN-7011_HD/Point2Point/WPS-P2P_IN-5905_04.webp"
import qiSelect05 from "../../../../images/Outdoor_Cameras/IN-7011_HD/Point2Point/WPS-P2P_IN-5905_05.webp"
import qiSelect06 from "../../../../images/Outdoor_Cameras/IN-7011_HD/Point2Point/WPS-P2P_IN-5905_06.webp"
import qiSelect07 from "../../../../images/Outdoor_Cameras/IN-7011_HD/Point2Point/WPS-P2P_IN-5905_07.webp"
import qiSelect08 from "../../../../images/Outdoor_Cameras/IN-7011_HD/Point2Point/WPS-P2P_IN-5905_08.webp"
import qiSelect09 from "../../../../images/Outdoor_Cameras/IN-7011_HD/Point2Point/WPS-P2P_IN-7011_09.webp"



export default function QIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect01}
                    alt="Verbinden Sie die Antenne mit der SMA Buchse in der Wandhalterung der Kamera."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Verbinden Sie die Antenne mit der SMA Buchse in der Wandhalterung der Kamera.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect02}
                    alt="Verbinden Sie das Netzteil mit dem Anschluss am Kabelbaum der Kamera um Ihre Kamera zu starten."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Verbinden Sie das Netzteil mit dem Anschluss am Kabelbaum der Kamera um Ihre Kamera zu starten.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect03}
                    alt="Wenn Ihr Router eine WPS (Wireless Protected Setup) Funktion hat, können Sie auch die WPS Taste am Router drücken (siehe Beispiele oben)."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Wenn Ihr Router eine WPS (Wireless Protected Setup) Funktion hat, können Sie auch die WPS Taste am Router drücken (siehe Beispiele oben).
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect04}
                    alt="Drücken Sie danach den Reset Button am Kabelbaum der Kamera für 3 Sekunden. Warten Sie danach für max. 5min darauf, dass sich die Kamera mit Ihrem WLAN Router verbindet."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Drücken Sie danach den Reset Button am Kabelbaum der Kamera für 3 Sekunden. Warten Sie danach für max. 5min darauf, dass sich die Kamera mit Ihrem WLAN Router verbindet.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect05}
                    alt="Starten Sie unsere Android-, iOS- oder Windows Phone-App Instar Vision. Wählen Sie, um eine neue p2p-Kamera hinzuzufügen und starten Sie den QR-Code-Scanner. Ihr QR-Code befindet sich auf einem Etikett am Kameragehäuse (s. oben)."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Starten Sie unsere <Link to="/Software/Android/InstarVision/P2P/">Android</Link>, <Link to="/Software/iOS/InstarVision/iPhone/P2P/">iOS</Link> oder <Link to="/Software/Windows/InstarVision/Windows_Phone/P2P/">Windows Phone</Link> App InstarVision. Wählen Sie das Hinzufügen einer neuen p2p Kamera und starten Sie den QR Code Scanner. Lokalisieren Sie den QR Code auf dem Kameragehäuse.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect06}
                    alt="The QR code contains all the necessary information to add your camera. Please be aware that the default login credentials will be used. If you want to change the default password, please check the Quick Installation Guide. The password can only be changed in the web user interface."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Der QR Code beinhaltet alle Informationen zum Einbinden der Kamera. Bitte beachten Sie, dass hierbei die <Link to="/Web_User_Interface/720p_Serie/System/Benutzer/">Standard Login Daten</Link> verwendet werden.Wenn Sie das Standardpasswort ändern möchten, überprüfen Sie bitte die <Link to="/Aussenkameras/IN-7011_HD/Schnell_Installation/">Schnell Installation Anleitung</Link>. Das Passwort kann nur in der Web-Benutzeroberfläche geändert werden.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect07}
                    alt="The Login can only be Changed inside the webUI of your camera. The QR Code inside user interface always contains the actual information."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Das Standard Kennwort kann nur über die <Link to="/Web_User_Interface/720p_Serie/System/Benutzer/">Weboberfläche</Link> der Kamera geändert werden. Der <Link to="/Web_User_Interface/720p_Serie/Netzwerk/P2P/">QR Code</Link> in der Weboberfläche beinhaltet immer die aktuellen Daten.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect08}
                    alt="Die App wird jetzt den Live Stream der Kamera abgreifen können und Ihnen die Möglichkeit geben die Kamera zu schwenken, den Audiostream zu aktivieren und einen Schnappschuss zu speichern."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Die App wird jetzt den Live Stream der Kamera abgreifen können und Ihnen die Möglichkeit geben die Kamera zu schwenken, den Audiostream zu aktivieren und einen Schnappschuss zu speichern.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect09}
                    alt="Mit der WPS / P2P-Methode können Sie Ihre Kamera schnell anschließen und den Live-Stream der Kamera überprüfen. Eine Anleitung zum Zugriff auf weitere Kamerafunktionen finden Sie in der Schnellinstallationsanleitung."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Mit der WPS / P2P-Methode können Sie Ihre Kamera schnell anschließen und den Live-Stream der Kamera überprüfen. Eine Anleitung zum Zugriff auf weitere Kamerafunktionen finden Sie in der Schnellinstallationsanleitung.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
