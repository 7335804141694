import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class SMTPTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "SMTP Server" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'SMTP Voreinstellungen', descriptionColumn: 'Ihre INSTAR Kamera kann mit nahezu jedem Email Provider kommunizieren. Die Voreinstellungen geben Ihnen nur einen Überblick über die meistgenutzen Provider. Falls Sie Ihren nicht finden, tippen Sie die Daten manuell ein. Wenn Sie die Serveradresse Ihres Providers nicht kennen, verwenden Sie einfach INSTAR SMTP Server. Diese Voreinstellung erlaubt es Ihnen, über einen INSTAR Server die Mail direkt zu Ihrer persönlichen E-Mail Adresse zu senden. Alles was Sie als nächstes machen müssen, ist Ihre persönliche Email Adresse als Empfänger im Email Menü einzutragen.' },
        { actionColumn: 'SMTP Server', descriptionColumn: 'Geben Sie die Serveradresse Ihres Email Providers ein. Wenn Sie die Serveradresse Ihres Providers nicht kennen, verwenden Sie einfach INSTAR Preset . Diese Voreinstellung erlaubt es Ihnen, über einen INSTAR Server die Mail direkt zu Ihrer persönlichen E-Mail Adresse zu senden. Alles was Sie als nächstes machen müssen, ist Ihre persönliche Email Adresse als Empfänger im Email Menü einzutragen.' },
        { actionColumn: 'SMTP Port', descriptionColumn: 'Wählen Sie den Port, den Ihr SMTP Server benutzt um Ihre Emails zu empfangen. Der Standard Port für eine unverschlüsselte Mailübertragung ist 25, für SSL wählen Sie Port 465 und _TLS/STARTTLS_ nutzt den Port 587.' },
        { actionColumn: 'SMTP SSL', descriptionColumn: 'Wählen sie den Verschlüsselungsmodus den Ihr SMTP Server benutzt. Beachten Sie, dass die meisten Server zwar alle Verschlüsselungen unterstützen, dafür aber unterschiedliche Ports verwenden.' },
        { actionColumn: 'SMTP Benutzername', descriptionColumn: 'Geben Sie Ihren Email Benutzernamen ein - dies ist, in den meisten Fällen, einfach Ihre Email Adresse.' },
        { actionColumn: 'SMTP Kennwort', descriptionColumn: 'Geben Sie das Passwort Ihres Email Accounts an und bestätigen Sie es.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

SMTPTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(SMTPTable)