import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'DDNSS',
      href: '/de/Fernzugriff/DDNS_Anbieter/DDNSS/',
    },
    {
      text: 'NoIP',
      href: '/de/Fernzugriff/DDNS_Anbieter/NoIP/',
    },
    {
      text: 'Selfhost',
      href: '/de/Fernzugriff/DDNS_Anbieter/Selfhost/',
    },
    {
      text: '◄ DDNS Anbieter',
      href: '/de/Fernzugriff/DDNS_Anbieter/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={6}
        aria-label="Use a Third Party DDNS services with your INSTAR IP Camera"
      />
      <EuiSpacer />
    </Fragment>
  );
}