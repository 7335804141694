import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Kennwort Richtlinien">
        <EuiText>
          <p>Das WiFi Passwort ist auf max. 63 Zeichen begrenzt unterstützt möglicherweise nicht die folgenden Sonderzeichen: {' _& = " ` + \\ '}</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

