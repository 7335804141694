import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class ActionTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Alarm Aktionen" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Alarm-Email senden', descriptionColumn: 'Alle Alarm-Aktionen können über die Bereiche der Bewegungserkennung, den Alarmeigang, als auch den Audio Alarm ausgelöst werden. Hier können Sie die Alarmbenachrichtigung per Email aktivieren. Bitte beachten Sie, dass Sie zuerst den ausgehenden Email Server (SMTP) und den Email Empfänger eingeben müssen.' },
        { actionColumn: 'Alarm Video auf SD-Karte senden', descriptionColumn: 'Aktivieren, um im Alarmfall ein Video auf die interne SD Karte zu senden. Eine Alarm-Video Datei startet immer mit einem A und danach mit einem Zeitstempel. Ihre Kamera nimmt immer 3 Sekunden vor dem Alarm und 15 Sekunden nach dem Alarm auf.' },
        { actionColumn: 'Alarm Video auf einen FTP-Server senden', descriptionColumn: 'Aktivieren, um im Alarmfall ein Video auf Ihren FTP Server zu senden. Bitte beachten Sie, dass Sie zuerst Ihre FTP Server Informationen eingeben müssen.' },
        { actionColumn: 'Alarm Snapshot auf SD-Karte senden', descriptionColumn: 'Aktivieren, um im Alarmfall einen Snapshot auf die interne SD Karte zu senden.' },
        { actionColumn: 'Alarm Snapshot auf FTP Server senden', descriptionColumn: 'Aktivieren, um im Alarmfall Snapshots auf Ihren FTP Server zu senden. Bitte beachten Sie, dass Sie zuerst Ihre FTP Server Informationen im FTP Menü eingeben müssen.' },
        { actionColumn: 'Snapshot Auflösung', descriptionColumn: 'Stellen Sie die Auflösung für die Snapshots ein, die im Alarmfall per FTP hochgeladen werden, per Email gesendet werden oder auf der SD-Karte gespeichert werden.' },
        { actionColumn: 'Fotos in Email', descriptionColumn: 'Stellen Sie ein, wie viele Snapshots in einer Alarm Email angehängt werden sollen. Bitte beachten Sie, dass die Email etwas verzögert eintrifft, wenn zu viele Bilder angehängt werden. Wenn Sie ohne eine Verzögerung benachrichtigt werden wollen, nutzen Sie bitte den Push Dienst.' },
        { actionColumn: 'Fotos auf SD-Karte', descriptionColumn: 'Set how many images you want to be saved to SD Card in case of an alarm.' },
        { actionColumn: 'Fotos per FTP Upload', descriptionColumn: 'Stellen Sie ein, wie viele Snapshots Sie im Alarmfall von Ihrer Kamera auf Ihren FTP Server senden wollen.' },
        { actionColumn: 'Externen Ausgang aktivieren', descriptionColumn: 'Aktivieren Sie den externen Alarm Ausgang Ihrer Kamera (nicht bei allen Kameramodellen verfügbar) und lassen Sie Ihre Kamera im Alarmfall das Relais schließen.' },
        { actionColumn: 'Schließzeit des Relais in Sekunden', descriptionColumn: 'Einstellen, wie lange das Relais am Alarmausgang im Alarmfall geschlossen bleiben soll.' },
        { actionColumn: 'Externen Alarm Eingang aktivieren.', descriptionColumn: 'Aktivieren Sie den Alarm Eingang Ihrer Kamera (nicht bei allen Kameramodellen verfügbar). Informationen über das Verbinden mit einem externen Sensor können Sie hier finden. Sie können jeden Sensor mit Ihrer Kamera verbinden, welcher mit einem potentialfreien Kontakt arbeitet.' },
        { actionColumn: 'Stromkreis während Alarm / Alarmgerät eingestellt auf', descriptionColumn: 'Stellen Sie ein, ob der externe Sensor konfiguriert ist, um den Stromkreis im Falle einer Erkennung zu schließen oder den Kontakt zu öffnen (siehe auch IN-Motion 500). Bitte beachten Sie, dass sich Ihre Kamera in einem konstanten Alarmzustand befindet, wenn Sie den Alarmeingang auf N.C. stellen und keinen externen Sensor anschließen.' },
        { actionColumn: 'Alarmeingang und Alarmbereiche koppeln', descriptionColumn: 'Es wird empfohlen die interne Bewegungserkennung mit dem externen Sensor zu koppeln. Bitte beachten Sie, dass Sie mindestens einen Alarmbereich aktiviert haben müssen, um die Kopplungsfunktion nutzen zu können.' },
        { actionColumn: 'Internen PIR Sensor aktivieren.', descriptionColumn: 'Nur IN-6014 HD - Aktivieren Sie den internen passiven Infrarot Sensor (PIR Bewegungserkennung) um einen Alarm auszulösen, wenn eine Person den Überwachungsbereich betritt. Sie können die Alarmbereiche deaktivieren, wenn der PIR Sensor aktiv ist.' },
        { actionColumn: 'IR und Alarmbereiche koppeln', descriptionColumn: 'Nur IN-6014 HD - Sie können auch die Alarmbereiche mit dem internen PIR Sensor koppeln. Nutzen Sie diese Funktion um effizient gegen Falschalarme vorzugehen. Bitte denken Sie daran, dass Sie mindestens einen Alarmbereich aktiviert haben müssen, um diese Funktion nutzen zu können! Zusätzlich müssen Sie das Augen-Symbol als auch das Einbrecher Symbol in unserer Smartphone App um den Alarm zu deaktivieren.' },
        { actionColumn: 'Akustisches Signal aktivieren', descriptionColumn: 'Nur IN-6014 HD - Einstellen, wenn Ihre Kamer im Alarmfall ein Alarm Signal abspielen soll. Bei _alle anderen Kameramodellen_ befindet sich diese Einstellung im Audio Menü' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

ActionTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(ActionTable)