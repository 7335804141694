import React from 'react'

import {Link} from 'gatsby'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiButton
} from '@elastic/eui'


import ivImage01 from '../../../../../images/Software/Windows/InstarVision/Record/Image043_small.png'
import ivImage02 from '../../../../../images/Software/Windows/InstarVision/Record/Image041_small.png'
import ivImage03 from '../../../../../images/Software/Windows/InstarVision/Record/Image042_small.png'

export default function ImageCard() {

  return (
    <EuiFlexGroup gutterSize="l" direction="row" >
      <EuiFlexItem>
        <EuiCard
          textAlign="left"
          image={
            <div>
              <img
                src={ivImage01}
                alt="Allgemeine Einstellungen für die Videoaufnahme."
              />
            </div>
          }
          title="Allgemein"
          description="Allgemeine Einstellungen für die Videoaufnahme."
          content={
            <EuiText>
              <p>Allgemeine Einstellungen für die Videoaufnahme.</p>
            </EuiText>
          }
          footer={
            <EuiFlexGroup justifyContent="flexEnd">
              <EuiFlexItem grow={false}>
                <Link to="/Software/Windows/InstarVision/Record/General/">
                  <EuiButton size="s" fill>
                    mehr...
                  </EuiButton>
                </Link>
              </EuiFlexItem>
            </EuiFlexGroup>
          }
        />
        </EuiFlexItem>
        <EuiFlexItem>
        <EuiCard
          textAlign="left"
          image={
            <div>
              <img
                src={ivImage02}
                alt="Legen Sie geplante Videoaufnahmen für Ihre Kamera fest."
              />
            </div>
          }
          title="Zeitplan"
          description="Legen Sie geplante Videoaufnahmen für Ihre Kamera fest."
          content={
            <EuiText>
              <p>Legen Sie geplante Videoaufnahmen für Ihre Kamera fest.</p>
            </EuiText>
          }
          footer={
            <EuiFlexGroup justifyContent="flexEnd">
              <EuiFlexItem grow={false}>
                <Link to="/Software/Windows/InstarVision/Record/Continuous_Recording/">
                  <EuiButton size="s" fill>
                    mehr...
                  </EuiButton>
                </Link>
              </EuiFlexItem>
            </EuiFlexGroup>
          }
        />
        </EuiFlexItem>
        <EuiFlexItem>
        <EuiCard
          textAlign="left"
          image={
            <div>
              <img
                src={ivImage03}
                alt="Aktivieren Sie die Bewegungserkennung und die Alarm Videoaufnahme."
              />
            </div>
          }
          title="Alarm Aufnahmen"
          description="Aktivieren Sie die Bewegungserkennung und die Alarm Videoaufnahme."
          content={
            <EuiText>
              <p>Aktivieren Sie die Bewegungserkennung und die Alarm Videoaufnahme.</p>
            </EuiText>
          }
          footer={
            <EuiFlexGroup justifyContent="flexEnd">
              <EuiFlexItem grow={false}>
                <Link to="/Software/Windows/InstarVision/Record/Alarm_Recording/">
                  <EuiButton size="s" fill>
                    mehr...
                  </EuiButton>
                </Link>
              </EuiFlexItem>
            </EuiFlexGroup>
          }
        />
        </EuiFlexItem>
    </EuiFlexGroup>
  );
}
