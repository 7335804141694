import React from 'react'

import {Link} from 'gatsby'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiButton
} from '@elastic/eui'


export default function ImageCard() {

  return (
    <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
      <EuiFlexItem>
        <EuiCard
          textAlign="left"
          title="Kamera Liste"
          footer={
            <EuiFlexGroup justifyContent="flexEnd">
              <EuiFlexItem grow={false}>
                <Link to="/Software/Windows/InstarVision/Camera_List/">
                  <EuiButton size="s" fill>
                    mehr...
                  </EuiButton>
                </Link>
              </EuiFlexItem>
            </EuiFlexGroup>
          }>
          <EuiText>
            <p>Fügen Sie Kameras und elektronische Karten hinzu und bearbeiten Sie bereits existierende.</p>
          </EuiText>
        </EuiCard>
        </EuiFlexItem>
      <EuiFlexItem>
        <EuiCard
          textAlign="left"
          title="Layout"
          footer={
            <EuiFlexGroup justifyContent="flexEnd">
              <EuiFlexItem grow={false}>
                <Link to="/Software/Windows/InstarVision/Layout/">
                  <EuiButton size="s" fill>
                    mehr...
                  </EuiButton>
                </Link>
              </EuiFlexItem>
            </EuiFlexGroup>
          }>
          <EuiText>
            <p>Arrangieren Sie Ihre Kameras nach Ihrem Ansprüchen in Layout Vorlagen.</p>
          </EuiText>
        </EuiCard>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiCard
          textAlign="left"
          title="Kamera PTZ"
          footer={
            <EuiFlexGroup justifyContent="flexEnd">
              <EuiFlexItem grow={false}>
                <Link to="/Software/Windows/InstarVision/PTZ/">
                  <EuiButton size="s" fill>
                    mehr...
                  </EuiButton>
                </Link>
              </EuiFlexItem>
            </EuiFlexGroup>
          }>
          <EuiText>
            <p>Kontrollieren Sie die Pan, Tilt und Zoom Funktionen zum Bewegen Ihrer Kamera.</p>
          </EuiText>
        </EuiCard>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}
