import React from 'react'
import { OutboundLink } from 'gatsby-plugin-gtag'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Wireless Connection">
        <EuiText>
          <p>
            Ihre WiFi Verbindung sollte sich automatisch über WPS oder den <OutboundLink href="https://install.instar.com/" target="_blank" rel="noopener noreferrer">Installation Wizard</OutboundLink> eingerichtet haben. Nutzen Sie den WiFi Scanner um Ihre Kamera mit einem anderen WiFi Netzwerk zu verbinden. Oder nutzen Sie die Suchergebnisse des Scanners um die Signalstärke am Installationspunkt der Kamera zu messen.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

