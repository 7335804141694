import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function LatestArticle() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1080p_Serie_CGI_Befehle/Netzwerk_Menu/IP_Konfiguration/">
                  <StaticImage
                    src='../../../images/1080p_Series_CGI_List/Network_Menu/1080pCGIs-WebUI_Network_IP-Config.png'
                    alt="1080p Serie CGI List - Network Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IP Configuration"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1080p_Serie_CGI_Befehle/Netzwerk_Menu/ONVIF/">
                  <StaticImage
                    src='../../../images/1080p_Series_CGI_List/Network_Menu/1080pCGIs-WebUI_Network_ONVIF.png'
                    alt="1080p Serie CGI List - Multimedia Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="ONVIF"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1080p_Serie_CGI_Befehle/Netzwerk_Menu/Fernzugriff/">
                  <StaticImage
                    src='../../../images/1080p_Series_CGI_List/Network_Menu/1080pCGIs-WebUI_Network_Remote.png'
                    alt="1080p Serie CGI List - Multimedia Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Remote Access"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1080p_Serie_CGI_Befehle/Netzwerk_Menu/UPnP/">
                  <StaticImage
                    src='../../../images/1080p_Series_CGI_List/Network_Menu/1080pCGIs-WebUI_Network_UPnP.png'
                    alt="1080p Serie CGI List - Multimedia Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="UPnP"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1080p_Serie_CGI_Befehle/Netzwerk_Menu/WLAN/">
                  <StaticImage
                    src='../../../images/1080p_Series_CGI_List/Network_Menu/1080pCGIs-WebUI_Network_WiFi.png'
                    alt="1080p Serie CGI List - Multimedia Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="WiFi"
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
