import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class FTPTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "FTP Server" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'FTP Server', descriptionColumn: 'Geben Sie hier die Adresse Ihres FTP-Servers ein. Sie können auch Geräte innerhalb Ihres LANs für diese Funktion verwenden, z. B. einen lokalen Server oder vielleicht Ihren Router oder NAS.' },
        { actionColumn: 'FTP Port', descriptionColumn: 'Der Standardport für den FTP-Dienst ist Port 21. Passen Sie diesen Port hier an, wenn Sie Ihren Server anders konfiguriert haben.' },
        { actionColumn: 'FTP Pfad', descriptionColumn: 'Das FTP-Verzeichnis - der Ordner, in den Ihre Kamera Bilder und Videos hochlädt - ist immer relativ zum Stammverzeichnis Ihres FTP-Benutzers! Das Stammverzeichnis für jeden Benutzer wird auf Ihrem FTP-Server definiert - lesen Sie unser AVM Fritzbox Handbuch für ein Beispiel.' },
        { actionColumn: 'FTP Benutzername', descriptionColumn: 'Der Benutzername - und seine Zugriffsrechte - werden auf Ihrem FTP-Server definiert. Geben Sie hier den Benutzernamen ein, den Ihre Kamera verwenden soll.' },
        { actionColumn: 'FTP Kennwort', descriptionColumn: 'Fügen Sie hier das entsprechende Passwort ein.' },
        { actionColumn: 'FTP Modus', descriptionColumn: 'Sie können in den meisten Fällen den passiven Modus (PASV) verwenden. Wählen Sie den PORT-Modus nur, wenn PASV fehlschlägt. Dies kann passieren, wenn sich Ihr FTP-Server hinter einer NAT-Firewall befindet.' },
        { actionColumn: 'FTP Ordner Struktur', descriptionColumn: 'Wenn Sie eine große Anzahl von Dateien auf den FTP-Server hochladen möchten, empfiehlt es sich, jeden Tag einen neuen Ordner für diese Uploads anzulegen. Bei einigen Systemen kann es zu Problemen mit dieser Einstellung kommen. Versuchen Sie in diesem Fall stattdessen, alle Dateien in einen Ordner hochzuladen.' },
        { actionColumn: 'Test', descriptionColumn: 'Die Testfunktion wird versuchen, eine Datei auf Ihren FTP-Server hochzuladen - der Name der Datei besteht aus dem Großbuchstaben T und einem Zeitstempel. Vergessen Sie nicht, Ihre Einstellungen zuerst zu übermitteln, bevor Sie die Testfunktion verwenden!' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

FTPTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(FTPTable)