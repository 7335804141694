import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="WPA3 Verschlüsselung">
        <EuiText>
            <p>Wenn in Ihrem Router die WLAN Verschlüsselung <strong>WPA2/WPA3</strong> eingestellt ist und die Kamera per WPS in Ihr WLAN eingebunden wird, wird sich die Kamera zuerst per WPA2 verbinden. Erst nach einem Neustart der Kamera wird sie in den WPA3 Modus wechseln.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

