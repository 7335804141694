import React from 'react'
import { Link } from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="BlueIris">
        <EuiText>
         <h2>Alle BlueIris Tutorials</h2>
         <ul>
            <li><Link to="/Software/Windows/Blue_Iris_v5/">BlueIris Version 5</Link></li>
            <ul>
              <li><Link to="/Software/Windows/Blue_Iris_v5/INSTAR_MQTT/">BlueIris MQTT mit Ihrer INSTAR Kamera</Link></li>
              <li><Link to="/Software/Windows/Blue_Iris_v5/Home_Assistant/">BlueIris MQTT mit Home Assistant</Link></li>
              <li><Link to="/Software/Windows/Blue_Iris_v5/ioBroker/">BlueIris MQTT mit ioBroker</Link></li>
              <li><Link to="/Software/Windows/Blue_Iris_v5/Node-RED/">BlueIris MQTT mit Node-RED</Link></li>
            </ul>
            <li><Link to="/Software/Windows/Blue_Iris/">BlueIris v3 Windows Software für Ihre INSTAR IP Kamera</Link></li>
         </ul>
         
         <h2>Alle BlueIris FAQs</h2>
         <ul>
           <li><Link to="/Frequently_Asked_Question/WQHD_with_BlueIris_v5/">Verwendung von BlueIris v5 mit Ihrer 2K+ WQHD-Kamera</Link></li>
           <li><Link to="/Frequently_Asked_Question/BlueIris_v5_http_alarmserver/">BlueIris v5 als HTTP Alarmserver</Link></li>
           <li><Link to="/Frequently_Asked_Question/BlueIris_v5_preset_positions/">BlueIris v5 Einstellen der Preset Positionen</Link></li>
         </ul>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox