import React from 'react'
import { OutboundLink } from 'gatsby-plugin-gtag'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import poe1000a from "../../../images/Products/Power_over_Ethernet/IN-PoE_1000_web.webp"
import poe1000b from "../../../images/Products/Power_over_Ethernet/IN-PoE100_web_black_1.webp"
import poe4000 from "../../../images/Products/Power_over_Ethernet/IN-PoE_4000.webp"
import poe8000 from "../../../images/Products/Power_over_Ethernet/IN-PoE_8000_web.webp"


export default function PoECards() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={poe1000a}
                    alt="IN-PoE 1000"
                  />
              }
              title='IN-PoE 1000'
              description={
                <p>Der <OutboundLink to="https://www.instar.com/accessories/poe-accessories/in-poe1000-poe-injector-black.html">IN-POE 1000</OutboundLink> Power-over-Ethernet-Adapter zur Stromversorgung Ihrer PoE-Geräte - erhältlich in schwarz oder weiß.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={poe1000b}
                    alt="IN-PoE 1000"
                  />
              }
              title='IN-PoE 1000'
              description={
                <p>Der <OutboundLink to="https://www.instar.com/accessories/poe-accessories/in-poe1000-poe-injector-black.html">IN-POE 1000</OutboundLink> Power-over-Ethernet-Adapter - der Plug & Play-Begleiter für Ihre PoE-IP-Kamera.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={poe4000}
                    alt="IN-PoE 4000"
                  />
              }
              title='IN-PoE 4000'
              description={
                <p>Der IN-POE 4000 Power over Ethernet Switches für 4 INSTAR PoE-Kameras.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={poe8000}
                    alt="IN-PoE 8000"
                  />
              }
              title='IN-PoE 8000'
              description={
                <p>Der IN-POE 8000 Power over Ethernet Switches für 7 INSTAR PoE-Kameras.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiSpacer />
    </React.Fragment>
  );
}
