import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'Unboxing',
      href: '/de/Schnell_Installation/Nach_dem_Auspacken/',
    },
    {
      text: 'Erste Schritte',
      href: '/de/Schnell_Installation/Erste_Schritte/',
    },
    {
      text: 'WiFi',
      href: '/de/Schnell_Installation/Herstellen_der_WLAN_Verbindung/',
    },
    {
      text: 'PoE',
      href: '/de/Schnell_Installation/Power_over_Ethernet/',
    },
    {
      text: 'Powerline',
      href: '/de/Schnell_Installation/Powerline/',
    },
    {
      text: 'Scanner',
      href: '/de/Schnell_Installation/Alternative_IP_Scanner/',
    },
    {
      text: 'Update',
      href: '/de/Schnell_Installation/WebUI_und_Firmware_Upgrade/',
    },
    {
      text: '◄ Installation',
      href: '/de/Schnell_Installation/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={11}
        aria-label="Cloud Platform for your INSTAR IP Camera"
      />
      <EuiSpacer />
    </Fragment>
  );
}