import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Image Settings">
        <EuiText>
          <p>
            Hier können Sie die Bildparameter Helligkeit, Sättigung, Kontrast, Belichtung (Shutter) und Schärfe einstellen. Sie können das Videobild spiegeln und spiegeln, einen Zeitstempel anzeigen und einen Kameranamen festlegen und diesen ebenfalls anzeigen. Die angezeigten Parameter werden in das Kamerabild gebrannt. Bitte stellen Sie sicher, dass die Kamera nach dem Ändern des Namens neu gestartet wird.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

