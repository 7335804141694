import React from 'react'
import {Link} from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-gtag'

import {
    EuiText,
    EuiCallOut,
    EuiButton,
    EuiSpacer
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Troubleshooting">
        <EuiText>
          <p>Falls der DDNS-Status als fehlerhaft angezeigt werden sollte, starten Sie Ihre Kamera am besten einmal neu und überprüfen Sie die IP Konfiguration der Kamera aus Schritt 1 (besonders wenn ein Fehler in der Netzwerkkonfiguration angezeigt wird).</p>
          <p>Beachten Sie bitte, das manche Router - besonders einige Modelle des Telekom Speeports - den Zugriff auf die DDNS Adresse aus dem lokalen Netzwerk nicht zulassen. In diesem Fall können Sie einmal das WLAN Ihres Handys deaktivieren und versuchen ueber das Datennetzwerk auf die Kamera zuzugreifen.</p>
          <p>Es kann sein, dass die Verbindung erst nach kurzer Zeit hergestellt werden kann. Sollte das Problem länger als einen Tag bestehen, ist es z.B. möglich, dass Ihr Internet Anschluss über keine öffentliche IPv4 Adresse verfügt. In diesem Fall können Sie den Zugriff über die <Link to="/Fernzugriff/Point_to_Point/">P2P Funktion</Link> einrichten.</p>
          <p>Falls Der Eintrag der Hersteller-Domain fehlt, <OutboundLink href="https://www.instar.de/contact_us/" target="_blank" rel="noopener noreferrer">kontaktieren Sie den Support</OutboundLink> damit wir Ihrer Kamera eine Hersteller-Domain aufspielen können.</p>
        </EuiText>
        <EuiSpacer />
        <Link to="/Fernzugriff/Der_DDNS_Dienst/Testen_der_DDNS_Adresse/"><EuiButton color="danger" fill>DDNS Test</EuiButton></Link>
    </EuiCallOut>
  );
}

export default DangerBox

