import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Achtung">
        <EuiText>
            <p>Nachdem Sie die Kamera durchgeleitet haben, ist diese aus dem Internet für jeden erreichbar. Um Ihre Kamera vor Fremdzugriffen zu schützen, müssen Sie zuvor ein persönliches Kennwort auf der Kamera hinterlegen. Dieses Können Sie im Benutzer Menü unter <Link to="/Web_User_Interface/1440p_Serie/System/Benutzer/">System/Benutzer</Link> hinterlegen.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

