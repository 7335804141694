import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="h.265 Video Codec">
        <EuiText>
          <h3>MIME Type Not Found!</h3>
          <p>Bitte beachten Sie, dass Ihre 2k+ WQHD-Kamera den h.265-Videocodec für ihren primären Videostream verwendet. Dieser Codec wird derzeit nur von Safari (unter macOS) und dem Edge Browser (unter Windows) unterstützt. Wenn Sie den fragmentierten MP4-Stream verwenden möchten, um den Videostream in eine Webseite einzubetten, wird empfohlen, den Videocodec auf h.264 zu ändern, um die Zugänglichkeit zu Ihrer Website zu maximieren. Die Kodierung kann in den <Link to='/Web_User_Interface/1440p_Serie/Multimedia/Video/'>Multimedia/Video Einstellungen</Link> geändert werden.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox
