import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Serie/Multimedia/Video/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_Multimedia_Video.webp'
                    alt="Multimedia Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Video"
              description="Mit den Videoeinstellungen können Sie die Qualität aller 3 Videoströme Ihrer Kamera einstellen. Bitte beachten Sie, dass hohe Qualitätseinstellungen mit hohen Anforderungen verbunden sind..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/720p_Serie/Multimedia/Video/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Serie/Multimedia/Bildeinstellungen/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_Multimedia_Image.webp'
                    alt="Multimedia Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Bild"
              description="Hier können Sie die Bildparameter Helligkeit, Sättigung, Kontrast, Belichtung (Shutter) und Schärfe einstellen. Sie können das Videobild spiegeln und spiegeln..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/720p_Serie/Multimedia/Bildeinstellungen/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Serie/Multimedia/Privat_Maske/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_Multimedia_Privat.webp'
                    alt="Multimedia Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Privatsphärenmaske"
              description="Mit dem Menü Privatsphärenmaske können Sie Bereiche aus Ihrer Überwachung ausblenden. So können Sie z. B. einen Teil der öffentlichen Straße vor Ihrem Haus noch sehen, wenn..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/720p_Serie/Multimedia/Privat_Maske/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Serie/Multimedia/Audio/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_Multimedia_Audio.webp'
                    alt="Multimedia Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Audio"
              description="Hier können Sie die Verstärkung der Mikrofone/Audio-in der Kamera sowie die Lautstärke des Audio-out-Signals einstellen."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/720p_Serie/Multimedia/Audio/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
