import React from 'react'
import {Link} from 'gatsby'

import { EuiAvatar, EuiButtonIcon, EuiText, EuiBadge, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import {
  EuiCommentList,
} from '@elastic/eui'

const alarmSchedule = (
  <EuiFlexGroup responsive={false} alignItems="center" gutterSize="s">
    <EuiFlexItem grow={false}>MQTT Topic: Alarmzeitplan</EuiFlexItem>
    <EuiFlexItem grow={false}>
      <EuiBadge color="primary">Firmware Update</EuiBadge>
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <EuiBadge color="warning">Full HD Serie</EuiBadge>
    </EuiFlexItem>
  </EuiFlexGroup>
);

const annotation = (
  <EuiButtonIcon
    title="Annotation"
    aria-label="Annotation"
    color="subdued"
    iconType="annotation"
  />
);

const timeLine = (
  <EuiButtonIcon
    title="Git Commit"
    aria-label="Git Commit"
    color="subdued"
    iconType="timeline"
  />
);

const alarmScheduleDescription = (
  <EuiText size="s">
    <h3>Fehlendes Topic für den Alarmzeitplan hinzugefügt:</h3>
    <p>
      Ihre Kamera verfügt über 2 Alarmzeitpläne - einen der bestimmt ob die Alarmerkennung scharfgestellt ist oder nicht und einen BackUp Zeitplan der von Ihnen gesetzten Einstellung. Auf diese weise it es möglich den Alarmzeitplan kurzfristig zu manipulieren ("Snooze" Funktion) und anschließend aus dem BackUp wiederherzustellen. Der Wert, der Ihnen in der <Link to="/Web_User_Interface/1080p_Serie/Alarm/Zeitplan/">WebUI angezeigt wird</Link> ist immer den BackUp Zeitplan. Wenn Sie über die MQTT API Änderungen am Zeitplan vornehmen, sollten Sie entsprechend immer beide Zeitpläne gleichzeitig schalten. Dies ist jetzt mit den folgenden Topics möglich:
    </p>
    <br/>
    <ul>
      <li><code>alarm/schedule/dayoftheweek</code></li>
      <li><code>alarm/schedule/dayoftheweek/webui</code></li>
    </ul>
    <br/>
    <p>
      Letzterer ist hierbei für den BackUp Zeitplan gedacht, der unter anderem für die Anzeige in der WebUI verwendet wird. Und der Wert <code>dayoftheweek</code> entsprechend für den Wochentag den Sie anpassen möchten: <Link to="/Erweitert/INSTAR_MQTT_Broker/MQTT_API/">monday, tuesday, wednesday, usw.</Link>.
    </p>
  </EuiText>
);

const mPol = (
  <EuiFlexGroup responsive={false} alignItems="center" gutterSize="s">
    <EuiFlexItem grow={false}>
      <EuiAvatar
        imageUrl="/de/images/Assistants/agentsmith.webp"
        size="m"
        name="Mike Polinowski"
      />
    </EuiFlexItem>
    <EuiFlexItem grow={false}>Mike Polinowski</EuiFlexItem>
  </EuiFlexGroup>
);

const comments = [
  {
    username: mPol,
    type: 'update',
    actions: timeLine,
    event: alarmSchedule,
    timestamp: 'Juni 4, 2021',
    timelineIcon: 'tag',
  },
  {
    username: mPol,
    event: 'INSTAR MQTT: Topic Hinzugefügt',
    children: alarmScheduleDescription,
    actions: annotation,
    timestamp: 'Juni 4, 2021',
    timelineIcon: 'tag',
  }
];

export default () => <EuiCommentList comments={comments} />;