import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class P2PTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Netzwerk Einstellunge" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'P2P Verbindung aktivieren', descriptionColumn: 'Durch das Aktivieren der Point-2-Point Verbindung, verbinden Sie Ihre Kamera direkt mit der InstarVision App auf Ihrem Smartphone oder Tablet. Mit dieser Möglichkeit benötigen Sie keine Portweiterleitung innerhalb Ihres Routers um Ihre Kamera zu erreichen, wenn Sie unterwegs sind. Die Verbindung ist aber - in den meisten Fällen - langsamer als die Geschwindigkeit, die Sie erreichen, wenn Sie die Kamera über eine DDNS Adresse verwenden.' },
        { actionColumn: 'P2P Benutzer ID', descriptionColumn: 'Die ist die Identifikationsnummer Ihrer Kamera, die für die P2P Verbindung verwendet wird. Sie können diese auch manuell in eine Drittanbieter-Software wie P2P Client eingeben. Wenn Sie unsere Smartphone App nutzen, scannen Sie einfach den QR Code unten um alle benötigten Daten, die Sie zur Verbindung brauchen, übermitteln.' },
        { actionColumn: 'QR Code', descriptionColumn: 'Um Ihre Kamera zu unserer iPhone, iPad, Windows Phone, Windows Metro oder Android App hinzuzufügen, scannen Sie einfach den QR Code.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

P2PTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(P2PTable)