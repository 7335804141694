import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Besser ist Manuell">
        <EuiText>
          <p>Falls Sie eine manuelle Portweiterleitung in Ihrem Router verwenden, stellen Sie sicher, dass der UPnP Dienst deaktiviert ist! Wir empfehlen, eine manuelle Portweiterleitung einzurichten.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

