import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'IN-90x',
      href: '/de/Downloadbereich/Utensilien/IN-90x/',
    },
    {
      text: 'IN-DV1215',
      href: '/de/Downloadbereich/Utensilien/IN-DV1215/',
    },
    {
      text: 'IN-Mikro 380',
      href: '/de/Downloadbereich/Utensilien/IN-Mikro_380/',
    },
    {
      text: 'IN-Motion 300',
      href: '/de/Downloadbereich/Utensilien/IN-Motion_300/',
    },
    {
      text: 'IN-Motion 500',
      href: '/de/Downloadbereich/Utensilien/IN-Motion_500/',
    },
    {
      text: 'Zubehör',
      href: '/de/Downloadbereich/Utensilien/',
    },
    {
      text: '◄ Downloadbereich',
      href: '/de/Downloadbereich/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={11}
        aria-label="Downloadbereich for your INSTAR IP Camera"
      />
      <EuiSpacer />
    </Fragment>
  );
}