import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Ändern des Ports des Web Interfaces:">
        <EuiText>
          <p>Der Standard Webport auf dem die Weboberfläche von Shinobi läuft ist <code>8080</code>. Aufgrund eines Portkonfliktes mussten wir diesen im folgenden ändern auf <code>8888</code>. In der Anleitung werden wir weiterhin den Standard-Port angeben, alle Screenshots zeigen allerdings den neu von uns gewählten Port.</p>
          <p><strong>OPTIONAL</strong>: Wenn Sie ebenfalls den Port ändern wollen, können Sie dies im Installationsverzeichnis der Software (Standard: <code>/home/Shinobi</code>) in den Dateien <code>/conf.json</code> und <code>/conf.sample.json</code> tun:</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

