import React, { useState, Fragment } from 'react';

import {
  EuiIcon,
  EuiHeaderAlert,
  EuiHeaderSectionItemButton,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiTitle,
  EuiLink,
  EuiFlyoutFooter,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButtonEmpty,
  EuiText,
  EuiShowFor,
  EuiBadge,
} from '@elastic/eui';

export default () => {
  const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
  const [showBadge, setShowBadge] = useState(true);

  const alerts = [
    {
      title: '2k+ WQHD Update 3.0',
      text: 'MQTT Anpassungen an neu integrierte Funktionen:',
      action: (
        <>
          <EuiLink
            href="https://forum.instar.com/t/firmware-update-3-0-0-733/29825">
            Changelog
          </EuiLink>,{" "} 
          <EuiLink
            href="https://wiki.instar.com/de/Frequently_Asked_Question/WQHD_Alarmserver_Custom_Headers/">
            Arbeiten mit Alarmserver-Headern
          </EuiLink>,{" "} 
          <EuiLink
            href="https://wiki.instar.com/de/Frequently_Asked_Question/Debugging_Alarmserver_Headers/">
            Debugging des HTTP-Alarmservers in Node-RED
          </EuiLink>
        </>
      ),
      date: '8 Mar 2024',
      badge: <EuiBadge color="hollow">Update</EuiBadge>,
    },
    {
      title: '2k+ WQHD Update 3.0',
      text: 'MQTT Anpassungen an neu integrierte Funktionen:',
      action: (
        <>
          <EuiLink
            href="https://forum.instar.com/t/firmware-update-3-0-0-733/29825">
            Changelog
          </EuiLink>,{" "}  
          <EuiLink
            href="https://forum.instar.com/t/firmware-update-3-0-0-733/29825/6">
            Liste neuer MQTT Topics
          </EuiLink>,{" "} 
          <EuiLink
            href="https://wiki.instar.com/de/Erweitert/INSTAR_MQTT_Broker/">
            INSTAR MQTT Dienst
          </EuiLink>
        </>
      ),
      date: '8 Mar 2024',
      badge: <EuiBadge color="hollow">Update</EuiBadge>,
    },
    {
      title: '2k+ WQHD Update 3.0',
      text: 'Erweiterte Benutzer-Verwaltung:',
      action: (
        <>
          <EuiLink
            href="https://forum.instar.com/t/firmware-update-3-0-0-733/29825">
            Changelog
          </EuiLink>,{" "}  
          <EuiLink
            href="https://wiki.instar.com/de/Frequently_Asked_Question/WQHD_User_Permissions/">
            FAQ // Benutzerberechtigungssystem
          </EuiLink>,{" "} 
          <EuiLink
            href="https://wiki.instar.com/de/Web_User_Interface/1440p_Serie/System/Benutzer/">
            System Menü // Benutzerverwaltung
          </EuiLink>
        </>
      ),
      date: '8 Mar 2024',
      badge: <EuiBadge color="hollow">Update</EuiBadge>,
    },
    {
      title: 'Stream Deck',
      text: 'IP-CAMERA Plugin für Ihr Elgato Streamdeck:',
      action: (
        <EuiLink
          href="https://wiki.instar.com/de/Software/Andere_Plattform/Vivre_Motion_Stream_Deck/">
          ELGATO Stream Deck zweckentfremden für IP Kameras / Überwachungskameras
        </EuiLink>
      ),
      date: '25 Jan 2024',
      badge: <EuiBadge color="hollow">Update</EuiBadge>,
    },
    {
      title: '2k+ WQHD Update 2.2',
      text: 'Neuer Ad-hoc WLAN Modus:',
      action: (
        <EuiLink
          href="https://wiki.instar.com/de/Web_User_Interface/1440p_Serie/Netzwerk/WLAN/">
          Neu - Nutzen Sie Ihre Kamera als WLAN Accesspoint!
        </EuiLink>
      ),
      date: '19 Jan 2024',
      badge: <EuiBadge color="hollow">Update</EuiBadge>,
    },
    {
      title: '2k+ WQHD Update 2.2',
      text: 'Neue Polygon-Alarmbereiche:',
      action: (
        <EuiLink
          href="https://wiki.instar.com/de/Web_User_Interface/1440p_Serie/Alarm/Bereiche/">
          Neu - Alarmbereiche mit mehr als 4 Ecken!
        </EuiLink>
      ),
      date: '19 Jan 2024',
      badge: <EuiBadge color="hollow">Update</EuiBadge>,
    },
    {
      title: '2k+ WQHD Update 2.2',
      text: 'Verbesserte ONVIF Unterstützung:',
      action: (
        <EuiLink
          href="https://wiki.instar.com/de/Web_User_Interface/1440p_Serie/Netzwerk/ONVIF/">
          Neu - Nutzen Sie Ihre Kamera über ONVIF in Synology oder QNAP!
        </EuiLink>
      ),
      date: '19 Jan 2024',
      badge: <EuiBadge color="hollow">Update</EuiBadge>,
    },
    {
      title: 'API Suche',
      text: 'INSTAR bietet Ihnen zwei vollständig dokumentierte Programmierschnittstelle mit denen Sie Ihre Kamera entweder über HTTP oder MQTT in Ihre Drittanbietersoftware einbinden können. Die Übersichtsliste der verfügbaren Funktionen ist in einer durchsuchbaren Liste zusammengefasst.',
      action: (
        <EuiLink
          href="https://wiki.instar.com/de/1440p_Serie_CGI_Befehle/Komplette_CGI_Liste/">
          API Suche für HTTP CGI Befehle und MQTT Topics
        </EuiLink>
      ),
      date: '08 Dez 2023',
      badge: <EuiBadge color="hollow">Update</EuiBadge>,
    },
    {
      title: 'WLAN WPA2/3 Enterprise (EAP)',
      text: 'Überwachungskameras mit WPA2/3 Enterprise (EAP): Neue Maßstäbe in Sachen WLAN Sicherheit bei INSTAR. Schauen Sie unser Einführungsvideo hier:',
      action: (
        <EuiLink
          href="https://wiki.instar.com/de/Web_User_Interface/1440p_Serie/Netzwerk/WLAN/">
          1440p Web User Interface - WLAN Menü
        </EuiLink>
      ),
      date: '02 Dez 2023',
      badge: <EuiBadge color="hollow">Update</EuiBadge>,
    },
    {
      title: '2k+ WQHD WebUI Update 2.0',
      text: 'Massive Überarbeitung der Firmware und Weboberfläche mit vielen neuen Funtkionen - wie getrennten Tag/Nacht Bildeinstellungen, verbesserte WDR Funktion, Unterstützung von WPA2 / WPA3 Enterprise (PEAP/TTLS) für die WLAN Verbindung, Integration eines Kontaktformulars direkt in die Weboberfläche, Optimierung der Geräuscherkennung. Das vollaständige Changelog können Sie bei uns im Downloadbereich für Ihr Kameramodell einsehen - z.B. hier:',
      action: (
        <EuiLink
          href="https://wiki.instar.com/de/Downloadbereich/Innenkameras/IN-8415_WQHD/">
          IN-8415 2K+ WQHD Downloadbereich
        </EuiLink>
      ),
      date: '02 Dez 2023',
      badge: <EuiBadge color="hollow">Update</EuiBadge>,
    },
    {
      title: 'InstarVision Android / iOS App Update',
      text: 'Neugestaltung der Mehrfachansicht und Integration von neuen Kameramodellen. Die Apps sind ab sofort über den Google Playstore und Apple Store kostenlos erhältlich.',
      action: (
        <EuiLink
          href="https://wiki.instar.com/de/Downloadbereich/Mobile_Apps/Android_BlackBerry/">
          Android APK Download
        </EuiLink>
      ),
      date: '01 Dez 2023',
      badge: <EuiBadge color="hollow">Update</EuiBadge>,
    },
    {
      title: 'IN-9420 2K+ WQHD PTZ Kamera',
      text: 'Die IN-9420 - der direkte Nachfolger der IN-9020 - wird am 11.12.2023 erstmal in begrenzter Stückzahl verfügbar sein. Der reguläre Verkauf beginnt im Frühjahr.',
      action: (
        <EuiLink
          href="https://wiki.instar.com/de/Aussenkameras/IN-9420_WQHD/">
          IN-9420 2K+ WQHD Übersicht
        </EuiLink>
      ),
      date: '28 Nov 2023',
      badge: <EuiBadge color="hollow">Release</EuiBadge>,
    },
    {
      title: 'InstarVision Android / iOS App Update',
      text: 'Unterstützung für die optionale Entsperrung der App mit Gesichtserkennung und vollen Zugriff auf SD / SD-Dateidownload mit P2P. Jetzt im App Store verfügbar',
      action: (
        <EuiLink
          href="https://wiki.instar.com/de/Downloadbereich/Mobile_Apps/Android_BlackBerry/">
          Android APK Download
        </EuiLink>
      ),
      date: '29 Jul 2021',
      badge: <EuiBadge color="hollow">Update</EuiBadge>,
    },
    {
      title: 'Apple HomeKit Support',
      text: 'Die INSTAR Deutschland GmbH, ein professioneller Entwickler für IP Kameras und Netzwerktechnik, erweitert die Smarthome-Unterstützung durch die Kompatibilität mit Apple HomeKit, zudem erhält die Cloud-Plattform jetzt eine künstliche Intelligenz zur Personen- und Fahrzeugerkennung.',
      action: (
        <EuiLink
          href="https://www.instar.com/blog/homekit-fullhdipkameras/">
          Homekit Update für Full HD Kameras
        </EuiLink>
      ),
      date: '17 Jun 2021',
      badge: <EuiBadge color="hollow">Update</EuiBadge>,
    },
    {
      title: 'FHD WebUI Update3.2 (364) / 3.2(94)',
      text: 'HomeKit Support - Fügen Sie Ihre Kamera jetzt auch in Apple’s HomeKit App hinzu! Aktivieren / deaktivieren Sie die Kennzeichen- oder Gesichtserfassung der Cloud. Sowohl der Backup-Alarmzeitplan als auch der reguläre Alarmzeitplan sind jetzt über MQTT schaltbar.',
      action: (
        <EuiLink
          href="https://wiki.instar.com/de/Schnell_Installation/WebUI_und_Firmware_Upgrade/">
          Über den Auto-Updater herunterladen
        </EuiLink>
      ),
      date: '04 Jun 2021',
      badge: <EuiBadge color="hollow">Update</EuiBadge>,
    },
    {
      title: 'Best #InstarMoments – Gewinner und Preise',
      text: 'Der zweite Wettbewerb zu den besten #InstarMoments endete Anfang Mai mit drei Gewinnern, die jeweils eine neue INSTAR IN-8015 WLAN IP Kamera als Preis erhielten. Die Gewinner-Videos zeigen interessante Momente einschließlich eines schönen Wintereinbruchs, eines lustigen Mäusefilms und eines geschickten „Nussknackers“.',
      action: (
        <EuiLink
          href="https://www.instar.com/blog/best-instarmoments-gewinner/">
          #InstarMoments
        </EuiLink>
      ),
      date: '12 Mai 2021',
      badge: <EuiBadge>Aktion</EuiBadge>,
    },
    {
      title: 'Preiserhöhung durch Chip-Krise',
      text: 'Weltweit ist der Markt der Halbleiter-Chipsätze seit geraumer Zeit extrem aus den Fugen geraten. Wir, als kleines mittelständisches Unternehmen, konnten bislang noch die Turbulenzen, insbesondere vom wirtschaftlichen Aspekt hergesehen, abfangen. Leider ist dies in der Zukunft nicht mehr möglich. Um die Produktion aufrecht zu erhalten sind wir daher gezwungen, die Preise für unsere Kameras anzupassen.',
      action: (
        <EuiLink
          href="https://www.instar.com/blog/instar_chip-krise_preiserhoehung/">
          Chip-Krise bei Halbleitern
        </EuiLink>
      ),
      date: '10 Mai 2021',
      badge: <EuiBadge color="hollow">Info</EuiBadge>,
    },
    {
      title: '#InstarMoments Programm',
      text: 'Bis zum 1. April: Teilen Sie Ihre schönsten INSTAR Aufnahmen und gewinnen Sie tolle Preise!',
      action: (
        <EuiLink
          target="_blank"
          external
          href="https://moments.instar.de">
          Jetzt mitmachen!
        </EuiLink>
      ),
      date: '08 Feb 2021',
      badge: <EuiBadge>Aktion</EuiBadge>,
    },
    {
      title: 'FHD WebUI Update 3.1 (347)',
      text: 'MQTT Adapter wurde überarbeitet - frei wählbares MQTT Präfix und Kamera ID, automatischer Reset des MQTT Alarmservers und neues Last-Will-Topic zum Überwachen der Kamera.',
      action: (
        <EuiLink
          href="https://wiki.instar.com/de/Schnell_Installation/WebUI_und_Firmware_Upgrade/">
          Über den Auto-Updater herunterladen
        </EuiLink>
      ),
      date: '20 Dec 2020',
      badge: <EuiBadge color="hollow">Update</EuiBadge>,
    },
    {
      title: 'INSTAR-Produkte zum Prime Day 2020: Sicherheitskameras im Angebot',
      text:
        'Schnäppchenjäger aufgepasst: Der Amazon Prime Day beginnt ab sofort bis zum 14. Oktober um 23:59 Uhr. Das jährliches Shopping-Event umfasst jede Menge Schnäppchen aus allen Kategorien, von Technik über Fashion bis hin zu Haushaltsartikeln. Auch der Hersteller INSTAR, die Sicherheitskameras sowie Netzwerktechnik hierzulande entwickelt, nimmt an der zweitätigen Sonderaktion teil mit Rabatten von bis zu 60%.',
      action: (
        <EuiLink
          target="_blank"
          external
          href="https://www.instar.de/news/index/view/?id=6">
          Read the news
        </EuiLink>
      ),
      date: '19 Oct 2020',
      badge: <EuiBadge>Aktion</EuiBadge>,
    },
    {
      title: 'InstarVision Android Beta Update v1.5.6',
      text:
        'Fehler im Erweitert/Email Einstellungen Menü behoben. Die Daten für die Alarm Email Benachrichtigung auf der Kamera können jetzt über die App geändert werden. Bei der Anzeige des Alarmstatus in der Multi-Ansicht (siehe letztes Update) wurde der Ladevorgang für eine größere Anzahl eingebundener Kameras optimiert.',
      action: (
        <EuiLink
          target="_blank"
          external
          href="http://wiki.instar.com/dl/InstarVisionAndroid/InstarVision_v1.5.6b2.zip">
          APK herunterladen
        </EuiLink>
      ),
      date: '20 Sept 2020',
      badge: <EuiBadge color="hollow">Update</EuiBadge>,
    },
    {
      title: 'FHD WebUI Update 3.0 (321)',
      text:
        'Audio-Support für den HTML5 Video-Stream im Safari Browser, Die zeitgesteuerte IR-Schaltung wurde mit der IR-Cut-Schaltung verknüpft, Der WDR Modus kann jetzt fest nach Zeitplan eingestellt werden, die Wiki Suche wurde überarbeitet - und mehr',
      action: (
        <EuiLink
          href="https://wiki.instar.com/Web_User_Interface/1080p_Series/System/Update/">
          Über den Auto-Updater herunterladen
        </EuiLink>
      ),
      date: '20 Aug 2020',
      badge: <EuiBadge color="hollow">Update</EuiBadge>,
    },
    {
      title: 'InstarVision Android & iOS Update',
      text: 'Updates sind jetzt über die App Stores verfügbar: Neuer Einrichtungs-Assistent zur Installation und zum Hinzufügen von Kameras, Verbesserte Performance bei der manuellen Aufnahme, Option zum Teilen/Versenden von exportierten Kamera-Daten, Option zum Speichern von Vorlagen für den Alarmzeitplan, Integration neuer Kameramodelle...',
      action: (
        <EuiLink
          target="_blank"
          external
          href="https://wiki.instar.com/dl/InstarVisionAndroid/InstarVision_v1.5.4.zip">
          APK herunterladen
        </EuiLink>
      ),
      date: '20 Jul 2020',
      badge: <EuiBadge color="hollow">Update</EuiBadge>,
    },
  ];

  const closeFlyout = () => {
    setIsFlyoutVisible(false);
  };

  const showFlyout = () => {
    setShowBadge(false);
    setIsFlyoutVisible(!isFlyoutVisible);
  };

  const searchButton = (
    <EuiShowFor sizes={['l', 'xl']}>
      <EuiButtonEmpty
        iconType="search"
        color="text"
        size="m"
        iconSide="right"
        href="/de/Search/">
        Suche
      </EuiButtonEmpty>
    </EuiShowFor>
  );

  const button = (
    <EuiHeaderSectionItemButton
      aria-controls="headerNewsFeed"
      aria-expanded={isFlyoutVisible}
      aria-haspopup="true"
      aria-label={`News feed: ${
        { showBadge } ? 'Updates available' : 'No updates'
      }`}
      onClick={() => showFlyout()}
      notification={showBadge && '1'}>
      <EuiIcon type="boxesVertical" size="m" />
    </EuiHeaderSectionItemButton>
  );

  let flyout;
  if (isFlyoutVisible) {
    flyout = (
      <EuiFlyout
        onClose={() => closeFlyout()}
        size="s"
        id="headerNewsFeed"
        aria-labelledby="flyoutSmallTitle">
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="s">
            <h2 id="flyoutSmallTitle">Was gibt&apos;s neues?</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>
          {alerts.map((alert, i) => (
            <EuiHeaderAlert
              key={`alert-${i}`}
              title={alert.title}
              action={alert.action}
              text={alert.text}
              date={alert.date}
              badge={alert.badge}
            />
          ))}
        </EuiFlyoutBody>
        <EuiFlyoutFooter>
          <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty
                iconType="cross"
                onClick={() => closeFlyout()}
                flush="left">
                Close
              </EuiButtonEmpty>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiText color="subdued" size="s">
                <p>INSTAR News Feed</p>
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlyoutFooter>
      </EuiFlyout>
    );
  }

  return (
    <Fragment>
      {searchButton}
      {button}
      {flyout}
    </Fragment>
  );
};
