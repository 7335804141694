import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class RebootTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Reboot & Reset Einstellungen" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Kamera Modell', descriptionColumn: 'Zeigt die Modellnummer Ihrer Kamera - Für mehr Informationen über Ihr Kamera Modell schauen Sie sich unsere vollständiges Portfolio an.' },
        { actionColumn: 'Firmware Version', descriptionColumn: 'Version des Betriebssystems Ihrer Kamera.' },
        { actionColumn: 'WebUI Version', descriptionColumn: 'Version der Weboberfläche Ihrer Kamera.' },
        { actionColumn: 'Kamera neustarten', descriptionColumn: 'Klicken Sie in die Checkbox und Bestätigen Sie, um die Kamera neuzustarten.' },
        { actionColumn: 'Kamera Reset', descriptionColumn: 'Klicken Sie in die Checkbox und Bestätigen Sie, um die Kamera auf Ihre Werkseinstellungen zu reseten. Bitte beachten Sie, dass Sie nach dem Zurücksetzen auf die Werkseinstellungen, Ihre Kamera wieder mit einem LAN-Kabel einrichten müssen.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

RebootTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(RebootTable)