import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'FAQs',
      href: '/de/FAQs/'
    },
    {
      text: 'Objektive',
      href: '/de/Produkte/Objektive/',
    },
    {
      text: 'Cloud Dienst',
      href: '/de/Bewegungserkennung/INSTAR_Cloud/',
    },
    {
      text: 'Software',
      href: '/de/Software/',
    },
    {
      text: 'Andere Produkte',
      href: '/de/Produkte/Andere/',
    },
    {
      text: 'WebUI',
      href: '/de/Produkte/Web_User_Interface/',
    },
    {
      text: 'Handbücher',
      href: '/de/Produkte/Gebrauchsanleitungen/',
    },
    {
      text: '◄ Produkte',
      href: '/de/Produkte/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={11}
        aria-label="Cloud Platform for your INSTAR IP Camera"
      />
      <EuiSpacer />
    </Fragment>
  );
}