import React from 'react'
import { Link } from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Update:">
        <EuiText>
          <h2>Neuer Alarmserver</h2>
          <br/>
          <ul>
            <li><Link to="/Web_User_Interface/1080p_Serie/Alarm/Alarm_Server/">Alarmserver WebUI Menü</Link></li>
            <li><Link to="/Erweitert/Alarmserver_Queries_for_your_Smarthome/">Arbeiten mit Alarmserver Queries</Link></li>
            <li><Link to="/Frequently_Asked_Question/WQHD_Alarmserver_Custom_Headers/">Arbeiten mit Alarmserver-Headern</Link></li>
          </ul>
          <h3>Beispiel Anwendungsfälle:</h3>
          <ul>
            <li><Link to="/Frequently_Asked_Question/Debugging_Alarmserver_Headers/">Debugging des HTTP-Alarmservers in Node-RED</Link></li>
            <li><Link to="/Frequently_Asked_Question/ioBroker_and_INSTAR_Alarmserver/">ioBroker und INSTAR Alarmserver</Link></li>
            <li><Link to="/Software/Andere_Plattform/Loxone_Miniserver/#use-the-loxone-miniserver-as-alarm-server">Loxone Miniserver</Link></li>
            <li><Link to="/Software/Andere_Plattform/Homematic_IP/#using-the-homematic-ccu3-as-alarmserver-for-our-camera">Homematic CCU3</Link></li>
            <li><Link to="/Erweitert/Homematic_CCU3_and_RedMatic/#instar-ip-camera---homematic">Homematic & RedMatic</Link></li>
            <li><Link to="/Software/Andere_Plattform/Homee/#camera-alarmserver-and-homee-webhook">homee</Link></li>
            <li><Link to="/Software/Andere_Plattform/Athom_Homey/#notifying-homey-when-the-camera-alarm-is-triggered-alarm-server">Homey</Link></li>
            <li><Link to="/Erweitert/IFTTT_als_Alarmserver/">IFTTT</Link></li>
            <li><Link to="/Software/Linux/Home_Assistant/#using-home-assistant-as-alarmserver-for-your-instar-camera">Home Assistant</Link></li>
            <li><Link to="/Frequently_Asked_Question/AS_Alarm_Link_between_Cameras/">Alarmserver zum linken Ihrer Kameras</Link></li>
            <li><Link to="/Frequently_Asked_Question/MQTT_Alarmserver_PIR_Switch/">MQTT Alarmserver als Lichtschalter</Link></li>
            <li><Link to="/Frequently_Asked_Question/BlueIris_v5_http_alarmserver/">BlueIris v5 als HTTP Alarmserver</Link></li>
            <li><Link to="/Frequently_Asked_Question/MQTT_Alarmserver_Interval/">INSTAR MQTT Alarmserver Intervall</Link></li>
            <li><Link to="/Frequently_Asked_Question/INSTAR_MQTT_Alarmserver_Homey/">INSTAR MQTT Alarmserver für Ihr Homey Smarthome</Link></li>
            <li><Link to="/Frequently_Asked_Question/INSTAR_MQTT_Alarmserver_ioBroker/">INSTAR MQTT Alarmserver für Ihren ioBroker</Link></li>
            <li><Link to="/Frequently_Asked_Question/INSTAR_MQTT_Alarmserver_Motion_Tracking/">INSTAR MQTT Alarmserver für Objektverfolgung</Link></li>
            <li><Link to="/Frequently_Asked_Question/INSTAR_MQTT_Alarmserver/">MQTT verwenden, um Ihr Smarthome zu informieren, dass ein Alarm ausgelöst wurde</Link></li>
          </ul>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox