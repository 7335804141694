import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Fotoserie">
        <EuiText>
          <p>
            Nehmen Sie Schnappschüsse in einem einstellbaren Zeitintervall auf und fügen Sie sie in einer Software eines Drittanbieters zusammen, um ein TimeLapse-Video zu erstellen. Sie können die Schnappschüsse auch mit einem festen Dateinamen hochladen. Dadurch wird die letzte Datei immer überschrieben und Sie können ein Live-Bild von Ihrer Kamera auf Ihrer Website anzeigen.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

