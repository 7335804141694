import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Deactivate your Camera´s Status LEDs">
        <EuiText>
          <p>
            Haben Sie die blinkende, blaue LED an der Kamera erkannt? Die kann in einem dunklen Raum sehr nerven. Aber es kann dazu dienen, Probleme mit Ihrer Netzwerkverbindung zu beheben oder Personen darauf aufmerksam machen, dass Sie gefilmt werden. Für alle anderen Zwecke können Sie die LED hier deaktivieren.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

