import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function LatestArticle() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1080p_Serie_CGI_Befehle/Netzwerk_Menu/">
                  <StaticImage
                    src='../../images/1080p_Series_CGI_List/1080pCGIs_Network.png'
                    alt="1080p Serie CGI List - Netzwerk Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Netzwerk Menu"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1080p_Serie_CGI_Befehle/Multimedia_Menu/">
                  <StaticImage
                    src='../../images/1080p_Series_CGI_List/1080pCGIs_Features.png'
                    alt="1080p Serie CGI List - Features Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Multimedia Menu"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1080p_Serie_CGI_Befehle/Features_Menu/">
                  <StaticImage
                    src='../../images/1080p_Series_CGI_List/1080pCGIs_Features.png'
                    alt="1080p Serie CGI List - Features Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Features Menu"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1080p_Serie_CGI_Befehle/Alarm_Menu/">
                  <StaticImage
                    src='../../images/1080p_Series_CGI_List/1080pCGIs_Alarm.png'
                    alt="1080p Serie CGI List - Alarm Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Alarm Menu"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1080p_Serie_CGI_Befehle/System_Menu/">
                  <StaticImage
                    src='../../images/1080p_Series_CGI_List/1080pCGIs_System.png'
                    alt="1080p Serie CGI List - System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="System Menu"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1080p_Serie_CGI_Befehle/Komplette_CGI_Liste/">
                  <StaticImage
                    src='../../images/1080p_Series_CGI_List/1080pCGIs_Network.png'
                    alt="1080p Serie CGI List"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Complete CGI List"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
