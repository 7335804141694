import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Achtung">
        <EuiText>
          <p>Der Benutzername und das Kennwort darf maximal 30 Zeichen lang sein, es muss alphanumerisch sein, wobei folgende Sonderzeichen <u>unterstützt</u> werden: <code>&#33; &#35; &#40; &#41; &#42; &#45; &#46; &#47; &#63; &#64; &#91; &#93; &#94; &#95; &#123; &#124; &#125; &#60;</code> . Folgende Sonderzeichen werden derzeit <u>nicht unterstützt</u>: <code>ä ö ü ´ " ' , &gt; ; ~ $ § % +</code></p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

