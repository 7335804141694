import React from 'react'
import {Link} from 'gatsby'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText
} from '@elastic/eui'

import qiSelect01 from "../../../../images/Indoor_Cameras/IN-6001_HD/Quick_Installation/Quickinstallation_IN-6001_01.webp"
import qiSelect02 from "../../../../images/Indoor_Cameras/IN-6001_HD/Quick_Installation/Quickinstallation_IN-6001_02.webp"
import qiSelect03 from "../../../../images/Indoor_Cameras/IN-6001_HD/Quick_Installation/Quickinstallation_IN-6001_03.webp"
import qiSelect04 from "../../../../images/Indoor_Cameras/IN-6001_HD/Quick_Installation/Quickinstallation_IN-6001_04.webp"
import qiSelect05 from "../../../../images/Indoor_Cameras/IN-6001_HD/Quick_Installation/Quickinstallation_IN-6001_05.webp"
import qiSelect06 from "../../../../images/Indoor_Cameras/IN-6001_HD/Quick_Installation/Quickinstallation_IN-6001_06.webp"
import qiSelect07 from "../../../../images/Indoor_Cameras/IN-6001_HD/Quick_Installation/Quickinstallation_IN-6001_07.webp"
import qiSelect08 from "../../../../images/Indoor_Cameras/IN-6001_HD/Quick_Installation/Quickinstallation_IN-6001_08.webp"
import qiSelect09 from "../../../../images/Indoor_Cameras/IN-6001_HD/Quick_Installation/Quickinstallation_IN-6001_09.webp"
import qiSelect10 from "../../../../images/Indoor_Cameras/IN-6001_HD/Quick_Installation/Quickinstallation_IN-6001_10.webp"
import qiSelect11 from "../../../../images/Indoor_Cameras/IN-6001_HD/Quick_Installation/Quickinstallation_14.webp"



export default function QIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect01}
                    alt="Schließen Sie zuerst die Kamera am Netzteil an. Wenn Sie die Kamera nur per LAN Kabel betreiben möchten, springen Sie bitte zu Schritt 4."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Schließen Sie zuerst die Kamera am Netzteil an. Wenn Sie die Kamera nur per LAN Kabel betreiben möchten, springen Sie bitte zu Schritt 4.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect02}
                    alt="Wir empfehlen, dass Sie einen möglichst freien WLAN Kanal in Ihrem Router wählen und die WPA2 (AES bzw. CCMP) Verschlüsselung einstellen (nicht TKIP)."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Wir empfehlen, dass Sie einen möglichst freien WLAN Kanal in Ihrem Router wählen und die WPA2 (AES bzw. CCMP) Verschlüsselung einstellen (nicht TKIP).
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect03}
                    alt="A Press the WPS button (s. above) on your router. B Press the camera´s reset button for 3s . Be aware that holding it for longer than 10s will result in a camera reset. C The camera will now automatically connect to your WiFi network. Please skip to step 7 (for PC or Mac) or 14 (for smartphone)."
                  />
              }
              footer={
                <EuiText>
                  <ul>
                    <li><strong>A</strong> Drücken Sie den WPS Button auf Ihrem Router (s. o.).</li>
                    <li><strong>B</strong> Drücken Sie den Reset Taster der Kamera für 3s (länger als 10s führt zu einem Werksreset!).</li>
                    <li><strong>C</strong> Die Kamera wird sich danach automatisch mit Ihrem WLAN verbinden. Springen Sie zu Schritt 7 (PC or Mac) oder Schritt 11 (Smartphone).</li>
                  </ul>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect04}
                    alt="Stecken Sie das Netzwerkkabel in den (Anschluss s. #12) im hinteren Teil der Kamera um eine LAN Verbindung aufzubauen (auch für WLAN benötigt, wenn kein WPS vorhanden s. #4)."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Stecken Sie das Netzwerkkabel in den (Anschluss s. <Link to="/Innenkameras/IN-6001_HD/Product_Features/">#12</Link>) im hinteren Teil der Kamera um eine LAN Verbindung aufzubauen (auch für WLAN benötigt, wenn kein WPS vorhanden s. #4).
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect05}
                    alt="Stecken Sie das andere Ende des Netzwerkkabels in einen freien LAN Port Ihres Internet Routers."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Stecken Sie das andere Ende des Netzwerkkabels in einen freien LAN Port Ihres Internet Routers.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect06}
                    alt="Starten Sie das IP Camera Tool von der Software CD und führen Sie einen Doppelklick auf den Namen der Kamera aus."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Starten Sie das <a href="https://install.instar.com/WQHDv2/IN-9408_WQHD_Installation/01_LAN_Integration/03_Find_your_Camera" target="_blank" rel="noopener noreferrer">IP Camera Tool</a> von der <a href="https://install.instar.com" target="_blank" rel="noopener noreferrer">Software CD</a> und führen Sie einen Doppelklick auf den Namen der Kamera aus.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect07}
                    alt="Standard Logins Benutzer/Kennwort: admin / instar für Administratoren, user / instar für normale Nutzer und guest / instar für Gäste."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Standard Logins Benutzer/Kennwort: <strong>admin / instar</strong> für Administratoren, <strong>user / instar</strong> für normale Nutzer und <strong>guest / instar</strong> für Gäste.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect08}
                    alt="Aktivieren Sie das WLAN der Kamera in der Weboberfläche mittels des WLAN- Scanners. Nach der Eingabe und Speicherung des WLAN Passwortes muss das LAN Kabel gezogen und die Kamera neugestartet werden."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Aktivieren Sie das WLAN der Kamera in der Weboberfläche mittels des <Link to="/Web_User_Interface/720p_Serie/Netzwerk/WLAN/">WLAN- Scanners</Link>. Nach der Eingabe und Speicherung des WLAN Passwortes muss das LAN Kabel gezogen und die Kamera neugestartet werden.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect09}
                    alt="Für den Zugriff über das Internet ist eine Portweiterleitung des HTTP Ports (Standard “80”) in Ihrem Router notwendig."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Für den Zugriff über das Internet ist eine Portweiterleitung des HTTP Ports (Standard “80”) in Ihrem Router notwendig. Für den Zugriff auf den <Link to="/Web_User_Interface/720p_Serie/Multimedia/Video/">Flash Videostream</Link> (z.B. über das Flash Plugin im Webbrowser) benötigen Sie noch die Weiterleitung des <Link to="/Web_User_Interface/720p_Serie/Netzwerk/IP_Konfiguration/">RTMP Ports</Link> (Standard “1935”). siehe auch #14
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect10}
                    alt="Now you can enter your camera´s DDNS information to our free Windows Phone, Windows Metro, Android, iPhone, iPad or Blackberry App."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Sie können jetzt unsere kostenlose <Link to="/Software/Windows/InstarVision/Windows_Phone/DDNS/">Windows Phone</Link>, <Link to="/Software/Windows/InstarVision/Metro/DDNS/">Windows Metro</Link>, <Link to="/Software/Android/InstarVision/DDNS/">Android</Link>, <Link to="/Software/iOS/InstarVision/iPhone/DDNS/">iPhone</Link>, <Link to="/Software/iOS/InstarVision/iPad/DDNS/">iPad</Link> oder <Link to="/Software/Other_Platforms/InstarVision_Blackberry/">Blackberry</Link> App nutzen um auf die Kamera über das Internet zuzugreifen.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect11}
                    alt="If you cannot open a port for camera´s DDNS service, you can still use the Point-2-Point Service instead with our Windows Phone, Windows Metro, Android, iPhone, iPad or Blackberry App."
                  />
              }
              footer={
                <EuiText>
                    <p>
                      Wenn Sie keinen Port für den DDNS-Dienst der Kamera öffnen können, können Sie weiterhin den <Link to="/Web_User_Interface/1080p_Serie/Netzwerk/Fernzugriff/">Point-2-Point Dienst</Link> nutzen, um mit unserer <Link to="/Software/Windows/InstarVision/Windows_Phone/P2P/">Windows Phone</Link>, <Link to="/Software/Windows/InstarVision/Metro/P2P/">Windows Metro</Link>, <Link to="/Software/Android/InstarVision/P2P/">Android</Link>, <Link to="/Software/iOS/InstarVision/iPhone/P2P/">iPhone</Link>, <Link to="/Software/iOS/InstarVision/iPad/P2P/">iPad</Link> oder <a href="/Software/Other_Platforms/InstarVision_Blackberry/">Blackberry</a> App auf die Kamera zuzugreifen.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
