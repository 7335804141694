import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class ImageAdvancedTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Erweiterte Bildeinstellungen" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Voreinstellungen', descriptionColumn: 'Die ganzen Bildeinstellungen können etwas überwältigen. Die Voreinstellungen bieten Ihnen einen Startpunkt für übliche Lichtverhältnisse. Wählen Sie ein Preset aus und klicken Sie auf übernehmen. Führen Sie im Anschluß noch noch ein paar Feineinstellungen durch. Sollte das Bild der Kamera einmal vollständig "verstellt" sein, können Sie es hier wieder auf die Standardwerte zurückbringen.' },
        { actionColumn: 'Bildeinstellungen aktivieren', descriptionColumn: 'Ihre Kamera verwendet unterschiedliche Bildeinstellungen für Tag und Nacht. Standardmäßig ist dies auf automatisch eingestellt und ermöglicht Ihrer Kamera, die richtigen Einstellungen zu laden, je nachdem, ob sie im Tag- oder Nachtmodus läuft. Sie können Ihre Kamera zwingen, eine der beiden Einstellungen zu laden, indem Sie hier Tag- oder Nachteinstellungen auswählen.' },
        { actionColumn: 'Tag/Nacht', descriptionColumn: 'Klicken Sie hier, um zwischen den Einstellungen für Tag und Nacht umzuschalten. Beachten Sie, dass Ihre Kamera NICHT in den entsprechenden Modus umschaltet, wenn Sie auf diese Schaltfläche klicken. Nur Änderungen, die an den derzeit aktiven Einstellungen vorgenommen werden, sind im Live-Video Ihrer Kamera sichtbar. Verwenden Sie das Dropdown-Menü oben, um die Einstellungen für den derzeit nicht aktiven Modus zu laden, damit Sie diese Einstellungen anpassen können.' },
        { actionColumn: 'Bild entzerren', descriptionColumn: 'Entzerren Sie das Videobild, um den Fischaugeneffekt eines Weitwinkel-Objektives entgegenzuwirken.' },
        { actionColumn: 'Wide Dynamic Range', descriptionColumn: 'Wird Ihre Kamera von einer starken Hintergrundbeleuchtung geblendet? Oder ist die Hälfte des Bildes im Schatten und Details in diesem Bereich kaum zu erkennen. Mit dem leistungsstarken WDR-Modus können Sie mit diesen Situationen umgehen. Optimieren Sie das Bild indem Sie weiter unten die Gammastufe anheben oder absenken, um die maximalen Details aus dem Kamerabild herauszuholen.' },
        { actionColumn: 'WDR Stärke', descriptionColumn: 'Dieser Schieberegler passt die Stärke des WDR-Modus an. Je höher der Wert, desto mehr versucht der Sensor, Bereiche mit hohem Kontrast in Ihrem Videobild zu kompensieren. Hohe Werte können den Blendungseffekt starker Lichtquellen kompensieren. Ein hoher Wert für die WDR-Stärke wird die Schatten aufhellen und gleichzeitig die Helligkeit der Lichtquelle verringern. Beachten Sie, dass ein hoher Wert von WDR einen Blooming-Effekt in der Umgebung von Lichtquellen verursacht, wodurch das Bild weniger scharf erscheint. Verringern Sie die WDR-Stärke, um diesen Effekt zu reduzieren.' },
        { actionColumn: 'Flimmerunterdrückung', descriptionColumn: 'Lichtquellen, die mit Wechselstrom betrieben werden, fluktuieren in Ihrer Lichtintensität mit der Frequenz der Stromquelle. Dies kann dazu führen, dass das Bild der Kamera zu flackern beginnt. Aktivieren Sie die Unterdrückung, um diesen Effekt zu reduzieren. Beachten Sie, daß es manchmal notwendig ist, den WDR Modus zu deaktivieren, wenn das Flackern zu stark wird. Gerade bei LED Lichtquellen kann man hier manchmal, bei aktivierten WDR, dunkle Balken durchs Bild wandern sehen.' },
        { actionColumn: 'Frequenz', descriptionColumn: 'In Deutschland liegt die Wechselstrom Frequenz bei 50Hz. In den USA liegt sie jedoch bei 60Hz. Stellen Sie hier die landesspezifische Frequenz für die Flimmerunterdrückung ein.' },
        { actionColumn: 'Max ISO', descriptionColumn: 'Der ISO Wert entspricht der maximalen Verstärkung des Sensorsignals, die die Kamera verwenden darf, um das Bild aufzuhellen. Die Skala ist in 32 Schritte unterteilt, was einem Breich von 400 - 12800 ISO entspricht. Die Kamera verwendet die maximal erlaubte Einstellung nur, wenn sie sie auch benötigt. D.h. Einstellungen hier werden nur einen sichtbaren Effekt haben, wenn die Kamera sich im dunklen befindet! Ein zu hoher Wert wird zu starkem Bildrauschen führen.' },
        { actionColumn: 'Auto. Entrauschen', descriptionColumn: 'Entfernen Sie digitales Rauschen in ihrem Kamerabild.' },
        { actionColumn: 'Fester Entrauschwert', descriptionColumn: 'Deaktivieren Sie das automatische Entrauschen oben, um einen manuellen Wert einzustellen. Wenn Sie mit den von Ihnen gewählten Bildeinstellungen ein zu starkes Bildrauschen erhalten, können Sie diesen Wert über 15 Stufen anheben. Beachten Sie bitte, dass ein hoher Wert jedoch dazu führt, dass Sie Details im Video verlieren. Für die Überwachung ist ein leicht verrauschtes Bild häufig besser auszuwerten (z.B. um Nummernschildern oder Gesichter zu erkennen).' },
        { actionColumn: 'Auto. Gamma-Einstellung', descriptionColumn: 'Automatische Gamma Korrektur. Stellen Sie den Gamma-Wert des Bilds ein. Die Gamma Korrektur hebt (oder senkt) die Mitteltöne im Bild, während sehr dunkle und sehr helle Bereiche nicht verändert werden. Das bedeutet, während der Helligkeit Wert unterbelichtete Bilder über das gesamte Spektrum anhebt und das Ergebnis ein sehr verwaschen aussehendes Bild ist, können Sie mit dem Gamma Wert das Bild aufhellen (oder abdunkeln) ohne den Kontrast zu verlieren.' },
        { actionColumn: 'Gamma', descriptionColumn: 'Das Bild wird heller, je weiter Sie den Gammawert anheben. Auf diese Weise können Sie mehr Details in dunklen Bereiche im Bild erkennen, ohne die Informationen in den hellen Bereichen zu verlieren. Wenn die Kamera geblendet wird, z.B. von einer starken Lichtquellen bei Nacht, kann ein sehr niedriger Gammawert im überblendeten Bereich des Bildes sichtbar machen.' },
        { actionColumn: 'Auto. Schwarzwert', descriptionColumn: 'Automatische Schwarzwert Korrektur.' },
        { actionColumn: 'Schwarzwert', descriptionColumn: 'Legen Sie hier fest ab wann eine Helligkeitsstufe als Schwarz eingestuft wird. Regeln Sie den Wert ein, bis das Bild natürlich wirkt. Ein zu hoher Wert wird dem Bild (bei Sonnenlicht) einen Grünstich geben. Bei einem zu niedrigen Wert bekommen Sie ein rosa oder pink gefärbtes Live Video.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

ImageAdvancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(ImageAdvancedTable)