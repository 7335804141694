import React from 'react'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiCard
} from '@elastic/eui'


export default function QRDownloads() {

  return (
    <EuiFlexGroup gutterSize="l">
    <EuiFlexItem>
      <EuiCard
        textAlign="left"
        href="https://www.microsoft.com/de-de/store/p/instarvision/9nblggh10wtp"
        image="/en/images/Downloads/Qrcode_Microsoft-AppStore.webp"
        title={'InstarVision Metro'}
        description="Laden Sie die InstarVision Metro Desktop App aus dem Microsoft Store herunter."
      />
    </EuiFlexItem>
    <EuiFlexItem>
      <EuiCard
        textAlign="left"
        href="https://www.microsoft.com/de-de/store/p/instarvision/9nblggh10wtp"
        image="/en/images/Downloads/Qrcode_Microsoft-AppStore.webp"
        title={'InstarVision Windows Phone'}
        description="Laden Sie die InstarVision Windows Phone App aus dem Microsoft Store herunter."
      />
    </EuiFlexItem>
  </EuiFlexGroup>
  );
}