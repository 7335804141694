import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Geplante Aufnahme">
        <EuiText>
          <p>
            Nehmen Sie Videos in einstellbaren Zeitintervallen auf. Die Aufnahme erfolgt, in der gewählten Auflösung, auf der internen SD Karte der Kamera. Es wird empfohlen die maximale Länge der Aufnahme zu minimieren, so daß - innerhalb des aktiven Zeitfensters - alle paar Minuten eine neue Aufnahme begonnen wird.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

