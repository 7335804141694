import { EuiSpacer } from '@elastic/eui';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import TriggerTable from 'components/Frequently_Asked_Question/WQHD_MQTTv5_Alarmserver_APIv2_NodeRED/triggerTable';
import TriggerCombinedTable from 'components/Frequently_Asked_Question/WQHD_MQTTv5_Alarmserver_APIv2_NodeRED/triggerCombinedTable.jsx';
import * as React from 'react';
export default {
  EuiSpacer,
  BreadCrumbs,
  SEOHelmet,
  TriggerTable,
  TriggerCombinedTable,
  React
};