import React from 'react'
import {Link} from 'gatsby'

import {
  EuiCard,
  EuiText,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

import privacyError1 from "../../../../../images/Web_User_Interface/1080p_Series/Network/IP_Configuration/https_privacy_error_01.webp"
import privacyError2 from "../../../../../images/Web_User_Interface/1080p_Series/Network/IP_Configuration/https_privacy_error_02.webp"

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={privacyError1}
                    alt="Verwendung von HTTPS innerhalb Ihres lokalen Netzwerks"
                  />
              }
              title='Verwendung von HTTPS innerhalb Ihres lokalen Netzwerks'
              description={
                <EuiText>
                    <p>
                      Das INSTAR SSL-Zertifikat ist nur gültig, wenn Sie über die <Link to="/Web_User_Interface/1080p_Serie/Netzwerk/Fernzugriff/">DDNS-Adresse</Link> der Kamera zugreifen. Wenn Sie versuchen, die HTTPS-Verschlüsselung zu verwenden, wenn Sie auf Ihre Kamera innerhalb Ihres lokalen Netzwerks (über ihre lokale IP-Adresse) zugreifen, wird eine Sicherheitswarnung angezeigt.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={privacyError2}
                    alt="Akzeptieren Sie das Zertifikat für die lokale IP-Adresse"
                  />
              }
              title='Akzeptieren Sie das Zertifikat für die lokale IP-Adresse'
              footer={
                <EuiText>
                    <p>
                      Klicken Sie auf Erweitert (die Benennung dieser Schaltfläche variiert je nach Browser) und klicken Sie darauf, dass Sie trotzdem fortfahren möchten. Die Webbrowser Safari und Firefox werden Sie zusätzlich bitten, eine Ausnahme für das Zertifikat hinzuzufügen - klicken Sie auf Ok, um fortzufahren.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
