import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Weitere Anmerkung">
        <EuiText>
          <p>Ihre Kamera unterstützt weitere Verschlüsselungsstandards - wir empfehlen für Ihre Sicherheit und eine zuverlässige Verbindung den gemischten Modus WPA3/WPA2. Wenn Sie einen älteren Router verwenden, wählen Sie WPA2 Standard mit AES/CCMP. Vermeiden Sie den Modus WPA (PSK) und WPA2/WPA mit TKIP! Wenn Ihr WiFi instabil bleibt, wählen Sie bitte einen festen WiFi Kanal für Ihren Router.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

