import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Serie/Software/Sicherung/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_Software_Backup.webp'
                    alt="Software Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Sicherung"
              description="Sie können Ihre Kamerakonfiguration sichern, nachdem Sie alles eingerichtet haben. Laden Sie die Datei herunter und benennen Sie sie für eine spätere Wiederherstellung entsprechend. Bitte beachten Sie, dass Software-Updates..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/720p_Serie/Software/Sicherung/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Serie/Software/Sprache/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_Software_Language.webp'
                    alt="Software Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Sprache"
              description="Ändern Sie die Sprache der Web-Bedienoberfläche auf Deutsch, Englisch, Französisch oder Chinesisch. Die Benutzeroberfläche stellt die Sprache automatisch entsprechend der Sprache..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/720p_Serie/Software/Sprache/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Serie/Software/Neustart_Werkseinstellungen/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_Software_Reboot_Reset.webp'
                    alt="Software Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Neustart & Werkseinstellungen"
              description="Ändern Sie die IP-Konfiguration oder die WiFi-Einstellungen Ihrer Kamera oder verbinden Sie sie mit Ihrem Smartphone, indem Sie den DDNS-, P2P- oder Push-Service-QR-Code mit unserer Smartphone-App scannen..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/720p_Serie/Software/Neustart_Werkseinstellungen/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Serie/Software/Update/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_Software_Update.webp'
                    alt="Software Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Update"
              description="Die INSTAR-Softwareabteilung arbeitet ständig daran, Ihre Erfahrungen mit unserem Produkt zu verbessern. Sie können den Update-Bereich von Zeit zu Zeit überprüfen..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/720p_Serie/Software/Update/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
