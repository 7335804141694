import React from 'react'

import {
  EuiSpacer
} from '@elastic/eui'

import MemoryGraph from "./memory-load-graph"
import MemoryGraphMulti from "./memory-load-graph-multi"

function ProcessorCard() {
  return (
    <div>
        <h3>Speicher Auslastung [MB]</h3>
        <MemoryGraph />
        <EuiSpacer />
        <MemoryGraphMulti />
        <EuiSpacer />
        <div>
          <p><strong>Setup</strong>: Für den 4-Kamera-Test wurden nur HD-Kameras verwendet. Bei den 8 Kameras wurde 1 VGA- und 1 FHD-Kamera hinzugefügt. Bei der 16-Kamera-Konfiguration wurden 12 HD-, 2 VGA- und 2 FHD-Kameras verwendet.</p>
        </div>
    </div>
  );
}

export default ProcessorCard