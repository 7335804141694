import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Serie/Features/Email_Einstellungen/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_Features_Email.webp'
                    alt="INSTAR 1440p Web User Interface Features Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Email Einstellungen"
              description="Hier müssen Sie den Absender, die Empfänger, den Betreff sowie optional einen kurzen E-Mail-Text mit maximal 64 Zeichen eingeben. Die Absenderadresse sollte mit der SMTP-Adresse übereinstimmen..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1440p_Serie/Features/Email_Einstellungen/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Serie/Features/FTP/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_Features_FTP.webp'
                    alt="INSTAR 1440p Web User Interface Features Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="FTP Einstellungen"
              description="Um den Video-FTP-Upload zu verwenden, müssen Sie zunächst die Adresse Ihres FTP-Servers und Ihre Anmeldedaten festlegen. z.B. können Sie den FTP-Dienst Ihres Routers als FTP-Server verwenden..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1440p_Serie/Features/FTP/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Serie/Features/IR_Nachtsicht/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_Features_Nightvision.webp'
                    alt="INSTAR 1440p Web User Interface Features Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IR Nachtsicht"
              description="Die IR-LEDs Ihrer Kamera werden durch den internen Lichtsensor automatisch ein- und ausgeschaltet. Wenn Sie Ihre Kamera hinter einem stark reflektierenden Fenster verwenden möchten oder Sie Ihre Kamera..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1440p_Serie/Features/IR_Nachtsicht/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Serie/Features/PTZ/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_Features_PTZ.webp'
                    alt="INSTAR 1440p Web User Interface Features Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Pan & Tilt"
              description="Deaktivieren Sie den Kalibrierungslauf Ihrer Kamera beim Start (deaktiviert auch die gespeicherten Positionen). Lassen Sie Ihre Kamera nach einem Neustart eine gespeicherte Position anfahren oder begrenzen Sie den Bereich..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1440p_Serie/Features/PTZ/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Serie/Features/PTZ_Tour/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_Features_PTZ_Tour.webp'
                    alt="INSTAR 1440p Web User Interface Features Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Pan & Tilt Tour"
              description="Deaktivieren Sie den Kalibrierungslauf Ihrer Kamera beim Start (deaktiviert auch die gespeicherten Positionen). Lassen Sie Ihre Kamera nach einem Neustart eine gespeicherte Position anfahren oder begrenzen Sie den Bereich..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1440p_Serie/Features/PTZ_Tour/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Serie/Features/SD_Karte/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_Features_SD_Card.webp'
                    alt="INSTAR 1440p Web User Interface Features Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="SD Karte"
              description="Bitte wählen Sie das entsprechende Kästchen aus und klicken Sie auf Senden, um Ihre SD-Karte entweder zu formatieren oder auszuhängen. Ihre SD-Karte ist möglicherweise nach einem unerwarteten Neustart nicht mehr verfügbar..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1440p_Serie/Features/SD_Karte/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Serie/Features/Manuelle_Aufnahme/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_Features_SD_Card.webp'
                    alt="INSTAR 1440p Web User Interface Features Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Manuelle Aufnahme"
              description="Definieren Sie hier die Gesamtdauer und Auflösung für manuelle Aufnahmen, die Sie über die Schnellleiste initiieren können. Beachten Sie, dass die eingestellte Vorlaufzeit in die Gesamtdauer der Aufnahme..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1440p_Serie/Features/Manuelle_Aufnahme/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Serie/Features/Status_LED/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_Features_Status_LEDs.webp'
                    alt="INSTAR 1440p Web User Interface Features Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Status LED"
              description="Ist Ihnen die blaue und blinkende LED an der Kamera aufgefallen? Sie kann in einem dunklen Raum sehr störend sein. Sie kann aber auch zur Fehlersuche bei der Netzwerkverbindung oder als Hinweis auf die..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1440p_Serie/Features/Status_LED/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Serie/Features/Wizard/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_Features_Wizard.webp'
                    alt="INSTAR 1440p Web User Interface Features Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Wizard"
              description="Installation Wizard."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1440p_Serie/Features/Wizard/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
