import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="INSTAR IFTTT Applets">
        <EuiText>
          <p>
            Der Name IFTTT steht im Englichen für “if this, then that.”. IFTTT bietet Ihnen eine Software-Plattform, die Anwendungen, Geräte und Dienste von verschiedenen Entwicklern miteinander verbindet, um eine oder mehrere Automatisierungen mit diesen Anwendungen, Geräten und Diensten auszulösen.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

