import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Serie/Software/">
                  <StaticImage
                    src='../../../images/Search/WebUI_720p_SearchThumb_Software_Update.webp'
                    alt="Software Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Software Menu"
              description="Ändern Sie die Sprache der Web-Benutzeroberfläche, führen Sie einen Neustart oder Reset durch oder laden Sie ein Firmware- und Web-UI-Update auf Ihre Kamera."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/720p_Serie/Software/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Serie/Netzwerk/">
                  <StaticImage
                    src='../../../images/Search/WebUI_720p_SearchThumb_Network_IP_Configuration.webp'
                    alt="720p Series CGI List - Netzwerk Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Network Menu"
              description="Ändern Sie die IP-Konfiguration oder die WiFi-Einstellungen Ihrer Kamera oder verbinden Sie sie mit Ihrem Smartphone, indem Sie den DDNS-, P2P- oder Push-Service-QR-Code mit unserer Smartphone-App scannen."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/720p_Serie/Netzwerk/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Serie/System/">
                  <StaticImage
                    src='../../../images/Search/WebUI_720p_SearchThumb_System_Info.webp'
                    alt="720p Series CGI List - System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="System Menu"
              description="Ändern Sie die Systemeinstellungen Ihrer Kamera, passen Sie die Status- und IR-LEDs oder das Schwenk-, Neige- und Zoomverhalten Ihrer Kamera an."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/720p_Serie/System/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Serie/Multimedia/">
                  <StaticImage
                    src='../../../images/Search/WebUI_720p_SearchThumb_Multimedia_Video.webp'
                    alt="720p Series CGI List - Multimedia Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Multimedia Menu"
              description="Passen Sie die Videoqualität und die Audiolautstärke Ihrer Kamera an. In den Bildeinstellungen können Sie Kontrast, Sättigung und Helligkeit Ihrer Kamera einstellen."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/720p_Serie/Multimedia/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Serie/Alarm/">
                  <StaticImage
                    src='../../../images/Search/WebUI_720p_SearchThumb_Alarm_Alarmserver.webp'
                    alt="720p Series CGI List - Alarm Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Alarm Menu"
              description="Aktivieren Sie die Bewegungs- oder Audioerkennung Ihrer Kamera und die Aktionen, die im Falle eines Alarms ausgeführt werden sollen - wie Videoaufnahmen, E-Mail-Benachrichtigungen oder FTP-Uploads."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/720p_Serie/Alarm/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1080p_Serie/Cloud/">
                  <StaticImage
                    src='../../../images/Search/WebUI_720p_SearchThumb_Cloud_Introduction.webp'
                    alt="720p Series CGI List - Cloud Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Cloud Menu"
              description="Ein Überblick über unsere Online-Aufzeichnungsplattform für Ihre INSTAR IP-Kamera."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1080p_Serie/Cloud/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
