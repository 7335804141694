import React from 'react'
import {EuiImage} from '@elastic/eui'


import weeSymbolImage from '../../../../images/Indoor_Cameras/IN-8015_HD/Warranty/Weesymbol_180x255.jpg'

const Image = () => {
    return (
      <div style={{ maxWidth: '915px', margin: '2.5rem 0 2.5rem 0', boxShadow: '0 2px 3px 0 rgb(0 0 0 / 36%' }}>
        <EuiImage
          alt='Wee Symbol'
          url={weeSymbolImage}
          float="left"
          margin="m"
        />
      </div>
    )
}

export default Image
