import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Datenschutz">
        <EuiText>
          <p>
            Der IFTTT-Dienst muss entweder in Ihrem INSTAR Cloud-Konto aktiviert werden. Oder durch Aktivieren des IFTTT-Schalters unten. Damit erteilen Sie der INSTAR Cloud die Erlaubnis, Ihre Kamera über das P2P-Protokoll zu steuern. Die erforderlichen Zugangsinformationen werden in Ihrem INSTAR Cloud-Konto gespeichert und nicht an Dritte weitergegeben.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

