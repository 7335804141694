import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="WebUI Einstellungen">
        <EuiText>
          <p>
            Einstellungen für die Weboberfläche Ihrer INSTAR 1080p FullHD IP Kamera
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

