import React from 'react'
import {Link} from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-gtag'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Kann man auch WLAN Kameras per PoE betreiben?">
        <EuiText>
          <p><strong>Ja</strong>! Nicht-PoE Kameras, wie z.B. das Modell <Link to="/Aussenkameras/IN-5905_HD/">IN-5905 HD</Link> oder <Link to="/Innenkameras/IN-6001_HD/">IN-6001 HD</Link> können über einen sog. <strong>PoE Splitter</strong> über PoE betrieben werden.</p>
          <p>Beachten Sie bitte, dass Sie für ein 5V Kameramodell, wie die IN-6001 HD, einen <OutboundLink href="https://www.instar.de/accessories/poe-accessories/poe-splitter-5v.html" target="_blank" rel="noopener noreferrer">5V Splitter</OutboundLink> benötigen, während die IN-5905 HD entsprechend den <OutboundLink href="https://www.instar.de/accessories/poe-accessories/poe-splitter-12v.html" target="_blank" rel="noopener noreferrer">12V Splitter</OutboundLink> benötigt.</p>
          <p>Der Splitter wird zwischen der Kamera und dem PoE Injektor oder Switch angebracht und splittet den Strom und das Netzwerk wieder in 2 separate Anschlüsse auf.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

