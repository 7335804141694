import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBoxInfo() {
  return (
    <EuiCallOut size="m" iconType="pin" title="Wie lassen sich die Bewegungserkennungsbereiche einstellen?">
        <EuiText>
          <h3>Desktop View</h3>
          <ul>
              <li><strong>Knoten verschieben:</strong> Klicken Sie auf einen vorhandenen Knoten, halten Sie die Maustaste gedrückt und bewegen Sie die Maus, um die Position des Knotens zu ändern.</li>
              <li><strong>Hinzufügen von Knoten:</strong> Halten Sie die <strong>SHIFT</strong> Taste gedrückt und klicken Sie auf die Verbindungslinie zwischen zwei Knoten, um  bis zu einer <strong>Gesamtzahl von 32</strong> weiteren Knoten dazwischen einzufügen.</li>
              <li><strong>Entfernen von Knoten:</strong> Halten Sie die <strong>SHIFT</strong> - Taste gedrückt und klicken Sie auf einen vorhandenen Knoten, um ihn zu entfernen.</li>
          </ul>
          <h3>Mobile View</h3>
          <ul>
              <li><strong>Knoten verschieben:</strong> Berühren Sie einen vorhandenen Knoten und verschieben Sie ihn, um seine Position zu ändern. Wenn Sie die <em>+</em> oder <em>-</em> Taste (<em>siehe unten</em>) aktiviert haben, müssen Sie sie erst wieder in den normalen Modus zurückschalten.</li>
              <li><strong>Hinzufügen von Knoten:</strong> Aktivieren Sie die <em>+</em> Schaltfläche unterhalb des Schnappschusses und streichen Sie in den aktiven Bereich über die Verbindungslinie zwischen zwei Knoten, um einen weiteren Knoten dazwischen einzufügen.</li>
              <li><strong>Entfernen von Knoten:</strong> Aktivieren Sie die Schaltfläche <em>-</em> unterhalb des Schnappschusses und berühren Sie einen vorhandenen Knoten und ziehen Sie ihn zum Rand des Canvas, um ihn zu entfernen.</li>
          </ul>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBoxInfo

