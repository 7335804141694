import React from 'react'

import {Link} from 'gatsby'

import {
  EuiImage,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiSpacer
} from '@elastic/eui'

import ivImage01 from "../../../../../images/Software/Windows/InstarVision/Camera_List/Image068a.png"
import ivImage02 from "../../../../../images/Software/Windows/InstarVision/Camera_List/Image068.png"
import ivImage03 from "../../../../../images/Software/Windows/InstarVision/Camera_List/Image069.png"
import ivImage04 from "../../../../../images/Software/Windows/InstarVision/Camera_List/Image071.png"
import ivImage05 from "../../../../../images/Software/Windows/InstarVision/Camera_List/Image070.png"
import ivImage06 from "../../../../../images/Software/Windows/InstarVision/Camera_List/Image075.png"
import ivImage07 from "../../../../../images/Software/Windows/InstarVision/Camera_List/Image074.png"


export default function ImageCard() {

  return (
    <React.Fragment>
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem grow={false}>
          <EuiImage
            hasShadow
            allowFullScreen
            alt="InstarVision v2 for Windows"
            src={ivImage01}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <p>
              <strong>IR LEDs:</strong> Schalten Sie die Nachtsicht LEDs der Kamera ein oder aus.
            </p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />
      
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem grow={false}>
          <EuiImage
            hasShadow
            allowFullScreen
            alt="InstarVision v2 for Windows"
            src={ivImage02}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <p>
              <strong>Maximiertes Darstellungsfeld:</strong> Dieser Button maximiert das Darstellungsfeld der ausgewählten Kamera. Sie können dies auch über einen Doppelklick auf das Video erreichen.
            </p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />
      
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem grow={false}>
          <EuiImage
            hasShadow
            allowFullScreen
            alt="InstarVision v2 for Windows"
            src={ivImage03}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <p>
              <strong>Mikrofon:</strong> Dieser Button aktiviert das Mikrofon an Ihrem PC/Laptop. Nutzen Sie diese Funktion um einen Audiostream zum internen Lautsprecher Ihrer Kamera zu senden - falls vorhanden.
            </p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />
      
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem grow={false}>
          <EuiImage
            hasShadow
            allowFullScreen
            alt="InstarVision v2 for Windows"
            src={ivImage04}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <p>
              <strong>Audio:</strong> Der Audio Button aktiviert oder deaktiviert den Audiostream von Ihrer Kamera. Das Icon ändert sich zu  wenn der Stream deaktiviert wurde.
            </p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />
      
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem grow={false}>
          <EuiImage
            hasShadow
            allowFullScreen
            alt="InstarVision v2 for Windows"
            src={ivImage05}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <p>
              <strong>Snapshot:</strong> Dieser Button speichert einen Schnappschuss des aktuellen Videostreams als JPG Bilddatei. Die Datei wird im aktuellen Aufnahmepfad im Snapshot Ordner gespeichert.
            </p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />
      
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem grow={false}>
          <EuiImage
            hasShadow
            allowFullScreen
            alt="InstarVision v2 for Windows"
            src={ivImage06}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <p>
              <strong>Aufnahme:</strong> Dieser Button startet und stoppt die manuelle Aufnahme des Videostreams. Die Farben Grün, Blau und Rot zeigen den aktuellen Aufnahme Status des Kanals.
            </p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />

      <EuiText>
        <p>
        Wenn eine Aufnahme gestartet wird, beginnt das Icon im Sekunden Takt zu blinken. Die Farbe  signalisiert die manuelle Aufnahme, welche durch ein erneutes Drücken gestoppt werden kann. Das  steht für eine geplante Aufnahme, welche Sie im <Link to="/Software/Windows/InstarVision/Record/Continuous_Recording/">Aufnahme Menü</Link> unter Zeitplan konfigurieren können. Ein  bedeutet, dass die Bewegungserkennung des Kanals aktiv ist und eine Bewegung erkannt hat. Die Alarmaufnahme können Sie im Alarm Menü konfigurieren.
        </p>
      </EuiText>

      <EuiSpacer />
      
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem grow={false}>
          <EuiImage
            hasShadow
            allowFullScreen
            alt="InstarVision v2 for Windows"
            src={ivImage07}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <p>
              <strong>Trennen:</strong> Dieser Button stoppt den Videostream der ausgewählten Kamera - das Link Symbol neben dem Kanalnamen in der Kamera Liste wird ausgeblendet.
            </p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  );
}
