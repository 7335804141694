import React from 'react'
import { EuiImage, EuiText } from '@elastic/eui'

import ivImage from "../../../../images/Software/Windows/InstarVision/Image004.jpg"




export default () => (
  <EuiText>
    <EuiImage
      size="l"
      float="left"
      margin="l"
      hasShadow
      caption="InstarVision v3 for Windows"
      allowFullScreen
      alt="InstarVision v3 for Windows"
      src={ivImage}
    />
    <ul>
      <li><strong>Kamera Liste</strong>: Fügen Sie Kameras und elektronische Karten hinzu und bearbeiten Sie bereits existierende.</li>
      <li><strong>Layout</strong>: Arrangieren Sie Ihre Kameras nach Ihrem Ansprüchen in Layout Vorlagen.</li>
      <li><strong>Kamera PTZ</strong>: Kontrollieren Sie die Pan, Tilt und Zoom Funktion Ihrer Kamera.</li>
      <li><strong>Bildeinstellungen</strong>: Stellen Sie die Bildparameter Ihrer Kamera ein.</li>
      <li><strong>Webserver</strong>: Lassen Sie InstarVision das Video Ihrer Kamera ins Internet streamen und setzten Sie diesen in Ihrer Webseite ein.</li>
      <li><strong>Aufnahme</strong>: Nutzen Sie die Software um geplante und alarmausgelöste Videos aufzunehmen und verwalten Sie Ihre FTP Uploads und Emailbenachrichtigungen.</li>
      <li><strong>System</strong>: Stellen Sie die UI Sprache, den Startmodus und die TCP Ports für den Fernzugriff ein.</li>
      <li><strong>Benutzer</strong>: Die feinstufige Benutzerverwaltung.</li>
      <li><strong>Erweitert</strong>: Überprüfen Sie Ihr Software Log, importieren/exportieren Sie Ihre Systemeinstellungen und starten Sie den InstarVision Video Player.</li>
      <li><strong>Über InstarVision</strong>: Allgemeine Informationen über die Software Installation.</li>
    </ul>
  </EuiText>
);
