import React from 'react'
import { OutboundLink } from 'gatsby-plugin-gtag'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Ergänzung:">
        <EuiText>
          <p>Beachten Sie bitte, das vor der Installation <OutboundLink href="https://nodejs.org/en/download/package-manager/">Node.js 8 oder 9</OutboundLink> auf Ihrem System vorhanden sein muß.</p>
          <p>Sollte Node.js 8/9 fehlen, wird dieses von dem Installationsscript nachinstalliert, jedoch schlägt die Installation von FFMPEG fehl und das Programm wird am Ende nicht starten.</p>
          <p>Sollte dies bei Ihnen passiert sein, können Sie FFMPEG über <OutboundLink href="https://www.npmjs.com/package/ffmpeg">npm nachinstallieren</OutboundLink>. Gehen Sie dafür in Installationsverzeichnis von Shinobi <code>cd /home/Shinobi</code> und starten Sie die installation über <code>npm install ffmpeg</code>. Danach sollte Shinobi über PM2 startfähig sein <code>pm2 restart all</code>, <code>pm2 list</code>.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

