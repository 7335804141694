import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'Administration',
      href: '/de/Bewegungserkennung/INSTAR_Cloud/Administration/',
    },
    {
      text: 'Aufnahmen',
      href: '/de/Bewegungserkennung/INSTAR_Cloud/Alarm_Aufnahmen/',
    },
    {
      text: 'Sets',
      href: '/de/Bewegungserkennung/INSTAR_Cloud/Kamera_Sets/',
    },
    {
      text: 'Account',
      href: '/de/Bewegungserkennung/INSTAR_Cloud/Benutzer_Account_Erstellen/',
    },
    {
      text: 'Speicherplatz',
      href: '/de/Bewegungserkennung/INSTAR_Cloud/Speicherplatz/',
    },
    {
      text: 'Benutzer',
      href: '/de/Bewegungserkennung/INSTAR_Cloud/Benutzer/',
    },
    {
      text: 'Erweiterungen',
      href: '/de/Bewegungserkennung/INSTAR_Cloud/Cloud_Erweiterungen/',
    },
    {
      text: '◄ Cloud',
      href: '/de/Bewegungserkennung/INSTAR_Cloud/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={11}
        aria-label="Cloud Platform for your INSTAR IP Camera"
      />
      <EuiSpacer />
    </Fragment>
  );
}