import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class SDTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Manuelle Aufnahme" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Aufnahmeauflösung', descriptionColumn: 'Wählen Sie den Videostream, den Sie aufzeichnen möchten, wenn Sie die Videoaufzeichnung auslösen. Ihre Kamera bietet 3 Videostreams, die sich in der Auflösung unterscheiden - 1440p, 1080p, 720p. Beachten Sie, dass Sie jeden dieser Streams separat im Menü Multimedia / Video konfigurieren können.' },
        { actionColumn: 'Gesamtdauer der Aufnahme', descriptionColumn: 'Definieren Sie hier die Gesamtdauer und Auflösung für manuelle Aufnahmen, die Sie über die Schnellleiste initiieren können. Beachten Sie, dass die eingestellte Vorlaufzeit in die Gesamtdauer der Aufnahme integriert ist.' },
        { actionColumn: 'Länge der Vorlaufzeit', descriptionColumn: 'Ihre Kamera kann so konfiguriert werden, dass sie kontinuierlich aufzeichnet und das Video verwirft, wenn innerhalb von 10 Sekunden kein Aufnahmebefehl ausgelöst wird. Diese `0s` bis `10s` der Voraufzeichnung kann zu Ihrem manuell aufgezeichneten Video hinzugefügt werden.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

SDTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(SDTable)