import React from 'react'
import { Link } from 'gatsby'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'


import products8015HDSearchIcon from '../../../images/Search/P_SearchThumb_IN-8015HD.webp'
import products9008HDSearchIcon from '../../../images/Search/P_SearchThumb_IN-9008HD.webp'


export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Produkte/Objektive/IN-9008/">
                  <img
                    src={products9008HDSearchIcon}
                    alt="IN-9008 Full HD"
                  />
                </Link>
              }
              title="IN-9008 Full HD"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Produkte/Objektive/IN-9008/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Produkte/Objektive/IN-8015/">
                  <img
                    src={products8015HDSearchIcon}
                    alt="IN-8015 Full HD"
                  />
                </Link>
              }
              title="IN-8015 Full HD"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Produkte/Objektive/IN-8015/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
