import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class ImageTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Bildeinstellungen" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Farbton', descriptionColumn: 'UVerwenden Sie den Farbtonregler, um den Farbton für Ihr Videobild zu ändern. Mit dem Farbton-Parameter können Sie Farbanpassungen vornehmen, wenn der automatische Weißabgleich nicht Ihren Vorstellungen entspricht.' },
        { actionColumn: 'Helligkeit', descriptionColumn: 'Stellen Sie die Helligkeit des Videobilds ein. Um ein helleres Bild zu erhalten, können Sie hier den Wert 75 eintragen.' },
        { actionColumn: 'Bildsättigung', descriptionColumn: 'Stellen Sie die Sättigung des Videobilds ein. Um kräftigere Farben im Video zu erhalten, können Sie hier einen Wert von 125 einstellen. Dieser Wert steht nur im Tag-Modus der Kamera zur Verfügung.' },
        { actionColumn: 'Kontrast', descriptionColumn: 'Stellen Sie den Kontrast des Videobilds ein.' },
        { actionColumn: 'Bildschärfe', descriptionColumn: 'Stellen Sie die Schärfe des Videobilds ein. Dieser Wert verstellt nicht das Objektiv um einen besseren Fokus zu erhalten, sondern zeichnet kontrastreiche Kanten im Video nach, um eine höhere Schärfung des Bildes zu erhalten. Sie können den Wert auf ca. 75 anheben. Beachten Sie dabei aber, dass die Schärfung bei detailreichen Szenen, z.B. Grünanlagen, zu unschönen digitalen Artefakten im Bild führen kann. In diesem Fall sollte man den Wert reduzieren.' },
        { actionColumn: 'Gamma', descriptionColumn: 'Stellen Sie den Gamma-Wert des Bilds ein. Die Gamma Korrektur hebt (oder senkt) die Mitteltöne im Bild, während sehr dunkle und sehr helle Bereiche nicht verändert werden. Das bedeutet, während der Helligkeit Wert unterbelichtete Bilder über das gesamte Spektrum anhebt und das Ergebnis ein sehr _verwaschen_ aussehendes Bild ist, können Sie mit dem Gamma Wert das Bild aufhellen (oder abdunkeln) ohne den Kontrast zu verlieren. Gerade im Nachtmodus kann ein Gamma Wert von 0 das Bild angenehm heller machen.' },
        { actionColumn: 'Belichtung', descriptionColumn: 'Stellen Sie hier die Belichtung (Shutter) für die Videoaufnahme ein. Beachten Sie, dass eine lange Belichtungszeit das Bild stark aufhellt. Dies führt allerdings dazu, das schnelle Bewegungen nicht mehr aufgelöst werden - d.h. Personen, die durchs Bild gehen, werden "verschmiert" dargestellt werden. Es ist besser mit kurzen Belichtungszeiten zu arbeiten und den Helligkeits- und Gamma-Wert zu nutzen um das Video aufzuhellen.' },
        { actionColumn: 'Default', descriptionColumn: 'Dieser Button setzt die Bildeinstellungen auf ihre Standardwerte zurück.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

ImageTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(ImageTable)