import React from 'react'
import Helmet from 'react-helmet'


export default function SeoHelmet(props) {

  return (
    <Helmet defaultTitle={`INSTAR Wiki 2.5`} titleTemplate={`%s | INSTAR Wiki 2.5 | INSTAR Deutschland GmbH`}>
    
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="icon" href="/de/favicon.ico" />
  
      {/* General tags */}
      <title>{props.title}</title>
      <meta name="description" content={props.description} />
      <meta name="image" content={`https://wiki.instar.com/de${props.image}`} />
  
      {/* OpenGraph tags */}
      <meta property="og:url" content={`https://wiki.instar.com${props.location}`} />
      <meta name="og:type" content="website" />
      <meta name="og:site_name" content="INSTAR Wiki 2.5" />
      <meta property="og:title" content={props.title} />
      <meta property="og:description" content={props.description} />
      <meta property="og:image" content={`https://wiki.instar.com/de${props.image}`} />

      {/* OpenGraph Article tas */}
      <meta property="og:article:published_time" content={props.dateChanged} />
      <meta property="og:article:modified_time" content={props.dateChanged} />
      <meta property="og:article:author" content={props.author} />
      <meta property="og:article:section" content="Technology" />
      <meta property="og:article:tag" content={props.tag} />
  
      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@INSTAR_DE" />
      <meta name="twitter:creator" content="@INSTAR_DE" />
      <meta name="twitter:title" content={props.title} />
      <meta name="twitter:description" content={props.description} />
      <meta name="twitter:image" content={`https://wiki.instar.com/de${props.twitter}`} />
  
      <link
        rel="canonical"
        href={`https://wiki.instar.com${props.location}`}
      />
      <html lang="de" />
    </Helmet>
  );
}

SeoHelmet.defaultProps = {
  dateChanged: "2021-07-22",
  tag: "INSTAR IP Kamera",
  author: "Mike Polinowski",
  image: "/images/Search/QI_SearchThumb_SetupWizard.png",
  twitter: "/images/Search/QI_SearchThumb_SetupWizard.webp",
  title: "INSTAR Wiki :: Wissensdatenbank für IP Kameras und Hausautomatisierung",
  description: 'Fragen zu Ihren Kameras, der Netzwerktechnik oder der Smart-System-Integration? Hier bieten wir fachkundige Anleitungen rund um IP Kameras und smarte Integrationsmöglichkeiten an.',
}