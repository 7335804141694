import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Einbindung in die INSTAR Cloud">
        <EuiText>
          <p>
          Einfacher kann die Einbindung in die Cloud gar nicht sein. Sie müssen sich lediglich auf <a href="https://cloud.instar.de">cloud.instar.de</a> registrieren und schon kann es los gehen. Testen Sie die Cloud jetzt 30 Tage lang gratis. Wenn Ihnen unser Cloud Service gefällt, dann können Sie sich anschliessend Speicherplatz für eine jährliche Gebühr dazubuchen.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

