import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Innenkameras/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_Indoor_Cameras.webp'
                    alt="Innenkameras"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Innenkameras"
              description="INSTAR bietet eine Reihe von IP-Kameras für jede Überwachungsaufgabe im Innenbereich. Brauchen Sie etwas Kompaktes und Unauffälliges? Oder ein Pan & Tilt-Modell?"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Innenkameras/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Aussenkameras/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_Outdoor_Cameras.webp'
                    alt="Aussenkameras"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Aussenkameras"
              description="INSTAR bietet eine Reihe von IP-Kameras für jede Außenüberwachungsaufgabe. Brauchen Sie etwas Kompaktes und Unauffälliges? Oder ein Pan&Tilt-Modell?"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Aussenkameras/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Produkte/Gebrauchsanleitungen/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_Products.webp'
                    alt="Gebrauchsanleitungen"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Gebrauchsanleitungen"
              description="Für alle unsere HD-IP-Kameramodelle erstellen wir ein - stets aktuelles - Online-Benutzerhandbuch. Hier können Sie Ihre Reise beginnen!"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Produkte/Gebrauchsanleitungen/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_Products.webp'
                    alt="Kamera Software"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Kamera Software"
              description="Nutzen Sie unsere kostenlosen mobilen Apps für Android, iOS, Windows Phone oder Blackberry. Oder nutzen Sie ein Drittsystem von Homematic, Loxone, AVM, Synology, QNAP und Co."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_WebUI_1080p.webp'
                    alt="Web User Interface"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Web User Interface"
              description="Mit der Web-Benutzeroberfläche können Sie Ihre INSTAR IP-Kamera über Ihren bevorzugten Webbrowser konfigurieren und steuern."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/">
                  <StaticImage
                    src='../../images/Search/D_Downloads_SearchThumb.webp'
                    alt="Downloads"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Downloadbereich"
              description="Sie können die neueste Version der Firmware und der Web-Bedienoberfläche Ihrer Kamera über die webUI herunterladen. Für ältere Versionen und Bedienungsanleitungen kommen Sie hierher."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Produkte/Objektive/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_Products.webp'
                    alt="Kamera Objektive"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Kamera Objektive"
              description="INSTAR bietet eine Reihe von Kameraobjektiven für jede Außenüberwachungsaufgabe. Brauchen Sie ein Weitwinkel? Oder vielleicht ein Teleobjektiv?"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Produkte/Objektive/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Produkte/USB-Webcams/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_IN-W1.webp'
                    alt="IN-Wx USB Webcams"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-Wx USB Webcam"
              description="Die IN-Wx ist eine USB-Webcam mit einer 1080p Full HD-Auflösung. Sie kann an Ihren PC oder Laptop angeschlossen werden und mit Chat- und Team-Programmen wie Skype, Zoom, Facetime und anderen verwendet werden."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Produkte/USB-Webcams/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Produkte/Andere/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_Products.webp'
                    alt="Andere Produkte"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Andere Produkte"
              description="IP-Kamera-Zubehör und Netzwerksicherheitsprodukte von INSTAR."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Produkte/Andere/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
