import React from 'react'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="HVYMYqL9hSI"
                    title="IN-5905 HD SD Karte"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              title="IN-5905 HD SD Karte"
              footer={
                <EuiText>
                  <p>Wir zeigen Ihnen in diesem Video wie man die MicroSD Speicherkarte auf max. 32GB aufrüsten kann. Benötigtes Werkzeug: 1x Kreuzschraubendreher:</p>
                  <ul>
                    <li><strong>Schritt 1)</strong> Entfernen Sie die obere Abdeckung</li>
                    <li><strong>Schritt 2)</strong> Lösen Sie die Schraube an der Halterung um diese abzuwinkeln</li>
                    <li><strong>Schritt 3)</strong> Öffnen Sie die Kamera indem Sie die 3 Schrauben an der hinteren Abdeckung lösen</li>
                    <li><strong>Schritt 4)</strong> Ziehen Sie die innere Einheit ein klein wenig heraus damit Sie an den Speicherkarten-Einschub gelangen</li>
                    <li><strong>Schritt 5)</strong> Wechseln Sie die MicroSD Speicherkarte</li>
                    <li><strong>Schritt 6)</strong> Verschließen Sie das Gehäuse wieder und bringen Sie die obere Abdeckung wieder an der Kamera an</li>
                  </ul>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}
