import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'


export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Erweitert/CGI_Befehle/">
                  <StaticImage
                    src='../../images/Search/AU_SearchThumb_CGICommands.webp'
                    alt="CGI Befehle"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="CGI Befehle"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Erweitert/CGI_Befehle/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Erweitert/INSTAR_MQTT_Broker/">
                  <StaticImage
                    src='../../images/Search/AU_SearchThumb_MQTT.webp'
                    alt="INSTAR MQTT"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="INSTAR MQTT"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Erweitert/INSTAR_MQTT_Broker/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Erweitert/VPN_AVM_Fritzbox/">
                  <StaticImage
                    src='../../images/Search/AU_SearchThumb_VPN.webp'
                    alt="VPN Tunnel"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="VPN Tunnel"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Erweitert/VPN_AVM_Fritzbox/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Erweitert/Website_Integration/">
                  <StaticImage
                    src='../../images/Search/AU_SearchThumb_WebsiteIntegration.webp'
                    alt="Website Integration"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Website Integration"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Erweitert/Website_Integration/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Erweitert/Youtube_Videostreaming_from_your_Camera/">
                  <StaticImage
                    src='../../images/Search/AU_SearchThumb_Youtube.webp'
                    alt="Youtube Videostreaming"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Youtube Videostreaming"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Erweitert/Youtube_Videostreaming_from_your_Camera/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Erweitert/Alle_Smarthome_Tutorials/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_Smarthome.webp'
                    alt="Youtube Videostreaming"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Smarthome"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Erweitert/Alle_Smarthome_Tutorials/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Erweitert/Tasker/">
                  <StaticImage
                    src='../../images/Search/AU_SearchThumb_Tasker.webp'
                    alt="Youtube Videostreaming"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Tasker Android App"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Erweitert/Tasker/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Erweitert/WebUI_Wiederherstellen/">
                  <StaticImage
                    src='../../images/Search/AU_SearchThumb_ResetAfterWebUIError.webp'
                    alt="WebUI Wiederherstellen"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Restore WebUI"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Erweitert/WebUI_Wiederherstellen/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Erweitert/Firmware_Wiederherstellen/">
                  <StaticImage
                    src='../../images/Search/AU_SearchThumb_ResetAfterFirmwareError.webp'
                    alt="Youtube Videostreaming"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Restore Firmware"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Erweitert/Firmware_Wiederherstellen/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Erweitert/CCTV_vs_IP/">
                  <StaticImage
                    src='../../images/Search/AU_SearchThumb_IPvsCCTV.webp'
                    alt="CCTV vs IP"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IP vs CCTV"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Erweitert/CCTV_vs_IP/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Erweitert/Kerberos_mit_Docker/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_Lupus_XT2.webp'
                    alt="Kerberos.io Videoüberwachung"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Kerberos.io Videoüberwachung"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Erweitert/Kerberos_mit_Docker/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}