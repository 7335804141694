import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'Android',
      href: '/de/Software/Android/'
    },
    {
      text: 'Smarthome',
      href: '/de/Software/Smarthome/',
    },
    {
      text: 'iOS',
      href: '/de/Software/iOS/',
    },
    {
      text: 'Linux',
      href: '/de/Software/Linux/',
    },
    {
      text: 'macOS',
      href: '/de/Software/macOS/',
    },
    {
      text: 'Andere Plattform',
      href: '/de/Software/Andere_Plattform/',
    },
    {
      text: 'Windows',
      href: '/de/Software/Windows/',
    },
    {
      text: '◄ Software',
      href: '/de/Software/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={11}
        aria-label="Software Platform for your INSTAR IP Camera"
      />
      <EuiSpacer />
    </Fragment>
  );
}