import React from 'react'
import { OutboundLink } from 'gatsby-plugin-gtag'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiIcon
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="PDc6_BsczRY"
                    title="INSTAR Cloud Speicherplatz"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              footer={
                <EuiText>
                <p>In diesem Video erklären wir wie Sie neuen Speicherplatz in Ihre Cloud Account hinzufügen können bzw. wie Sie diesen erweitern können.</p>
                <p>Der Preis für die INSTAR Cloud, ist im Gegensatz zu vielen anderen Anbietern, nicht an die Anzahl der Kameras gebunden sondern Sie können soviele Kameras wie Sie möchten hinzufügen und zahlen nur für die Größe des Speicherplatzes den Sie verwenden möchten. Auf <OutboundLink href="https://www.youtube.com/watch?v=spHKmZRmn4E" target="_blank" rel="noopener noreferrer">Youtube ansehen <EuiIcon type="popout" /></OutboundLink></p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}