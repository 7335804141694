import React from 'react'

import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Kommunikation mit Ihrer Kamera">
        <EuiText>
          <p>Die folgenden Beispiele verwenden <strong><Link to="/Erweitert/CGI_Befehle/">CGI-Befehle</Link></strong>, um mit Ihrem Smarthome Kamera-Funktionen zu automatisieren. Um diese Befehle zu verwenden, müssen Sie die <Link to="/Web_User_Interface/1440p_Serie/Netzwerk/IP_Konfiguration/">IP-Adresse, den HTTP Port</Link> und das <Link to="/Web_User_Interface/1440p_Serie/System/Benutzer/">Admin Login</Link> hinzufügen - z.B. wenn die IP-Adresse Ihrer Kamera <strong>192.168.178.121</strong>, der LAN-Port der Kamera <strong>8081</strong> und das Admin-Login <strong>admin/instar</strong> ist und Sie möchten den Befehl senden, der einen Alarm auf Ihrer Kamera auslöst <strong>param.cgi?cmd=pushhostalarm</strong>, muss dies so aussehen:</p>
          <ul>
            <li><strong>WQHD 2k+ Serie:</strong></li>
            <ul>
              <li><em>http://192.168.178.121:8081/param.cgi?cmd=pushhostalarm&-user=admin&-pwd=instar</em></li>
            </ul>
            <li><strong>HD & Full HD Serie:</strong></li>
            <ul>
              <li><em>http://192.168.178.121:8081/param.cgi?cmd=pushhostalarm&-usr=admin&-pwd=instar</em></li>
            </ul>
          </ul>
          <p>Bevor Sie diesen Befehl in Ihre Smarthome-Konfiguration eingeben, können Sie ihn zunächst kopieren in die Adressleiste Ihres Webbrowsers einfügen und die Eingabetaste drücken. Wenn Sie ein <strong>OK</strong> von Ihrer Kamera erhalten und den gewünschten Effekt sehen - im obigen Fall sollten Sie einen Alarm im <Link to="/Web_User_Interface/1440p_Serie/System/Log/">Kamera System Log</Link> sehen und alle aktiven <Link to="/Web_User_Interface/1440p_Serie/Alarm/Aktionen/">Alarmaktionen</Link> sollten ausgelöst werden - können Sie mit der Konfiguration Ihres Smarthome fortfahren.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox