import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'IN-5905 HD',
      href: '/de/Downloadbereich/Aussenkameras/IN-5905_HD/',
    },
    {
      text: 'IN-5907 HD',
      href: '/de/Downloadbereich/Aussenkameras/IN-5907_HD/',
    },
    {
      text: 'IN-7011 HD',
      href: '/de/Downloadbereich/Aussenkameras/IN-7011_HD/',
    },
    {
      text: 'IN-9008 FHD',
      href: '/de/Downloadbereich/Aussenkameras/IN-9008_HD/',
    },
    {
      text: 'IN-9010 FHD',
      href: '/de/Downloadbereich/Aussenkameras/IN-9010_HD/',
    },
    {
      text: 'IN-9020 FHD',
      href: '/de/Downloadbereich/Aussenkameras/IN-9020_HD/',
    },
    {
      text: 'Aussenkameras',
      href: '/de/Downloadbereich/Aussenkameras/',
    },
    {
      text: '◄ Downloadbereich',
      href: '/de/Downloadbereich/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={11}
        aria-label="Downloadbereich for your INSTAR IP Camera"
      />
      <EuiSpacer />
    </Fragment>
  );
}