import React from 'react'
import { Link } from 'gatsby'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

import taskerMobileIcon from '../../../images/Advanced_User/Tasker/Tasker_Mobile.webp'
import taskerSceneIcon from '../../../images/Advanced_User/Tasker/Tasker_Scene.webp'
import taskerShortcutIcon from '../../../images/Advanced_User/Tasker/Tasker_Shortcut.webp'
import taskerTimerIcon from '../../../images/Advanced_User/Tasker/Tasker_Timer.webp'
import taskerWiFiIcon from '../../../images/Advanced_User/Tasker/Tasker_WiFi.webp'

export default function LatestArticle() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={taskerMobileIcon}
                    alt="Tasker: Activate Alarm"
                  />
              }
              title="Automatisierung Task"
              description="Deaktivieren Sie die Bewegungserkennung Ihrer Kamera, wenn sich Ihr Smartphone in das Mobilfunk Netzwerk einwählt."
              footer={
                <EuiFlexGroup justifyContent="spaceBetween">
                  <Link to="/Erweitert/Tasker/Bewegungserkennung_Deaktivieren_Aufgabe_Hinzufuegen/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Task
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                  <Link to="/Erweitert/Tasker/Bewegungserkennung_Deaktivieren_Profil_Hinzufuegen/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Profile
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={taskerSceneIcon}
                    alt="Tasker: Activate Alarm"
                  />
              }
              title="Automatisierung Task"
              description="Erzeugen Sie eine erweiterbare Tasker Scene zum Schalten der Nachtsicht."
              footer={
                <EuiFlexGroup justifyContent="spaceBetween">
                  <Link to="/Erweitert/Tasker/Nachtsicht_Aktivieren_Aufgabe/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Task I
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                  <Link to="/Erweitert/Tasker/Nachtsicht_Deaktivieren_Aufgabe/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Task II
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                  <Link to="/Erweitert/Tasker/Nachtsicht_Szene/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Scene
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={taskerShortcutIcon}
                    alt="Tasker: Activate Alarm"
                  />
              }
              title="Automatisierung Task"
              description="Erzeugen Sie einen Tasker Shortcut um die IR LEDs Ihrer Kamera ein- und auszuschalten."
              footer={
                <EuiFlexGroup justifyContent="spaceBetween">
                  <Link to="/Erweitert/Tasker/IR_Aktivieren_Shortcut_Hinzufuegen/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Shortcut I
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                  <Link to="/Erweitert/Tasker/IR_Deaktivieren_Shortcut_Hinzufuegen/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Shortcut II
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={taskerTimerIcon}
                    alt="Tasker: Activate Alarm"
                  />
              }
              title="Automatisierung Task"
              description="Drehen Sie Ihre Kamera auf eine andere Position und passen Sie die Empfindlichkeit der Bewegungserkennung an."
              footer={
                <EuiFlexGroup justifyContent="spaceBetween">
                  <Link to="/Erweitert/Tasker/Zeitplan_PTZ_Aufgabe/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Task I
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                  <Link to="/Erweitert/Tasker/Zeitplan_Alarm_Aufgabe/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Task II
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                  <Link to="/Erweitert/Tasker/Zeitplan_Profil/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Scene
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <img
                  src={taskerWiFiIcon}
                  alt="Tasker: Activate Alarm"
                />
              }
              title="Automatisierung Task"
              description="Aktivieren Sie die Bewegungserkennung Ihrer Kamera, wenn sich Ihr Smartphone mit Ihrem WLAN Netz verbindet."
              footer={
                <EuiFlexGroup justifyContent="spaceBetween">
                  <Link to="/Erweitert/Tasker/Bewegungserkennung_Aufgabe_Hinzufuegen/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Task
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                  <Link to="/Erweitert/Tasker/Bewegungserkennung_Profil_Hinzufuegen/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Profile
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}