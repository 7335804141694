import React from 'react'
import { OutboundLink } from 'gatsby-plugin-gtag'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiIcon
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="JWa1L_rBRW4"
                    title="Funktionsweise einer INSTAR IP-Kamera"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              title="Funktionsweise einer INSTAR IP-Kamera"
              footer={
                <EuiText>
                <p>Animiertes Tutorial zur Funktionsweise einer INSTAR IP-Kamera. Was ist eine IP-Adresse? Wohin führt ein Port? Was ist eine DDNS-Adresse? Auf <OutboundLink href="https://www.youtube.com/watch?v=JWa1L_rBRW4" target="_blank" rel="noopener noreferrer">Youtube ansehen <EuiIcon type="popout" /></OutboundLink></p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}