import React from 'react'
import { OutboundLink } from 'gatsby-plugin-gtag'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="* Eclipse Mosquitto">
        <EuiText>
          <p>
          Das INSTAR MQTT Interface basiert auf dem <OutboundLink href="https://mosquitto.org" target="_blank">Eclipse Mosquitto</OutboundLink> Open Source MQTT Broker. Der Quellcode kann <OutboundLink href="https://github.com/eclipse/mosquitto" target="_blank">von Github heruntergeladen</OutboundLink> werden und ist unter <OutboundLink target="_blank" href="http://www.eclipse.org/legal/epl-v20.html">Eclipse Public License 2.0</OutboundLink> verfügbar.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

