import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class MQTTTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "MQTT Settings" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'MQTT aktivieren', descriptionColumn: 'Der MQTT-Dienst ist standardmäßig deaktiviert. Verwenden Sie diesen Schalter, um den Broker zu starten.' },
        { actionColumn: 'Externen MQTT Broker verwenden', descriptionColumn: 'Standardmäßig wird der interne `127.0.0.1` Broker verwendet. Wenn Sie Ihre Kamera an einen anderen Broker anbinden möchten, aktivieren Sie hier die Option und geben Sie die Adresse unten ein.' },
        { actionColumn: 'MQTT Server Adresse', descriptionColumn: 'Standardmäßig wird der interne `127.0.0.1` Broker verwendet. Wenn Sie Ihre Kamera an einen anderen Broker anbinden möchten, aktivieren Sie oben die Option und geben Sie hier die Adresse ein.' },
        { actionColumn: 'MQTT Server Port', descriptionColumn: 'Dies ist der Port, über den der MQTT-Broker eine Verbindung zu Clients ohne TLS-Verschlüsselung herstellt. Der Standardwert ist `1883`.' },
        { actionColumn: 'MQTT Server Port (SSL)', descriptionColumn: 'Dies ist der Port, den der MQTT-Broker verwendet, um eine Verbindung zu Clients mit TLS-Verschlüsselung herzustellen. Der Standardwert ist `8883`.' },
        { actionColumn: 'Authentifizierung', descriptionColumn: 'Die Authentifizierung muss aktiviert sein, wenn Sie die Kamera als Broker verwenden. Wenn Sie bereits einen anderen Broker in ihrem Netzwerk haben und die Kamera als Klient einbinden, kann sie, wenn gewünscht, deaktiviert werden.' },
        { actionColumn: 'Benutzername', descriptionColumn: 'Geben Sie den Benutzernamen ein, den Sie für den Zugriff auf den MQTT Broker verwenden möchten.' },
        { actionColumn: 'Kennwort', descriptionColumn: 'Fügen Sie hier das entsprechende Passwort hinzu.' },
        { actionColumn: 'Zertifikat herunterladen', descriptionColumn: 'Wenn Sie die TLS-Verschlüsselung für Ihr MQTT-Netzwerk verwenden, muss jeder Client über den richtigen Schlüssel verfügen, damit er sich mit Ihrem Broker verbinden kann. Dieser Schlüssel kann hier heruntergeladen werden.' },
        { actionColumn: 'Serverzertifikat hochladen', descriptionColumn: 'Der MQTT Broker verwendet für die TLS-Verschlüsselung ein offizielles CA-Zertifikat - das gleiche, das auch für die Verbindung mit Ihrer Kamera über HTTPS verwendet wird. Wenn Sie ein benutzerdefiniertes Zertifikat für Ihr MQTT-Netzwerk benötigen, können Sie es hier hochladen.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

MQTTTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(MQTTTable)