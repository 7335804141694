import { EuiSpacer } from '@elastic/eui';
import YoutubeVideoCard from 'components/Advanced_User/VPN_AVM_Fritzbox/YoutubeVideoCard';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  YoutubeVideoCard,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};