import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/styles'
import {
  SortingState, SelectionState, FilteringState, GroupingState,
  IntegratedFiltering, IntegratedGrouping, IntegratedSelection,
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table, TableHeaderRow, TableFilterRow, TableGroupRow,
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class TechSpecGrid extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
        columns: [
          { name: "category", title: "Kategorie" },
          { name: "feature", title: "Feature" },
          { name: "property", title: "Beschreibung" }
        ],
        rows: [
          { category: 'Optik & Bild', feature: 'Standard Objektiv', property: '4.3 ±5% mm (man. Fokus) f/1.6 IR /M12 - S-Mount [2]' },
          { category: 'Optik & Bild', feature: 'Blickwinkel', property: 'ca. 90° (diag.)' },
          { category: 'Optik & Bild', feature: 'CMOS Sensor', property: 'Sony STARVIS IMX335 CMOS Sensor [1]' }, 
          { category: 'Optik & Bild', feature: 'Sensor Format', property: '1/2.8"' },
          { category: 'Optik & Bild', feature: 'Abmessungen', property: '38 x 38 (mm) PCB, 26.5 x 26.5 (mm)' },
          { category: 'Optik & Bild', feature: 'Pixel Größe', property: '2.0 µm (H) × 2.0 µm (V)' },
          { category: 'Optik & Bild', feature: 'Dynamischer Bereich', property: '72dB' },
          { category: 'Optik & Bild', feature: 'Empfindlichkeit', property: '>=2000mV' },
          { category: 'Optik & Bild', feature: 'Max. Auflösung', property: '5.0 Megapixel' },
          { category: 'Optik & Bild', feature: 'Gesamtpixelanzahl', property: '2704 (H) × 2104 (V) ca. 5.69 M Pixel' },
          { category: 'Optik & Bild', feature: 'Anzahl an effektiven Pixeln', property: '2616 (H) × 1964 (V) ca. 5.14 M Pixel' },
          { category: 'Optik & Bild', feature: 'Anzahl an aktiven Pixeln', property: '2616 (H) × 1960 (V) ca. 5.13 M Pixel' },
          { category: 'Optik & Bild', feature: 'Anzahl empfohlener Pixeln', property: '2592 (H) × 1944 (V) ca. 5.04 M Pixel' },
          { category: 'Optik & Bild', feature: 'Unterstützte Auflösungen', property: '1440p (2560x1440 Pixel ~ 3.7MP), 1080p (1920x1080 Pixel ~ 2.1MP), 720p (1280x720 Pixel ~ 1MP)' },
          { category: 'Optik & Bild', feature: 'Bit Tiefe', property: '10 / 12 bit' },
          { category: 'Optik & Bild', feature: 'High dynamic range (HDR)', property: 'Mehrfachbelichtung HDR / Digitale Überlagerung HDR' },
          { category: 'Optik & Bild', feature: 'Videokomprimierung', property: 'h.265, h.264, MJPEG, JPG' },
          { category: 'Optik & Bild', feature: 'Bitrate (h.265)', property: 'up to 5.120 kbps' },
          { category: 'Optik & Bild', feature: 'Framerate', property: '5.0MP 2592 x 1944 about 14fps /  4.0MP 2592 x 1520 about 19fps / 1080P @30fps' },
          { category: 'Optik & Bild', feature: 'Parallele Streams', property: '3' },
          { category: 'Optik & Bild', feature: 'Video Overlay', property: 'Datum, Zeit, Kameraname' },
          { category: 'Optik & Bild', feature: 'Video Parameter', property: 'Helligkeit, Kontrast, Sättigung, Schärfe, Gamma, Belichtung' },
          { category: 'Optik & Bild', feature: 'Tag / Nacht Umschaltung', property: 'Ja' },
          { category: 'Optik & Bild', feature: 'Weißabgleich', property: 'Automatisch' },
          { category: 'Optik & Bild', feature: 'Elektronischer Shutter', property: 'Automatisch' },
          { category: 'Optik & Bild', feature: 'Flip / Spiegel des Videos', property: 'Ja / Ja' },
          { category: 'Nachtsicht', feature: 'Infrarot LEDs', property: '4 LEDs @ 850 nm' },
          { category: 'Nachtsicht', feature: 'Infrarot LEDs "unsichtbar"', property: '6 LEDs @ 940 nm' },
          { category: 'Nachtsicht', feature: 'IR Modi', property: 'an/aus/automatisch  940nm/850nm/beide' },
          { category: 'Nachtsicht', feature: 'max. Reichweite', property: '5-10 m' },
          { category: 'Nachtsicht', feature: 'intgr. IRcut Filter', property: 'Ja (Automatisch)' },
          { category: 'Netzwerk', feature: 'Netzwerk Schnittstellen', property: 'GMAC+ 10Base-T/100Base-TX PHY Ethernet Port / WLAN IEEE 802.11b/g/n (nicht im PoE Model)' },
          { category: 'Netzwerk', feature: 'Netzwerk Protokolle', property: 'TCP/IP, DHCP, PPPoE, ARP, FTP, SMTP, DNS, NTP, UPnP, HTTP, TCP, UDP, MQTTv.5' },
          { category: 'Netzwerk', feature: 'Übertragungsverschlüsselung', property: 'TLSv1.3 - HTTPS. MQTTSv.5, FTPS, sFTP' },
          { category: 'Netzwerk', feature: 'WiFi Verschlüsselung (nicht im PoE Model)', property: 'WPA2/3-PSK (TKIP, AES, CCMP)' },
          { category: 'Netzwerk', feature: 'WiFi Frequenz (nicht im PoE Model)', property: '5GHz / 2.4GHz, Wi‑Fi 5 (802.11ac)' },
          { category: 'Netzwerk', feature: 'WiFi Frequenz Bänder (nicht im PoE Model)', property: '2,4-2.4845GHz, 5,150-5,350/5,470 - 5,725GHz' },
          { category: 'Netzwerk', feature: 'WiFi Frequenz Übertragung', property: '<16dBm @ 11b/g, <15dBm @ 11n < 18dBm @11ac' },
          { category: 'Netzwerk', feature: 'WiFi Antenne (nicht im PoE Model)', property: '3 dBi WLAN internal Antenna' },
          { category: 'Netzwerk', feature: 'WiFi Reichweite (nicht im PoE Model)', property: 'ca. 8-12m' },
          { category: 'Netzwerk', feature: 'kostenlose DDNS Addresse', property: 'Ja' },
          { category: 'Netzwerk', feature: 'kostenlose P2P UID', property: 'Ja' },
          { category: 'Chipsatz', feature: 'AI IPCAM SoC', property: 'Novatek NT98528' },
          { category: 'Chipsatz', feature: 'CPU', property: 'Dual Cortex A9' },
          { category: 'Chipsatz', feature: 'Memory', property: '16-bit DDR3' },
          { category: 'Chipsatz', feature: 'Codec', property: '8Mp20+sub stream' },
          { category: 'Chipsatz', feature: 'Fast Boot', property: 'Linux application < 1.2/1.5s' },
          { category: 'Chipsatz', feature: 'Platform AI', property: 'HW-CNN 1.5T' },
          { category: 'Chipsatz', feature: 'ISP', property: 'TMNR2 3DNR, SHDR, Defog, RGBIR' },
          { category: 'Chipsatz', feature: 'Serial Bus', property: 'USB2.0' },
          { category: 'Features', feature: 'Zugriffschutz', property: 'Benutzername/Kennwort - 3 Stufen Authentifizierung' },
          { category: 'Features', feature: 'Bewegungserkennung', property: 'Ja (software Analysis + PIR)' },
          { category: 'Features', feature: 'Alarm I/O', property: 'Nein' },
          { category: 'Features', feature: 'Alarm Benachrichtigung', property: 'Email/FTP/HTTP/MQTTv.5 (Alarmserver)/Push' },
          { category: 'Features', feature: 'Alarm Pre-Recording', property: 'Ja (3s)' },
          { category: 'Features', feature: 'Fotoserie', property: 'Ja (auf SD Card und FTP server)' },
          { category: 'Features', feature: 'SD Karte', property: 'Herausnehmbare microSDHC-Karte mit bis zu 256 GB (32 GB im Lieferumfang enthalten) [3]' },
          { category: 'Features', feature: 'Cloud Speicher', property: 'Ja (optional)' },
          { category: 'Features', feature: 'Pan & Tilt', property: 'Nein' },
          { category: 'Features', feature: 'Mikrofon', property: 'Integriertes Mikrofon' },
          { category: 'Features', feature: 'Lautsprecher', property: 'Integriertes Lautsprecher' },
          { category: 'Features', feature: 'Intercom', property: 'Zwei-Wege-Audiokommunikation mit unserer App InstarVision' },
          { category: 'Features', feature: 'Geplante & Alarmaufnahme', property: 'Ja' },
          { category: 'Peripherie', feature: 'Gehäuse', property: 'ABS (Weiß)' },
          { category: 'Peripherie', feature: 'Halterug', property: 'Wand & Decken Halterug' },
          { category: 'Peripherie', feature: 'Betriebstemperatur', property: '-5°C to 55°C' },
          { category: 'Peripherie', feature: 'Stromversorgung', property: '110V - 230V' },
          { category: 'Peripherie', feature: 'Netzwerkkable', property: '3m Cat5e' },
          { category: 'Peripherie', feature: 'Netzteil', property: '3m / 110-240V ac @ 50-60Hz to 5V dc and 1500mA' },
          { category: 'Peripherie', feature: 'Stromstecker', property: '1.35mm Innen / 3.5mm Außen' },
          { category: 'Peripherie', feature: 'Stromversorgung', property: 'Max. 5.0 Watt (im Normalbetrieb ~4 Watt)' },
          { category: 'Peripherie', feature: 'Zertifizierung', property: 'CE, FCC, RoHS' },
        ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns} >

          <FilteringState />

          <SortingState
            defaultSorting={[
              { columnName: 'category', direction: 'asc' },
            ]}
          />
          <GroupingState
            defaultGrouping={[{ columnName: 'category' }]}
            defaultExpandedGroups={['Optik & Bild']}
          />
          <SelectionState />

          <IntegratedFiltering />
          <IntegratedGrouping />
          <IntegratedSelection />
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
          <TableFilterRow />
          <TableGroupRow />
        </Grid>
      </Paper>
    );
  }
}

TechSpecGrid.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(TechSpecGrid)





















































  












































































































  































































































































