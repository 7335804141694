import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="INSTAR MQTT Broker">
        <EuiText>
          <p>
            Alle Kamerafunktionen Ihrer INSTAR Full HD-Kamera können über die <Link to="/1080p_Serie_CGI_Befehle/">HTTP/S CGI Schnittstelle</Link> gesteuert werden. Dies ist die Schnittstelle, die von der Web-Benutzeroberfläche und allen INSTAR Smartphone-Anwendungen verwendet wird. Im Smarthome und Internet of Things (IoT) Bereich gibt es ein weiteres Protokoll, das weit verbreitet ist und einige Vorteile gegenüber dem klassischen HTTP bietet - das Protokoll heißt MQTT. Das Hinzufügen einer MQTT-Schnittstelle * zu unseren Full HD-Kameras macht das Hinzufügen dieser Kameras zu einem bestehenden Smarthome - fast - zu einfach :)
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

