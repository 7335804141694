import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Linux/MotionEye/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Linux.webp'
                    alt="MotionEye on Debian"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="MotionEye unter Debian"
              description="Motion ist ein hochgradig konfigurierbares Programm, das Videosignale von vielen Kameratypen überwacht. Richten Sie es ein, um Ihre INSTAR IP-Kamera zu überwachen, Zeitraffervideos zu erstellen und mehr."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Linux/MotionEye/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Linux/Shinobi_Open_Source_CCTV/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_OpenHAB.webp'
                    alt="Shinobi CCTV"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Shinobi CCTV"
              description="Shinobi ist Open Source, in Node.js geschrieben und wirklich einfach zu bedienen. Es ist die Zukunft von CCTV und NVR für Entwickler und Endanwender gleichermaßen."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Linux/Shinobi_Open_Source_CCTV/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Linux/Node-RED/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_Node-RED.webp'
                    alt="Node-RED & MQTT on Debian"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Node-RED & MQTT unter Debian"
              description="Node-RED ist ein Programmierwerkzeug, um Hardwaregeräte, APIs und Online-Dienste auf neue und interessante Weise miteinander zu verdrahten..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Linux/Node-RED/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Linux/Home_Assistant/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_HomeAssistant.webp'
                    alt="Home Assistant on Debian"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Home Assistant unter Debian"
              description="Open-Source-Heimautomatisierung, die lokale Kontrolle und Privatsphäre in den Vordergrund stellt. Angetrieben von einer weltweiten Community von Tüftlern und Heimwerkern. Perfekt für den Betrieb auf einem Raspberry Pi oder einem lokalen Server."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Linux/Home_Assistant/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Linux/OpenHAB2/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_OpenHAB.webp'
                    alt="OpenHAB 2 on Debian"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="OpenHAB 2 on Debian"
              description="Empowering the smart home - eine hersteller- und technologieunabhängige Open-Source-Automatisierungssoftware für Ihr Zuhause..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Linux/OpenHAB2/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Linux/Frigate_NVR/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Linux.webp'
                    alt="Frigate NVR in Docker"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
             }
              title="Frigate NVR in Docker"
              description="Ein vollständiger und lokaler NVR für Home Assistant mit AI-Objekterkennung. Verwendet OpenCV und Tensorflow zur lokalen Objekterkennung in Echtzeit für IP-Kameras."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Linux/Frigate_NVR/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
