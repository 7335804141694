import React from 'react'

import {
  EuiTitle,
  EuiSpacer,
  EuiFlexGroup,
  EuiHorizontalRule
} from '@elastic/eui'

import IntroCards from './networkCGIToC'

export default function IntroHome() {

  return (
    <React.Fragment>
      <EuiSpacer />
        <EuiTitle size="m">
            <h1>1080p Serie CGI Liste :: Network Menu</h1>
        </EuiTitle>
        <EuiHorizontalRule margin="xs" />
      <EuiFlexGroup gutterSize="l" direction="column" style={{margin: '0 7px'}} >
          <IntroCards />
    </EuiFlexGroup>
  </React.Fragment>
  );
}
