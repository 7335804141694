import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Windows/InstarVision/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_InstarVision_Windows.webp'
                    alt="InstarVision 3.0"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="InstarVision 3.0"
              description="Die Windows App InstarVision für Ihren PC oder Laptop. Steuern Sie Ihre Kamera von Ihrem Computer aus und nutzen Sie die Rechenleistung für erweiterte..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/InstarVision/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Windows/InstarVision/Windows_Phone/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_InstarVision_WP.webp'
                    alt="InstarVision für Windows Phone"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="InstarVision für Windows Phone"
              description="Die Windows App InstarVision für Ihr Windows Phone. Steuern Sie Ihre Kamera, wenn Sie keinen Zugriff auf Ihren PC haben."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/InstarVision/Windows_Phone/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Windows/InstarVision/Metro/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_InstarVision_Metro.webp'
                    alt="InstarVision für Windows Metro"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="InstarVision Metro App"
              description="Die Windows Metro App InstarVision für Ihren PC oder Laptop. Steuern Sie Ihre Kamera von Ihrem Computer aus und nutzen..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/InstarVision/Metro/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Windows/iSpy/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_iSpy_Connect.webp'
                    alt="iSpy Connect"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="iSpy Connect"
              description="Die Windows App iSpy für Ihren PC oder Laptop. Steuern Sie Ihre Kamera von Ihrem Computer aus und nutzen Sie die Rechenleistung für erweiterte Funktionen."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/iSpy/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Windows/Sighthound/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Sighthound.webp'
                    alt="Sighthound"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Sighthound"
              description="Die Windows App Sighthound für Ihren PC oder Laptop. Steuern Sie Ihre Kamera von Ihrem Computer aus und nutzen Sie die Rechenleistung für erweiterte Funktionen."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/Sighthound/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Windows/P2P_Client/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_P2P_Client.webp'
                    alt="P2P Client"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="P2P Client"
              description="Der Windows-App-P2P-Client für Ihren PC oder Laptop. Steuern Sie Ihre Kamera von Ihrem Computer aus und nutzen Sie die Rechenleistung für erweiterte Funktionen."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/P2P_Client/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Windows/go1984/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_go1984.webp'
                    alt="go1984"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="go1984"
              description="Die Windows-App go1984 für Ihren PC oder Laptop. Steuern Sie Ihre Kamera von Ihrem Computer aus und nutzen Sie die Rechenleistung für erweiterte Funktionen."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/go1984/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Windows/VLC_Player/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_VLC.webp'
                    alt="VLC Player"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="VLC Player"
              description="Der Windows-App-VLC-Player für Ihren PC oder Laptop. Steuern Sie Ihre Kamera von Ihrem Computer aus und nutzen Sie die Rechenleistung für erweiterte Funktionen."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/VLC_Player/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Windows/CCTV_Chrome_Plugin/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_CCTV.webp'
                    alt="CCTV für Chrome"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="CCTV für Chrome"
              description="Verwenden Sie das CCTV Chrome Browser Plugin, um Ihre Kamera über Ihren bevorzugten Webbrowser zu steuern."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/CCTV_Chrome_Plugin/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Windows/Blue_Iris_v5/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Blue_Iris.webp'
                    alt="Blue Iris v5"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Blue Iris v5"
              description="The Windows app Blue Iris for your PC or Laptop. Control your camera from your computer and harness the computing power for advanced features."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/Blue_Iris_v5/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Windows/Blue_Iris/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Blue_Iris.webp'
                    alt="Blue Iris v3"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Blue Iris v3"
              description="Die Windows App Blue Iris für Ihren PC oder Laptop. Steuern Sie Ihre Kamera von Ihrem Computer aus und nutzen Sie die Rechenleistung für erweiterte Funktionen."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/Blue_Iris/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Windows/YAW_Cam/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_YAW_Cam.webp'
                    alt="YAW Cam"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="YAW Cam"
              description="Die Windows App YAW Cam für Ihren PC oder Laptop. Steuern Sie Ihre Kamera von Ihrem Computer aus und nutzen Sie die Rechenleistung für erweiterte Funktionen."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/YAW_Cam/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Windows/Zone_Trigger/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Zone_Trigger.webp'
                    alt="Zone Trigger"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Zone Trigger"
              description="Die Windows App Zone Trigger für Ihren PC oder Laptop. Steuern Sie Ihre Kamera von Ihrem Computer aus und nutzen Sie die Rechenleistung für erweiterte..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/Zone_Trigger/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Windows/WebCam_XP/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_WebcamXP.webp'
                    alt="WebCam XP"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="WebCam XP"
              description="Die Windows App WebCam XP für Ihren PC oder Laptop. Steuern Sie Ihre Kamera von Ihrem Computer aus und nutzen Sie die Rechenleistung für erweiterte Funktionen."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/WebCam_XP/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Windows/Webcam_Motion_Detector/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Webcam_MD.webp'
                    alt="Webcam Motion Detector"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Webcam Motion Detector"
              description="Die Windows App Webcam Motion für Ihren PC oder Laptop. Steuern Sie Ihre Kamera von Ihrem Computer aus und nutzen Sie die Rechenleistung für erweiterte..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/Webcam_Motion_Detector/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Windows/Zebra_Surveillance/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Zebra_Surveillance.webp'
                    alt="Zebra Surveillance"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Zebra Surveillance"
              description="Die Windows App Zebra für Ihren PC oder Laptop. Steuern Sie Ihre Kamera von Ihrem Computer aus und nutzen Sie die Rechenleistung für erweiterte Funktionen."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/Zebra_Surveillance/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
