import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class VersionTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "featureColumn", title: "Feature" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { featureColumn: 'Software Version', descriptionColumn: 'Version Nummer Ihrer Software Installation.' },
        { featureColumn: 'Verfügbare Kanäle', descriptionColumn: 'Die Anzahl der verfügbaren Kanäle (Kameras die Sie erlaubt sind zur Kamera Liste hinzuzufügen) hängt von Ihrer Lizenz ab. Sie können maximal bis zu 64 Kameras in das InstarVision Überwachung Center hinzufügen.' },
        { featureColumn: 'Aktiver Nutzer', descriptionColumn: 'Zeigt Ihnen das aktive Benutzerprofil.' },
        { featureColumn: 'Support Information', descriptionColumn: 'Bitte nutzen Sie die offizielle INSTAR Webseite um uns zu kontaktieren. Das INSTAR Support Team steht Ihnen bei Fragen und Anregungen gerne zur Verfügung.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

VersionTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(VersionTable)