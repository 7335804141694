import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Infrared Nightvision">
        <EuiText>
          <p>
            Die IR LEDs in Ihrer Kamera werden automatisch durch den Lichtsensor ein- und ausgeschaltet. Wenn Sie Ihre Kamera hinter einem hoch reflektierten Fenster zum Beispiel als Wetterkamera verwenden möchten und die IR LEDs nicht benötigen, deaktivieren Sie die LEDs einfach hier. Beachten Sie, dass der automatische IR Cutfilter sich automatisch permament vor die Linse bewegt. Das heißt Sie können kein externes IR Licht verwenden, wenn die internen IR Lampen ausgeschaltet sind.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

