import React from 'react'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText
} from '@elastic/eui'

import lenseAdjust01 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Lense_Adjustment/Quickinstallation_Lense-Adjust_5905_01.webp"
import lenseAdjust02 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Lense_Adjustment/Quickinstallation_Lense-Adjust_5905_02.webp"
import lenseAdjust03 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Lense_Adjustment/Quickinstallation_Lense-Adjust_5905_03.webp"
import lenseAdjust04 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Lense_Adjustment/Quickinstallation_Lense-Adjust_5905_04.webp"
import lenseAdjust05 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Lense_Adjustment/Quickinstallation_Lense-Adjust_5905_05.webp"
import lenseAdjust06 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Lense_Adjustment/Quickinstallation_Lense-Adjust_5905_06.webp"



export default function QIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={lenseAdjust01}
                    alt="I. Entfernen Sie die obere Abdeckung der IN-5905 HD."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    <strong>I</strong>. Bitte entfernen Sie die obere Abdeckung Ihres IN-5905 HD. Achten Sie darauf, den Kunststoffring unter der weißen Schraube nicht zu verlieren, der der Wasserdichtigkeit dient!
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={lenseAdjust02}
                    alt="II. Lösen Sie die zwei hinteren Gehäuseschrauben. Eventuell sollten Sie die Gelenkschraube lockern um die Gehäuseschrauben zugänglicher zu machen."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    <strong>II.</strong> Lösen Sie die zwei hinteren Gehäuseschrauben. Eventuell sollten Sie die Gelenkschraube lockern um die Gehäuseschrauben zugänglicher zu machen.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={lenseAdjust03}
                    alt="III. Öffnen Sie nun vorsichtig die Kamera ohne irgendwelche Kabel zu trennen."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    <strong>III.</strong> Öffnen Sie nun vorsichtig die Kamera ohne irgendwelche Kabel zu trennen.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={lenseAdjust04}
                    alt="IV. Lösen Sie nun die drei äußeren Schrauben, die das Innenleben an der Kamerafront befestigen. Achtung! Lassen Sie die Schrauben, die mit einem Hexagon markiert sind unberührt!"
                  />
              }
              footer={
                <EuiText>
                    <p>
                    <strong>IV.</strong> Lösen Sie nun die drei äußeren Schrauben, die das Innenleben an der Kamerafront befestigen. Achtung! Lassen Sie die Schrauben, die mit einem Hexagon markiert sind unberührt!
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={lenseAdjust05}
                    alt="V. Entnehmen Sie nun langsam und vorsichtig das Innenleben mitsamt der Optik aus der Kamerafront. Diese Einheit ist vorne mit der LED Platine über zwei Kabelstränge verbunden. Bitte achten Sie darauf, dass Sie diese nicht beschädigen."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    <strong>V.</strong> Entnehmen Sie nun langsam und vorsichtig das Innenleben mitsamt der Optik aus der Kamerafront. Diese Einheit ist vorne mit der LED Platine über zwei Kabelstränge verbunden. Bitte achten Sie darauf, dass Sie diese nicht beschädigen.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={lenseAdjust06}
                    alt="VI. Lösen Sie nun die Fixierschraube am mittlerem Objektivring. Die darunterliegende Schraube hält das Objektiv an der Sensoreinheit fest und muss nicht gelöst werden."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    <strong>VI.</strong> Lösen Sie nun die Fixierschraube am mittlerem Objektivring. Die darunterliegende Schraube hält das Objektiv an der Sensoreinheit fest und muss nicht gelöst werden. Schließlich setzen Sie die Kamera in umgekehrter Reihenfolge wieder zusammen. Passen Sie dabei auf, dass die zwei Kabelstränge im Inneren der Kamerafront nicht die Optik behindern und vermeiden Sie, dass Kabel eingequetscht werden.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
