import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Table,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-material-ui";

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word",
  },
};

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
);

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0,
    }}
    {...restProps}
  />
);

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class AudioTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Audio Einstellungen" },
        { name: "descriptionColumn", title: "Beschreibung" },
      ],
      rows: [
        {
          actionColumn: "Audio aktivieren",
          descriptionColumn:
            "Aktiveren oder deaktiveren des Kamera Audio. Dies deaktiviert entweder den Kamera Input oder den Kamera Output.",
        },
        {
          actionColumn: "Audio Lautstärke",
          descriptionColumn:
            "Die Audio Lautstärke regelt das interne oder (optional) externe Mikrofon an Ihrer Kamera. Bitte denken Sie daran, dass die Lautstärke des Audioeingans indirekt die Empfindlichkeit Audioerkennung beeinflusst.",
        },
        {
          actionColumn: "Audio Output Lautstärke",
          descriptionColumn:
            "Hier können Sie die Audiolautstärke für die internen oder optional externen Lautsprecher einstellen.",
        },
        {
          actionColumn: "Rauschunterdrückung (ANR) & ANR-Stufe",
          descriptionColumn:
            "Nutzen Sie die aktive Rauschunterdrückung (Active Noise Reduction), um das Hintergrundrauschen im Audiostream der Kamera zu unterdrücken. Legen Sie hier die optimale Stufe fest, abhängig von der Umgebungslautstärke im Umfeld der Kamera.",
        },
        {
          actionColumn: "Echounterdrückung (AEC)",
          descriptionColumn:
            "Wenn Sie die Lautsprecher Funktion der Kamera nutzen kann Echo (Feedback Loop) in das Audio gelangen, wenn die Ausioaufnahme und Wiedergabe im gleichen Raum erfolgt. Nutzen Sie die Echounterdrückung (Active Echo Cancellation), um diesen Effekt zu unterbinden.",
        },
      ],
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{ display: "inline-block", maxWidth: "915px" }}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

AudioTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AudioTable);
