import React from 'react'

import Image01Quality from '../../../images/Outdoor_Cameras/IN-9408_WQHD/IN-9408-vs-IN-9008_resTestBig.webp'
import Image02Quality from '../../../images/Outdoor_Cameras/IN-9408_WQHD/IN-9408-vs-IN-9008_resTestBig2.webp'
import Image03Quality from '../../../images/Outdoor_Cameras/IN-9408_WQHD/IN-9408-vs-IN-9008_WA_resTestBig.webp'
import Image04Quality from '../../../images/Outdoor_Cameras/IN-9408_WQHD/IN-9408v9008_resTestBig2.webp'
import Image05Quality from '../../../images/Outdoor_Cameras/IN-9408_WQHD/IN-9408v9008_resTestBig3.webp'
import Image06Quality from '../../../images/Outdoor_Cameras/IN-9408_WQHD/IN-9408-vs-IN-9008_Night_resTestBig.webp'

import {
  EuiImage,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

export default () => (
    <React.Fragment>
        <EuiFlexGroup justifyContent="spaceBetween">
            <EuiFlexItem grow={false}>
                <EuiImage
                    size="fullWidth"
                    hasShadow
                    allowFullScreen
                    caption="Klick mich"
                    alt="IN-9408 2k+ (WQHD) vs IN-9008HD"
                    fullScreenIconColor="light"
                    src={Image01Quality}
                />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
                <EuiImage
                    size="fullWidth"
                    hasShadow
                    allowFullScreen
                    caption="Klick mich"
                    alt="IN-9408 2k+ (WQHD) vs IN-9008HD"
                    fullScreenIconColor="light"
                    src={Image02Quality}
                />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
                <EuiImage
                    size="fullWidth"
                    hasShadow
                    allowFullScreen
                    caption="Klick mich"
                    alt="IN-9408 2k+ (2k+ (WQHD)) vs IN-9008HD"
                    fullScreenIconColor="light"
                    src={Image03Quality}
                />
            </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer />

        <EuiFlexGroup justifyContent="spaceBetween">
            <EuiFlexItem grow={false}>
                <EuiImage
                    size="fullWidth"
                    hasShadow
                    allowFullScreen
                    caption="Klick mich"
                    alt="IN-9408 2k+ (WQHD) vs IN-9008HD"
                    fullScreenIconColor="light"
                    src={Image04Quality}
                />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
                <EuiImage
                    size="fullWidth"
                    hasShadow
                    allowFullScreen
                    caption="Klick mich"
                    alt="IN-9408 2k+ (WQHD) vs IN-9008HD"
                    fullScreenIconColor="light"
                    src={Image05Quality}
                />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
                <EuiImage
                    size="fullWidth"
                    hasShadow
                    allowFullScreen
                    caption="Klick mich"
                    alt="IN-9408 2k+ (WQHD) vs IN-9008HD"
                    fullScreenIconColor="light"
                    src={Image06Quality}
                />
            </EuiFlexItem>
        </EuiFlexGroup>
  </React.Fragment>
)