import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class FeatureTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "numberColumn", title: "#" },
        { name: "componentColumn", title: "Komponente" },
        { name: "functionColumn", title: "Funktion" },
      ],
      rows: [
        { numberColumn: '01', componentColumn: 'Infrarot LEDs', functionColumn: '5x High-power Nachtsicht IR LED´s @850nm' },
        { numberColumn: '02', componentColumn: 'Objektiv', functionColumn: 'Weitwinkel (Brennweite 4.2mm)' },
        { numberColumn: '03', componentColumn: 'Helligkeitsensor', functionColumn: 'Fotodiode für den Dämmerungsschalter' },
        { numberColumn: '04', componentColumn: 'PIR Sensor', functionColumn: 'Integrierter Panasonic Passiver Infrarot (PIR) Bewegungsmelder' },
        { numberColumn: '05', componentColumn: 'Mikrofon', functionColumn: 'Für Geräuscherkennung / Audio Aufnahme / Zwei-Wege Audio Kommunikation' },
        { numberColumn: '06', componentColumn: 'Audio Ausgang', functionColumn: '3.5mm Klinke für Kopfhörer / Aktivlautsprecher' },
        { numberColumn: '07', componentColumn: 'Netzwerkanschluss', functionColumn: 'RJ45 LAN Port für das CAT5e Ethernet Kabel' },
        { numberColumn: '08', componentColumn: 'Netzteilanschluss', functionColumn: 'Für das 12V / 2A dc Netzteil' },
        { numberColumn: '09', componentColumn: 'Micro SD Karte', functionColumn: 'SD Kartenleser für MicroSD/SDXC Karten bis 128 GB (16 GB inklusive)' },
        { numberColumn: '10', componentColumn: 'WPS / Reset', functionColumn: '3s für WLAN Einrichtung / 15s für Werksreset' },
        { numberColumn: '11', componentColumn: 'Status LEDs', functionColumn: 'Rot: Power, Blau: Netzwerk Status' },
        { numberColumn: '12', componentColumn: 'Antennen Anschluss', functionColumn: 'RP-SMA WiFi Antennenanschluss' },
        { numberColumn: '13', componentColumn: 'WiFi Antenne', functionColumn: '3 dBi Antenne (RP-SMA)' },
        { numberColumn: '14', componentColumn: 'Kabel Einlass', functionColumn: 'IP65 wettergeschützte Gummidichtungen für 3, 2 oder 1 Kabel. Sehen Sie bitte unsere Schnellinstallation Anleitung bezüglich des Anschlusses der Kabel' },
        { numberColumn: '15', componentColumn: 'Dichtungsring', functionColumn: 'IP65 wettergeschützte Gummidichtungen für 3, 2 oder 1 Kabel. Sehen Sie bitte unsere Schnellinstallation Anleitung bezüglich des Anschlusses der Kabel' },
        { numberColumn: '16', componentColumn: 'Nuß', functionColumn: 'Zum Verschließen des Kabeleinlasses' },
        { numberColumn: '17', componentColumn: 'Halterung', functionColumn: 'Wand- und Deckenhalterung' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

FeatureTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(FeatureTable)


// import React from 'react'
// import {Table, Column, Cell} from 'fixed-data-table-2'
// import '../../../../assets/styles/data_tables/fixed-data-table.css'

// const numberRow = [
//   '01',
//   '02',
//   '03',
//   '04',
//   '05',
//   '06',
//   '07',
//   '08',
//   '09',
//   '10',
//   '11',
//   '12',
//   '13',
//   '14',
//   '15',
//   '16',
//   '17'
// ];

// const componentRow = [
//   'Infrared LEDs',
//   'Lense',
//   'Photo sensor',
//   'PIR Detector',
//   'Microphone',
//   'Audio Output',
//   'Network Port',
//   'Power Connector',
//   'Micro SD Card',
//   'WPS / Reset',
//   'Status LEDs',
//   'Antenna Connector',
//   'WiFi Antenna',
//   'Cable Feedthrough',
//   'Sealing Ring',
//   'Nut',
//   'Bracket'
// ];

// const functionRow = [
//   '5x High-power infrared LED´s for night vision @850nm',
//   'Wide Angle (focal length 4.2mm)',
//   'Fotodiode für den Dämmerungsschalter',
//   'Integrated Panasonic PIR Motion Detector',
//   'Für Geräuscherkennung / Audio Aufnahme / Zwei-Wege Audio Kommunikation',
//   '3.5mm Jack for Earphone / Active Speaker',
//   'RJ45 LAN Port for the provided CAT5e Ethernet Cable (or higher)',
//   'For included 12V / 2A dc Power supply',
//   'SD Kartenleser für MicroSD/SDXC Karten bis 128 GB (16 GB inklusive)',
//   '3s for WiFi Setup / 15s for Reset to Factory Defaults',
//   'Red: Power, Blue: Network Status',
//   'RP-SMA WiFi Antennenanschluss',
//   '3 dBi Antenna (RP-SMA)',
//   'IP65 Weatherproof with Rubber Sealing for 3, 2 or 1 Cables. Please check out our Quick Installation Guide on how to attach your Camera Cables',
//   'Rubber Sealing Rings for 3, 2 or 1 Cables. Please check out our Quick Installation Guide on how to attach your Camera Cables',
//   'To close the Cable Feedthrough and Weather Seal it.',
//   'Wall- and Ceiling-Mount.'
// ];

// // Render your table
// const FeatureTable = () => {
//     return (
//       <Table
//         rowHeight={55}
//         rowsCount={numberRow.length}
//         touchScrollEnabled={true}
//         width={850}
//         maxHeight={1000}
//         headerHeight={50}>
//         <Column
//           header={<Cell>#</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {numberRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={1}
//           width={50}
//         />
//         <Column
//           header={<Cell>Component</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {componentRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={2}
//           width={250}
//         />
//         <Column
//           header={<Cell>Function</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {functionRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={3}
//           width={535}
//         />
//       </Table>
//     )
// }

// export default FeatureTable