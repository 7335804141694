import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="System Logbuch">
        <EuiText>
          <p>
            Nutzen Sie das Log um Fehlerquellen auf die Schliche zu kommen. Sie erhalten keine Alarm Emails mehr, die SMTP Server Daten scheinen aber noch zu funktionieren? Schauen Sie im Log nach, ob überhaupt noch Alarme ausgelöst werden. Sie erhalten kontinuierlich Alarmbenachrichtigungen, obwohl Sie testweise schon einmal die Bewegungserkennung ganz deaktiviert haben? Überprüfen Sie im Log was den Alarm auslöst. Ist eventuell der Audio Alarm aktiv? Oder kommt der Trigger vom Alarmeingang der Kamera?
          </p>
          <p>
            Klicken Sie oben rechts auf das SD Karten Symbol, um das Logbuch auf der SD Karte der Kamera zu speichern. Auf diese Weise bleibt das Log auch nach einem Kameraneustart erhalten.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

