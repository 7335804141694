import React from 'react'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="t7CcMk_9Kus"
                    title="Installation der App InstarVision auf einem iPhone"
                    adNetwork={true}
                    poster="hqdefault" //maxresdefault
                    noCookie={true}
                />
              }
              footer={
                <EuiText>
                  <p>Installation der App InstarVision auf einem iPhone</p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="cLVYEmb1jAk"
                    title="Hinzufügen einer Kamera in der iPhone App InstarVision"
                    adNetwork={true}
                    poster="hqdefault" //maxresdefault
                    noCookie={true}
                />
              }
              footer={
                <EuiText>
                  <p>Hinzufügen einer Kamera in der iPhone App InstarVision</p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer />

        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="HXC9wS31gdc"
                    title="Mehrere Kameras in der iPhone App InstarVision"
                    adNetwork={true}
                    poster="hqdefault" //maxresdefault
                    noCookie={true}
                />
              }
              footer={
                <EuiText>
                  <p>Mehrere Kameras in der iPhone App InstarVision</p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="pSxS0qpFrg8"
                    title="Erklärung der Einstellungen in iPhone App InstarVision"
                    adNetwork={true}
                    poster="hqdefault" //maxresdefault
                    noCookie={true}
                />
              }
              footer={
                <EuiText>
                  <p>Erklärung der Einstellungen in iPhone App InstarVision</p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer />

        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="P-YyavzBkEk"
                    title="Aufnahmen und SD Karten Zugriff in iPhone App InstarVision"
                    adNetwork={true}
                    poster="hqdefault" //maxresdefault
                    noCookie={true}
                />
              }
              footer={
                <EuiText>
                  <p>Aufnahmen und SD Karten Zugriff in iPhone App InstarVision</p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="jgGk1vw9UYM"
                    title="Schaltflächen in der iPhone App InstarVision"
                    adNetwork={true}
                    poster="hqdefault" //maxresdefault
                    noCookie={true}
                />
              }
              footer={
                <EuiText>
                  <p>Schaltflächen in der iPhone App InstarVision</p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer />

        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="8_Ox0Ny8-zU"
                    title="InstarVision :: App Übersicht"
                    adNetwork={true}
                    poster="hqdefault" //maxresdefault
                    noCookie={true}
                />
              }
              footer={
                <EuiText>
                  <p>InstarVision :: App Übersicht</p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="RIJtKs17U7Y"
                    title="InstarVision :: Benutzerverwaltung"
                    adNetwork={true}
                    poster="hqdefault" //maxresdefault
                    noCookie={true}
                />
              }
              footer={
                <EuiText>
                  <p>InstarVision :: Benutzerverwaltung</p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer />

        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="fTKBtHTSI3Q"
                    title="InstarVision :: Einstellungen Exportieren"
                    adNetwork={true}
                    poster="hqdefault" //maxresdefault
                    noCookie={true}
                />
              }
              footer={
                <EuiText>
                  <p>InstarVision :: Einstellungen Exportieren</p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="HuboA0aC4Vc"
                    title="Drittanbieter Kameras einbinden per RTSP / JPEG in die App InstarVision"
                    adNetwork={true}
                    poster="hqdefault" //maxresdefault
                    noCookie={true}
                />
              }
              footer={
                <EuiText>
                  <p>Drittanbieter Kameras einbinden per RTSP / JPEG in die App InstarVision</p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}
