import { EuiSpacer } from '@elastic/eui';
import VideoCard from 'components/Motion_Detection/Videos/video-ftp-server-fritzbox';
import NavButtons from 'components/Motion_Detection/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  VideoCard,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};