import React from 'react'
import {Link} from 'gatsby'

import {
  EuiCard,
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import menuBarAudio from "../../../../images/Web_User_Interface/720p_Series/Video_Menu_Bar/WebUI-MenuVideoBar_Audio.png"
import menuBarMic from "../../../../images/Web_User_Interface/720p_Series/Video_Menu_Bar/WebUI-MenuVideoBar_Microphone.png"
import menuBarZoom from "../../../../images/Web_User_Interface/720p_Series/Video_Menu_Bar/WebUI-MenuVideoBar_Zoom.png"
import menuBarRec from "../../../../images/Web_User_Interface/720p_Series/Video_Menu_Bar/WebUI-MenuVideoBar_RecPath.png"

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={menuBarAudio}
                    alt="Kamera Mikrofon"
                  />
              }
              title='Kamera Mikrofon'
              description={
                <EuiText>
                    <p>
                    Klicken Sie hier um den Audiostream der Kamera zu aktivieren. Stellen Sie die Lautstärke im <Link to="/Web_User_Interface/720p_Serie/Multimedia/Audio/">Audio Menü</Link> ein.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={menuBarMic}
                    alt="Kamera Lautsprecher"
                  />
              }
              title='Kamera Lautsprecher'
              footer={
                <EuiText>
                    <p>
                      Im Fall das Ihr PC mit einem Mikrofon ausgestattet ist, klicken Sie hier um den Audio-Ausgang Ihrer Kamera zu aktivieren.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={menuBarZoom}
                    alt="Digitaler Zoom"
                  />
              }
              title='Digitaler Zoom'
              footer={
                <p>
                  Klicken Sie hier um den 3x Digitalen Zoom zu aktivieren - nutzen Sie den 3. Button um auf die Standardeinstellungen zurückzukehren.
                </p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={menuBarRec}
                    alt="Aufnahmepfad"
                  />
              }
              title='Aufnahmepfad'
              footer={
                <p>
                  Hier können Sie den Aufnahmepfad für Ihre <Link to="/Web_User_Interface/720p_Serie/Video_Menu_Bar/">manuell aufgenommenen</Link> Videos und Snapshots einstellen. Der Pfad wird von all Ihren INSTAR HD Kameras genutzt!
                </p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
