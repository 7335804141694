import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Panasonic PIR Installation">
        <EuiText>
            <p>PaPIRs sind pyroelektrische Infrarotsensoren, die Variationen in Infrarotstrahlen erkennen. Die Erkennung kann jedoch in den folgenden Fällen nicht erfolgreich sein: fehlende Bewegung oder keine Temperaturänderung in der Wärmequelle. Sie könnten auch das Vorhandensein von Wärmequellen außer einem menschlichen Körper erkennen. Die Effizienz und Zuverlässigkeit des Systems kann abhängig von den tatsächlichen Betriebsbedingungen variieren:</p>
            <ul>
              <li>Erkennen von Wärmequellen, die nicht der menschliche Körper sind, wie:</li>
              <ul>
                <li>kleine Tiere betreten den Erfassungsbereich</li>
                <li>Wenn eine Wärmequelle, beispielsweise Sonnenlicht, Glühlampe, Autoscheinwerfer usw., oder ein starker Lichtstrahl den Sensor trifft, unabhängig davon, ob der Erfassungsbereich innen oder außen ist</li>
                <li>Plötzliche Temperaturänderung im oder um den Erfassungsbereich herum, verursacht durch heißen oder kalten Wind aus der HLK oder Dampf aus einem Luftbefeuchter usw.</li>
              </ul>
              <li>Schwierigkeit beim Erfassen der Wärmequelle</li>
              <ul>
                <li>Glas, Acryl oder ähnliche Materialien, die zwischen dem Target und dem Sensor stehen, können eine korrekte Übertragung von Infrarotstrahlen nicht zulassen</li>
                <li>Keine Bewegung oder schnelle Bewegungen der Wärmequelle im Erfassungsbereich</li>
              </ul>
              <li>Erweiterung des Erfassungsbereichs: Bei einem erheblichen Unterschied der Umgebungstemperatur und der Körpertemperatur des Menschen kann der Erfassungsbereich größer sein als der konfigurierte Erfassungsbereich</li>
              <li>Erkennungsentfernung: Die PIR-Bewegungsmelder von Panasonic geben den Erkennungsabstand in den Spezifikationen an, da sie normalerweise mit der Linse geliefert werden. Der PIR-Bewegungsmelder könnte Schwankungen in Infrarotstrahlen erkennen, jedoch werden solche Variationen durch die folgenden drei Faktoren bestimmt</li>
              <ul>
                <li>Der Temperaturunterschied zwischen dem Ziel und der Umgebung: Je größer der Temperaturunterschied, desto einfacher ist es Ziele zu erkennen</li>
                <li>Bewegungsgeschwindigkeit: Wenn sich das Ziel langsamer oder schneller als in den Tabellen angegeben bewegt, kann die Erkennungsfähigkeit geringer sein</li>
                <li>Zielgröße: Der menschliche Körper ist der Standard. Wenn das Ziel kleiner oder größer als in der Tabelle angegeben ist, kann die Erkennungsfähigkeit geringer sein</li>
              </ul>
              <li>Der in unserem Datenblatt erläuterte Erfassungsabstand ist durch die drei oben genannten Faktoren definiert. Panasonics Standard für den Temperaturunterschied zwischen Ziel und Umgebung ist mit 4 ° C definiert. Je größer der Temperaturunterschied ist, desto länger ist der Erfassungsabstand. Wenn die Temperaturdifferenz 8 ° C beträgt, was doppelt so viel wie üblich ist, beträgt der Erkennungsabstand ca. 1,4 mal länger als die Distanz bei 4 ° C. Wenn z. B. Ziele in einer Entfernung von 5 m bei 4 ° C erkannt werden, kann der Sensor Ziele in einer Entfernung von 7 m bei 8 ° C erkennen. (Dies basiert auf der Theorie, dass die Nachweisempfindlichkeit umgekehrt zum Quadrat der Entfernung variiert.)</li>
              <li>Linsenmaterial und die Platteneinstellung vor der Linse: Typischerweise ist das einzige Material, das durch Infrarotstrahlen durchgelassen werden kann, Polyethylen. (Das Linsenmaterial der PIR-Bewegungssensoren von Panasonic ist &quot;Polyethylen hoher Dichte, HDPE&quot;.) Wenn Sie eine Platte vor die Linse stellen müssen, wählen Sie bitte eine aus Polyethylen. Bitte beachten Sie, dass die Dicke oder Farbe der Platte die Erkennungsfähigkeit, z. es kann den Erfassungsabstand kürzer machen. Bitte bestätigen Sie daher, indem Sie den Sensor mit der Platte unter realistischen Bedingungen testen.</li>
            </ul>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

