import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class PTZTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Pan, Tilt & Zoom" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Justagefahrt und Positionen', descriptionColumn: 'Aktivieren/Deaktivieren Sie hier die Kalibrierungsfahrt, die die Kamera beim Neustart durchführt. Beachten Sie bitte, dass die speicherbaren Positionen der Kamera nur mit aktiver Kalibrierungsfahrt zur Verfügung stehen.' },
        { actionColumn: 'Position 1 nach Neustart', descriptionColumn: 'Nach einem Neustart fährt die Kamera nach der Kalibrierung immer in eine Mittelposition. Hier haben Sie die Möglichkeit die Kamera stattdessen die [gespeicherte Position 1](/Web_User_Interface/1080p_Serie/Live_Video/) anfahren zu lassen. Nur verfügbar mit aktivierter Justagefahrt!' },
        { actionColumn: 'Alarmposition aktivieren', descriptionColumn: 'Möchten Sie, dass die Kamera bei einem Alarmereignis automatisch eine gespeicherte Position anfährt? Dann aktivieren Sie diese Funktion hier. Dies ist besonders interessant bei der Verwendung eines externen Bewegungsmelders, wie den IN-MOTION 500, oder des Audioalarms.  Lasse Sie die Kamera eine Richtung überwachen und den anderen Sensor die andere. Nur verfügbar mit aktivierter Justagefahrt!' },
        { actionColumn: 'Alarmposition', descriptionColumn: 'Legen Sie hier fest, welche Position die Kamera bei einem Alarm anfahren soll.' },
        { actionColumn: 'Automatisch auf Parkposition fahren', descriptionColumn: 'Wollen Sie Ihre Kamera standardmäßig immer auf eine bestimmte Position fahren? Aktivieren Sie die Parkposition, um Ihre Kamera automatisch auf diese Position zurückzufahren, nachdem Sie bewegt wurde.' },
        { actionColumn: 'Parkposition auswählen', descriptionColumn: 'Legen Sie hier die Parkposition fest (siehe oben).' },
        { actionColumn: 'Nach x Sekunden bewegen', descriptionColumn: 'Ihre Kamera wird für x Sekunden warten und dann zu Ihrer Parkposition zurückfahren.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

PTZTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(PTZTable)