import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class FeatureTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "numberColumn", title: "#" },
        { name: "componentColumn", title: "Komponente" },
        { name: "functionColumn", title: "Funktion" },
      ],
      rows: [
        { numberColumn: '01', componentColumn: 'Wandhalterung', functionColumn: 'Für Befestigung an der Wand' },
        { numberColumn: '02', componentColumn: 'Deckenhalterung', functionColumn: 'Für Befestigung an der Decke' },
        { numberColumn: '03', componentColumn: 'Antennenanschluss', functionColumn: 'Für RP-SMA WLAN-Antennen (nicht für PoE-Version)' },
        { numberColumn: '04', componentColumn: 'Dual LEDs', functionColumn: '6 high-power IR LEDs (@ 850nm), 6 high-power floodlight LEDs' },
        { numberColumn: '05', componentColumn: 'Zoomobjektiv', functionColumn: '5-fach optischer Zoom mit Autofokus (2,8mm ~ 12mm)' },
        { numberColumn: '06', componentColumn: 'Helligkeitssensor', functionColumn: 'Dämmerungsschalter für IR-LEDs' },
        { numberColumn: '07', componentColumn: 'PIR-Wärmesensor', functionColumn: 'Integrierter passiver Panasonic Infrarot-Bewegungsmelder' },
        { numberColumn: '08', componentColumn: 'Mikrofon', functionColumn: 'Für Geräuscherkennung und Audioaufnahme' },
        { numberColumn: '09', componentColumn: 'Status LEDs', functionColumn: 'Rot: Power-LED; Blau: Netzwerkstatus-LED. Beide können deaktiviert werden. (s. LED Modi, unten)' },
        { numberColumn: '10', componentColumn: 'MicroSD Card Slot', functionColumn: 'Slot for MicroSD/SDXC cards (max. 256GB) - 32GB included inside the camera' },
        { numberColumn: '11', componentColumn: 'Lautsprecher', functionColumn: 'Integrierter Lautsprecher' },
        { numberColumn: '12', componentColumn: 'Alarmeingang', functionColumn: 'Alarmeingang für potentialfreie Alarmsensorens' },
        { numberColumn: '13', componentColumn: 'Alarmausgang', functionColumn: 'Unterstützt externe Alarmgeräte, z. B. Sirenen' },
        { numberColumn: '14', componentColumn: 'Reset', functionColumn: '20 Sekunden gedrückt halten zum zurücksetzen' },
        { numberColumn: '15', componentColumn: 'Netzwerk-LED', functionColumn: 'LED für Netzwerk-Status - Ein: bestehende Netzwerkverbindung per LAN-Kabel, Aus: keine Verbindung per LAN-Kabel, Kamera ist per WLAN verbunden oder kein Strom vorhanden.' },
        { numberColumn: '16', componentColumn: 'Netzwerkanschluss', functionColumn: 'RJ45-LAN-Anschluss für Cat5e-LAN-Kabel oder höher' },
        { numberColumn: '17', componentColumn: 'Stromanschluss', functionColumn: 'Für mitgeliefertes 12V / 2A DC Netzteil' },
        { numberColumn: '18', componentColumn: 'Anschlusskabel', functionColumn: 'Mit LAN- u. Stromanschluss sowie Alarmeingang, Alarmausgang, Reset' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

FeatureTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(FeatureTable)