import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class UserTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "parameterColumn", title: "Parameter" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { parameterColumn: 'IP Adresse', descriptionColumn: 'Wählen Sie hier eine IP-Adresse für Ihre Kamera aus. Aber achten Sie darauf, dass die IP-Adresse Ihrem Router angepasst ist! Für eine Fritzbox, zum Beispiel, ist dies standardmäßig __192.168.178.XXX__, für einen Telekom Speedport Router die __192.168.2.XXX__. Damit es nicht zu Fehlern in Ihrem lokalen Netzwerk kommt, wählen Sie am besten eine freie Zuordnungszahl für Ihre Kamera ab dem Wert 200. Im Falle einer Fritzbox vergeben Sie also für Kamera 1 die 192.168.178.201, für Kamera 2 die 192.168.178.202, usw.' },
        { parameterColumn: 'Subnetzmaske', descriptionColumn: 'Hier geben Sie standardmäßig die __255.255.255.0__ rein.' },
        { parameterColumn: 'Gateway', descriptionColumn: 'Hier geben Sie die IP-Adresse Ihres Routers ein, z.B. 192.168.178.1 falls Sie eine Fritzbox benutzen oder 192.168.2.1 für den Telekom Speedport Router.' },
        { parameterColumn: 'DNS Server', descriptionColumn: 'Hier geben Sie ebenfalls die IP-Adresse Ihres Routers ein.' },
        { parameterColumn: 'HTTP Port', descriptionColumn: 'Vergeben Sie hier für jede Ihrer Kamera einen Wert ab __8081__ aufsteigend. Also für Kamera 1 den Wert 8081, für Kamera 2 den Wert 8082, usw. (__Bei einer FullHD Kamera lassen Sie diesen Port auf 80__)' },
        { parameterColumn: 'HTTPS Port', descriptionColumn: '(__Nur bei FullHD Kameras__) Vergeben Sie hier für jede Ihrer Kamera einen Wert ab 8081 aufsteigend. Also für Kamera 1 den Wert __8081__, für Kamera 2 den Wert 8082, usw.' },
        { parameterColumn: 'RTMP Port', descriptionColumn: 'Vergeben Sie hier für jede Ihrer Kamera einen Wert ab __1935__ aufsteigend. Also für Kamera 1 den Wert 1935, für Kamera 2 den Wert 1936, usw.' },
        { parameterColumn: 'RTSP Port', descriptionColumn: 'Vergeben Sie hier für jede Ihrer Kamera einen Wert ab __554__ aufsteigend. Also für Kamera 1 den Wert 554, für Kamera 2 den Wert 555, usw.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

UserTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(UserTable)