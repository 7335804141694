import { EuiSpacer } from "@elastic/eui";
import SuccessBox from 'components/Web_User_Interface/1440p_Series/System/My_Account/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/SystemChangeUserDataPerm';
import AccountTable from 'components/Web_User_Interface/1440p_Series/System/My_Account/AccountTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  SuccessBox,
  PermissionBox,
  AccountTable,
  BreadCrumbs,
  SEOHelmet,
  React
};