import { EuiSpacer } from "@elastic/eui";
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/AlarmPerm';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/Features/Email/SuccessBox';
import BreadCrumbs from "components/Layout/BreadCrumbs";
import SEOHelmet from "components/Layout/SEOHelmet";
import * as React from 'react';
export default {
  EuiSpacer,
  PermissionBox,
  SuccessBox,
  BreadCrumbs,
  SEOHelmet,
  React
};