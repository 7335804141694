import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="INSTAR Alexa Skill">
        <EuiText>
          <p>
          Wir bieten Ihnen im Alexa Skill Store sowohl einen INSTAR Cloud Skill als auch einen INSTAR Camera Skill an. Für die <Link to="/Bewegungserkennung/INSTAR_Cloud/Alexa_Cloud_Skills/">INSTAR Cloud Skill</Link> benötigen Sie nur ein INSTAR Cloud-Konto und ein Alexa-Gerät mit einem Display. Fügen Sie die Skill auf Ihrem Alexa-Gerät hinzu und nutzen Sie es direkt.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

