import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'Kamera Befehle',
      href: '/de/Bewegungserkennung/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Camera_Commands/',
    },
    {
      text: 'Cloud Befehle',
      href: '/de/Bewegungserkennung/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/',
    },
    {
      text: 'Troubleshooting',
      href: '/de/Bewegungserkennung/INSTAR_Cloud/Alexa_Cloud_Skills/Troubleshooting/',
    },
    {
      text: 'Kamera hinzufügen',
      href: '/de/Bewegungserkennung/INSTAR_Cloud/Alexa_Cloud_Skills/How_to_add_your_camera/',
    },
    {
      text: '◄ Alexa Cloud Skills',
      href: '/de/Bewegungserkennung/INSTAR_Cloud/Alexa_Cloud_Skills/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={6}
        aria-label="Alexa Cloud Skills"
      />
      <EuiSpacer />
    </Fragment>
  );
}

// export default () => {
//   const breadcrumbs = [
//     {
//       text: 'Camera Commands',
//       href: '/Bewegungserkennung/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Camera_Commands/',
//     },
//     {
//       text: 'Cloud Commands',
//       href: '/Bewegungserkennung/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/',
//     },
//     {
//       text: 'Troubleshooting',
//       href: '/Bewegungserkennung/INSTAR_Cloud/Alexa_Cloud_Skills/Troubleshooting/',
//     },
//     {
//       text: 'Add a Camera',
//       href: '/Bewegungserkennung/INSTAR_Cloud/Alexa_Cloud_Skills/How_to_add_your_camera/',
//     },
//     {
//       text: '◄ Alexa Cloud Skills',
//       href: '/Bewegungserkennung/INSTAR_Cloud/Alexa_Cloud_Skills/',
//     }
//   ];

//   return (
//     <Fragment>
//       <EuiBreadcrumbs
//         truncate={true}
//         breadcrumbs={breadcrumbs}
//         max={6}
//         aria-label="Alexa Cloud Skills"
//       />
//       <EuiSpacer />
//     </Fragment>
//   );
// }