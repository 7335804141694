import React from 'react'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import domoImage01 from "../../../../images/Software/Other_Platforms/Domovea/Domovea_01.webp"
import domoImage02 from "../../../../images/Software/Other_Platforms/Domovea/Domovea_02.webp"
import domoImage03 from "../../../../images/Software/Other_Platforms/Domovea/Domovea_03.webp"
import domoImage04 from "../../../../images/Software/Other_Platforms/Domovea/Domovea_04.webp"
import domoImage05 from "../../../../images/Software/Other_Platforms/Domovea/Domovea_05.webp"
import domoImage06 from "../../../../images/Software/Other_Platforms/Domovea/Domovea_06.webp"


export default function PoECards() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={domoImage01}
                    alt="Domovea Home Automation for your INSTAR IP Camera"
                  />
              }
              description={
                <p><strong>Fig1.</strong> Starten Sie bitte das Domovea Konfigurations-Tool und öffnen Sie das Kamera Konfigurationsmenü.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={domoImage02}
                    alt="Domovea Home Automation for your INSTAR IP Camera"
                  />
              }
              description={
                <p><strong>Fig2.</strong> Jetzt müssen wir noch die INSTAR Kamera Templates in das Domovea System importieren. Klicken Sie hier auf <a href="https://wiki.instar.com/dl/Domovea_Excamfiles_INSTAR.zip">Download</a> um die benötigten Excam Dateien für die Modelle IN-5905 HD, IN-5907 HD, IN-6001 HD, IN-6012 HD, IN-6014 HD herunterzuladen (wir hatten noch keine Möglichkeit es zu testen - aber die CGI Befehle der neuen Full HD Serie sind weitestgehend identisch mit den oben genannten Modellen. Man sollte also die IN-8015 Full HD auch als IN-6014 HD und die IN-9008 Full HD als IN-5905 HD einbinden können).</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={domoImage03}
                    alt="Domovea Home Automation for your INSTAR IP Camera"
                  />
              }
              description={
                <p><strong>Fig3.</strong> Öffnen Sie den Import Dialog und wählen Sie die Excam Datei für Ihr Kameramodell aus. Beachten Sie bitte, dass Domovea nicht die Pan&Tilt Funktion unserer Kameras - Sie können die Kamera also nicht über die Software schwenken oder neigen.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={domoImage04}
                    alt="Domovea Home Automation for your INSTAR IP Camera"
                  />
              }
              description={
                <p><strong>Fig4.</strong> Warten Sie auf die Bestätigung.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={domoImage05}
                    alt="Domovea Home Automation for your INSTAR IP Camera"
                  />
              }
              description={
                <p><strong>Fig5.</strong> Das Menü zum Hinzufügen von Kameras sollte jetzt Ihr INSTAR Kamera Modell beinhalten. Wählen Sie Ihr Modell aus dem Auswahlmenü.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={domoImage06}
                    alt="Domovea Home Automation for your INSTAR IP Camera"
                  />
              }
              description={
                <p><strong>Fig6.</strong> Fügen Sie hier die IP Adresse Ihrer Kamera und das Login Ihres Kamerabenutzers ein.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
