import { EuiSpacer } from '@elastic/eui';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import PrimaryBoxInfo from 'components/Advanced_User/All_Home_Assistant_Tutorials/PrimaryBox';
import * as React from 'react';
export default {
  EuiSpacer,
  BreadCrumbs,
  SEOHelmet,
  PrimaryBoxInfo,
  React
};