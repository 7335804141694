import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/styles'
import {
  SortingState, SelectionState, FilteringState, GroupingState,
  IntegratedFiltering, IntegratedGrouping, IntegratedSelection,
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table, TableHeaderRow, TableFilterRow, TableGroupRow,
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class TechSpecGrid extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "category", title: "Kategorie" },
        { name: "feature", title: "Feature" },
        { name: "property", title: "Eigenschaft" }
      ],
      rows: [
        { category: 'Optik & Imaging', feature: 'Standard Objektiv', property: '8.0 mm (Manueller Fokus) f/1.8' },
        { category: 'Optik & Imaging', feature: 'Blickwinkel', property: '44.1° dia.' },
        { category: 'Optik & Imaging', feature: 'Optischer Zoom', property: 'Nein' },
        { category: 'Optik & Imaging', feature: 'Digitaler Zoom', property: '3 x (nur ActiveX)' },
        { category: 'Optik & Imaging', feature: 'Bildsensor', property: 'OV10633 720p 1/3" CMOS Wide Dynamic Range [1]' },
        { category: 'Optik & Imaging', feature: 'Sensor Sensibilität', property: '3650 mV/lux*s' },
        { category: 'Optik & Imaging', feature: 'Dynamikbereich', property: '115 dB' },
        { category: 'Optik & Imaging', feature: 'Signal/Rauschen', property: '39 dB' },
        { category: 'Optik & Imaging', feature: 'Sensorfläche', property: '5510.4 x 3418.8 µm' },
        { category: 'Optik & Imaging', feature: 'Auflösung', property: '1.0 Megapixel' },
        { category: 'Optik & Imaging', feature: 'Unterstützte Auflösungen', property: '1280 x 720, 640 x 352, 320 x 176' },
        { category: 'Optik & Imaging', feature: 'Min. Beleuchtung (Farbe)', property: '0.05 Lux @ f/2.0' },
        { category: 'Optik & Imaging', feature: 'Video Kompression', property: 'h.264, MJPEG, JPG' },
        { category: 'Optik & Imaging', feature: 'h.264 Bitrate', property: '90 kbps - 6.144 kbps' },
        { category: 'Optik & Imaging', feature: 'Framerate', property: 'bis zu 25fps' },
        { category: 'Optik & Imaging', feature: '# an parallelen Streams', property: '3' },
        { category: 'Optik & Imaging', feature: 'Video Overlay', property: 'Datum, Uhrzeit, Kamera Name' },
        { category: 'Optik & Imaging', feature: 'Video Parameter', property: 'Helligkeit, Kontrast, Saturation' },
        { category: 'Optik & Imaging', feature: 'Tag / Nacht Wechsel', property: 'Ja' },
        { category: 'Optik & Imaging', feature: 'Weißabgleich', property: 'Automatisch' },
        { category: 'Optik & Imaging', feature: 'Elektronische Blende', property: 'Automatisch' },
        { category: 'Optik & Imaging', feature: 'Video Drehen / Spiegeln', property: 'Ja / Ja' },
        { category: 'Nachtsicht', feature: 'Infrarot LEDs', property: '3 High-Power @ 850 nm' },
        { category: 'Nachtsicht', feature: 'IR Modes', property: 'aus/Automatisch' },
        { category: 'Nachtsicht', feature: 'Effektive Reichweite', property: '15-25 m' },
        { category: 'Nachtsicht', feature: 'intgr. IRcut Filter', property: 'Ja (Automatisch)' },
        { category: 'Netzwerk', feature: 'Netzwerkschnittstelle', property: '10Base-T/100Base-TX Ethernet Port / WLAN IEEE 802.11b/g/n (nur nicht-PoE Version) / PoE IEEE 802.3af (nur nicht-WLAN Version)' },
        { category: 'Netzwerk', feature: 'Netzwerkprotokolle', property: 'TCP/IP, DHCP, PPPoE, ARP, FTP, SMTP, DNS, NTP, UPnP, HTTP, TCP, UDP' },
        { category: 'Netzwerk', feature: 'SSL Verschlüsselung', property: 'keine' },
        { category: 'Netzwerk', feature: 'WLAN Verschlüsselung', property: 'WEP (64/128bit), WPA/WPA2-PSK (TKIP, AES, CCMP)' },
        { category: 'Netzwerk', feature: 'WLAN Frequenzen', property: '2.4GHz 802.11b/g/n (nur nicht-PoE Version)' },
        { category: 'Netzwerk', feature: 'WiFi Antenne', property: '5 dBi WLAN SMA Antenne (nur nicht-PoE Version) [2]' },
        { category: 'Netzwerk', feature: 'WiFi Reichweite', property: 'ca. 8-12m' },
        { category: 'Netzwerk', feature: 'kostenlose DDNS Addresse', property: 'Ja' },
        { category: 'Netzwerk', feature: 'kostenlose P2P UID', property: 'Ja' },
        { category: 'Features', feature: 'Zugangsbeschränkung', property: 'Benutzername/Passwort - 3 Level Autorisierung' },
        { category: 'Features', feature: 'Bewegungserkennung', property: 'Ja (Software Analyse + PIR)' },
        { category: 'Features', feature: 'Alarm I/O', property: 'Ja (potentialfreier Alarm-Ausgang / max. 30VDC/125VAC @ 1A) [3]' },
        { category: 'Features', feature: 'Alarm Pre-Recording', property: 'Ja (3s)' },
        { category: 'Features', feature: 'Fotoserie', property: 'Ja (auf SD Karte und FTP Server)' },
        { category: 'Features', feature: 'Alarm Benachrichtigung', property: 'Email/FTP/HTTP (Alarmserver)/Push' },
        { category: 'Features', feature: 'SD Karte', property: 'Erweiterbare microSDHC Karte bis zu 32GB (8GB inklusive) [5]' },
        { category: 'Features', feature: 'Cloud Speicherplatz', property: 'Ja (optional)' },
        { category: 'Features', feature: 'Pan & Tilt', property: 'Nein' },
        { category: 'Features', feature: 'Audio', property: 'Audio-Out (Cinch)' },
        { category: 'Features', feature: 'Mikrofon', property: 'Audio In für externes Mikrofons (z.B. IN-MIKRO 380) [6]' },
        { category: 'Features', feature: 'Geplante & Alarm Aufnahmen', property: 'Ja' },
        { category: 'Hardware', feature: 'Gehäuse', property: 'IP65 Aluminium (silber/schwarz)' },
        { category: 'Hardware', feature: 'Halterung', property: 'Wand & Decken (optional) Halterung [4]' },
        { category: 'Hardware', feature: 'Betriebstemperatur', property: '-5°C bis 55°C' },
        { category: 'Hardware', feature: 'Netzspannung', property: '110V - 230V' },
        { category: 'Hardware', feature: 'Netzwerkkabel', property: '1.5m Cat5e' },
        { category: 'Hardware', feature: 'Netzteil', property: '110-240V ac @ 50-60Hz bis 12V dc und 2000mA' },
        { category: 'Hardware', feature: 'Netzstecker', property: '2,1mm Innen / 5,5mm Außen' },
        { category: 'Hardware', feature: 'Stromverbrauch', property: 'max. 13 W' },
        { category: 'Hardware', feature: 'Gewicht', property: '1260g' },
        { category: 'Hardware', feature: 'Abmessungen in [cm]', property: '10cm/10cm/25cm' },
        { category: 'Hardware', feature: 'Verpackung', property: '11cm/19cm/28.5cm' },
        { category: 'Hardware', feature: 'Zertifikate', property: 'CE, FCC, RoHS' }
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns} >

          <FilteringState />

          <SortingState
            defaultSorting={[
              { columnName: 'category', direction: 'asc' },
            ]}
          />
          <GroupingState
            defaultGrouping={[{ columnName: 'category' }]}
            defaultExpandedGroups={['Optik & Imaging']}
          />
          <SelectionState />

          <IntegratedFiltering />
          <IntegratedGrouping />
          <IntegratedSelection />
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
          <TableFilterRow />
          <TableGroupRow />
        </Grid>
      </Paper>
    );
  }
}

TechSpecGrid.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(TechSpecGrid)

























  































































































































