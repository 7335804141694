import React from 'react'
import { Link } from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Windows Software">
        <EuiText>
          <h2>SD Karten Zugriff</h2>
        <ul>
          <li><Link to="/Bewegungserkennung/SD_Karten_Zugriff/Bitkinex/">Bitkinex</Link></li>
          <li><Link to="/Bewegungserkennung/SD_Karten_Zugriff/CuteFTP/">CuteFTP</Link></li>
          <li><Link to="/Bewegungserkennung/SD_Karten_Zugriff/DownThemAll/">DownThemAll! (Firefox Addon)</Link></li>
        </ul>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox