import React from 'react'

import {
  EuiSpacer,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

import inPoESplitter from "../../../images/Quick_Installation/Power_over_Ethernet/IN-PoE_Splitter.webp"
import inPoE1000 from "../../../images/Quick_Installation/Power_over_Ethernet/IN-PoE_1000_web.webp"
import inPoE4000 from "../../../images/Quick_Installation/Power_over_Ethernet/IN-PoE_4000_web.webp"
import inPoE8000 from "../../../images/Quick_Installation/Power_over_Ethernet/IN-PoE_8000_web.webp"

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <img
                  src={inPoESplitter}
                  alt="IN-PoE Splitter"
                />
              }
              title='IN-PoE Splitter'
              description={
                <p>Nutzen Sie den PoE Splitter um Ihre WLAN Kamera per PoE mit Strom zu versorgen. Erhältlich für <a href="https://www.instar.de/accessories/poe-accessories/poe-splitter-5v.html" target="_blank" rel="noopener noreferrer">5V Kameramodelle</a> und <a href="https://www.instar.de/accessories/poe-accessories/poe-splitter-12v.html" target="_blank" rel="noopener noreferrer">12V Kameramodelle</a>.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <img
                  src={inPoE1000}
                  alt="IN-PoE 1000"
                />
              }
              title='IN-PoE 1000'
              description={
                <p>Der IN-PoE 1000 Power-over-Ethernet Injektor um Ihre PoE Geräte zu betreiben ist in <a href="http://www.instar.de/accessories/poe-accessories/in-poe1000-poe-injector-black.html" target="_blank" rel="noopener noreferrer">Schwarz</a> und <a href="http://www.instar.de/accessories/poe-accessories/in-poe1000-poe-injector-white.html" target="_blank" rel="noopener noreferrer">Weiß</a>.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <img
                  src={inPoE4000}
                  alt="IN-PoE 4000"
                />
              }
              title='IN-PoE 4000'
              description={
                <p>Der <a href="http://www.instar.de/accessories/poe-accessories/in-poe-4000-poe-switch.html" target="_blank" rel="noopener noreferrer">IN-POE 4000</a> Power-over-Ethernet Switches für 4 INSTAR PoE Kameras.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <img
                  src={inPoE8000}
                  alt="IN-PoE 8000"
                />
              }
              title='IN-PoE 8000'
              description={
                <p>Der <a href="http://www.instar.de/accessories/poe-accessories/in-poe-8000-poe-switch.html" target="_blank" rel="noopener noreferrer">IN-PoE 8000</a> Power-over-Ethernet Switches für 7 INSTAR PoE Kameras.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
