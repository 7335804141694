import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="INSTAR MQTT Broker">
        <EuiText>
          <p><strong>Nur für Full HD Kameramodelle</strong></p>
          <p>Wenn Sie eine INSTAR Full HD-Kamera verwenden, finden Sie auch unsere <a href="/de/Erweitert/INSTAR_MQTT_Broker/Home_Assistant/">MQTT Broker Integration in den Home Assistant</a>. Die MQTT-Schnittstelle ermöglicht den Zugriff auf alle Kamerafunktionen über die native MQTT-Unterstützung der Home Assistant Lovelace UI!</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

