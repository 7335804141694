import React from 'react'

import {EuiText, EuiSpacer} from '@elastic/eui'

import PowerBarGraph from './power-indoor-bar-graph'

function PowerCard() {
  return (
    <div style={{ padding: '15px',maxWidth: '1115px', margin: '2.5rem 0 2.5rem 0', boxShadow: '0 2px 3px 0 rgb(0 0 0 / 36%' }}>
      <EuiText grow={false} size="m" color="default" >
        Leistungsaufnahme [W]
      </EuiText>
      <EuiSpacer />
      <PowerBarGraph />
    </div>
  );
}


export default PowerCard
