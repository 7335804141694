import React from 'react'

import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Löschen Sie den Browser-Cache nach einem Update">
        <EuiText>
          <p>Manchmal verhält sich die Weboberfläche der Kamera nicht so wie sie soll. Sie haben Ihre Kamerasoftware aktualisiert, aber die Oberfläche zeigt immer noch die alte Versionsnummer an? Ein Update sollte neue Funktionen hinzufügen, aber diese können Sie nirgends finden? Oder lassen sich bestimmte Funktionen einfach nicht mehr einstellen? In diesem Fall kann es sein, dass Ihr Browser alte Teile der Weboberfläche gespeichert (cached) hat und sich einfach weigert diese noch einmal neu von der Kamera zu laden. Hier finden Sie Anleitungen für eine Zahl gängiger Browser, wie Sie diese veralteten Dateien entfernen und ggf. den Cache des Browsers auch gleich ganz deaktivieren können:  <Link to="/Schnell_Installation/Leeren_des_Browserverlaufs/">wie leert man den Cache</Link> (Historie / Verlauf) des Browsers nach dem Update.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

