import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Achtung">
        <EuiText>
          <p>Beachten Sie, dass die maximale Länge Ihres Email Passworts 39 Zeichen ist. Die folgenden Sonderzeichen werden nicht unterstützt: <code>& = " ` + \</code></p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

