import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'P2P',
      href: '/de/Fernzugriff/Point_to_Point/',
    },
    {
      text: 'DDNS',
      href: '/de/Fernzugriff/Der_DDNS_Dienst/',
    },
    {
      text: 'Port Weiterleitung',
      href: '/de/Fernzugriff/Port_Weiterleitung/',
    },
    {
      text: 'Mobiler Zugriff',
      href: '/de/Fernzugriff/Mobil_Zugriff/',
    },
    {
      text: 'DDNS Anbieter',
      href: '/de/Fernzugriff/DDNS_Anbieter/',
    },
    {
      text: '◄ Fernzugriff',
      href: '/de/Fernzugriff/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={11}
        aria-label="Remote access your INSTAR IP Camera"
      />
      <EuiSpacer />
    </Fragment>
  );
}