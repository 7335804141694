import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Achtung">
        <EuiText>
          <p>Die MAC-Adresse Ihres WiFi Moduls ist <u>nicht dieselbe</u> MAC-Adresse wie die LAN MAC-Adresse Ihrer Kamera! Falls Sie einen MAC-Filter in Ihrem Router verwenden, deaktivieren Sie diesen zuerst und fügen Sie die Kamera zu den vertrauenswürdigen Geräten hinzu.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

