import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class AreasTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Alarm Bereiche" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Bereiche', descriptionColumn: 'Der Haken hinter Aktiv aktiviert den entsprechenden Bereich. D.h. die Kamera beginnt innerhalb dieses Bereiches nach Bewegungen zu suchen und wird bei einer positiven Erkennung, die von Ihnen gewählten ALARMAKTIONEN auslösen. In der MENÜLEISTE über dem Livevideo finden Sie einen Shortcut um die einzelnen Bereich schnell zu aktivieren/deaktivieren, ohne das Bereiche Menü aufrufen zu müssen. Sobald eine der Zahlen 1-4 rot dargestellt wird, ist der zugehörige Bereich aktiv.' },
        { actionColumn: 'Aktivieren', descriptionColumn: 'Checken Sie den Aktivier-Switch um den jeweiligen Bereich zu aktivieren. Der Bereich wird im Live-Video, in die Videoaufnahmen als auch in den Snapshots ausgeschwärzt.' },
        { actionColumn: 'Sensitivität', descriptionColumn: 'Legen Sie hier fest, wie viele Pixel sich im Bild ändern müssen um eine ALARMAKTION auszulösen. Umso höher die Empfindlichkeit ist, desto weniger muss sich im Bildinhalt verändern um einen Alarm auszulösen. Beachten Sie bitte, dass die Empfindlichkeit auch von der Größe des Bereiches abhängt. Ein Objekt, dass 100 Pixel im Videobild ein nimmt, nimmt prozentual eine größere Fläche in einem kleinen Bereich ein, als in einem großen Bereich. Wenn Sie zu viele Fehlalarme erhalten, versuchen Sie die Bereiche größer einzuzeichnen. Wenn Ihnen die Erkennung zu unempfindlich ist, zeichnen Sie kleinere Bereiche im Bild ein.' },
        { actionColumn: 'Alles', descriptionColumn: 'Klicken Sie auf diesen Button um den ausgewählten Bereich über dem ganzen Bild aufzuspannen.' },
        { actionColumn: 'Reset', descriptionColumn: 'Bewegen Sie den ausgewählten Bereich wieder in seine Ausgangsposition.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

AreasTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(AreasTable)