import React from 'react'
import 'react-vis/dist/style.css'
import {
  FlexibleWidthXYPlot,
  XAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  DiscreteColorLegend,
  Crosshair
} from 'react-vis'

const DATA = [
  [
    { x: 'VGA', y: 56 },
    { x: '720p', y: 72 },
    { x: '1080p', y: 150 }
  ],
  [
    { x: 'VGA', y: 70 },
    { x: '720p', y: 94 },
    { x: '1080p', y: 170 }
  ]
];

export default class Example extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      crosshairValues: []
    };
  }

  render() {
    return (
      <React.Fragment>

        <div className="chart">
          <FlexibleWidthXYPlot
            onMouseLeave={() => this.setState({ crosshairValues: [] })}
            height={300}
            xType="ordinal"
            stackBy="y">

            <VerticalGridLines />

            <HorizontalGridLines />

            <XAxis />

            <VerticalBarSeries
              onNearestX={(value, { index }) =>
                this.setState({ crosshairValues: DATA.map(d => d[index]) })}
              cluster="MemoryLoad"
              color="#1274d3"
              data={DATA[0]} />

            <VerticalBarSeries
              cluster="MemoryLoad"
              color="#efac59"
              data={DATA[1]} />

            <Crosshair values={this.state.crosshairValues} />

          </FlexibleWidthXYPlot>
        </div>

        <div className="legend">
          <DiscreteColorLegend
            orientation="horizontal"
            items={[
              {
                title: '[0] 1 Kamera [MB]',
                color: '#1274d3'
              },
              {
                title: '[1] 2 Kameras [MB]',
                color: '#efac59'
              }
            ]}
          />
        </div>

      </React.Fragment>
    );
  }
}