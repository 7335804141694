import React from 'react'
import {Link} from 'gatsby'

import {
  EuiCard,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
} from '@elastic/eui'

import iPadApp from "../../../../../images/Software/iOS/InstarVision/IPad_App-MockUp.png"
import iPhoneApp from "../../../../../images/Software/iOS/InstarVision/IPhone_App-MockUp.png"


export default function softwareArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={iPadApp}
                    alt="InstarVision for iPad INSTAR IP Camera"
                  />
              }
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/iOS/InstarVision/Wizard/iPad/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        InstarVision fürs iPad
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={iPhoneApp}
                    alt="InstarVision for iPhone INSTAR IP Camera"
                  />
              }
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/iOS/InstarVision/Wizard/iPhone/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        InstarVision fürs iPhone
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
