import React from 'react'
import { Link } from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Node-RED">
        <EuiText>
         <h2>Alle Node-RED Tutorials</h2>
         <ul>
           <li><Link to="/Erweitert/Alexa_Sprachsteuerung_ohne_Cloud/">Alexa Sprachkontrolle ohne Cloud-Anbindung</Link></li>
           <li><Link to="/Erweitert/Node-RED_Alarm_Event_Timeline/">Node-RED Alarm Ereigniszeitstrahl</Link></li>
           <li><Link to="/Erweitert/Node-RED_und_IFTTT/">Node-RED und IFTTT</Link></li>
           <li><Link to="/Erweitert/Node-RED_und_MQTT/">Node-RED und MQTT</Link></li>
           <li><Link to="/Erweitert/Alarmserver_Queries_for_your_Smarthome/">Node-RED und Alarmserver Queries</Link></li>
           <li><Link to="/Erweitert/Node-RED_mit_INSTAR_Cloud_Webhook/">Node-RED und der INSTAR Cloud Webhook</Link></li>
           <li><Link to="/Erweitert/Node-RED_und_SQL-Logging/">Node-RED und SQL-Logging</Link></li>
           <li><Link to="/Erweitert/Node-RED_und_ONVIF/">Node-RED und ONVIF</Link></li>
           <li><Link to="/Erweitert/Node-RED_Dashboard_Live_Video/">Node-RED Dashboard Live Video</Link></li>
           <li><Link to="/Erweitert/Node-RED_in_Docker/">Node-RED in Docker</Link></li>
           <li><Link to="/Erweitert/Node-RED_auf_Android/">Node-RED unter Android</Link></li>
           <li><Link to="/Erweitert/INSTAR_MQTT_Broker/Node-RED/">INSTAR MQTT - Node-RED</Link></li>
         </ul>
         
         <h2>Alle Node-RED FAQs</h2>
         <ul>
           <li><Link to="/Frequently_Asked_Question/Node-RED_for_INSTAR_WQHD_MQTTv5_Cameras/">Node-Red v3 mit Ihrem WQHD INSTAR MQTTv5 Broker</Link></li>
           <li><Link to="/Frequently_Asked_Question/Mosquitto_2.0_with_Node-RED/">Mosquitto 2.0 mit Node-RED</Link></li>
           <li><Link to="/Frequently_Asked_Question/HTML5_Video_mit_Node-RED_Dashboard/">HTML5 Video mit Node-RED Dashboard</Link></li>
           <li><Link to="/Frequently_Asked_Question/ioBroker_INSTAR_Camera_Vis_with_Node-RED/">ioBroker INSTAR Kamera Vis mit Node-RED</Link></li>
           <li><Link to="/Frequently_Asked_Question/Homebridge_in_Node/">Homebridge in Node-RED</Link></li>
           <li><Link to="/Frequently_Asked_Question/INSTAR_MQTT_Alarmserver_Motion_Tracking/">INSTAR MQTT Alarmserver Bewegungsverfolgung</Link></li>
           <li><Link to="/Frequently_Asked_Question/INSTAR_MQTT_Node-RED_Self-Signed_Certificate/">INSTAR MQTT Node-RED selbstsigniertes Zertifikat</Link></li>
         </ul>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox
