import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class LogTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Camera Log" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Initialization / Server start', descriptionColumn: 'Zeitpunkt des letzten Systemstartvorgangs Beachten Sie, dass das Systemprotokoll bei jedem Neustart gelöscht wird.' },
        { actionColumn: 'Event: Camera switched to night mode', descriptionColumn: 'Ihre Kamera hat in den Schwarzweißmodus gewechselt, den IRcut-Filter zurückgezogen und die Infrarot-LEDs aktiviert.' },
        { actionColumn: 'Event: Camera switched to day mode', descriptionColumn: 'Ihre Kamera hat in den Farbmodus gewechselt, den IRcut-Filter verwendet und die Infrarot-LEDs deaktiviert.' },
        { actionColumn: 'user (admin, user or guest) has logged in (Standard Stream)', descriptionColumn: 'Login des Admins, Nutzers oder Gastes zum Live-Video-Stream.' },
        { actionColumn: 'Event: Motion detection (Area 1, 2, 3 or 4)', descriptionColumn: 'Uhrzeit eines Bewegungserkennungsereignisses in Alarmbereich 1, 2, 3 oder 4.' },
        { actionColumn: 'Record to SD', descriptionColumn: 'Eine Aufzeichnung auf die interne SD-Karte wurde durch eine Alarmaktion ausgelöst' },
        { actionColumn: 'Input Alarm', descriptionColumn: 'Zeitpunkt des Auslösealarms durch Alarmeingang - z.B. durch einen externen Sensor oder der interne PIR - Sensor (im Falle der IN-8015 HD)' },
        { actionColumn: 'Audio Alarm', descriptionColumn: 'Uhrzeit eines Alarmereignisses durch den Audioalarm Ihrer Kamera.' },
        { actionColumn: 'Event: alarm to email (snap)', descriptionColumn: 'Das Alarmereignis löste eine Alarmbenachrichtigung per E-Mail gemäß Ihren Einstellungen im Menü Alarmaktionen aus.' },
        { actionColumn: 'Event: alarm to ftp (snap or video)', descriptionColumn: 'Das Alarmereignis hat gemäß den Einstellungen im Alarm Actions Menu einen FTP-Upload von Videos oder Schnappschüssen ausgelöst, auf Ihren FTP Server.' },
        { actionColumn: 'Event: alarm to SD (snap or video)', descriptionColumn: 'Das Alarmereignis hat eine Video- oder Schnappschussaufnahme auf Ihrer SD-Karte ausgelöst, entsprechend Ihrer Einstellungen im Alarm-Aktionsmenü.' },
        { actionColumn: 'Delete', descriptionColumn: 'Löschen Sie das Systemprotokoll. Beachten Sie, dass das Systemprotokoll bei jedem Neustart gelöscht wird.' },
        { actionColumn: 'Refresh', descriptionColumn: 'Das Protokoll wird nicht aktualisiert, solange das Menü geöffnet bleibt. Klicken Sie auf Aktualisieren, um es manuell zu tun.' },
        { actionColumn: 'Ordner YYYYMMTT wird gelöscht', descriptionColumn: 'Die Löschung eines Tagesordners von der SD-Karte wurde gestartet. Dies geschieht gemäß der Einstellungen.' },
        { actionColumn: 'Ordner YYYYMMTT wurde von der SD-Karte gelöscht', descriptionColumn: 'Ein Tagesordner wurde von der SD-Karte gelöscht. Dies geschieht gemäß der Einstellungen.' }
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

LogTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(LogTable)