import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class FeatureTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "modelColumn", title: "Model" },
        { name: "lenseColumn", title: "Installed Lense" },
        { name: "lenseUpgradeColumn", title: "Lense Upgrade" },
        { name: "nightColumn", title: "Night Vision" },
        { name: "lengthColumn", title: "Body Length" }
      ],
      rows: [
        { modelColumn: 'IN-5907 HD', lenseColumn: 'Standard (ca. 45° )', lenseUpgradeColumn: 'Ja, Weitwinkel', nightColumn: '18-25m', lengthColumn: '24,8cm ohne Halterung'},
        { modelColumn: 'IN-5905 HD', lenseColumn: 'Weitwinkel (ca. 90°)', lenseUpgradeColumn: 'Nein', nightColumn: '15-20m', lengthColumn: '26.3cm mit Halterung'}
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: 920}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

FeatureTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(FeatureTable)


