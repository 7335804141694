import React from 'react'
import {Link} from 'gatsby'

import {
  EuiCard,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import qiSelect01 from "../../../../images/Outdoor_Cameras/IN-9008_HD/Quick_Installation/IN-9008-Quick-Installation-0-4_1h.webp"
import qiSelect02 from "../../../../images/Outdoor_Cameras/IN-9008_HD/Quick_Installation/IN-9008-Quick-Installation-0-4_2h.webp"
import qiSelect03 from "../../../../images/Outdoor_Cameras/IN-9008_HD/Quick_Installation/IN-9008-Quick-Installation-0-4_3h.webp"




export default function QIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              title='1-hole Sealing Ring'
              image={
                <Link to="/Aussenkameras/IN-9008_HD/Schnell_Installation/PoE_or_WiFi/">
                  <img
                    src={qiSelect01}
                    alt="1-hole Sealing Ring: to only connect the Power Cable while using a WiFi connection"
                  />
                </Link>
              }
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Aussenkameras/IN-9008_HD/Schnell_Installation/PoE_or_WiFi/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Nur WLAN
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              title='2-hole Sealing Ring'
              image={
                <Link to="/Aussenkameras/IN-9008_HD/Schnell_Installation/Ethernet/">
                  <img
                    src={qiSelect02}
                    alt="2-hole Sealing Ring: to connect the Power Cable and LAN Cable"
                  />
                </Link>
              }
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Aussenkameras/IN-9008_HD/Schnell_Installation/Ethernet/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Ethernet
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              title='3-hole Sealing Ring'
              image={
                <Link to="/Aussenkameras/IN-9008_HD/Schnell_Installation/Ethernet_and_Audio-Out/">
                  <img
                    src={qiSelect03}
                    alt="3-hole Sealing Ring: to connect the Power Cable, LAN Cable and Audio Out"
                  />
                </Link>
              }
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Aussenkameras/IN-9008_HD/Schnell_Installation/Ethernet_and_Audio-Out/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Ethernet & Audio-out
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
