import React from 'react'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="dPYGU8b_B7g"
                    title="INSTAR Cloud Camera Sets"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              title="INSTAR Cloud Camera Sets"
              footer={
                <EuiText>
                  <p>In diesem Video zeigen wir Ihnen wie Sie Ihre Überwachungskameras zu Sets zusammenfügen um so schnell und einfach die Aufnahmen eines kompletten Sets einsehen zu können. Dies macht vor allem dann Sinn, wenn Sie viele Kameras in Ihrem INSTAR Cloud Account hinzugefügt haben und verwalten.</p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}
