import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-gtag";
import { EuiButton, EuiSpacer } from '@elastic/eui';
import FWChangelog from 'components/Search/FW1440pclSearch';
import NavButtons from "components/Downloads/Outdoor_Cameras/NavButtons";
import BreadCrumbs from "components/Layout/BreadCrumbs";
import SEOHelmet from "components/Layout/SEOHelmet";
import * as React from 'react';
export default {
  Link,
  OutboundLink,
  EuiButton,
  EuiSpacer,
  FWChangelog,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};