import React, {Suspense} from 'react'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
// import LanguageToggle from './LanguageToggleEUI'
import LanguageToggle from './LanguageToggle'

import '../../assets/styles/gatsby-plugin-breadcrumb.css'

// export const BreadCrumbs = (props) => {

//   return(
//     <>
//       <LanguageToggle locationEN={props.locationEN} locationDE={props.locationDE} locationFR={props.locationFR} />
//       <Breadcrumb location={props.locationBC} crumbLabel={props.crumbLabel} />
//     </>
//   )
// }

// export default BreadCrumbs

// export const LangTog = (locationEN, locationDE, locationFR, locationBC, crumbLabel) => {
//   const [show, setShow] = React.useState(false)

//   React.useEffect(() => {
//     const timeout = setTimeout(() => {
//       setShow(true)
//     }, 100)

//     return () => clearTimeout(timeout)

//   }, [show])

//   if (!show) return null

//   return(
//     <>
//       <LanguageToggle locationEN={locationEN} locationDE={locationDE} locationFR={locationFR} />
//       <Breadcrumb location={locationBC} crumbLabel={crumbLabel} />
//     </>
//   )
// }

// export const BreadCrumbs = (props) => {

//   return(
//     <>
//       {/* <Suspense> */}
//         <LangTog
//           locationEN={props.locationEN}
//           locationDE={props.locationDE}
//           locationFR={props.locationFR}
//           location={props.locationBC}
//           crumbLabel={props.crumbLabel}
//           />
//       {/* </Suspense> */}
//     </>
//   )
// }

// export default BreadCrumbs


// fix Suspense
// https://stackoverflow.com/questions/63066974/how-to-use-react-lazy-in-gatsby
// https://dev.to/dance2die/react-suspense-doesn-t-work-with-gatsby-yet-22hi

const BreadCrumbs = (props) => {
  const [show, setShow] = React.useState(false)

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true)
    }, 1)

    return () => clearTimeout(timeout)

  }, [show])

  if (!show) return null

  return(
    <>
      <LanguageToggle locationEN={props.locationEN} locationDE={props.locationDE} locationFR={props.locationFR} />
      <Breadcrumb location={props.locationBC} crumbLabel={props.crumbLabel} />
    </>
  )
}

export default BreadCrumbs