import React from 'react'
import styled from '@emotion/styled';

import in9408WQHD from '../../../../../images/Outdoor_Cameras/IN-9408_WQHD/Quick_Installation/9408er.jpg'

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  container {
    cursor: pointer;
    margin: 15px 0 25px 0;
  }
  img {
    width: 218px;
    height: 200px;
    padding: 3px;
    border: 1px solid #ededed;
  }
  h2 {
    font-size: 15px;
    color: black;
    margin-bottom: 0.3em;
  }
`;

const TableOfContent = () => {
  return (
    <React.Fragment>
        <h2>IN-9408 2k+ WLAN</h2>
        <ButtonWrapper>
            <container>
                <a target="_blank" rel="nofollow" rel="noreferrer" href="https://install.instar.com/WQHDv2/IN-9408_WQHD_Installation">
                    <img src={in9408WQHD} />
                </a>
            </container>
            <container>
                <ul>
                    <li><a target="_blank" rel="nofollow" rel="noopener" href="https://install.instar.com/WQHDv2/IN-9408_WQHD_Installation/01_LAN_Integration">Konfiguration über LAN</a></li>
                    <li><a target="_blank" rel="nofollow" rel="noopener" href="https://install.instar.com/WQHDv2/IN-9408_WQHD_Installation/02_WLAN_WPS_Integration">Konfiguration über WLAN</a></li>
                    <li><a target="_blank" rel="nofollow" rel="noopener" href="https://install.instar.com/WQHDv2/IN-9408_WQHD_Installation/03_WiFi_Assembly_Instruction">Montage über WLAN</a></li>
                    <li><a target="_blank" rel="nofollow" rel="noopener" href="https://install.instar.com/WQHDv2/IN-9408_WQHD_Installation/04_LAN_Assembly_Instruction">Montage über LAN</a></li>
                    <li><a target="_blank" rel="nofollow" rel="noopener" href="https://install.instar.com/WQHDv2/IN-9408_WQHD_Installation/05_LAN_Audio_Assembly_Instruction">Montage über LAN mit ext. Audio</a></li>              
                </ul>
            </container>
        </ButtonWrapper>
        <h2>IN-9408 2k+ PoE</h2>
        <ButtonWrapper>
            <container>
                <a target="_blank" rel="nofollow" rel="noopener" href="https://install.instar.com/WQHDv2/IN-9408PoE_WQHD_Installation">
                    <img src={in9408WQHD} />
                </a>
            </container>
            <container>
                <ul>
                    <li><a target="_blank" rel="nofollow" rel="noopener" href="https://install.instar.com/WQHDv2/IN-9408PoE_WQHD_Installation/01_LAN_PoE_Integration">Konfiguration über LAN (PoE)</a></li>
                    <li><a target="_blank" rel="nofollow" rel="noopener" href="https://install.instar.com/WQHDv2/IN-9408PoE_WQHD_Installation/02_LAN_Integration">Konfiguration über LAN (Netzteil)</a></li>
                    <li><a target="_blank" rel="nofollow" rel="noopener" href="https://install.instar.com/WQHDv2/IN-9408PoE_WQHD_Installation/03_LAN_PoE_Assembly_Instruction">Montage über LAN (PoE)</a></li>
                    <li><a target="_blank" rel="nofollow" rel="noopener" href="https://install.instar.com/WQHDv2/IN-9408PoE_WQHD_Installation/04_LAN_Assembly_Instruction">Montage über LAN (Netzteil)</a></li>
                    <li><a target="_blank" rel="nofollow" rel="noopener" href="https://install.instar.com/WQHDv2/IN-9408PoE_WQHD_Installation/05_LAN_Audio_Assembly_Instruction">Montage über LAN mit ext. Audio</a></li>
                </ul>
            </container>
        </ButtonWrapper>
    </React.Fragment>
  )
};

export default TableOfContent;