import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class ActionTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Objekt Erkennung" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Objekt', descriptionColumn: 'Wählen Sie das Objekt - Person, Fahrzeug oder Tier - aus, für das Sie die Erkennungsschwelle (Erkennungssicherheit) und den Begrenzungsrahmen anpassen möchten.' },
        { actionColumn: 'Begrenzungsrahmen anzeigen', descriptionColumn: 'Zeigt die Begrenzungsrahmen - minimale und maximale Größe - für das ausgewählte Objekt an. Verwenden Sie Ihre Maus, um den äußeren und inneren Rahmen auf die gewünschte Größe zu ziehen oder zu schieben. Im obigen Beispiel muss ein Objekt - das als Person erkannt wurde - größer als der innere Rahmen und kleiner als der äußere Rahmen sein, um einen Alarm auszulösen.' },
        { actionColumn: 'Erkennungsschwelle', descriptionColumn: 'Der Objekterkennungsalgorithmus gibt einen Prozentwert aus, der angibt, wie sicher er ist, dass es sich bei dem erkannten Objekt tatsächlich um eine Person, ein Fahrzeug oder ein Tier handelt. Stellen Sie hier den Schwellenwert ein, um festzulegen, wie sicher der Algorithmus sein muss, um einen Alarm auszulösen.' }
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

ActionTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(ActionTable)