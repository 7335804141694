import React from 'react'
import {Link} from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-gtag'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText
} from '@elastic/eui'

import qiSelect01 from "../../../../../../images/Outdoor_Cameras/IN-9008_HD/Quick_Installation/PoE_or_WiFi/WPS/QI-P2P_Tile_9008_Step_06.webp"
import qiSelect02 from "../../../../../../images/Outdoor_Cameras/IN-9008_HD/Quick_Installation/PoE_or_WiFi/WPS/Quickinstallation_Tile_9008_Step_10.webp"
import qiSelect03 from "../../../../../../images/Outdoor_Cameras/IN-9008_HD/Quick_Installation/PoE_or_WiFi/WPS/Quickinstallation_Tile_9008_Step_11.webp"
import qiSelect04 from "../../../../../../images/Outdoor_Cameras/IN-9008_HD/Quick_Installation/PoE_or_WiFi/WPS/Quickinstallation_Tile_9008_Step_12.webp"




export default function QIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              title='II-e-2 Router WPS'
              image={
                  <img
                    src={qiSelect01}
                    alt="Aktivieren Sie den WPS-Modus Ihres Routers gleichzeitig mit der Aktivierung auf Ihrer Kamera. Bitte beziehen Sie sich auf das Handbuch Ihres Router bezüglich der WPS-Funktion."
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">Aktivieren Sie den WPS-Modus Ihres Routers gleichzeitig mit der Aktivierung auf Ihrer Kamera. Bitte beziehen Sie sich auf das Handbuch Ihres Router bezüglich der WPS-Funktion.</EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              title='II-e-3 Camera Tool'
              image={
                  <img
                    src={qiSelect02}
                    alt="Start our IP Camera Tool from the Software CD and double click on the name of the camera"
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">Starten Sie das <OutboundLink href="https://install.instar.com/WQHDv2/IN-9408_WQHD_Installation/01_LAN_Integration/03_Find_your_Camera" target="_blank" rel="noopener noreferrer">IP Camera Tool</OutboundLink> und doppelklicken Sie auf den Namen der Kamera.</EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              title='II-e-4 User Login'
              image={
                  <img
                    src={qiSelect03}
                    alt="The default administrator login is User/Password: admin / instar. Our Installation Wizard will lead you through the rest of the installation process."
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">Der Standard-Administrator-Login ist Benutzer / Passwort: <strong>admin / instar</strong>. Unser <OutboundLink href="http://install.instar.de/" target="_blank" rel="noopener noreferrer">Installation Wizard</OutboundLink> führt Sie durch den Rest des Installationsprozesses.</EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              title='II-e-5 Video Plugin'
              image={
                  <img
                    src={qiSelect04}
                    alt="You need to install the Adobe Flash Plugin to display your cameras h.264 video stream. You can use to HTML5 Stream without the need to install a Browser plugins on your machine."
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">Sie müssen jetzt das Adobe Flash Plugin installieren, um den h.264 Video-Stream anzuzeigen - UPDATE: Flash wird nicht mehr benötigt - <Link to="/Schnell_Installation/Live_Video/">HTML5 Video</Link>. Sie können alternative den <Link to="/Web_User_Interface/1080p_Serie/Multimedia/Video/">MJPEG Stream</Link> aktivieren, falls Sie nicht berechtigt sind Browser-Plugins auf Ihrem Computer zu installieren.</EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
