import React from 'react'

import {
  EuiImage,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'

import ivImage from '../../../../../images/Software/Windows/InstarVision/Webserver/Image039_cut.png'


export default function ImageCard() {

  return (
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem grow={false}>
            <EuiImage
              hasShadow
              allowFullScreen
              alt="InstarVision v2 for Windows"
              src={ivImage}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiText>
              <p>Verwenden Sie das InstarVision Surveillance Center, um Ihre Kameras in Ihrem lokalen Netzwerk oder im Internet zu veröffentlichen. Die Software bietet einen Webservice, den Sie erreichen können, indem Sie auf die lokale IP-Adresse des Servers auf einem anderen PC im selben Netzwerk zugreifen. Im angegebenen Beispiel lautet die Serveradresse <strong>192.168.1.18</strong>. Rufen Sie einfach eine andere Workstation in Ihrem Netzwerk auf, öffnen Sie den Standard-Webbrowser und geben Sie <code>http://192.168.1.18</code> ein, um die InstarVision-Webbenutzeroberfläche zu öffnen. Wenn Sie die WebUI vom Server selbst überprüfen möchten, öffnen Sie Ihren Browser und geben Sie <code>http://localhost</code> oder <code>http://127.0.0.1</code> ein.</p>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
  );
}
