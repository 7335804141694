import React from 'react'

import {Link} from 'gatsby'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiButton
} from '@elastic/eui'


export default function ImageCard() {

  return (
    <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
      <EuiFlexItem>
        <EuiCard
          textAlign="left"
          title="Bildeinstellungen"
          footer={
            <EuiFlexGroup justifyContent="flexEnd">
              <EuiFlexItem grow={false}>
                <Link to="/Software/Windows/InstarVision/Image_Adjust/">
                  <EuiButton size="s" fill>
                    mehr...
                  </EuiButton>
                </Link>
              </EuiFlexItem>
            </EuiFlexGroup>
          }>
          <EuiText>
            <p>Stellen Sie die einzelnen Bildparameter wie Helligkeit, Kontrast und Sättigung Ihrer eingebundenen Kameras ein.</p>
          </EuiText>
        </EuiCard>
        </EuiFlexItem>
      <EuiFlexItem>
        <EuiCard
          textAlign="left"
          title="About"
          footer={
            <EuiFlexGroup justifyContent="flexEnd">
              <EuiFlexItem grow={false}>
                <Link to="/Software/Windows/InstarVision/About/">
                  <EuiButton size="s" fill>
                    More
                  </EuiButton>
                </Link>
              </EuiFlexItem>
            </EuiFlexGroup>
          }>
          <EuiText>
            <p>Information about your software installation.</p>
          </EuiText>
        </EuiCard>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiCard
          textAlign="left"
          title="Aufnahme"
          footer={
            <EuiFlexGroup justifyContent="flexEnd">
              <EuiFlexItem grow={false}>
                <Link to="/Software/Windows/InstarVision/Record/">
                  <EuiButton size="s" fill>
                    mehr...
                  </EuiButton>
                </Link>
              </EuiFlexItem>
            </EuiFlexGroup>
          }>
          <EuiText>
            <p>Nutzen Sie die Software um geplante und alarmausgelöste Videos aufzunehmen und verwalten Sie Ihre FTP Uploads.</p>
          </EuiText>
        </EuiCard>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}
