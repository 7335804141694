import React from 'react'
import { OutboundLink } from 'gatsby-plugin-gtag'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiIcon
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="us9UcKaD1dw"
                    title="INSTAR Cloud Benutzer"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              footer={
                <EuiText>
                <p>In diesem Video zeigen wir Ihnen wie Sie zusätzliche Benutzer in Ihrem INSTAR Cloud Account hinzufügen können und diesen unterschiedliche Rechte zuweisen können.</p>
                <p>Auf diese Weise haben Sie die Möglichkeit manchen Benutzern alle Kameras anzeigen zu lassen und anderen wiederrum nur die relevanten.</p>
                <p>Gerade für Firmen ist dies interessant da Sie so unterschiedlichen Mitarbeitern Zugriff auf Ihren Account geben können ohne dabei Angst haben zu müssen, das der Benutzer irgendwelche Einstellungen verändern kann. Auf <OutboundLink href="https://www.youtube.com/watch?v=us9UcKaD1dw" target="_blank" rel="noopener noreferrer">Youtube ansehen <EuiIcon type="popout" /></OutboundLink></p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}