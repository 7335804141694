import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Anmerkung">
        <EuiText>
          <p>Wenn Sie bei IN-6014 HD einen externen Sensor (oder den internen PIR-Sensor) verwenden, müssen Sie entweder die interne Bewegungserkennung (Alarmbereiche) deaktivieren oder mit dem Alarmeingang koppeln! Der Alarm kann sonst nicht ausgelöst werden.<br/><br/>Wenn Sie beide Erkennungsmethoden gekoppelt haben, müssen Sie beide deaktivieren, um die Bewegungserkennung zu deaktivieren!</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

