import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class WebUITable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "WebUI Einstellungen" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Snapshot', descriptionColumn: 'Verwenden Sie die Kachel Snapshot, um einen neuen Schnappschuss aus dem Live-Video Ihrer Kamera zu erstellen und in Ihrem Download-Ordner zu speichern.' },
        { actionColumn: 'Aufnehmen', descriptionColumn: 'Starten und stoppen Sie manuell eine Videoaufnahme auf der internen SD-Karte Ihrer Kamera.' },
        { actionColumn: 'IR-LEDs', descriptionColumn: 'Manuelles Aktivieren oder Deaktivieren der IR-LEDs Ihrer Kamera. Die IR-LEDs schalten sich nur ein, wenn es dunkel ist.' },
        { actionColumn: 'Alarm Emails', descriptionColumn: 'Aktivieren oder deaktivieren Sie die Alarmbenachrichtigung per E-Mail.' },
        { actionColumn: 'Meldungen', descriptionColumn: 'Überprüfen Sie die letzten Benachrichtigungen, die die WebUI in der oberen rechten Ecke des Browserfensters anzeigt.' },
        { actionColumn: 'Update', descriptionColumn: 'Verwenden Sie den Auto-Updater, um die Firmware Ihrer Kamera zu aktualisieren, sobald ein Update verfügbar ist.' },
        { actionColumn: 'Zum Shop', descriptionColumn: 'Fügen Sie einen Link zum INSTAR-Webshop hinzu.' },
        { actionColumn: 'Tour', descriptionColumn: 'Wenn Sie eine  Schwenk- und Neigetour für Ihre Kamera programmiert haben, können Sie die Tour-Kachel verwenden, um sie auszulösen.' },
        { actionColumn: 'Sprache', descriptionColumn: 'Legen Sie die Sprache fest, in der die WebUI angezeigt wird.' },
        { actionColumn: 'WebUI Modus', descriptionColumn: 'Manuelles Umschalten zwischen dem dunklen und dem hellen WebUI-Anzeigemodus.' },
        { actionColumn: 'INSTAR Cloud', descriptionColumn: 'Fügen Sie einen Link zu Ihrem optionalen INSTAR Cloud-Konto hinzu.' },
        { actionColumn: 'Vollbild', descriptionColumn: 'Schaltet den Vollbildmodus der webUIs um.' },
        { actionColumn: 'Object Tracking', descriptionColumn: 'Wenn Sie die Objekterkennung aktiviert haben, können Sie diese Kachel verwenden, um erkannte Objekte im Live-Videostream Ihrer Kamera anzuzeigen.' },
        { actionColumn: 'Mein Konto', descriptionColumn: 'Rufen Sie Ihr Benutzerkonto Konfigurationsmenü auf.' },
        { actionColumn: 'Play', descriptionColumn: 'Klicken Sie auf diese Kachel, um das Live-Video Ihrer Kamera anzuhalten oder fortzusetzen.' },
        { actionColumn: 'Lautlos', descriptionColumn: 'Klicken Sie auf diese Kachel, um das Mikrofon der Kamera stumm zu schalten oder die Stummschaltung aufzuheben.' },
        { actionColumn: 'Digitaler Zoom', descriptionColumn: 'Wenn Ihre Kamera nicht über ein optisches Zoomobjektiv verfügt, können Sie diesen digitalen Zoom verwenden, um Bereiche im Live-Video Ihrer Kamera zu vergrößern.' },
        { actionColumn: 'Alarm', descriptionColumn: 'Verwenden Sie diese Kachel, um alle Alarme zu pausieren - Bewegung, Geräusche, PIR, usw. Wenn der Alarm pausiert ist, wird ein rotes Glockensymbol auf dem Live-Video Ihrer Kamera angezeigt.' },
        { actionColumn: 'Relais', descriptionColumn: 'Wenn Ihr Kameramodell über ein Alarmausgangsrelais verfügt, können Sie diese Kachel verwenden, um dieses Relais manuell zu öffnen oder zu schließen.' },
        { actionColumn: 'Bereiche Zeichnen', descriptionColumn: 'Genau wie die Objektverfolgungskachel, die eine Überlagerung der erkannten Objekte im Live-Video Ihrer Kamera ermöglicht, können Sie diese Kachel verwenden, um den Alarmbereich anzuzeigen, der die letzte Bewegungserkennung ausgelöst hat. Verwenden Sie diese Funktion, um die Einrichtung Ihrer Alarmbereiche effektiv zu optimieren.' }
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

WebUITable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(WebUITable)