import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function LatestArticle() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1440p_Serie_CGI_Befehle/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_CGICommands_1440p.webp'
                    alt="1440p Serie CGI Liste"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="2K+ Serie CGI Liste"
              description="Steuern Sie Ihre 1440p WQHD 2K+ Kamera über die HTTP-Schnittstelle"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/1440p_Serie_CGI_Befehle/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1080p_Serie_CGI_Befehle/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_CGICommands_1080p.webp'
                    alt="1080p Serie CGI Liste"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="FullHD Serie CGI Liste"
              description="Steuern Sie Ihre 1080p FullHD Kamera über die HTTP-Schnittstelle"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/1080p_Serie_CGI_Befehle/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/720p_Serie_CGI_Befehle/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_CGICommands_720p.webp'
                    alt="720p Serie CGI Liste"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="HD Serie CGI Liste"
              description="Steuern Sie Ihre 720p HD Kamera über die HTTP-Schnittstelle"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/720p_Serie_CGI_Befehle/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Erweitert/CGI_Befehle/VGA_Serie_CGI_Befehle/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_CGICommands_VGA.webp'
                    alt="VGA Serie CGI Liste"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="VGA Serie CGI Liste"
              description="Steuern Sie Ihre 480p VGA Kamera über die HTTP-Schnittstelle"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Erweitert/CGI_Befehle/VGA_Serie_CGI_Befehle/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
