import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class ONVIFTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "ONVIF Einstellungen" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'ONVIF Protokoll aktivieren', descriptionColumn: 'Aktivieren oder deaktivieren Sie den ONVIF Service Ihrer Kamera.' },
        { actionColumn: 'ONVIF Authentifizierung', descriptionColumn: 'Sollten Sie Probleme haben die Kamera in eine Drittanbieter Anwendung über den ONVIF Dienst einzubinden, können Sie hier einmal probieren testweise die Authentifizierung zu deaktivieren. In diesem Fall sollte man den ONVIF Port nicht im Router durchleiten, um unerwünschte Zugriffe auf die Kamera zu vermeiden.' },
        { actionColumn: 'Verwenden Sie die ONVIF-Gerätezeiteinstellung', descriptionColumn: 'Mit dem ONVIF-Protokoll können Sie die Datums- und Uhrzeiteinstellung Ihrer Kamera anpassen. Wenn Sie z. B. einen ONVIF NVR-Videorekorder oder eine Aufnahmesoftware verwenden, die über ONVIF mit Ihrer Kamera verbunden ist, können Sie diesem Gerät oder dieser Software erlauben, die Uhrzeit in Ihrer Kamera einzustellen. Auf diese Weise können Sie sicherstellen, dass alle Ihre Kameras synchron sind.' },
        { actionColumn: 'ONVIF Port', descriptionColumn: 'Dies ist der Port über den Ihre Kamera über das ONVIF Protokoll. ansprechbar ist. Es wird empfohlen diesen auf dem Standardwert von 8080 zu belassen. Achten Sie bitte darauf nicht den LAN, RTMP oder RTSP Port Port auf den gleichen Wert zu legen!' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

ONVIFTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(ONVIFTable)