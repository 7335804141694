import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class ConfigTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "parameterColumn", title: "Parameter" },
        { name: "valueColumn", title: "Description" }
      ],
      rows: [
        { parameterColumn: 'Anwendung', valueColumn: 'Es gibt eine Reihe von Voreinstellungen, aus denen Sie wählen können. Wie z. B. _HTTP Server_, _Webserver_ oder andere. Wir empfehlen, jedes Mal eine eigene Regel zu erstellen, anstatt eine voreingestellte Regel zu verwenden.' },
        { parameterColumn: 'Name', valueColumn: 'Dies ist nur wichtig, wenn Sie mehr als eine Kamera verwenden, damit Sie später zwischen den verschiedenen Kameras unterscheiden können. Sie können sie z. B. mit dem Kameranamen oder dem Kamerastandort benennen.' },
        { parameterColumn: 'Protokoll', valueColumn: 'Die Kamera benötigt nur das TCP-Protokoll. Sie müssen den UDP-Verkehr nicht weiterleiten.' },
        { parameterColumn: 'Von (externer) Port', valueColumn: 'Dies ist der externe (WAN-)Port, mit dem Sie später über das Internet auf die Kamera zugreifen können. Wenn Sie nur eine Kamera verwenden, ist es am besten, ihn auf 80 einzustellen. Wenn Sie mehr als eine Kamera verwenden, müssen Sie den Port für jede Kamera erhöhen. So würde die zweite Kamera zum Beispiel den Port 8081 verwenden.' },
        { parameterColumn: 'Zu (externer) Port', valueColumn: 'Einige Router verwenden möglicherweise Port 80 als Service-Port. In diesem Fall müssen Sie für die erste Kamera Port 8081 verwenden.' },
        { parameterColumn: 'IP Adresse', valueColumn: 'Hier müssen Sie die IP-Adresse der Kamera eintragen.' },
        { parameterColumn: 'Zu (interner) Port', valueColumn: 'Dies ist der interne Port der Kamera, den wir zuvor eingerichtet haben. Wenn Sie einen anderen Port als 80 in Ihrem Netzwerk verwenden, müssen Sie ihn hier entsprechend einstellen. Wir empfehlen in diesem Moment Port 80 zu verwenden.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

ConfigTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(ConfigTable)