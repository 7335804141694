import React, {useState} from 'react'
import Image from './Image'

export default function Card(props) {

    const [state, setState] = useState("vergleich");
    
    return (
        
      <div className="card" onClick= {
            () => {setState(state === "vergleich" ? "entfernen":"vergleich");
            (state === "vergleich") ? props.compare(props.specifications):props.entfernen(props.specifications)}
        }>
            
        <div className={`card_overlay ${state === "entfernen" ? "compare-item":""}`}>
            <div className="vergleich">{state}</div>
        </div>

        <Image src={props.image} info={props.name}/>

      </div>
    )
}
