import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class FeatureTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "numberColumn", title: "#" },
        { name: "componentColumn", title: "Komponente" },
        { name: "functionColumn", title: "Funktion" },
      ],
      rows: [
        { numberColumn: '01', componentColumn: 'Helligkeitssensor', functionColumn: 'Dämmerungsschalter für IR-LEDs' },
        { numberColumn: '02', componentColumn: 'Objektiv', functionColumn: 'Weitwinkel (Brennweite: 4,3mm / Blickwinkel: ~ 90º dia.)' },
        { numberColumn: '03', componentColumn: 'Infrarot-LEDs', functionColumn: '10 IR-LEDs (Nachtsicht @ 850nm)' },
        { numberColumn: '04', componentColumn: 'Status LEDs', functionColumn: 'Rot: Power-LED; Blau: Netzwerkstatus-LED. Beide können deaktiviert werden. (s. LED Modi, unten)' },
        { numberColumn: '05', componentColumn: 'PIR-Wärmesensor', functionColumn: 'Integrierter Passiv-Infrarot (PIR) Bewegungsmelder' },
        { numberColumn: '06', componentColumn: 'Lautsprecher', functionColumn: 'Integrierter Lautsprecher' },
        { numberColumn: '07', componentColumn: 'MicroSD-Einschub', functionColumn: 'Für MicroSD/SDXC Speicherkarten (max. 256GB - 32GB inkl.)' },
        { numberColumn: '08', componentColumn: 'Mikrofon', functionColumn: 'Für Geräuscherkennung, Audioaufnahme u. Zwei-Wege-Audio' },
        { numberColumn: '09', componentColumn: 'Stromanschluss', functionColumn: '5V / 2A DC - Anschluss Ø : 1,35mm (innen) / 3,5mm (außen)' },
        { numberColumn: '10', componentColumn: 'WPS / Reset', functionColumn: '3 Sekunden für WPS-WLAN-Setup / 20 Sek. für Reset' },
        { numberColumn: '11', componentColumn: 'Alarm In/Out', functionColumn: 'Eingang für potentialfreie externe Alarmsensoren Ausgang (Relais) unterstützt bis zu max. 24V/1A' },
        { numberColumn: '12', componentColumn: 'Antennenanschluss', functionColumn: 'Für RP-SMA WLAN-Antennen (nicht für PoE Version)' },
        { numberColumn: '13', componentColumn: 'Netzwerkanschluss', functionColumn: 'RJ45-LAN-Anschluss für Cat5e LAN-Kabel oder höher' },
        { numberColumn: '14', componentColumn: 'Audioausgang', functionColumn: '3,5mm Kopfhörer / aktiver Lautsprecher-Anschluss' },
        { numberColumn: '15', componentColumn: 'Stativgewinde', functionColumn: 'Gewinde für mitgelieferte Halterung (1/4”-20 UNC)' },
        { numberColumn: '16', componentColumn: 'Aufkleber', functionColumn: 'P2P-ID, LAN-MAC-Adresse und Seriennummer' }
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

FeatureTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(FeatureTable)