import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="INSTAR Homekit">
        <EuiText>
          <p>
            Verwenden Sie Ihre INSTAR Full HD IP-Kamera in Apple Homekit. HomeKit ist ein Software-Framework von Apple, das in iOS/iPadOS zur Verfügung gestellt wird und mit dem Sie mit Ihrer Kamera über Apple-Geräte kommunizieren und diese steuern können. Es bietet Ihnen eine Möglichkeit, Ihre Kamera automatisch zu erkennen und auf ihr Live-Video zuzugreifen.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

