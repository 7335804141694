import { EuiSpacer } from '@elastic/eui';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/SystemPerm';
import Successbox from 'components/Web_User_Interface/1440p_Series/Smarthome/Google_Nest/SuccessBox';
import * as React from 'react';
export default {
  EuiSpacer,
  BreadCrumbs,
  SEOHelmet,
  PermissionBox,
  Successbox,
  React
};