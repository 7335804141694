import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class SDTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "SD Karte" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Maximale Aufbewahrungszeit', descriptionColumn: 'Legen Sie fest, ob die Kamera immer den ältesten Ordner auf der SD Karte löschen soll, oder ob die Video- und Bildaufnahmen nur eine begrenzte Zeit speichern wollen. Sollte die Kapazität der Karte erreicht werden, bevor diese Zeitspanne abgelaufen ist, wird weiterhin immer der älteste Ordner gelöscht um Platz zu schaffen.' },
        { actionColumn: 'SD Ordner öffnen', descriptionColumn: 'Ein Klick auf den Ordner-Button wird Sie durch das SD-Karten Verzeichnis führen, wo all Ihre Snapshots und Videoaufnahmen abliegen. Nutzen Sie den Admin-Login der Kamera, um auf das Verzeichnis zuzugreifen. Es gibt auch bequemere Wege, um auf die Aufnahmen zuzugreifen. Zum Beispiel über Software von Drittanbietern auf Ihre Aufnahmen zuzugreifen oder unsere Handy App InstarVision für iPhone, iPad, Android, Windows Phone und Windows Metro.' },
        { actionColumn: 'SD-Karte auswerfen', descriptionColumn: 'Unmounten Sie die SD-Karte bevor Sie die Karte aus der Kamera ziehen. Das Betriebssystem der Kamera wird in regelmäßigen Zeitabständen versuchen, die Karte wieder bereitzustellen.' },
        { actionColumn: 'SD-Karte formatieren', descriptionColumn: 'Ihre Kamera wird den ältesten Ordner automatisch löschen, sobald die Speicherkapazität der Karte erreicht ist. So ist immer genug Platz für neue Aufnahmen verfügbar! Nutzen Sie den "Formatierungs"-Befehl, wenn Sie Ihre Aufnahmen auf der Kamera löschen möchten, oder wenn das Filesystem der Kamera korrupt ist. Dies kann nach einem Neustart vorkommen oder wenn Sie die SD-Karte der Kamera entfernen, während noch Dateien auf die Karte geschrieben werden.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

SDTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(SDTable)