import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class DateTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Datum & Uhrzeit Einstellungen" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Aktuelle System Uhrzeit Ihrer Kamera', descriptionColumn: 'Interne Zeit, die im Betriebssystem Ihrer Kamera eingestellt ist. Diese Zeit wird für alle Alarm- und Aufzeichnungszeitpläne sowie für den Zeitstempel verwendet, der für Dateinamen verwendet wird.' },
        { actionColumn: 'Zeitzone', descriptionColumn: 'Hier können Sie die Zeitzone auswählen, in der sich die Kamera befindet - im Fall von Deutschland wäre dies GMT+1.' },
        { actionColumn: 'Mit PC synchronisieren', descriptionColumn: 'Wenn Ihre Kamera keinen Zugang zum Internet hat, können Sie diese Funktion nutzen, um die Zeiteinstellung Ihres Computers mit der Kamera zu synchronisieren. Dies wird nicht empfohlen, da Sie diesen Vorgang regelmäßig wiederholen müssen, um die interne Uhr genau zu halten.' },
        { actionColumn: 'Automatische NTP/NTS Synchronisierung', descriptionColumn: 'Diese Funktion synchronisiert die interne Uhr Ihrer Kamera mit einem Internet-Zeitserver (NTP/S). Es wird empfohlen, diese Funktion zu verwenden, um die Uhrzeit der Kamera genau zu halten.' },
        { actionColumn: 'Sicheres NTP (NTS)', descriptionColumn: 'Aktivieren Sie diese Option, um die sichere NTP TLS-Verschlüsselung zu verwenden.' },
        { actionColumn: 'NTP/S Server', descriptionColumn: 'Wählen Sie einen NTP- oder NTS-Server aus, mit dem die Systemzeit Ihrer Kamera synchronisiert werden soll.' },
        { actionColumn: 'Custom Server', descriptionColumn: 'Für geschlossene Unternehmensnetze müssen Sie möglicherweise einen eigenen internen NTP/NTS-Server einrichten.' }
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

DateTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(DateTable)