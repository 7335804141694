import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class FeatureTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "numberColumn", title: "#" },
        { name: "componentColumn", title: "Komponente" },
        { name: "functionColumn", title: "Funktion" },
      ],
      rows: [
        { numberColumn: '01', componentColumn: 'Photosensor', functionColumn: 'Twilight switch for IR-LEDs' },
        { numberColumn: '02', componentColumn: 'Infrared-LEDs', functionColumn: '6 IR-LEDs (@ 940nm) - invisible to the human eye' },
        { numberColumn: '03', componentColumn: 'Objektiv', functionColumn: 'Wide-angle (focal length: 4.3mm / field of view: ~ 90º dia.)' },
        { numberColumn: '04', componentColumn: 'Mikrofon', functionColumn: 'For noise detection / audio rec.' },
        { numberColumn: '05', componentColumn: 'PIR-Sensor', functionColumn: 'Integrated passive infrared (PIR) motion detector' },
        { numberColumn: '06', componentColumn: 'Speaker', functionColumn: 'Integrated loudspeaker' },
        { numberColumn: '07', componentColumn: 'Antenna', functionColumn: 'Built-in 3dB Antenna' },
        { numberColumn: '08', componentColumn: 'Camera Label', functionColumn: 'P2P ID, LAN MAC address and serial number' },
        { numberColumn: '09', componentColumn: 'Status LED', functionColumn: 'Red: Power; Blue: Network Status; Both can be disabled.' },
        { numberColumn: '10', componentColumn: 'WPS / Reset', functionColumn: '3 seconds for quick WiFi setup / 15-20 seconds for reset' },
        { numberColumn: '11', componentColumn: 'SD Card Reader', functionColumn: 'Slot for MicroSD/SDXC cards (max. 128GB - 16GB included) - inside the camera' },
        { numberColumn: '12', componentColumn: 'Mounting Thread / Bracket', functionColumn: 'The bracket can be used as wall mount or ceiling mount when connected to either one of	the two mounting threads' },
        { numberColumn: '13', componentColumn: 'Netzteilanschluss', functionColumn: 'For included 5V / 1.5A Power Supply' },
        { numberColumn: '14', componentColumn: 'Netzwerkanschluss', functionColumn: 'RJ45 LAN port / connector for Cat5e LAN cable or higher' },
        { numberColumn: '15', componentColumn: 'Screw Lock', functionColumn: 'For desk-top, wall and ceiling installation' },
        { numberColumn: '16', componentColumn: 'Built-in Magnet', functionColumn: 'For wall mount use together with metal mounting plate' }
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

FeatureTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(FeatureTable)


// import React from 'react'
// import {Table, Column, Cell} from 'fixed-data-table-2'
// import '../../../../assets/styles/data_tables/fixed-data-table.css'

// const numberRow = [
//   "01",
//   "02",
//   "03",
//   "04",
//   "05",
//   "06",
//   "07",
//   "08",
//   "09",
//   "10",
//   "11",
//   "12",
//   "13",
//   "14",
//   "15",
//   "16",
// ];

// const componentRow = [
//   "Photosensor",
//   "Infrared-LEDs",
//   "Lense",
//   "Microphone",
//   "PIR-Sensor",
//   "Speaker",
//   "Antenna",
//   "Camera Label",
//   "Status LED",
//   "WPS / Reset",
//   "SD Card Reader",
//   "Mounting Thread / Bracket",
//   "Power Connector",
//   "Network Port",
//   "Screw Lock",
//   "Built-in Magnet"
// ];

// const functionRow = [
//   "Twilight switch for IR-LEDs",
//   "6 IR-LEDs (@ 940nm) - invisible to the human eye",
//   "Wide-angle (focal length: 4.3mm / field of view: ~ 90º dia.)",
//   "For noise detection / audio rec.",
//   "Integrated passive infrared (PIR) motion detector",
//   "Integrated loudspeaker",
//   "Built-in 3dB Antenna",
//   "P2P ID, LAN MAC address and serial number",
//   "Red: Power; Blue: Network Status; Both can be disabled.",
//   "3 seconds for quick WiFi setup / 15-20 seconds for reset",
//   "Slot for MicroSD/SDXC cards (max. 128GB - 16GB included) - inside the camera",
//   "The bracket can be used as wall mount or ceiling mount when connected to either one of	the two mounting threads",
//   "For included 5V / 1.5A Power Supply",
//   "RJ45 LAN port / connector for Cat5e LAN cable or higher",
//   "For desk-top, wall and ceiling installation",
//   "For wall mount use together with metal mounting plate"
// ];

// // Render your table
// const FeatureTable = () => {
//     return (
//       <Table
//         rowHeight={55}
//         rowsCount={numberRow.length}
//         touchScrollEnabled={true}
//         width={850}
//         maxHeight={1000}
//         headerHeight={50}>
//         <Column
//           header={<Cell>#</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {numberRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={1}
//           width={50}
//         />
//         <Column
//           header={<Cell>Component</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {componentRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={2}
//           width={250}
//         />
//         <Column
//           header={<Cell>Function</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {functionRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={3}
//           width={535}
//         />
//       </Table>
//     )
// }

// export default FeatureTable