import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class FTPTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "FTP Dienst" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'FTP Server', descriptionColumn: 'Tragen Sie hier die Adresse Ihres FTP Servers ein. Dabei kann es sich um einen Webserver handeln, aber auch um einen lokalen Server oder z.B. um Ihren Router oder NAS.' },
        { actionColumn: 'FTP Port', descriptionColumn: 'Der Standard Port für den FTP Dienst ist der Port 21. Wenn Sie Ihren FTP Server anders konfiguriert haben, geben Sie hier bitte den entsprechenden Port an.' },
        { actionColumn: 'Modus', descriptionColumn: 'In den meisten Fällen werden Sie hier den passiven (PASV) Modus wählen können. Wählen Sie den PORT Modus nur, wenn PASV fehlschlägt. Dies wird z.B. passieren, wenn sich der FTP Server hinter einer NAT Firewall befindet.' },
        { actionColumn: 'FTP Verzeichnis', descriptionColumn: 'Tragen Sie hier den Unterordner, relativ zum root-Verzeichnis Ihres FTP Benutzers, ein, in dem Ihre Kamera die Dateien ablegen soll. Ein Beispiel anhand eines FTP Servers auf der AVM Fritzbox finden Sie hier.' },
        { actionColumn: 'FTP Ordnerstruktur', descriptionColumn: 'Wenn Ihre Kamera viele Dateien auf den FTP Server hochläd, werden Sie wollen, dass diese jeden Tag in einen neuen Ordner abgelegt werden. Sollten Sie Probleme mit dieser Einstellung haben, probieren Sie einmal die alternative Einstellung, alle Dateien in den gleichen Ordner zu legen und beachten Sie bitte den Hinweis (unten).' },
        { actionColumn: 'FTP Benutzername', descriptionColumn: 'Geben Sie hier den Benutzernamen an, mit dem Sie auf den FTP Server zugreifen können.' },
        { actionColumn: 'FTP Kennwort', descriptionColumn: 'Geben Sie hier das Kennwort an, mit dem Sie auf den FTP Server zugreifen können.' },
        { actionColumn: 'Test', descriptionColumn: 'Mit dem Test wird ein Testbild des Kamera Livestreams auf Ihren FTP Server geladen. Der Name der Datei setzt sich aus dem Großbuchstaben T und einem Zeitstempel zusammen.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

FTPTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(FTPTable)