import React from 'react'
import { Link } from 'gatsby'

import {
  EuiSpacer,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

import mqttHiveMQCardIcon from '../../../images/Advanced_User/INSTAR_MQTT_Broker/toc_Card_hivemq.webp'
import mqttMoszCardIcon from '../../../images/Advanced_User/INSTAR_MQTT_Broker/toc_Card_mosquitto.webp'
import mqttCloudMqttCardIcon from '../../../images/Advanced_User/INSTAR_MQTT_Broker/toc_Card_cloudmqtt.webp'

export default function LatestArticle() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Erweitert/INSTAR_MQTT_Broker/HiveMQ/">
                  <img
                    src={mqttHiveMQCardIcon}
                    alt="INSTAR MQTT - HiveMQ"
                  />
                </Link>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Erweitert/INSTAR_MQTT_Broker/Mosquitto/">
                  <img
                    src={mqttMoszCardIcon}
                    alt="INSTAR MQTT - Mosquitto"
                  />
                </Link>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Erweitert/INSTAR_MQTT_Broker/Cloud_MQTT/">
                  <img
                    src={mqttCloudMqttCardIcon}
                    alt="INSTAR MQTT - Cloud MQTT"
                  />
                </Link>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
