import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/styles'
import {
  SortingState, SelectionState, FilteringState, GroupingState,
  IntegratedFiltering, IntegratedGrouping, IntegratedSelection,
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table, TableHeaderRow, TableFilterRow, TableGroupRow,
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class MQTT2CGIGrid extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
        columns: [
          { name: "category", title: "Kategorie" },
          { name: "feature", title: "Wert" },
          { name: "property", title: "CGI Befehl" }
        ],
        rows: [
          { category: 'Network', feature: 'DHCP: {"val":"on"}, {"val":"off"}', property: 'param.cgi?cmd=setnetattr&-dhcp=[val]' },
          { category: 'Network', feature: 'IP Address: {"val":"192.168.178.23"}', property: 'param.cgi?cmd=setnetattr&-ipaddr=[val]' },
          { category: 'Network', feature: 'Network Mask: {"val":"255.255.255.0"}', property: 'param.cgi?cmd=setnetattr&-netmask=[val]' },
          { category: 'Network', feature: 'Gateway: {"val":"192.168.178.1"}', property: 'param.cgi?cmd=setnetattr&-gateway=[val]' },
          { category: 'Network', feature: 'DNS Server: {"val":"192.168.178.1"}', property: 'param.cgi?cmd=setnetattr&-fdnsip=[val]' },
          { category: 'Network', feature: 'HTTP Port: {"val":"80"}', property: 'param.cgi?cmd=sethttpport&-httpport=[val]' },
          { category: 'Network', feature: 'HTTPS Port: {"val":"443"}', property: 'param.cgi?cmd=sethttpsport&-httpsport=[val]' },
          { category: 'Network', feature: 'RTSP Authentication: {"val":"1"}, {"val":"0"}', property: 'param.cgi?cmd=setrtspauth&-rtsp_aenable=[val]' },
          { category: 'Network', feature: 'RTSP Port: {"val":"554"}', property: 'param.cgi?cmd=setrtspport&-rtspport=[val]' },
          { category: 'Network', feature: 'RTMP Port: {"val":"1935"}', property: 'param.cgi?cmd=setrtmpattr&-rtmpport=[val]' },
          { category: 'Network', feature: 'WiFi: {"val":"1"}, {"val":"0"}', property: 'param.cgi?cmd=setwirelessattr&-wf_enable=[val]' },
          { category: 'Network', feature: 'WiFi SSID: {"val":"My-Network"}', property: 'param.cgi?cmd=setwirelessattr&-wf_ssid=[val]' },
          { category: 'Network', feature: 'WiFi Authentication: {"val":"0"} - {"val":"3"}', property: 'param.cgi?cmd=setwirelessattr&-wf_auth=[val]' },
          { category: 'Network', feature: 'WiFi Password: {val":"secret-password"}', property: 'param.cgi?cmd=setwirelessattr&-wf_key=[val]' },
          { category: 'Network', feature: 'WiFi Encryption: {"val":"1"}, {"val":"0"}', property: 'param.cgi?cmd=setwirelessattr&-wf_enc=[val]' },
          { category: 'Network', feature: 'DDNS Service: {"val":"1"}, {"val":"0"}', property: 'param.cgi?cmd=setourddnsattr&-our_enable=[val]' },
          { category: 'Network', feature: 'DDNS 3rd Party Service: {"val":"1"}, {"val":"0"}', property: 'param.cgi?cmd=set3thddnsattr&-d3th_enable=[val]' },
          { category: 'Network', feature: 'DDNS 3rd Party Map', property: '/tmpfs/th3ddns.dat' },
          { category: 'Network', feature: 'DDNS Primary Map', property: '/tmpfs/fddns.dat' },
          { category: 'Network', feature: 'P2P Service: {"val":"1"}, {"val":"0"}', property: 'cmd=setsmartp2pattr&-smartp2p_enable=[val]' },
          { category: 'Network', feature: 'UPnP Service: {"val":"1"}, {"val":"0"}', property: 'param.cgi?cmd=setupnpattr&-upm_enable=[val]' },
          { category: 'Network', feature: 'UPnP Map', property: '/tmpfs/upnpmap.dat' },
          { category: 'Network', feature: 'ONVIF Service: {"val":"1"}, {"val":"0"}', property: 'param.cgi?cmd=setonvifattr&-ov_enable=[val]' },
          { category: 'Network', feature: 'ONFIV Authentication: {"val":"1"}, {"val":"0"}', property: 'param.cgi?cmd=setonvifattr&-ov_authflag=[val]' },
          { category: 'Network', feature: 'ONFIV Forbit: {"val":"1"}, {"val":"0"}', property: 'param.cgi?cmd=setonvifattr&-ov_forbitset=[val]' },
          { category: 'Network', feature: 'ONVIF Port: {"val":"8080"}', property: 'param.cgi?cmd=setonvifattr&-ov_port=[val]' },
          { category: 'Network', feature: 'LAN MAC Address', property: '/param.cgi?cmd=getlanmac' },
          { category: 'Network', feature: 'WLAN MAC Address', property: '/tmpfs/wifi.mac' },
          { category: 'Network', feature: 'WLAN MAC Address', property: 'param.cgi?cmd=getwifimac' },
          { category: 'Network', feature: 'WLAN Search Networks', property: 'param.cgi?cmd=searchwireless' },
          { category: 'Network', feature: 'WLAN Set Login for Testing', property: 'param.cgi?cmd=chkwirelessattr&-wf_ssid=MySSID&-wf_auth=3&-wf_key=MyWifiKey&-wf_enc=0&-wf_mode=0' },
          { category: 'Network', feature: 'WLAN Test Login', property: 'param.cgi?cmd=getchkwireless' },
          { category: 'Network', feature: 'SSL Public Certificate', property: '/tmpfs/pcert.pem' },
          { category: 'Network', feature: 'MQTT Serice: {"val":"1;0;127.0.0.1;1883;8883;1;admin;instar"}', property: 'param.cgi?cmd=set_instar_admin&-index=32&-value=[val]' },
          { category: 'Network', feature: 'MQTT Log', property: '/tmpfs/mqtt-log' },
          { category: 'Multimedia', feature: 'Output Volume: {"val":"1"} - {"val":"100"}', property: 'param.cgi?cmd=setaudiooutvolume&-volume=[val]' },
          { category: 'Multimedia', feature: 'Input Volume: {"val":"1"} - {"val":"100"}', property: 'param.cgi?cmd=setaudioinvolume&-volume=[val]' },
          { category: 'Multimedia', feature: 'Audio 1st Stream: {"val":"1"}, {"val":"0"}', property: 'param.cgi?cmd=setaencattr&-chn=11&-aeswitch=[val]' },
          { category: 'Multimedia', feature: 'Audio 2nd Stream: {"val":"1"}, {"val":"0"}', property: 'param.cgi?cmd=setaencattr&-chn=12&-aeswitch=[val]' },
          { category: 'Multimedia', feature: 'Audio 3rd Stream: {"val":"1"}, {"val":"0"}', property: 'param.cgi?cmd=setaencattr&-chn=13&-aeswitch=[val]' },
          { category: 'Multimedia', feature: 'Bitrate 1st Stream {"val":"512"}, {"val":"1024"}, {"val":"2048"}, {"val":"3072"}, {"val":"4069"}', property: 'param.cgi?cmd=setvencattr&-chn=11&-bps=[val]' },
          { category: 'Multimedia', feature: 'Bitrate 1st Stream {"val":"256"}, {"val":"512"}, {"val":"768"}, {"val":"1024"}, {"val":"2048"}', property: 'param.cgi?cmd=setvencattr&-chn=12&-bps=[val]' },
          { category: 'Multimedia', feature: 'Bitrate 1st Stream {"val":"90"}, {"val":"128"}, {"val":"256"}, {"val":"512"}', property: 'param.cgi?cmd=setvencattr&-chn=13&-bps=[val]' },
          { category: 'Multimedia', feature: 'Compression 1st Stream {"val":"1"} - {"val":"6"}', property: 'param.cgi?cmd=setvencattr&-chn=11&-imagegrade=[val]' },
          { category: 'Multimedia', feature: 'Compression 2nd Stream {"val":"1"} - {"val":"6"}', property: 'param.cgi?cmd=setvencattr&-chn=12&-imagegrade=[val]' },
          { category: 'Multimedia', feature: 'Compression 3rd Stream {"val":"1"} - {"val":"6"}', property: 'param.cgi?cmd=setvencattr&-chn=13&-imagegrade=[val]' },
          { category: 'Multimedia', feature: 'Variable Bitrate 1st Stream {"val":"1"}, {"val":"0"}', property: 'param.cgi?cmd=setvencattr&-chn=11&-brmode=[val]' },
          { category: 'Multimedia', feature: 'Variable Bitrate 2nd Stream {"val":"1"}, {"val":"0"}', property: 'param.cgi?cmd=setvencattr&-chn=12&-brmode=[val]' },
          { category: 'Multimedia', feature: 'Variable Bitrate 3rd Stream {"val":"1"}, {"val":"0"}', property: 'param.cgi?cmd=setvencattr&-chn=13&-brmode=[val]' },
          { category: 'Multimedia', feature: 'Framerate 1st Stream {"val":"1"} - {"val":"25"}', property: 'param.cgi?cmd=setvencattr&-chn=11&-fps=[val]' },
          { category: 'Multimedia', feature: 'Framerate 2nd Stream {"val":"1"} - {"val":"25"}', property: 'param.cgi?cmd=setvencattr&-chn=12&-fps=[val]' },
          { category: 'Multimedia', feature: 'Framerate 3rd Stream {"val":"1"} - {"val":"25"}', property: 'param.cgi?cmd=setvencattr&-chn=13&-fps=[val]' },
          { category: 'Multimedia', feature: 'Keyframe Interval 1st Stream {"val":"10"} - {"val":"150"}', property: 'param.cgi?cmd=setvencattr&-chn=11&-gop=[val]' },
          { category: 'Multimedia', feature: 'Keyframe Interval 2nd Stream {"val":"10"} - {"val":"150"}', property: 'param.cgi?cmd=setvencattr&-chn=12&-fps=[val]' },
          { category: 'Multimedia', feature: 'Keyframe Interval 3rd Stream {"val":"10"} - {"val":"150"}', property: 'param.cgi?cmd=setvencattr&-chn=13&-fps=[val]' },
          { category: 'Multimedia', feature: 'WebUI Stream {"val":"11"}, {"val":"12"}, {"val":"13"}', property: 'param.cgi?cmd=set_instar_guest&-index=40&-value=[val]' },
          { category: 'Multimedia', feature: 'MJPEG Stream {"val":"true"}, {"val":"false"}', property: 'param.cgi?cmd=set_instar_admin&-index=41&-value=[val]' },
          { category: 'Multimedia', feature: 'MJPEG Stream {"val":"11"} - {"val":"13"}', property: '/mjpegstream.cgi?-chn=[val]' },
          { category: 'Multimedia', feature: 'Snapshot Size {"val":"11"} - {"val":"13"}', property: 'param.cgi?cmd=snapimage&-chn=[val]' },
          { category: 'Multimedia', feature: 'Stream Number Active', property: 'param.cgi?cmd=getstreamnum' },
          { category: 'Multimedia', feature: 'Request iFrame {"val":"11"} - {"val":"13"}', property: '/param.cgi?cmd=requestifrm&-chn=[val]' },
          { category: 'Multimedia', feature: 'Brightness {"val":"1"} - {"val":"100"}', property: 'param.cgi?cmd=setimageattr&-brightness=[val]' },
          { category: 'Multimedia', feature: 'Saturation {"val":"1"} - {"val":"225"}', property: 'param.cgi?cmd=setimageattr&-saturation=[val]' },
          { category: 'Multimedia', feature: 'HUE {"val":"1"} - {"val":"100"}', property: 'param.cgi?cmd=setimageattr&-hue=[val]' },
          { category: 'Multimedia', feature: 'Contrast {"val":"1"} - {"val":"100"}', property: 'param.cgi?cmd=setimageattr&-contrast=[val]' },
          { category: 'Multimedia', feature: 'Sharpness {"val":"1"} - {"val":"100"}', property: 'param.cgi?cmd=setimageattr&-sharpness=[val]' },
          { category: 'Multimedia', feature: 'Gamma {"val":"0"} - {"val":"3"}', property: 'param.cgi?cmd=setimageattr&-gamma=[val]' },
          { category: 'Multimedia', feature: 'Exposure {"val":"1"} - {"val":"255"}', property: 'param.cgi?cmd=setimageattr&-targety=[val]' },
          { category: 'Multimedia', feature: 'Flip {"val":"on"}, {"val":"off"}', property: 'param.cgi?cmd=setimageattr&-flip=[val]' },
          { category: 'Multimedia', feature: 'Mirror {"val":"on"}, {"val":"off"}', property: 'param.cgi?cmd=setimageattr&-mirror=[val]' },
          { category: 'Multimedia', feature: 'Auto Denoise {"val":"0"}, {"val":"1"}', property: 'param.cgi?cmd=setimageattrex&-d3noauto=[val]' },
          { category: 'Multimedia', feature: 'Manual Denoise {"val":"0"} - {"val":"255"}', property: 'param.cgi?cmd=setimageattrex&-d3noval=[val]' },
          { category: 'Multimedia', feature: 'Auto WDR {"val":"0"}, {"val":"1"}', property: 'param.cgi?cmd=setimageattrex&-wdrauto=[val]' },
          { category: 'Multimedia', feature: 'Auto WDR Strength {"val":"0"} - {"val":"2"}', property: 'param.cgi?cmd=setimageattrex&-wdrautval=[val]' },
          { category: 'Multimedia', feature: 'Manual WDR Strenghts {"val":"0"} - {"val":"255"}', property: 'param.cgi?cmd=setimageattrex&-wdrmanval=[val]' },
          { category: 'Multimedia', feature: 'Hardware WDR {"val":"0"}, {"val":"1"}', property: 'param.cgi?cmd=setvideoattr&-wdrmode=[val]' },
          { category: 'Multimedia', feature: 'Lense Corection {"val":"0"} - {"val":"399"}', property: 'param.cgi?cmd=setldcattr&-ldc_ratio=[val]' },
          { category: 'Multimedia', feature: 'Name Overlay {"val":"0"}, {"val":"1"}', property: 'param.cgi?cmd=setoverlayattr&-region=1&-show=[val]' },
          { category: 'Multimedia', feature: 'Camera Name {"val":"Office Cam"}', property: 'param.cgi?cmd=setoverlayattr&-region=1&-name=[val]' },
          { category: 'Multimedia', feature: 'Timestamp Overlay {"val":"0"}, {"val":"1"}', property: 'param.cgi?cmd=setoverlayattr&-region=0&-show=[val]' },
          { category: 'Multimedia', feature: 'Privacy Region 1 {"val":"0"}, {"val":"1"}', property: 'param.cgi?cmd=setcover&-region=1&-show=[val]' },
          { category: 'Multimedia', feature: 'Privacy Region 1 Color {"val":"000000"}', property: 'param.cgi?cmd=setcover&-region=1&-color=[val]' },
          { category: 'Multimedia', feature: 'Privacy Region 1 X-Origin {"val":"0"}', property: 'param.cgi?cmd=setcover&-region=1&-x=[val]' },
          { category: 'Multimedia', feature: 'Privacy Region 1 Y-Origin {"val":"840"}', property: 'param.cgi?cmd=setcover&-region=1&-y=[val]' },
          { category: 'Multimedia', feature: 'Privacy Region 1 Height {"val":"240"}', property: 'param.cgi?cmd=setcover&-region=1&-h=[val]' },
          { category: 'Multimedia', feature: 'Privacy Region 1 Width {"val":"240"}', property: 'param.cgi?cmd=setcover&-region=1&-w=[val]' },
          { category: 'Multimedia', feature: 'Privacy Region 2 {"val":"0"}, {"val":"1"}', property: 'param.cgi?cmd=setcover&-region=2&-show=[val]' },
          { category: 'Multimedia', feature: 'Privacy Region 2 Color {"val":"000000"}', property: 'param.cgi?cmd=setcover&-region=2&-color=[val]' },
          { category: 'Multimedia', feature: 'Privacy Region 2 X-Origin {"val":"0"}', property: 'param.cgi?cmd=setcover&-region=21&-x=[val]' },
          { category: 'Multimedia', feature: 'Privacy Region 2 Y-Origin {"val":"840"}', property: 'param.cgi?cmd=setcover&-region=2&-y=[val]' },
          { category: 'Multimedia', feature: 'Privacy Region 2 Height {"val":"240"}', property: 'param.cgi?cmd=setcover&-region=2&-h=[val]' },
          { category: 'Multimedia', feature: 'Privacy Region 2 Width {"val":"240"}', property: 'param.cgi?cmd=setcover&-region=2&-w=[val]' },
          { category: 'Multimedia', feature: 'Privacy Region 3 {"val":"0"}, {"val":"1"}', property: 'param.cgi?cmd=setcover&-region=3&-show=[val]' },
          { category: 'Multimedia', feature: 'Privacy Region 3 Color {"val":"000000"}', property: 'param.cgi?cmd=setcover&-region=3&-color=[val]' },
          { category: 'Multimedia', feature: 'Privacy Region 3 X-Origin {"val":"0"}', property: 'param.cgi?cmd=setcover&-region=3&-x=[val]' },
          { category: 'Multimedia', feature: 'Privacy Region 3 Y-Origin {"val":"840"}', property: 'param.cgi?cmd=setcover&-region=3&-y=[val]' },
          { category: 'Multimedia', feature: 'Privacy Region 3 Height {"val":"240"}', property: 'param.cgi?cmd=setcover&-region=3&-h=[val]' },
          { category: 'Multimedia', feature: 'Privacy Region 3 Width {"val":"240"}', property: 'param.cgi?cmd=setcover&-region=3&-w=[val]' },
          { category: 'Multimedia', feature: 'Privacy Region 4 {"val":"0"}, {"val":"1"}', property: 'param.cgi?cmd=setcover&-region=4&-show=[val]' },
          { category: 'Multimedia', feature: 'Privacy Region 4 Color {"val":"000000"}', property: 'param.cgi?cmd=setcover&-region=4&-color=[val]' },
          { category: 'Multimedia', feature: 'Privacy Region 4 X-Origin {"val":"0"}', property: 'param.cgi?cmd=setcover&-region=4&-x=[val]' },
          { category: 'Multimedia', feature: 'Privacy Region 4 Y-Origin {"val":"840"}', property: 'param.cgi?cmd=setcover&-region=4&-y=[val]' },
          { category: 'Multimedia', feature: 'Privacy Region 4 Height {"val":"240"}', property: 'param.cgi?cmd=setcover&-region=4&-h=[val]' },
          { category: 'Multimedia', feature: 'Privacy Region 4 Width {"val":"240"}', property: 'param.cgi?cmd=setcover&-region=4&-w=[val]' },
          { category: 'Features', feature: 'Email Sender {"val":"sender@email.com"}', property: 'param.cgi?cmd=setsmtpattr&-ma_from=[val]' },
          { category: 'Features', feature: 'Email Receiver {"val":"receiver@email.com"}', property: 'param.cgi?cmd=setsmtpattr&-ma_to=[val]' },
          { category: 'Features', feature: 'Email Subject {"val":"Alarm Email from my Office Cam"}', property: 'param.cgi?cmd=setsmtpattr&-ma_subject=[val]' },
          { category: 'Features', feature: 'Email Text {"val":"Email body text"}', property: 'param.cgi?cmd=setsmtpattr&-ma_text=[val]' },
          { category: 'Features', feature: 'Email Server {"val":"mx.instar.email"}', property: 'param.cgi?cmd=setsmtpattr&-ma_server=[val]' },
          { category: 'Features', feature: 'Email Encryption {"val":"0"} - {"val":"3"}', property: 'param.cgi?cmd=setsmtpattr&-ma_ssl=[val]' },
          { category: 'Features', feature: 'Email Port {"val":"587"}', property: 'param.cgi?cmd=setsmtpattr&-ma_port=[val]' },
          { category: 'Features', feature: 'Email Authentication {"val":"0"}, {"val":"1"}', property: 'param.cgi?cmd=setsmtpattr&-ma_logintype=[val]' },
          { category: 'Features', feature: 'Email Username {"val":"emailuser@email.com"}', property: 'param.cgi?cmd=setsmtpattr&-ma_username=[val]' },
          { category: 'Features', feature: 'Email Password {"val":"password"}', property: 'param.cgi?cmd=setsmtpattr&-ma_password=[val]' },
          { category: 'Features', feature: 'Email Test Login', property: 'param.cgi?cmd=testsmtp' },
          { category: 'Features', feature: 'Ftp Server {"val":"192.168.178.1"}', property: 'param.cgi?cmd=setftpattr&-ft_server=[val]' },
          { category: 'Features', feature: 'Ftp Port {"val":"21"}', property: 'param.cgi?cmd=setftpattr&-ft_port=[val]' },
          { category: 'Features', feature: 'Ftp Username {"val":"ftpuser"}', property: 'param.cgi?cmd=setftpattr&-ft_username=[val]' },
          { category: 'Features', feature: 'Ftp Password {"val":"password"}', property: 'param.cgi?cmd=setftpattr&-ft_password=[val]' },
          { category: 'Features', feature: 'Ftp PASV Mode {"val":"0"}, {"val":"1"}', property: 'param.cgi?cmd=setftpattr&-ft_mode=[val]' },
          { category: 'Features', feature: 'Ftp Dirname {"val":".%2Fdirectory"}', property: 'param.cgi?cmd=setftpattr&-ft_dirname=[val]' },
          { category: 'Features', feature: 'Ftp Dirmode {"val":"0"}, {"val":"1"}', property: 'param.cgi?cmd=setftpattr&-ft_dirmode=[val]' },
          { category: 'Features', feature: 'Ftp Encryption {"val":"0"}, {"val":"1"}', property: 'param.cgi?cmd=setftpattr&-ft_ssl=[val]' },
          { category: 'Features', feature: 'Ftp Test Login', property: '/param.cgi?cmd=testftp' },
          { category: 'Features', feature: 'Nightvision Auto LED {"val":"auto"}, {"val":"close"}', property: 'param.cgi?cmd=setinfrared&-infraredstat=[val]' },
          { category: 'Features', feature: 'Nightvision Auto IRcut {"val":"auto"}, {"val":"open"}, {"val":"close"}', property: 'param.cgi?cmd=set_instar_admin&-index=2&-value=[val]' },
          { category: 'Features', feature: 'Nightvision Schedule ON {"val":"0"}, {"val":"1"}', property: 'param.cgi?cmd=setplancgi&-plancgi_enable_0=[val]' },
          { category: 'Features', feature: 'Nightvision Schedule ON Time {"val":"75605"}', property: 'param.cgi?cmd=setplancgi&-plancgi_time_0=[val]' },
          { category: 'Features', feature: 'Nightvision Schedule OFF {"val":"0"}, {"val":"1"}', property: 'param.cgi?cmd=setplancgi&-plancgi_enable_1=[val]' },
          { category: 'Features', feature: 'Nightvision Schedule OFF Time {"val":"75605"}', property: 'param.cgi?cmd=setplancgi&-plancgi_time_1=[val]' },
          { category: 'Features', feature: 'Nightvision Upper IR Threshold {"val":"1"},  {"val":"270"}', property: 'param.cgi?cmd=setircutattr&-saradc_switch_value=[val]' },
          { category: 'Features', feature: 'Nightvision Lower IR Threshold {"val":"1"},  {"val":"270"}', property: 'param.cgi?cmd=setircutattr&-saradc_b2c_switch_value=[val]' },
          { category: 'Features', feature: 'Nightvision Current Brightness {"val":"1"},  {"val":"270"}', property: 'param.cgi?cmd=getsaradcstate' },
          { category: 'Features', feature: 'PTZ Move {"val":"left"}, {"val":"right"}, {"val":"down"}, {"val":"up"}, {"val":"stop"}, {"val":"focusin"}, {"val":"focusout"}, {"val":"zoomout"}, {"val":"zoomin"}, {"val":"tour"}, {"val":"hscan"}, {"val":"vscan"}', property: 'ptzctrl.cgi?-step=0&-act=[val]&-speed=63' },
          { category: 'Features', feature: 'PTZ Move Step {"val":"left"}, {"val":"right"}, {"val":"down"}, {"val":"up"}, {"val":"stop"}, {"val":"focusin"}, {"val":"focusout"}, {"val":"zoomout"}, {"val":"zoomin"}', property: 'ptzctrl.cgi?-step=1&-act=[val]&-speed=63' },
          { category: 'Features', feature: 'PTZ Preset {"val":"0"} - {"val":"7"} & {"val":"88"} (Start Scan)', property: 'param.cgi?cmd=preset&-act=goto&-number=[val]' },
          { category: 'Features', feature: 'PTZ Save Preset {"val":"0"} - {"val":"7"}', property: 'param.cgi?cmd=preset&-act=set&-status=1&-number=[val]' },
          { category: 'Features', feature: 'PTZ Scan {"val":"hscan"}, {"val":"vscan"}, {"val":"tour"}', property: 'param.cgi?cmd=ptzctrl&-act=[val]' },
          { category: 'Features', feature: 'PTZ Calibration {"val":"off"}, {"val":"on"}', property: 'param.cgi?cmd=setmotorattr&-selfdet=[val]' },
          { category: 'Features', feature: 'PTZ Start Preset {"val":"off"}, {"val":"on"}', property: 'param.cgi?cmd=setmotorattr&-movehome=[val]' },
          { category: 'Features', feature: 'PTZ Start Preset Position {"val":"1"} - {"val":"8"}', property: 'param.cgi?cmd=setmotorattr&-initpresetindex=[val]' },
          { category: 'Features', feature: 'PTZ Alarm Preset {"val":"off"}, {"val":"on"}', property: 'param.cgi?cmd=setmdalarm&-aname=preset&-switch=[val]' },
          { category: 'Features', feature: 'PTZ Alarm Preset {"val":"1"}, {"val":"8"}', property: 'param.cgi?cmd=setmotorattr&-alarmpresetindex=[val]' },
          { category: 'Features', feature: 'PTZ Mask Alarm "val":"0"} - {"val":"1"} ', property: 'param.cgi?cmd=setmotorattr&-ptzalarmmask=[val]' },
          { category: 'Features', feature: 'PTZ Pan Speed {"val":"0"} - {"val":"2"}', property: 'param.cgi?cmd=setmotorattr&-panspeed=[val]' },
          { category: 'Features', feature: 'PTZ Tilt Speed {"val":"0"} - {"val":"2"}', property: 'param.cgi?cmd=setmotorattr&-tiltspeed=[val]' },
          { category: 'Features', feature: 'PTZ Pan Scan {"val":"1"} - {"val":"50"}', property: 'param.cgi?cmd=setmotorattr&-panscan=[val]' },
          { category: 'Features', feature: 'PTZ Tilt Scan {"val":"1"} - {"val":"50"}', property: 'param.cgi?cmd=setmotorattr&-tiltscan=[val]' },
          { category: 'Features', feature: 'PTZ Park Preset {"val":"0"}, {"val":"1"}', property: 'param.cgi?cmd=settimerpreset&-timerpreset_enable=[val]' },
          { category: 'Features', feature: 'PTZ Park Preset {"val":"1"}, {"val":"8"}', property: 'param.cgi?cmd=settimerpreset&-timerpreset_index=[val]' },
          { category: 'Features', feature: 'PTZ Park Preset Interval {"val":"30"}, {"val":"900"}', property: 'param.cgi?cmd=settimerpreset&-timerpreset_interval=[val]' },
          { category: 'Features', feature: 'PTZ Tour Presets {"val":"0;1;2;-1;-1;-1;-1;-1"}', property: 'param.cgi?cmd=setptztour&-tour_index=[val]' },
          { category: 'Features', feature: 'PTZ Tour Interval {"val":"60;120;300;300;300;300;300;300"}', property: 'param.cgi?cmd=setptztour&-tour_interval=[val]' },
          { category: 'Features', feature: 'PTZ Tour Repeats {"val":"1"} - {"val":"50"}', property: 'param.cgi?cmd=setptztour&-tour_times=[val]' },
          { category: 'Features', feature: 'Manual Recording Duration {"val":"0"} - {"val":"600"}', property: 'param.cgi?cmd=set_instar_admin&-index=44&-value=[val]' },
          { category: 'Features', feature: 'Manual Recording Start {"val":"60"} - {"val":"900"}', property: 'param.cgi?cmd=manualrec&-act=on&-time=[val]' },
          { category: 'Features', feature: 'Manual Recording Stop{"val":"off"}', property: 'param.cgi?cmd=manualrec&-act=[val]' },
          { category: 'Features', feature: 'Indicator Power {"val":"off"}, {"val":"on"}', property: 'param.cgi?cmd=setlightattr&-light_index=2&-light_enable=[val]' },
          { category: 'Features', feature: 'Indicator WiFi {"val":"off"}, {"val":"on"}', property: 'param.cgi?cmd=setlightattr&-light_index=1&-light_enable=[val]' },
          { category: 'Alarm', feature: 'Trigger Alarm', property: 'param.cgi?cmd=pushhostalarm' },
          { category: 'Alarm', feature: 'Alarm Email {"val":"off"}, {"val":"on"}', property: 'cmd=setmdalarm&-aname=email&-switch=[val]' },
          { category: 'Alarm', feature: 'Alarm Email Snapshot {"val":"off"}, {"val":"on"}', property: 'param.cgi?cmd=setmdalarm&-aname=emailsnap&-switch=[val]' },
          { category: 'Alarm', feature: 'SD Card Index', property: '/tmpfs/sd/' },
          { category: 'Alarm', feature: 'SD Card Format', property: 'param.cgi?cmd=sdfrmt' },
          { category: 'Alarm', feature: 'SD Card Unmount', property: 'param.cgi?cmd=sdstop' },
          { category: 'Alarm', feature: 'Alarm-IN Relay {"val":"1"} - {"val":"0"}', property: 'param.cgi?cmd=setrelayattr&-enable=[val]' },
          { category: 'Alarm', feature: 'Alarm-IN Relay Intervall {"val":"5"} - {"val":"30"}', property: 'param.cgi?cmd=setrelayattr&-time=[val]' },
          { category: 'Alarm', feature: 'Alarm Snapshot SD Card {"val":"off"}, {"val":"on"}', property: 'param.cgi?cmd=setmdalarm&-aname=snap&-switch=[val]' },
          { category: 'Alarm', feature: 'Alarm Snapshot FTP {"val":"off"}, {"val":"on"}', property: 'param.cgi?cmd=setmdalarm&-aname=ftpsnap&-switch=[val]' },
          { category: 'Alarm', feature: 'Alarm Snapshots SD # {"val":"0"}, {"val":"15"}', property: 'param.cgi?cmd=setalarmsnapattr&-snap_chn=11&-snap_count=[val]' },
          { category: 'Alarm', feature: 'Alarm Snapshots Email # {"val":"0"}, {"val":"15"}', property: 'param.cgi?cmd=setalarmsnapattr&-snap_chn=11&-email_snap_count=[val]' },
          { category: 'Alarm', feature: 'Alarm Snapshots FTP {"val":"0"}, {"val":"15"}', property: 'param.cgi?cmd=setalarmsnapattr&-snap_chn=11&-ftp_snap_count=[val]' },
          { category: 'Alarm', feature: 'Alarm Video SD Card {"val":"off"}, {"val":"on"}', property: 'param.cgi?cmd=setmdalarm&-aname=record&-switch=[val]' },
          { category: 'Alarm', feature: 'Alarm Video FTP {"val":"off"}, {"val":"on"}', property: 'param.cgi?cmd=setmdalarm&-aname=ftprec&-switch=[val]' },
          { category: 'Alarm', feature: 'Alarm Video Length {"val":"15"}, {"val":"30"}, {"val":"45"}, {"val":"60"}', property: 'param.cgi?cmd=set_instar_admin&-index=45&-value=[val]' },
          { category: 'Alarm', feature: 'Alarm PIR {"val":"0"}, {"val":"1"}', property: 'param.cgi?cmd=setpirattr&-pir_enable=[val]' },
          { category: 'Alarm', feature: 'Alarm PIR Flag {"val":"0"}, {"val":"1"}', property: 'param.cgi?cmd=setpirattr&-pir_flag=[val]' },
          { category: 'Alarm', feature: 'Alarm Link Areas {"val":"off"}, {"val":"on"}', property: 'param.cgi?cmd=setmdalarm&-aname=type&-switch=[val]' },
          { category: 'Alarm', feature: 'Alarm Alarm-OUT {"val":"off"}, {"val":"on"}', property: 'param.cgi?cmd=setmdalarm&-aname=relay&-switch=[val]' },
          { category: 'Alarm', feature: 'Alarm Alarm-IN {"val":"0"}, {"val":"1"}', property: 'param.cgi?cmd=setioattr&-io_enable=[val]' },
          { category: 'Alarm', feature: 'Alarm Alarm-IN Flag {"val":"0"}, {"val":"1"}', property: 'param.cgi?cmd=setioattr&-io_flag=[val]' },
          { category: 'Alarm', feature: 'Alarm Alarm Signal {"val":"off"}, {"val":"on"}', property: 'param.cgi?cmd=setmdalarm&-aname=sound&-switch=[val]' },
          { category: 'Alarm', feature: 'Alarm Audioalarm {"val":"0"}, {"val":"1"}', property: 'param.cgi?cmd=setaudioalarmattr&-aa_enable=[val]' },
          { category: 'Alarm', feature: 'Alarm Audio Alarm Sensitivity {"val":"10"} - {"val":"100"}', property: 'param.cgi?cmd=setaudioalarmattr&-aa_value=[val]' },
          { category: 'Alarm', feature: 'Alarm Area 1 {"val":"0"}, {"val":"1"}', property: 'param.cgi?cmd=setmdattr&-name=1&-enable=[val]' },
          { category: 'Alarm', feature: 'Alarm Area 1 Sensitivity {"val":"1"} - {"val":"100"}', property: 'param.cgi?cmd=setmdattr&-name=1&-s=[val]' },
          { category: 'Alarm', feature: 'Alarm Area 1 X-Origin {"val":"0"} - {"val":"1920"}', property: 'pparam.cgi?cmd=setmdattr&-name=1&-x=[val]' },
          { category: 'Alarm', feature: 'Alarm Area 1 Y-Origin {"val":"0"} - {"val":"1080"}', property: 'pparam.cgi?cmd=setmdattr&-name=1&-y=[val]' },
          { category: 'Alarm', feature: 'Alarm Area 1 Height {"val":"1"} - {"val":"1080"}', property: 'pparam.cgi?cmd=setmdattr&-name=1&-h=[val]' },
          { category: 'Alarm', feature: 'Alarm Area 1 Width {"val":"1"} - {"val":"1920"}', property: 'pparam.cgi?cmd=setmdattr&-name=1&-w=[val]' },
          { category: 'Alarm', feature: 'Alarm Area 2 {"val":"0"}, {"val":"1"}', property: 'param.cgi?&cmd=setmdattr&-name=2&-enable=[val]' },
          { category: 'Alarm', feature: 'Alarm Area 2 Sensitivity {"val":"1"} - {"val":"100"}', property: 'param.cgi?cmd=setmdattr&-name=2&-s=[val]' },
          { category: 'Alarm', feature: 'Alarm Area 2 X-Origin {"val":"0"} - {"val":"1920"}', property: 'pparam.cgi?cmd=setmdattr&-name=2&-x=[val]' },
          { category: 'Alarm', feature: 'Alarm Area 2 Y-Origin {"val":"0"} - {"val":"1080"}', property: 'pparam.cgi?cmd=setmdattr&-name=2&-y=[val]' },
          { category: 'Alarm', feature: 'Alarm Area 2 Height {"val":"1"} - {"val":"1080"}', property: 'pparam.cgi?cmd=setmdattr&-name=2&-h=[val]' },
          { category: 'Alarm', feature: 'Alarm Area 2 Width {"val":"1"} - {"val":"1920"}', property: 'pparam.cgi?cmd=setmdattr&-name=2&-w=[val]' },
          { category: 'Alarm', feature: 'Alarm Area 3 {"val":"0"}, {"val":"1"}', property: 'param.cgi?cmd=setmdattr&-name=3&-enable=[val]' },
          { category: 'Alarm', feature: 'Alarm Area 3 Sensitivity {"val":"1"} - {"val":"100"}', property: 'param.cgi?cmd=setmdattr&-name=3&-s=[val]' },
          { category: 'Alarm', feature: 'Alarm Area 3 X-Origin {"val":"0"} - {"val":"1920"}', property: 'pparam.cgi?cmd=setmdattr&-name=3&-x=[val]' },
          { category: 'Alarm', feature: 'Alarm Area 3 Y-Origin {"val":"0"} - {"val":"1080"}', property: 'pparam.cgi?cmd=setmdattr&-name=3&-y=[val]' },
          { category: 'Alarm', feature: 'Alarm Area 3 Height {"val":"1"} - {"val":"1080"}', property: 'pparam.cgi?cmd=setmdattr&-name=3&-h=[val]' },
          { category: 'Alarm', feature: 'Alarm Area 3 Width {"val":"1"} - {"val":"1920"}', property: 'pparam.cgi?cmd=setmdattr&-name=3&-w=[val]' },
          { category: 'Alarm', feature: 'Alarm Area 4 {"val":"0"}, {"val":"1"}', property: 'param.cgi?cmd=setmdattr&-name=4&-enable=[val]' },
          { category: 'Alarm', feature: 'Alarm Area 4 Sensitivity {"val":"1"} - {"val":"100"}', property: 'param.cgi?cmd=setmdattr&-name=4&-s=[val]' },
          { category: 'Alarm', feature: 'Alarm Area 4 X-Origin {"val":"0"} - {"val":"1920"}', property: 'pparam.cgi?cmd=setmdattr&-name=4&-x=[val]' },
          { category: 'Alarm', feature: 'Alarm Area 4 Y-Origin {"val":"0"} - {"val":"1080"}', property: 'pparam.cgi?cmd=setmdattr&-name=4&-y=[val]' },
          { category: 'Alarm', feature: 'Alarm Area 4 Height {"val":"1"} - {"val":"1080"}', property: 'pparam.cgi?cmd=setmdattr&-name=4&-h=[val]' },
          { category: 'Alarm', feature: 'Alarm Area 4 Width {"val":"1"} - {"val":"1920"}', property: 'pparam.cgi?cmd=setmdattr&-name=4&-w=[val]' },
          { category: 'Alarm', feature: 'Alarm Schedule Sunday {"val":"NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}', property: 'param.cgi?cmd=setbackupschedule&-ename=md&-week0=[val]' },
          { category: 'Alarm', feature: 'Alarm Schedule Monday {"val":"NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}', property: 'param.cgi?cmd=setbackupschedule&-ename=md&-week1=[val]' },
          { category: 'Alarm', feature: 'Alarm Schedule Tuesday {"val":"NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}', property: 'param.cgi?cmd=setbackupschedule&-ename=md&-week2=[val]' },
          { category: 'Alarm', feature: 'Alarm Schedule Wednesday {"val":"NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}', property: 'param.cgi?cmd=setbackupschedule&-ename=md&-week3=[val]' },
          { category: 'Alarm', feature: 'Alarm Schedule Thursday {"val":"NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}', property: 'param.cgi?cmd=setbackupschedule&-ename=md&-week4=[val]' },
          { category: 'Alarm', feature: 'Alarm Schedule Friday {"val":"NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}', property: 'param.cgi?cmd=setbackupschedule&-ename=md&-week5=[val]' },
          { category: 'Alarm', feature: 'Alarm Schedule Saturday {"val":"NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}', property: 'param.cgi?cmd=setbackupschedule&-ename=md&-week6=[val]' },
          { category: 'Alarm', feature: 'Alarm Schedule Area Group 12 {"val":"0"}, {"val":"1"}', property: 'param.cgi?cmd=setplancgi&-plancgi_enable_3=[val]' },
          { category: 'Alarm', feature: 'Alarm Schedule Area Group 12 Time {"val":"21610"}', property: 'param.cgi?cmd=setplancgi&-plancgi_time_3=[val]' },
          { category: 'Alarm', feature: 'Alarm Schedule Area Group 34 {"val":"0"}, {"val":"1"}', property: 'param.cgi?cmd=setplancgi&-plancgi_enable_4=[val]' },
          { category: 'Alarm', feature: 'Alarm Schedule Area Group 34 Time {"val":"64810"}', property: 'param.cgi?cmd=setplancgi&-plancgi_time_4=[val]' },
          { category: 'Alarm', feature: 'Alarm push Notification {"val":"off"}, {"val":"on"}', property: 'param.cgi?cmd=setmdalarm&-aname=server&-switch=[val]' },
          { category: 'Alarm', feature: 'Alarmserver {"val":"off"}, {"val":"on"}', property: 'param.cgi?cmd=setmdalarm&-aname=server2&-switch=[val]' },
          { category: 'Alarm', feature: 'Alarmserver IP {"val":"192.168.2.88"}', property: 'param.cgi?cmd=setalarmserverattr&-as_index=3&-as_server=[val]' },
          { category: 'Alarm', feature: 'Alarmserver Port {"val":"80"}', property: 'param.cgi?cmd=setalarmserverattr&-as_index=3&-as_port=[val]' },
          { category: 'Alarm', feature: 'Alarmserver Path {"val":"/dev/sps/io/alarmserver/Pulse"}', property: 'param.cgi?cmd=setalarmserverattr&-as_index=3&-as_path=[val]' },
          { category: 'Alarm', feature: 'Alarmserver Query1 {"val":"0"}, {"val":"1"}', property: 'param.cgi?cmd=setalarmserverattr&-as_index=3&-as_query1=[val]' },
          { category: 'Alarm', feature: 'Alarmserver Query Attr1 {"val":"command name"}', property: 'param.cgi?cmd=setalarmserverattr&-as_index=3&-as_queryattr1=[val]' },
          { category: 'Alarm', feature: 'Alarmserver Query Val1 {"val":"command value"}', property: 'param.cgi?cmd=setalarmserverattr&-as_index=3&-as_queryval1=[val]' },
          { category: 'Alarm', feature: 'Alarmserver Query2 {"val":"0"}, {"val":"1"}', property: 'param.cgi?cmd=setalarmserverattr&-as_index=3&-as_query2=[val]' },
          { category: 'Alarm', feature: 'Alarmserver Query Attr2 {"val":"command name"}', property: 'param.cgi?cmd=setalarmserverattr&-as_index=3&-as_queryattr2=[val]' },
          { category: 'Alarm', feature: 'Alarmserver Query Val2 {"val":"command value"}', property: 'param.cgi?cmd=setalarmserverattr&-as_index=3&-as_queryval2=[val]' },
          { category: 'Alarm', feature: 'Alarmserver Query3 {"val":"0"}, {"val":"1"}', property: 'param.cgi?cmd=setalarmserverattr&-as_index=3&-as_query3=[val]' },
          { category: 'Alarm', feature: 'Alarmserver Query Attr3 {"val":"command name"}', property: 'param.cgi?cmd=setalarmserverattr&-as_index=3&-as_queryattr3=[val]' },
          { category: 'Alarm', feature: 'Alarmserver Query Val3 {"val":"command value"}', property: 'param.cgi?cmd=setalarmserverattr&-as_index=3&-as_queryval3=[val]' },
          { category: 'Alarm', feature: 'Alarmserver Append Trigger {"val":"0"}, {"val":"1"}', property: 'param.cgi?cmd=setalarmserverattr&-as_index=3&-as_activequery=[val]' },
          { category: 'Alarm', feature: 'Alarmserver Authentication {"val":"0"}, {"val":"1"}', property: 'param.cgi?cmd=setalarmserverattr&-as_index=3&-as_auth=[val]' },
          { category: 'Alarm', feature: 'Alarmserver Username {"val":"admin"}', property: 'param.cgi?cmd=setalarmserverattr&-as_index=3&-as_username=[val]' },
          { category: 'Alarm', feature: 'Alarmserver Password {"val":"password"}', property: 'param.cgi?cmd=setalarmserverattr&-as_index=3&-as_password=[val]' },
          { category: 'Tasks', feature: 'Photoseries SD Card {"val":"0"}, {"val":"1"}', property: 'param.cgi?cmd=setsnaptimerattrex&-as_type=snap&-as_enable=[val]' },
          { category: 'Tasks', feature: 'Photoseries SD Card Interval {"val":"1"}, {"val":"86400"}', property: 'param.cgi?cmd=setsnaptimerattrex&-as_type=snap&-as_interval=[val]' },
          { category: 'Tasks', feature: 'Photoseries Email {"val":"0"}, {"val":"1"}', property: 'param.cgi?cmd=setsnaptimerattrex&-as_type=email&-as_enable=[val]' },
          { category: 'Tasks', feature: 'Photoseries Email Interval {"val":"1"}, {"val":"1440"}', property: 'param.cgi?cmd=setsnaptimerattrex&-as_type=email&-as_interval=[val]' },
          { category: 'Tasks', feature: 'Photoseries FTP {"val":"0"}, {"val":"1"}', property: 'param.cgi?cmd=setsnaptimerattrex&-as_type=ftp&-as_enable=[val]' },
          { category: 'Tasks', feature: 'Photoseries FTP Interval {"val":"1"}, {"val":"86400"}', property: 'param.cgi?cmd=setsnaptimerattrex&-as_type=ftp&-as_interval=[val]' },
          { category: 'Tasks', feature: 'Photoseries FTP Fixedname {"val":"0"}, {"val":"1"}', property: 'param.cgi?cmd=setalarmsnapattr&-snap_name_mode=[val]' },
          { category: 'Tasks', feature: 'Photoseries FTP Filename {"val":"filename"}', property: 'param.cgi?cmd=setalarmsnapattr&-snap_timer_name=[val]' },
          { category: 'Tasks', feature: 'Photoseries Schedule Sunday {"val":"-week0=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"-week0=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}', property: 'param.cgi?cmd=setscheduleex&-ename=snap&-week0=[val]' },
          { category: 'Tasks', feature: 'Photoseries Schedule Monday {"val":"-week0=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"-week0=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}', property: 'param.cgi?cmd=setscheduleex&-ename=snap&-week1=[val]' },
          { category: 'Tasks', feature: 'Photoseries Schedule Tuesday {"val":"-week0=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"-week0=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}', property: 'param.cgi?cmd=setscheduleex&-ename=snap&-week2=[val]' },
          { category: 'Tasks', feature: 'Photoseries Schedule Wednesday {"val":"-week0=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"-week0=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}', property: 'param.cgi?cmd=setscheduleex&-ename=snap&-week3=[val]' },
          { category: 'Tasks', feature: 'Photoseries Schedule Thursday {"val":"-week0=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"-week0=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}', property: 'param.cgi?cmd=setscheduleex&-ename=snap&-week4=[val]' },
          { category: 'Tasks', feature: 'Photoseries Schedule Friday {"val":"-week0=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"-week0=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}', property: 'param.cgi?cmd=setscheduleex&-ename=snap&-week5=[val]' },
          { category: 'Tasks', feature: 'Photoseries Schedule Saturday {"val":"-week0=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"-week0=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}', property: 'param.cgi?cmd=setscheduleex&-ename=snap&-week6=[val]' },
          { category: 'Tasks', feature: 'Snapshot large', property: '/tmpfs/snap.jpg' },
          { category: 'Tasks', feature: 'Snapshot medium', property: '/tmpfs/auto.jpg' },
          { category: 'Tasks', feature: 'Snapshot small', property: '/tmpfs/auto2.jpg' },
          { category: 'Tasks', feature: 'Video Recording {"val":"0"}, {"val":"1"}', property: 'param.cgi?cmd=setplanrecattr&-planrec_enable=[val]' },
          { category: 'Tasks', feature: 'Video Recording Resolution {"val":"11"}, {"val":"12"}, {"val":"13"}', property: 'param.cgi?cmd=setplanrecattr&-planrec_chn=[val]' },
          { category: 'Tasks', feature: 'Video Recording Length {"val":"60"} - {"val":"900"}', property: 'param.cgi?cmd=setplanrecattr&-planrec_time=[val]' },
          { category: 'Tasks', feature: 'Photoseries Schedule Sunday {"val":"-week0=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"-week0=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}', property: 'param.cgi?cmd=setscheduleex&-ename=snap&-week0=[val]' },
          { category: 'Tasks', feature: 'Video Recording Schedule Monday {"val":"-week0=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"-week0=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}', property: 'param.cgi?cmd=setscheduleex&-ename=snap&-week1=[val]' },
          { category: 'Tasks', feature: 'Video Recording Schedule Tuesday {"val":"-week0=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"-week0=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}', property: 'param.cgi?cmd=setscheduleex&-ename=snap&-week2=[val]' },
          { category: 'Tasks', feature: 'Video Recording Schedule Wednesday {"val":"-week0=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"-week0=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}', property: 'param.cgi?cmd=setscheduleex&-ename=snap&-week3=[val]' },
          { category: 'Tasks', feature: 'Video Recording Schedule Thursday {"val":"-week0=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"-week0=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}', property: 'param.cgi?cmd=setscheduleex&-ename=snap&-week4=[val]' },
          { category: 'Tasks', feature: 'Video Recording Schedule Friday {"val":"-week0=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"-week0=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}', property: 'param.cgi?cmd=setscheduleex&-ename=snap&-week5=[val]' },
          { category: 'Tasks', feature: 'Video Recording Schedule Saturday {"val":"-week0=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"-week0=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}', property: 'param.cgi?cmd=setscheduleex&-ename=snap&-week6=[val]' },
          { category: 'System', feature: 'User Active', property: 'param.cgi?cmd=getuserinfo' },
          { category: 'System', feature: 'User Admin Name {"val":"admin"}', property: 'param.cgi?cmd=setuserattr&-at_username=admin&-at_newname=[val]' },
          { category: 'System', feature: 'User Admin Password {"val":"instar"}', property: 'param.cgi?cmd=setuserattr&-at_username=admin&-at_password=[val]' },
          { category: 'System', feature: 'User User Enable {"val":"0"}, {"val":"1"}', property: 'param.cgi?cmd=setuserattr&-at_username=user&-at_enable=[val]' },
          { category: 'System', feature: 'User User Name {"val":"user"}', property: 'param.cgi?cmd=setuserattr&-at_username=user&-at_newname=[val]' },
          { category: 'System', feature: 'User User Password {"val":"instar"}', property: 'param.cgi?cmd=setuserattr&-at_username=user&-at_password=[val]' },
          { category: 'System', feature: 'User Guest Enable {"val":"0"}, {"val":"1"}', property: 'param.cgi?cmd=setuserattr&-at_username=guest&-at_enable=[val]' },
          { category: 'System', feature: 'User Guest Name {"val":"guest"}', property: 'param.cgi?cmd=setuserattr&-at_username=guest&-at_newname=[val]' },
          { category: 'System', feature: 'User Guest Password {"val":"instar"}', property: 'param.cgi?cmd=setuserattr&-at_username=guest&-at_password=[val]' },
          { category: 'System', feature: 'User IP Logging {"val":"0"}, {"val":"1"}', property: 'param.cgi?cmd=setlogattr&-log_mode=[val]' },
          { category: 'System', feature: 'System Reboot', property: 'param.cgi?cmd=sysreboot' },
          { category: 'System', feature: 'System Reboot Planned {"val":"0"}, {"val":"1"}', property: 'param.cgi?cmd=setplancgi&-plancgi_enable_2=[val]' },
          { category: 'System', feature: 'System Reboot Time {"val":"0"} - {"val":"86400"}', property: 'param.cgi?cmd=setplancgi&-plancgi_time_2=[val]' },
          { category: 'System', feature: 'System Settings Backup', property: '/backup.cgi' },
          { category: 'System', feature: 'System Settings Restore', property: '/restore.cgi[?-setting_file=]' },
          { category: 'System', feature: 'System Upgrade', property: '/upgrade.cgi[?-filename=]' },
          { category: 'System', feature: 'System Reset', property: 'param.cgi?cmd=sysreset.cgi' },
          { category: 'System', feature: 'System Log', property: '/tmpfs/syslog.txt' },
          { category: 'System', feature: 'System Synchonize Log', property: 'param.cgi?cmd=logsync' },
          { category: 'System', feature: 'System Clean Log', property: 'param.cgi?cmd=cleanlog' },
          { category: 'System', feature: 'Camera Device ID', property: 'param.cgi?cmd=getsysinfo' },
          { category: 'System', feature: 'Camera Device Type', property: 'param.cgi?cmd=getdevtype' },
          { category: 'System', feature: 'Camera Server Parameter', property: 'param.cgi?cmd=getserverinfo' },
          { category: 'System', feature: 'Camera Server Time', property: 'param.cgi?cmd=setservertime&-time=2023.03.14.22.36.23&-timezone=Europe%2FAmsterdam&-dstmode=on' },
          { category: 'System', feature: 'INSTAR Parameter', property: 'param.cgi?cmd=set_instar_admin&-index=11&-value=2&cmd=set_instar_admin&-index=49&-value=off' },
          { category: 'System', feature: 'Camera NTP Server', property: 'param.cgi?cmd=setntpattr&-ntpenable=1&-ntpinterval=2&-ntpserver=time.nist.gov' }
        ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns} >

          <FilteringState />

          <SortingState
            defaultSorting={[
              { columnName: 'category', direction: 'asc' },
            ]}
          />
          <GroupingState
            defaultGrouping={[{ columnName: 'category' }]}
            defaultExpandedGroups={['Network']}
          />
          <SelectionState />

          <IntegratedFiltering />
          <IntegratedGrouping />
          <IntegratedSelection />
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
          <TableFilterRow />
          <TableGroupRow />
        </Grid>
      </Paper>
    );
  }
}

MQTT2CGIGrid.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(MQTT2CGIGrid)