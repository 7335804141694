import React from 'react'
import { Chrome } from './src/components/Layout/chrome/chrome'

require("prismjs/themes/prism-coy.css")


/* //adding loading spinner to ./src/html.js
export const onInitialClientRender = () => {
  setTimeout(function() {
      document.getElementById("___loader").style.display = "none"
  }, 1000)
} */

export const wrapRootElement = ({ element }) => {
  return <Chrome>{element}</Chrome>;
};