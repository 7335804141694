import React from 'react'
import { Link } from 'gatsby'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

import products3010SearchIcon from 'images/Search/P_SearchThumb_IN-3010.webp'
import products3011SearchIcon from 'images/Search/P_SearchThumb_IN-3011.webp'
import products6001SearchIcon from 'images/Search/P_SearchThumb_IN-6001HD.webp'
import products6012SearchIcon from 'images/Search/P_SearchThumb_IN-6012HD.webp'
import products6014SearchIcon from 'images/Search/P_SearchThumb_IN-6014HD.webp'
import products8001HDSearchIcon from 'images/Search/P_SearchThumb_IN-8001HD.webp'
import products8003HDSearchIcon from 'images/Search/P_SearchThumb_IN-8003HD.webp'
import products8015HDSearchIcon from 'images/Search/P_SearchThumb_IN-8015HD.webp'
import products8401WQHDSearchIcon from 'images/Search/P_SearchThumb_IN-8401WQHD.webp'
import products8403WQHDSearchIcon from 'images/Search/P_SearchThumb_IN-8403WQHD.webp'
import products8415WQHDSearchIcon from 'images/Search/P_SearchThumb_IN-8415WQHD.webp'


export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Innenkameras/IN-8415_WQHD/">
                  <img
                    src={products8415WQHDSearchIcon}
                    alt="IN-8415 WQHD"
                  />
                </Link>
              }
              title="IN-8415 WQHD"
              description="INSTAR IN-8415 2k+ WQHD Pan&Tilt Nachtsicht-Überwachungskamera (LAN/WLAN) Kleine aber feine Innenkamera mit 850nm Infrarot-LEDs, PIR-Sensor und SD-Karte."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Innenkameras/IN-8415_WQHD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Innenkameras/IN-8403_WQHD/">
                  <img
                    src={products8403WQHDSearchIcon}
                    alt="IN-8403 WQHD"
                  />
                </Link>
              }
              title="IN-8403 WQHD"
              description="INSTAR IN-8403 2k+ WQHD Nachtsicht-Überwachungskamera (LAN/WLAN). Die kleine aber feine Innen- und (IP67) Außenkamera mit visuellen (Weißlicht) und 850nm Infrarot-LEDs, PIR-Sensor und SD-Karte."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Innenkameras/IN-8403_WQHD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Innenkameras/IN-8401_WQHD/">
                  <img
                    src={products8401WQHDSearchIcon}
                    alt="IN-8401 WQHD"
                  />
                </Link>
              }
              title="IN-8401 WQHD"
              description="Die IN-8401 2k+ WQHD ist die neue INSTAR Innenkamera mit dem neuen 1440p Chipsatz. Und die erste Kamera, die sowohl 850nm als auch 'unsichtbare' 940nm IR-Nachtsicht bietet."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Innenkameras/IN-8401_WQHD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Innenkameras/IN-8401_WQHD/">
                  <img
                    src={products8401WQHDSearchIcon}
                    alt="IN-8401 WQHD"
                  />
                </Link>
              }
              title="IN-8401 WQHD"
              description="Die IN-8401 2k+ WQHD ist die neue INSTAR Innenkamera mit dem neuen 1440p Chipsatz. Und die erste Kamera, die sowohl 850nm als auch 'unsichtbare' 940nm IR-Nachtsicht bietet."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Innenkameras/IN-8401_WQHD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Innenkameras/IN-8015_HD/">
                  <img
                    src={products8015HDSearchIcon}
                    alt="IN-8015 Full HD"
                  />
                </Link>
              }
              title="IN-8015 Full HD"
              description="Die IN-8015 FHD ist die erste INSTAR Kamera mit dem neuen 1080p-Chipsatz. Sie bietet alle Vorteile der IN-6014 HD mit einer überarbeiteten Software und vieles mehr."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Innenkameras/IN-8015_HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Innenkameras/IN-8003_HD/">
                  <img
                    src={products8003HDSearchIcon}
                    alt="IN-8003 Full HD"
                  />
                </Link>
              }
              title="IN-8003 Full HD"
              description="Die IN-8003 FHD ist die erste INSTAR-Kamera mit dem neuen 1080p-Chipsatz. Mit dem wetterfesten Gehäuse können Sie sie sowohl im Innen- als auch im Außenbereich installieren."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Innenkameras/IN-8003_HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Innenkameras/IN-8001_HD/">
                  <img
                    src={products8001HDSearchIcon}
                    alt="IN-8001 Full HD"
                  />
                </Link>
              }
              title="IN-8001 Full HD"
              description="Das IN-8001 FHD ist der Nachfolger des IN-6001 HD mit dem neuen 1080p-Chipsatz. Mit seinen unsichtbaren IR-LEDs ist er ideal für den Einsatz als Babyphone geeignet."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Innenkameras/IN-8001_HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Innenkameras/IN-6014_HD/">
                  <img
                    src={products6014SearchIcon}
                    alt="IN-6014 HD"
                  />
                </Link>
              }
              title="IN-6014 HD"
              description="Die IN-6014 HD bietet alle Vorteile der IN-6012 HD. Hat aber zusätzlich ein 80 ° Weitwinkelobjektiv und einen integrierten PIR-Bewegungsmelder."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Innenkameras/IN-6014_HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Innenkameras/IN-6012_HD/">
                  <img
                    src={products6012SearchIcon}
                    alt="IN-6012 HD"
                  />
                </Link>
              }
              title="IN-6012 HD"
              description="Die IN-6012HD ist die Standard-HD-PTZ-Kamera für die Innenraumüberwachung. Sie verfügt über ein Standardobjektiv mit 40 ° Betrachtungswinkel und IR-Nachtsicht."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Innenkameras/IN-6012_HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Innenkameras/IN-6001_HD/">
                  <img
                    src={products6001SearchIcon}
                    alt="IN-6001 HD"
                  />
                </Link>
              }
              title="IN-6001 HD"
              description="Der Nachfolger der VGA-Kamera IN-3001 - kompakt, unauffällig, IR-Nachtsicht-fähig mit einer Videoauflösung von 720p HD und integrierter SD-Karte."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Innenkameras/IN-6001_HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Innenkameras/IN-3011/">
                  <img
                    src={products3011SearchIcon}
                    alt="IN-3011"
                  />
                </Link>
              }
              title="IN-3011"
              description="Die IN-3011 ist die Standard-Innenkamera für die VGA-Überwachung. Sie hat IR-Nachtsicht und einen, über unsere App, fernsteuerbaren Kamerakopf."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Innenkameras/IN-3011/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Innenkameras/IN-3010/">
                  <img
                    src={products3010SearchIcon}
                    alt="IN-3010"
                  />
                </Link>
              }
              title="IN-3010"
              description="Die IN-3010 ist eine Allround-Lösung für die Innenraumüberwachung. Der Kamerakopf ist fernsteuerbar und mit Nachtsichtdioden ausgestattet."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Innenkameras/IN-3010/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Innenkameras/IN-3003/">
                  <img
                    src={products3010SearchIcon}
                    alt="IN-3003"
                  />
                </Link>
              }
              title="IN-3003"
              description="Die IN-3003 ist eine kompakte Lösung für die Innenraumüberwachung. Der Kamerakopf ist mit IR-Dioden für Infrarot-Nachtsicht ausgestattet."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Innenkameras/IN-3003/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Innenkameras/IN-3003/">
                  <img
                    src={products3010SearchIcon}
                    alt="IN-3003"
                  />
                </Link>
              }
              title="IN-3003"
              description="Die IN-3003 ist eine kompakte Lösung für die Innenraumüberwachung. Der Kamerakopf ist mit IR-Dioden für Infrarot-Nachtsicht ausgestattet."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Innenkameras/IN-3003/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloadbereich/Innenkameras/IN-3001/">
                  <img
                    src={products3010SearchIcon}
                    alt="IN-3001"
                  />
                </Link>
              }
              title="IN-3001"
              description="Die IN-3001 ist eine kompakte Lösung für die Innenraumüberwachung. Der Kamerakopf ist mit IR-Dioden für Infrarot-Nachtsicht ausgestattet."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloadbereich/Innenkameras/IN-3001/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
