import React from 'react'
import {Link} from 'gatsby'

import {
  EuiAccordion,
  EuiText,
  EuiSpacer,
  EuiImage,
  EuiPanel
} from '@elastic/eui'


import Action01 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/action-01.webp'
import Action01Set from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/action-01-set-1.webp'
import Action02 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/action-02.webp'
import Action02Set from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/action-02-set-1.webp'
import Action03 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/action-03.webp'
import Action03Set from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/action-03-set-1.webp'
import Action04 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/action-04.webp'
import Action04Set from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/action-04-set-1.webp'
import Action05 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/action-05.webp'
import Action05Set from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/action-05-set-1.webp'
import Action06 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/action-06.webp'
import Action06Set from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/action-06-set-1.webp'
import Action07 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/action-07.webp'
import Action07Set from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/action-07-set-1.webp'
import Action08 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/action-08.webp'
import Action09 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/action-09.webp'
import Action10 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/action-10.webp'
import Action11 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/action-11.webp'
import Action11Set from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/action-11-set-1.webp'



function ActionsAccordion() {
  return (
    <React.Fragment>
      
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion1"
          buttonContent="Turn on motion detection"
          paddingSize="l">
          <EuiPanel color="subdued">
            <EuiText style={{height: 500}}>
                <EuiImage
                  size="l"
                  float="left"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="IFTTT Turn on motion detection"
                  src={Action01}
                />
                <p>This action will turn on your camera's motion detection. You need to choose Camera name:</p>
                <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
                <EuiSpacer size="xl" />
                <EuiImage
                  size="l"
                  float="right"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="IFTTT Turn on motion detection"
                  src={Action01Set}
                />
              </EuiText>
            </EuiPanel>
          </EuiAccordion>
        </EuiPanel>
  
      <EuiSpacer />
      
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion2"
          buttonContent="Turn off motion detection"
          buttonContentClassName="eui-textTruncate"
          paddingSize="l">
          <EuiPanel color="subdued">
            <EuiText style={{height: 500}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Turn off motion detection"
                src={Action02}
              />
              <p>This action will turn off your camera's motion detection. You need to choose Camera name:</p>
              <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
              <EuiSpacer size="xl" />
              <EuiImage
                size="l"
                float="right"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Turn off motion detection"
                src={Action02Set}
              />
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
  
      <EuiSpacer />
      
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion3"
          buttonContent="Turn on Alarm Email"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 500}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Turn on Alarm Email"
                src={Action03}
              />
              <p>This action will turn on your camera's alarm email notification. You need to choose Camera name:</p>
              <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
              <EuiSpacer size="xl" />
              <EuiImage
                size="l"
                float="right"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Turn on Alarm Email"
                src={Action03Set}
              />
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
  
      <EuiSpacer />
      
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion3"
          buttonContent="Turn off Alarm Email"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 500}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Turn off Alarm Email"
                src={Action04}
              />
              <p>This action will turn off your camera's alarm email notification. You need to choose Camera name:</p>
              <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
              <EuiSpacer size="xl" />
              <EuiImage
                size="l"
                float="right"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Turn off Alarm Email"
                src={Action04Set}
              />
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
  
      <EuiSpacer />
      
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion3"
          buttonContent="Turn camera to a preset position"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 500}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Turn camera to a preset position"
                src={Action05}
              />
              <p>This action will allow you to turn your pan&tilt camera to a specific preset position. | <strong>1.</strong> You need to choose Camera name and <strong>2.</strong> You need to set the position:</p>
              <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
              <EuiSpacer size="xl" />
              <EuiImage
                size="l"
                float="right"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Turn camera to a preset position"
                src={Action05Set}
              />
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
  
      <EuiSpacer />
      
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion3"
          buttonContent="Switch alarm areas"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 500}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Switch alarm areas"
                src={Action06}
              />
              <p>This action will allow you to turn your camera's motion detection areas on or off. | <strong>1.</strong> You need to choose Camera name <strong>2.</strong> You need to enable or disable alarm area:</p>
              <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
              <EuiSpacer size="xl" />
              <EuiImage
                size="l"
                float="right"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Switch alarm areas"
                src={Action06Set}
              />
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
  
      <EuiSpacer />
      
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion3"
          buttonContent="Set the alarm area sensitivity"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 500}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Set the alarm area sensitivity"
                src={Action07}
              />
              <p>This action will adjust the sensitivity of your camera's motion detection areas. | <strong>1.</strong> You need to choose Camera name <strong>2.</strong> You need to enable or disable alarm area:</p>
              <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
              <EuiSpacer size="xl" />
              <EuiImage
                size="l"
                float="right"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Set the alarm area sensitivity"
                src={Action07Set}
              />
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
  
      <EuiSpacer />
      
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion3"
          buttonContent="Turn on Cloud alarm email"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 500}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Turn on Cloud alarm email"
                src={Action08}
              />
              <p>This action will turn on the Cloud alarm email notification:</p>
              <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
  
      <EuiSpacer />
      
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion3"
          buttonContent="Turn off Cloud alarm email"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 500}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Turn off Cloud alarm email"
                src={Action09}
              />
              <p>This action will turn off the Cloud alarm email notification:</p>
              <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
              <EuiSpacer size="xl" />
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
  
      <EuiSpacer />
      
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion3"
          buttonContent="Receive a Cloud status email"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 500}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Receive a Cloud status email"
                src={Action10}
              />
              <p>This action will send an email with the number of alarm uploads today:</p>
              <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
              <EuiSpacer size="xl" />
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
  
      <EuiSpacer />
      
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion3"
          buttonContent="Alarm trigger and video upload"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 500}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Alarm trigger and video upload"
                src={Action11}
              />
              <p>This action will trigger an alarm recording and upload to your Cloud account. You need to choose Camera name:</p>
              <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
              <EuiSpacer size="xl" />
              <EuiImage
                size="l"
                float="right"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Alarm trigger and video upload"
                src={Action11Set}
              />
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
    </React.Fragment>
  );
}

export default ActionsAccordion

// export default () => (
//   <React.Fragment>
    
//     <EuiPanel hasBorder>
//       <EuiAccordion
//         id="accordion1"
//         buttonContent="Turn on motion detection"
//         paddingSize="l">
//         <EuiPanel color="subdued">
//           <EuiText style={{height: 500}}>
//               <EuiImage
//                 size="l"
//                 float="left"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="IFTTT Turn on motion detection"
//                 src={Action01}
//               />
//               <p>This action will turn on your camera's motion detection. You need to choose Camera name:</p>
//               <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
//               <EuiSpacer size="xl" />
//               <EuiImage
//                 size="l"
//                 float="right"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="IFTTT Turn on motion detection"
//                 src={Action01Set}
//               />
//             </EuiText>
//           </EuiPanel>
//         </EuiAccordion>
//       </EuiPanel>

//     <EuiSpacer />
    
//     <EuiPanel hasBorder>
//       <EuiAccordion
//         id="accordion2"
//         buttonContent="Turn off motion detection"
//         buttonContentClassName="eui-textTruncate"
//         paddingSize="l">
//         <EuiPanel color="subdued">
//           <EuiText style={{height: 500}}>
//             <EuiImage
//               size="l"
//               float="left"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT Turn off motion detection"
//               src={Action02}
//             />
//             <p>This action will turn off your camera's motion detection. You need to choose Camera name:</p>
//             <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
//             <EuiSpacer size="xl" />
//             <EuiImage
//               size="l"
//               float="right"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT Turn off motion detection"
//               src={Action02Set}
//             />
//           </EuiText>
//         </EuiPanel>
//       </EuiAccordion>
//     </EuiPanel>

//     <EuiSpacer />
    
//     <EuiPanel hasBorder>
//       <EuiAccordion
//         id="accordion3"
//         buttonContent="Turn on Alarm Email"
//         paddingSize="m">
//         <EuiPanel color="subdued">
//           <EuiText style={{height: 500}}>
//             <EuiImage
//               size="l"
//               float="left"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT Turn on Alarm Email"
//               src={Action03}
//             />
//             <p>This action will turn on your camera's alarm email notification. You need to choose Camera name:</p>
//             <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
//             <EuiSpacer size="xl" />
//             <EuiImage
//               size="l"
//               float="right"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT Turn on Alarm Email"
//               src={Action03Set}
//             />
//           </EuiText>
//         </EuiPanel>
//       </EuiAccordion>
//     </EuiPanel>

//     <EuiSpacer />
    
//     <EuiPanel hasBorder>
//       <EuiAccordion
//         id="accordion3"
//         buttonContent="Turn off Alarm Email"
//         paddingSize="m">
//         <EuiPanel color="subdued">
//           <EuiText style={{height: 500}}>
//             <EuiImage
//               size="l"
//               float="left"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT Turn off Alarm Email"
//               src={Action04}
//             />
//             <p>This action will turn off your camera's alarm email notification. You need to choose Camera name:</p>
//             <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
//             <EuiSpacer size="xl" />
//             <EuiImage
//               size="l"
//               float="right"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT Turn off Alarm Email"
//               src={Action04Set}
//             />
//           </EuiText>
//         </EuiPanel>
//       </EuiAccordion>
//     </EuiPanel>

//     <EuiSpacer />
    
//     <EuiPanel hasBorder>
//       <EuiAccordion
//         id="accordion3"
//         buttonContent="Turn camera to a preset position"
//         paddingSize="m">
//         <EuiPanel color="subdued">
//           <EuiText style={{height: 500}}>
//             <EuiImage
//               size="l"
//               float="left"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT Turn camera to a preset position"
//               src={Action05}
//             />
//             <p>This action will allow you to turn your pan&tilt camera to a specific preset position. | <strong>1.</strong> You need to choose Camera name and <strong>2.</strong> You need to set the position:</p>
//             <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
//             <EuiSpacer size="xl" />
//             <EuiImage
//               size="l"
//               float="right"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT Turn camera to a preset position"
//               src={Action05Set}
//             />
//           </EuiText>
//         </EuiPanel>
//       </EuiAccordion>
//     </EuiPanel>

//     <EuiSpacer />
    
//     <EuiPanel hasBorder>
//       <EuiAccordion
//         id="accordion3"
//         buttonContent="Switch alarm areas"
//         paddingSize="m">
//         <EuiPanel color="subdued">
//           <EuiText style={{height: 500}}>
//             <EuiImage
//               size="l"
//               float="left"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT Switch alarm areas"
//               src={Action06}
//             />
//             <p>This action will allow you to turn your camera's motion detection areas on or off. | <strong>1.</strong> You need to choose Camera name <strong>2.</strong> You need to enable or disable alarm area:</p>
//             <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
//             <EuiSpacer size="xl" />
//             <EuiImage
//               size="l"
//               float="right"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT Switch alarm areas"
//               src={Action06Set}
//             />
//           </EuiText>
//         </EuiPanel>
//       </EuiAccordion>
//     </EuiPanel>

//     <EuiSpacer />
    
//     <EuiPanel hasBorder>
//       <EuiAccordion
//         id="accordion3"
//         buttonContent="Set the alarm area sensitivity"
//         paddingSize="m">
//         <EuiPanel color="subdued">
//           <EuiText style={{height: 500}}>
//             <EuiImage
//               size="l"
//               float="left"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT Set the alarm area sensitivity"
//               src={Action07}
//             />
//             <p>This action will adjust the sensitivity of your camera's motion detection areas. | <strong>1.</strong> You need to choose Camera name <strong>2.</strong> You need to enable or disable alarm area:</p>
//             <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
//             <EuiSpacer size="xl" />
//             <EuiImage
//               size="l"
//               float="right"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT Set the alarm area sensitivity"
//               src={Action07Set}
//             />
//           </EuiText>
//         </EuiPanel>
//       </EuiAccordion>
//     </EuiPanel>

//     <EuiSpacer />
    
//     <EuiPanel hasBorder>
//       <EuiAccordion
//         id="accordion3"
//         buttonContent="Turn on Cloud alarm email"
//         paddingSize="m">
//         <EuiPanel color="subdued">
//           <EuiText style={{height: 500}}>
//             <EuiImage
//               size="l"
//               float="left"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT Turn on Cloud alarm email"
//               src={Action08}
//             />
//             <p>This action will turn on the Cloud alarm email notification:</p>
//             <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
//           </EuiText>
//         </EuiPanel>
//       </EuiAccordion>
//     </EuiPanel>

//     <EuiSpacer />
    
//     <EuiPanel hasBorder>
//       <EuiAccordion
//         id="accordion3"
//         buttonContent="Turn off Cloud alarm email"
//         paddingSize="m">
//         <EuiPanel color="subdued">
//           <EuiText style={{height: 500}}>
//             <EuiImage
//               size="l"
//               float="left"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT Turn off Cloud alarm email"
//               src={Action09}
//             />
//             <p>This action will turn off the Cloud alarm email notification:</p>
//             <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
//             <EuiSpacer size="xl" />
//           </EuiText>
//         </EuiPanel>
//       </EuiAccordion>
//     </EuiPanel>

//     <EuiSpacer />
    
//     <EuiPanel hasBorder>
//       <EuiAccordion
//         id="accordion3"
//         buttonContent="Receive a Cloud status email"
//         paddingSize="m">
//         <EuiPanel color="subdued">
//           <EuiText style={{height: 500}}>
//             <EuiImage
//               size="l"
//               float="left"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT Receive a Cloud status email"
//               src={Action10}
//             />
//             <p>This action will send an email with the number of alarm uploads today:</p>
//             <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
//             <EuiSpacer size="xl" />
//           </EuiText>
//         </EuiPanel>
//       </EuiAccordion>
//     </EuiPanel>

//     <EuiSpacer />
    
//     <EuiPanel hasBorder>
//       <EuiAccordion
//         id="accordion3"
//         buttonContent="Alarm trigger and video upload"
//         paddingSize="m">
//         <EuiPanel color="subdued">
//           <EuiText style={{height: 500}}>
//             <EuiImage
//               size="l"
//               float="left"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT Alarm trigger and video upload"
//               src={Action11}
//             />
//             <p>This action will trigger an alarm recording and upload to your Cloud account. You need to choose Camera name:</p>
//             <Link to="/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
//             <EuiSpacer size="xl" />
//             <EuiImage
//               size="l"
//               float="right"
//               margin="l"
//               hasShadow
//               allowFullScreen
//               alt="IFTTT Alarm trigger and video upload"
//               src={Action11Set}
//             />
//           </EuiText>
//         </EuiPanel>
//       </EuiAccordion>
//     </EuiPanel>
//   </React.Fragment>
// );