import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Schnell_Installation/Wie_Funktioniert_eine_IP_Kamera/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_HowDoesAnIPCameraWork.webp'
                    alt="Wie funktioniert eine IP Kamera"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Wie funktioniert eine IP-Kamera?"
              description="Eine IP-Kamera ist ein komplexes Produkt. Es ist jedoch nicht kompliziert, ein INSTAR-Produkt zu bedienen. Im Folgenden möchten wir Ihnen eine Einführung in die Grundfunktionen einer IP-Kamera geben. Für mehr In..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Schnell_Installation/Wie_Funktioniert_eine_IP_Kamera/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Schnell_Installation/Nach_dem_Auspacken/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_AfterUnpackingYourIPCamera.webp'
                    alt="Nach dem Auspacken"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Nach dem Auspacken"
              description="Inhaltsverzeichnis Installationsassistent Auspacken Objektiveinstellung Heizungsinstallation Anschlusskabel Entfernen oder Verlängern SD-Karten-Upgrade Auspacken Objektiveinstellung Weitere Informationen finden Sie in ..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Schnell_Installation/Nach_dem_Auspacken/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Schnell_Installation/Erste_Schritte/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_FirstStepsWithYourIPCamera.webp'
                    alt="Erste Schritte"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Erste Schritte Installieren der Kamera"
              description="Im Folgenden geben wir Ihnen eine kurze Einführung in die Grundfunktionen unserer IP-Kameras und die ersten Schritte der Installation. Wir werden auf häufige Probleme eingehen und häufig gestellte Fragen beantworten..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Schnell_Installation/Erste_Schritte/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Schnell_Installation/Power_over_Ethernet/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_PowerOverEthernet.webp'
                    alt="Power over Ethernet"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Power over Ethernet"
              description="PoE-Anforderungen Power over Ethernet oder PoE beschreibt Systeme, die elektrische Energie zusammen mit Daten auf einer Twisted-Pair-Ethernet-Verkabelung weiterleiten. Dadurch kann ein einziges Kabel sowohl eine Datenverbindung als auch..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Schnell_Installation/Power_over_Ethernet/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Schnell_Installation/Powerline/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_Powerline.webp'
                    alt="IN-LAN Powerline"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-LAN Powerline"
              description="Netzwerk über Ihr Stromnetz Installation IN-LAN ist eine intelligente und sichere Technologie, mit der Sie ganz einfach ein Heimnetzwerk über das hauseigene Stromnetz einrichten können - ohne aufwendige und teure..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Schnell_Installation/Powerline/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Schnell_Installation/Direkte_LAN_Verbindung/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_DirectConnectionWithYourPC.webp'
                    alt="Direkte LAN Verbindung"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Direkte LAN Verbindung"
              description="Wie können Sie Ihre Kamera über ein Ethernet-Kabel direkt mit Ihrem Computer verbinden? In der folgenden Anleitung wird Ihnen Schritt für Schritt erklärt, wie Sie eine direkte LAN-Verbindung zwischen Ihrem Computer..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Schnell_Installation/Direkte_LAN_Verbindung/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Schnell_Installation/Alternative_IP_Scanner/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_AlternativeIPScanner_eng.webp'
                    alt="IP Kamera Suche"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IP Kamera Suche"
              description="INSTAR Camera Tool Der einfachste Weg, unsere Kamera in Ihrem lokalen Netzwerk zu suchen, ist unser INSTAR Camera Tool für Windows, macOS und LINUX. Zugriff auf die SD-Karte Ihrer Kamera Alternative IP-Scanner zuweisen..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Schnell_Installation/Alternative_IP_Scanner/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Schnell_Installation/Sprachauswahl/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_LanguageSettings.webp'
                    alt="Sprachauswahl"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Sprachauswahl"
              description="Wie stelle ich die Sprache in der Web-Bedienoberfläche ein? Die neue INSTAR-Web-Oberfläche für Ihre IP-Kamera erkennt automatisch, welche Standardsprache Ihr Webbrowser verwendet. Standardmäßig ist die Weboberfläche..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Schnell_Installation/Sprachauswahl/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Schnell_Installation/Live_Video/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_LiveVideo.webp'
                    alt="Zeigen Sie das Video Ihrer Kameras in Ihrem Browser an"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Zeigen Sie das Video Ihrer Kamera in Ihrem Browser an"
              description="HTML5 Video Ab der Version 2.6 der Weboberfläche unterstützen alle Full-HD-Kameramodelle HTML5 Video zur pluginfreien Wiedergabe des Videostreams. Diese Funktion ist standardmäßig aktiviert und kann s..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Schnell_Installation/Live_Video/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Schnell_Installation/Benutzerverwaltung/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_CreatingUserAccounts.webp'
                    alt="Benutzerverwaltung"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Benutzerverwaltung auf Ihrer Kamera"
              description="Sichern Sie den Zugriff auf Ihre IP-Kamera Um in das Benutzermenü zu gelangen, klicken Sie bitte in der Weboberfläche Ihrer IP-Kamera auf System und wählen dann das Untermenü Benutzer wie unten dargestellt. Wir empfehlen immer ..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Schnell_Installation/Benutzerverwaltung/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Schnell_Installation/WebUI_und_Firmware_Upgrade/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_WebUIFirmwareUpgrade.webp'
                    alt="Software Update"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Software Update"
              description="WebUI- und Firmware-Upgrade 1080p-Kameraserie Installieren Sie die neueste Firmware und Web-Benutzeroberfläche auf Ihrer IP-Kamera Wenn ein neues Update verfügbar ist, gehen Sie auf System / Update und Sie werden..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Schnell_Installation/WebUI_und_Firmware_Upgrade/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Schnell_Installation/Leeren_des_Browserverlaufs/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_ClearBrowsingHistory.webp'
                    alt="Löschen des Browserverlaufs"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Löschen des Browserverlaufs"
              description="Manchmal verhält sich die WebUI Ihrer Kamera möglicherweise nicht so, wie Sie es wünschen. Sie haben die Software Ihrer Kamera aktualisiert, aber sie zeigt Ihnen immer noch die alte Version an? Ein Update sollte neue Funktionen auf der Benutzeroberfläche bringen..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Schnell_Installation/Leeren_des_Browserverlaufs/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            {/* <EuiCard
              textAlign="left"
              image={
                <Link to="/Schnell_Installation/ONVIF/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_ONVIF.webp'
                    alt="ONVIF"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="ONVIF"
              description="Alle aktuellen INSTAR 1080p IP-Kameras sind konform mit dem ONVIF-Profil S Version 2.4 Software-Integration Die 720p-Serie unterstützt nun auch das ONVIF-Profil S Version 2.4 Die Überwachungsindustrie..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Schnell_Installation/ONVIF/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            /> */}
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Schnell_Installation/Herstellen_der_WLAN_Verbindung/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_WLAN.webp'
                    alt="Wireless Protected Setup"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Wireless Protected Setup"
              description="Verbinden Sie Ihre IP-Kamera direkt mit Ihrem lokalen WiFi Wenn Ihr WLAN-Router über die WPS-Funktion verfügt, können Sie Ihre Kamera einfach per Knopfdruck mit Ihrem WLAN verbinden. Im Folgenden wird der Vorgang erklärt..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Schnell_Installation/Herstellen_der_WLAN_Verbindung/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Schnell_Installation/Internet_Protocol_IPv6/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_DirectConnectionWithYourPC.webp'
                    alt="Internet Protokoll Version 6 (IPv6)"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Internet Protokoll Version 6 (IPv6)"
              description="In diesem Artikel möchten wir Ihnen IPv6 erklären, die neueste Version des Internet-Protokolls, und wie SLAAC (Stateless Address Autoconfiguration) funktioniert, welches wir in unseren INSTAR 2K+ Kameras integriert haben...."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Schnell_Installation/Internet_Protocol_IPv6/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
