import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'VGA Serie',
      href: '/de/Bewegungserkennung/Einrichten/VGA_Serie/',
    },
    {
      text: '720p Serie',
      href: '/de/Bewegungserkennung/Einrichten/720p_Serie/',
    },
    {
      text: '1080p Serie',
      href: '/de/Bewegungserkennung/Einrichten/1080p_Serie/',
    },
    {
      text: '1440p Serie',
      href: '/de/Bewegungserkennung/Einrichten/',
    },
    {
      text: '◄ Bewegungserkennung',
      href: '/de/Bewegungserkennung/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={6}
        aria-label="Motion Detection Setup"
      />
      <EuiSpacer />
    </Fragment>
  );
}