import React from 'react'

import {
  EuiPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiTitle,
  EuiImage
} from '@elastic/eui'

import relaySpecsImage from '../../../../images/Indoor_Cameras/IN-8015_HD/Technical_Specifications/HD_Camera_Alarm_Relay.png'

export default function FooterCards() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiPanel paddingSize="m">
              <EuiTitle size="m">
                <h3>[3] Alarm Relay Spezifikationen</h3>
              </EuiTitle>
              <EuiImage
                allowFullScreen
                alt='HD Kameras Alarm Relay Spezifikationen'
                src={relaySpecsImage}
              />
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
