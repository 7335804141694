import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase)
const CustomTable = withStyles(styles)(CustomTableBase)

class DetectorTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "settingColumn", title: "Einstellung" },
        { name: "optionColumn", title: "Empfohlene" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { settingColumn: 'Enabled', optionColumn: 'Yes', descriptionColumn: 'Aktivieren Sie, um die anderen Optionen offenzulegen und zu nutzen.' },
        { settingColumn: 'Send Frames', optionColumn: 'Yes', descriptionColumn: 'Drücken Sie Frames auf das angeschlossene Plugin, um es zu analysieren.' },
        { settingColumn: 'Allow Next Trigger', optionColumn: '2000', descriptionColumn: 'Die Anzahl der Millisekunden, die vergehen muss, bevor die nächste Veranstaltungsregistrierung zugelassen wird.' },
        { settingColumn: 'Save Events to SQL', optionColumn: 'Yes', descriptionColumn: 'Speichern Sie Bewegungsereignisse in SQL. Dies ermöglicht die Anzeige von Ereignissen über Video während der im Power Video Viewer aufgetretenen Zeitereignisse.' },
        { settingColumn: 'Detector Rate', optionColumn: '2', descriptionColumn: 'Wie viele Bilder pro Sekunde an den Bewegungsmelder gesendet werden sollen.' },
        { settingColumn: 'Feed-In Image Width', optionColumn: '640', descriptionColumn: 'Breite des zu erkennenden Bildes. Kleinere Größen benötigen weniger CPU.' },
        { settingColumn: 'Feed-In Image Height', optionColumn: '480', descriptionColumn: 'Höhe des zu erkennenden Bildes. Kleinere Größen benötigen weniger CPU.' },
        { settingColumn: 'How to Record', optionColumn: 'Traditional', descriptionColumn: 'Es gibt mehrere Methoden zum Aufzeichnen. Lesen Sie unten, warum das so ist.' },
        { settingColumn: 'Trigger Record', optionColumn: 'Yes', descriptionColumn: 'Wenn aktiviert, gibt es den Befehl, den Aufnahmeprozess zu starten.' },
        { settingColumn: 'Recording FPS Change on Start', optionColumn: ' ', descriptionColumn: 'Lassen Sie das Feld unverändert - wenn Sie in Ihrem Live-Video eine niedrige Bildrate verwenden, verwenden Sie diese Option, um die Bildrate jedes Mal zu erhöhen, wenn eine Alarmaufnahme läuft.' },
        { settingColumn: 'Recording Timeout', optionColumn: '0.2', descriptionColumn: 'Die Zeitspanne, in Minuten, die das Video aufnehmen soll.' }
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}
  
  
DetectorTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(DetectorTable)



// import React from 'react'
// import {Table, Column, Cell} from 'fixed-data-table-2'
// import '../../../../assets/styles/data_tables/fixed-data-table.css'

// const detectorRow = [
//   'Enabled',
//   'Send Frames',
//   'Allow Next Trigger',
//   'Save Events to SQL',
//   'Detector Rate',
//   'Feed-In Image Width',
//   'Feed-In Image Height',
//   'How to Record',
//   'Trigger Record',
//   'Recording FPS Change on Start',
//   'Recording Timeout',
// ]

// const optionRow = [
//   'Yes',
//   'Yes',
//   '2000',
//   'Yes',
//   '2',
//   '640',
//   '480',
//   'Traditional',
//   'Yes',
//   ' ',
//   '0.2',
// ]

// const descriptionRow = [
//   'Enable to reveal and use the other options.',
//   'Push frames to the connected plugin to be analyzed.',
//   'The amount of milliseconds that needs to pass before allowing the next event registration.',
//   'Save Motion Events in SQL. This will allow display of events over video during the time events occurred in',
//   'How many frames per second to send to the motion detector.',
//   'Width of the image being detected. Smaller sizes take less CPU.',
//   'Height of the image being detected. Smaller sizes take less CPU.',
//   'There are multiple methods to record. Read below on why that is.',
//   'When enabled it will give the command to start the recording process.',
//   'Leave blank for no change - when you are using a low frame rate in your live video, use this option to increase the frame rate everytime when an alarm recording is running.',
//   'The amount of time, in minutes, that the video will record for.',
// ]

// // Render your table
// const DetectorTable = () => {
//     return (
//       <Table
//         rowHeight={55}
//         rowsCount={detectorRow.length}
//         touchScrollEnabled={true}
//         width={850}
//         maxHeight={1000}
//         headerHeight={50}>
//         <Column
//           header={<Cell>Settings</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {detectorRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={1}
//           width={150}
//         />
//         <Column
//           header={<Cell>Recommended</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {optionRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={1}
//           width={150}
//         />
//         <Column
//           header={<Cell>Description</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {descriptionRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={2}
//           width={395}
//         />
//       </Table>
//     )
// }

// export default DetectorTable