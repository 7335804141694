import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'MQTT WebUI',
      href: '/de/Web_User_Interface/1080p_Serie/Netzwerk/MQTT/',
    },
    {
      text: 'MQTT Software',
      href: '/de/Erweitert/INSTAR_MQTT_Broker/#kompatible-software',
    },
    {
      text: 'Debugging',
      href: '/de/Erweitert/INSTAR_MQTT_Broker/Windows_macOS_und_LINUX_Apps/',
    },
    {
      text: '1080p CGI Commands',
      href: '/de/1080p_Serie_CGI_Befehle/Komplette_CGI_Liste/',
    },
    {
      text: 'MQTT vs. CGI',
      href: '/de/Erweitert/INSTAR_MQTT_Broker/MQTT_vs_HTTP_API/',
    },
    {
      text: 'MQTT API',
      href: '/de/Erweitert/INSTAR_MQTT_Broker/MQTT_API/',
    },
    {
      text: 'INSTAR MQTT',
      href: '/de/Erweitert/INSTAR_MQTT_Broker/',
    },
    {
      text: '◄ Developers',
      href: '/de/Erweitert/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={11}
        aria-label="Topics for Developers"
      />
      <EuiSpacer />
    </Fragment>
  );
}