import React from 'react'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="UxnCqE1YU0k"
                    title="IN-5907 HD Anschlusskabel"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              title="IN-5907 HD Anschlusskabel"
              footer={
                <EuiText>
                  <p>Wir zeigen Ihnen in diesem Video wie Sie das Anschlusskabel in Ihrer IP Kamera (INSTAR IN-5907HD) entfernen können, um es so z.B. durch eine Wand zu verlegen oder aber um das Kabel gegen ein längeres (z.B. 4m Anschlusskabel) auszutauschen.</p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="boee4eFuJSw"
                    title="IN-5905 HD Anschlusskabel"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              title="IN-5905 HD Anschlusskabel"
              footer={
                <EuiText>
                  <p>Wir erklären Ihnen in diesem Video wie Sie das Anschlusskabel bei Ihrer IN-5905HD entfernen können um dieses so z.B. durch eine Wand zu verlegen. Achtung: Der Umbau erfordert entsprechendes Geschick und wird daher nicht jedem empfohlen...! </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}
