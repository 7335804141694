import React from 'react'
import {Link} from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-gtag'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText
} from '@elastic/eui'

import qiSelect01 from "../../../../../images/Outdoor_Cameras/IN-9008_HD/Quick_Installation/Ethernet_and_Audio-Out/Quickinstallation_Tile_9008_Step_09.webp"
import qiSelect02 from "../../../../../images/Outdoor_Cameras/IN-9008_HD/Quick_Installation/Ethernet_and_Audio-Out/Quickinstallation_Tile_9008_Step_10.webp"
import qiSelect03 from "../../../../../images/Outdoor_Cameras/IN-9008_HD/Quick_Installation/Ethernet_and_Audio-Out/Quickinstallation_Tile_9008_Step_11.webp"
import qiSelect04 from "../../../../../images/Outdoor_Cameras/IN-9008_HD/Quick_Installation/Ethernet_and_Audio-Out/Quickinstallation_Tile_9008_Step_12.webp"




export default function QIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              title='IV-d Router Connection'
              image={
                  <img
                    src={qiSelect01}
                    alt="Verbinden Sie das Netzwerkkabel der Kamera mit einem freien LAN-Anschluss Ihres Internetrouters."
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">Verbinden Sie das Netzwerkkabel der Kamera mit einem freien LAN-Anschluss Ihres Internetrouters.</EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              title='IV-e Camera Tool'
              image={
                  <img
                    src={qiSelect02}
                    alt="2-hole Sealing Ring: to connect the Power Cable and LAN Cable"
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">Starten Sie <OutboundLink href="https://install.instar.com/WQHDv2/IN-9408_WQHD_Installation/01_LAN_Integration/03_Find_your_Camera">IP Camera Tool</OutboundLink> von der Software-CD und doppelklicken Sie auf den Namen der Kamera.</EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              title='IV-f User Login'
              image={
                  <img
                    src={qiSelect03}
                    alt="The default administrator login is User/Password: admin / instar. Our Installation Wizard will lead you through the rest of the installation process."
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">Die Standardadministrator-Anmeldung ist Benutzer / Kennwort: <strong>admin / instar</strong>. Unser <OutboundLink href="http://install.instar.de/" target="_blank" rel="noopener noreferrer">Installation Wizard</OutboundLink> führt Sie durch den Rest des Installationsprozesses.</EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              title='IV-g Video Plugin'
              image={
                  <img
                    src={qiSelect04}
                    alt="2-hole Sealing Ring: to connect the Power Cable and LAN Cable"
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">Sie müssen jetzt das <Link to="/Schnell_Installation/Live_Video/">Adobe Flash Plugin</Link> installieren, um den h.264 Video-Stream anzuzeigen. Sie können alternative den <Link to="/Web_User_Interface/1080p_Serie/Multimedia/Video/">MJPEG Stream</Link> aktivieren, falls Sie nicht berechtigt sind Browser-Plugins auf Ihrem Computer zu installieren.</EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
