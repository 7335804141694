import React from 'react'
import {Link} from 'gatsby'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import qsP2PWPS01 from "../../../../images/Indoor_Cameras/IN-8015_HD/Point2Point/IN-8015HD_Quick-Installation_P2P-WPS_01.webp"
import qsP2PWPS02 from "../../../../images/Indoor_Cameras/IN-8015_HD/Point2Point/IN-8015HD_Quick-Installation_P2P-WPS_02.webp"
import qsP2PWPS03 from "../../../../images/Indoor_Cameras/IN-8015_HD/Point2Point/IN-8015HD_Quick-Installation_P2P-WPS_03.webp"
import qsP2PWPS04 from "../../../../images/Indoor_Cameras/IN-8015_HD/Point2Point/IN-8015HD_Quick-Installation_P2P-WPS_04.webp"
import qsP2PWPS05 from "../../../../images/Indoor_Cameras/IN-8015_HD/Point2Point/IN-8015HD_Quick-Installation_P2P-WPS_05.webp"
import qsP2PWPS06 from "../../../../images/Indoor_Cameras/IN-8015_HD/Point2Point/IN-8015HD_Quick-Installation_P2P-WPS_06.webp"
import qsP2PWPS07 from "../../../../images/Indoor_Cameras/IN-8015_HD/Point2Point/IN-8015HD_Quick-Installation_P2P-WPS_07.webp"
import qsP2PWPS08 from "../../../../images/Indoor_Cameras/IN-8015_HD/Point2Point/IN-8015HD_Quick-Installation_P2P-WPS_08.webp"




export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qsP2PWPS01}
                    alt="IN-8015 Full HD Quick Installation"
                  />
              }
              title='Step 1'
              description={
                <p>Stecken Sie die WiFi Antenne in den dafür vorgesehenen Anschluss der Kamera wie hier gezeigt (<Link to="/Innenkameras/IN-8015_HD/Product_Features/">#9</Link>). (nur für die NICHT-PoE Version!)</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qsP2PWPS02}
                    alt="IN-8015 Full HD Quick Installation"
                  />
              }
              title='Step 2'
              description={
                <p>Schließen Sie das Netzteil an (<Link to="/Innenkameras/IN-8015_HD/Product_Features/">#8</Link>) um Ihre Kamera zu starten. Wenn Sie das PoE-Modell installieren, stecken Sie das LAN-Kabel ein (<Link to="/Innenkameras/IN-8015_HD/Product_Features/">#13</Link>), verbinden Sie es mit Ihrem Router und überspringen Sie den folgenden WLAN-Bereich.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qsP2PWPS03}
                    alt="IN-8015 Full HD Quick Installation"
                  />
              }
              title='Step 3'
              description={
                <p>Warten Sie bis die Kamera ihre Kalibrierungsfahrt beendet hat und aktivieren Sie danach den WPS Modus in Ihrem Router. In den meisten Fällen gibt es dafür einen Knopf am Gehäuse des Routers (s.o.)</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qsP2PWPS04}
                    alt="IN-8015 Full HD Quick Installation"
                  />
              }
              title='Step 4'
              description={
                <p>Nachdem der Router im WPS Modus ist, drücken Sie bitte den Reset / WPS Button <Link to="/Innenkameras/IN-8015_HD/Product_Features/">#12</Link> und halten ihn für 3 Sekunden gedrückt - 10 Sekunden würde zu einem Werksreset führen!</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qsP2PWPS05}
                    alt="IN-8015 Full HD Quick Installation"
                  />
              }
              title='Step 5'
              description={
                <p>Um die Kamera über den P2P Dienst in unserer Smartphone App hinzuzufügen scannen Sie bitte den <Link to="/Web_User_Interface/1080p_Serie/Netzwerk/Fernzugriff/">QR Code</Link> auf der Kamera oder in der Weboberfläche der Kamera ab.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qsP2PWPS06}
                    alt="IN-8015 Full HD Quick Installation"
                  />
              }
              title='Step 6'
              description={
                <p>Starten Sie dafür unsere kostenlose <Link to="/Software/Android/InstarVision/P2P/">Android</Link>, <Link to="/Software/iOS/InstarVision/iPhone/P2P/">iOS</Link> oder <Link to="/Software/Windows/InstarVision/Windows_Phone/P2P/">Windows Phone</Link> App <strong>InstarVision</strong>. Wählen Sie das Hinzufügen einer neuen P2P Kamera und starten Sie den QR Code Scanner.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qsP2PWPS07}
                    alt="IN-8015 Full HD Quick Installation"
                  />
              }
              title='Step 7'
              description={
                <p>Der <Link to="/Web_User_Interface/1080p_Serie/Netzwerk/Fernzugriff/">QR code</Link> beinhaltet alle Informationen zum Einbinden der Kamera. Das Standard Kennwort kann nur über die Weboberfläche der Kamera geändert werden. Für weitere Informationen, lesen Sie bitte die <Link to="/Innenkameras/IN-8015_HD/Schnell_Installation/">Schnell Installation Anleitung</Link>.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qsP2PWPS08}
                    alt="IN-8015 Full HD Quick Installation"
                  />
              }
              title='Step 8'
              description={
                <p>Die App wird jetzt den Live Stream der Kamera abgreifen können und Ihnen die Möglichkeit geben die Kamera zu schwenken, den Audiostream zu aktivieren und einen Schnappschuss zu speichern.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
