import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class MQTTTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "MQTT Settings" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'MQTT aktivieren', descriptionColumn: 'Der MQTT-Dienst ist standardmäßig deaktiviert. Verwenden Sie diesen Schalter, um den Broker zu starten.' },
        { actionColumn: 'MQTT über Websocket (WS) aktivieren', descriptionColumn: 'Aktivieren Sie die Tunnelung des MQTT-Dienstes über eine Webbrowser-konforme Websocket-Verbindung.' },
        { actionColumn: 'Externen MQTT Broker verwenden', descriptionColumn: 'Standardmäßig wird der interne `127.0.0.1` Broker verwendet. Wenn Sie Ihre Kamera an einen anderen Broker anbinden möchten, aktivieren Sie hier die Option und geben Sie die Adresse unten ein.' },
        { actionColumn: 'MQTT Server Adresse', descriptionColumn: 'Standardmäßig wird der interne `127.0.0.1` Broker verwendet. Wenn Sie Ihre Kamera an einen anderen Broker anbinden möchten, aktivieren Sie oben die Option und geben Sie hier die Adresse ein.' },
        { actionColumn: 'MQTT Server Port', descriptionColumn: 'Dies ist der Port, über den der MQTT-Broker eine Verbindung zu Clients ohne TLS-Verschlüsselung herstellt. Der Standardwert ist 1883.' },
        { actionColumn: 'WS Server Port', descriptionColumn: 'Dies ist der Port, den ein MQTT-Client verwenden kann, um sich ohne TLS-Verschlüsselung über einen Websocket-Tunnel mit Ihrem Broker zu verbinden. Der Standardwert ist "1885".' },
        { actionColumn: 'MQTT Server Port (TLS)', descriptionColumn: 'Dies ist der Port, den der MQTT-Broker verwendet, um eine Verbindung zu Clients mit TLS-Verschlüsselung herzustellen. Der Standardwert ist 8883.' },
        { actionColumn: 'WSS Server Port (TLS)', descriptionColumn: 'Dies ist der Port, den ein MQTT-Client verwenden kann, um eine Verbindung zu Ihrem Broker mit TLS-Verschlüsselung über einen Websocket-Tunnel herzustellen. Der Standardwert ist "8885".' },
        { actionColumn: 'Client-TLS-Zertifikat verifizieren', descriptionColumn: 'Lassen sie überprüfen, ob alle verbindenden Clients ein gültiges CA-TLS-Zertifikat verwenden. Lassen Sie die Verbindung nicht zu, wenn die Überprüfung fehlschlägt. Wenn Sie das INSTAR-TLS-Zertifikat Ihrer Kamera verwenden möchten, stellen Sie sicher, dass Sie die INSTAR-DDNS-Adresse anstelle der lokalen IP-Adresse Ihres Clients für die Verbindung verwenden.' },
        { actionColumn: 'Quality-of-Service (QoS)', descriptionColumn: 'Nachrichten werden "fire-and-forget" gesendet (QoS=0), gesendet und erneut gesendet, bis sie empfangen wurden (QoS=1, Standard) oder gesendet, bis sie empfangen wurden UND es wird sichergestellt, dass sie nur einmal empfangen werden (QoS=2, nur für Zeitreihendaten)' },
        { actionColumn: 'Authentifizierung', descriptionColumn: 'Die Authentifizierung muss aktiviert sein, wenn Sie die Kamera als Broker verwenden. Wenn Sie bereits einen anderen Broker in ihrem Netzwerk haben und die Kamera als Klient einbinden, kann sie, wenn gewünscht, deaktiviert werden.' },
        { actionColumn: 'Benutzername', descriptionColumn: 'Geben Sie den Benutzernamen ein, den Sie für den Zugriff auf den MQTT Broker verwenden möchten.' },
        { actionColumn: 'Kennwort', descriptionColumn: 'Fügen Sie hier das entsprechende Passwort hinzu.' },
        { actionColumn: 'Test', descriptionColumn: 'Testen Sie den MQTT-Alarmserver. Der Alarmserver, z.B. Ihr Smarthome-System, muss ein Abonnement haben für "alarm/triggered" und "alarm/triggered/object".' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

MQTTTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(MQTTTable)