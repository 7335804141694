import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Serie/Netzwerk/IP_Konfiguration/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_Network_IP_Configuration.webp'
                    alt="Netzwerk Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IP Konfiguration"
              description="Ändern Sie die IP-Adresse, das Gateway und den DNS-Server Ihrer Kamera - alle diese Einstellungen werden normalerweise vom Installationsassistenten eingerichtet und müssen nicht geändert werden..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/720p_Serie/Netzwerk/IP_Konfiguration/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Serie/Netzwerk/WLAN/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_Network_WLAN.webp'
                    alt="Netzwerk Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="WLAN"
              description="Ihre WiFi-Verbindung sollte bereits per WPS oder durch den Installationsassistenten eingerichtet worden sein. Verwenden Sie den WiFi-Scanner, um Ihre Kamera mit einem anderen WiFi-Netzwerk zu verbinden..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/720p_Serie/Netzwerk/WLAN/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Serie/Netzwerk/DDNS/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_Network_DDNS.webp'
                    alt="Netzwerk Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="DDNS Dienst"
              description="Sie haben zwei Möglichkeiten, über das Internet auf Ihre Kamera zuzugreifen - der einfache Weg heißt P2P. Der einzige Weg, um vollständigen Zugriff auf alle Funktionen Ihrer Kamera zu erhalten, heißt DDNS..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/720p_Serie/Netzwerk/DDNS/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Serie/Netzwerk/P2P/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_Network_P2P.webp'
                    alt="Netzwerk Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="P2P Service"
              description="Der P2P-Zugang ermöglicht Ihnen den Zugriff auf den Live-Video-Stream Ihrer Kamera über unsere iOS- oder Android-App. Scannen Sie einfach den P2P-QR-Code mit unserer App und bestätigen Sie die Einstellungen..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/720p_Serie/Netzwerk/P2P/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Serie/Netzwerk/UPnP/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_Network_UPnP.webp'
                    alt="Netzwerk Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="UPnP Dienst"
              description="Der UPnP-Standard soll Ihnen das Leben leicht machen. Jeder Port, der von einem Gerät in Ihrem Netzwerk öffentlich benötigt wird, wird automatisch weitergeleitet..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/720p_Serie/Netzwerk/UPnP/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Serie/Netzwerk/Push_Dienst/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_Network_Push.webp'
                    alt="Netzwerk Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Push Dienst"
              description="Ihre Kamera kann Sie per E-Mail benachrichtigen, wenn eine Bewegungserkennung ausgelöst wird. Eine Push-Benachrichtigung erfüllt den gleichen Zweck, ist aber viel schneller! Aktivieren Sie Push, wenn Sie..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/720p_Serie/Netzwerk/Push_Dienst/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
