import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1080p_Serie/Alarm/Alarm_Server/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1080p_SearchThumb_Smarthome_Alarmserver.webp'
                    alt="Smarthome Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Alarm Server"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1080p_Serie/Alarm/Alarm_Server/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1080p_Serie/Netzwerk/MQTT/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1080p_SearchThumb_Smarthome_MQTT.webp'
                    alt="Smarthome Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="MQTT Broker"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1080p_Serie/Netzwerk/MQTT/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1080p_Serie/Smarthome/Alexa_Skill/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1080p_SearchThumb_Smarthome_Alexa.webp'
                    alt="Smarthome Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Alexa Skill"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1080p_Serie/Smarthome/Alexa_Skill/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1080p_Serie/Smarthome/IFTTT_Applets/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1080p_SearchThumb_Smarthome_IFTTT.webp'
                    alt="Smarthome Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IFTTT Applets"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1080p_Serie/Smarthome/IFTTT_Applets/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1080p_Serie/Smarthome/Homekit/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1080p_SearchThumb_Smarthome_Homekit.webp'
                    alt="Smarthome Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="INSTAR Homekit"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1080p_Serie/Smarthome/Homekit/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
