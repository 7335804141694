
import React, { useState } from 'react'
import {Link} from 'gatsby'

import {
  EuiCallOut,
  EuiFlyout,
  EuiFlyoutHeader,
  EuiFlyoutBody,
  EuiButton,
  EuiTitle,
  EuiText,
  EuiSpacer
} from '@elastic/eui'

import homekitBanner01 from "images/Products/Homekit/Homekit_1080p_Smartphone_01.webp"
import homekitBanner02 from "images/Products/Homekit/Homekit_1080p_Smartphone_02.webp"

export default () => {
  const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);

  const closeFlyout = () => setIsFlyoutVisible(false);

  const showFlyout = () => setIsFlyoutVisible(true);

  let flyout;

  const callOut = (
    <EuiCallOut iconType="help">
      <p>
        Die Anbindung an den Homekit Dienst steht ab der Firmware Version <code>3.2(361)</code> (<em>Juni 2021</em>) für alle Full HD Modelle zur Verfügung. Sie können die Firmware Ihrer Kamera direkt über die <Link to="/Schnell_Installation/WebUI_und_Firmware_Upgrade/">Weboberfläche aktualisieren</Link>.
      </p>
    </EuiCallOut>
  );

  if (isFlyoutVisible) {
    flyout = (
      <EuiFlyout
        ownFocus
        onClose={closeFlyout}
        size="l"
        aria-labelledby="flyoutWithBannerTitle">
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="m">
            <h2 id="flyoutWithBannerTitle">Apple Homekit</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody banner={callOut}>
          <EuiText>
            <h4>Hinzufügen Ihrer Kamera</h4>
            <p>Verwenden Sie Ihre INSTAR Full HD IP-Kamera in Apple Homekit. HomeKit ist ein Software-Framework von Apple, das in iOS/iPadOS zur Verfügung gestellt wird und mit dem Sie mit Ihrer Kamera über Apple-Geräte kommunizieren und diese steuern können. Es bietet Ihnen eine Möglichkeit, Ihre Kamera automatisch zu erkennen und auf ihr Live-Video zuzugreifen.</p>
          </EuiText>
          <EuiSpacer />
          <img alt="INSTAR Homekit Support" src={homekitBanner01} style={{ maxWidth: '100%', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px'}} />
          <EuiSpacer />
          <EuiText>
            <p><Link to="/Web_User_Interface/1080p_Serie/Smarthome/Homekit/">Aktivieren Sie den Homekit-Dienst</Link> und öffnen Sie die Homekit-App auf Ihrem iOS-Gerät und scannen Sie entweder den QR-Code hier in der WebUI Ihrer Kameras oder verwenden Sie den automatischen Netzwerkscan, indem Sie auswählen, dass Sie keinen QR-Code zum Scannen haben. Durch das Scannen des QR-Codes wird Ihre Kamera automatisch hinzugefügt. Wenn Sie den Netzwerkscan verwendet haben, müssen Sie die Kamera, die Sie hinzufügen möchten, aus der Liste der Geräte auswählen, die in Ihrem Netzwerk erkannt wurden.</p>
          </EuiText>
          <EuiSpacer />
          <img alt="INSTAR Homekit Support" src={homekitBanner02} style={{ maxWidth: '100%', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px'}} />
          <EuiSpacer />
          <EuiText>
            <p>Wenn Sie den QR-Scanner nicht verwendet haben, werden Sie nun aufgefordert, den Pin-Code Ihrer Kamera einzugeben, der oberhalb des QR-Codes innerhalb der webUI angezeigt wird. Anschließend können Sie Ihrer Kamera einen Standort und einen Namen zuweisen und die Konfiguration speichern.</p>
          </EuiText>
          <EuiSpacer />
        </EuiFlyoutBody>
      </EuiFlyout>
    );
  }
  return (
    <div>
      <EuiSpacer />
      <EuiButton onClick={showFlyout} color="text">Homekit</EuiButton>
      {flyout}
    </div>
  );
};