import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Kennwort Richtlinien">
        <EuiText>
            <p>Das WLAN-Passwort ist nach dem WPA-Standard auf max. 63 Zeichen beschränkt und darf alle <a href="https://en.wikipedia.org/wiki/ASCII#Character_set" target="_blank" rel="noopener noreferrer">ASCII Zeichen</a> enthalten. Dies beinhaltet nicht die <a href="https://en.wikipedia.org/wiki/Extended_ASCII" target="_blank" rel="noopener noreferrer">Erweiterten ASCII Zeichen</a>. Achten Sie darauf, daß <strong>Umlaute</strong> z.B. nicht Teil dieses unterstüzten Zeichensatzes sind!</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

