import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class WiFiTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "WLAN Einstellungen" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Suche / Manuell', descriptionColumn: 'Bitte klicken Sie auf Suchen, damit Ihre Kamera nach drahtlosen Netzwerken suchen kann. Wählen Sie Ihr WLAN aus der Liste aus, fügen Sie Ihren WLAN-Schlüssel hinzu und senden Sie ihn ein. Nach der Bestätigung ziehen Sie einfach das Ethernet-Kabel ab und die Kamera wird sich mit dem WiFi-Netzwerk verbinden. Alternativ geben Sie den Netzwerkparameter manuell ein. Weitere Informationen zur WPS-Funktion finden Sie in unserem Installation Wizard.' },
        { actionColumn: 'SSID', descriptionColumn: 'Name Ihres WiFi-Netzwerks. Wenn Sie in Ihrem Netzwerk mehr als einen Access Point (AP) verwenden, wird empfohlen, jedem einzelnen SSID eindeutige SSIDs zuzuweisen. Auf diese Weise können Sie Ihre Kamera an den AP binden, der die beste Verbindung bietet.' },
        { actionColumn: 'Netzwerk Type', descriptionColumn: 'Wählen Sie, ob Sie Ihre Kamera direkt mit einem anderen Netzwerkgerät verbinden möchten (ad hoc), oder Sie die Kamera in Ihr lokales WiFi Netzwerk einbinden möchten (infra).' },
        { actionColumn: 'Authentifizierung/Verschlüsselung', descriptionColumn: 'Wählen Sie die Verschlüsselungsart, die Ihr Router verwendet. Wir empfehlen, dass Ihr Router reines WPA2 (AES oder CCMP) verwendet und <u>nicht</u> WPA+WPA2 (TKIP).' },
        { actionColumn: 'Type', descriptionColumn: 'Wählen Sie die Verschlüsselungsart, die Ihr Router verwendet. Wir empfehlen, dass Ihr Router reines WPA2 (AES oder CCMP) verwendet und <u>nicht</u> WPA+WPA2 (TKIP). Wenn Ihre Kamera erkennt, dass Ihr Verschlüsselungstyp TKIP ist, versuchen Sie einfach Ihre Kamera einzubinden - es besteht eine gute Chance, dass es funktioniert. Wenn jedoch Probleme auftreten, ändern Sie den Verschlüsselungstyp in Ihrem Router wie empfohlen und suchen Sie erneut nach dem WiFi-Netzwerk mit Ihrer Kamera. Ihre Kamera erkennt die Änderung. Klicken Sie auf Test, um sie zu testen und anzuwenden.' },
        { actionColumn: 'Kennwort', descriptionColumn: 'Hier müssen Sie Ihren WiFi Schlüssel eingeben. Der Standardschlüssel kann meistens auf einem Aufkleber auf dem Router gefunden werden.' },
        { actionColumn: 'Bevorzugter WLAN Access Point', descriptionColumn: 'Wenn Ihr Netzwerk über mehr als einen Access Point mit demselben Netzwerknamen (SSID) verfügt, können Sie jetzt die MAC-Adresse (BSSID) des Access Points sperren, mit dem Ihre Kamera verbunden werden soll. Dadurch wird vermieden, dass Ihre Kamera eine Verbindung zu einem Access Point mit einem schwächeren Signal herstellt.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

WiFiTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(WiFiTable)