import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class FeatureTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "numberColumn", title: "#" },
        { name: "componentColumn", title: "Komponente" },
        { name: "functionColumn", title: "Funktion" },
      ],
      rows: [
        { numberColumn: '01', componentColumn: 'Helligkeitsensor', functionColumn: 'Twilight-Switch for IR LEDs' },
        { numberColumn: '02', componentColumn: 'Objektiv', functionColumn: 'Wideangle lense (focal length: 4.2mm / field of view: 72.3.º hor.)' },
        { numberColumn: '03', componentColumn: 'Infrared-LEDs', functionColumn: '10 IR LEDs (nightvision @ 850nm)' },
        { numberColumn: '04', componentColumn: 'Camera Head', functionColumn: 'Controllable via the User Interface and Smartphone App' },
        { numberColumn: '05', componentColumn: 'PIR Detector', functionColumn: 'Integrated Passive Infrared Motion Detector' },
        { numberColumn: '06', componentColumn: 'MicroSD Kartenleser', functionColumn: 'Slot for SD/SDHC cards (up to 128 GB - 16 GB included)' },
        { numberColumn: '07', componentColumn: 'Audio Out', functionColumn: '3.5mm earphone / active speaker jack' },
        { numberColumn: '08', componentColumn: 'Netzwerkanschluss', functionColumn: 'RJ45 LAN-port for the provided CAT5e LAN cable (For the power supply according to IEEE802.3af only in case of the IN-8015 HD PoE!)' },
        { numberColumn: '09', componentColumn: 'Antennen Anschluss', functionColumn: 'SMA WiFi Antennenanschluss (Not available in case of the IN-8015 HD PoE model!)' },
        { numberColumn: '10', componentColumn: 'LED Indicator', functionColumn: 'Red - Switchable Status-LED for network traffic' },
        { numberColumn: '11', componentColumn: 'Alarm I/O', functionColumn: 'Alarm Output (1&2) / Input (3&4)' },
        { numberColumn: '12', componentColumn: 'Reset Button', functionColumn: 'Setzen Sie Ihre Kamera auf die Werkseinstellungen zurück' },
        { numberColumn: '13', componentColumn: 'Netzteilanschluss', functionColumn: '5V / 1.5 A DC - Plug Ø : 1.35mm (in) / 3.5mm (out)' },
        { numberColumn: '14', componentColumn: 'Camera ID', functionColumn: 'MAC address LAN and QR for the P2P connection' },
        { numberColumn: '15', componentColumn: 'Stand Mount', functionColumn: 'Standard camera thread for provided stand support' }
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

FeatureTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(FeatureTable)


// import React from 'react'
// import {Table, Column, Cell} from 'fixed-data-table-2'
// import '../../../../assets/styles/data_tables/fixed-data-table.css'

// const numberRow = [
//   '01',
//   '02',
//   '03',
//   '04',
//   '05',
//   '06',
//   '07',
//   '08',
//   '09',
//   '10',
//   '11',
//   '12',
//   '13',
//   '14',
//   '15',
// ];

// const componentRow = [
//   'Photo sensor',
//   'Lense',
//   'Infrared-LEDs',
//   'Camera Head',
//   'PIR Detector',
//   'MicroSD Kartenleser',
//   'Audio Out',
//   'Network Port',
//   'Antenna Connector',
//   'LED Indicator',
//   'Alarm I/O',
//   'Reset Button',
//   'Power Connector',
//   'Camera ID',
//   'Stand Mount',
// ];

// const functionRow = [
//   'Twilight-Switch for IR LEDs',
//   'Wideangle lense (focal length: 4.2mm / field of view: 72.3.º hor.)',
//   '10 IR LEDs (nightvision @ 850nm)',
//   'Controllable via the User Interface and Smartphone App',
//   'Integrated Passive Infrared Motion Detector',
//   'Slot for SD/SDHC cards (up to 128 GB - 16 GB included)',
//   '3.5mm earphone / active speaker jack',
//   'RJ45 LAN-port for the provided CAT5e LAN cable (For the power supply according to IEEE802.3af only in case of the IN-8015 HD PoE!)',
//   'SMA WiFi Antennenanschluss (Not available in case of the IN-8015 HD PoE model!)',
//   'Red - Switchable Status-LED for network traffic',
//   'Alarm Output (1&2) / Input (3&4)',
//   'Setzen Sie Ihre Kamera auf die Werkseinstellungen zurück',
//   '5V / 1.5 A DC - Plug Ø : 1.35mm (in) / 3.5mm (out)',
//   'MAC address LAN and QR for the P2P connection',
//   'Standard camera thread for provided stand support',
// ];

// // Render your table
// const FeatureTable = () => {
//     return (
//       <Table
//         rowHeight={55}
//         rowsCount={numberRow.length}
//         touchScrollEnabled={true}
//         width={850}
//         maxHeight={1000}
//         headerHeight={50}>
//         <Column
//           header={<Cell>#</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {numberRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={1}
//           width={50}
//         />
//         <Column
//           header={<Cell>Component</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {componentRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={2}
//           width={250}
//         />
//         <Column
//           header={<Cell>Function</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {functionRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={3}
//           width={535}
//         />
//       </Table>
//     )
// }

// export default FeatureTable