import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class AudioAlarmTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Audio Alarm" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Audio Alarm durch akustisches Signal', descriptionColumn: 'Wenn Ihre Kamera ein internes Mikrofon besitzt - oder Sie ein externes Mikrofon in den Audio-In Eingang Ihrer Kamera eingesteckt haben - können Sie es als Auslöser für Geräusche vor Ihrer Kamera verwenden. Das könnte nützliche sein, wenn Sie Ihre Kamera als Babyphone benutzen wollen.  Der Audio Alarm kann alle eingestellten Alarm Aktionen auslösen, ebenso die Push-Benachrichtigung.' },
        { actionColumn: 'Audio Alarm Sensibilität', descriptionColumn: 'Stellen Sie hier die Sensibilität der Audio Erkennung ein. Bitte beachten Sie, dass die Sensibilität auch abhängig von der Signalstärke des Audio Eingangs ist.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

AudioAlarmTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(AudioAlarmTable)