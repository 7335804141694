import React from 'react'
import { Link } from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="FHEM">
        <EuiText>
         <h2>Alle ioBroker Tutorials</h2>
         <ul>
            <li><Link to="/Erweitert/FHEM_auf_Raspberry_Pi/">FHEM auf einem Raspberry Pi</Link></li>
         </ul>
         
         <h2>All ioBroker FAQs</h2>
         <ul>
           <li><Link to="/Frequently_Asked_Question/FHEM_mit_INSTAR_WQHD_Kameras/">FHEM mit INSTAR WQHD Kameras</Link></li>
         </ul>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox