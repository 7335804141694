import React from 'react'
import {Link} from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-gtag'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import qsQuickInstall01 from "../../../../images/Indoor_Cameras/IN-8015_HD/Quick_Installation/Quickinstallation_Tile_8015_Step_01.webp"
import qsQuickInstall02 from "../../../../images/Indoor_Cameras/IN-8015_HD/Quick_Installation/Quickinstallation_Tile_8015_Step_02.webp"
import qsQuickInstall03 from "../../../../images/Indoor_Cameras/IN-8015_HD/Quick_Installation/Quickinstallation_Tile_8015_Step_03.webp"
import qsQuickInstall04 from "../../../../images/Indoor_Cameras/IN-8015_HD/Quick_Installation/Quickinstallation_Tile_8015_Step_04.webp"
import qsQuickInstall05 from "../../../../images/Indoor_Cameras/IN-8015_HD/Quick_Installation/Quickinstallation_Tile_8015_Step_05.webp"
import qsQuickInstall06 from "../../../../images/Indoor_Cameras/IN-8015_HD/Quick_Installation/Quickinstallation_Tile_8015_Step_06.webp"
import qsQuickInstall07 from "../../../../images/Indoor_Cameras/IN-8015_HD/Quick_Installation/Quickinstallation_Tile_8015_Step_07.webp"
import qsQuickInstall08 from "../../../../images/Indoor_Cameras/IN-8015_HD/Quick_Installation/Quickinstallation_Tile_8015_Step_08.webp"
import qsQuickInstall09 from "../../../../images/Indoor_Cameras/IN-8015_HD/Quick_Installation/Quickinstallation_Tile_8015_Step_09.webp"
import qsQuickInstall10 from "../../../../images/Indoor_Cameras/IN-8015_HD/Quick_Installation/Quickinstallation_Tile_8015_Step_10.webp"
import qsQuickInstall11 from "../../../../images/Indoor_Cameras/IN-8015_HD/Quick_Installation/Quickinstallation_Tile_8015_Step_11.webp"
import qsQuickInstall12 from "../../../../images/Indoor_Cameras/IN-8015_HD/Quick_Installation/Quickinstallation_Tile_8015_Step_12.webp"




export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qsQuickInstall01}
                    alt="IN-8015 Full HD Quick Installation"
                  />
              }
              title='Step 1'
              description={
                <p>Stecken Sie die WiFi Antenne in den dafür vorgesehenen Anschluss an der Kamera wie hier gezeigt (<Link to="/Innenkameras/IN-8015_HD/Product_Features/">#9</Link>). (nur für nicht-PoE Version!)</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qsQuickInstall02}
                    alt="IN-8015 Full HD Quick Installation"
                  />
              }
              title='Step 2'
              description={
                <p>Stecken Sie das Netzteil ein (<Link to="/Innenkameras/IN-8015_HD/Product_Features/">#13</Link>), um die Kamera zu starten.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qsQuickInstall03}
                    alt="IN-8015 Full HD Quick Installation"
                  />
              }
              title='Step 3'
              description={
                <p>Verbinden Sie das Netzwerkkabel auf der Rückseite der Kamera (s. <Link to="/Innenkameras/IN-8015_HD/Product_Features/">#8</Link>), um eine LAN Verbindung herzustellen. Wenn Sie das PoE-Modell installieren, verbinde Sie die Kamera mit Ihrem Router und überspringe den folgenden WLAN-Bereich.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qsQuickInstall04}
                    alt="IN-8015 Full HD Quick Installation"
                  />
              }
              title='Step 4'
              description={
                <p>Verbinden Sie das Netzwerkkabel an einen freien LAN-Port an Ihrem Internet Router.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qsQuickInstall05}
                    alt="IN-8015 Full HD Quick Installation"
                  />
              }
              title='Step 5'
              description={
                <p>Starten Sie das <OutboundLink href="https://install.instar.com/WQHDv2/IN-9408_WQHD_Installation/01_LAN_Integration/03_Find_your_Camera" target="_blank" rel="noopener noreferrer">IP Camera Tool</OutboundLink> von und Doppelklicken Sie den Namen der Kamera.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qsQuickInstall06}
                    alt="IN-8015 Full HD Quick Installation"
                  />
              }
              title='Step 6'
              description={
                <p>Standardlogin Benutzername / Kennwort:  <strong>admin / instar</strong>. Unser <a href="https://install.instar.com" target="_blank" rel="noopener noreferrer">Installation Wizard</a> wird Sie danach durch den restlichen Installationsprozess führen.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qsQuickInstall07}
                    alt="IN-8015 Full HD Quick Installation"
                  />
              }
              title='Step 7'
              description={
                <p>Für die Wiedergabe des h.264 Video Streams benötigen Sie das <Link to="/Schnell_Installation/Live_Video/">Adobe Flash Plugin</Link>. Für eine Plugin-freie Wiedergabe können Sie den, etwas Bandbreite-fordernden <Link to="/Web_User_Interface/1080p_Serie/Multimedia/Video/">MJPEG Stream</Link> nutzen.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qsQuickInstall08}
                    alt="IN-8015 Full HD Quick Installation"
                  />
              }
              title='Step 8'
              description={
                <p>Für die WLAN Einbindung wird es empfohlen Ihren WiFi Router auf ein freies WiFi Frequenzband (802.11bgn) zu setzen und die WPA2/AES (oder WPA2/CCMP) Verschlüsselung zu aktivieren.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qsQuickInstall09}
                    alt="IN-8015 Full HD Quick Installation"
                  />
              }
              title='Step 9'
              description={
                <p>Greifen Sie auf die <Link to="/Web_User_Interface/1080p_Serie/Netzwerk/WLAN/">WiFi Einstellungen</Link> Ihrer Kamera zu und geben Sie die WLAN Informationen ein. Speichern Sie Ihre Eingaben, stecken Sie das LAN-Kabel ab und starten Sie Ihre Kamera neu, um eine Verbindung mit dem WLAN herzustellen.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qsQuickInstall10}
                    alt="IN-8015 Full HD Quick Installation"
                  />
              }
              title='Step 10'
              description={
                <p>Sie müssen eine <Link to="/Fernzugriff/Port_Weiterleitung/">Portweiterleitung</Link> in Ihrem Router einrichten, wenn Sie Ihre Kamera über die <Link to="/Fernzugriff/Der_DDNS_Dienst/">DDNS Adresse</Link> aus dem Internet erreichen möchten. Alternativ können Sie auch die <Link to="/Web_User_Interface/1080p_Serie/Netzwerk/Fernzugriff/">P2P Benutzer ID</Link> nutzen, um die Kamera mit Ihrer SmartPhone App zu verbinden (siehe Schritt 12 unten).</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qsQuickInstall11}
                    alt="IN-8015 Full HD Quick Installation"
                  />
              }
              title='Step 11'
              description={
                <p>Jetzt können Sie Ihre DDNS Informationen in unsere kostenlosen <Link to="/Software/Windows/InstarVision/Windows_Phone/DDNS/">Windows Phone</Link>, <Link to="/Software/Windows/InstarVision/Metro/DDNS/">Windows Metro</Link>, <Link to="/Software/Android/InstarVision/DDNS/">Android</Link>, <Link to="/Software/iOS/InstarVision/iPhone/DDNS/">iPhone</Link>, <Link to="/Software/iOS/InstarVision/iPad/DDNS/">iPad</Link> oder <Link to="/Software/Other_Platforms/InstarVision_Blackberry/">Blackberry</Link> App eingeben.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qsQuickInstall12}
                    alt="IN-8015 Full HD Quick Installation"
                  />
              }
              title='Step 12'
              description={
                <p>Falls Sie keinen Port für Ihren DDNS-Dienst öffnen können, können Sie immer noch den <Link to="/Web_User_Interface/1080p_Serie/Netzwerk/Fernzugriff/">Point-2-Point Dienst</Link> mit unseren kostenlosen <Link to="/Software/Windows/InstarVision/Windows_Phone/P2P/">Windows Phone</Link>, <Link to="/Software/Windows/InstarVision/Metro/P2P/">Windows Metro</Link>, <Link to="/Software/Android/InstarVision/P2P/">Android</Link>, <Link to="/Software/iOS/InstarVision/iPhone/P2P/">iPhone</Link>, <Link to="/Software/iOS/InstarVision/iPad/P2P/">iPad</Link> oder <Link to="/Software/Other_Platforms/InstarVision_Blackberry/">Blackberry</Link> Apps nutzen.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
