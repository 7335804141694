import React from 'react'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText
} from '@elastic/eui'

import lenseAdjust01 from "../../../../images/Outdoor_Cameras/IN-5907_HD/Lense_Adjustment/Quickinstallation_Lense-Adjust_5907_01.webp"
import lenseAdjust02 from "../../../../images/Outdoor_Cameras/IN-5907_HD/Lense_Adjustment/Quickinstallation_Lense-Adjust_5907_02.webp"
import lenseAdjust03 from "../../../../images/Outdoor_Cameras/IN-5907_HD/Lense_Adjustment/Quickinstallation_Lense-Adjust_5907_03.webp"
import lenseAdjust04 from "../../../../images/Outdoor_Cameras/IN-5907_HD/Lense_Adjustment/Quickinstallation_Lense-Adjust_5907_04.webp"



export default function QIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={lenseAdjust01}
                    alt="I. Entfernen Sie die obere Abdeckung der IN-5907 HD. Halten Sie das Kameragehäuse fest und entfernen Sie die vordere Abdeckung durch drehen gegen den Uhrzeigersinn."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    <strong>I</strong>. Entfernen Sie die obere Abdeckung der IN-5907 HD. Halten Sie das Kameragehäuse fest und entfernen Sie die vordere Abdeckung durch drehen gegen den Uhrzeigersinn.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={lenseAdjust02}
                    alt="II. Entfernen Sie danach einfach die 3 äußeren Schrauben (nicht die 2 inneren Schrauben)."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    <strong>II.</strong> Entfernen Sie danach einfach die 3 äußeren Schrauben (nicht die 2 inneren Schrauben).
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={lenseAdjust03}
                    alt="III. Ziehen Sie nun vorsichtig das Objektiv und den Sensor aus dem Gehäuse und lösen Sie die seitliche Schraube, die den oberen Part des Objektiv fixiert."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    <strong>III.</strong> Ziehen Sie nun vorsichtig das Objektiv und den Sensor aus dem Gehäuse und lösen Sie die seitliche Schraube, die den oberen Part des Objektiv fixiert.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={lenseAdjust04}
                    alt="IV. Öffnen Sie die Kamera im WebUI. Während Sie das Bild auf dem PC sehen, drehen Sie am oberen Teil des Objektives (achten Sie darauf, daß sich das ganze Objektiv nicht aus der Fassung löst) bis das Bild die korrekte Schärfe erreicht. Fixieren Sie das Objektiv und setzen Sie die Kamera in umgekehrter Reihenfolge wieder zusammen."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    <strong>IV.</strong> Öffnen Sie die Kamera im WebUI. Während Sie das Bild auf dem PC sehen, drehen Sie am oberen Teil des Objektives (achten Sie darauf, daß sich das ganze Objektiv nicht aus der Fassung löst) bis das Bild die korrekte Schärfe erreicht. Fixieren Sie das Objektiv und setzen Sie die Kamera in umgekehrter Reihenfolge wieder zusammen.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
