import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/styles'
import {
  SortingState, SelectionState, FilteringState, GroupingState,
  IntegratedFiltering, IntegratedGrouping, IntegratedSelection,
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table, TableHeaderRow, TableFilterRow, TableGroupRow,
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class TechSpecGrid extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
        columns: [
          { name: "category", title: "Kategorie" },
          { name: "feature", title: "Feature" },
          { name: "property", title: "Eigenschaft" }
        ],
        rows: [
          { category: 'Optik & Imaging', feature: 'Standard Objektiv', property: 'f = 2.8mm ~ 12mm  F/1.8 (IR Lense)' },
          { category: 'Optik & Imaging', feature: 'Blickwinkel', property: '36° ~ 120° diagonal field of view (diag.)' },
          { category: 'Optik & Imaging', feature: 'Optischer Zoom', property: '4x' },
          { category: 'Optik & Imaging', feature: 'Bildsensor', property: 'Panasonic MN34229 1080p 1/3" CMOS Wide Dynamic Range [2]' },
          { category: 'Optik & Imaging', feature: 'Sensorfläche', property: '5,346 (H) µm x 3,003 (V) µm' },
          { category: 'Optik & Imaging', feature: 'Auflösung', property: '2.12 Megapixel' },
          { category: 'Optik & Imaging', feature: 'Unterstützte Auflösungen', property: '1080p, 320p, 160p' },
          { category: 'Optik & Imaging', feature: 'Min. Beleuchtung (Farbe)', property: '0.05 Lux @ f/2.1' },
          { category: 'Optik & Imaging', feature: 'Video Kompression', property: 'h.264, MJPEG, JPG' },
          { category: 'Optik & Imaging', feature: 'h.264 Bitrate', property: '90 kbps - 4.096 kbps' },
          { category: 'Optik & Imaging', feature: 'Framerate', property: 'bis zu 30fps' },
          { category: 'Optik & Imaging', feature: '# an parallelen Streams', property: '3' },
          { category: 'Optik & Imaging', feature: 'Video Overlay', property: 'Datum, Uhrzeit, Kamera Name' },
          { category: 'Optik & Imaging', feature: 'Video Parameter', property: 'Helligkeit, Kontrast, Saturation, Schärfe, Gamma, Belichtung' },
          { category: 'Optik & Imaging', feature: 'Tag / Nacht Wechsel', property: 'Ja' },
          { category: 'Optik & Imaging', feature: 'Weißabgleich', property: 'Automatisch' },
          { category: 'Optik & Imaging', feature: 'Elektronische Blende', property: 'Automatisch' },
          { category: 'Optik & Imaging', feature: 'Video Drehen / Spiegeln', property: 'Ja / Ja' },
          { category: 'Nachtsicht', feature: 'Infrarot LEDs', property: '6 high-power LEDs @ 850 nm' },
          { category: 'Nachtsicht', feature: 'IR Modes', property: 'aus/Automatisch' },
          { category: 'Nachtsicht', feature: 'Effektive Reichweite', property: '20-25m' },
          { category: 'Nachtsicht', feature: 'intgr. IRcut Filter', property: 'Ja (Automatisch)' },
          { category: 'Netzwerk', feature: 'Netzwerkschnittstelle', property: '10Base-T/100Base-TX Ethernet Port / WLAN IEEE 802.11b/g/n' },
          { category: 'Netzwerk', feature: 'Network Protocols', property: 'TCP/IP, DHCP, PPPoE, ARP, FTP, SMTP, DNS, NTP, UPnP, HTTP, TCP, UDP, MQTT' },
          { category: 'Netzwerk', feature: 'SSL Verschlüsselung', property: 'HTTPS & FTPS' },
          { category: 'Netzwerk', feature: 'WLAN Verschlüsselung', property: 'WPA/WPA2-PSK (TKIP, AES, CCMP)' },
          { category: 'Netzwerk', feature: 'WLAN Frequenzen', property: '2.4GHz, IEEE 802.11b/g/n bis zu 150Mbps' },
          { category: 'Netzwerk', feature: 'WiFi Antenne', property: '5 dBi WLAN RP-SMA Antenne [3]' },
          { category: 'Netzwerk', feature: 'WiFi Reichweite', property: 'ca. 8-12m' },
          { category: 'Netzwerk', feature: 'Free DDNS Address', property: 'Ja' },
          { category: 'Netzwerk', feature: 'kostenlose P2P UID', property: 'Ja' },
          { category: 'Features', feature: 'Zugangsbeschränkung', property: 'Benutzername/Passwort - 3 Level Autorisierung' },
          { category: 'Features', feature: 'Bewegungserkennung', property: 'Ja (Software Analyse + PIR)' },
          { category: 'Features', feature: 'Alarm Input', property: 'Nein' },
          { category: 'Features', feature: 'Alarm Output', property: 'Ja' },
          { category: 'Features', feature: 'PIR Sensor Winkel', property: '120° horizontal' },
          { category: 'Features', feature: 'PIR Reichweite', property: '12 Meter' },
          { category: 'Features', feature: 'Alarm Benachrichtigung', property: 'Email/FTP/HTTP (Alarmserver)/Push' },
          { category: 'Features', feature: 'Alarm Pre-Recording', property: 'Ja (3s)' },
          { category: 'Features', feature: 'Fotoserie', property: 'Ja (auf SD Karte und FTP Server)' },
          { category: 'Features', feature: 'SD Karte', property: 'Erweiterbare microSDHC Karte bis zu 128GB (16GB inklusive)  [4]' },
          { category: 'Features', feature: 'Cloud Speicherplatz', property: 'Ja (optional)' },
          { category: 'Features', feature: 'Pan & Tilt', property: '355° / 90°' },
          { category: 'Features', feature: 'Mikrofon', property: 'Integriertes Mikrofon' },
          { category: 'Features', feature: 'Scheduled & Alarm Recording', property: 'Ja' },
          { category: 'Hardware', feature: 'Gehäuse', property: 'IP65 Aluminium (weiß/schwarz)' },
          { category: 'Hardware', feature: 'Halterung', property: 'Wand & Deckenhalterung' },
          { category: 'Hardware', feature: 'Betriebstemperatur', property: '- 20°C bis 55°C (es wird kein extra Heizelement benötig)' },
          { category: 'Hardware', feature: 'Netzspannung', property: '110V - 230V' },
          { category: 'Hardware', feature: 'Netzwerkkabel', property: '3m Cat5e' },
          { category: 'Hardware', feature: 'Power over Ethernet', property: 'IEEE 802.3af  (+ Pin 4 and 5 / - Pin 7 and 8)' },
          { category: 'Hardware', feature: 'Netzteil', property: '110-240V ac @ 50-60Hz bis 12V dc und 2000mA - Kabellänge: 3m' },
          { category: 'Hardware', feature: 'Netzstecker', property: '2,1mm Innen / 5,5mm Außen' },
          { category: 'Hardware', feature: 'Stromverbrauch', property: 'Max. 16W (Normalbetrieb 5W)' },
          { category: 'Hardware', feature: 'Gewicht', property: '1900 g' },
          { category: 'Hardware', feature: 'Abmessungen in [cm]', property: '23 cm / 15 cm / 15 cm' },
          { category: 'Hardware', feature: 'Pyroelektrischer Bewegungsmelder', property: 'Panasonic PaPIR EKMC16 Long Distance Pyroelectric Sensor [5]' },
          { category: 'Hardware', feature: 'PIR Sensor Winkel', property: '102° x 92° (108° x 99°)' },
          { category: 'Hardware', feature: 'PIR Reichweite', property: 'bis zu 12m [6]' },
          { category: 'Hardware', feature: 'PIR Zonen', property: '92' },
          { category: 'Hardware', feature: 'PIR Bedingungen', property: 'Temp Unterschied > 4°C, Objektgeschwindigkeit: 1.0m/s, mit einer Größe von 700×250mm' },
          { category: 'Hardware', feature: 'Zertifikate', property: 'CE, FCC, RoHS' }
        ]
      };
    }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns} >

          <FilteringState />

          <SortingState
            defaultSorting={[
              { columnName: 'category', direction: 'asc' },
            ]}
          />
          <GroupingState
            defaultGrouping={[{ columnName: 'category' }]}
            defaultExpandedGroups={['Optik & Imaging']}
          />
          <SelectionState />

          <IntegratedFiltering />
          <IntegratedGrouping />
          <IntegratedSelection />
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
          <TableFilterRow />
          <TableGroupRow />
        </Grid>
      </Paper>
    );
  }
}

TechSpecGrid.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(TechSpecGrid)






















































































