import React from 'react'
import {Link} from 'gatsby'

import {
  EuiCard,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import ddnsNoIP from "../../../images/Internet_Access/DDNS_Provider/DDNS_No-IP.webp"
import ddnsSelfhost from "../../../images/Internet_Access/DDNS_Provider/DDNS_Selfhost.webp"
import ddnsDDNSS from "../../../images/Internet_Access/DDNS_Provider/DDNS_DDNSS.webp"




export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Fernzugriff/DDNS_Anbieter/NoIP/">
                  <img
                    src={ddnsNoIP}
                    alt="No-IP DDNS Dienst"
                  />
                </Link>
              }
              title='NoIP DDNS Dienst'
              description={
                <p>No-IP (English)</p>
              }
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Fernzugriff/DDNS_Anbieter/NoIP/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Fernzugriff/DDNS_Anbieter/Selfhost/">
                  <img
                    src={ddnsSelfhost}
                    alt="Selfhost DDNS Dienst"
                  />
                </Link>
              }
              title='Selfhost DDNS Dienst'
              description={
                <p>Selfhost (German)</p>
              }
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Fernzugriff/DDNS_Anbieter/Selfhost/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Fernzugriff/DDNS_Anbieter/DDNSS/">
                  <img
                    src={ddnsDDNSS}
                    alt="DDNSS DDNS Dienst"
                  />
                 </Link>
              }
              title='DDNSS DDNS Dienst'
              description={
                <p>DDNSS (English)</p>
              }
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Fernzugriff/DDNS_Anbieter/DDNSS/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
