import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Date & Time">
        <EuiText>
          <p>
            Sie können die interne Zeit Ihrer Kamera entweder manuell mit der Zeit des zugreifenden PCs oder automatisch mit Internet Zeitservern (NTP) synchronisieren. Solange die Kamera Kontakt zum Internet hat, empfehlen wir den Abgleich mit einem NTP Server Ihrer Wahl, um eine möglichst hohe Genauigkeit zu erreichen. Beachten Sie bitte, dass Sie die Sommerzeit manuell aktivieren müssen, wenn Sie sich in einer Zeitzone aufhalten, in der die Zeit im Sommer eine Stunde vorgestellt wird.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

