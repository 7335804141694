import React from 'react'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="FjE95bL7_p4"
                    title="An overview over DDNS and P2P Remote Access"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              title="An overview over DDNS and P2P Remote Access"
              footer={
                <EuiText>
                  <p>In diesem Video erklären wir Ihnen "hoffentlich" auf verständliche Weise wie DDNS funktioniert, was es ist und wie das Prinzip der Portweiterleitung funktioniert. Zudem zeigen wir auch kurz auf wie P2P funktioniert und aufgebaut ist.</p>
                  <p>Folgen Sie uns jetzt im INSTARTV YouTube Kanal, abbonieren Sie uns und erhalten Sie automatisch Informationen sobald neue Videos verfügbar sind damit Sie Ihre INSTAR IP Kamera noch besser nutzen können.</p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}
