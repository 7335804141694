import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class UserTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Nutzerrechte" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Grundrechte', descriptionColumn: 'Erforderliches Minimum an Rechten, um mit der Web-Benutzeroberfläche interagieren zu können.' },
        { actionColumn: 'Benutzer Verwaltung', descriptionColumn: 'Im Zusammenhang mit dem System-Recht ist dies die Berechtigung Benutzer anzulegen und Berechtigungen anzupassen. Wenn das System-Recht fehlt kann man nur den eigenen Account anpassen.' },
        { actionColumn: 'BasicAuthentication Erlauben', descriptionColumn: 'Berechtigung zum Zugriff auf die Kameradienste unter Verwendung der Basisauthentifizierung. Ohne das Authentizierungsrecht bleiben bereits erteilte Zugangstoken aktiv. Können jedoch nicht mehr erneuert werden. D.h. das WebUI Login ist nach dem Ablauf vorhandender Token nicht mehr möglich. Der P2P Zugriff, wenn erlaubt, ist auch ohne Basisauthentifizierung möglich.' },
        { actionColumn: 'Video Livestream', descriptionColumn: 'Erlaubnis zum Zugriff auf den Live-Videostream der Kamera.' },
        { actionColumn: 'RTSP Stream', descriptionColumn: 'Erlaubnis zum Zugriff auf den RTSP-Stream Ihrer Kamera.' },
        { actionColumn: 'SD Karten Zugriff', descriptionColumn: 'Berechtigung zum Zugriff auf den SD Karten Menü zum Betrachten und herunterladen von Alarmaufzeichnungen. Der SD Karten Viewer wird nur angezeigt, wenn eine SD Karte in der Kamera vorhanden ist.' },
        { actionColumn: 'Intercom (Audio Features)', descriptionColumn: 'Erlaubnis zum Zugriff auf den Live-Audio-Stream der Kamera.' },
        { actionColumn: 'Multimedia Menü Zugriff', descriptionColumn: 'Berechtigung zum Zugriff auf das Multimedia-Menü und zum Vornehmen von Änderungen an den Audio-, Video- und Bildeinstellungen Ihrer Kamera.' },
        { actionColumn: 'Alarm Menü Zugriff', descriptionColumn: 'Berechtigung zum Zugriff auf das Alarmmenü, zum Konfigurieren der Alarmaktionen, der Alarm-Bewegungserkennungsbereiche, der Objekterkennung, des Alarmzeitplans und der Push-Benachrichtigung.' },
        { actionColumn: 'Alarmrelais Einstellungen', descriptionColumn: 'Erlaubnis, das Alarmrelais der Kamera über die entsprechende Schaltflächenkachel zu steuern.' },
        { actionColumn: 'Flutlicht LEDs', descriptionColumn: 'Erlaubnis, die Wießlicht LEDs der Kamera (falls vorhanden) über die entsprechende Schaltflächenkachel zu steuern.' },
        { actionColumn: 'System Einstellungen', descriptionColumn: 'Berechtigung zum Zugriff auf das System, Netzwerk, Features und Smarthomemenü.' },
        { actionColumn: 'PTZ Positionen Speichern', descriptionColumn: 'Erlaubnis zum Speichern/Überschreiben der voreingestellten Schwenk-, Neige- und Zoompositionen.' },
        { actionColumn: 'PTZ Positionen Aufrufen', descriptionColumn: 'Erlaubnis zur Steuerung von Schwenken, Neigen und Zoomen der Kamera.' },
        { actionColumn: 'ONVIF Administrator', descriptionColumn: 'Berechtigung zum Zugriff auf das ONVIF-Menü und zur Verwaltung des ONVIF-Dienstes. Die Berechtigung "System Einstellungen" beinhaltet die Berechtigung "ONVIF Administrator".' },
        { actionColumn: 'ONVIF Operator', descriptionColumn: 'Erlaubnis zur Verwendung des ONVIF-Dienstes Ihrer Kamera zur Steuerung der Kamera.' },
        { actionColumn: 'ONVIF Benutzer', descriptionColumn: 'Erlaubnis zur Nutzung des ONVIF-Dienstes Ihrer Kamera für den Zugriff auf den Live-Videostream.' },
        { actionColumn: 'P2P Zugriff', descriptionColumn: 'Erlaubnis zum Zugriff auf die Kamera über die InstarVision-Smartphone-App mit dem Point-2-Point-Dienst.' },
        { actionColumn: 'Push Benachrichtigungen', descriptionColumn: 'Erlaubnis zum Empfang von Alarm-Push-Benachrichtigungen über die InstarVision-App.' }
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

UserTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(UserTable)