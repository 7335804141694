import React from 'react'
import { OutboundLink } from 'gatsby-plugin-gtag'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

import inLAN500Black from "../../../images/Quick_Installation/Powerline/In-lan_500.webp"
import inLAN500White from "../../../images/Quick_Installation/Powerline/In-lan_500p_white.webp"

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <img
                  src={inLAN500Black}
                  alt="IN-LAN 500 Powerline Adapter"
                />
              }
              title='IN-LAN 500 Powerline Adapter'
              description={
                <p>Verfügbar in <OutboundLink href="https://www.instar.de/accessories/netzwerk-ueber-die-steckdose-powerline-dlan/in-lan-500.html" target="_blank" rel="noopener noreferrer">Weiß</OutboundLink>.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <img
                  src={inLAN500White}
                  alt="IN-LAN 500 Powerline Adapter"
                />
              }
              title='IN-LAN 500 Powerline Adapter'
              description={
                <p>Verfügbar in <OutboundLink href="https://www.instar.de/accessories/netzwerk-ueber-die-steckdose-powerline-dlan/in-lan-500p-black.html" target="_blank" rel="noopener noreferrer">Schwarz</OutboundLink> und <OutboundLink href="https://www.instar.de/accessories/netzwerk-ueber-die-steckdose-powerline-dlan/in-lan-500p-white.html" target="_blank" rel="noopener noreferrer">Weiß</OutboundLink>.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
