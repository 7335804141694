import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Websocket Support">
        <EuiText>
        <p>Das <strong>biOS MQTT Plugin</strong> verbindet sich mit Ihrem MQTT Broker über einen Websocket (WS) Tunnel. WS werden von INSTAR Full HD MQTTv3 Kameras nicht unterstützt. Sie können sie aber trotzdem über Ihr Stream Deck steuern, indem Sie sie mit einem WS-unterstützenden MQTT Broker verbinden, z.B. einer INSTAR 2k+ WQHD MQTTv5 Kamera oder einem externen Mosquitto Broker. Wenn sie als MQTT-Client verbunden sind, können Sie Befehle an Ihren MQTT (WS)-Broker senden, die dann über reguläres MQTT (ohne Websocket) an sie weitergeleitet werden.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

