import React from 'react'
import { OutboundLink } from 'gatsby-plugin-gtag'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiIcon
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="fYrV-vr8zbs"
                    title="Firmware überschreiben"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              footer={
                <EuiText>
                <p>In diesem Video erklären wir Ihnen wie Sie eine serielle Verbindung zu Ihrer INSTAR HD IP Kamera herstellen können unter Windows 7 / 8 / 10. Dazu benötigen Sie das Programm Tera Term, dieses finden Sie in der aktuellsten Version hier: <OutboundLink href="https://osdn.net/projects/ttssh2/releases/" target="_blank" rel="noopener noreferrer">Tera Term <EuiIcon type="popout" /></OutboundLink>. Auf <OutboundLink href="https://www.youtube.com/watch?v=fYrV-vr8zbs" target="_blank" rel="noopener noreferrer">Youtube ansehen <EuiIcon type="popout" /></OutboundLink></p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}
