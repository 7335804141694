import React from 'react'
import 'react-vis/dist/style.css'
import {
  FlexibleWidthXYPlot,
  VerticalGridLines,
  HorizontalGridLines,
  AreaSeries,
  DiscreteColorLegend,
  Crosshair,
  XAxis
} from 'react-vis'

const DATA = [
  [
    { x: 'VGA', y: 114 },
    { x: '720p', y: 51 },
    { x: '1080p', y: 570 }
  ],
  [
    { x: 'VGA', y: 170 },
    { x: '720p', y: 87 },
    { x: '1080p', y: 630 }
  ]
];


export default class Example extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      crosshairValues: []
    };
  }

  render() {
    return (
      <React.Fragment>

        <div className="chart">
          <FlexibleWidthXYPlot
            onMouseLeave={() => this.setState({ crosshairValues: [] })}
            height={300}
            xType="ordinal"
            stackBy="y">

            <XAxis
              attr="x"
              attrAxis="y"
              orientation="bottom"
            />

            <VerticalGridLines />

            <HorizontalGridLines />

            <AreaSeries
              onNearestX={(value, { index }) =>
                this.setState({ crosshairValues: DATA.map(d => d[index]) })}
              cluster="ProcessorLoad"
              color="#1274d3"
              
              data={DATA[0]} />

            <AreaSeries
              cluster="ProcessorLoad"
              color="#efac59"
              
              data={DATA[1]} />

            <Crosshair values={this.state.crosshairValues} />

          </FlexibleWidthXYPlot>
        </div>

        <div className="legend">
          <DiscreteColorLegend
            orientation="horizontal"
            items={[
              {
                title: '[0] 1 Kamera [kBps]',
                color: '#1274d3'
              },
              {
                title: '[1] 2 Kameras [kBps]',
                color: '#efac59'
              }
            ]}
          />
        </div>

      </React.Fragment>
    );
  }
}
