import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class SettingsTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "parameterColumn", title: "Parameter" },
        { name: "valueColumn", title: "Wert" },
      ],
      rows: [
        { parameterColumn: 'Kameraname', valueColumn: 'Es kann ein Kameraname vergeben werden.' },
        { parameterColumn: 'Typ', valueColumn: 'Wählen Sie __Andere__' },
        { parameterColumn: 'URL 1.', valueColumn: 'Geben Sie hier die MJPEG Streams der Kamera an (s.u.)' },
        { parameterColumn: 'Erweiterte Authentifizierung', valueColumn: 'Diese Option muss deaktiviert bleiben (__Basic Authentication__)' },
        { parameterColumn: 'Kamera unterwegs sichtbar', valueColumn: 'Sollte immer aktiviert sein, da dies dafür sorgt, dass die LUPUS XT als Proxy dient und für das Livebild innerhalb des Alarmsystems die mit TLS 1.2 verschlüsselte Verbindung der Alarmanlage verwendet wird. Eine Portfreigabe für die Kamera im Router ist für dieses Livebild dann ebenfalls nicht mehr notwendig.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

SettingsTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(SettingsTable)