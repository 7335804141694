import React from 'react'
import { Link } from 'gatsby'
import { EuiImage, EuiText } from '@elastic/eui'

import ivImage from "../../../../images/Software/Windows/InstarVision/Image_Language.jpg"




export default () => (
  <EuiText>
    <EuiImage
      size="l"
      float="left"
      margin="l"
      hasShadow
      caption="InstarVision v3 for Windows"
      allowFullScreen
      alt="InstarVision v3 for Windows"
      src={ivImage}
    />
    <p>Sie müssen sich bei der Software standardmäßig nicht anmelden. Es existiert jedoch ein Standard Administrations-Login, dass z.B. durch das Drücken des Schloss Symbols in der oberen rechten Ecke der UI (über dem INSTAR Logo) aktiviert werden kann. Danach können Sie keines der Menüs mehr öffnen, ohne zuvor das Admin-Login einzugeben! Sie können dieses Standard Login im <Link to="/Software/Windows/InstarVision/Users/">Benutzer Menü</Link> anpassen. Dort können Sie das Login auch standardmäßig aktivieren, so dass ein Starten der Software, ohne das richtige Kennwort, nicht mehr möglich ist. Sollten Sie diese Funktion nutzen, schreiben Sie sich am besten das verwendete Login irgendwo aus. Ohne das Login ist der Zugriff auf die Software nicht mehr möglich!</p>
  </EuiText>
);
