import React from 'react'

import {Link} from 'gatsby'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiButton
} from '@elastic/eui'


export default function ImageCard() {

  return (
    <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
      <EuiFlexItem>
        <EuiCard
          textAlign="left"
          title="System"
          footer={
            <EuiFlexGroup justifyContent="flexEnd">
              <EuiFlexItem grow={false}>
                <Link to="/Software/Windows/InstarVision/System/">
                  <EuiButton size="s" fill>
                    mehr...
                  </EuiButton>
                </Link>
              </EuiFlexItem>
            </EuiFlexGroup>
          }>
          <EuiText>
            <p>Stellen Sie die UI Sprache, den Startmodus und die TCP Ports für den Fernzugriff ein.</p>
          </EuiText>
        </EuiCard>
        </EuiFlexItem>
      <EuiFlexItem>
        <EuiCard
          textAlign="left"
          title="Benutzer"
          footer={
            <EuiFlexGroup justifyContent="flexEnd">
              <EuiFlexItem grow={false}>
                <Link to="/Software/Windows/InstarVision/Users/">
                  <EuiButton size="s" fill>
                    mehr...
                  </EuiButton>
                </Link>
              </EuiFlexItem>
            </EuiFlexGroup>
          }>
          <EuiText>
            <p>Die Benutzerverwaltung mit feinstufigen Einstellungen auf die Funktionen der Software.</p>
          </EuiText>
        </EuiCard>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiCard
          textAlign="left"
          title="Erweitert"
          footer={
            <EuiFlexGroup justifyContent="flexEnd">
              <EuiFlexItem grow={false}>
                <Link to="/Software/Windows/InstarVision/Advanced/">
                  <EuiButton size="s" fill>
                    mehr...
                  </EuiButton>
                </Link>
              </EuiFlexItem>
            </EuiFlexGroup>
          }>
          <EuiText>
            <p>Allgemeine Informationen über die Software Installation.</p>
          </EuiText>
        </EuiCard>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}
