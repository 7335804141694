import React from 'react'

import {Link} from 'gatsby'

import { OutboundLink } from 'gatsby-plugin-gtag'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Hinweis:">
        <EuiText>
          <p>Der Home Assistant wird mit einem <OutboundLink href="https://www.home-assistant.io/docs/mqtt/broker#embedded-broker">eingebetteten MQTT-Broker</OutboundLink> geliefert. Da wir den <Link to="/Software/Linux/Node-RED#installing-mosquitto/">Mosquitto MQTT Broker mit Node-RED</Link> bereits installiert haben, haben wir diesen sekundären Broker durch Hinzufügen der obigen Konfiguration deaktiviert. Stellen Sie sicher, dass der <Link to="#f%C3%BChren-sie-den-home-assistant-als-dienst-aus">Mosquitto-Service vor dem Home Assistant ausgeführt wird</Link>.</p><br/>
          <p>Wenn Sie nicht vorhaben, den Mosquitto Server zu verwenden, überspringen Sie diesen Schritt und der eingebettete MQTT-Broker wird stattdessen automatisch gestartet.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

