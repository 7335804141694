import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class LogTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Camera Log" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'startup', descriptionColumn: 'Inbetriebnahme' },
        { actionColumn: 'shutdown', descriptionColumn: 'Kamera wird heruntergefahren' },
        { actionColumn: 'wifiEstablished', descriptionColumn: 'Wifi-Verbindung hergestellt' },
        { actionColumn: 'wifiDisconnected', descriptionColumn: 'Vom Wifi getrennt' },
        { actionColumn: 'LanOnWifiOff', descriptionColumn: 'Wifi gestoppt, weil LAN-Kabel angeschlossen' },
        { actionColumn: 'switchToDay', descriptionColumn: 'Umschalten in den Tagesmodus' },
        { actionColumn: 'switchToNight', descriptionColumn: 'Umschalten in den Nachtmodus' },
        { actionColumn: 'pirTriggered', descriptionColumn: 'PIR ausgelöst' },
        { actionColumn: 'soundDetected', descriptionColumn: 'Geräusch erkannt' },
        { actionColumn: 'manualAlarm', descriptionColumn: 'Manuell ausgelöster Alarm' },
        { actionColumn: 'sdDeleteOldest', descriptionColumn: 'SD-Karte voll, %s löschen' },
        { actionColumn: 'sdTimedDelete', descriptionColumn: 'Gelöscht %s (maximale Speicherzeit erreicht)' },
        { actionColumn: 'userLogin', descriptionColumn: '%s hat sich angemeldet' },
        { actionColumn: 'userLoginIp', descriptionColumn: '%s hat sich von %s aus angemeldet' },
        { actionColumn: 'userLogout', descriptionColumn: '%s hat sich von %s aus angemeldet' },
        { actionColumn: 'Motion Detection in area x, y, z triggered', descriptionColumn: 'Bereich x,y,z haben Bewegungen erkannt' },
        { actionColumn: 'PIR and Motion Detection in area x, z triggered', descriptionColumn: 'PIR Sensor & Bereich x,y,z haben Bewegungen erkannt' },
        { actionColumn: 'push', descriptionColumn: 'Die Push-Benachrichtigung wurde gesendet' },
        { actionColumn: 'alarmserver', descriptionColumn: 'Der Alarmserver wurde kontaktiert' },
        { actionColumn: 'cloudRecord', descriptionColumn: '%s wird in die INSTAR CLOUD hochgeladen' },
        { actionColumn: 'sdRecord', descriptionColumn: '%s wird auf di SD geschrieben' },
        { actionColumn: 'sdSnap', descriptionColumn: 'Schnappschuss %s auf SD gespeichert' },
        { actionColumn: 'ftpRecord', descriptionColumn: 'Hochladen der Aufnahme %s über FTP' },
        { actionColumn: 'ftpSnap', descriptionColumn: 'Hochladen de Snapshot %s über FTP' },
        { actionColumn: 'ftpFail', descriptionColumn: 'FTP-Upload von %s fehlgeschlagen. Bitte überprüfen Sie Ihre FTP-Einstellungen' },
        { actionColumn: 'ftpFailTimeout', descriptionColumn: 'FTP-Upload von %s wegen Zeitüberschreitung fehlgeschlagen' },
        { actionColumn: 'ftpFailTooMany', descriptionColumn: 'FTP-Upload von %s fehlgeschlagen. Zu viele gleichzeitige Uploads' },
        { actionColumn: 'ftpSuccess', descriptionColumn: 'Erfolgreich %s per FTP hochgeladen' },
        { actionColumn: 'emailPrepare', descriptionColumn: 'E-Mail mit %d Schnappschüssen vorbereiten' },
        { actionColumn: 'emailSuccess', descriptionColumn: 'E-Mail erfolgreich gesendet' },
        { actionColumn: 'emailFail', descriptionColumn: 'E-Mail-Versand fehlgeschlagen. Bitte überprüfen Sie Ihre SMTP-Einstellungen' },
        { actionColumn: 'emailFailTimeout', descriptionColumn: 'E-Mail-Versand aufgrund von Zeitüberschreitung fehlgeschlagen' },
        { actionColumn: 'recKeyWarning', descriptionColumn: 'Warnung: Kein Keyframe in der Voraufzeichnung. Bitte überprüfen Sie Ihre Videoeinstellungen' }
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

LogTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(LogTable)