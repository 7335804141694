import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Nur verfügbar ab Software-Version Firmware 4.1.2.42 WebUI 2.1 (192)">
        <EuiText>
          <p>Den Download finden Sie in unserem: <strong><Link to="/Downloadbereich/Aussenkameras/IN-9008_HD/">Download Bereich</Link></strong>.</p>
          <p>Sie kann auch direkt über die Weboberfläche Ihrer Kamera heruntergeladen werden: <strong><Link to="/Web_User_Interface/1080p_Serie/System/Update/">System/Update Menü</Link></strong>.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

