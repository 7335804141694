import React from 'react'
import { OutboundLink } from 'gatsby-plugin-gtag'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiIcon
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="spWzXLxpXVA"
                    title="INSTAR Kamera in Ihrem Netzwerk einen Namen vergeben"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              title="INSTAR Kamera in Ihrem Netzwerk einen Namen vergeben"
              footer={
                <EuiText>
                <p>Wir zeigen in diesem Video wie Sie Ihrer INSTAR Kamera in Ihrem Netzwerk einen Namen vergeben können wie z.B. eingang.cam. So können Sie dann im Webbrowser, anstatt der IP Adresse den Namen eingeben und gelangen so auf Ihre Kamera. Auf <OutboundLink href="https://www.youtube.com/watch?v=spWzXLxpXVA" target="_blank" rel="noopener noreferrer">Youtube ansehen <EuiIcon type="popout" /></OutboundLink></p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}