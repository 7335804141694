import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'VGA Serie',
      href: '/de/Schnell_Installation/WebUI_und_Firmware_Upgrade/VGA_Kameras/',
    },
    {
      text: '720p Serie',
      href: '/de/Schnell_Installation/WebUI_und_Firmware_Upgrade/720p_Kameras/',
    },
    {
      text: '1080p Serie',
      href: '/de/Schnell_Installation/WebUI_und_Firmware_Upgrade/',
    },
    {
      text: '◄ Installation',
      href: '/de/Schnell_Installation/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={6}
        aria-label="Software Update"
      />
      <EuiSpacer />
    </Fragment>
  );
}

// export default () => {
//   const breadcrumbs = [
//     {
//       text: 'VGA Series',
//       href: '/Schnell_Installation/WebUI_And_Firmware_Upgrade/VGA_Cameras/',
//     },
//     {
//       text: '720p Series',
//       href: '/Schnell_Installation/WebUI_And_Firmware_Upgrade/720p_Cameras/',
//     },
//     {
//       text: '1080p Series',
//       href: '/Schnell_Installation/WebUI_And_Firmware_Upgrade/',
//     },
//     {
//       text: '◄ Software Update',
//       href: '/Schnell_Installation/WebUI_And_Firmware_Upgrade/',
//     }
//   ];

//   return (
//     <Fragment>
//       <EuiBreadcrumbs
//         truncate={true}
//         breadcrumbs={breadcrumbs}
//         max={6}
//         aria-label="Software Update"
//       />
//       <EuiSpacer />
//     </Fragment>
//   );
// }