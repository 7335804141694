import React from 'react'
import {Link} from 'gatsby'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import qsQuickInstall01 from "../../../../images/Indoor_Cameras/IN-8003_HD/Quick_Installation/IN-8003_Quick_Installation_01.webp"
import qsQuickInstall02 from "../../../../images/Indoor_Cameras/IN-8003_HD/Quick_Installation/IN-8003_Quick_Installation_02.webp"
import qsQuickInstall03 from "../../../../images/Indoor_Cameras/IN-8003_HD/Quick_Installation/IN-8003_Quick_Installation_03.webp"
import qsQuickInstall04 from "../../../../images/Indoor_Cameras/IN-8003_HD/Quick_Installation/IN-8003_Quick_Installation_04.webp"




export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              paddingSize="l"
              image={
                  <img
                    src={qsQuickInstall01}
                    alt="IN-8003 Full HD Quick Installation"
                  />
              }
              title='Step 1'
              description={
                <p>Im Lieferumfang befinden sich mehrere Werkzeuge um die Kamera zu öffnen. So können Sie die Ausrichtung der Kamera verändern, das Objektiv scharfstellen oder das Anschlusskabel im inneren der Kamera lösen.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              paddingSize="l"
              image={
                  <img
                    src={qsQuickInstall02}
                    alt="IN-8003 Full HD Quick Installation"
                  />
              }
              title='Step 2'
              description={
                <p>Der Schraubenschlüssel dient zum lösen der Mutter auf der Rückseite des Kameragehäuses. Durch lösen der Mutter können Sie das Gehäuse öffnen um so im inneren z.B. das Anschlusskabel zu lösen sofern Sie dieses durch eine Wand verlegen möchten. Seien Sie beim öffnen und erneuten schließen sehr Vorsichtig damit Sie die Kamera nicht beschädigen.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              paddingSize="l"
              image={
                  <img
                    src={qsQuickInstall03}
                    alt="IN-8003 Full HD Quick Installation"
                  />
              }
              title='Step 3'
              description={
                <p>Achten Sie bei der Installation darauf, dass das Anschlußkabel gradlinig aus der Kamera geführt wird und kein Knick im Kabel entsteht.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              paddingSize="l"
              image={
                  <img
                    src={qsQuickInstall04}
                    alt="IN-8003 Full HD Quick Installation"
                  />
              }
              title='Step 4'
              description={
                <p>Die Kamera kann im Anschluß sowohl vertikal als auch horizontal an der Wand angebracht werden. In seltenen Fällen müssen Sie ggf. die <Link to="/Innenkameras/IN-8003_HD/Objektivjustage/">Schärfe des Objektives</Link> nachjustieren, in einem solchen Fall liefern wir dazu bereits die benötigten Werkzeuge um die Front der Kamera zu öffnen.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
