import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="SSL Certificate">
        <EuiText>
          <p>
            Sie möchten Ihr eigenes SSL-Zertifikat für Ihre eigene DDNS-Domaine verwenden? Kein Problem!
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

