import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Fernzugriff/Point_to_Point/">
                  <StaticImage
                    src='../../images/Search/IA_SearchThumb_P2P.webp'
                    alt="P2P Fernzugriff"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="P2P Fernzugriff"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Fernzugriff/Point_to_Point/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Fernzugriff/Der_DDNS_Dienst/">
                  <StaticImage
                    src='../../images/Search/IA_SearchThumb_DDNSService.webp'
                    alt="DDNS Anbieter"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="DDNS Anbieter"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Fernzugriff/Der_DDNS_Dienst/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Fernzugriff/Port_Weiterleitung/">
                  <StaticImage
                    src='../../images/Search/IA_SearchThumb_Portforwarding.webp'
                    alt="Port Weiterleitung"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Port Weiterleitung"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Fernzugriff/Port_Weiterleitung/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Fernzugriff/Mobil_Zugriff/">
                  <StaticImage
                    src='../../images/Search/IA_SearchThumb_Mobile.webp'
                    alt="Mobiler Zugriff"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Mobiler Zugriff"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Fernzugriff/Mobil_Zugriff/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Fernzugriff/DDNS_Anbieter/">
                  <StaticImage
                    src='../../images/Search/IA_SearchThumb_ThirdPartyDDNS.webp'
                    alt="DDNS Anbieter"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="DDNS Anbieter"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Fernzugriff/DDNS_Anbieter/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
