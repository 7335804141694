import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'Triggers & Actions',
      href: '/de/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/',
    },
    {
      text: 'Applets Einleitung',
      href: '/de/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Einleitung/',
    },
    {
      text: 'Applets Erstellen',
      href: '/de/Bewegungserkennung/INSTAR_Cloud/IFTTT/User_Guide/How_to_create_own_Applets/',
    },
    {
      text: 'INSTAR Applets',
      href: '/de/Bewegungserkennung/INSTAR_Cloud/IFTTT/User_Guide/How_to_use_INSTAR_Applets/',
    },
    {
      text: 'P2P Login',
      href: '/de/Bewegungserkennung/INSTAR_Cloud/IFTTT/User_Guide/How_to_add_P2P_Credentials/',
    },
    {
      text: '◄ IFTTT',
      href: '/de/Bewegungserkennung/INSTAR_Cloud/IFTTT/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={6}
        aria-label="INSTAR IFTTT"
      />
      <EuiSpacer />
    </Fragment>
  );
}

// export default () => {
//   const breadcrumbs = [
//     {
//       text: 'Triggers & Actions',
//       href: '/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/',
//     },
//     {
//       text: 'Applets Instruction',
//       href: '/Bewegungserkennung/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/',
//     },
//     {
//       text: 'Create Applets',
//       href: '/Bewegungserkennung/INSTAR_Cloud/IFTTT/User_Guide/How_to_create_own_Applets/',
//     },
//     {
//       text: 'INSTAR Applets',
//       href: '/Bewegungserkennung/INSTAR_Cloud/IFTTT/User_Guide/How_to_use_INSTAR_Applets/',
//     },
//     {
//       text: 'P2P Credentials',
//       href: '/Bewegungserkennung/INSTAR_Cloud/IFTTT/User_Guide/How_to_add_P2P_Credentials/',
//     },
//     {
//       text: '◄ IFTTT',
//       href: '/Bewegungserkennung/INSTAR_Cloud/IFTTT/',
//     }
//   ];

//   return (
//     <Fragment>
//       <EuiBreadcrumbs
//         truncate={true}
//         breadcrumbs={breadcrumbs}
//         max={6}
//         aria-label="INSTAR IFTTT"
//       />
//       <EuiSpacer />
//     </Fragment>
//   );
// }