import React from 'react'
import { OutboundLink } from 'gatsby-plugin-gtag'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Hinweis:">
        <EuiText>
          <p>Bevor Sie einen Port auf Ihr Hausautomatisierungssystem weiterleiten, vergewissern Sie sich, dass der Zugang zum System passwortgeschützt ist! Z.B. im Falle von <OutboundLink href="https://nodered.org/docs/user-guide/runtime/securing-node-red">Node-RED</OutboundLink> müssen Sie Ihre Node-Red-Benutzeroberfläche schützen, indem Sie die Variablen <em>adminAuth</em> und <em>httpNodeAuth</em> setzen, um den Webhook selbst zu schützen. Beide können in der  <OutboundLink href="https://nodered.org/docs/user-guide/runtime/securing-node-red">setting.js</OutboundLink> Datei konfiguriert werden.</p>
        </EuiText >
    </EuiCallOut>
  );
}

export default DangerBox

