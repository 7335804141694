import React from 'react'

import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Kommunizieren Sie mit Ihrer Kamera">
        <EuiText>
          <p>Wenn Sie daran interessiert sind, die MQTT-Schnittstelle zu nutzen, um Ihre Kamera in ein Smarthome-System einzubinden, sind Sie wahrscheinlich bereits mit unserer <Link to="/Erweitert/CGI_Befehle/">HTTP/S CGI Schnittstelle</Link> mit dem Sie über Ihren Webbrowser Befehle an Ihre Kamera senden können.</p>
          <p>MQTT funktioniert in etwa auf die gleiche Weise. Jeder CGI-Befehl entspricht einem MQTT-Topic und jedes Topic kann spezifische Nutzdaten enthalten. Um zum Beispiel den ersten Bewegungserkennungsbereich auf Ihrer Kamera zu aktivieren, können Sie den folgenden <Link to="/Erweitert/CGI_Befehle/">CGI Befehl</Link> senden:</p>
          <ul>
            <li><code>param.cgi?cmd=setmdattr&-name=1&-enable=[val]</code></li>
          </ul>
          <p>Der Wert <code>val</code> kann entweder <code>1</code> oder <code>0</code> sein - je nachdem, ob Sie den Erkennungsbereich aktivieren oder deaktivieren möchten. In <Link to="/Erweitert/INSTAR_MQTT_Broker/">MQTT</Link> dasselbe zu tun, schaut folgendermaßen aus:</p>
          <ul>
            <li><code>alarm/area1/enable &#123;"val":"0"&#125;, &#123;"val":"1"&#125;</code></li>
          </ul>
          <p>Das Topic, um den Bereich ein- oder auszuschalten, ist <code>alarm/area1/enable</code> und Sie müssen entweder einen Wert von <code>1</code> oder <code>0</code> senden, um es zu schalten.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

