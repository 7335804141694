import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut,
    EuiSpacer
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Kamera Reset & WPS">
        <EuiText>
          <b>Wenn Sie die Reset-Taste an Ihrer Kamera drücken, werden die Status-LEDs ausgeschaltet und...</b>
          <ol>
            <EuiSpacer />
            <li><b>Nach 3s</b>: Schalten sich die Status-LEDs ein und es starten, nach dem Loslassen der Taste, den <Link to="/Web_User_Interface/1440p_Serie/Netzwerk/WLAN/">Wireless-Protected-Setup Modus</Link> oder <b>WPS</b>, mit dem Sie Ihre Kamera zu Ihrem lokalen WiFi hinzufügen können.</li>
            <li><b>Nach 15s</b>: Beginnen die Status-LEDs langsam zu blinken und es werden, nach dem Loslassen der Taste, alle Kameraeinstellungen <b>mit Ausnahme der Netzwerkkonfiguration</b> zurückgesetzt.</li>
            <li><b>Nach 20s</b>: Beginnen die Status-LEDs schnell zu blinken und es werden, nach dem Loslassen der Taste, <b>ALLE</b> Kameraeinstellungen zurückgesetzt.</li>
            <li><b>Nach 30s</b>: Gehen die Status-LEDs aus und die Kamera wird in den Zustand vor dem letzten Firmware-Update zurückgesetzt. Anschließend startet die Kamera neu.</li>
          </ol>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

