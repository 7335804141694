import React from 'react'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

import ImageGallery from "react-image-gallery"

import galleryImage01 from "../../../../images/Products/Lenses/snap_compare_lager_6m_tag.webp"
import galleryImage01Thumb from "../../../../images/Products/Lenses/snap_compare_lager_6m_tag_thumb.webp"
import galleryImage02 from "../../../../images/Products/Lenses/IN-8015_2-8mm_lense_day.webp"
import galleryImage02Thumb from "../../../../images/Products/Lenses/IN-8015_2-8mm_lense_day_thumb.webp"
import galleryImage03 from "../../../../images/Products/Lenses/IN-8015_4-2mm_lense_day.webp"
import galleryImage03Thumb from "../../../../images/Products/Lenses/IN-8015_4-2mm_lense_day_thumb.webp"
import galleryImage04 from "../../../../images/Products/Lenses/IN-8015_8mm_lense_day.webp"
import galleryImage04Thumb from "../../../../images/Products/Lenses/IN-8015_8mm_lense_day_thumb.webp"
import galleryImage05 from "../../../../images/Products/Lenses/IN-8015_16mm_lense_day.webp"
import galleryImage05Thumb from "../../../../images/Products/Lenses/IN-8015_16mm_lense_day_thumb.webp"

import "react-image-gallery/styles/css/image-gallery.css"

export class IN8015ImageQGallery extends React.Component {
  render() {
    const images = [
      { original: galleryImage01, thumbnail: galleryImage01Thumb },
      { original: galleryImage02, thumbnail: galleryImage02Thumb },
      { original: galleryImage03, thumbnail: galleryImage03Thumb },
      { original: galleryImage04, thumbnail: galleryImage04Thumb },
      { original: galleryImage05, thumbnail: galleryImage05Thumb }
    ];

    return (
      <Card>
        <CardContent>
          <ImageGallery items={images} lazyLoad={true} showPlayButton={false} />
        </CardContent>
      </Card>
    );
  }
}

export default IN8015ImageQGallery