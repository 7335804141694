import React from 'react'

import {
  EuiImage,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'

import ivImage from "../../../../../images/Software/Windows/InstarVision/Camera_List/Image083.png"

export default function ImageCard() {

  return (
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem style={{ maxWidth: 650 }}>
            <EuiImage
              hasShadow
              allowFullScreen
              alt="InstarVision v2 for Windows"
              src={ivImage}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiText>
                <p>Nutzen Sie eine Architektur Zeichnung Ihres Überwachungsbereiches oder einen Schnappschuss von Google Maps als interaktive eMap für Ihre Überwachung.</p>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
  );
}
