import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Video Settings">
        <EuiText>
          <p>
            Die Videoeinstellungen erlauben es, die Qualität von allen 3 Videostreams Ihrer Kamera einzustellen. Bitte beachten Sie, dass eine hohe Qualität in den Videoeinstellungen auch gleichzeitig hohe Hardware- und Bandbreitenansprüche mit sich bringt. Richten Sie die Videoeinstellungen ein, um ein flüssiges Video zu erhalten. Bitte schauen Sie unter <Link to="/Produkte/Innenkameras/IN-8015_HD/Video_Streaming/">RTSP Streaming</Link> um auf die Kamera Streams 2 und 3 zuzugreifen.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

