import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class ActionTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Alarm Aktionen" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Alarm aktivieren', descriptionColumn: 'Aktivieren oder deaktivieren Sie alle Alarmaktionen.' },
        { actionColumn: 'Internen PIR Sensor aktivieren', descriptionColumn: 'Aktivieren Sie den internen passiven Infrarot Sensor (PIR Bewegegunserkennung) um einen Alarm auszulösen, wenn eine Person den Überwachungsbereich betritt. Sie können die ALARMBEREICHE deaktivieren, wenn der PIR Sensor aktiv ist.' },
        { actionColumn: 'PIR und Alarmbereiche koppeln', descriptionColumn: 'Sie können auch die ALARMBEREICHE mit dem internen PIR Sensor koppeln. Nutzen Sie diese Funktion um effizient gegen Falschalarme vorzugehen. Bitte denken Sie daran, dass Sie mindestens einen Alarmbereich aktiviert haben müssen, um diese Funktion nutzen zu können! Wenn alles korrekt eingestellt ist, dann sollte in der Smartphone APP sowohl das Einbrecher-Symbol als auch das Auge rot unterlegt sein.' },
        { actionColumn: 'Gekoppelte Bereiche', descriptionColumn: 'Wählen Sie aus, welche Bereiche mit dem internen PIR-Sensor gekoppelt werden sollen. Diese Bereiche werden nur dann einen Alarm auslösen, wenn auch der PIR-Wärmesensor gleichzeitig ausgelöst wird. Bereiche, die nicht ausgewählt sind, werden weiterhin unabhängig auslösen.' },
        { actionColumn: 'Alarmeingang aktivieren', descriptionColumn: 'Am Alarmeingang können externe Bewegungssensoren installiert werden.<br>Der Eingangsmodus gibt an welchen Status der Alarmeingang im Ruhezustand hat. N.C. steht für Normally Closed. In dieser Stellung besteht durchgehend ein geschlossener Kreis zum externen Sensor. Sollte der Sensor den Kontakt unterbrechen oder die Verbindung zum Alarmsensor wird von einer Person getrennt, so erkennt die Kamera einen Alarm. N.O. steht für Normally Open. In diesem Modus wird ein Alarm registriert sobald der Alarmsensor einen geschlossenen Kreis herstellt.' },
        { actionColumn: 'Alarmeingang Modus', descriptionColumn: 'Legen Sie fest ob der Alarmeingang im Normalzustand einen offenen (N.O.) oder geschlossenen (N.C.) Kreislauf erwartet.' },
        { actionColumn: 'Geräuscherfassung', descriptionColumn: 'Bei Überschreitung eines gewissen Tonpegels, registriert die Kamera einen Alarm. Dies ist die ideale Funktion, um Ihre Kamera als Babyphone zu nutzen. Die Empfindlichkeit ist abghängig von der Mikrofonsensitivität, die unter Multimedia -&gt; Audio konfigurierbar ist. Bei Aussenkameras wird hierfür ein externes Mikrofon benötigt.' },
        { actionColumn: 'Erfassungsempfindlichkeit', descriptionColumn: 'Stellen Sie ein wie sensibel die Audio Erkennung reagieren soll.' },
        { actionColumn: 'Geräuschdauer für Alarm', descriptionColumn: 'Legen Sie fest wie lange ein Geräusch mit ausreichender Lautstärke andauern muss, damit ein Audioalarm ausgelöst wird.' },
        { actionColumn: 'Objekterkennung aktivieren', descriptionColumn: 'Aktivieren Sie die Objekterkennung Ihrer Kamera. Der Algorithmus wird 3 Objekt-Klassen im Live Video Ihrer Kamera erkennen - Personen, Fahrzeuge und Tiere. Sie können diese erkannten Objekte im Live Video in der Weboberfläche mit einem Rahmen versehen lassen. Und (s.u.) alle Alarme filtern in denen diese Objekte nicht erkannt wurde.' },
        { actionColumn: 'Erkennungsschwellwert', descriptionColumn: 'Der Schwellenwert ist die Erkennungssicherheit, die erforderlich ist, um einen Alarm auszulösen.' },
        { actionColumn: 'Personen voraussetzen', descriptionColumn: 'Unterdrücken Sie alle Alarme in denen keine Personen erkannt wurden.' },
        { actionColumn: 'Fahrzeuge voraussetzen', descriptionColumn: 'Unterdrücken Sie alle Alarme in denen keine Fahrzeuge erkannt wurden.' },
        { actionColumn: 'Tiere voraussetzen', descriptionColumn: 'Unterdrücken Sie alle Alarme in denen keine Tiere erkannt wurden.' }
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

ActionTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(ActionTable)