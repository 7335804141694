import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="h.265 Video-Wiedergabe in Microsoft Edge">
        <EuiText>
            <p>Die HEVC/h.256-Unterstützung befindet sich derzeit noch in der Beta-Phase im Microsoft Edge (Chromium) Browser und muss manuell aktiviert werden. Um die Konfigurationsseite aufzurufen, geben Sie Folgendes in die Adressleiste Ihres Browsers ein <code>edge://flags</code> und setzen Sie die <code>PlayReady Experimental HEVC Decoding</code> Flagge auf <code>true</code>. Zusätzlich müssen Sie möglicherweise die <Link to="https://apps.microsoft.com/store/detail/hevc-video-extensions-from-device-manufacturer/9N4WGH0Z6VHQ?hl=en-us&gl=US">Microsoft HEVC Video Erweiterung</Link> aus dem Microsoft Store installieren.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox
