import React from 'react'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import ceConform from "images/Indoor_Cameras/IN-8401_WQHD/Safety_Warnings/CE-Conformity.webp"
import fccConform from "images/Indoor_Cameras/IN-8401_WQHD/Safety_Warnings/FCC-Conformity.webp"
import rohsConform from "images/Indoor_Cameras/IN-8401_WQHD/Safety_Warnings/RoHS-Conformity.webp"




export default function ConformityCard() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={ceConform}
                    alt="CE Conformity"
                  />
              }
              description={
                <p>Dieses Gerät erfüllt die grundlegenden Anforderungen der europäischen Richtlinien zur elektromagnetischen Verträglichkeit (2004/108/EC) und der Niederspannungsrichtlinie (2006/95/EC). Bei ordnungsgemäßer Installation gemäß dieser Anleitung entspricht das Produkt den CE-Bestimmungen. Um das Risiko von elektromagnetischen Störungen (z.B. bei Radios oder Funkverkehr) zu vermeiden, wird dringend empfohlen, nur abgeschirmte Kabel zu verwenden.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={fccConform}
                    alt="FCC Conformity"
                  />
              }
              description={
                <p>Dieses Produkt entspricht den Bestimmungen von FCC Teil 15. Diese Grenzwerte wurden festgelegt, um einen angemessenen Schutz vor schädlicher Exposition beim Betrieb des Geräts zu gewährleisten. Dieses Produkt sendet und verwendet Funkenergie. Es kann außerdem andere Funkkommunikationssysteme stören, wenn es nicht gemäß dieser Anleitung verwendet wird.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={rohsConform}
                    alt="RoHS Conformity"
                  />
              }
              description={
                <p>Alle INSTAR-Produkte sind bleifrei und erfüllen die Anforderungen der europäischen Richtlinie zur Beschränkung gefährlicher Stoffe (RoHS). Dies garantiert, dass der gesamte Produktionsprozess und das Produkt selbst frei von Blei und allen aufgeführten gefährlichen Stoffen sind.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
