import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class FeatureTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "numberColumn", title: "#" },
        { name: "componentColumn", title: "Komponente" },
        { name: "functionColumn", title: "Funktion" },
      ],
      rows: [
        { numberColumn: '01', componentColumn: 'Wandhalterung', functionColumn: 'Wandhalterung' },
        { numberColumn: '02', componentColumn: 'Ceiling Bracket', functionColumn: 'Dekenhalterung' },
        { numberColumn: '03', componentColumn: 'Antennen Anschluss', functionColumn: 'Antennen Anschluss' },
        { numberColumn: '04', componentColumn: 'Infrarot LEDs', functionColumn: 'Infrarot LEDs' },
        { numberColumn: '05', componentColumn: 'Autofocus Objektiv', functionColumn: 'Autofocus Objektiv' },
        { numberColumn: '06', componentColumn: 'Helligkeitsensor', functionColumn: 'Fotosensor' },
        { numberColumn: '07', componentColumn: 'Mikrofon', functionColumn: 'Mikrofon' },
        { numberColumn: '08', componentColumn: 'MicroSD Kartenleser', functionColumn: 'MicroSD Kartenleser' },
        { numberColumn: '09', componentColumn: 'Network LED', functionColumn: 'Network LED' },
        { numberColumn: '10', componentColumn: 'Alarm Input', functionColumn: 'Alarm Input' },
        { numberColumn: '11', componentColumn: 'Netzwerkanschluss', functionColumn: 'Network Port' },
        { numberColumn: '12', componentColumn: 'Netzteilanschluss', functionColumn: 'Power Connector' },
        { numberColumn: '13', componentColumn: 'Verlängerungskabel', functionColumn: 'Verlängerungskabel' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

FeatureTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(FeatureTable)