import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Bitte beachten Sie">
        <EuiText>
          <p>Es ist nicht mögliche, die IR LEDs dauerhaft zu aktivieren. Wenn Sie die IR LEDs bei Nacht ausschalten, kann es zu Fehlalarmen durch den Bewegungssensor kommen. In diesem Fall müssen Sie den Bewegungsalarm in der Nacht durch den Zeitplan deaktivieren.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

