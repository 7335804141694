import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class WiFiTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Push Dienst" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Suche / Manuell', descriptionColumn: 'Klicken Sie bitte auf Suche um Ihre Kamera nach WLAN Netzwerken zu suchen. Wählen Sie Ihr WiFi Netzwerk aus der Liste unten aus, geben Sie Ihren Netzwerkschlüssel ein und Bestätigen anschließend. Nach der Bestätigung, entfernen Sie einfach das Ethernet-Kabel und Ihre Kamera wird sich mit dem WLAN verbinden. Alternativen können Sie die Netzwerkparameter auch manuell eingeben.' },
        { actionColumn: 'Netzwerk Typ', descriptionColumn: 'Wählen Sie, ob Sie Ihre Kamera direkt mit einem anderen Netzwerkgerät verbinden möchten (ad hoc), oder Sie die Kamera in Ihr lokales WiFi Netzwerk einbinden möchten (infra).' },
        { actionColumn: 'Authentifizierung/Verschlüsselung', descriptionColumn: 'Wählen Sie die Verschlüsselungsart, die Ihr Router verwendet. Wir empfehlen, dass Ihr Router reines WPA2 (AES oder CCMP) verwendet und nicht WPA+WPA2 (TKIP).' },
        { actionColumn: 'Verschlüsselungstyp', descriptionColumn: 'Setzen Sie den Verschlüsselungstyp auf dieselbe Einstellung wie Ihr Router. Wir empfehlen, dass Sie Ihren Router auf den AES Standard einstellen. Im Fall der AVM Fritzbox, wählen Sie CCMP und koppeln es mit AES in Ihrer Kamera.' },
        { actionColumn: 'WiFi Schlüssel', descriptionColumn: 'Hier müssen Sie Ihren WiFi Schlüssel eingeben. Der Standardschlüssel kann meistens auf einem Aufkleber auf dem Router gefunden werden.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

WiFiTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(WiFiTable)