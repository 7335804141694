import React from 'react'
import {OutboundLink} from 'gatsby-plugin-gtag'

import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="qh4-5yQBXlo"
                    title="INSTAR IN-6012 HD Overview"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              footer={
                <EuiText>
                  <p>Die INSTAR IN-6012 ist eine Pan&Tilt-fähige 720p HD IP-Kamera der Firma INSTAR. Auf <OutboundLink href="https://www.youtube.com/watch?v=qh4-5yQBXlo" target="_blank" rel="noopener noreferrer">Youtube ansehen</OutboundLink></p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}
