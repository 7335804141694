import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Table,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-material-ui";

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word",
  },
};

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
);

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0,
    }}
    {...restProps}
  />
);

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class ONVIFTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "ONVIF Einstellungen" },
        { name: "descriptionColumn", title: "Beschreibung" },
      ],
      rows: [
        {
          actionColumn: "ONVIF Protokoll aktivieren",
          descriptionColumn:
            "Aktivieren oder deaktivieren Sie den ONVIF Service Ihrer Kamera.",
        },
        {
          actionColumn: "ONVIF Port",
          descriptionColumn:
            "Dies ist der Port über den Ihre Kamera über das ONVIF Protokoll ansprechbar ist. Es wird empfohlen diesen auf dem Standardwert von 8080 zu belassen.",
        },
        {
          actionColumn: "ONVIF Port (SSL)",
          descriptionColumn:
            "Dies ist der Port über den Ihre Kamera über das ONVIF Protokoll TLS verschlüsselt ansprechbar ist. Es wird empfohlen diesen auf dem Standardwert von 8090 zu belassen.",
        },
        {
          actionColumn: "ONVIF WS-Security",
          descriptionColumn:
            "Wenn die WS-Security-Unterstützung aktiviert ist, wird das Passwort dieses Benutzerkontos verschlüsselt und kann von diesem Gerät entschlüsselt werden. Um den ONVIF-Spezifikationen zu entsprechen, muss die Kamera das Passwort für WS-Security kennen. WS-Security-Unterstützung ist daher standardmäßig nicht aktiv, da die Kamera in der Standardeinstellung nur einen kryptografischen Hash Ihres Passworts speichert und somit Ihr Passwort nicht direkt kennt. Aus diesem Grund wird strengstens empfohlen einen neuen User mit lediglich den gewünschten ONVIF-Einstellungen zu erstellen.",
        },
      ],
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{ display: "inline-block", maxWidth: "915px" }}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

ONVIFTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ONVIFTable);
