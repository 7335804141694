import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class ResetTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Werksreset Einstellungen" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Zurück in den Werkszustand!', descriptionColumn: 'Wenn Sie die Option "Alles zurücksetzen" auswählen und den Reset durchführen, dann beachten Sie bitte, dass Ihre Kamera wieder neu über ein Netzwerkkabel eingerichtet werden muss. Jede INSTAR FullHD WLAN-Kamera nach dem kompletten Werksreset einen WLAN-Hotspot auf, mit dem Sie sich verbinden können um Ihre Kamera für das WLAN einzurichten. Wenn Sie mit dem Hotspot verbunden sind, dann können Sie die Kamera unter der IP-Adresse 192.168.9.1 erreichen.' },
        { actionColumn: 'WiFi & IP Konfiguration beibehalten', descriptionColumn: 'Hier können Sie die Kamera in den Werkszustand zurückversetzen. Die Option “WLAN & IP Konfig. beibehalten” ist standardmäßig ausgewählt. Wenn Sie den Reset mit dieser Option durchführen, dann behält die Kamera sämtliche Netzwerkdaten und ist nach dem Reset über die gleiche IP Adresse wie zuvor erreichbar. Auch die WLAN-Daten bleiben erhalten.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

ResetTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(ResetTable)