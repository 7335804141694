import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Windows/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_Windows.webp'
                    alt="Windows"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Windows"
              description="Verwenden Sie unser InstarVision Surveillance Center, um Ihre Kamera von Ihrem Windows-PC aus zu verwalten. Oder verwenden Sie eine der vielen unterstützten Anwendungen von Drittanbietern."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/macOS/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_macOS.webp'
                    alt="macOS"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="macOS"
              description="Verwenden Sie unser InstarVision Überwachungscenter, um Ihre Kamera von Ihrem iMac oder MacBook aus zu verwalten. Oder verwenden Sie eine der vielen unterstützten Anwendungen von Drittanbietern."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/macOS/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Linux/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_Linux.webp'
                    alt="Linux"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Linux"
              description="Verwenden Sie die Linux Open Source-Software wie Motion, Node-Red, Home Assistant, OpenHAB und ioBroker, um Ihre INSTAR IP-Kamera zu steuern."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Linux/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Android/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_Android.webp'
                    alt="Android"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Android"
              description="Verwenden Sie unsere Android-App InstarVision, um Ihre Kamera von Ihrem Smartphone oder Tablet aus zu verwalten. Oder verwenden Sie eine der vielen unterstützten Anwendungen von Drittanbietern."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Android/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/iOS/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_iOS.webp'
                    alt="iOS"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="iOS"
              description="Verwenden Sie unsere InstarVision iOS App, um Ihre Kamera von Ihrem iPhone oder iPad aus zu verwalten. Oder verwenden Sie eine der vielen unterstützten Anwendungen von Drittanbietern."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/iOS/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Smarthome/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_Smarthome.webp'
                    alt="Smarthome Systeme"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Smarthome Systeme"
              description="Ein Hausautomationssystem steuert Beleuchtung, Klima, Unterhaltungssysteme und Geräte. Es kann auch Sicherheit zu Hause wie Zugangskontrolle und IP-Kameras enthalten."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Smarthome/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Andere_Plattform/">
                  <StaticImage
                    src='../../images/Search/D_SearchThumb_Software.webp'
                    alt="Thirdparty Systems"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Drittanbieter Systeme"
              description="Verwenden Sie Ihr Synology oder QNAP NAS, um alle Ihre Kameras und ihre Videoaufnahmen zu verwalten. Oder setzen Sie Systeme von Homematic, Loxone, AVM, usw..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Andere_Plattform/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
