import React from "react";
import { Link } from "gatsby";

import { EuiText, EuiCallOut } from "@elastic/eui";

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" title="Beachten Sie bitte">
      <EuiText>
        <p>
          Es gibt eine{" "}
          <Link to="/Frequently_Asked_Question/WQHD_MQTTv5_Alarmserver_Testing/">
            neue Alarmserver API für WQHD 2K+ Kameras
          </Link>
          . Die einzelnen Schritte unten sind weiterhin gültig. Nur der URL
          Query nennt sich jetzt <code>&trigger</code> anstelle von{" "}
          <code>&active</code>, die Zahlenwerte haben sich geändert und
          ermöglichen jetzt das auswerten mehrerer, zeitgleicher Alarmauslöser!
          Sie finden eine Beispiel-Implementierung der MQTT- and
          HTTP-Alarmserver v2 API mit Node-RED{" "}
          <Link to="/Frequently_Asked_Question/WQHD_MQTTv5_Alarmserver_APIv2_NodeRED/">
            hier
          </Link>
          .
        </p>
      </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox;
