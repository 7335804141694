import YoutubeVideoFTP from 'components/Software/Other_Platforms/Synology/VideoFTPSynology';
import YoutubeVideoWQHDCam from 'components/Software/Other_Platforms/Synology/VideoCamWQHDSynology';
import YoutubeVideoCam from 'components/Software/Other_Platforms/Synology/VideoCamSynology';
import NavButtons from 'components/Software/Other_Platforms/NavButtons';
import ForumBox from 'components/Software/Other_Platforms/Synology/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  YoutubeVideoFTP,
  YoutubeVideoWQHDCam,
  YoutubeVideoCam,
  NavButtons,
  ForumBox,
  BreadCrumbs,
  SEOHelmet,
  React
};