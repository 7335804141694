import React from 'react'

import {
  EuiTitle,
  EuiSpacer,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule
} from '@elastic/eui'

import IntroCards from './IntroLensesToC'

export default function IntroHome() {

  return (
    <React.Fragment>
      <EuiSpacer />
        <EuiTitle size="m">
            <h2>Full HD Kameras</h2>
        </EuiTitle>
        <EuiHorizontalRule margin="xs" />
      <EuiFlexGroup gutterSize="l" direction="column" style={{margin: '0 7px'}} >
        <EuiFlexItem>
            <IntroCards />
        </EuiFlexItem>
    </EuiFlexGroup>
  </React.Fragment>
  );
}