import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'Cloud',
      href: '/de/Bewegungserkennung/INSTAR_Cloud/',
    },
    {
      text: 'FTP Server',
      href: '/de/Bewegungserkennung/Router_als_FTP_Server/',
    },
    {
      text: 'Alarmserver',
      href: '/de/Bewegungserkennung/Alarm_Server/',
    },
    {
      text: 'FTP Upload',
      href: '/de/Bewegungserkennung/Alarm_FTP_Upload/',
    },
    {
      text: 'SD Karten',
      href: '/de/Bewegungserkennung/SD_Karten_Zugriff/',
    },
    {
      text: 'Benachrichtigung',
      href: '/de/Bewegungserkennung/Alarm_Benachrichtigung/',
    },
    {
      text: 'Einrichten',
      href: '/de/Bewegungserkennung/Einrichten/',
    },
    {
      text: '◄ Bewegungserkennung',
      href: '/de/Bewegungserkennung/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={11}
        aria-label="Cloud Platform for your INSTAR IP Camera"
      />
      <EuiSpacer />
    </Fragment>
  );
}