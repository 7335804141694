import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="CA Zertifikate">
        <EuiText>
          <p>
            Sie möchten Ihr eigenes SSL-Zertifikat für Ihre eigene DDNS-Domain verwenden? Kein Problem! Generieren Sie ein selbstsigniertes Zertifikat und laden Sie das Zertifikat (<code>X.509 Zertifikat</code> und den Schlüssel <code>privater RSA-Schlüssel</code>) hoch - kombiniert in einer einzigen <code>*.pem</code>-Datei. Dies kann über das <Link to="/Web_User_Interface/1440p_Serie/Netzwerk/SSL_Zertifikat/">Menü SSL-Zertifikat</Link> erfolgen. Wenn Sie ein Zertifikat verwenden möchten, das von einer <bold>Zertifizierungsstelle</bold> signiert ist - z. B. ein von <a to='https://letsencrypt.org/'>Let's Encrypt</a> generiertes Zertifikat - müssen Sie hier das öffentliche CA-Zertifikat Ihres Anbieters hochladen. Wenn <bold>Sie kein</bold> Zertifikat hochladen, verwendet Ihre Kamera das CA-Zertifikat, das zu Ihrer persönlichen <Link to='/Web_User_Interface/1440p_Serie/Netzwerk/Fernzugriff/'>INSTAR-DDNS-Adresse</Link> gehört - in jedem Fall ist Ihre verschlüsselte Kommunikation sicher!
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

