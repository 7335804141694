import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class ActionTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Alarm Aktionen" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Alarm Video auf SD-Karte senden', descriptionColumn: 'Aktivieren, um im Alarmfall ein Video auf die interne SD KARTE zu senden. Eine Alarm-Video Datei startet immer mit einem A und danach mit einem Zeitstempel. Ihre Kamera nimmt immer 3 Sekunden vor dem Alarm und 15 Sekunden nach dem Alarm auf.' },
        { actionColumn: 'Alarm Video auf einen FTP(S) oder sFTP Server senden', descriptionColumn: 'Aktivieren, um im Alarmfall ein Video auf Ihren FTP Server zu senden. Bitte beachten Sie, dass Sie zuerst Ihre FTP SERVER INFORMATIONEN eingeben müssen.' },
        { actionColumn: 'Aufzeichnung in der INSTAR-Cloud speichern', descriptionColumn: 'Aktivieren Sie diese Funktion, um Alarmaufzeichnungen an den INSTAR Cloud-Dienst zu senden.' },
        { actionColumn: 'Videoauflösung', descriptionColumn: 'Stellen Sie die Auflösung für die Alarmvideos Ihrer Kamera ein.' },
        { actionColumn: 'Länge der Alarmvideos', descriptionColumn: 'Legen Sie die Länge Ihrer Alarmaufzeichnung fest (Ihre Kamera fügt eine 3s-Voraufzeichnung hinzu)' },
        { actionColumn: 'Alarm-Email senden', descriptionColumn: 'Alle Alarm-Aktionen können über die BEREICHE DER BEWEGUNGSERKENNUNG, den Alarmeigang, als auch denAUDIO ALARM ausgelöst werden. Hier können Sie die Alarmbenachrichtigung per Email aktivieren. Bitte beachten Sie, dass Sie zuerst den AUSGEHENDEN EMAIL SERVER (SMTP) und den EMAIL EMPFÄNGER eingeben müssen.' },
        { actionColumn: 'Alarmton ausgeben', descriptionColumn: 'Bei einem Alarm wird für die Dauer der Alarmphase ein Warnton abgespielt. Bei Aussenkameras wird hierfür ein externer Lautsprecher benötigt.' },
        { actionColumn: 'Alarmtondauer', descriptionColumn: 'Wie lange soll der Alarmton ausgegeben werden. Mit einem Wert von 0 wird die Tondatei einmal vollständig ausgegeben.' },
        { actionColumn: 'Alarmausgang aktivieren (Relais)', descriptionColumn: 'Lassen Sie Ihre Kamera das Alarmrelais bei einem Alarmereignis öffnen oder schließen (falls bei Ihrem Kameramodell vorhanden).' },
        { actionColumn: 'Normalzustand des Relais', descriptionColumn: 'Legen Sie fest ob das Relais im Normalzustand offen (N.O.) oder geschlossen (N.C.) sein soll.' },
        { actionColumn: 'Schaltdauer in Sekunden', descriptionColumn: 'Legen Sie fest wie lange das Relais nach einem Alarm im aktiven Zustand verbleiben soll.' },
        { actionColumn: 'Alarm Snapshot auf SD-Karte senden', descriptionColumn: 'Aktivieren, um im Alarmfall einen Snapshot auf die interne SD Karte zu senden.' },
        { actionColumn: 'Alarm Snapshot auf FTPS/sFTP Server senden', descriptionColumn: 'Aktivieren, um im Alarmfall Snapshots auf Ihren FTP Server zu senden. Bitte beachten Sie, dass Sie zuerst Ihre FTP Server Informationen im FTP Menü eingeben müssen.' },
        { actionColumn: 'Bildauflösung', descriptionColumn: 'Stellen Sie die Auflösung für die Snapshots ein, die im Alarmfall per FTP hochgeladen werden, per Email gesendet werden oder auf der SD-Karte gespeichert werden.' },
        { actionColumn: 'Fotos in Email (email)', descriptionColumn: 'Stellen Sie ein, wie viele Snapshots in einer Alarm Email angehängt werden sollen. Bitte beachten Sie, dass die Email etwas verzögert eintrifft, wenn zu viele Bilder angehängt werden. Wenn Sie ohne eine Verzögerung benachrichtigt werden wollen, nutzen Sie bitte den Push Dienst.' },
        { actionColumn: 'Fotos auf SD-Karte', descriptionColumn: 'Stellen Sie ein, wie viele Snapshots Sie im Alarmfall auf Ihrer SD Karte speichern wollen.' },
        { actionColumn: 'Fotos per FTP Upload', descriptionColumn: 'Stellen Sie ein, wie viele Snapshots Sie im Alarmfall von Ihrer Kamera auf Ihren FTP Server senden wollen.' },
        { actionColumn: 'Uploadintervall (Email)', descriptionColumn: 'Wenn ein Alarm ausgelöst wird, erstellt Ihre Kamera die oben eingestellte Anzahl von Bildern mit einem Aufnahmeintervall von 1s. Ihre Kamera geht dann in das hier eingestellte Abkühlintervall, bevor sie einen neuen Satz von Bildern sendet, wenn ein Alarm ausgelöst wird.' },
        { actionColumn: 'Speicherungsintervall (SD)', descriptionColumn: 'Wenn ein Alarm ausgelöst wird, erstellt Ihre Kamera die oben eingestellte Anzahl von Bildern mit einem Aufnahmeintervall von 1s. Ihre Kamera geht dann in das hier eingestellte Abkühlintervall, bevor sie einen neuen Satz von Bildern speichert, wenn ein Alarm ausgelöst wird.' },
        { actionColumn: 'Uploadintervall (FTP)', descriptionColumn: 'Wenn ein Alarm ausgelöst wird, erstellt Ihre Kamera die oben eingestellte Anzahl von Bildern mit einem Aufnahmeintervall von 1s. Ihre Kamera geht dann in das hier eingestellte Abkühlintervall, bevor sie einen neuen Satz von Bildern sendet, wenn ein Alarm ausgelöst wird.' },
        { actionColumn: 'Zusätzliche LED Beleuchtung bei Alarm', descriptionColumn: 'Wenn Ihr Kameramodell mit einem zusätzlichen Satz von LEDs einer anderen Wellenlänge (940 nm oder weißes Licht) ausgestattet ist, können Sie diese bei Auslösung eines Alarms aktivieren. Stellen Sie auch das Intervall ein, in dem diese LEDs nach einer Alarmauslösung aktiv sein sollen.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

ActionTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(ActionTable)