import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Android/InstarVision/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_InstarVision_Android.webp'
                    alt="InstarVision"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="InstarVision"
              description="Die Android-App InstarVision für Ihr Android-Tablet oder -Smartphone. Steuern Sie Ihre Kamera, wenn Sie keinen Zugriff auf Ihren Computer haben."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Android/InstarVision/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Android/TinyCam_Monitor/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_TinyCam.webp'
                    alt="TinyCam"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="TinyCam"
              description="Die Android-App TinyCam für Ihr Android-Tablet oder -Smartphone. Steuern Sie Ihre Kamera, wenn Sie keinen Zugriff auf Ihren Computer haben."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Android/TinyCam_Monitor/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Android/IP_Cam_Viewer/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IP_Cam_Viewer.webp'
                    alt="IP Cam Viewer"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IP Cam Viewer"
              description="Die Android-App IP CamViewer für Ihr Android-Tablet oder -Smartphone. Steuern Sie Ihre Kamera, wenn Sie keinen Zugriff auf Ihren Computer haben."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Android/IP_Cam_Viewer/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
