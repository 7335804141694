import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Serie/Alarm/Aktionen/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_Alarm_Actions.webp'
                    alt="Alarm Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Alarm Aktionen"
              description="Passen Sie das Verhalten der Kamera im Falle eines Bewegungsalarm-Auslöseereignisses an. Sie können die Kamera so einstellen, dass sie Ihnen eine E-Mail sendet und Schnappschüsse oder Videos auf Ihrer SD-Karte speichert..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/720p_Serie/Alarm/Aktionen/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Serie/Alarm/Bereiche/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_Alarm_Areas.webp'
                    alt="Alarm Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Alarmbereiche"
              description="Die Alarmbereiche erkennen Bewegungen durch eine Bildanalyse, die auf jede Veränderung innerhalb des Videobildes empfindlich ist, anstatt..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/720p_Serie/Alarm/Bereiche/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Serie/Alarm/Zeitplan/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_Alarm_Schedule.webp'
                    alt="Alarm Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Alarm Zeitplan"
              description="Hier können Sie einen Zeitplan festlegen, in dem die Bewegungserkennung für die aktiven Erkennungsbereiche aktiv sein soll. Jedes blaue Quadrat steht für 30 Minuten scharfe Bewegung..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/720p_Serie/Alarm/Zeitplan/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Serie/Alarm/SMTP_Server/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_Alarm_SMTP_Server.webp'
                    alt="Alarm Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="SMTP Server"
              description="Verwenden Sie einfach die INSTAR-Voreinstellung, wenn Sie die SMTP-Informationen Ihres E-Mail-Providers nicht kennen. Wählen Sie die Voreinstellung aus dem Dropdown-Menü und bestätigen Sie. Die E-Mail..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/720p_Serie/Alarm/SMTP_Server/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Serie/Alarm/Email/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_Alarm_Email.webp'
                    alt="Alarm Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Email"
              description="Hier müssen Sie den Absender, die Empfänger, den Betreff sowie optional einen kurzen E-Mail-Text mit maximal 64 Zeichen eingeben. Die Absenderadresse sollte..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/720p_Serie/Alarm/Email/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Serie/Alarm/FTP/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_Alarm_FTP.webp'
                    alt="Alarm Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="FTP"
              description="Um den Video-FTP-Upload zu verwenden, müssen Sie zunächst die Adresse Ihres FTP-Servers und Ihre Anmeldedaten festlegen. Sie können z. B. den FTP-Dienst Ihres Routers als FTP-Server verwenden..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/720p_Serie/Alarm/FTP/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Serie/Alarm/Alarmserver/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_Alarm_Alarmserver.webp'
                    alt="Alarm Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Alarm Server"
              description="Verwenden Sie Ihre Kamera, um Ihr Hausautomatisierungssystem im Falle einer Bewegungserkennung zu benachrichtigen..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/720p_Serie/Alarm/Alarmserver/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Serie/Alarm/Audio_Alarm/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_Alarm_Audio_Alarm.webp'
                    alt="Alarm Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Audio Alarm"
              description="Der Audio-Alarm der Kamera nutzt das integrierte Mikrofon, um Geräusche zu erkennen und Alarmereignisse auszulösen. Diese Ereignisse werden genau wie Bewegungserkennungsereignisse behandelt..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/720p_Serie/Alarm/Audio_Alarm/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Serie/Alarm/SD_Karte/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_Alarm_SD_Card.webp'
                    alt="Alarm Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="SD Karte"
              description="Bitte markieren Sie das entsprechende Kästchen und klicken Sie auf Senden, um Ihre SD-Karte entweder zu formatieren oder auszubauen. Es wird empfohlen, die SD-Karte immer auszuhängen, bevor Sie sie entfernen..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/720p_Serie/Alarm/SD_Karte/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
