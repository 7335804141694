import React from 'react'

import {
  EuiAccordion,
  EuiText,
  EuiSpacer,
  EuiImage,
  EuiPanel
} from '@elastic/eui'

import Command01 from '../../../../../images/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/INSTAR_Cloud_Alexa_Skill_06-EN.webp'
import Command02 from '../../../../../images/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/INSTAR_Cloud_Alexa_Skill_07-EN.webp'
import Command03 from '../../../../../images/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/INSTAR_Cloud_Alexa_Skill_08-EN.webp'
import Command04 from '../../../../../images/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/INSTAR_Cloud_Alexa_Skill_09-EN.webp'
import Command05 from '../../../../../images/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/INSTAR_Cloud_Alexa_Skill_10-EN.webp'
import Command06 from '../../../../../images/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/INSTAR_Cloud_Alexa_Skill_11-EN.webp'
import Command07 from '../../../../../images/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/INSTAR_Cloud_Alexa_Skill_12-EN.webp'
import Command08 from '../../../../../images/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/INSTAR_Cloud_Alexa_Skill_13-EN.webp'
import Command09 from '../../../../../images/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/INSTAR_Cloud_Alexa_Skill_14-EN.webp'
import Command10 from '../../../../../images/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/INSTAR_Cloud_Alexa_Skill_15-EN.webp'
import Command11 from '../../../../../images/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/INSTAR_Cloud_Alexa_Skill_16-EN.webp'
import Command12 from '../../../../../images/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/INSTAR_Cloud_Alexa_Skill_17-EN.webp'
import Command13 from '../../../../../images/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/INSTAR_Cloud_Alexa_Skill_18-EN.webp'
import Command14 from '../../../../../images/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/INSTAR_Cloud_Alexa_Skill_19-EN.webp'
import Command15 from '../../../../../images/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/INSTAR_Cloud_Alexa_Skill_20-EN.webp'
import Command16 from '../../../../../images/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/INSTAR_Cloud_Alexa_Skill_21-EN.webp'
import Command17 from '../../../../../images/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/INSTAR_Cloud_Alexa_Skill_22-EN.webp'


function CommandAccordion() {
  return (
    <React.Fragment>
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion1"
          buttonContent="Starten Sie die INSTAR Cloud Skill"
          paddingSize="l"
          style={{height: "fit-content"}}>
            <EuiPanel color="subdued">
              <EuiText style={{height: 300}}>
                <ul>
                  <li>Öffne INSTAR Cloud</li>
                  <li>Starte INSTAR Cloud</li>
                </ul>
              </EuiText>
            </EuiPanel>
          </EuiAccordion>
        </EuiPanel>
  
        <EuiSpacer />
  
      
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion1"
          buttonContent="Aktuelle Alarmvideos abspielen"
          paddingSize="l"
          style={{height: "fit-content"}}>
            <EuiPanel color="subdued">
              <EuiText style={{height: 300}}>
                <ul>
                  <li>Zeige die letzten Alarme</li>
                  <li>Zeige mir die neuesten Alarme</li>
                </ul>
              </EuiText>
            </EuiPanel>
          </EuiAccordion>
        </EuiPanel>
  
        <EuiSpacer />
  
      
        <EuiPanel hasBorder>
          <EuiAccordion
            id="accordion1"
            buttonContent="Abspielen der letzten Alarmvideos von einer bestimmten Kamera"
            paddingSize="l"
            style={{height: "fit-content"}}>
              <EuiPanel color="subdued">
                <EuiText style={{height: 280}}>
                  <EuiImage
                    size="l"
                    float="left"
                    margin="l"
                    hasShadow
                    allowFullScreen
                    alt="Abspielen der letzten Alarmvideos von einer bestimmten Kamera"
                    src={Command01}
                  />
                  <h5>Alexa Befehle:</h5>
                  <ul>
                    <li>Zeige die letzten Alarme von Kamera <code>[cameraName]</code></li>
                    <li>Zeige mir die neuesten Aufnahmen von Kamera <code>[cameraName]</code></li>
                  </ul>
                  <p>
                    <code>[cameraName]</code> ist der Name, den Sie Ihrer Kamera innerhalb des INSTAR Cloud Systems gegeben haben.
                  </p>
                </EuiText>
              </EuiPanel>
            </EuiAccordion>
          </EuiPanel>
  
          <EuiSpacer />
  
          <EuiPanel hasBorder>
            <EuiAccordion
              id="accordion1"
              buttonContent="Alle Alarmvideos von einer bestimmten Kamera wiedergeben"
              paddingSize="l"
              style={{height: "fit-content"}}>
                <EuiPanel color="subdued">
                  <EuiText style={{height: 280}}>
                    <EuiImage
                      size="l"
                      float="left"
                      margin="l"
                      hasShadow
                      allowFullScreen
                      alt="Alle Alarmvideos von einer bestimmten Kamera wiedergeben"
                      src={Command02}
                    />
                    <h5>Alexa Befehle:</h5>
                    <ul>
                      <li>Zeige alle Alarme von Kamera <code>[cameraName]</code></li>
                      <li>Zeige mir alle Aufnahmen von Kamera <code>[cameraName]</code></li>
                    </ul>
                    <p>
                      <code>[cameraName]</code> ist der Name, den Sie Ihrer Kamera innerhalb des INSTAR Cloud Systems gegeben haben.
                    </p>
                  </EuiText>
                </EuiPanel>
              </EuiAccordion>
            </EuiPanel>
  
            <EuiSpacer />
  
            <EuiPanel hasBorder>
              <EuiAccordion
                id="accordion2"
                buttonContent="Wiedergabe einer bestimmten Anzahl der neuesten Alarmvideos"
                buttonContentClassName="eui-textTruncate"
                paddingSize="l">
                <EuiPanel color="subdued">
                  <EuiText style={{height: 280}}>
                    <EuiImage
                      size="l"
                      float="left"
                      margin="l"
                      hasShadow
                      allowFullScreen
                      alt="Wiedergabe einer bestimmten Anzahl der neuesten Alarmvideos"
                      src={Command03}
                    />
                    <h5>Alexa Befehle:</h5>
                    <ul>
                      <li>Zeige die letzten <code>[alarmsCount]</code> Alarme</li>
                      <li>Zeige mir die <code>[alarmsCount]</code> neuesten Videos</li>
                    </ul>
                    <p>
                      <code>[alarmsCount]</code> muss eine Zahl sein: 1, 2, 3 ... 100
                    </p>
                  </EuiText>
                </EuiPanel>
              </EuiAccordion>
            </EuiPanel>
  
            <EuiSpacer />
  
        <EuiPanel hasBorder>
          <EuiAccordion
            id="accordion3"
            buttonContent="Wiedergabe von Alarmvideos nach Alarmstatus"
            paddingSize="m">
            <EuiPanel color="subdued">
              <EuiText style={{height: 280}}>
                <EuiImage
                  size="l"
                  float="left"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="Wiedergabe von Alarmvideos nach Alarmstatus"
                  src={Command04}
                />
                <h5>Alexa Befehle:</h5>
                <ul>
                  <li>Zeige alle <code>[status]</code>* Alarme</li>
                  <li>Zeige mir alle <code>[status]</code>* Aufnahmen</li>
                </ul>
              </EuiText>
            </EuiPanel>
          </EuiAccordion>
        </EuiPanel>
  
        <EuiSpacer />
  
        <EuiPanel hasBorder>
          <EuiAccordion
            id="accordion4"
            buttonContent="Abspielen von Alarmvideos nach Datum"
            paddingSize="m">
            <EuiPanel color="subdued">
              <EuiText style={{height: 280}}>
                <EuiImage
                  size="l"
                  float="left"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="Abspielen von Alarmvideos nach Datum"
                  src={Command05}
                />
                <h5>Alexa Befehle:</h5>
                <ul>
                  <li>Zeige alle Alarme vom <code>[alarmsDate]</code></li>
                  <li>Zeige mir alle Aufnahmen vom <code>[alarmsDate]</code></li>
                </ul>
                <p>
                  <code>[alarmsDate]</code> wie <em>heute</em>, <em>gestern</em>, <em>vorgestern</em> oder ein bestimmtes Datum, wie <em>Erster April 2020</em> <strong>Beachten Sie</strong>: Sie müssen in letzteren Fall immer das Jahr mit angeben.
                </p>
              </EuiText>
            </EuiPanel>
          </EuiAccordion>
        </EuiPanel>
  
        <EuiSpacer />
  
        <EuiPanel hasBorder>
          <EuiAccordion
            id="accordion4"
            buttonContent="Abspielen von Alarmvideos nach erkannten Objekten"
            paddingSize="m">
            <EuiPanel color="subdued">
              <EuiText style={{height: 280}}>
                <EuiImage
                  size="l"
                  float="left"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="Abspielen von Alarmvideos nach erkannten Objekten"
                  src={Command06}
                />
                <h5>Alexa Befehle:</h5>
                <ul>
                  <li>Zeige alle Alarme mit <code>[object]</code>*</li>
                  <li>Zeige alle Aufnahmen mit <code>[object]</code>*</li>
                </ul>
              </EuiText>
            </EuiPanel>
          </EuiAccordion>
        </EuiPanel>
  
        <EuiSpacer />
  
        <EuiPanel hasBorder>
          <EuiAccordion
            id="accordion4"
            buttonContent="Wiedergabe einer bestimmten Anzahl von Alarmvideos von einer bestimmten Kamera"
            paddingSize="m">
            <EuiPanel color="subdued">
              <EuiText style={{height: 280}}>
                <EuiImage
                  size="l"
                  float="left"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="Wiedergabe einer bestimmten Anzahl von Alarmvideos von einer bestimmten Kamera"
                  src={Command07}
                />
                <h5>Alexa Befehle:</h5>
                <ul>
                  <li>Zeige die letzten <code>[alarmsCount]</code> Alarme von Kamera <code>[cameraName]</code></li>
                  <li>PSpiele mir die letzten <code>[alarmsCount]</code> Aufnahmen von Kamera <code>[cameraName]</code> ab</li>
                </ul>
                <p>
                  <code>[cameraName]</code> ist der Name, den Sie Ihrer Kamera innerhalb des INSTAR Cloud Systems gegeben haben.
                </p>
                <p>
                  <code>[alarmsCount]</code> muss eine Zahl sein: 1, 2, 3...100
                </p>
              </EuiText>
            </EuiPanel>
          </EuiAccordion>
        </EuiPanel>
  
        <EuiSpacer />
  
        <EuiPanel hasBorder>
          <EuiAccordion
            id="accordion4"
            buttonContent="Wiedergabe von Alarmvideos basierend auf Kameraname und Datum"
            paddingSize="m">
            <EuiPanel color="subdued">
              <EuiText style={{height: 280}}>
                <EuiImage
                  size="l"
                  float="left"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="Wiedergabe von Alarmvideos basierend auf Kameraname und Datum"
                  src={Command08}
                />
                <h5>Alexa Befehle:</h5>
                <ul>
                  <li>Zeige alle Alarme von <code>[cameraName]</code> vom <code>[alarmsDate]</code></li>
                  <li>Zeige alle Alarme vom <code>[alarmsDate]</code> von Kamera <code>[cameraName]</code></li>
                </ul>
                <p>
                  <code>[cameraName]</code> ist der Name, den Sie Ihrer Kamera innerhalb des INSTAR Cloud Systems gegeben haben.
                </p>
                <p>
                  <code>[alarmsDate]</code> wie <em>heute</em>, <em>gestern</em>, <em>vorgestern</em> oder ein bestimmtes Datum, wie <em>Erster April 2020</em> <strong>Beachten Sie</strong>: Sie müssen in letzteren Fall immer das Jahr mit angeben.
                </p>
              </EuiText>
            </EuiPanel>
          </EuiAccordion>
        </EuiPanel>
  
        <EuiSpacer />
  
        <EuiPanel hasBorder>
          <EuiAccordion
            id="accordion4"
            buttonContent="Abspielen der Alarmvideos basierend auf erkannten Objekten und Datum"
            paddingSize="m">
            <EuiPanel color="subdued">
              <EuiText style={{height: 280}}>
                <EuiImage
                  size="l"
                  float="left"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="Abspielen der Alarmvideos basierend auf erkannten Objekten und Datum"
                  src={Command09}
                />
                <h5>Alexa Befehle:</h5>
                <ul>
                  <li>Zeige alle Alarme vom <code>[alarmsDate]</code> mit <code>[object]</code>*</li>
                  <li>Zeige alle <code>[object]</code>* die am <code>[alarmsDate]</code> erkannt wurden</li>
                </ul>
                <p>
                  <code>[alarmsDate]</code> wie <em>heute</em>, <em>gestern</em>, <em>vorgestern</em> oder ein bestimmtes Datum, wie <em>Erster April 2020</em> <strong>Beachten Sie</strong>: Sie müssen in letzteren Fall immer das Jahr mit angeben.
                </p>
              </EuiText>
            </EuiPanel>
          </EuiAccordion>
        </EuiPanel>
  
        <EuiSpacer />
  
        <EuiPanel hasBorder>
          <EuiAccordion
            id="accordion4"
            buttonContent="Abspielen der Alarmvideos auf der Grundlage des Kameranamens, der erkannten Objekte und des Datums"
            paddingSize="m">
            <EuiPanel color="subdued">
              <EuiText style={{height: 280}}>
                <EuiImage
                  size="l"
                  float="left"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="Abspielen der Alarmvideos auf der Grundlage des Kameranamens, der erkannten Objekte und des Datums"
                  src={Command10}
                />
                <h5>Alexa Befehle:</h5>
                <ul>
                  <li>Zeige alle Alarme von <code>[cameraName]</code> vom <code>[alarmsDate]</code> mit <code>[object]</code>*</li>
                  <li>Zeige alle <code>[object]</code>* die am <code>[alarmsDate]</code> erkannt wurden bei Kamera <code>[cameraName]</code></li>
                </ul>
                <p>
                  <code>[cameraName]</code> ist der Name, den Sie Ihrer Kamera innerhalb des INSTAR Cloud Systems gegeben haben.
                </p>
                <p>
                  <code>[alarmsDate]</code> wie <em>heute</em>, <em>gestern</em>, <em>vorgestern</em> oder ein bestimmtes Datum, wie <em>Erster April 2020</em> <strong>Beachten Sie</strong>: Sie müssen in letzteren Fall immer das Jahr mit angeben.
                </p>
              </EuiText>
            </EuiPanel>
          </EuiAccordion>
        </EuiPanel>
  
        <EuiSpacer />
  
        <EuiPanel hasBorder>
          <EuiAccordion
            id="accordion4"
            buttonContent="Wiedergabe von Alarmvideos basierend auf Datum Uhrzeit und des Kameranamens"
            paddingSize="m">
            <EuiPanel color="subdued">
              <EuiText style={{height: 280}}>
                <EuiImage
                  size="l"
                  float="left"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="Wiedergabe von Alarmvideos basierend auf Datum Uhrzeit und des Kameranamens"
                  src={Command11}
                />
                <h5>Alexa Befehle:</h5>
                <ul>
                  <li>Zeige alle Alarme von Kamera <code>[cameraName]</code> vom <code>[alarmsDate]</code> zwischen <code>[timeStart]</code> und <code>[timeEnd]</code> Uhr</li>
                  <li>Zeige mir alle Aufnahmen von Kamera <code>[cameraName]</code> vom <code>[alarmsDate]</code> zwischen <code>[timeStart]</code> und <code>[timeEnd]</code> Uhr</li>
                </ul>
                <p>
                  <code>[cameraName]</code> ist der Name, den Sie Ihrer Kamera innerhalb des INSTAR Cloud Systems gegeben haben.
                </p>
                <p>
                  <code>[alarmsDate]</code> wie <em>heute</em>, <em>gestern</em>, <em>vorgestern</em> oder ein bestimmtes Datum, wie <em>Erster April 2020</em> <strong>Beachten Sie</strong>: Sie müssen in letzteren Fall immer das Jahr mit angeben.
                </p>
                <p>
                  <code>[timeStart]</code> / <code>[timeEnd]</code> eine Tageszeit, etwa 7 Uhr morgens, 19 Uhr abends
                </p>
              </EuiText>
            </EuiPanel>
          </EuiAccordion>
        </EuiPanel>
  
        <EuiSpacer />
  
        <EuiPanel hasBorder>
          <EuiAccordion
            id="accordion4"
            buttonContent="Wiedergabe von Alarmvideos eines bestimmten Kamerasets"
            paddingSize="m">
            <EuiPanel color="subdued">
              <EuiText style={{height: 280}}>
                <EuiImage
                  size="l"
                  float="left"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="Wiedergabe von Alarmvideos eines bestimmten Kamerasets"
                  src={Command12}
                />
                <h5>Alexa Befehle:</h5>
                <ul>
                  <li>Zeige alle Alarme von Kameraset <code>[cameraSet]</code></li>
                  <li>Zeige mir alle Aufnahmen von Kameraset <code>[cameraSet]</code></li>
                </ul>
                <p>
                  <code>[cameraSet]</code> ist der Name eines Kamerasets, das Sie innerhalb des INSTAR Cloud-Systems erstellt haben.
                </p>
              </EuiText>
            </EuiPanel>
          </EuiAccordion>
        </EuiPanel>
  
        <EuiSpacer />
  
        <EuiPanel hasBorder>
          <EuiAccordion
            id="accordion4"
            buttonContent="Wiedergabe einer bestimmten Anzahl von Alarmvideos von einem bestimmten Kameraset"
            paddingSize="m">
            <EuiPanel color="subdued">
              <EuiText style={{height: 280}}>
                <EuiImage
                  size="l"
                  float="left"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="Wiedergabe einer bestimmten Anzahl von Alarmvideos von einem bestimmten Kameraset"
                  src={Command13}
                />
                <h5>Alexa Befehle:</h5>
                <ul>
                  <li>Zeige die letzten <code>[alarmsCount]</code> Alarme von Kameraset <code>[cameraSet]</code></li>
                  <li>Bitte zeige mir die neuesten <code>[alarmsCount]</code> Aufnahmen von Kameraset <code>[cameraSet]</code></li>
                </ul>
                <p>
                  <code>[alarmsCount]</code> muss eine Zahl sein: 1, 2, 3...100
                </p>
                <p>
                  <code>[cameraSet]</code> ist der Name Ihres Kamerasets, das Sie innerhalb des INSTAR Cloud-Systems erstellt haben.
                </p>
              </EuiText>
            </EuiPanel>
          </EuiAccordion>
        </EuiPanel>
  
        <EuiSpacer />
  
        <EuiPanel hasBorder>
          <EuiAccordion
            id="accordion4"
            buttonContent="Anzahl an Kameras"
            paddingSize="m">
            <EuiPanel color="subdued">
              <EuiText style={{height: 280}}>
                <EuiImage
                  size="l"
                  float="left"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="Anzahl an Kameras"
                  src={Command14}
                />
                <h5>Alexa Befehle:</h5>
                <ul>
                  <li>Wie viele Kameras befinden sich in meinem Account</li>
                  <li>Sag mir die Anzahl der Kameras in meinem Account</li>
                </ul>
              </EuiText>
            </EuiPanel>
          </EuiAccordion>
        </EuiPanel>
  
        <EuiSpacer />
  
        <EuiPanel hasBorder>
          <EuiAccordion
            id="accordion4"
            buttonContent="Verfügbarer Speicherplatz"
            paddingSize="m">
            <EuiPanel color="subdued">
              <EuiText style={{height: 280}}>
                <EuiImage
                  size="l"
                  float="left"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="Verfügbarer Speicherplatz"
                  src={Command15}
                />
                <h5>Alexa Befehle:</h5>
                <ul>
                  <li>Wie viel Speicher ist noch frei</li>
                  <li>Wie viel Speicherplatz habe ich noch</li>
                </ul>
              </EuiText>
            </EuiPanel>
          </EuiAccordion>
        </EuiPanel>
  
        <EuiSpacer />
  
        <EuiPanel hasBorder>
          <EuiAccordion
            id="accordion4"
            buttonContent="Auslaufsdatum des Speicherplatzes"
            paddingSize="m">
            <EuiPanel color="subdued">
              <EuiText style={{height: 280}}>
                <EuiImage
                  size="l"
                  float="left"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="Auslaufsdatum des Speicherplatzes"
                  src={Command16}
                />
                <h5>Alexa Befehle:</h5>
                <ul>
                  <li>Wann läuft der Speicherplan aus</li>
                  <li>Bis wann läuft mein Speicherplan</li>
                </ul>
              </EuiText>
            </EuiPanel>
          </EuiAccordion>
        </EuiPanel>
  
        <EuiSpacer />
  
        <EuiPanel hasBorder>
          <EuiAccordion
            id="accordion4"
            buttonContent="Anzahl der Alarme einer bestimmten Kamera"
            paddingSize="m">
            <EuiPanel color="subdued">
              <EuiText style={{height: 280}}>
                <EuiImage
                  size="l"
                  float="left"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="Anzahl der Alarme einer bestimmten Kamera"
                  src={Command17}
                />
                <h5>Alexa Commands:</h5>
                <ul>
                  <li>Wie viele Videos hat Kamera <code>[cameraName]</code></li>
                  <li>Wie viele Alarme hat Kamera <code>[cameraName]</code></li>
                </ul>
                <p>
                  <code>[cameraName]</code> ist der Name, den Sie Ihrer Kamera innerhalb des INSTAR Cloud Systems gegeben haben.
                </p>
              </EuiText>
            </EuiPanel>
          </EuiAccordion>
        </EuiPanel>
  
        <EuiSpacer />
  
        <EuiPanel hasBorder>
          <EuiAccordion
            id="accordion4"
            buttonContent="Anzahl der Alarme bestimmter Kamerasets"
            paddingSize="m">
            <EuiPanel color="subdued">
              <EuiText style={{height: 300}}>
                <h5>Alexa Befehle:</h5>
                <ul>
                  <li>Wie viele Alarme sind in Kameraset <code>[cameraSet]</code></li>
                  <li>Wie viele Alarme hat Kameraset <code>[cameraSet]</code></li>
                </ul>
              </EuiText>
            </EuiPanel>
          </EuiAccordion>
        </EuiPanel>
  
        <EuiSpacer />
  
        <EuiPanel hasBorder>
          <EuiAccordion
            id="accordion4"
            buttonContent="Available storage"
            paddingSize="m">
            <EuiPanel color="subdued">
              <EuiText style={{height: 300}}>
                <h5>Alexa Commands:</h5>
                <ul>
                  <li>How much space is left on my cloud</li>
                  <li>How much memory is still available</li>
                </ul>
                <p>
                  <code>[cameraSet]</code> ist der Name Ihres Kamerasets, das Sie innerhalb des INSTAR Cloud-Systems erstellt haben.
                </p>
              </EuiText>
            </EuiPanel>
          </EuiAccordion>
        </EuiPanel>
  
        <EuiSpacer />
  
        <EuiPanel hasBorder>
          <EuiAccordion
            id="accordion4"
            buttonContent="Ändern Sie Ihre Alarm-E-Mail-Einstellungen"
            paddingSize="m">
            <EuiPanel color="subdued">
              <EuiText style={{height: 300}}>
                <h5>Alexa Befehle:</h5>
                <ul>
                  <li><code>[switch]</code>* Alarm Emails</li>
                  <li>Email bei Alarm <code>[switch]</code>*</li>
                </ul>
              </EuiText>
            </EuiPanel>
          </EuiAccordion>
        </EuiPanel>
  
        <EuiSpacer />
  
        <EuiPanel hasBorder>
          <EuiAccordion
            id="accordion4"
            buttonContent="Objekterkennung Einstellungen für Kameras ändern"
            paddingSize="m">
            <EuiPanel color="subdued">
              <EuiText style={{height: 300}}>
                <h5>Alexa Befehle:</h5>
                <ul>
                  <li><code>[switch]</code>* Objekterkennung für <code>[cameraName]</code></li>
                  <li>Objekterkennung für Kamera <code>[switch]</code>* <code>[cameraName]</code></li>
                </ul>
                <p>
                  <code>[cameraSet]</code> ist der Name, den Sie Ihrer Kamera innerhalb des INSTAR Cloud Systems gegeben haben.
                </p>
              </EuiText>
            </EuiPanel>
          </EuiAccordion>
        </EuiPanel>
  
        <EuiSpacer />
  
        <EuiPanel hasBorder>
          <EuiAccordion
            id="accordion4"
            buttonContent="Video Pausieren"
            paddingSize="m">
            <EuiPanel color="subdued">
              <EuiText style={{height: 300}}>
                <h5>Alexa Befehle:</h5>
                <ul>
                  <li>Pause</li>
                  <li>Pausieren</li>
                </ul>
              </EuiText>
            </EuiPanel>
          </EuiAccordion>
        </EuiPanel>
  
        <EuiSpacer />
  
        <EuiPanel hasBorder>
          <EuiAccordion
            id="accordion4"
            buttonContent="Video Fortsetzen"
            paddingSize="m">
            <EuiPanel color="subdued">
              <EuiText style={{height: 300}}>
                <h5>Alexa Befehle:</h5>
                <ul>
                  <li>Fortsetzen</li>
                  <li>Mach Weiter</li>
                </ul>
              </EuiText>
            </EuiPanel>
          </EuiAccordion>
        </EuiPanel>
  
        <EuiSpacer />
  
        <EuiPanel hasBorder>
          <EuiAccordion
            id="accordion4"
            buttonContent="Nächstes Video"
            paddingSize="m">
            <EuiPanel color="subdued">
              <EuiText style={{height: 300}}>
                <h5>Alexa Befehle:</h5>
                <ul>
                  <li>Nächstes</li>
                  <li>Weiter</li>
                </ul>
              </EuiText>
            </EuiPanel>
          </EuiAccordion>
        </EuiPanel>
  
        <EuiSpacer />
  
        <EuiPanel hasBorder>
          <EuiAccordion
            id="accordion4"
            buttonContent="Vorheriges Video"
            paddingSize="m">
            <EuiPanel color="subdued">
              <EuiText style={{height: 300}}>
                <h5>Alexa Befehle:</h5>
                <ul>
                  <li>Vorheriges</li>
                  <li>Spiele das letzte</li>
                </ul>
              </EuiText>
            </EuiPanel>
          </EuiAccordion>
        </EuiPanel>
  
        <EuiSpacer />
  
        <EuiPanel hasBorder>
          <EuiAccordion
            id="accordion4"
            buttonContent="Prüfen Sie welches INSTAR Cloud-Konto mit Alexa verknüpft ist"
            paddingSize="m">
            <EuiPanel color="subdued">
              <EuiText style={{height: 300}}>
                <h5>Alexa Befehle:</h5>
                <ul>
                  <li>Was für einen Account verwende ich</li>
                  <li>Welchen Cloud Benutzer verwende ich</li>
                </ul>
              </EuiText>
            </EuiPanel>
          </EuiAccordion>
        </EuiPanel>
  
        <EuiSpacer />
  
        <EuiPanel hasBorder>
          <EuiAccordion
            id="accordion4"
            buttonContent="Zurück zur Startseite der INSTAR Cloud"
            paddingSize="m">
            <EuiPanel color="subdued">
              <EuiText style={{height: 300}}>
                <h5>Alexa Befehle:</h5>
                <ul>
                  <li>Zurück zur Startseite</li>
                  <li>Zeige Startseite</li>
                </ul>
              </EuiText>
            </EuiPanel>
          </EuiAccordion>
        </EuiPanel>
  
        <EuiSpacer />
  
        <EuiPanel hasBorder>
          <EuiAccordion
            id="accordion4"
            buttonContent="Um Hilfe bitten"
            paddingSize="m">
            <EuiPanel color="subdued">
              <EuiText style={{height: 300}}>
                <h5>Alexa Befehle:</h5>
                <ul>
                  <li>Hilfe</li>
                  <li>Wie verwende ich diese Skill</li>
                </ul>
              </EuiText>
            </EuiPanel>
          </EuiAccordion>
        </EuiPanel>
  
        <EuiSpacer />
  
        <EuiPanel hasBorder>
          <EuiAccordion
            id="accordion4"
            buttonContent="Beenden Sie die INSTAR Cloud Skill"
            paddingSize="m">
            <EuiPanel color="subdued">
              <EuiText style={{height: 300}}>
                <h5>Alexa Befehle:</h5>
                <ul>
                  <li>Beende INSTAR Cloud</li>
                  <li>Schließe INSTAR Cloud</li>
                </ul>
              </EuiText>
            </EuiPanel>
          </EuiAccordion>
        </EuiPanel>
    </React.Fragment>
  );
}

export default CommandAccordion


// export default () => (
//   <React.Fragment>
//     <EuiPanel hasBorder>
//       <EuiAccordion
//         id="accordion1"
//         buttonContent="Starten Sie die INSTAR Cloud Skill"
//         paddingSize="l"
//         style={{height: "fit-content"}}>
//           <EuiPanel color="subdued">
//             <EuiText style={{height: 300}}>
//               <ul>
//                 <li>Öffne INSTAR Cloud</li>
//                 <li>Starte INSTAR Cloud</li>
//               </ul>
//             </EuiText>
//           </EuiPanel>
//         </EuiAccordion>
//       </EuiPanel>

//       <EuiSpacer />

    
//     <EuiPanel hasBorder>
//       <EuiAccordion
//         id="accordion1"
//         buttonContent="Aktuelle Alarmvideos abspielen"
//         paddingSize="l"
//         style={{height: "fit-content"}}>
//           <EuiPanel color="subdued">
//             <EuiText style={{height: 300}}>
//               <ul>
//                 <li>Zeige die letzten Alarme</li>
//                 <li>Zeige mir die neuesten Alarme</li>
//               </ul>
//             </EuiText>
//           </EuiPanel>
//         </EuiAccordion>
//       </EuiPanel>

//       <EuiSpacer />

    
//       <EuiPanel hasBorder>
//         <EuiAccordion
//           id="accordion1"
//           buttonContent="Abspielen der letzten Alarmvideos von einer bestimmten Kamera"
//           paddingSize="l"
//           style={{height: "fit-content"}}>
//             <EuiPanel color="subdued">
//               <EuiText style={{height: 280}}>
//                 <EuiImage
//                   size="l"
//                   float="left"
//                   margin="l"
//                   hasShadow
//                   allowFullScreen
//                   alt="Abspielen der letzten Alarmvideos von einer bestimmten Kamera"
//                   src={Command01}
//                 />
//                 <h5>Alexa Befehle:</h5>
//                 <ul>
//                   <li>Zeige die letzten Alarme von Kamera <code>[cameraName]</code></li>
//                   <li>Zeige mir die neuesten Aufnahmen von Kamera <code>[cameraName]</code></li>
//                 </ul>
//                 <p>
//                   <code>[cameraName]</code> ist der Name, den Sie Ihrer Kamera innerhalb des INSTAR Cloud Systems gegeben haben.
//                 </p>
//               </EuiText>
//             </EuiPanel>
//           </EuiAccordion>
//         </EuiPanel>

//         <EuiSpacer />

//         <EuiPanel hasBorder>
//           <EuiAccordion
//             id="accordion1"
//             buttonContent="Alle Alarmvideos von einer bestimmten Kamera wiedergeben"
//             paddingSize="l"
//             style={{height: "fit-content"}}>
//               <EuiPanel color="subdued">
//                 <EuiText style={{height: 280}}>
//                   <EuiImage
//                     size="l"
//                     float="left"
//                     margin="l"
//                     hasShadow
//                     allowFullScreen
//                     alt="Alle Alarmvideos von einer bestimmten Kamera wiedergeben"
//                     src={Command02}
//                   />
//                   <h5>Alexa Befehle:</h5>
//                   <ul>
//                     <li>Zeige alle Alarme von Kamera <code>[cameraName]</code></li>
//                     <li>Zeige mir alle Aufnahmen von Kamera <code>[cameraName]</code></li>
//                   </ul>
//                   <p>
//                     <code>[cameraName]</code> ist der Name, den Sie Ihrer Kamera innerhalb des INSTAR Cloud Systems gegeben haben.
//                   </p>
//                 </EuiText>
//               </EuiPanel>
//             </EuiAccordion>
//           </EuiPanel>

//           <EuiSpacer />

//           <EuiPanel hasBorder>
//             <EuiAccordion
//               id="accordion2"
//               buttonContent="Wiedergabe einer bestimmten Anzahl der neuesten Alarmvideos"
//               buttonContentClassName="eui-textTruncate"
//               paddingSize="l">
//               <EuiPanel color="subdued">
//                 <EuiText style={{height: 280}}>
//                   <EuiImage
//                     size="l"
//                     float="left"
//                     margin="l"
//                     hasShadow
//                     allowFullScreen
//                     alt="Wiedergabe einer bestimmten Anzahl der neuesten Alarmvideos"
//                     src={Command03}
//                   />
//                   <h5>Alexa Befehle:</h5>
//                   <ul>
//                     <li>Zeige die letzten <code>[alarmsCount]</code> Alarme</li>
//                     <li>Zeige mir die <code>[alarmsCount]</code> neuesten Videos</li>
//                   </ul>
//                   <p>
//                     <code>[alarmsCount]</code> muss eine Zahl sein: 1, 2, 3 ... 100
//                   </p>
//                 </EuiText>
//               </EuiPanel>
//             </EuiAccordion>
//           </EuiPanel>

//           <EuiSpacer />

//       <EuiPanel hasBorder>
//         <EuiAccordion
//           id="accordion3"
//           buttonContent="Wiedergabe von Alarmvideos nach Alarmstatus"
//           paddingSize="m">
//           <EuiPanel color="subdued">
//             <EuiText style={{height: 280}}>
//               <EuiImage
//                 size="l"
//                 float="left"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="Wiedergabe von Alarmvideos nach Alarmstatus"
//                 src={Command04}
//               />
//               <h5>Alexa Befehle:</h5>
//               <ul>
//                 <li>Zeige alle <code>[status]</code>* Alarme</li>
//                 <li>Zeige mir alle <code>[status]</code>* Aufnahmen</li>
//               </ul>
//             </EuiText>
//           </EuiPanel>
//         </EuiAccordion>
//       </EuiPanel>

//       <EuiSpacer />

//       <EuiPanel hasBorder>
//         <EuiAccordion
//           id="accordion4"
//           buttonContent="Abspielen von Alarmvideos nach Datum"
//           paddingSize="m">
//           <EuiPanel color="subdued">
//             <EuiText style={{height: 280}}>
//               <EuiImage
//                 size="l"
//                 float="left"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="Abspielen von Alarmvideos nach Datum"
//                 src={Command05}
//               />
//               <h5>Alexa Befehle:</h5>
//               <ul>
//                 <li>Zeige alle Alarme vom <code>[alarmsDate]</code></li>
//                 <li>Zeige mir alle Aufnahmen vom <code>[alarmsDate]</code></li>
//               </ul>
//               <p>
//                 <code>[alarmsDate]</code> wie <em>heute</em>, <em>gestern</em>, <em>vorgestern</em> oder ein bestimmtes Datum, wie <em>Erster April 2020</em> <strong>Beachten Sie</strong>: Sie müssen in letzteren Fall immer das Jahr mit angeben.
//               </p>
//             </EuiText>
//           </EuiPanel>
//         </EuiAccordion>
//       </EuiPanel>

//       <EuiSpacer />

//       <EuiPanel hasBorder>
//         <EuiAccordion
//           id="accordion4"
//           buttonContent="Abspielen von Alarmvideos nach erkannten Objekten"
//           paddingSize="m">
//           <EuiPanel color="subdued">
//             <EuiText style={{height: 280}}>
//               <EuiImage
//                 size="l"
//                 float="left"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="Abspielen von Alarmvideos nach erkannten Objekten"
//                 src={Command06}
//               />
//               <h5>Alexa Befehle:</h5>
//               <ul>
//                 <li>Zeige alle Alarme mit <code>[object]</code>*</li>
//                 <li>Zeige alle Aufnahmen mit <code>[object]</code>*</li>
//               </ul>
//             </EuiText>
//           </EuiPanel>
//         </EuiAccordion>
//       </EuiPanel>

//       <EuiSpacer />

//       <EuiPanel hasBorder>
//         <EuiAccordion
//           id="accordion4"
//           buttonContent="Wiedergabe einer bestimmten Anzahl von Alarmvideos von einer bestimmten Kamera"
//           paddingSize="m">
//           <EuiPanel color="subdued">
//             <EuiText style={{height: 280}}>
//               <EuiImage
//                 size="l"
//                 float="left"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="Wiedergabe einer bestimmten Anzahl von Alarmvideos von einer bestimmten Kamera"
//                 src={Command07}
//               />
//               <h5>Alexa Befehle:</h5>
//               <ul>
//                 <li>Zeige die letzten <code>[alarmsCount]</code> Alarme von Kamera <code>[cameraName]</code></li>
//                 <li>PSpiele mir die letzten <code>[alarmsCount]</code> Aufnahmen von Kamera <code>[cameraName]</code> ab</li>
//               </ul>
//               <p>
//                 <code>[cameraName]</code> ist der Name, den Sie Ihrer Kamera innerhalb des INSTAR Cloud Systems gegeben haben.
//               </p>
//               <p>
//                 <code>[alarmsCount]</code> muss eine Zahl sein: 1, 2, 3...100
//               </p>
//             </EuiText>
//           </EuiPanel>
//         </EuiAccordion>
//       </EuiPanel>

//       <EuiSpacer />

//       <EuiPanel hasBorder>
//         <EuiAccordion
//           id="accordion4"
//           buttonContent="Wiedergabe von Alarmvideos basierend auf Kameraname und Datum"
//           paddingSize="m">
//           <EuiPanel color="subdued">
//             <EuiText style={{height: 280}}>
//               <EuiImage
//                 size="l"
//                 float="left"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="Wiedergabe von Alarmvideos basierend auf Kameraname und Datum"
//                 src={Command08}
//               />
//               <h5>Alexa Befehle:</h5>
//               <ul>
//                 <li>Zeige alle Alarme von <code>[cameraName]</code> vom <code>[alarmsDate]</code></li>
//                 <li>Zeige alle Alarme vom <code>[alarmsDate]</code> von Kamera <code>[cameraName]</code></li>
//               </ul>
//               <p>
//                 <code>[cameraName]</code> ist der Name, den Sie Ihrer Kamera innerhalb des INSTAR Cloud Systems gegeben haben.
//               </p>
//               <p>
//                 <code>[alarmsDate]</code> wie <em>heute</em>, <em>gestern</em>, <em>vorgestern</em> oder ein bestimmtes Datum, wie <em>Erster April 2020</em> <strong>Beachten Sie</strong>: Sie müssen in letzteren Fall immer das Jahr mit angeben.
//               </p>
//             </EuiText>
//           </EuiPanel>
//         </EuiAccordion>
//       </EuiPanel>

//       <EuiSpacer />

//       <EuiPanel hasBorder>
//         <EuiAccordion
//           id="accordion4"
//           buttonContent="Abspielen der Alarmvideos basierend auf erkannten Objekten und Datum"
//           paddingSize="m">
//           <EuiPanel color="subdued">
//             <EuiText style={{height: 280}}>
//               <EuiImage
//                 size="l"
//                 float="left"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="Abspielen der Alarmvideos basierend auf erkannten Objekten und Datum"
//                 src={Command09}
//               />
//               <h5>Alexa Befehle:</h5>
//               <ul>
//                 <li>Zeige alle Alarme vom <code>[alarmsDate]</code> mit <code>[object]</code>*</li>
//                 <li>Zeige alle <code>[object]</code>* die am <code>[alarmsDate]</code> erkannt wurden</li>
//               </ul>
//               <p>
//                 <code>[alarmsDate]</code> wie <em>heute</em>, <em>gestern</em>, <em>vorgestern</em> oder ein bestimmtes Datum, wie <em>Erster April 2020</em> <strong>Beachten Sie</strong>: Sie müssen in letzteren Fall immer das Jahr mit angeben.
//               </p>
//             </EuiText>
//           </EuiPanel>
//         </EuiAccordion>
//       </EuiPanel>

//       <EuiSpacer />

//       <EuiPanel hasBorder>
//         <EuiAccordion
//           id="accordion4"
//           buttonContent="Abspielen der Alarmvideos auf der Grundlage des Kameranamens, der erkannten Objekte und des Datums"
//           paddingSize="m">
//           <EuiPanel color="subdued">
//             <EuiText style={{height: 280}}>
//               <EuiImage
//                 size="l"
//                 float="left"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="Abspielen der Alarmvideos auf der Grundlage des Kameranamens, der erkannten Objekte und des Datums"
//                 src={Command10}
//               />
//               <h5>Alexa Befehle:</h5>
//               <ul>
//                 <li>Zeige alle Alarme von <code>[cameraName]</code> vom <code>[alarmsDate]</code> mit <code>[object]</code>*</li>
//                 <li>Zeige alle <code>[object]</code>* die am <code>[alarmsDate]</code> erkannt wurden bei Kamera <code>[cameraName]</code></li>
//               </ul>
//               <p>
//                 <code>[cameraName]</code> ist der Name, den Sie Ihrer Kamera innerhalb des INSTAR Cloud Systems gegeben haben.
//               </p>
//               <p>
//                 <code>[alarmsDate]</code> wie <em>heute</em>, <em>gestern</em>, <em>vorgestern</em> oder ein bestimmtes Datum, wie <em>Erster April 2020</em> <strong>Beachten Sie</strong>: Sie müssen in letzteren Fall immer das Jahr mit angeben.
//               </p>
//             </EuiText>
//           </EuiPanel>
//         </EuiAccordion>
//       </EuiPanel>

//       <EuiSpacer />

//       <EuiPanel hasBorder>
//         <EuiAccordion
//           id="accordion4"
//           buttonContent="Wiedergabe von Alarmvideos basierend auf Datum Uhrzeit und des Kameranamens"
//           paddingSize="m">
//           <EuiPanel color="subdued">
//             <EuiText style={{height: 280}}>
//               <EuiImage
//                 size="l"
//                 float="left"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="Wiedergabe von Alarmvideos basierend auf Datum Uhrzeit und des Kameranamens"
//                 src={Command11}
//               />
//               <h5>Alexa Befehle:</h5>
//               <ul>
//                 <li>Zeige alle Alarme von Kamera <code>[cameraName]</code> vom <code>[alarmsDate]</code> zwischen <code>[timeStart]</code> und <code>[timeEnd]</code> Uhr</li>
//                 <li>Zeige mir alle Aufnahmen von Kamera <code>[cameraName]</code> vom <code>[alarmsDate]</code> zwischen <code>[timeStart]</code> und <code>[timeEnd]</code> Uhr</li>
//               </ul>
//               <p>
//                 <code>[cameraName]</code> ist der Name, den Sie Ihrer Kamera innerhalb des INSTAR Cloud Systems gegeben haben.
//               </p>
//               <p>
//                 <code>[alarmsDate]</code> wie <em>heute</em>, <em>gestern</em>, <em>vorgestern</em> oder ein bestimmtes Datum, wie <em>Erster April 2020</em> <strong>Beachten Sie</strong>: Sie müssen in letzteren Fall immer das Jahr mit angeben.
//               </p>
//               <p>
//                 <code>[timeStart]</code> / <code>[timeEnd]</code> eine Tageszeit, etwa 7 Uhr morgens, 19 Uhr abends
//               </p>
//             </EuiText>
//           </EuiPanel>
//         </EuiAccordion>
//       </EuiPanel>

//       <EuiSpacer />

//       <EuiPanel hasBorder>
//         <EuiAccordion
//           id="accordion4"
//           buttonContent="Wiedergabe von Alarmvideos eines bestimmten Kamerasets"
//           paddingSize="m">
//           <EuiPanel color="subdued">
//             <EuiText style={{height: 280}}>
//               <EuiImage
//                 size="l"
//                 float="left"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="Wiedergabe von Alarmvideos eines bestimmten Kamerasets"
//                 src={Command12}
//               />
//               <h5>Alexa Befehle:</h5>
//               <ul>
//                 <li>Zeige alle Alarme von Kameraset <code>[cameraSet]</code></li>
//                 <li>Zeige mir alle Aufnahmen von Kameraset <code>[cameraSet]</code></li>
//               </ul>
//               <p>
//                 <code>[cameraSet]</code> ist der Name eines Kamerasets, das Sie innerhalb des INSTAR Cloud-Systems erstellt haben.
//               </p>
//             </EuiText>
//           </EuiPanel>
//         </EuiAccordion>
//       </EuiPanel>

//       <EuiSpacer />

//       <EuiPanel hasBorder>
//         <EuiAccordion
//           id="accordion4"
//           buttonContent="Wiedergabe einer bestimmten Anzahl von Alarmvideos von einem bestimmten Kameraset"
//           paddingSize="m">
//           <EuiPanel color="subdued">
//             <EuiText style={{height: 280}}>
//               <EuiImage
//                 size="l"
//                 float="left"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="Wiedergabe einer bestimmten Anzahl von Alarmvideos von einem bestimmten Kameraset"
//                 src={Command13}
//               />
//               <h5>Alexa Befehle:</h5>
//               <ul>
//                 <li>Zeige die letzten <code>[alarmsCount]</code> Alarme von Kameraset <code>[cameraSet]</code></li>
//                 <li>Bitte zeige mir die neuesten <code>[alarmsCount]</code> Aufnahmen von Kameraset <code>[cameraSet]</code></li>
//               </ul>
//               <p>
//                 <code>[alarmsCount]</code> muss eine Zahl sein: 1, 2, 3...100
//               </p>
//               <p>
//                 <code>[cameraSet]</code> ist der Name Ihres Kamerasets, das Sie innerhalb des INSTAR Cloud-Systems erstellt haben.
//               </p>
//             </EuiText>
//           </EuiPanel>
//         </EuiAccordion>
//       </EuiPanel>

//       <EuiSpacer />

//       <EuiPanel hasBorder>
//         <EuiAccordion
//           id="accordion4"
//           buttonContent="Anzahl an Kameras"
//           paddingSize="m">
//           <EuiPanel color="subdued">
//             <EuiText style={{height: 280}}>
//               <EuiImage
//                 size="l"
//                 float="left"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="Anzahl an Kameras"
//                 src={Command14}
//               />
//               <h5>Alexa Befehle:</h5>
//               <ul>
//                 <li>Wie viele Kameras befinden sich in meinem Account</li>
//                 <li>Sag mir die Anzahl der Kameras in meinem Account</li>
//               </ul>
//             </EuiText>
//           </EuiPanel>
//         </EuiAccordion>
//       </EuiPanel>

//       <EuiSpacer />

//       <EuiPanel hasBorder>
//         <EuiAccordion
//           id="accordion4"
//           buttonContent="Verfügbarer Speicherplatz"
//           paddingSize="m">
//           <EuiPanel color="subdued">
//             <EuiText style={{height: 280}}>
//               <EuiImage
//                 size="l"
//                 float="left"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="Verfügbarer Speicherplatz"
//                 src={Command15}
//               />
//               <h5>Alexa Befehle:</h5>
//               <ul>
//                 <li>Wie viel Speicher ist noch frei</li>
//                 <li>Wie viel Speicherplatz habe ich noch</li>
//               </ul>
//             </EuiText>
//           </EuiPanel>
//         </EuiAccordion>
//       </EuiPanel>

//       <EuiSpacer />

//       <EuiPanel hasBorder>
//         <EuiAccordion
//           id="accordion4"
//           buttonContent="Auslaufsdatum des Speicherplatzes"
//           paddingSize="m">
//           <EuiPanel color="subdued">
//             <EuiText style={{height: 280}}>
//               <EuiImage
//                 size="l"
//                 float="left"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="Auslaufsdatum des Speicherplatzes"
//                 src={Command16}
//               />
//               <h5>Alexa Befehle:</h5>
//               <ul>
//                 <li>Wann läuft der Speicherplan aus</li>
//                 <li>Bis wann läuft mein Speicherplan</li>
//               </ul>
//             </EuiText>
//           </EuiPanel>
//         </EuiAccordion>
//       </EuiPanel>

//       <EuiSpacer />

//       <EuiPanel hasBorder>
//         <EuiAccordion
//           id="accordion4"
//           buttonContent="Anzahl der Alarme einer bestimmten Kamera"
//           paddingSize="m">
//           <EuiPanel color="subdued">
//             <EuiText style={{height: 280}}>
//               <EuiImage
//                 size="l"
//                 float="left"
//                 margin="l"
//                 hasShadow
//                 allowFullScreen
//                 alt="Anzahl der Alarme einer bestimmten Kamera"
//                 src={Command17}
//               />
//               <h5>Alexa Commands:</h5>
//               <ul>
//                 <li>Wie viele Videos hat Kamera <code>[cameraName]</code></li>
//                 <li>Wie viele Alarme hat Kamera <code>[cameraName]</code></li>
//               </ul>
//               <p>
//                 <code>[cameraName]</code> ist der Name, den Sie Ihrer Kamera innerhalb des INSTAR Cloud Systems gegeben haben.
//               </p>
//             </EuiText>
//           </EuiPanel>
//         </EuiAccordion>
//       </EuiPanel>

//       <EuiSpacer />

//       <EuiPanel hasBorder>
//         <EuiAccordion
//           id="accordion4"
//           buttonContent="Anzahl der Alarme bestimmter Kamerasets"
//           paddingSize="m">
//           <EuiPanel color="subdued">
//             <EuiText style={{height: 300}}>
//               <h5>Alexa Befehle:</h5>
//               <ul>
//                 <li>Wie viele Alarme sind in Kameraset <code>[cameraSet]</code></li>
//                 <li>Wie viele Alarme hat Kameraset <code>[cameraSet]</code></li>
//               </ul>
//             </EuiText>
//           </EuiPanel>
//         </EuiAccordion>
//       </EuiPanel>

//       <EuiSpacer />

//       <EuiPanel hasBorder>
//         <EuiAccordion
//           id="accordion4"
//           buttonContent="Available storage"
//           paddingSize="m">
//           <EuiPanel color="subdued">
//             <EuiText style={{height: 300}}>
//               <h5>Alexa Commands:</h5>
//               <ul>
//                 <li>How much space is left on my cloud</li>
//                 <li>How much memory is still available</li>
//               </ul>
//               <p>
//                 <code>[cameraSet]</code> ist der Name Ihres Kamerasets, das Sie innerhalb des INSTAR Cloud-Systems erstellt haben.
//               </p>
//             </EuiText>
//           </EuiPanel>
//         </EuiAccordion>
//       </EuiPanel>

//       <EuiSpacer />

//       <EuiPanel hasBorder>
//         <EuiAccordion
//           id="accordion4"
//           buttonContent="Ändern Sie Ihre Alarm-E-Mail-Einstellungen"
//           paddingSize="m">
//           <EuiPanel color="subdued">
//             <EuiText style={{height: 300}}>
//               <h5>Alexa Befehle:</h5>
//               <ul>
//                 <li><code>[switch]</code>* Alarm Emails</li>
//                 <li>Email bei Alarm <code>[switch]</code>*</li>
//               </ul>
//             </EuiText>
//           </EuiPanel>
//         </EuiAccordion>
//       </EuiPanel>

//       <EuiSpacer />

//       <EuiPanel hasBorder>
//         <EuiAccordion
//           id="accordion4"
//           buttonContent="Objekterkennung Einstellungen für Kameras ändern"
//           paddingSize="m">
//           <EuiPanel color="subdued">
//             <EuiText style={{height: 300}}>
//               <h5>Alexa Befehle:</h5>
//               <ul>
//                 <li><code>[switch]</code>* Objekterkennung für <code>[cameraName]</code></li>
//                 <li>Objekterkennung für Kamera <code>[switch]</code>* <code>[cameraName]</code></li>
//               </ul>
//               <p>
//                 <code>[cameraSet]</code> ist der Name, den Sie Ihrer Kamera innerhalb des INSTAR Cloud Systems gegeben haben.
//               </p>
//             </EuiText>
//           </EuiPanel>
//         </EuiAccordion>
//       </EuiPanel>

//       <EuiSpacer />

//       <EuiPanel hasBorder>
//         <EuiAccordion
//           id="accordion4"
//           buttonContent="Video Pausieren"
//           paddingSize="m">
//           <EuiPanel color="subdued">
//             <EuiText style={{height: 300}}>
//               <h5>Alexa Befehle:</h5>
//               <ul>
//                 <li>Pause</li>
//                 <li>Pausieren</li>
//               </ul>
//             </EuiText>
//           </EuiPanel>
//         </EuiAccordion>
//       </EuiPanel>

//       <EuiSpacer />

//       <EuiPanel hasBorder>
//         <EuiAccordion
//           id="accordion4"
//           buttonContent="Video Fortsetzen"
//           paddingSize="m">
//           <EuiPanel color="subdued">
//             <EuiText style={{height: 300}}>
//               <h5>Alexa Befehle:</h5>
//               <ul>
//                 <li>Fortsetzen</li>
//                 <li>Mach Weiter</li>
//               </ul>
//             </EuiText>
//           </EuiPanel>
//         </EuiAccordion>
//       </EuiPanel>

//       <EuiSpacer />

//       <EuiPanel hasBorder>
//         <EuiAccordion
//           id="accordion4"
//           buttonContent="Nächstes Video"
//           paddingSize="m">
//           <EuiPanel color="subdued">
//             <EuiText style={{height: 300}}>
//               <h5>Alexa Befehle:</h5>
//               <ul>
//                 <li>Nächstes</li>
//                 <li>Weiter</li>
//               </ul>
//             </EuiText>
//           </EuiPanel>
//         </EuiAccordion>
//       </EuiPanel>

//       <EuiSpacer />

//       <EuiPanel hasBorder>
//         <EuiAccordion
//           id="accordion4"
//           buttonContent="Vorheriges Video"
//           paddingSize="m">
//           <EuiPanel color="subdued">
//             <EuiText style={{height: 300}}>
//               <h5>Alexa Befehle:</h5>
//               <ul>
//                 <li>Vorheriges</li>
//                 <li>Spiele das letzte</li>
//               </ul>
//             </EuiText>
//           </EuiPanel>
//         </EuiAccordion>
//       </EuiPanel>

//       <EuiSpacer />

//       <EuiPanel hasBorder>
//         <EuiAccordion
//           id="accordion4"
//           buttonContent="Prüfen Sie welches INSTAR Cloud-Konto mit Alexa verknüpft ist"
//           paddingSize="m">
//           <EuiPanel color="subdued">
//             <EuiText style={{height: 300}}>
//               <h5>Alexa Befehle:</h5>
//               <ul>
//                 <li>Was für einen Account verwende ich</li>
//                 <li>Welchen Cloud Benutzer verwende ich</li>
//               </ul>
//             </EuiText>
//           </EuiPanel>
//         </EuiAccordion>
//       </EuiPanel>

//       <EuiSpacer />

//       <EuiPanel hasBorder>
//         <EuiAccordion
//           id="accordion4"
//           buttonContent="Zurück zur Startseite der INSTAR Cloud"
//           paddingSize="m">
//           <EuiPanel color="subdued">
//             <EuiText style={{height: 300}}>
//               <h5>Alexa Befehle:</h5>
//               <ul>
//                 <li>Zurück zur Startseite</li>
//                 <li>Zeige Startseite</li>
//               </ul>
//             </EuiText>
//           </EuiPanel>
//         </EuiAccordion>
//       </EuiPanel>

//       <EuiSpacer />

//       <EuiPanel hasBorder>
//         <EuiAccordion
//           id="accordion4"
//           buttonContent="Um Hilfe bitten"
//           paddingSize="m">
//           <EuiPanel color="subdued">
//             <EuiText style={{height: 300}}>
//               <h5>Alexa Befehle:</h5>
//               <ul>
//                 <li>Hilfe</li>
//                 <li>Wie verwende ich diese Skill</li>
//               </ul>
//             </EuiText>
//           </EuiPanel>
//         </EuiAccordion>
//       </EuiPanel>

//       <EuiSpacer />

//       <EuiPanel hasBorder>
//         <EuiAccordion
//           id="accordion4"
//           buttonContent="Beenden Sie die INSTAR Cloud Skill"
//           paddingSize="m">
//           <EuiPanel color="subdued">
//             <EuiText style={{height: 300}}>
//               <h5>Alexa Befehle:</h5>
//               <ul>
//                 <li>Beende INSTAR Cloud</li>
//                 <li>Schließe INSTAR Cloud</li>
//               </ul>
//             </EuiText>
//           </EuiPanel>
//         </EuiAccordion>
//       </EuiPanel>
//   </React.Fragment>
// );