import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class PushTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Push Dienst" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Aktivieren der Push Benachrichtigung', descriptionColumn: 'Ihre Kamera kann Sie per Email informieren, jedes Mal wenn die Bewegungserkennung ausgelöst hat. Eine Push-Benachrichtigung erfüllt den selben Zweck, ist aber viel schneller! Aktivieren Sie die Push-Benachrichtigung, wenn Sie direkt über einen Alarm informiert werden möchten. Die Push-Benachrichtigung funktioniert nur mit der InstarVision App für IOS oder ANDROID Geräte. Benachrichtigung für unsere InstarVision Smartphone App. Öffnen Sie das Multiview Menü in unserer ANDROID oder IOS App, aktivieren Sie den Push Button.' },
        { actionColumn: 'Ruheintervall', descriptionColumn: 'Bei einem Daueralarm, wie lange soll der Server warten bis er Sie erneut benachrichtigt (1s - 60s)' },
        { actionColumn: 'Test', descriptionColumn: 'Nachdem Sie den Push Dienst aktiviert haben, können Sie die Schaltfläche Test verwenden, damit Ihre Kamera Ihnen eine Testbenachrichtigung sendet.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

PushTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(PushTable)