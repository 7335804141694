import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import PrimaryBoxH265 from 'components/1440p_Series_CGI_List/PrimaryBoxH265Warning';
import DangerBoxURL from 'components/1440p_Series_CGI_List/DangerBoxURL';
import { EuiSpacer } from '@elastic/eui';
import * as React from 'react';
export default {
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  PrimaryBoxH265,
  DangerBoxURL,
  EuiSpacer,
  React
};