import React from 'react'
import { Link } from 'gatsby'

import {
  EuiSpacer,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

import mqttHTML5CardIcon from '../../../images/Advanced_User/Website_Integration/Website_Integration_HTML.webp'
import mqttJavaCardIcon from '../../../images/Advanced_User/Website_Integration/Website_Integration_Java.webp'
import mqttPhpCardIcon from '../../../images/Advanced_User/Website_Integration/Website_Integration_PHP.webp'

export default function LatestArticle() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Erweitert/Webseiten_Einbindung/WS_Stream_WQHD_Cameras/">
                  <img
                    src={mqttHTML5CardIcon}
                    alt="Websocket Videostream"
                  />
                </Link>
              }
              title="Websocket Videostream"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Erweitert/Webseiten_Einbindung/HTML5_Stream_Full_HD_Cameras/">
                  <img
                    src={mqttHTML5CardIcon}
                    alt="HTML5 Videostream"
                  />
                </Link>
              }
              title="HTML5 Videostream"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Erweitert/Webseiten_Einbindung/MJPEG_Stream_WQHD_Cameras/">
                  <img
                    src={mqttHTML5CardIcon}
                    alt="MJPEG Videostream (WQHD 2k+ Kameras)"
                  />
                </Link>
              }
              title="MJPEG Videostream (WQHD 2k+ Kameras)"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Erweitert/Webseiten_Einbindung/HD_Kamera_Einbindung/#hinzuf%C3%BCgen-des-mjpeg-videostreams">
                  <img
                    src={mqttHTML5CardIcon}
                    alt="MJPEG Videostream (Full HD & HD Kameras)"
                  />
                </Link>
              }
              title="MJPEG Videostream (Full HD & HD Kameras)"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Erweitert/Webseiten_Einbindung/Cambozola/">
                  <img
                    src={mqttJavaCardIcon}
                    alt="Cambozola"
                  />
                </Link>
              }
              title="Cambozola Java Plugin"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Erweitert/Webseiten_Einbindung/Open_IP_Camera_Manager/">
                  <img
                    src={mqttPhpCardIcon}
                    alt="Open IP Camera Manager"
                  />
                </Link>
              }
              title="PHP Skripte"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
