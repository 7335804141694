import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Account Einstellungen">
        <EuiText>
          <p>
            Konfigurieren Sie den Ablauf Ihres Benutzer-Tokens, Ihre bevorzugte Sprache der Benutzeroberfläche und fügen Sie die Zwei-Faktor-Authentifizierung für Ihr Konto hinzu. Die hier hinzugefügte E-Mail-Adresse kann im Anschluss auch für die Funktion "Passwort vergessen" verwendet werden.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

