import React from 'react'
import { Link } from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="ioBroker">
        <EuiText>
         <h2>Alle ioBroker Tutorials</h2>
         <ul>
            <li><Link to="/Software/Linux/ioBroker/">ioBroker mit Docker (Aktualisiert 08.04.2024)</Link></li>
            <li><Link to="/Erweitert/IOBroker_auf_Raspberry_Pi/">ioBroker auf einem Raspberry Pi (Veralted)</Link></li>
            <li><Link to="/Erweitert/INSTAR_MQTT_Broker/ioBroker/">INSTAR MQTT - ioBroker</Link></li>
         </ul>
         
         <h2>Alle ioBroker FAQs</h2>
         <ul>
           <li><Link to="/Frequently_Asked_Question/ioBroker_INSTAR_WQHD_MQTTv5_CLient/">ioBroker v9.1.2 als MQTTv5 Client für Ihre WQHD INSTAR IP Kamera</Link></li>
           <li><Link to="/Frequently_Asked_Question/ioBroker_as_Broker_INSTAR_WQHD_MQTT/">ioBroker als MQTT Broker für Ihre WQHD Kamera</Link></li>
           <li><Link to="/Frequently_Asked_Question/WQHD_with_ioBroker_MQTT/">ioBroker MQTT Alarmserver für Ihre WQHD Kamera</Link></li>
           <li><Link to="/Frequently_Asked_Question/WQHD_with_ioBroker_REST_API/">ioBroker REST API mit Ihrer WQHD Kamera nutzen</Link></li>
           <li><Link to="/Frequently_Asked_Question/WQHD_with_ioBroker/">ioBroker mit Ihrer WQHD Kamera nutzen</Link></li>
           <li><Link to="/Frequently_Asked_Question/ioBroker_INSTAR_WQHD_MQTTv5_Camera_Control/">ioBroker v7 mit Ihrem WQHD INSTAR MQTTv5 Broker</Link></li>
           <li><Link to="/Frequently_Asked_Question/ioBroker_INSTAR_Camera_Vis_with_Node-RED/">Camera Vis mit Node-RED</Link></li>
           <li><Link to="/Frequently_Asked_Question/Live_Video_and_ioBroker_VIS/">Live Video and ioBroker VIS</Link></li>
           <li><Link to="/Frequently_Asked_Question/INSTAR_MQTT_Alarmserver_ioBroker/">INSTAR MQTT Alarmserver ioBroker</Link></li>
           <li><Link to="/Frequently_Asked_Question/ioBroker_and_INSTAR_Alarmserver/">ioBroker und INSTAR Alarmserver</Link></li>
           <li><Link to="/Frequently_Asked_Question/ioBroker_MQTT_Broker_vs_Client/">ioBroker MQTT Broker vs Client</Link></li>
           <li><Link to="/Frequently_Asked_Question/ioBroker_as_MQTT_Broker_for_your_Camera/">ioBroker als MQTT Broker für Ihre Kamera</Link></li>
           <li><Link to="/Frequently_Asked_Question/ioBroker_MQTT_Overwrites_Camera_Settings/">ioBroker MQTT überschreibt Kamera Einstellungen</Link></li>
           <li><Link to="/Frequently_Asked_Question/ioBroker_and_MotionEye_Webhook/">ioBroker und MotionEye Webhooks</Link></li>
           <li><Link to="/Frequently_Asked_Question/ioBroker_without_MQTT/">ioBroker ohne MQTT</Link></li>
         </ul>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox