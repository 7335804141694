import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class DateTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Date & Time Settings" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Datum & Uhrzeit Ihrer Kamera', descriptionColumn: 'Interne Zeiteinstellung innerhalb des Betriebssystems Ihrer Kamera. Diese Zeit wird für alle Alarm- und Aufnahmezeitpläne verwendet, sowie auch für den Zeitstempel der gespeicherten Dateien.' },
        { actionColumn: 'Synchronisation mit der Computer-Zeit', descriptionColumn: 'Falls Ihre Kamera keine Verbindung zum Internet hat, können Sie diese Funktion nutzen, um die Zeiteinstellungen des Computers mit denen der Kamera zu synchronisieren. Dies wird nicht empfohlen, da Sie den Prozess regelmäßig machen müssen um die interne Uhr genau zu halten.' },
        { actionColumn: 'Datum & Uhrzeit des Computers', descriptionColumn: 'Das ist die Uhrzeit Ihres Computers. Die Funktion oben wird diese mit der internen Uhr der Kamera synchronisieren.' },
        { actionColumn: 'Synchronisieren mit NTP Server Test', descriptionColumn: 'Diese Funktion synchronisiert die interne Uhr der Kamera mit einem Internet Zeitserver. Es wird empfohlen, diese Einstellung zu nutzen, um die Kamera Zeit genau zu halten.' },
        { actionColumn: 'Zeitzone', descriptionColumn: 'Hier können Sie die Zeitzone auswählen - für Deutschland ist das GMT+1.' },
        { actionColumn: 'Sommerzeit aktivieren', descriptionColumn: 'Sommerzeit aktivieren oder deaktivieren - bitte beachten Sie, wenn Sie dies aktiviert haben, wird die Einstellung nicht automatisch auf Winterzeit wechseln!' },
        { actionColumn: 'NTP Server', descriptionColumn: 'Wenn Sie Probleme bei der automatischen Synchronisation haben, können Sie einen anderen Internet Zeitserver aus der Liste auswählen. Geschlossene Unternehmen müssen eventuell einen internen NTP Server angeben - dies kann mit einem CGI Befehl gemacht werden.' },
        { actionColumn: 'NTP Update Intervall', descriptionColumn: 'Setzen Sie das Intervall, dass Ihre Kamera für die Zeitsynchronisation verwenden soll.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

DateTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(DateTable)