import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'CGI Befehle',
      href: '/de/Erweitert/CGI_Befehle/',
    },
    {
      text: 'INSTAR MQTT',
      href: '/de/Erweitert/INSTAR_MQTT_Broker/',
    },
    {
      text: 'Webseiten',
      href: '/de/Erweitert/Webseiten_Einbindung/',
    },
    {
      text: 'Videostreaming',
      href: '/de/Erweitert/Youtube_Videostreaming_from_your_Camera/',
    },
    {
      text: 'Smarthome',
      href: '/de/Erweitert/Alle_Smarthome_Tutorials/',
    },
    {
      text: 'WebUI',
      href: '/de/Erweitert/WebUI_Wiederherstellen/',
    },
    {
      text: 'Firmware',
      href: '/de/Erweitert/Firmware_Wiederherstellen/',
    },
    {
      text: 'VPN',
      href: '/de/Erweitert/VPN_AVM_Fritzbox/',
    },
    {
      text: '◄ Entwickler',
      href: '/de/Erweitert/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={11}
        aria-label="Topics for Developers"
      />
      <EuiSpacer />
    </Fragment>
  );
}