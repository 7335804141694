import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Achtung">
        <EuiText>
          <p>Wenn Sie Probleme mit zu vielen Alarmen haben, dann können Sie dies durch andere Einstellungen verbessern. Stellen Sie die Empfindlichkeit immer so ein, dass die von Ihnen gewünschte Bewegung registriert wird. In der Regel sind dies Personen. Stellen Sie dabei einfach einen Wert für die Empfindlichkeit ein, so dass die von Ihnen vermutete (Personen)-Bewegung noch ausreicht um einen Alarm auszulösen. Ist dieser Wert für die Sensibilität z.B. 50, so empfehlen wir einen Wert von 60 für Ihre endgültige Konfiguration. Sie können so sehr einfach den kleinsten möglichen Wert finden der für Ihre Anforderungen verlässlich arbeitet.</p>
          <p>Um Alarme durch unerwünschte Bewegungen zu vermeiden, sollten Sie den Bildausschnitt so auswählen, dass Bereiche wie z.B. der Himmel, Straßen im Hintergrund, stark reflektierende Flächen etc. nicht und/oder so gering wie möglich im Bildbereich sind. Die Wahl des richtigen Bildausschnittes kann durch andere Objektive und/oder einfach durch eine andere Kameraausrichtung erreicht werden. Dies kann die Anzahl der unerwünschten Alarme deutlich reduzieren.</p>
          <p><strong>Es wird empfohlen den integrierten PIR Sensor der Kamera mit den Alarmbereichen zu koppeln.</strong></p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

