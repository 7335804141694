import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class PTZTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Pan, Tilt & Zoom" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Alarme während der Fahrt unterbinden', descriptionColumn: 'Möchten Sie die Bewegungserkennung der Kamera während eines Kamerschwenk deaktivieren, um Fehlalarme zu unterbinden.' },
        { actionColumn: 'Für X Sekunden unterbinden', descriptionColumn: 'Stellen Sie ein, wie lange die Bewegungserkennung deaktiviert bleiben soll nachdem ein Steuerbefehl eingetroffen ist.' },
        { actionColumn: 'Schrittweise Steuerung aktivieren', descriptionColumn: 'Mit den Standardeinstellungen bewegt sich Ihre Kamera in eine Richtung, solange Sie die Pfeiltaste auf dem PTZ Control Pad drücken. Wenn Ihre Verbindung zur Kamera mit hohen Latenzzeiten behaftet ist, kann es passieren, dass der Stoppbefehl verloren geht und Ihre Kamera sich bis zum Ende der Reichweite bewegt. Sie können die einstufige Bewegung verwenden, um dies zu verhindern. Ihre Kamera bewegt sich nur einen Schritt mit jedem Klick auf das Steuerfeld - und Sie erhalten die höchste Präzision.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

PTZTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(PTZTable)