import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class ImageTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Bild Einstellungen" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Zurücksetzen', descriptionColumn: 'Dieser Button setzt die Bildeinstellungen auf ihre Standardwerte zurück.' },
        { actionColumn: 'Helligkeit', descriptionColumn: 'Stellen Sie die Helligkeit des Videobilds ein.' },
        { actionColumn: 'Sättigung', descriptionColumn: 'Stellen Sie die Sättigung des Videobilds ein.' },
        { actionColumn: 'Kontrast', descriptionColumn: 'Stellen Sie den Kontrast des Videobilds ein.' },
        { actionColumn: 'Belichtungszeit', descriptionColumn: 'Stellen Sie die Belichtungszeit des Bildsensors ein.' },
        { actionColumn: 'Schärfe', descriptionColumn: 'Stellen Sie die Schärfe des Videobilds ein.' },
        { actionColumn: 'Drehen & Spiegeln', descriptionColumn: 'Wenn Sie Ihre Kamera über Kopf installiert haben, können Sie hier das Videobild einfach drehen und spiegeln.' },
        { actionColumn: 'Zeitstempel zeigen', descriptionColumn: 'Einstellen, wenn Sie die Kamerazeit auf dem Videobild angezeigt bekommen möchten. Der Zeitstempel wird auch in Videoaufnahmen sichtbar sein.' },
        { actionColumn: 'Kameraname zeigen', descriptionColumn: 'Einstellen, wenn Sie den Kameranamen auf dem Videobild angezeigt bekommen möchten. Der Kameraname wird auch in Videoaufnahmen sichtbar sein. Sie können den Kameranamen unten eintragen. Bitte beachten Sie, dass Sie den Kameranamen nicht leer lassen können. Manche Drittanbieter-Software bestimmt das Kameramodell über den Kameranamen und wird kein _unknown models_ zulassen. In diesem Fall müssen Sie den Kamernamen zum Standardwert zurücksetzen - z.B. - z.B. IN-6012HD.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

ImageTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(ImageTable)