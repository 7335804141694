
import React, { useState } from 'react'
import {Link} from 'gatsby'

import {
  EuiCallOut,
  EuiFlyout,
  EuiFlyoutHeader,
  EuiFlyoutBody,
  EuiButton,
  EuiTitle,
  EuiSpacer
} from '@elastic/eui'

import ProductTable from 'components/Products/Compare/indoor-compare-table'
import './App.css'

export default () => {
  const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);

  const closeFlyout = () => setIsFlyoutVisible(false);

  const showFlyout = () => setIsFlyoutVisible(true);

  let flyout;

  const callOut = (
    <EuiCallOut iconType="help">
      <p>
        Dies is eine Übersicht über die technischen Spezifikationen aller <Link to="/Innenkameras/">INSTAR Innen IP Kameras</Link>. Für eine Übersicht der <Link to="/Aussenkameras/">Aussenkameras</Link> folgen Sie bitte dem <Link to="/Aussenkameras/">Link</Link>.
      </p>
    </EuiCallOut>
  );

  if (isFlyoutVisible) {
    flyout = (
      <EuiFlyout
        ownFocus
        onClose={closeFlyout}
        size="l"
        aria-labelledby="flyoutWithBannerTitle">
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="m">
            <h2 id="flyoutWithBannerTitle">Innenkameras Vergleichen</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody banner={callOut}>
          <ProductTable/>
        </EuiFlyoutBody>
      </EuiFlyout>
    );
  }
  return (
    <div>
      <EuiSpacer />
      <EuiButton onClick={showFlyout} color="text">Innenkameras Vergleichen</EuiButton>
      {flyout}
    </div>
  );
};