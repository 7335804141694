import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Info">
        <EuiText>
          <p>
            Das Info Panel ist eine einfache Sammlung von Systemvariablen die von Interesse sein können, wenn Sie Ihr Kamerasystem auf Fehler überprüfen müssen. Der DDNS Zugriff funktioniert nicht mehr? Überprüfen Sie hier den DDNS Status (muss erfolgreich sein). Sie können auch die WAN IP aus diesem Menü (anstatt der DDNS Adresse) verwenden, um direkt auf Ihre Kamera zuzugreifen. Sollte der Zugriff über die WAN IP möglich sein, über die DDNS Adresse jedoch nicht, ist vermutlich die Portweiterleitung der Schuldige. Um weiteren Fehlerquellen auf die Schliche zu kommen, überprüfen Sie auch das System Logbuch.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

