import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-gtag";
import { EuiSpacer, EuiButton, EuiHideFor, EuiShowFor } from "@elastic/eui";
import TableTechSpecs from "components/Outdoor_Cameras/IN-9420_WQHD/Technical_Specifications/Table";
import FooterCards from "components/Outdoor_Cameras/IN-9420_WQHD/Technical_Specifications/FooterCards";
import PrimaryBox from "components/Outdoor_Cameras/IN-9420_WQHD/Technical_Specifications/PrimaryBox";
import NavButtons from "components/Outdoor_Cameras/IN-9420_WQHD/NavButtons";
import BreadCrumbs from "components/Layout/BreadCrumbs";
import SEOHelmet from "components/Layout/SEOHelmet";
import * as React from 'react';
export default {
  Link,
  OutboundLink,
  EuiSpacer,
  EuiButton,
  EuiHideFor,
  EuiShowFor,
  TableTechSpecs,
  FooterCards,
  PrimaryBox,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};